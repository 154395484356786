import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { PrazoMedio } from '../../models/prazos-medio/prazo-medio';
import { PrazoMedioDesconto } from '../../models/prazos-medio/prazo-medio-desconto';

@Injectable({
  providedIn: 'root'
})

export class PrazoMedioService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getByIdPrazoPagamento(id_prazo_pagamento: number): Observable<PrazoMedio> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_prazo_pagamento": id_prazo_pagamento
    };

    return this.http.post<PrazoMedio>(this.baseUrl + "api/PrazoMedio/GetByIdPrazoPagamento", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getPrazoMedioDescontoByGuidClienteNapis(prazo_medio: number): Observable<PrazoMedioDesconto> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "prazo_medio": prazo_medio,
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<PrazoMedioDesconto>(this.baseUrl + "api/PrazoMedio/GetPrazoMedioDescontoByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }
}
