import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { MotivoNaoVenda } from '../../models/motivos-nao-venda/motivo-nao-venda';

@Injectable({
  providedIn: 'root'
})

export class MotivoNaoVendaService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public add(motivo_nao_venda: MotivoNaoVenda): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "motivo_nao_venda": motivo_nao_venda
    };

    return this.http.post<boolean>(this.baseUrl + "api/MotivoNaoVenda/Add", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getByGuidClienteNapis(): Observable<MotivoNaoVenda[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<MotivoNaoVenda[]>(this.baseUrl + "api/MotivoNaoVenda/GetByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getById(id: number): Observable<MotivoNaoVenda> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<MotivoNaoVenda>(this.baseUrl + "api/MotivoNaoVenda/GetById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public update(motivo_nao_venda: MotivoNaoVenda): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "motivo_nao_venda": motivo_nao_venda
    };

    return this.http.post<boolean>(this.baseUrl + "api/MotivoNaoVenda/Update", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateIsActiveById(id: number, is_active: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "is_active": is_active
    };

    return this.http.post<boolean>(this.baseUrl + "api/MotivoNaoVenda/UpdateIsActiveById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
