import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { timer, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TempoProgressivoService {
  /* Tempo */

  diasProgressivo: number = 0;
  horasProgressivo: number = 0;
  minutosProgressivo: number = 0;
  segundosProgressivo: number = 0;

  textoDescritivo: string = "Em Atendimento: ";
  textoDescritivoTempo: string = "";

  tempoProgressivo = new Subscription();

  /* Toastr */

  toastId: number = 0;

  constructor(
    private toastr: ToastrService
  ) { }

  encerrarTempo() {
    if (this.toastId != 0) {
      let toast: any = this.toastr.toasts.find(toast => toast.toastId == this.toastId);

      if (toast != null) {
        this.toastr.remove(toast.toastId);
      }
    }

    /* Tempo */

    this.diasProgressivo = 0;
    this.horasProgressivo = 0;
    this.minutosProgressivo = 0;
    this.segundosProgressivo = 0;

    this.textoDescritivoTempo = "";

    this.tempoProgressivo.unsubscribe();

    /* Toastr */

    this.toastId = 0;
  }

  iniciarTempo() {
    this.encerrarTempo();

    this.tempoProgressivo = timer(1000, 1000).subscribe(() => { /* Início / Intervalo */
      this.segundosProgressivo++;

      if (this.segundosProgressivo > 59) {
        this.minutosProgressivo++;

        this.segundosProgressivo = 0;

        if (this.minutosProgressivo > 59) {
          this.horasProgressivo++;

          this.minutosProgressivo = 0;

          if (this.horasProgressivo > 24) {
            this.diasProgressivo++;

            this.horasProgressivo = 0;
          }
        }
      }

      this.textoDescritivoTempo = "";

      if (this.diasProgressivo > 0) {
        this.textoDescritivoTempo = this.diasProgressivo.toString() + "D";
      }

      this.textoDescritivoTempo = this.textoDescritivoTempo + this.horasProgressivo.toString().padStart(2, "0") + ":" + this.minutosProgressivo.toString().padStart(2, "0") + ":" + this.segundosProgressivo.toString().padStart(2, "0");

      if (this.toastId != 0) {
        let toast: any = this.toastr.toasts.find(toast => toast.toastId == this.toastId);

        if (toast != null) {
          toast.toastRef.componentInstance.message = this.textoDescritivo + this.textoDescritivoTempo;
        } else {
          let toast: any = this.toastr.info(this.textoDescritivo + this.textoDescritivoTempo, "", {
            positionClass: "toast-bottom-left",
            extendedTimeOut: 0,
            tapToDismiss: false,
            timeOut: 0
          });

          this.toastId = toast.toastId;
        }
      } else {
        let toast: any = this.toastr.info(this.textoDescritivo + this.textoDescritivoTempo, "", {
          positionClass: "toast-bottom-left",
          extendedTimeOut: 0,
          tapToDismiss: false,
          timeOut: 0
        });

        this.toastId = toast.toastId;
      }
    });
  }
}
