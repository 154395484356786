import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

/* Services */

import { AuthService } from '../auth/auth.service';
import { CallCenterService } from '../call-center/call-center.service';
import { CanaisComunicacaoTelefoniaService } from '../canais-comunicacao/telefonia/canais-comunicacao-telefonia.service';
import { CanaisComunicacaoWhatsappService } from '../canais-comunicacao/whatsapp/canais-comunicacao-whatsapp.service';

/* Models */

import { CallCenterWhatsAppWebHook } from '../../models/call-center/call-center-whatsapp-webhook';
import { CanaisComunicacaoTelefoniaConexao } from '../../models/canais-comunicacao/telefonia/canais-comunicacao-telefonia-conexao';
import { CanaisComunicacaoWhatsAppAudio } from '../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-audio';
import { CanaisComunicacaoWhatsAppChat } from '../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-chat';
import { CanaisComunicacaoWhatsAppDocumento } from '../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-documento';
import { CanaisComunicacaoWhatsAppImagem } from '../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-imagem';
import { CanaisComunicacaoWhatsAppStatus } from '../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-status';
import { CanaisComunicacaoWhatsAppSticker } from '../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-sticker';
import { CanaisComunicacaoWhatsAppTelefone } from '../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-telefone';
import { CanaisComunicacaoWhatsAppTexto } from '../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-texto';
import { CentralAtendimentoEvento } from '../../models/central-atendimento/central-atendimento-evento';
import { CentralAtendimentoEventoRetorno } from '../../models/central-atendimento/central-atendimento-evento-retorno';

@Injectable({
  providedIn: 'root'
})

export class CentralAtendimentoService {
  /* Emitter */

  chatEmitter = new EventEmitter<CanaisComunicacaoWhatsAppChat[]>();
  eventosEmitter = new EventEmitter<CentralAtendimentoEvento[]>();
  maximizarEmitter = new EventEmitter<boolean>();
  statusEmitter = new EventEmitter<CanaisComunicacaoWhatsAppStatus>();

  /* Call Center (WhatsApp (WebHook)) */

  callcenterWhatsAppWebHook: CallCenterWhatsAppWebHook[] = [];

  /* Canais de Comunicação (Telefonia) */

  canaisComunicacaoTelefoniaStatus: boolean = false;

  /* Canais de Comunicação (WhatsApp) */

  canaisComunicacaoWhatsAppChat: CanaisComunicacaoWhatsAppChat[] = [];

  canaisComunicacaoWhatsAppStatus: CanaisComunicacaoWhatsAppStatus = null;

  /* Central de Atendimento (Eventos) */

  centralAtendimentoEventos: CentralAtendimentoEvento[] = [];

  /* Cliente */

  clienteSelecionado: number = 0;

  numeroSelecionado: string = "";

  constructor(
    private router: Router,
    private callCenterService: CallCenterService,
    private canaisComunicacaoTelefoniaService: CanaisComunicacaoTelefoniaService,
    private canaisComunicacaoWhatsAppService: CanaisComunicacaoWhatsappService
  ) {
    this.receberEventosTelefonia();
  }

  /* Eventos */

  adicionarEvento(evento: CentralAtendimentoEvento): Promise<CentralAtendimentoEventoRetorno> {
    let retorno = new Promise<CentralAtendimentoEventoRetorno>((resolver) => {
      let centralAtendimentoEventoRetorno = new CentralAtendimentoEventoRetorno();

      centralAtendimentoEventoRetorno.eventoExecutado = true;
      centralAtendimentoEventoRetorno.nomeArquivo = "";
      centralAtendimentoEventoRetorno.urlArquivo = "";

      switch (evento.tipo) {
        case "email":
          /* E-mail */

          return resolver(centralAtendimentoEventoRetorno);
        case "facebook":
          /* Facebook */

          return resolver(centralAtendimentoEventoRetorno);
        case "telefonia":
          /* Telefonia */

          let tipoSoftphone: number = parseInt(localStorage.getItem("tipo_softphone"));

          if (evento.acao == "abrir_canal") {
            /* Abrir Canal */

            this.emitirEvento(evento);

            this.canaisComunicacaoTelefoniaService.abrirCanal(tipoSoftphone);

            return resolver(centralAtendimentoEventoRetorno);
          } else if (evento.acao == "conectar") {
            /* Conectar */

            switch (tipoSoftphone) {
              case 1:
                /* Communicator */

                if (this.canaisComunicacaoTelefoniaService.telefoniaGuid == "") {
                  this.emitirEvento(evento);

                  let canaisComunicacaoTelefoniaConexao: CanaisComunicacaoTelefoniaConexao = null;

                  this.canaisComunicacaoTelefoniaService.communicatorConectar().subscribe(
                    canaisComunicacaoTelefoniaConexoes => canaisComunicacaoTelefoniaConexao = canaisComunicacaoTelefoniaConexoes,
                    error => { console.log("Erro: " + error) },
                    () => {
                      if (canaisComunicacaoTelefoniaConexao != null) {
                        this.canaisComunicacaoTelefoniaService.definirGuid(canaisComunicacaoTelefoniaConexao.session_id);

                        this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "abrir_canal", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));
                      } else {
                        this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Softphone indisponível...", 0, false, true, true, false, false, "", "", "", null, "", 0));
                      }

                      return resolver(centralAtendimentoEventoRetorno);
                    }
                  );
                } else {
                  this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "abrir_canal", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));

                  return resolver(centralAtendimentoEventoRetorno);
                }

                break;
              case 2:
                /* Bria */

                this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "abrir_canal", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));

                return resolver(centralAtendimentoEventoRetorno);
              case 3:
                /* L5 */

                this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "abrir_canal", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));

                return resolver(centralAtendimentoEventoRetorno);
              default:
                return resolver(centralAtendimentoEventoRetorno);
            }
          } else if (evento.acao == "desconectar") {
            /* Desconectar */

            this.emitirEvento(evento);

            this.canaisComunicacaoTelefoniaService.encerrarCanal(true);

            return resolver(centralAtendimentoEventoRetorno);
          } else if (evento.acao == "iniciar_chamada") {
            /* Iniciar Chamada */

            if (this.canaisComunicacaoTelefoniaStatus) {
              this.emitirEvento(evento);

              switch (tipoSoftphone) {
                case 1:
                  /* Communicator */

                  let chamada: any = null;

                  this.canaisComunicacaoTelefoniaService.communicatorIniciarChamada(evento.numero_telefone).subscribe(
                    chamadas => chamada = chamadas,
                    error => { console.log("Erro: " + error) },
                    () => {
                      return resolver(centralAtendimentoEventoRetorno);
                    }
                  );

                  break;
                case 2:
                  /* Bria */

                  this.canaisComunicacaoTelefoniaService.briaIniciarChamada(evento.numero_telefone);

                  return resolver(centralAtendimentoEventoRetorno);
                case 3:
                  /* L5 */

                  this.canaisComunicacaoTelefoniaService.l5IniciarChamada(evento.numero_telefone);

                  return resolver(centralAtendimentoEventoRetorno);
                default:
                  centralAtendimentoEventoRetorno.eventoExecutado = false;

                  return resolver(centralAtendimentoEventoRetorno);
              }
            } else {
              this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "conectar", "Chamadas", "Conectando...", 0, false, true, false, false, false, "", "", "", null, "", 0));

              centralAtendimentoEventoRetorno.eventoExecutado = false;

              return resolver(centralAtendimentoEventoRetorno);
            }
          } else if (evento.acao == "receber_chamada") {
            /* Receber Chamada */

            switch (tipoSoftphone) {
              case 1:
                /* Communicator */

                let chamada: any = null;

                this.canaisComunicacaoTelefoniaService.communicatorReceberChamada().subscribe(
                  chamadas => chamada = chamadas,
                  error => { console.log("Erro: " + error) },
                  () => {
                    return resolver(centralAtendimentoEventoRetorno);
                  }
                );

                break;
              case 2:
                /* Bria */

                this.canaisComunicacaoTelefoniaService.briaReceberChamada();

                return resolver(centralAtendimentoEventoRetorno);
              case 3:
                /* L5 */

                this.canaisComunicacaoTelefoniaService.l5ReceberChamada();

                return resolver(centralAtendimentoEventoRetorno);
              default:
                centralAtendimentoEventoRetorno.eventoExecutado = false;

                return resolver(centralAtendimentoEventoRetorno);
            }
          } else {
            this.emitirEvento(evento);

            return resolver(centralAtendimentoEventoRetorno);
          }

          break;
        case "whatsapp":
          /* WhatsApp */

          if (evento.acao == "enviar_audio") {
            /* Enviar - Áudio */

            let canaisComunicacaoWhatsAppAudio = new CanaisComunicacaoWhatsAppAudio();

            canaisComunicacaoWhatsAppAudio.phone = evento.numero_telefone;
            canaisComunicacaoWhatsAppAudio.audio = evento.base64;

            let audioEnviado: boolean = false;

            this.canaisComunicacaoWhatsAppService.sendAudio(canaisComunicacaoWhatsAppAudio).subscribe(
              audiosEnviados => audioEnviado = audiosEnviados,
              error => { console.log("Erro: " + error) },
              () => {
                if (audioEnviado) {

                }

                return resolver(centralAtendimentoEventoRetorno);
              }
            );
          } else if (evento.acao == "enviar_documento") {
            /* Enviar - Documento */

            let canaisComunicacaoAdicionarArquivo: string = "";

            this.canaisComunicacaoWhatsAppService.addCanaisComunicacaoArquivo(evento.arquivo).subscribe(
              canaisComunicacaoAdicionarArquivos => canaisComunicacaoAdicionarArquivo = canaisComunicacaoAdicionarArquivos,
              error => { console.log("Erro: " + error) },
              () => {
                if (canaisComunicacaoAdicionarArquivo != "") {
                  let extensao: string[] = evento.arquivo.name.split(".");

                  if (extensao.length > 1) {
                    let canaisComunicacaoWhatsAppDocumento = new CanaisComunicacaoWhatsAppDocumento();

                    canaisComunicacaoWhatsAppDocumento.extension = extensao[1];
                    canaisComunicacaoWhatsAppDocumento.phone = evento.numero_telefone;
                    canaisComunicacaoWhatsAppDocumento.document = canaisComunicacaoAdicionarArquivo;
                    canaisComunicacaoWhatsAppDocumento.fileName = extensao[0];

                    let documentoEnviado: boolean = false;

                    this.canaisComunicacaoWhatsAppService.sendDocument(canaisComunicacaoWhatsAppDocumento).subscribe(
                      documentosEnviados => documentoEnviado = documentosEnviados,
                      error => { console.log("Erro: " + error) },
                      () => {
                        if (documentoEnviado) {
                          /*
                          let nomeArquivo: string = canaisComunicacaoAdicionarArquivo.replace("https://crmarquivos.blob.core.windows.net/fotoswhats/", "");
 
                          let canaisComunicacaoDeletarArquivo: boolean = false;
 
                          this.canaisComunicacaoWhatsAppService.deleteCanaisComunicacaoArquivo(nomeArquivo).subscribe(
                            canaisComunicacaoDeletarArquivos => canaisComunicacaoDeletarArquivo = canaisComunicacaoDeletarArquivos,
                            error => { console.log("Erro: " + error) },
                            () => {
                              if (canaisComunicacaoDeletarArquivo) {
 
                              }
                            }
                          );
                          */

                          centralAtendimentoEventoRetorno.nomeArquivo = evento.arquivo.name;
                          centralAtendimentoEventoRetorno.urlArquivo = canaisComunicacaoAdicionarArquivo;
                        } else {
                          centralAtendimentoEventoRetorno.eventoExecutado = false;
                        }

                        return resolver(centralAtendimentoEventoRetorno);
                      }
                    );
                  } else {
                    centralAtendimentoEventoRetorno.eventoExecutado = false;

                    return resolver(centralAtendimentoEventoRetorno);
                  }
                } else {
                  centralAtendimentoEventoRetorno.eventoExecutado = false;

                  return resolver(centralAtendimentoEventoRetorno);
                }
              }
            );
          } else if (evento.acao == "enviar_imagem") {
            /* Enviar - Imagem */

            let canaisComunicacaoAdicionarArquivo: string = "";

            this.canaisComunicacaoWhatsAppService.addCanaisComunicacaoArquivo(evento.arquivo).subscribe(
              canaisComunicacaoAdicionarArquivos => canaisComunicacaoAdicionarArquivo = canaisComunicacaoAdicionarArquivos,
              error => { console.log("Erro: " + error) },
              () => {
                if (canaisComunicacaoAdicionarArquivo != "") {
                  let canaisComunicacaoWhatsAppImagem = new CanaisComunicacaoWhatsAppImagem();

                  canaisComunicacaoWhatsAppImagem.image = canaisComunicacaoAdicionarArquivo;
                  canaisComunicacaoWhatsAppImagem.phone = evento.numero_telefone;

                  let imagemEnviada: boolean = false;

                  this.canaisComunicacaoWhatsAppService.sendImage(canaisComunicacaoWhatsAppImagem).subscribe(
                    imagensEnviadas => imagemEnviada = imagensEnviadas,
                    error => { console.log("Erro: " + error) },
                    () => {
                      if (imagemEnviada) {
                        centralAtendimentoEventoRetorno.urlArquivo = canaisComunicacaoAdicionarArquivo;
                      } else {
                        centralAtendimentoEventoRetorno.eventoExecutado = false;
                      }

                      return resolver(centralAtendimentoEventoRetorno);
                    }
                  );
                } else {
                  centralAtendimentoEventoRetorno.eventoExecutado = false;

                  return resolver(centralAtendimentoEventoRetorno);
                }
              }
            );
          } else if (evento.acao == "enviar_sticker") {
            /* Enviar - Sticker */

            let canaisComunicacaoAdicionarArquivo: string = "";

            this.canaisComunicacaoWhatsAppService.addCanaisComunicacaoArquivo(evento.arquivo).subscribe(
              canaisComunicacaoAdicionarArquivos => canaisComunicacaoAdicionarArquivo = canaisComunicacaoAdicionarArquivos,
              error => { console.log("Erro: " + error) },
              () => {
                if (canaisComunicacaoAdicionarArquivo != "") {
                  let canaisComunicacaoWhatsAppSticker = new CanaisComunicacaoWhatsAppSticker();

                  canaisComunicacaoWhatsAppSticker.phone = evento.numero_telefone;
                  canaisComunicacaoWhatsAppSticker.sticker = canaisComunicacaoAdicionarArquivo;

                  let stickerEnviado: boolean = false;

                  this.canaisComunicacaoWhatsAppService.sendSticker(canaisComunicacaoWhatsAppSticker).subscribe(
                    stickerEnviados => stickerEnviado = stickerEnviados,
                    error => { console.log("Erro: " + error) },
                    () => {
                      if (stickerEnviado) {
                        centralAtendimentoEventoRetorno.urlArquivo = canaisComunicacaoAdicionarArquivo;
                      } else {
                        centralAtendimentoEventoRetorno.eventoExecutado = false;
                      }

                      return resolver(centralAtendimentoEventoRetorno);
                    }
                  );
                } else {
                  centralAtendimentoEventoRetorno.eventoExecutado = false;

                  return resolver(centralAtendimentoEventoRetorno);
                }
              }
            );
          } else if (evento.acao == "enviar_texto") {
            /* Enviar - Texto */

            let canaisComunicacaoWhatsAppTexto = new CanaisComunicacaoWhatsAppTexto();

            canaisComunicacaoWhatsAppTexto.phone = evento.numero_telefone;
            canaisComunicacaoWhatsAppTexto.message = evento.mensagem;

            let mensagemEnviada: boolean = false;

            this.canaisComunicacaoWhatsAppService.sendText(canaisComunicacaoWhatsAppTexto).subscribe(
              mensagensEnviadas => mensagemEnviada = mensagensEnviadas,
              error => { console.log("Erro: " + error) },
              () => {
                if (!mensagemEnviada) {
                  centralAtendimentoEventoRetorno.eventoExecutado = false;
                }

                return resolver(centralAtendimentoEventoRetorno);
              }
            );
          } else if (evento.acao == "fechar_chat") {
            /* Cliente */

            this.clienteSelecionado = 0;

            this.numeroSelecionado = "";

            return resolver(centralAtendimentoEventoRetorno);
          } else if (evento.acao == "iniciar_chat") {
            let centralAtendimentoEvento = this.centralAtendimentoEventos.find(centralAtendimentoEvento => centralAtendimentoEvento.tipo == evento.tipo && centralAtendimentoEvento.client_id_app == evento.client_id_app);

            if (centralAtendimentoEvento != null) {
              centralAtendimentoEvento.data_hora = new Date();
              centralAtendimentoEvento.exibir = true;
              centralAtendimentoEvento.visualizado = false;
              centralAtendimentoEvento.numero_telefone = evento.numero_telefone;

              if (centralAtendimentoEvento.acao == "mensagem_nao_lida") {
                centralAtendimentoEvento.acao = "iniciar_chat";
                centralAtendimentoEvento.titulo = "";
                centralAtendimentoEvento.mensagem_contador = 0;
              }

              this.eventosEmitter.emit(this.centralAtendimentoEventos);
            } else {
              this.emitirEvento(evento);
            }

            return resolver(centralAtendimentoEventoRetorno);
          } else if (evento.acao == "mensagem_nao_lida") {
            this.emitirEvento(evento);

            return resolver(centralAtendimentoEventoRetorno);
          } else if (evento.acao == "receber_chat") {
            /* Cliente */

            if (evento.client_id_app != null && evento.client_id_app != 0) {
              /* Cliente Vinculado */

              this.clienteSelecionado = evento.client_id_app;

              this.numeroSelecionado = "";
            } else {
              /* Cliente não Vinculado */

              this.clienteSelecionado = 0;

              this.numeroSelecionado = evento.numero_telefone;
            }

            let canaisComunicacaoWhatsAppTelefone = new CanaisComunicacaoWhatsAppTelefone();

            canaisComunicacaoWhatsAppTelefone.phone = evento.numero_telefone;
            canaisComunicacaoWhatsAppTelefone.amount = 15;
            canaisComunicacaoWhatsAppTelefone.lastmessageid = "";

            this.canaisComunicacaoWhatsAppService.getChat(canaisComunicacaoWhatsAppTelefone).subscribe(
              canalComunicacaoWhatsAppChat => this.canaisComunicacaoWhatsAppChat = canalComunicacaoWhatsAppChat,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.canaisComunicacaoWhatsAppChat.length > 0) {
                  this.canaisComunicacaoWhatsAppChat.forEach((item) => {
                    item.data_hora = new Date(item.momment);
                  });
                }

                this.chatEmitter.emit(this.canaisComunicacaoWhatsAppChat);

                return resolver(centralAtendimentoEventoRetorno);
              }
            );
          } else if (evento.acao == "receber_mensagem") {
            this.callCenterService.getWhatsAppWebHookByVendedor().subscribe(
              callcenterWhatsAppWebHook => this.callcenterWhatsAppWebHook = callcenterWhatsAppWebHook,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.callcenterWhatsAppWebHook.length > 0) {
                  this.callcenterWhatsAppWebHook.forEach((item) => {
                    if (item.id_client != null) {
                      let centralAtendimentoEvento = this.centralAtendimentoEventos.find(centralAtendimentoEvento => centralAtendimentoEvento.client_id_app == item.id_client && centralAtendimentoEvento.exibir);

                      if (centralAtendimentoEvento == null) {
                        this.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "mensagem_nao_lida", "Nova Mensagem", "", 1, false, true, false, false, false, item.name, "", item.telefone, null, "", item.id_client));
                      } else {
                        if (item.telefone != this.numeroSelecionado && item.id_client != this.clienteSelecionado) {
                          centralAtendimentoEvento.titulo = "Nova Mensagem";
                          centralAtendimentoEvento.mensagem_contador++;
                          centralAtendimentoEvento.visualizado = false;
                        }
                      }
                    } else {
                      let centralAtendimentoEvento = this.centralAtendimentoEventos.find(centralAtendimentoEvento => centralAtendimentoEvento.numero_telefone == item.telefone && centralAtendimentoEvento.exibir);

                      if (centralAtendimentoEvento == null) {
                        this.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "mensagem_nao_lida", "Nova Mensagem", "", 1, false, true, false, false, false, item.telefone, "", item.telefone, null, "", item.id_client));
                      } else {
                        if (item.telefone != this.numeroSelecionado && item.id_client != this.clienteSelecionado) {
                          centralAtendimentoEvento.titulo = "Nova Mensagem";
                          centralAtendimentoEvento.mensagem_contador++;
                          centralAtendimentoEvento.visualizado = false;
                        }
                      }
                    }
                  });
                }

                return resolver(centralAtendimentoEventoRetorno);
              }
            );
          } else if (evento.acao == "receber_status") {
            this.canaisComunicacaoWhatsAppService.getStatus().subscribe(
              canalComunicacaoWhatsAppStatus => this.canaisComunicacaoWhatsAppStatus = canalComunicacaoWhatsAppStatus,
              error => { console.log("Erro: " + error) },
              () => {
                this.statusEmitter.emit(this.canaisComunicacaoWhatsAppStatus);

                return resolver(centralAtendimentoEventoRetorno);
              }
            );
          } else {
            return resolver(centralAtendimentoEventoRetorno);
          }

          break;
        default:
          centralAtendimentoEventoRetorno.eventoExecutado = false;

          return resolver(centralAtendimentoEventoRetorno);
      }
    });

    return retorno;
  }

  emitirEvento(evento: CentralAtendimentoEvento) {
    evento.id = this.centralAtendimentoEventos.length + 1;
    evento.data_hora = new Date();

    this.centralAtendimentoEventos.push(evento);

    this.eventosEmitter.emit(this.centralAtendimentoEventos);
  }

  removerEventos() {
    this.centralAtendimentoEventos = this.centralAtendimentoEventos.filter(centralAtendimentoEvento => centralAtendimentoEvento.mensagem_contador > 0);

    this.eventosEmitter.emit(this.centralAtendimentoEventos);
  }

  /* Telefonia */

  receberEventosTelefonia() {
    this.canaisComunicacaoTelefoniaService.eventosEmitter.subscribe(
      canaisComunicacaoTelefoniaEvento => {
        if (canaisComunicacaoTelefoniaEvento != null) {
          this.canaisComunicacaoTelefoniaStatus = true;

          switch (canaisComunicacaoTelefoniaEvento.acao) {
            case "chamada_encerrada":
              this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Chamada encerrada:", 0, false, true, false, false, false, canaisComunicacaoTelefoniaEvento.identificacao, "", "", null, "", 0));

              return;
            case "chamada_em_andamento":
              this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Chamada em andamento:", 0, false, true, false, false, false, canaisComunicacaoTelefoniaEvento.identificacao, "", "", null, "", 0));

              return;
            case "deslogado":
              let tipo_softphone: number = parseInt(localStorage.getItem("tipo_softphone"));

              switch (tipo_softphone) {
                case 1:
                  /* Communicator */

                  this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Softphone deslogado...", 0, false, true, false, false, false, "", "", "", null, "", 0));

                  break;
                case 2:
                  /* Bria */

                  this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Softphone deslogado...", 0, false, true, false, false, false, "", "", "", null, "", 0));

                  break;
                case 3:
                  /* L5 */

                  this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Softphone deslogado...", 0, false, true, false, false, false, "", "", "", null, "", 0));

                  break;
                default:

              }

              this.canaisComunicacaoTelefoniaStatus = false;

              return;
            case "discando":
              if (!this.router.isActive("call-center-ligacao", false)) {
                if (canaisComunicacaoTelefoniaEvento.codigo_no_erp_do_cliente != "") {
                  this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Discando para:", 0, false, true, false, false, true, canaisComunicacaoTelefoniaEvento.identificacao, canaisComunicacaoTelefoniaEvento.codigo_no_erp_do_cliente, "", null, "", 0));
                } else {
                  this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Discando para:", 0, false, true, false, false, false, canaisComunicacaoTelefoniaEvento.identificacao, "", "", null, "", 0));
                }
              } else {
                this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Discando para:", 0, false, true, false, false, false, canaisComunicacaoTelefoniaEvento.identificacao, "", "", null, "", 0));
              }

              return;
            case "logado":
              this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Softphone logado com sucesso...", 0, false, true, false, false, false, "", "", "", null, "", 0));

              return;
            case "recebendo":
              this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Recebendo chamada:", 0, false, true, false, true, false, canaisComunicacaoTelefoniaEvento.identificacao, canaisComunicacaoTelefoniaEvento.codigo_no_erp_do_cliente, "", null, "", 0));

              return;
            case "softphone_indisponivel":
              this.adicionarEvento(new CentralAtendimentoEvento("telefonia", "", "Chamadas", "Softphone indisponível...", 0, false, true, true, false, false, "", "", "", null, "", 0));

              this.canaisComunicacaoTelefoniaStatus = false;

              return;
            default:

          }
        }
      }
    );
  }
}
