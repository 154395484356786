import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../../core/tools/animacao';
import { timer, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../../../core/services/call-center/call-center.service';
import { DashboardCallCenterPainelGerencialService } from '../../../core/services/dashboard/call-center-painel-gerencial/dashboard-call-center-painel-gerencial.service';
import { VendedorService } from '../../../core/services/vendedores/vendedor.service';

/* Models */

import { CallCenterPausa } from '../../../core/models/call-center/call-center-pausa';
import { CallCenterPedido } from '../../../core/models/call-center/call-center-pedido';
import { DashboardCallCenterPainelGerencialLigacao } from '../../../core/models/dashboard/call-center-painel-gerencial/dashboard-call-center-painel-gerencial-ligacao';
import { DashboardCallCenterPainelGerencialStatus } from '../../../core/models/dashboard/call-center-painel-gerencial/dashboard-call-center-painel-gerencial-status';

@Component({
  selector: 'app-dashboard-call-center-painel-gerencial',
  templateUrl: './dashboard-call-center-painel-gerencial.component.html',
  styleUrls: ['./dashboard-call-center-painel-gerencial.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class DashboardCallCenterPainelGerencialComponent implements OnInit {
  /* Ligações */

  vendedoresLigacoes: DashboardCallCenterPainelGerencialLigacao[] = [];

  /* Pausa */

  pausas: CallCenterPausa[] = [];

  vendedorPausas: CallCenterPausa[] = [];

  /* Pedidos */

  pedidos: CallCenterPedido[] = [];

  /* Status */

  vendedoresStatus: DashboardCallCenterPainelGerencialStatus[] = [];

  /* Tempo */

  tempoAtualizacao = new Subscription();

  /* Acompanhamento */

  /* Agendado */

  qtdeTotalAgendado: number = 0;
  qtdeTotalAgendadoRealizado: number = 0;
  porcentagemQtdeTotalAgendadoRealizado: number = 0;

  /* Gerado */

  qtdeTotalGerado: number = 0;
  qtdeTotalGeradoRealizado: number = 0;
  porcentagemQtdeTotalGeradoRealizado: number = 0;

  /* Manual */

  qtdeTotalManual: number = 0;

  qtdeTotalRealizado: number = 0;

  qtdeTotalPausa: number = 0;

  qtdeVendaRealizada: number = 0;
  porcentagemQtdeVendaRealizada: number = 0;
  qtdeNaoComprou: number = 0;
  porcentagemQtdeNaoComprou: number = 0;

  vendasExternas: number = 0;
  litragemExterna: number = 0;
  porcentagemVendasExternas: number = 0;

  vendasInternas: number = 0;
  litragemInterna: number = 0;
  porcentagemVendasInternas: number = 0;

  vendasTotal: number = 0;
  litragemTotal: number = 0;

  /* Data/Hora */

  dataHora: Date = null;

  /* Storage */

  guidclientenapis: string = localStorage.getItem("guidclientenapis");

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private dashboardCallCenterPainelGerencialService: DashboardCallCenterPainelGerencialService,
    private vendedorService: VendedorService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();

    this.pararTempo();
  }

  ngOnInit() {
    this.iniciarTempo();
  }

  /* Dados */

  calcularDados() {
    if (this.vendedoresLigacoes != null) {
      /* Acompanhamento */

      /* Agendado */

      let agendado: DashboardCallCenterPainelGerencialLigacao[] = this.vendedoresLigacoes.filter(vendedorLigacao => !vendedorLigacao.manual && !vendedorLigacao.lista);

      this.qtdeTotalAgendado = agendado.length;
      this.qtdeTotalAgendadoRealizado = agendado.filter(vendedorLigacao => vendedorLigacao.id_status != 1 && vendedorLigacao.id_status != 6 && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null).length;

      /* Gerado */

      let gerado: DashboardCallCenterPainelGerencialLigacao[] = this.vendedoresLigacoes.filter(vendedorLigacao => !vendedorLigacao.manual && vendedorLigacao.lista);

      this.qtdeTotalGerado = gerado.length;
      this.qtdeTotalGeradoRealizado = gerado.filter(vendedorLigacao => vendedorLigacao.id_status != 1 && vendedorLigacao.id_status != 6 && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null).length;

      /* Manual */

      let manual: DashboardCallCenterPainelGerencialLigacao[] = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.manual && !vendedorLigacao.lista && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null);

      this.qtdeTotalManual = manual.length;

      this.qtdeTotalRealizado = this.qtdeTotalAgendadoRealizado + this.qtdeTotalGeradoRealizado + this.qtdeTotalManual;

      this.qtdeTotalPausa = this.pausas.length;

      if (this.qtdeTotalAgendadoRealizado > 0) {
        this.porcentagemQtdeTotalAgendadoRealizado = ((this.qtdeTotalAgendadoRealizado / agendado.length) * 100);
      }

      if (this.qtdeTotalGeradoRealizado > 0) {
        this.porcentagemQtdeTotalGeradoRealizado = ((this.qtdeTotalGeradoRealizado / gerado.length) * 100);
      }

      this.qtdeVendaRealizada = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_status == 4 && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null).length;

      if (this.qtdeVendaRealizada > 0) {
        this.porcentagemQtdeVendaRealizada = ((this.qtdeVendaRealizada / this.qtdeTotalRealizado) * 100);
      }

      this.qtdeNaoComprou = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_status != 1 && vendedorLigacao.id_status != 4 && vendedorLigacao.id_status != 6 && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null).length;

      if (this.qtdeNaoComprou > 0) {
        this.porcentagemQtdeNaoComprou = ((this.qtdeNaoComprou / this.qtdeTotalRealizado) * 100);
      }

      this.vendasTotal = this.pedidos.reduce((sum, current) => sum + current.total_pedido, 0);
      this.litragemTotal = this.pedidos.reduce((sum, current) => sum + current.total_litragem, 0);

      this.vendasExternas = this.pedidos.filter(pedido => pedido.master_seller_id == null).reduce((sum, current) => sum + current.total_pedido, 0);
      this.litragemExterna = this.pedidos.filter(pedido => pedido.master_seller_id == null).reduce((sum, current) => sum + current.total_litragem, 0);

      if (this.vendasTotal > 0) {
        this.porcentagemVendasExternas = ((this.vendasExternas / this.vendasTotal) * 100);
      }

      this.vendasInternas = this.pedidos.filter(pedido => pedido.master_seller_id != null).reduce((sum, current) => sum + current.total_pedido, 0);
      this.litragemInterna = this.pedidos.filter(pedido => pedido.master_seller_id != null).reduce((sum, current) => sum + current.total_litragem, 0);

      if (this.vendasTotal > 0) {
        this.porcentagemVendasInternas = ((this.vendasInternas / this.vendasTotal) * 100);
      }

      this.vendedoresStatus.forEach((item) => {
        /* Agendado */

        let agendadoVendedor: DashboardCallCenterPainelGerencialLigacao[] = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && !vendedorLigacao.manual && !vendedorLigacao.lista);

        item.qtde_agendado = agendadoVendedor.length;
        item.qtde_realizado_agendado = agendadoVendedor.filter(vendedorLigacao => vendedorLigacao.id_status != 1 && vendedorLigacao.id_status != 6 && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null).length;

        /* Gerado */

        let geradoVendedor: DashboardCallCenterPainelGerencialLigacao[] = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && !vendedorLigacao.manual && vendedorLigacao.lista);

        item.qtde_gerado = geradoVendedor.length;
        item.qtde_realizado_gerado = geradoVendedor.filter(vendedorLigacao => vendedorLigacao.id_status != 1 && vendedorLigacao.id_status != 6 && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null).length;

        /* Manual */

        let manualVendedor: DashboardCallCenterPainelGerencialLigacao[] = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && vendedorLigacao.manual && !vendedorLigacao.lista && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null);

        item.qtde_manual = manualVendedor.length;

        item.qtde_total_realizado = item.qtde_realizado_agendado + item.qtde_realizado_gerado + item.qtde_manual;

        item.porcentagem_realizado_agendado = item.qtde_realizado_agendado > 0 ? ((item.qtde_realizado_agendado / item.qtde_total_realizado) * 100) : 0;
        item.porcentagem_realizado_gerado = item.qtde_realizado_gerado > 0 ? ((item.qtde_realizado_gerado / item.qtde_total_realizado) * 100) : 0;

        item.qtde_venda_realizada = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && vendedorLigacao.id_status == 4 && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null).length;
        item.porcentagem_venda_realizada = item.qtde_total_realizado > 0 ? ((item.qtde_venda_realizada / item.qtde_total_realizado) * 100) : 0;

        item.vendas_total = this.pedidos.filter(pedido => pedido.master_seller_id == item.id).reduce((sum, current) => sum + current.total_pedido, 0);
        item.litragem_total = this.pedidos.filter(pedido => pedido.master_seller_id == item.id).reduce((sum, current) => sum + current.total_litragem, 0);

        item.qtde_nao_comprou = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && vendedorLigacao.id_status != 1 && vendedorLigacao.id_status != 4 && vendedorLigacao.id_status != 6 && vendedorLigacao.check_in != null && vendedorLigacao.check_out != null).length;
        item.porcentagem_nao_comprou = item.qtde_total_realizado > 0 ? ((item.qtde_nao_comprou / item.qtde_total_realizado) * 100) : 0;

        let vendedorPausas = this.pausas.filter(vendedorPausa => vendedorPausa.seller_id == item.id);

        item.qtde_pausa = vendedorPausas.length;

        if (vendedorPausas.length > 0) {
          let dataHoraAtual: Date = new Date();

          let tempoPausa: number = 0;

          vendedorPausas.forEach((subItem) => {
            subItem.data_hora_final = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " " + subItem.hr_final_pausa);
            subItem.data_hora_inicial = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " " + subItem.hr_inicial_pausa);

            tempoPausa = tempoPausa + Math.abs(subItem.data_hora_final.getTime() - subItem.data_hora_inicial.getTime());

            if (dataHoraAtual >= subItem.data_hora_inicial && dataHoraAtual <= subItem.data_hora_final) {
              item.pausado = true;
            } else {
              item.pausado = false;
            }
          });

          tempoPausa = tempoPausa / vendedorPausas.length;

          let horasRestantes: number = Math.floor((tempoPausa / (1000 * 60 * 60)) % 24);
          let minutosRestantes: number = Math.floor((tempoPausa / (1000 * 60)) % 60);
          let segundosRestantes: number = Math.floor((tempoPausa / (1000)) % 60);

          item.tempo_medio_pausa = horasRestantes.toString().padStart(2, "0") + ":" + minutosRestantes.toString().padStart(2, "0") + ":" + segundosRestantes.toString().padStart(2, "0");
        } else {
          item.tempo_medio_pausa = "00:00:00";
        }

        let vendedorLigacoesComCheckOut = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && vendedorLigacao.check_out != null);

        if (vendedorLigacoesComCheckOut.length > 0) {
          let tempoEmAtendimento: number = 0;

          vendedorLigacoesComCheckOut.forEach((vendedorLigacao) => {
            let dataHoraInicial: Date = new Date(vendedorLigacao.check_in);
            let dataHoraFinal: Date = new Date(vendedorLigacao.check_out);

            tempoEmAtendimento = tempoEmAtendimento + Math.abs(dataHoraInicial.getTime() - dataHoraFinal.getTime());
          });

          let horasEmAtendimento: number = Math.floor((tempoEmAtendimento / (1000 * 60 * 60)) % 24);
          let minutosEmAtendimento: number = Math.floor((tempoEmAtendimento / (1000 * 60)) % 60);
          let segundosEmAtendimento: number = Math.floor((tempoEmAtendimento / (1000)) % 60);

          item.tempo_em_atendimento = horasEmAtendimento.toString().padStart(2, "0") + ":" + minutosEmAtendimento.toString().padStart(2, "0") + ":" + segundosEmAtendimento.toString().padStart(2, "0");

          if (this.qtdeTotalRealizado > 0) {
            let tempoMedioEmAtendimento = tempoEmAtendimento / item.qtde_total_realizado;

            let horasMedioEmAtendimento: number = Math.floor((tempoMedioEmAtendimento / (1000 * 60 * 60)) % 24);
            let minutosMedioEmAtendimento: number = Math.floor((tempoMedioEmAtendimento / (1000 * 60)) % 60);
            let segundosMedioEmAtendimento: number = Math.floor((tempoMedioEmAtendimento / (1000)) % 60);

            item.tempo_medio_em_atendimento = horasMedioEmAtendimento.toString().padStart(2, "0") + ":" + minutosMedioEmAtendimento.toString().padStart(2, "0") + ":" + segundosMedioEmAtendimento.toString().padStart(2, "0");
          } else {
            item.tempo_medio_em_atendimento = "00:00:00";
          }
        } else {
          item.tempo_em_atendimento = "00:00:00";
          item.tempo_medio_em_atendimento = "00:00:00";
        }

        item.qtde_email = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && vendedorLigacao.tipo == "E").length;

        item.qtde_feita = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && vendedorLigacao.check_in != null && vendedorLigacao.tipo == "F").length;
        item.porcentagem_feita = item.qtde_feita > 0 ? ((item.qtde_feita / item.qtde_total_realizado) * 100) : 0;

        item.qtde_recebida = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && vendedorLigacao.tipo == "R").length;
        item.porcentagem_recebida = item.qtde_recebida > 0 ? ((item.qtde_recebida / item.qtde_total_realizado) * 100) : 0;

        item.qtde_whatsapp = this.vendedoresLigacoes.filter(vendedorLigacao => vendedorLigacao.id_tmk == item.id && vendedorLigacao.tipo == "W").length;
        item.porcentagem_whatsapp = item.qtde_whatsapp > 0 ? ((item.qtde_whatsapp / item.qtde_total_realizado) * 100) : 0;
      });

      if (this.guidclientenapis == "fe4f98c8-2cb7-4c0c-9ac3-39269c547e50") {
        /* Lubexx */

        this.vendedoresStatus.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a["porcentagem_venda_realizada"], b["porcentagem_venda_realizada"]) * -1; /* Decrescente */
        });
      } else {
        this.vendedoresStatus.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a["porcentagem_realizado_agendado"], b["porcentagem_realizado_agendado"]) * -1; /* Decrescente */
        });
      }
    }
  }

  carregarDados() {
    /* Pausas */

    this.callCenterService.getPausaRealizadaHojeByUsuario().subscribe(
      pausa => this.pausas = pausa,
      error => { console.log("Erro: " + error) },
      () => {
        /* Ligações */

        this.dashboardCallCenterPainelGerencialService.getLigacaoByUsuario().subscribe(
          vendedoresLigacoes => this.vendedoresLigacoes = vendedoresLigacoes,
          error => { console.log("Erro: " + error) },
          () => {
            /* Pedidos */

            this.callCenterService.getPedidoDiarioByGuidClienteNapis().subscribe(
              pedido => this.pedidos = pedido,
              error => { console.log("Erro: " + error) },
              () => {
                /* Status */

                this.dashboardCallCenterPainelGerencialService.getStatusByUsuario().subscribe(
                  vendedoresStatus => this.vendedoresStatus = vendedoresStatus,
                  error => { console.log("Erro: " + error) },
                  () => {
                    if (this.vendedoresStatus.length > 0) {
                      this.vendedoresStatus.forEach((item) => {
                        if (item.minutos_pausa != null) {
                          if (item.minutos_pausa > 1440) {
                            item.minutos_pausa_texto = "(+1 DIA)";
                          } else if (item.minutos_pausa > 1 && item.minutos_pausa < 60) {
                            item.minutos_pausa_texto = "(" + item.minutos_pausa + "M)";
                          } else if (item.minutos_pausa <= 1) {
                            item.minutos_pausa_texto = "(1M)";
                          } else {
                            let qtdeHoras: string = (item.minutos_pausa / 60).toFixed(0);

                            item.minutos_pausa_texto = "(" + qtdeHoras + "H)";
                          }
                        }

                        if (item.seller_foto == null || item.seller_foto == "") {
                          item.seller_foto = "assets/perfil/perfil_sem_foto.png";
                        }
                      });

                      /* Data/Hora */

                      this.dataHora = new Date();

                      this.calcularDados();
                    }
                  }
                );
              }
            );
          }
        );
      }
    );
  }

  /* Ações */

  alterarCallCenterDiscadorAutomatico(seller_id: number, call_Center_discador_automatico: boolean) {
    let alterarCallCenterDiscadorAutomatico: boolean = false;

    this.vendedorService.updateCallCenterDiscadorAutomaticoByVendedor(seller_id, call_Center_discador_automatico).subscribe(
      alterarCallCenterDiscadoresAutomaticos => alterarCallCenterDiscadorAutomatico = alterarCallCenterDiscadoresAutomaticos,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarCallCenterDiscadorAutomatico) {
          let vendedorStatus = this.vendedoresStatus.find(vendedoresStatus => vendedoresStatus.id == seller_id);

          if (vendedorStatus != null) {
            if (call_Center_discador_automatico) {
              vendedorStatus.call_center_discador_automatico = true;
            } else {
              vendedorStatus.call_center_discador_automatico = false;
            }
          }

          if (call_Center_discador_automatico) {
            this.toastr.success("", "Discador Automático iniciado!");
          } else {
            this.toastr.success("", "Discador Manual iniciado!");
          }
        }
      }
    );
  }

  atualizar() {
    this.pararTempo();
    this.iniciarTempo();
  }

  iniciarTempo() {
    this.tempoAtualizacao = timer(0, 60000).subscribe(() => { /* Início / Intervalo */
      this.carregarDados();
    });
  }

  pararTempo() {
    this.tempoAtualizacao.unsubscribe();
  }

  /* Modal */

  modalLegendas(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-legendas",
      size: "lg",
      backdrop: "static"
    });
  }

  modalPausasRealizadas(content: any, seller_id: number) {
    this.vendedorPausas = this.pausas.filter(pausa => pausa.seller_id == seller_id);

    if (this.vendedorPausas.length > 0) {
      this.modalService.open(content, {
        ariaLabelledBy: "titulo-pausas-realizadas",
        size: "lg",
        backdrop: "static"
      });
    } else {
      this.toastr.error("", "Nenhuma pausa encontrada!");
    }
  }
}
