import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Animacao } from '../../core/tools/animacao';
import { Subscription, timer } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import MicRecorder from 'mic-recorder-to-mp3';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { CentralAtendimentoService } from '../../core/services/central-atendimento/central-atendimento.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { CallCenterWhatsAppWebHook } from '../../core/models/call-center/call-center-whatsapp-webhook';
import { CanaisComunicacaoWhatsAppChat } from '../../core/models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-chat';
import { CanaisComunicacaoWhatsAppChatAudio } from '../../core/models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-chat-audio';
import { CanaisComunicacaoWhatsAppChatDocumento } from '../../core/models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-chat-documento';
import { CanaisComunicacaoWhatsAppChatImagem } from '../../core/models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-chat-imagem';
import { CanaisComunicacaoWhatsAppChatSticker } from '../../core/models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-chat-sticker';
import { CanaisComunicacaoWhatsAppChatTexto } from '../../core/models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-chat-texto';
import { CanaisComunicacaoWhatsAppStatus } from '../../core/models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-status';
import { CentralAtendimentoEvento } from '../../core/models/central-atendimento/central-atendimento-evento';
import { CentralAtendimentoEventoRetorno } from '../../core/models/central-atendimento/central-atendimento-evento-retorno';
import { Cliente } from '../../core/models/clientes/cliente';
import { ConversaoService } from '../../core/tools/conversao.service';
import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-central-atendimento',
  templateUrl: './central-atendimento.component.html',
  styleUrls: ['./central-atendimento.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CentralAtendimentoComponent implements OnInit {
  maximizar: boolean = true;

  /* Canais de Comunicação (WhatsApp) */

  canaisComunicacaoWhatsAppChat: CanaisComunicacaoWhatsAppChat[] = [];

  canaisComunicacaoWhatsAppChatImagemSelecionada: CanaisComunicacaoWhatsAppChatImagem = null;

  canaisComunicacaoWhatsAppStatus: CanaisComunicacaoWhatsAppStatus = null;

  canaisComunicacaoWhatsAppChatPrimeiraVisualizacao: boolean = false;

  /* Central de Atendimento (Eventos) */

  centralAtendimentoEventos: CentralAtendimentoEvento[] = [];

  centralAtendimentoEventosVisiveis: CentralAtendimentoEvento[] = [];
  centralAtendimentoEventosVisiveisOriginal: CentralAtendimentoEvento[] = [];

  qtdeEventosNaoVisualizados: number = 0;

  qtdeEventosNaoVisualizadosEmail: number = 0;
  qtdeEventosNaoVisualizadosFacebook: number = 0;
  qtdeEventosNaoVisualizadosTelefonia: number = 0;
  qtdeEventosNaoVisualizadosWhatsApp: number = 0;

  /* Central de Atendimento (Reposicionamento) */

  mousePressionado: boolean = false;
  novaPosicao: number[] = [0, 0];

  /* Chat */

  chatCarregado: boolean = true;

  chatMensagem: string = "";

  exibirAnexar: boolean = false;
  exibirAudio: boolean = false;
  exibirChat: boolean = false;

  verificarChatAberto = new Subscription();
  verificarMensagemRecebida = new Subscription();

  /* Chat - Arquivo */

  arquivoTipo: string = "";

  /* Chat - Áudio */

  audioBlob: Blob = null;
  audioURL: string = "";

  recorder = new MicRecorder({
    bitRate: 128
  });

  gravandoAudio: boolean = false;

  /* Cliente (Selecionado) */

  clienteSelecionado: Cliente = null;

  /* Número (Selecionado) */

  numeroSelecionado: string = "";

  /* Vendedores */

  vendedores: Vendedor[] = [];

  vendedorInvalido: boolean = false;

  vendedorSelecionado: Vendedor = null;

  /* Storage */

  central_atendimento_estilo: string = localStorage.getItem("central_atendimento_estilo") == "0" ? "lateral" : "movel";

  usa_email: boolean = false;
  usa_facebook: boolean = false;
  usa_pabx: boolean = (localStorage.getItem("usa_pabx") == "true");
  usa_whatsapp: boolean = (localStorage.getItem("usa_whatsapp") == "true");
  token_innersac: string = localStorage.getItem("token_innersac");
  link_innersac: string = localStorage.getItem("link_innersac");

  /* Central de Atendimento */

  @ViewChild("centralAtendimento", { static: false }) centralAtendimento: ElementRef;
  @ViewChild("centralAtendimentoEvento", { static: false }) centralAtendimentoEvento: ElementRef;
  @ViewChild("centralAtendimentoTitulo", { static: false }) centralAtendimentoTitulo: ElementRef;
  @ViewChild("centralAtendimentoTituloAbas", { static: false }) centralAtendimentoTituloAbas: ElementRef;

  /* Chat */

  @ViewChild("centralAtendimentoChat", { static: false }) centralAtendimentoChat: ElementRef;
  @ViewChild("centralAtendimentoChatConteudo", { static: false }) centralAtendimentoChatConteudo: ElementRef;

  /* Arquivo */

  @ViewChild("arquivo", { static: false }) arquivo: ElementRef;

  @HostListener("window:paste", ["$event"])
  colarArquivo(evento: ClipboardEvent) {
    if (this.exibirChat && this.arquivo != null) {
      if (evento.clipboardData.items.length > 0) {
        if (evento.clipboardData.items[0].kind == "file") {
          let arquivoColado = evento.clipboardData.items[0].getAsFile();

          let transferencia = new DataTransfer();

          transferencia.items.add(arquivoColado);

          let lista = transferencia.files;

          this.arquivo.nativeElement.files = lista;

          switch (this.arquivo.nativeElement.files[0].type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "application/pdf":
            case "text/plain":
              this.arquivoTipo = "documento";

              break;
            case "image/bmp":
            case "image/jpeg":
            case "image/png":
              this.arquivoTipo = "imagem";

              break;
            case "image/webp":
              this.arquivoTipo = "sticker";

              break;
            default:

          }
        }
      }
    }
  }

  constructor(
    private router: Router,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private centralAtendimentoService: CentralAtendimentoService,
    private conversaoService: ConversaoService,
    private clienteService: ClienteService,
    private vendedorService: VendedorService
  ) { }

  ngAfterViewInit() {
    /* Central de Atendimento */

    this.posicaoPadraoCentralAtendimento();
  }

  ngOnDestroy() {
    this.modalService.dismissAll();

    this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("telefonia", "desconectar", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));

    this.centralAtendimentoService.removerEventos();

    this.verificarChatAberto.unsubscribe();
    this.verificarMensagemRecebida.unsubscribe();
  }

  ngOnInit() {
    /* Emmiter */

    this.receberEventos();
    this.receberMaximizarMaximizar();

    /* Telefonia */

    if (this.usa_pabx) {
      this.telefoniaConectar();
    }

    /* WhatsApp */

    if (this.usa_whatsapp) {
      this.receberChat();
      this.receberStatus();

      this.whatsAppReceberMensagem();

      this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "receber_status", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));
    }
  }

  /* Ações */

  alterarMinimizarMaximizar() {
    this.maximizar = !this.maximizar;

    this.centralAtendimentoService.maximizarEmitter.emit(this.maximizar);
  }

  minimizarMaximizar() {
    setTimeout(() => {
      if (this.maximizar) {
        this.verificarPosicaoCentralAtendimento();
      } else {
        if (this.central_atendimento_estilo == "movel") {
          this.posicaoPadraoCentralAtendimento();
        }
      }

      this.verificarPosicaoChat(true);
    }, 400);
  }

  /* Reposicionamento */

  /* Central de Atendimento */

  onMouseDownCentralAtendimento(evento: any) {
    this.mousePressionado = true;

    this.novaPosicao = [
      this.centralAtendimento.nativeElement.offsetLeft - evento.clientX,
      this.centralAtendimento.nativeElement.offsetTop - evento.clientY
    ];
  }

  onMouseLeaveCentralAtendimento() {
    if (this.mousePressionado) {
      this.mousePressionado = false;

      this.verificarPosicaoCentralAtendimento();
    }
  }

  onMouseMoveCentralAtendimento(evento: any) {
    if (this.mousePressionado) {
      this.centralAtendimento.nativeElement.style.left = (evento.clientX + this.novaPosicao[0]) + "px";
      this.centralAtendimento.nativeElement.style.top = (evento.clientY + this.novaPosicao[1]) + "px";
    }
  }

  onMouseUpCentralAtendimento() {
    this.mousePressionado = false;

    this.verificarPosicaoCentralAtendimento();
  }

  posicaoPadraoCentralAtendimento() {
    if (this.central_atendimento_estilo == "movel") {
      /* Altura */

      this.centralAtendimento.nativeElement.style.top = ((window.innerHeight - this.centralAtendimento.nativeElement.offsetHeight) - 2) + "px";

      /* Largura */

      this.centralAtendimento.nativeElement.style.left = "2px";
    } else {
      /* Altura */

      this.centralAtendimento.nativeElement.style.height = window.innerHeight - this.centralAtendimento.nativeElement.offsetTop + "px";

      /* Altura (Eventos) */

      this.centralAtendimentoEvento.nativeElement.style.height = ((((window.innerHeight - this.centralAtendimentoTitulo.nativeElement.offsetHeight) - this.centralAtendimentoTituloAbas.nativeElement.offsetHeight) - this.centralAtendimento.nativeElement.offsetTop) - 4) + "px";
    }
  }

  verificarPosicaoCentralAtendimento() {
    if (this.central_atendimento_estilo == "movel") {
      /* Altura */

      if (this.centralAtendimento.nativeElement.offsetTop < 0) {
        this.centralAtendimento.nativeElement.style.top = "2px";
      }

      if ((this.centralAtendimento.nativeElement.offsetTop + this.centralAtendimento.nativeElement.offsetHeight) > window.innerHeight) {
        this.centralAtendimento.nativeElement.style.top = ((window.innerHeight - this.centralAtendimento.nativeElement.offsetHeight) - 2) + "px";
      }

      /* Largura */

      if (this.centralAtendimento.nativeElement.offsetLeft < 0) {
        this.centralAtendimento.nativeElement.style.left = "2px";
      }

      if ((this.centralAtendimento.nativeElement.offsetLeft + this.centralAtendimento.nativeElement.offsetWidth) > window.innerWidth) {
        this.centralAtendimento.nativeElement.style.left = ((window.innerWidth - this.centralAtendimento.nativeElement.offsetWidth) - 66) + "px";
      }
    }
  }

  /* Chat */

  fecharChat() {
    this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "fechar_chat", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));

    this.verificarChatAberto.unsubscribe();

    this.chatCarregado = true;

    this.exibirChat = false;
  }

  onMouseDownChat(evento: any) {
    this.mousePressionado = true;

    this.novaPosicao = [
      this.centralAtendimentoChat.nativeElement.offsetLeft - evento.clientX,
      this.centralAtendimentoChat.nativeElement.offsetTop - evento.clientY
    ];
  }

  onMouseLeaveChat() {
    if (this.mousePressionado) {
      this.mousePressionado = false;

      this.verificarPosicaoChat(false);
    }
  }

  onMouseMoveChat(evento: any) {
    if (this.mousePressionado) {
      this.centralAtendimentoChat.nativeElement.style.left = (evento.clientX + this.novaPosicao[0]) + "px";
      this.centralAtendimentoChat.nativeElement.style.top = (evento.clientY + this.novaPosicao[1]) + "px";
    }
  }

  onMouseUpChat() {
    this.mousePressionado = false;

    this.verificarPosicaoChat(false);
  }

  onScroll(evento: any) {
    if (evento.target.scrollTop == 0) {

    }
  }

  posicaoPadraoChat() {
    /* Altura */

    this.centralAtendimentoChat.nativeElement.style.top = ((window.innerHeight - this.centralAtendimentoChat.nativeElement.offsetHeight) - 2) + "px";

    /* Largura */

    if (this.central_atendimento_estilo == "movel") {
      this.centralAtendimentoChat.nativeElement.style.left = "568px";
    } else {
      if (this.maximizar) {
        this.centralAtendimentoChat.nativeElement.style.left = "302px";
      } else {
        this.centralAtendimentoChat.nativeElement.style.left = "62px";
      }
    }
  }

  verificarPosicaoChat(maximizar: boolean) {
    if (this.exibirChat) {
      /* Altura */

      if (this.centralAtendimentoChat.nativeElement.offsetTop < 0) {
        this.centralAtendimentoChat.nativeElement.style.top = "2px";
      }

      if ((this.centralAtendimentoChat.nativeElement.offsetTop + this.centralAtendimentoChat.nativeElement.offsetHeight) > window.innerHeight) {
        this.centralAtendimentoChat.nativeElement.style.top = ((window.innerHeight - this.centralAtendimentoChat.nativeElement.offsetHeight) - 2) + "px";
      }

      /* Largura */

      if (this.centralAtendimentoChat.nativeElement.offsetLeft < 0 || maximizar) {
        if (this.central_atendimento_estilo == "movel") {
          this.centralAtendimentoChat.nativeElement.style.left = "568px";
        } else {
          if (this.maximizar) {
            this.centralAtendimentoChat.nativeElement.style.left = "302px";
          } else {
            this.centralAtendimentoChat.nativeElement.style.left = "62px";
          }
        }
      }

      if ((this.centralAtendimentoChat.nativeElement.offsetLeft + this.centralAtendimentoChat.nativeElement.offsetWidth) > window.innerWidth) {
        this.centralAtendimentoChat.nativeElement.style.left = ((window.innerWidth - this.centralAtendimentoChat.nativeElement.offsetWidth) - 66) + "px";
      }
    }
  }

  /* Chat - Anexar */

  cancelarAnexar() {
    this.exibirAnexar = false;

    setTimeout(() => {
      this.centralAtendimentoChatConteudo.nativeElement.scrollTop = this.centralAtendimentoChatConteudo.nativeElement.scrollHeight;
    }, 400);
  }

  iniciarAnexar() {
    if (this.chatCarregado && !this.exibirAudio && this.arquivo.nativeElement.files.length == 0) {
      this.exibirAnexar = true;
    }
  }

  /* Chat - Arquivo */

  iniciarArquivo(tipo: string) {
    this.arquivoTipo = tipo;

    switch (this.arquivoTipo) {
      case "documento":
        this.arquivo.nativeElement.accept = [".doc", ".docx", ".pdf", ".txt", ".xls", ".xlsx"];

        break;
      case "imagem":
      case "sticker":
        this.arquivo.nativeElement.accept = [".bmp", ".jpg", ".jpeg", ".png"];

        break;
      default:

    }

    this.arquivo.nativeElement.click();
  }

  removerArquivo() {
    /* Chat - Arquivo */

    this.arquivoTipo = "";

    /* Arquivo */

    this.arquivo.nativeElement.value = "";

    setTimeout(() => {
      this.centralAtendimentoChatConteudo.nativeElement.scrollTop = this.centralAtendimentoChatConteudo.nativeElement.scrollHeight;
    }, 400);
  }

  /* Chat - Áudio */

  cancelarAudio() {
    this.exibirAudio = false;

    if (this.gravandoAudio) {
      this.pararAudioGravacao();
    } else {
      this.audioBlob = null;
      this.audioURL = "";
    }

    setTimeout(() => {
      this.centralAtendimentoChatConteudo.nativeElement.scrollTop = this.centralAtendimentoChatConteudo.nativeElement.scrollHeight;
    }, 400);
  }

  iniciarAudio() {
    if (this.chatCarregado && !this.exibirAnexar) {
      this.exibirAudio = true;
    }
  }

  iniciarAudioGravacao() {
    this.audioBlob = null;
    this.audioURL = "";

    this.recorder.start().then(() => {
      this.gravandoAudio = true;
    }).catch(() => {
      this.toastr.error("", "Houve um erro, contate o suporte...");
    });
  }

  pararAudioGravacao() {
    this.audioBlob = null;
    this.audioURL = "";

    if (this.gravandoAudio) {
      this.gravandoAudio = false;

      this.recorder.stop().getMp3().then(([buffer, blob]) => {
        const file = new File(buffer, "audio.mp3", {
          type: blob.type,
          lastModified: Date.now()
        });

        this.audioBlob = blob;
        this.audioURL = URL.createObjectURL(file);
      });
    }
  }

  /* Dados */

  /* Eventos */

  abrirEvento(id: number) {
    let centralAtendimentoEventoVisivel = this.centralAtendimentoEventosVisiveis.find(centralAtendimentoEvento => centralAtendimentoEvento.id == id);

    if (centralAtendimentoEventoVisivel != null) {
      switch (centralAtendimentoEventoVisivel.tipo) {
        case "whatsapp":
          if (this.chatCarregado) {
            this.chatCarregado = false;

            this.canaisComunicacaoWhatsAppChat = [];

            this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "receber_status", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));

            this.whatsAppReceberChat(centralAtendimentoEventoVisivel.numero_telefone, centralAtendimentoEventoVisivel.client_id_app);

            centralAtendimentoEventoVisivel.titulo = "";
            centralAtendimentoEventoVisivel.mensagem_contador = 0;
          } else {
            this.toastr.warning("", "Tente novamente em alguns instantes...");
          }

          break;
        default:

      }
    }
  }

  ajustarEventos() {
    this.centralAtendimentoEventosVisiveis = this.centralAtendimentoEventos.filter(centralAtendimentoEvento => centralAtendimentoEvento.exibir);

    this.centralAtendimentoEventosVisiveisOriginal = this.centralAtendimentoEventosVisiveis;

    let centralAtendimentoEventoEspecifico: CentralAtendimentoEvento = null;

    /* Iniciar - Chat */

    centralAtendimentoEventoEspecifico = this.centralAtendimentoEventosVisiveis.find(centralAtendimentoVisivel => centralAtendimentoVisivel.acao == "iniciar_chat" && !centralAtendimentoVisivel.visualizado);

    if (centralAtendimentoEventoEspecifico != null) {
      this.chatCarregado = true;

      centralAtendimentoEventoEspecifico.visualizado = true;

      this.abrirEvento(centralAtendimentoEventoEspecifico.id);
    }

    this.totalizarNaoVisualizados();
  }

  excluirEvento(id: number) {
    let centralAtendimentoEvento = this.centralAtendimentoEventos.find(centralAtendimentoEvento => centralAtendimentoEvento.id == id);

    if (centralAtendimentoEvento != null) {
      centralAtendimentoEvento.exibir = false;
      centralAtendimentoEvento.visualizado = true;

      this.ajustarEventos();
    }
  }

  limparEventos() {
    this.centralAtendimentoService.removerEventos();
  }

  totalizarNaoVisualizados() {
    this.qtdeEventosNaoVisualizados = this.centralAtendimentoEventosVisiveis.filter(centralAtendimentoEvento => !centralAtendimentoEvento.visualizado).length;

    this.qtdeEventosNaoVisualizadosEmail = this.centralAtendimentoEventosVisiveis.filter(centralAtendimentoEvento => !centralAtendimentoEvento.visualizado && centralAtendimentoEvento.tipo == "email").length;
    this.qtdeEventosNaoVisualizadosFacebook = this.centralAtendimentoEventosVisiveis.filter(centralAtendimentoEvento => !centralAtendimentoEvento.visualizado && centralAtendimentoEvento.tipo == "facebook").length;
    this.qtdeEventosNaoVisualizadosTelefonia = this.centralAtendimentoEventosVisiveis.filter(centralAtendimentoEvento => !centralAtendimentoEvento.visualizado && centralAtendimentoEvento.tipo == "telefonia").length;
    this.qtdeEventosNaoVisualizadosWhatsApp = this.centralAtendimentoEventosVisiveis.filter(centralAtendimentoEvento => !centralAtendimentoEvento.visualizado && centralAtendimentoEvento.tipo == "whatsapp").length;
  }

  /* Emmiter */

  receberChat() {
    this.centralAtendimentoService.chatEmitter.subscribe(
      canalComunicacaoWhatsAppChat => {
        this.chatCarregado = true;

        if (canalComunicacaoWhatsAppChat.length > 0) {
          let ultimaMensagemAtual: CanaisComunicacaoWhatsAppChat = null;
          let ultimaMensagemRecebida: CanaisComunicacaoWhatsAppChat = null;

          if (this.canaisComunicacaoWhatsAppChat.length > 0) {
            ultimaMensagemAtual = this.canaisComunicacaoWhatsAppChat[this.canaisComunicacaoWhatsAppChat.length - 1];
            ultimaMensagemRecebida = canalComunicacaoWhatsAppChat[canalComunicacaoWhatsAppChat.length - 1];

            if (ultimaMensagemAtual.data_hora.getTime() < ultimaMensagemRecebida.data_hora.getTime()) {
              this.canaisComunicacaoWhatsAppChat = canalComunicacaoWhatsAppChat;
            }
          } else {
            this.canaisComunicacaoWhatsAppChat = canalComunicacaoWhatsAppChat;
          }
        }

        if (this.canaisComunicacaoWhatsAppChatPrimeiraVisualizacao) {
          this.canaisComunicacaoWhatsAppChatPrimeiraVisualizacao = false;

          setTimeout(() => {
            this.centralAtendimentoChatConteudo.nativeElement.scrollTop = this.centralAtendimentoChatConteudo.nativeElement.scrollHeight;
          }, 400);
        }
      }
    );
  }

  receberEventos() {
    /* Central de Atendimento */

    this.centralAtendimentoService.eventosEmitter.subscribe(
      centralAtendimentoEvento => {
        this.centralAtendimentoEventos = centralAtendimentoEvento;

        this.centralAtendimentoEventos.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a["data_hora"], b["data_hora"]) * -1; /* Decrescente */
        });

        if (!this.maximizar) {
          this.maximizar = !this.maximizar;

          setTimeout(() => {
            this.centralAtendimentoService.maximizarEmitter.emit(this.maximizar);

            this.verificarPosicaoCentralAtendimento();
          }, 400);
        }

        this.ajustarEventos();
      }
    );
  }

  receberMaximizarMaximizar() {
    this.centralAtendimentoService.maximizarEmitter.subscribe(
      maximizar => {
        timer(400).subscribe(tempo => {
          this.maximizar = maximizar;

          this.minimizarMaximizar();
        });
      }
    );
  }

  receberStatus() {
    this.centralAtendimentoService.statusEmitter.subscribe(
      canalComunicacaoWhatsAppStatus => {
        this.canaisComunicacaoWhatsAppStatus = canalComunicacaoWhatsAppStatus;
      }
    );
  }

  visualizarEvento(visualizado: boolean, id: number) {
    if (!visualizado && this.maximizar) {
      let centralAtendimentoEventoVisivel = this.centralAtendimentoEventosVisiveis.find(centralAtendimentoEvento => centralAtendimentoEvento.id == id);

      if (centralAtendimentoEventoVisivel != null) {
        centralAtendimentoEventoVisivel.visualizado = true;

        this.totalizarNaoVisualizados();
      }
    }
  }

  /* Modal */

  modalCommunicatorSolucaoDeProblemas(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-communicator-solucao-de-problemas",
      size: "lg",
      backdrop: "static"
    });
  }

  modalWhatsAppImagens(content: any, canaisComunicacaoWhatsAppChatImagem: CanaisComunicacaoWhatsAppChatImagem) {
    this.canaisComunicacaoWhatsAppChatImagemSelecionada = canaisComunicacaoWhatsAppChatImagem;

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-whatsapp-imagens",
      size: "lg",
      backdrop: "static"
    });
  }

  modalWhatsAppTransferir(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-whatsapp-transferir",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Transferir") {
        if (this.vendedorSelecionado != null) {
          let callCenterWhatsAppWebHook = new CallCenterWhatsAppWebHook();

          if (this.clienteSelecionado != null) {
            /* Cliente Vinculado */

            callCenterWhatsAppWebHook.telefone = this.clienteSelecionado.phone;
          } else {
            /* Cliente não Vinculado */

            callCenterWhatsAppWebHook.telefone = this.numeroSelecionado;
          }

          callCenterWhatsAppWebHook.status = false;
          callCenterWhatsAppWebHook.id_tmk = this.vendedorSelecionado.id;

          if (this.clienteSelecionado != null) {
            /* Cliente Vinculado */

            callCenterWhatsAppWebHook.id_client = this.clienteSelecionado.id;
          }

          let adicionarCallCenterWhatsAppWebHook: number = 0;

          this.callCenterService.addWhatsAppWebHook(callCenterWhatsAppWebHook).subscribe(
            adicionarCallCenterWhatsAppWebHooks => adicionarCallCenterWhatsAppWebHook = adicionarCallCenterWhatsAppWebHooks,
            error => { console.log("Erro: " + error) },
            () => {
              if (adicionarCallCenterWhatsAppWebHook > 0) {
                this.toastr.success("", "Transferência realizada com sucesso!");
              }

              this.vendedorInvalido = false;

              this.vendedorSelecionado = null;
            }
          );
        } else {
          this.modalWhatsAppTransferir(content);

          this.vendedorInvalido = true;
        }
      } else {
        this.vendedorInvalido = false;
      }
    }, () => {
      this.vendedorInvalido = false;
    });
  }

  /* Vendedor(a) */

  selecionarVendedor(id_vendedor: string) {
    this.vendedorSelecionado = this.vendedores.find(vendedores => vendedores.id == parseInt(id_vendedor));

    if (this.vendedorSelecionado != null) {
      this.vendedorInvalido = false;
    } else {
      this.vendedorInvalido = true;
    } 
  }

  /* Tab Selecionada */

  alterarTabSelecionada(tab_selecionada: string) {
    if (tab_selecionada == "todos") {
      this.centralAtendimentoEventosVisiveis = this.centralAtendimentoEventosVisiveisOriginal;
    } else {
      this.centralAtendimentoEventosVisiveis = this.centralAtendimentoEventosVisiveisOriginal.filter(centralAtendimentoEventoVisivelOriginal => centralAtendimentoEventoVisivelOriginal.tipo == tab_selecionada);

      switch (tab_selecionada) {
        case "telefonia":
          this.router.navigate(["/call-center"]);

          break;
        case "whatsapp":
          if (this.token_innersac != "" && this.link_innersac != "") {
            this.router.navigate(["/whatsapp-innersac"]);
          }

          break;
        default:

      }
    }
  }

  /* Ações (Telefonia) */

  telefoniaAtender(codigo_no_erp_do_cliente: string) {
    if (!this.router.isActive("call-center-ligacao", false)) {
      this.router.navigate(["/call-center-nova-ligacao"], {
        queryParams: {
          cliente: codigo_no_erp_do_cliente,
          ligacao: true
        }
      });
    }

    this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("telefonia", "receber_chamada", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));
  }

  telefoniaConectar() {
    this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("telefonia", "conectar", "Chamadas", "Conectando...", 0, false, true, false, false, false, "", "", "", null, "", 0));
  }

  telefoniaRedirecionar(codigo_no_erp_do_cliente: string) {
    if (!this.router.isActive("call-center-ligacao", false)) {
      this.router.navigate(["/call-center-nova-ligacao"], {
        queryParams: {
          cliente: codigo_no_erp_do_cliente,
          ligacao: true
        }
      });
    }
  }

  telefoniaTentarNovamente() {
    this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("telefonia", "conectar", "Chamadas", "Conectando...", 0, false, true, false, false, false, "", "", "", null, "", 0));
  }

  /* Ações (WhatsApp) */

  iniciarWhatsAppTransferir(content: any) {
    if (this.vendedores.length > 0) {
      this.modalWhatsAppTransferir(content);
    } else {
      /* Vendedores */

      this.vendedorService.getByGuidClienteNapis().subscribe(
        vendedores => this.vendedores = vendedores,
        error => { console.log("Erro: " + error) },
        () => {
          this.modalWhatsAppTransferir(content);
        }
      );
    }
  }

  whatsAppAbrirDocumento(documentUrl: string) {
    window.open(documentUrl, "_blank");
  }

  whatsAppEnviarMensagem() {
    if (this.audioBlob != null) {
      /* Áudio */

      let tamanho: number = (this.audioBlob.size / (1024 * 1024));

      if (tamanho > 16) {
        this.toastr.error("", "Tamanho de áudio inválido!");

        return;
      } else {
        const blobParaBase64 = this.conversaoService.blobParaBase64(this.audioBlob);

        blobParaBase64.then((retorno) => {
          if (this.clienteSelecionado != null) {
            /* Cliente Vinculado */

            this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_audio", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.clienteSelecionado.phone, null, retorno, 0));
          } else if (this.numeroSelecionado != "") {
            /* Cliente não Vinculado */

            this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_audio", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.numeroSelecionado, null, retorno, 0));
          }

          this.whatsAppProcessarMensagemAudio();
        });
      }
    } else if (this.arquivo.nativeElement.files.length > 0) {
      /* Arquivo */

      const arquivo: File = this.arquivo.nativeElement.files[0];

      let tamanho: number = (arquivo.size / (1024 * 1024));

      if (tamanho > 5) {
        this.toastr.error("", "Tamanho de arquivo inválido!");

        return;
      } else {
        switch (this.arquivoTipo) {
          case "documento":
            /* Documento */

            if (arquivo.name.toLowerCase().indexOf(".doc") !== -1 || arquivo.name.toLowerCase().indexOf(".docx") !== -1 || arquivo.name.toLowerCase().indexOf(".pdf") !== -1 || arquivo.name.toLowerCase().indexOf(".txt") !== -1 || arquivo.name.toLowerCase().indexOf(".xls") !== -1 || arquivo.name.toLowerCase().indexOf(".xlsx") !== -1) {
              if (this.clienteSelecionado != null) {
                /* Cliente Vinculado */

                const adicionarEvento = this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_documento", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.clienteSelecionado.phone, arquivo, "", 0));

                adicionarEvento.then((retorno) => {
                  this.whatsAppProcessarMensagemArquivoDocumento(retorno);
                });
              } else if (this.numeroSelecionado != "") {
                /* Cliente não Vinculado */

                const adicionarEvento = this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_documento", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.numeroSelecionado, arquivo, "", 0));

                adicionarEvento.then((retorno) => {
                  this.whatsAppProcessarMensagemArquivoDocumento(retorno);
                });
              }

              this.exibirAnexar = false;
            } else {
              this.toastr.error("", "Arquivo inválido!");
            }

            break;
          case "imagem":
            /* Imagem */

            if (arquivo.name.toLowerCase().indexOf(".bmp") !== -1 || arquivo.name.toLowerCase().indexOf(".jpg") !== -1 || arquivo.name.toLowerCase().indexOf(".jpeg") !== -1 || arquivo.name.toLowerCase().indexOf(".png") !== -1) {
              if (this.clienteSelecionado != null) {
                /* Cliente Vinculado */

                const adicionarEvento = this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_imagem", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.clienteSelecionado.phone, arquivo, "", 0));

                adicionarEvento.then((retorno) => {
                  this.whatsAppProcessarMensagemArquivoImagem(retorno);
                });
              } else if (this.numeroSelecionado != "") {
                /* Cliente não Vinculado */

                const adicionarEvento = this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_imagem", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.numeroSelecionado, arquivo, "", 0));

                adicionarEvento.then((retorno) => {
                  this.whatsAppProcessarMensagemArquivoImagem(retorno);
                });
              }

              this.exibirAnexar = false;
            } else {
              this.toastr.error("", "Arquivo inválido!");
            }

            break;
          case "sticker":
            /* Sticker */

            if (arquivo.name.toLowerCase().indexOf(".bmp") !== -1 || arquivo.name.toLowerCase().indexOf(".jpg") !== -1 || arquivo.name.toLowerCase().indexOf(".jpeg") !== -1 || arquivo.name.toLowerCase().indexOf(".png") !== -1) {
              if (this.clienteSelecionado != null) {
                /* Cliente Vinculado */

                const adicionarEvento = this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_sticker", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.clienteSelecionado.phone, arquivo, "", 0));

                adicionarEvento.then((retorno) => {
                  this.whatsAppProcessarMensagemSticker(retorno);
                });
              } else if (this.numeroSelecionado != "") {
                /* Cliente não Vinculado */

                const adicionarEvento = this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_sticker", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.numeroSelecionado, arquivo, "", 0));

                adicionarEvento.then((retorno) => {
                  this.whatsAppProcessarMensagemSticker(retorno);
                });
              }

              this.exibirAnexar = false;
            } else {
              this.toastr.error("", "Arquivo inválido!");
            }

            break;
          default:

        }
      }
    } else if (this.chatMensagem.trim() != "") {
      /* Texto */

      if (this.clienteSelecionado != null) {
        /* Cliente Vinculado */

        this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_texto", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.clienteSelecionado.phone, null, "", 0));
      } else if (this.numeroSelecionado != "") {
        /* Cliente não Vinculado */

        this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "enviar_texto", "", this.chatMensagem, 0, false, false, false, false, false, "", "", this.numeroSelecionado, null, "", 0));
      }

      this.whatsAppProcessarMensagemTexto();
    }

    setTimeout(() => {
      this.centralAtendimentoChatConteudo.nativeElement.scrollTop = this.centralAtendimentoChatConteudo.nativeElement.scrollHeight;
    }, 400);
  }

  whatsAppProcessarMensagemAudio() {
    let canaisComunicacaoWhatsAppChatAudio = new CanaisComunicacaoWhatsAppChatAudio();

    canaisComunicacaoWhatsAppChatAudio.audioUrl = this.audioURL;
    canaisComunicacaoWhatsAppChatAudio.mimeType = "audio/webm";

    let canaisComunicacaoWhatsAppChat = new CanaisComunicacaoWhatsAppChat();

    if (this.clienteSelecionado != null) {
      /* Cliente Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.clienteSelecionado.phone;
      canaisComunicacaoWhatsAppChat.participantPhone = this.clienteSelecionado.phone;
    } else if (this.numeroSelecionado != "") {
      /* Cliente não Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.numeroSelecionado;
      canaisComunicacaoWhatsAppChat.participantPhone = this.numeroSelecionado;
    }

    canaisComunicacaoWhatsAppChat.fromMe = true;
    canaisComunicacaoWhatsAppChat.audio = canaisComunicacaoWhatsAppChatAudio;
    canaisComunicacaoWhatsAppChat.status = "SENT";
    canaisComunicacaoWhatsAppChat.data_hora = new Date();

    this.canaisComunicacaoWhatsAppChat.splice(this.canaisComunicacaoWhatsAppChat.length, 0, canaisComunicacaoWhatsAppChat);

    this.canaisComunicacaoWhatsAppChat = [...this.canaisComunicacaoWhatsAppChat];

    this.cancelarAudio();
  }

  whatsAppProcessarMensagemArquivoDocumento(retorno: CentralAtendimentoEventoRetorno) {
    let canaisComunicacaoWhatsAppChatDocumento = new CanaisComunicacaoWhatsAppChatDocumento();

    canaisComunicacaoWhatsAppChatDocumento.documentUrl = retorno.urlArquivo;

    if (retorno.urlArquivo.toLowerCase().indexOf(".xls") !== -1 || retorno.urlArquivo.toLowerCase().indexOf(".xlsx") !== -1) {
      canaisComunicacaoWhatsAppChatDocumento.mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    } else if (retorno.urlArquivo.toLowerCase().indexOf(".doc") !== -1 || retorno.urlArquivo.toLowerCase().indexOf(".docx") !== -1) {
      canaisComunicacaoWhatsAppChatDocumento.mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else if (retorno.urlArquivo.toLowerCase().indexOf(".pdf") !== -1) {
      canaisComunicacaoWhatsAppChatDocumento.mimeType = "application/pdf";
    } else if (retorno.urlArquivo.toLowerCase().indexOf(".txt") !== -1) {
      canaisComunicacaoWhatsAppChatDocumento.mimeType = "text/plain";
    }

    canaisComunicacaoWhatsAppChatDocumento.fileName = retorno.nomeArquivo;

    let canaisComunicacaoWhatsAppChat = new CanaisComunicacaoWhatsAppChat();

    if (this.clienteSelecionado != null) {
      /* Cliente Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.clienteSelecionado.phone;
      canaisComunicacaoWhatsAppChat.participantPhone = this.clienteSelecionado.phone;
    } else if (this.numeroSelecionado != "") {
      /* Cliente não Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.numeroSelecionado;
      canaisComunicacaoWhatsAppChat.participantPhone = this.numeroSelecionado;
    }

    canaisComunicacaoWhatsAppChat.fromMe = true;
    canaisComunicacaoWhatsAppChat.document = canaisComunicacaoWhatsAppChatDocumento;
    canaisComunicacaoWhatsAppChat.status = "SENT";
    canaisComunicacaoWhatsAppChat.data_hora = new Date();

    this.canaisComunicacaoWhatsAppChat.splice(this.canaisComunicacaoWhatsAppChat.length, 0, canaisComunicacaoWhatsAppChat);

    this.canaisComunicacaoWhatsAppChat = [...this.canaisComunicacaoWhatsAppChat];

    this.removerArquivo();
  }

  whatsAppProcessarMensagemArquivoImagem(retorno: CentralAtendimentoEventoRetorno) {
    let canaisComunicacaoWhatsAppChatImagem = new CanaisComunicacaoWhatsAppChatImagem();

    canaisComunicacaoWhatsAppChatImagem.imageUrl = retorno.urlArquivo;

    if (retorno.urlArquivo.toLowerCase().indexOf(".bmp") !== -1) {
      canaisComunicacaoWhatsAppChatImagem.mimeType = "image/bmp";
    } else if (retorno.urlArquivo.toLowerCase().indexOf(".jpg") !== -1 || retorno.urlArquivo.toLowerCase().indexOf(".jpeg") !== -1) {
      canaisComunicacaoWhatsAppChatImagem.mimeType = "image/jpeg";
    } else if (retorno.urlArquivo.toLowerCase().indexOf(".png") !== -1) {
      canaisComunicacaoWhatsAppChatImagem.mimeType = "image/png";
    }

    canaisComunicacaoWhatsAppChatImagem.thumbnailUrl = retorno.urlArquivo;

    let canaisComunicacaoWhatsAppChat = new CanaisComunicacaoWhatsAppChat();

    if (this.clienteSelecionado != null) {
      /* Cliente Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.clienteSelecionado.phone;
      canaisComunicacaoWhatsAppChat.participantPhone = this.clienteSelecionado.phone;
    } else if (this.numeroSelecionado != "") {
      /* Cliente não Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.numeroSelecionado;
      canaisComunicacaoWhatsAppChat.participantPhone = this.numeroSelecionado;
    }

    canaisComunicacaoWhatsAppChat.fromMe = true;
    canaisComunicacaoWhatsAppChat.image = canaisComunicacaoWhatsAppChatImagem;
    canaisComunicacaoWhatsAppChat.status = "SENT";
    canaisComunicacaoWhatsAppChat.data_hora = new Date();

    this.canaisComunicacaoWhatsAppChat.splice(this.canaisComunicacaoWhatsAppChat.length, 0, canaisComunicacaoWhatsAppChat);

    this.canaisComunicacaoWhatsAppChat = [...this.canaisComunicacaoWhatsAppChat];

    this.removerArquivo();
  }

  whatsAppProcessarMensagemSticker(retorno: CentralAtendimentoEventoRetorno) {
    let canaisComunicacaoWhatsAppChatSticker = new CanaisComunicacaoWhatsAppChatSticker();

    canaisComunicacaoWhatsAppChatSticker.stickerUrl = retorno.urlArquivo;
    canaisComunicacaoWhatsAppChatSticker.mimeType = "image/webp";

    let canaisComunicacaoWhatsAppChat = new CanaisComunicacaoWhatsAppChat();

    if (this.clienteSelecionado != null) {
      /* Cliente Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.clienteSelecionado.phone;
      canaisComunicacaoWhatsAppChat.participantPhone = this.clienteSelecionado.phone;
    } else if (this.numeroSelecionado != "") {
      /* Cliente não Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.numeroSelecionado;
      canaisComunicacaoWhatsAppChat.participantPhone = this.numeroSelecionado;
    }

    canaisComunicacaoWhatsAppChat.fromMe = true;
    canaisComunicacaoWhatsAppChat.sticker = canaisComunicacaoWhatsAppChatSticker;
    canaisComunicacaoWhatsAppChat.status = "SENT";
    canaisComunicacaoWhatsAppChat.data_hora = new Date();

    this.canaisComunicacaoWhatsAppChat.splice(this.canaisComunicacaoWhatsAppChat.length, 0, canaisComunicacaoWhatsAppChat);

    this.canaisComunicacaoWhatsAppChat = [...this.canaisComunicacaoWhatsAppChat];

    this.removerArquivo();
  }

  whatsAppProcessarMensagemTexto() {
    let canaisComunicacaoWhatsAppChatTexto = new CanaisComunicacaoWhatsAppChatTexto();

    canaisComunicacaoWhatsAppChatTexto.message = this.chatMensagem;

    let canaisComunicacaoWhatsAppChat = new CanaisComunicacaoWhatsAppChat();

    if (this.clienteSelecionado != null) {
      /* Cliente Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.clienteSelecionado.phone;
      canaisComunicacaoWhatsAppChat.participantPhone = this.clienteSelecionado.phone;
    } else if (this.numeroSelecionado != "") {
      /* Cliente não Vinculado */

      canaisComunicacaoWhatsAppChat.phone = this.numeroSelecionado;
      canaisComunicacaoWhatsAppChat.participantPhone = this.numeroSelecionado;
    }

    canaisComunicacaoWhatsAppChat.fromMe = true;
    canaisComunicacaoWhatsAppChat.text = canaisComunicacaoWhatsAppChatTexto;
    canaisComunicacaoWhatsAppChat.status = "SENT";
    canaisComunicacaoWhatsAppChat.data_hora = new Date();

    this.canaisComunicacaoWhatsAppChat.splice(this.canaisComunicacaoWhatsAppChat.length, 0, canaisComunicacaoWhatsAppChat);

    this.canaisComunicacaoWhatsAppChat = [...this.canaisComunicacaoWhatsAppChat];

    this.chatMensagem = "";
  }

  whatsAppReceberChat(numero_telefone: string, client_id_app: number) {
    this.canaisComunicacaoWhatsAppChatPrimeiraVisualizacao = true;

    this.verificarChatAberto.unsubscribe();

    /* Cliente (Selecionado) */

    this.clienteSelecionado = null;

    /* Número (Selecionado) */

    this.numeroSelecionado = "";

    if (client_id_app != null && client_id_app != 0) {
      /* Cliente Vinculado */

      this.clienteService.getById(client_id_app).subscribe(
        clientesSelecionados => this.clienteSelecionado = clientesSelecionados,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.clienteSelecionado != null) {
            /* Cliente Vinculado */

            this.clienteSelecionado.phone = numero_telefone;

            this.verificarChatAberto = timer(0, 15000).subscribe(() => { /* Início / Intervalo */
              if (this.canaisComunicacaoWhatsAppStatus != null && this.canaisComunicacaoWhatsAppStatus.connected) {
                this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "receber_chat", "", "", 0, false, false, false, false, false, "", "", numero_telefone, null, "", this.clienteSelecionado.client_id_app));
              } else {
                this.verificarChatAberto.unsubscribe();
              }
            });

            this.exibirChat = true;

            setTimeout(() => {
              this.posicaoPadraoChat();
            }, 400);
          }
        }
      );
    } else {
      /* Cliente não Vinculado */

      this.numeroSelecionado = numero_telefone;

      this.verificarChatAberto = timer(0, 15000).subscribe(() => { /* Início / Intervalo */
        if (this.canaisComunicacaoWhatsAppStatus != null && this.canaisComunicacaoWhatsAppStatus.connected) {
          this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "receber_chat", "", "", 0, false, false, false, false, false, "", "", this.numeroSelecionado, null, "", 0));
        } else {
          this.verificarChatAberto.unsubscribe();
        }
      });

      this.exibirChat = true;

      setTimeout(() => {
        this.posicaoPadraoChat();
      }, 400);
    }
  }

  whatsAppReceberMensagem() {
    this.verificarMensagemRecebida = timer(10000, 10000).subscribe(() => { /* Início / Intervalo */
      this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "receber_mensagem", "", "", 0, false, false, false, false, false, "", "", "", null, "", 0));
    });
  }

  whatsAppRegistrar(codigo_no_erp_do_cliente: string) {
    if (!this.router.isActive("call-center-ligacao", false)) {
      this.router.navigate(["/call-center-nova-ligacao"], {
        queryParams: {
          cliente: codigo_no_erp_do_cliente,
          whatsapp: true
        }
      });
    }
  }
}
