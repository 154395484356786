import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { CallCenterAgendamento } from '../../models/call-center/call-center-agendamento';
import { CallCenterChamada } from '../../models/call-center/call-center-chamada';
import { CallCenterConfiguracao } from '../../models/call-center/call-center-configuracao';
import { CallCenterLigacaoAnalitica } from '../../models/call-center/call-center-ligacao-analitica';
import { CallCenterMotivoPausa } from '../../models/call-center/call-center-motivo-pausa';
import { CallCenterOportunidade } from '../../models/call-center/call-center-oportunidade';
import { CallCenterOportunidadeClassificacao } from '../../models/call-center/call-center-oportunidade-classificacao';
import { CallCenterOportunidadeFiltrar } from '../../models/call-center/call-center-oportunidade-filtrar';
import { CallCenterPausa } from '../../models/call-center/call-center-pausa';
import { CallCenterPedido } from '../../models/call-center/call-center-pedido';
import { CallCenterQualificacaoQuestaoResposta } from '../../models/call-center/call-center-qualificacao-questao-resposta';
import { CallCenterQualificacaoQuestao } from '../../models/call-center/call-center-qualificacao-questao';
import { CallCenterResultado } from '../../models/call-center/call-center-resultado';
import { CallCenterStatus } from '../../models/call-center/call-center-status';
import { CallCenterTipo } from '../../models/call-center/call-center-tipo';
import { CallCenterWhatsAppWebHook } from '../../models/call-center/call-center-whatsapp-webhook';

@Injectable({
  providedIn: 'root'
})

export class CallCenterService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Agendamento */

  public deleteAgendamentoById(id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/DeleteAgendamentoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getAgendamentoByCliente(client_id_app: number): Observable<CallCenterAgendamento[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<CallCenterAgendamento[]>(this.baseUrl + "api/CallCenter/GetAgendamentoByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateAgendamentoDataById(id: number, data_ligacao: Date): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "data_ligacao": data_ligacao
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/UpdateAgendamentoDataById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Chamada */

  public addChamada(chamada: CallCenterChamada): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "chamada": chamada
    };

    return this.http.post<number>(this.baseUrl + "api/CallCenter/AddChamada", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public getChamadaByIdCallCenterLigacao(id_call_center_ligacao: number): Observable<CallCenterChamada[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_call_center_ligacao": id_call_center_ligacao
    };

    return this.http.post<CallCenterChamada[]>(this.baseUrl + "api/CallCenter/GetChamadaByIdCallCenterLigacao", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateChamadaFimById(id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/UpdateChamadaFimById", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Configuração */

  public addConfiguracao(configuracao: CallCenterConfiguracao): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "configuracao": configuracao
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/AddConfiguracao", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getConfiguracaoByGuidClienteNapis(): Observable<CallCenterConfiguracao> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<CallCenterConfiguracao>(this.baseUrl + "api/CallCenter/GetConfiguracaoByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public updateConfiguracao(configuracao: CallCenterConfiguracao): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "configuracao": configuracao
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/UpdateConfiguracao", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Ligação */

  public addLigacao(ligacao: CallCenterLigacaoAnalitica): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "ligacao": ligacao
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/AddLigacao", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public deleteCallCenterLigacaoById(id: number, seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "seller_id": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/DeleteCallCenterLigacaoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getById(id: number): Observable<CallCenterLigacaoAnalitica> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<CallCenterLigacaoAnalitica>(this.baseUrl + "api/CallCenter/GetById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getCRMByVendedor(seller_id: number): Observable<CallCenterLigacaoAnalitica[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id
    };

    return this.http.post<CallCenterLigacaoAnalitica[]>(this.baseUrl + "api/CallCenter/GetCRMByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getHojeByCliente(client_id_app: number): Observable<CallCenterLigacaoAnalitica[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<CallCenterLigacaoAnalitica[]>(this.baseUrl + "api/CallCenter/GetHojeByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getUltimaLigacaoByCliente(client_id_app: number): Observable<CallCenterLigacaoAnalitica> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<CallCenterLigacaoAnalitica>(this.baseUrl + "api/CallCenter/GetUltimaLigacaoByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getLigacaoByUsuario(atencao: boolean): Observable<CallCenterLigacaoAnalitica[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id"),
      "atencao": atencao
    };

    return this.http.post<CallCenterLigacaoAnalitica[]>(this.baseUrl + "api/CallCenter/GetLigacaoByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateLigacao(ligacao: CallCenterLigacaoAnalitica): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "ligacao": ligacao
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/UpdateLigacao", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Motivo Pausa */

  public addMotivoPausa(callCenterMotivoPausa: CallCenterMotivoPausa): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    callCenterMotivoPausa.guidclientenapis = localStorage.getItem("guidclientenapis");

    let data = {
      "callCenterMotivoPausa": callCenterMotivoPausa
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/AddMotivoPausa", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getMotivoPausaByGuidClienteNapis(somente_ativo: boolean): Observable<CallCenterMotivoPausa[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "somente_ativo": somente_ativo
    };

    return this.http.post<CallCenterMotivoPausa[]>(this.baseUrl + "api/CallCenter/GetMotivoPausaByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getMotivoPausaGestorByGuidClienteNapis(somente_gestor: boolean): Observable<CallCenterMotivoPausa[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "somente_gestor": somente_gestor
    };

    return this.http.post<CallCenterMotivoPausa[]>(this.baseUrl + "api/CallCenter/GetMotivoPausaGestorByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getMotivoPausaById(id: number): Observable<CallCenterMotivoPausa> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<CallCenterMotivoPausa>(this.baseUrl + "api/CallCenter/GetMotivoPausaById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public updateMotivoPausa(callCenterMotivoPausa: CallCenterMotivoPausa): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "callCenterMotivoPausa": callCenterMotivoPausa
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/UpdateMotivoPausa", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Oportunidade */

  public getOportunidadeByUsuario(filtrar?: CallCenterOportunidadeFiltrar): Observable<CallCenterOportunidade[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id"),
      "filtrar": filtrar
    };

    return this.http.post<CallCenterOportunidade[]>(this.baseUrl + "api/CallCenter/GetOportunidadeByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Oportunidade (Classificação) */

  public getOportunidadeClassificacaoByGuidClienteNapis(): Observable<CallCenterOportunidadeClassificacao[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<CallCenterOportunidadeClassificacao[]>(this.baseUrl + "api/CallCenter/GetOportunidadeClassificacaoByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Pausa */

  public addPausa(callCenterPausa: CallCenterPausa): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    callCenterPausa.guidclientenapis = localStorage.getItem("guidclientenapis");

    let data = {
      "callCenterPausa": callCenterPausa
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/AddPausa", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public deletePausaById(id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/DeletePausaById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getPausaProgramadaByVendedor(seller_id: number): Observable<CallCenterPausa[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id
    };

    return this.http.post<CallCenterPausa[]>(this.baseUrl + "api/CallCenter/GetPausaProgramadaByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getPausaProgramadaHojeByUsuario(id: number): Observable<CallCenterPausa[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<CallCenterPausa[]>(this.baseUrl + "api/CallCenter/GetPausaProgramadaHojeByUsuario", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getPausaRealizada6MesesByGuidClienteNapis(): Observable<CallCenterPausa[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<CallCenterPausa[]>(this.baseUrl + "api/CallCenter/GetPausaRealizada6MesesByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getPausaRealizadaHojeByUsuario(): Observable<CallCenterPausa[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<CallCenterPausa[]>(this.baseUrl + "api/CallCenter/GetPausaRealizadaHojeByUsuario", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updatePausa(callCenterPausa: CallCenterPausa): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "callCenterPausa": callCenterPausa
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/UpdatePausa", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Pedido */

  public getPedidoDiarioByGuidClienteNapis(): Observable<CallCenterPedido[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<CallCenterPedido[]>(this.baseUrl + "api/CallCenter/GetPedidoDiarioByGuidClienteNapis", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Qualificação (Questões) */

  public addCallCenterQualificacaoQuestaoResposta(respostas: CallCenterQualificacaoQuestaoResposta[]): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "respostas": respostas
    };

    return this.http.post<boolean>(this.baseUrl + "api/CallCenter/AddCallCenterQualificacaoQuestaoResposta", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getCallCenterQualificacaoQuestaoResposta(client_id: number): Observable<CallCenterQualificacaoQuestaoResposta[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id": client_id
    };

    return this.http.post<CallCenterQualificacaoQuestaoResposta[]>(this.baseUrl + "api/CallCenter/GetCallCenterQualificacaoQuestaoResposta", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getQualificacaoQuestaoByGuidClienteNapis(): Observable<CallCenterQualificacaoQuestao[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<CallCenterQualificacaoQuestao[]>(this.baseUrl + "api/CallCenter/GetQualificacaoQuestaoByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Resultado */

  public getResultadoByGuidClienteNapis(): Observable<CallCenterResultado[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<CallCenterResultado[]>(this.baseUrl + "api/CallCenter/GetResultadoByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Status */

  public getStatus(): Observable<CallCenterStatus[]> {
    return this.http.get<CallCenterStatus[]>(this.baseUrl + "api/CallCenter/GetStatus").pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Tipo */

  public getTipo(): Observable<CallCenterTipo[]> {
    return this.http.get<CallCenterTipo[]>(this.baseUrl + "api/CallCenter/GetTipo").pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* WhatsApp (WebHook) */

  public addWhatsAppWebHook(callCenterWhatsAppWebHook: CallCenterWhatsAppWebHook): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "callCenterWhatsAppWebHook": callCenterWhatsAppWebHook
    };

    return this.http.post<number>(this.baseUrl + "api/CallCenter/AddWhatsAppWebHook", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public getWhatsAppWebHookByVendedor(): Observable<CallCenterWhatsAppWebHook[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id_tmk": localStorage.getItem("id")
    };

    return this.http.post<CallCenterWhatsAppWebHook[]>(this.baseUrl + "api/CallCenter/GetWhatsAppWebHookByVendedor", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
