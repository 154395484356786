import { trigger, state, style, transition, animate, group, animateChild, query } from '@angular/animations';

export const Animacao = [
  trigger("centralAtendimentoLateral", [
    state("aumentar", style({
      "width": "300px"
    })),

    state("diminuir", style({
      "width": "60px"
    })),

    transition("aumentar <=> diminuir", group([
      query("@centralAtendimentoEvento", [
        animateChild()
      ], { optional: true }),
      query("@conteudo", [
        animateChild()
      ], { optional: true }),
      animate(100)
    ]))
  ]),

  trigger("centralAtendimentoEvento", [
    state("aparecer", style({
      "opacity": 1
    })),

    state("esconder", style({
      "opacity": 0
    })),

    transition("aparecer <=> esconder", [
      animate(50)
    ])
  ]),

  trigger("conteudo", [
    state("aumentar", style({
      "padding-left": "300px"
    })),

    state("diminuir", style({
      "padding-left": "60px"
    })),

    state("original", style({
      "padding-left": "0px"
    })),

    transition("aumentar <=> diminuir", group([
      query("@centralAtendimentoEvento", [
        animateChild()
      ], { optional: true }),
      animate(100)
    ]))
  ]),

  trigger("esvanecerDesvanecer", [
    state("in", style({ "opacity": 1 })),

    transition(":enter", [
      style({ "opacity": 0 }),
      animate(300)
    ]),

    transition(":leave",
      animate(300, style({ "opacity": 0 }))
    )
  ]),

  trigger("desvanecer", [
    transition(":enter", [
      style({ "opacity": 0 }),
      animate(400)
    ])
  ])
]
