import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { DashboardControleCliente } from '../../../models/dashboard/controle-cliente/dashboard-controle-cliente';
import { DashboardControleClienteSemVisita } from '../../../models/dashboard/controle-cliente/dashboard-controle-cliente-sem-visita';
import { DashboardControleClienteVendedor } from '../../../models/dashboard/controle-cliente/dashboard-controle-cliente-vendedor';

@Injectable({
  providedIn: 'root'
})

export class DashboardControleClienteService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getByPeriodo(dt_inicial: Date, dt_final: Date): Observable<DashboardControleCliente[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "dt_inicial": dt_inicial,
      "dt_final": dt_final
    };

    return this.http.post<DashboardControleCliente[]>(this.baseUrl + "api/DashboardControleCliente/GetByPeriodo", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Sem Visita */

  public getSemVisita30diasByGuidClienteNapis(): Observable<DashboardControleClienteSemVisita[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<DashboardControleClienteSemVisita[]>(this.baseUrl + "api/DashboardControleCliente/GetSemVisita30diasByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getSemVisita60diasByGuidClienteNapis(): Observable<DashboardControleClienteSemVisita[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<DashboardControleClienteSemVisita[]>(this.baseUrl + "api/DashboardControleCliente/GetSemVisita60diasByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Vendedor(a) */

  public getVendedorByPeriodo(dt_inicial: Date, dt_final: Date): Observable<DashboardControleClienteVendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "dt_inicial": dt_inicial,
      "dt_final": dt_final
    };

    return this.http.post<DashboardControleClienteVendedor[]>(this.baseUrl + "api/DashboardControleCliente/GetVendedorByPeriodo", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
