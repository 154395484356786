import { Component, OnInit, ViewChild, ElementRef, TemplateRef, ViewContainerRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { CentralAtendimentoService } from '../../core/services/central-atendimento/central-atendimento.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { ConfiguracaoService } from '../../core/services/configuracoes/configuracao.service';
import { ContaCorrenteService } from '../../core/services/contas-correntes/conta-corrente.service';
import { FormaPagamentoService } from '../../core/services/formas-pagamento/forma-pagamento.service';
import { FormatacaoService } from '../../core/tools/formatacao.service';
import { NotaFiscalService } from '../../core/services/notas-fiscais/nota-fiscal.service';
import { PdfService } from '../../core/tools/pdf.service';
import { PedidoService } from '../../core/services/pedidos/pedido.service';
import { PedidoStatusService } from '../../core/services/pedidos/pedido-status.service';
import { PedidoItensService } from '../../core/services/pedidos/pedido-itens.service';
import { PrazoMedioService } from '../../core/services/prazos-medio/prazo-medio.service';
import { PrazoPagamentoClienteService } from '../../core/services/prazos-pagamento/prazo-pagamento-cliente.service';
import { PrazoPagamentoService } from '../../core/services/prazos-pagamento/prazo-pagamento.service';
import { ProdutoService } from '../../core/services/produtos/produto.service';
import { TempoProgressivoService } from '../../core/tools/tempo-progressivo.service';
import { TipoFreteService } from '../../core/services/tipos/tipo-frete.service';
import { TipoPedidoService } from '../../core/services/tipos/tipo-pedido.service';
import { TransportadoraService } from '../../core/services/transportadoras/transportadora.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { CallCenterConfiguracao } from '../../core/models/call-center/call-center-configuracao';
import { Cliente } from '../../core/models/clientes/cliente';
import { ClienteFiltrar } from '../../core/models/clientes/cliente-filtrar';
import { ClienteLocalEntrega } from '../../core/models/clientes/cliente-local-entrega';
import { ClienteTabela } from '../../core/models/clientes/cliente-tabela';
import { Configuracao } from '../../core/models/configuracoes/configuracao';
import { ContaCorrenteSaldo } from '../../core/models/contas-correntes/conta-corrente-saldo';
import { FormaPagamento } from '../../core/models/formas-pagamento/forma-pagamento';
import { NotaFiscalSintetica } from '../../core/models/notas-fiscais/nota-fiscal-sintetica';
import { Pedido } from '../../core/models/pedidos/pedido';
import { PedidoBonificacaoRetorno } from '../../core/models/pedidos/pedido-bonificacao-retorno';
import { PedidoItens } from '../../core/models/pedidos/pedido-itens';
import { PedidoItensSintetico } from '../../core/models/pedidos/pedido-itens-sintetico';
import { PedidoSintetico } from '../../core/models/pedidos/pedido-sintetico';
import { PedidoStatus } from '../../core/models/pedidos/pedido-status';
import { PrazoMedio } from '../../core/models/prazos-medio/prazo-medio';
import { PrazoMedioDesconto } from '../../core/models/prazos-medio/prazo-medio-desconto';
import { PrazoPagamento } from '../../core/models/prazos-pagamento/prazo-pagamento';
import { PrazoPagamentoCliente } from '../../core/models/prazos-pagamento/prazo-pagamento-cliente';
import { ProdutoDesconto } from '../../core/models/produtos/produto-desconto';
import { ProdutoFichaTecnica } from '../../core/models/produtos/produto-ficha-tecnica';
import { ProdutoFoto } from '../../core/models/produtos/produto-foto';
import { ProdutoLocal } from '../../core/models/produtos/produto-local';
import { ProdutoLote } from '../../core/models/produtos/produto-lote';
import { ProdutoPromocional } from '../../core/models/produtos/produto-promocional';
import { ProdutoPromocionalAgrupado } from '../../core/models/produtos/produto-promocional-agrupado';
import { ProdutoReferenciaCliente } from '../../core/models/produtos/produto-referencia-cliente';
import { ProdutoSimilar } from '../../core/models/produtos/produto-similar';
import { ProdutoTabela } from '../../core/models/produtos/produto-tabela';
import { TipoFrete } from '../../core/models/tipos/tipo-frete';
import { TipoPedido } from '../../core/models/tipos/tipo-pedido';
import { Transportadora } from '../../core/models/transportadoras/transportadora';
import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-pedidos-cadastro',
  templateUrl: './pedidos-cadastro.component.html',
  styleUrls: ['./pedidos-cadastro.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class PedidosCadastroComponent implements OnInit {
  /* Categorias (Fixas) */

  campanhas: string = "CAMPANHAS";
  combo: string = "COMBO";

  /* Calculando/Carregamento */

  calculando: boolean = false;
  carregando: boolean = false;

  /* CDK */

  tamanhoProdutos: string = "";

  /* Composição */

  visualizandoComposicao: boolean = false;

  /* Dados */

    /* Adicionando/Alterando */

    pedido: Pedido = null;
    pedidoItens: PedidoItens[] = [];
    pedidoItensInativos: PedidoItens[] = [];

    /* Atendimento */

    retornarCallCenterLigacao: boolean = false;

    /* Bonificação */

    bonificacaoInvalida: boolean = false;

    /* Call Center (Configurações) */

    callCenterConfiguracao: CallCenterConfiguracao = null;

    /* Canal de Vendas */

    canalVendasSelecionado: number = 2;

    /* Configurações */

    configuracao: Configuracao = null;

    tipoOrdenacaoItensPedido: number = 0;

    /* Cliente (Locais Entrega) */

    clienteLocaisEntrega: ClienteLocalEntrega[] = [];

    clienteLocalEntregaSelecionado: ClienteLocalEntrega = null;

    /* Cliente (Tabela) */

    tabelasClientes: ClienteTabela[] = [];

    /* Contas Correntes (Saldo) */

    contaCorrenteSaldo: ContaCorrenteSaldo = null;

    contaCorrenteSaldoDisponivel: number = 0;

    /* Data Atual */

    dataAtual = new Date();

    /* Dt. Previsão de Entrega */

    dataPrevisaoEntrega: { year: number, month: number, day: number } = null;

    /* Dt. Validade */

    dataValidade: { year: number, month: number, day: number } = null;

    dataValidadeInvalida: boolean = false;

    /* Desconto Itens */

    porcentagemDescontoAplicado: number = null;

    porcentagemDescontoAplicadoErro: boolean = false;

    /* Filtro (Clientes) */

    clientes: Cliente[] = [];
    clientesOriginal: Cliente[] = [];

    clienteSelecionado: Cliente = null;

    clienteInvalido: boolean = false;

    /* Forma de Pagamento */

    formasPagamento: FormaPagamento[] = [];

    formaPagamentoSelecionada: FormaPagamento = null;

    /* Grupos Estoque */

    gruposEstoque: string[] = [];

    /* Notas Fiscais */

    notasFiscais: NotaFiscalSintetica[] = [];

    /* Nro Pedido/Orçamento */

    nroPedidoOrcamento: number = 0;
    nroPedidoOrcamentoVinculado: number = 0;

    /* Observações */

    observacaoInterna: string = "";
    observacaoNotaFiscal: string = "";

    /* Prazo Médio */

    prazoMedio: PrazoMedio = null;

    prazoMedioDesconto: PrazoMedioDesconto = null;

    /* Prazo de Pagamento */

    prazosPagamentoIniciais: PrazoPagamento[] = [];
    prazosPagamentoEspeciais: PrazoPagamento[] = [];

    prazosPagamentoOriginal: PrazoPagamento[] = [];

    prazoPagamentoSelecionado: PrazoPagamento = null;

    prazoPagamentoInvalido: boolean = false;

    /* Prazo de Pagamento (Cliente) */

    prazosPagamentoCliente: PrazoPagamentoCliente[] = [];

    /* Pedido/Orçamento */

    pedidoOrcamentoSelecionado: number = 1; /* Pedido */

    /* Pedido Bonificação (Retorno) */

    pedidoBonificacaoRetorno: PedidoBonificacaoRetorno = null;

    /* Pedido Vinculado */

    pedidoVinculado: string = "";

    /* Pedidos (Cliente) */

    pedidosClientes: PedidoSintetico[] = [];
    pedidosEnviadosClientes: PedidoSintetico[] = [];
    pedidosNaoEnviadosClientes: PedidoSintetico[] = [];

    pedidoNaoEnviadoClienteHoje: PedidoSintetico = null;

    /* Pedidos (FatorFinanceiro) */

    exibirFatorFinanceiroEnviados: boolean = false;
    exibirFatorFinanceiroNaoEnviados: boolean = false;

    /* Pedidos Itens (Cliente) */

    pedidosItensClientes: PedidoItensSintetico[] = [];
    pedidosItensEnviadosClientes: PedidoItensSintetico[] = [];
    pedidosItensNaoEnviadosClientes: PedidoItensSintetico[] = [];

    /* Pedidos Itens Enviados (Cliente) */

    pedidosItensEnviados: PedidoItensSintetico[] = [];

    pedidosItensEnviadosExpandido: number = -1;

    /* Pedidos Itens Não Enviados (Cliente) */

    pedidosItensNaoEnviados: PedidoItensSintetico[] = [];

    pedidosItensNaoEnviadosExpandido: number = -1;

    /* Pedidos (Status) */

    pedidosStatus: PedidoStatus[] = [];

    /* Porcentagem Desconto (Temporária) */

    porcentagemDescontoTemporario: number = null;

    /* Produto (Estoque Local) */

    produtoLocais: ProdutoLocal[] = [];

    /* Produto (Ficha Técnica) */

    produtoFichasTecnicas: ProdutoFichaTecnica[] = [];

    /* Produto (Fotos) */

    produtoFotos: ProdutoFoto[] = [];

    produtoFotosSelecionado: ProdutoPromocional = null;

    /* Produto (Informações) */

    produtoInformacoesSelecionado: ProdutoPromocional = null;

    /* Produto (Lotes) */

    produtoLotes: ProdutoLote[] = [];

    produtoLotesSelecionado: ProdutoPromocional = null;

    /* Produto (Tabelas) */

    produtoTabelas: ProdutoTabela[] = [];

    produtoTabelasSelecionado: ProdutoPromocional = null;

    /* Produtos */

    produtos: ProdutoPromocional[] = [];
    produtosAdicionados: ProdutoPromocional[] = [];
    produtosAdicionadosOriginal: ProdutoPromocional[] = [];
    produtosAtualizados: ProdutoPromocional[] = [];
    produtosComErro: ProdutoPromocional[] = [];
    produtosComposicoes: ProdutoPromocional[] = [];
    produtosOriginal: ProdutoPromocional[] = [];

    produtosAgrupados: ProdutoPromocionalAgrupado[] = [];

    valorParcialProdutosAdicionados: number = 0;
    valorTotalPesoProdutosAdicionados: number = 0;
    valorTotalProdutosAdicionados: number = 0;
    valorTotalProdutosAdicionadosFatorFinanceiro: number = 0;
    valorTotalProdutosAdicionadosImposto: number = 0;

    produtoCampanhaSelecionado: ProdutoPromocional = null;

    produtoSelecionado: ProdutoPromocional = null;

    produtoSemImagem: string = "assets/produto_sem_imagem.png";

    /* Produtos (Referências Cliente) */

    produtosReferenciasCliente: ProdutoReferenciaCliente[] = null;

    produtoReferenciaClienteSelecionado: string = "";

    /* Produtos (Similares) */

    produtosSimilares: ProdutoSimilar[] = [];

    /* Reprovar */

    motivoReprovacao: string = "";

    /* Resultado */

    porcentagemMarkupPedido: number = 0;
    simulacaoResultado: string = "0%";

    /* Tipo Frete */

    tiposFrete: TipoFrete[] = [];

    tipoFreteSelecionado: TipoFrete = null;

    /* Tipo Pedido */

    tiposPedido: TipoPedido[] = [];

    tiposPedidoErro: boolean = false;
    tiposPedidoSelecionado: TipoPedido = null;
    tiposPedidoSelecionadoOriginal: TipoPedido = null;

    /* Transportadoras */

    transportadoras: Transportadora[] = [];

    transportadoraSelecionada: Transportadora = null;

    /* Vendedores */

    vendedores: Vendedor[] = [];

    vendedorSelecionado: Vendedor = null;

  /* Externo */

    /* Cliente (Externo) */

    clienteSelecionadoExterno: string = "";

    /* Ligação (Externa) */

    ligacaoSelecionadaExterna: number = 0;

    /* Pedido (Aprovar (Externo)) */

    pedidoAprovarExterno: boolean = false;

    /* Pedido (Duplicar (Externo)) */

    pedidoDuplicarExterno: boolean = false;

    /* Vendedor (Externo) */

    vendedorSelecionadoExterno: number = 0;

    /* Visita (Externa) */

    visitaSelecionadaExterna: number = 0;

  /* Fator Financeiro */

  exibirFatorFinanceiro: boolean = false;

  /* Filtro (Clientes) */

  formFiltroCliente: FormGroup;

  filtroClientes: any[] = [
    { texto: "Código", valor: "0" },
    { texto: "CNPJ/CPF", valor: "1" },
    { texto: "Nome Fantasia", valor: "2" },
    { texto: "Razão Social", valor: "3" }
  ];

  filtrandoCliente: boolean = false;

  enviadoCliente: boolean = false;

  /* Filtro (Produtos) */

  formFiltroProduto: FormGroup;

  filtroProdutos: any[] = [
    { texto: "Campanha", valor: "0" },
    { texto: "Código", valor: "1" },
    { texto: "Descrição", valor: "2" },
    { texto: "Marca", valor: "3" },
    { texto: "Referência", valor: "4" }
  ];

  enviadoProduto: boolean = false;

  filtrandoProduto: boolean = false;

  filtroProdutoSelecionado: string = "2";

  /* Filtro (Produtos (Campanhas)) */

  formFiltroProdutoCampanha: FormGroup;

  enviadoProdutoCampanha: boolean = false;

  filtrandoProdutoCampanha: boolean = false;

  /* Filtro (Status) */

  filtroStatus: any[] = [
    { texto: "Todos", valor: "0" },
    { texto: "Novo Item", valor: "1" },
    { texto: "Item Reposição", valor: "2" }
  ];

  /* Itens (Menu) */

  itemSelecionado: number = 0;
  itemMaximoPermitido: number = 0;

  /* Saldo Gerado */

  saldoGeradoErro: boolean = false;
  saldoGeradoValorParcial: number = 0;
  saldoGeradoValorFinal: number = 0;

  /* Modal */

  modalReferenciaSelecionarLote: NgbModalRef = null;
  modalReferenciaTabelasProdutos: NgbModalRef = null;

  @ViewChild("adicionarItens", { static: true }) adicionarItens: TemplateRef<any>;
  @ViewChild("adicionarItensFiltro", { static: false }) adicionarItensFiltro: ElementRef;
  @ViewChild("adicionarItensDados", { static: false }) adicionarItensDados: ElementRef;
  @ViewChild("adicionarItensProdutos", { static: false }) adicionarItensProdutos: CdkVirtualScrollViewport;
  @ViewChild("comBonificacao", { static: false }) comBonificacao: ElementRef;
  @ViewChild("composicao", { static: false }) composicao: ElementRef;

  /* Storage */

  is_master: boolean = (localStorage.getItem("is_master") == "true");
  is_manager: boolean = (localStorage.getItem("is_manager") == "true");
  is_master_seller: boolean = (localStorage.getItem("is_master_seller") == "true");
  is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");
  modelo_tela_ligacoes: number = parseInt(localStorage.getItem("modelo_tela_ligacoes"));
  obs_gerais: string = localStorage.getItem("obs_gerais");
  seller_id: number = parseInt(localStorage.getItem("id"));

  @HostListener("window:keydown", ["$event"])
  exibirComposicao(event: KeyboardEvent) {
    if (event.key === "F2") {
      this.modalComposicao(this.composicao);
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private pdfService: PdfService,
    private viewContainerRef: ViewContainerRef,
    private callCenterService: CallCenterService,
    private centralAtendimentoService: CentralAtendimentoService,
    private clienteService: ClienteService,
    private configuracaoService: ConfiguracaoService,
    private contaCorrenteService: ContaCorrenteService,
    private formaPagamentoService: FormaPagamentoService,
    private formatacaoService: FormatacaoService,
    private notaFiscalService: NotaFiscalService,
    private pedidoService: PedidoService,
    private pedidoStatusService: PedidoStatusService,
    private pedidoItensService: PedidoItensService,
    private prazoMedioService: PrazoMedioService,
    private prazoPagamentoClienteService: PrazoPagamentoClienteService,
    private prazoPagamentoService: PrazoPagamentoService,
    private produtoService: ProdutoService,
    private tempoProgressivoService: TempoProgressivoService,
    private tipoFreteService: TipoFreteService,
    private tipoPedidoService: TipoPedidoService,
    private transportadoraService: TransportadoraService,
    private vendedorService: VendedorService
  ) { }

  ngAfterViewInit() {
    this.centralAtendimentoService.maximizarEmitter.emit(false);
  }

  ngOnDestroy() {
    this.modalService.dismissAll();

    if (this.visitaSelecionadaExterna != 0 && this.retornarCallCenterLigacao == false) {
      this.atualizarEmAtendimento(false);

      this.tempoProgressivoService.encerrarTempo();

      /* Storage */

      localStorage.setItem("id_call_center_ligacao", "0");
    }
  }

  ngOnInit() {
    /* Filtro (Clientes) */

    this.formFiltroCliente = this.formBuilder.group({
      filtro: ["3"],
      codigo: ["", Validators.required],
      cnpj_cpf: ["", Validators.required],
      nome_fantasia: ["", Validators.required],
      razao_social: ["", Validators.required]
    });

    this.organizarFiltroCliente();

    /* Filtro (Produtos) */

    this.formFiltroProduto = this.formBuilder.group({
      filtro: [this.filtroProdutoSelecionado],
      codigo: ["", Validators.required],
      descricao: ["", Validators.required],
      marca: ["", Validators.required],
      referencia: ["", Validators.required],
      status: ["0"]
    });

    /* Filtro (Produtos (Campanhas)) */

    this.formFiltroProdutoCampanha = this.formBuilder.group({
      descricao: ["", Validators.required]
    });

    this.organizarFiltroProduto();

    /* Externo */

      /* Cliente (Externo) */

      let idCliente: string = "";

      this.route.queryParams.subscribe(parametros => { idCliente = parametros["cliente"] });

      if (idCliente != null) {
        this.clienteSelecionadoExterno = idCliente;
      }

      /* Pedido (Aprovar (Externo)) */

      let aprovar: string = "false";

      this.route.queryParams.subscribe(parametros => { aprovar = parametros["aprovar"] });

      if (aprovar != null) {
        this.pedidoAprovarExterno = (aprovar == "true");
      }

      /* Pedido (Duplicar (Externo)) */

      let duplicar: string = "false";

      this.route.queryParams.subscribe(parametros => { duplicar = parametros["duplicar"] });

      if (duplicar != null) {
        this.pedidoDuplicarExterno = (duplicar == "true");
      }

      /* Ligação (Externa) */

      let idLigacao: string = null;

      this.route.queryParams.subscribe(parametros => { idLigacao = parametros["ligacao"] });

      if (idLigacao != null && !isNaN(parseInt(idLigacao)) && isFinite(parseInt(idLigacao))) {
        this.ligacaoSelecionadaExterna = parseInt(idLigacao);
      }

      /* Vendedor (Externo) */

      let idVendedor: string = null;

      this.route.queryParams.subscribe(parametros => { idVendedor = parametros["vendedor"] });

      if (idVendedor != null && !isNaN(parseInt(idVendedor)) && isFinite(parseInt(idVendedor))) {
        this.vendedorSelecionadoExterno = parseInt(idVendedor);
      }

      /* Visita (Externa) */

      let idVisita: string = null;

      this.route.queryParams.subscribe(parametros => { idVisita = parametros["visita"] });

      if (idVisita != null && !isNaN(parseInt(idVisita)) && isFinite(parseInt(idVisita))) {
        this.visitaSelecionadaExterna = parseInt(idVisita);
      }

    this.carregarDados();
  }

  get fa() {
    return this.formFiltroProdutoCampanha.controls;
  }

  get fc() {
    return this.formFiltroCliente.controls;
  }

  get fp() {
    return this.formFiltroProduto.controls;
  }

  /* Carregamentos */

  carregarDados() {
    this.carregando = true;

    /* Configurações */

    this.configuracaoService.getByGuidClienteNapis().subscribe(
      configuracoes => this.configuracao = configuracoes,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.configuracao != null) {
          if (this.configuracao.prazo_pagamento_antes_itenspedido && this.configuracao.utiliza_fator_financeiro) {
            this.toastr.error("", "Configuração inválida!");

            this.router.navigate(["/pedidos"]);

            return;
          }

          if (this.configuracao.tipo_ordenacao_itens_pedido != null) {
            this.tipoOrdenacaoItensPedido = this.configuracao.tipo_ordenacao_itens_pedido;
          }

          /* Call Center (Configurações) */

          this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
            callCenterConfiguracao => this.callCenterConfiguracao = callCenterConfiguracao,
            error => { console.log("Erro: " + error) },
            () => {
              /* Produtos (Similares) */

              this.produtoService.getProdutoSimilarByGuidClienteNapis().subscribe(
                produtoSimilar => this.produtosSimilares = produtoSimilar,
                error => { console.log("Erro: " + error) },
                () => {
                  /* Prazo de Pagamento */

                  this.prazoPagamentoService.getByGuidClienteNapis().subscribe(
                    prazosPagamento => this.prazosPagamentoOriginal = prazosPagamento,
                    error => { console.log("Erro: " + error) },
                    () => {
                      this.prazosPagamentoOriginal.forEach((item) => {
                        item.descricao_inicial = item.descricao;
                        item.prazo_especial_inicial = item.prazo_especial;
                      });

                      /* Tipo Frete */

                      this.tipoFreteService.getByGuidClienteNapis().subscribe(
                        tiposFrete => this.tiposFrete = tiposFrete,
                        error => { console.log("Erro: " + error) },
                        () => {
                          if (this.tiposFrete.length > 0) {
                            this.tipoFreteSelecionado = this.tiposFrete.find(tipoFrete => tipoFrete.id == 4); /* EMITENTE (CIF) */

                            if (this.tipoFreteSelecionado == null) {
                              this.tipoFreteSelecionado = this.tiposFrete[0];
                            }
                          }

                          /* Tipo Pedido */

                          this.tipoPedidoService.getByGuidClienteNapis().subscribe(
                            tiposPedido => this.tiposPedido = tiposPedido,
                            error => { console.log("Erro: " + error) },
                            () => {
                              if (this.tiposPedido.length > 0) {
                                this.tiposPedidoSelecionado = this.tiposPedido.find(tipoPedido => tipoPedido.padrao == "S"); /* PADRÃO */

                                if (this.tiposPedidoSelecionado == null) {
                                  this.tiposPedidoSelecionado = this.tiposPedido[0];
                                }
                              }

                              /* Transportadoras */

                              this.transportadoraService.getByGuidClienteNapis(this.configuracao).subscribe(
                                transportadoras => this.transportadoras = transportadoras,
                                error => { console.log("Erro: " + error) },
                                () => {
                                  if (this.transportadoras.length > 0) {
                                    this.transportadoraSelecionada = this.transportadoras[0];
                                  }

                                  /* Vendedores */

                                  this.vendedorService.getByUsuario().subscribe(
                                    vendedores => this.vendedores = vendedores,
                                    error => { console.log("Erro: " + error) },
                                    () => {
                                      if (this.vendedores.length > 0) {
                                        this.vendedores.forEach((item) => {
                                          if (item.id == this.seller_id) {
                                            this.vendedorSelecionado = item;
                                          }
                                        });

                                        if (this.vendedorSelecionado == null) {
                                          this.vendedorSelecionado = this.vendedores[0];
                                        }
                                      }

                                      /* Forma de Pagamento */

                                      this.formaPagamentoService.getByGuidClienteNapis().subscribe(
                                        formasPagamento => this.formasPagamento = formasPagamento,
                                        error => { console.log("Erro: " + error) },
                                        () => {
                                          if (this.formasPagamento.length > 0) {
                                            this.formaPagamentoSelecionada = this.formasPagamento.find(formaPagamento => formaPagamento.padrao); /* PADRÃO */

                                            if (this.formaPagamentoSelecionada == null) {
                                              this.formaPagamentoSelecionada = this.formasPagamento[0];
                                            }
                                          }

                                          /* Produtos (Referências Cliente) */

                                          if (this.configuracao != null && this.configuracao.separar_produtos_por_referencia == "S") {
                                            this.produtoService.getReferenciaClienteByGuidClienteNapis().subscribe(
                                              produtosReferenciasCliente => this.produtosReferenciasCliente = produtosReferenciasCliente,
                                              error => { console.log("Erro: " + error) },
                                              () => {
                                                if (this.produtosReferenciasCliente.length > 0) {
                                                  this.produtoReferenciaClienteSelecionado = this.produtosReferenciasCliente[0].referencia_cliente;
                                                }

                                                this.verificarAdicionandoAlterando();

                                                this.carregando = false;
                                              }
                                            );
                                          } else {
                                            this.verificarAdicionandoAlterando();

                                            this.carregando = false;
                                          }
                                        }
                                      );
                                    }
                                  );
                                }
                              );
                            }
                          );
                        }
                      );
                    }
                  );
                }
              );
            }
          );
        }
      }
    );
  }

  carregarDadosAprovacao() {
    /* Pedidos (Status) */

    this.pedidoStatusService.getPedidoStatusByOrder(this.nroPedidoOrcamento).subscribe(
      pedidosStatus => this.pedidosStatus = pedidosStatus,
      error => { console.log("Erro: " + error) },
      () => {
        /* Cliente (Tabela) */

        this.clienteService.getTabelaByIdCliente(this.clienteSelecionado.client_id_app).subscribe(
          tabelaCliente => this.tabelasClientes = tabelaCliente,
          error => { console.log("Erro: " + error) },
          () => {
            /* Pedidos */

            this.pedidoService.getByCliente(this.clienteSelecionado.client_id_app).subscribe(
              pedidoCliente => this.pedidosClientes = pedidoCliente,
              error => { console.log("Erro: " + error) },
              () => {
                let pedidosEnviadosClientes = this.pedidosClientes.filter(pedidoCliente => pedidoCliente.order_sent_on != null && pedidoCliente.dt_integracao != null);

                this.pedidosEnviadosClientes = JSON.parse(JSON.stringify(pedidosEnviadosClientes));

                this.pedidosEnviadosClientes = this.pedidosEnviadosClientes.sort((a, b) => {
                  const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

                  let dataAnterior = this.datePipe.transform(a["order_sent_on"], "yyyy/MM/dd");
                  let proximaData = this.datePipe.transform(b["order_sent_on"], "yyyy/MM/dd");

                  return compare(dataAnterior, proximaData) * -1; /* Decrescente */
                });

                let pedidosNaoEnviadosClientes = this.pedidosClientes.filter(pedidoCliente => pedidoCliente.order_sent_on == null);

                this.pedidosNaoEnviadosClientes = JSON.parse(JSON.stringify(pedidosNaoEnviadosClientes));

                this.pedidosNaoEnviadosClientes.forEach((item) => {
                  let data = this.datePipe.transform(item.order_created_at, "yyyy/MM/dd");

                  if (data == this.datePipe.transform(new Date(), "yyyy/MM/dd")) {
                    this.pedidoNaoEnviadoClienteHoje = item;
                  }
                });

                this.pedidosNaoEnviadosClientes = this.pedidosNaoEnviadosClientes.sort((a, b) => {
                  const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

                  let dataAnterior = this.datePipe.transform(a["order_created_at"], "yyyy/MM/dd");
                  let proximaData = this.datePipe.transform(b["order_created_at"], "yyyy/MM/dd");

                  return compare(dataAnterior, proximaData) * -1; /* Decrescente */
                });

                /* Pedidos Itens */

                this.pedidoItensService.getByCliente(this.clienteSelecionado.client_id_app).subscribe(
                  pedidoItensCliente => this.pedidosItensClientes = pedidoItensCliente,
                  error => { console.log("Erro: " + error) },
                  () => {
                    if (this.pedidosItensClientes.length > 0) {
                      let pedidosItensEnviadosClientes = this.pedidosItensClientes.filter(pedidoItemCliente => pedidoItemCliente.order_sent_on != null && pedidoItemCliente.dt_integracao != null);

                      this.pedidosItensEnviadosClientes = JSON.parse(JSON.stringify(pedidosItensEnviadosClientes));

                      let pedidosItensNaoEnviadosClientes = this.pedidosItensClientes.filter(pedidoItemCliente => pedidoItemCliente.order_sent_on == null);

                      this.pedidosItensNaoEnviadosClientes = JSON.parse(JSON.stringify(pedidosItensNaoEnviadosClientes));

                      /* Grupos Estoque */

                      this.produtoService.getGrupoEstoqueByGuidClienteNapis().subscribe(
                        grupoEstoque => this.gruposEstoque = grupoEstoque,
                        error => { console.log("Erro: " + error) },
                        () => {
                          for (var i = 0; i < this.gruposEstoque.length; i++) {
                            let qtdeGrupoEstoque = this.pedidosItensEnviadosClientes.filter(pedidoItensClientes => pedidoItensClientes.grupo_estoque == this.gruposEstoque[i]).length;

                            if (qtdeGrupoEstoque > 0) {
                              this.gruposEstoque.splice(i, 1);

                              this.gruposEstoque = [...this.gruposEstoque];
                            }
                          }

                          this.prosseguirItemSelecionado(2);

                          this.carregando = false;
                        }
                      );
                    }
                  }
                );
              }
            );
          }
        );
      }
    );
  }

  carregarDadosPorCliente(inicial: boolean) {
    let retorno = new Promise<void>((resolver) => {
      if (this.clienteSelecionado != null) {
        /* Prazo de Pagamento (Cliente) */

        this.prazoPagamentoClienteService.getByClientIdApp(this.clienteSelecionado.client_id_app).subscribe(
          prazosPagamentoClientes => this.prazosPagamentoCliente = prazosPagamentoClientes,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.prazosPagamentoCliente.length > 0) {
              let prazoPagamentoCliente: PrazoPagamentoCliente = null;

              this.prazosPagamentoOriginal.forEach((item) => {
                prazoPagamentoCliente = this.prazosPagamentoCliente.find(prazoPagamentoCliente => prazoPagamentoCliente.id_prazo == item.idprazopagamento);

                if (prazoPagamentoCliente != null) {
                  if (item.prazo_especial_inicial != "S") {
                    item.prazo_especial = "N";
                    item.id_prazo_pagamento_cliente = prazoPagamentoCliente.id;
                    item.especial_cliente = false;
                  }
                } else {
                  item.prazo_especial = "S";
                  item.especial_cliente = true;
                }
              });
            } else {
              this.prazosPagamentoOriginal.forEach((item) => {
                item.prazo_especial = item.prazo_especial_inicial;
                item.descricao = item.descricao_inicial;
                item.id_prazo_pagamento_cliente = null;
                item.especial_cliente = false;
              });
            }

            /* Contas Correntes (Saldo) */

            this.contaCorrenteService.getSaldoByVendedor(this.clienteSelecionado.seller_id).subscribe(
              contasCorrentesSaldo => this.contaCorrenteSaldo = contasCorrentesSaldo,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.contaCorrenteSaldo != null) {
                  this.contaCorrenteSaldoDisponivel = this.contaCorrenteSaldo.saldo_final;
                } else {
                  this.contaCorrenteSaldoDisponivel = 0;
                }

                /* Cliente (Locais Entrega) */

                this.clienteService.getClienteLocalEntregaByCliente(this.clienteSelecionado.id).subscribe(
                  clienteLocalEntrega => this.clienteLocaisEntrega = clienteLocalEntrega,
                  error => { console.log("Erro: " + error) },
                  () => {
                    if (this.clienteLocalEntregaSelecionado != null) {
                      let clienteLocalEntrega = this.clienteLocaisEntrega.find(clienteLocalEntrega => clienteLocalEntrega.id == this.clienteLocalEntregaSelecionado.id)

                      if (clienteLocalEntrega != null) {
                        this.clienteLocalEntregaSelecionado = clienteLocalEntrega;
                      }
                    } else {
                      this.clienteLocalEntregaSelecionado = null;
                    }

                    /* Produtos */

                    let idPrazoPagamentoSelecionado: number = null;

                    if (this.prazoPagamentoSelecionado != null) {
                      idPrazoPagamentoSelecionado = this.prazoPagamentoSelecionado.idprazopagamento;
                    }

                    this.produtoService.getProdutoPromocionalByGuidClienteNapis(this.clienteSelecionado.client_id_app, this.obs_gerais, this.formaPagamentoSelecionada.id, idPrazoPagamentoSelecionado).subscribe(
                      produtos => this.produtos = produtos,
                      error => { console.log("Erro: " + error) },
                      () => {
                        this.produtos.forEach((item) => {
                          if (item.produtos_vinculados != null) {
                            /* Produtos Vinculados */

                            item.produtos_vinculados.forEach((subItem) => {
                              subItem.valor_tabela = 0;

                              if (item.grupo_estoque == this.combo) {
                                if (!subItem.bonificacao) {
                                  subItem.qtde = null;
                                }
                              } else {
                                subItem.qtde = subItem.qtde_minima;
                              }

                              subItem.bonificacao_selecionada = false;

                              subItem.exibir = true;
                              subItem.expandido = true;
                              subItem.valor = subItem.price_promotional == 0 ? subItem.price : subItem.price_promotional;
                              subItem.porcentagem_desconto = 0;
                              subItem.descritivo_desconto = "";
                              subItem.id_order_item = 0;
                              subItem.observacao_item = "";

                              /* Imposto */

                              subItem.valor_imposto = 0;
                              subItem.valor_imposto_unitario = 0;
                              subItem.valor_imposto_produto = null;
                              subItem.valor_imposto_total = 0;

                              subItem.valor_imposto_total_final = 0;

                              subItem.valor_unitario_final = 0;

                              /* Ordem */

                              subItem.ordem = 0;
                            });
                          }

                          /* Total */

                          if (item.categoria == this.campanhas) {
                            if (item.grupo_estoque == this.combo) {
                              item.valor_total = 0;
                              item.valor_total_produtos_vinculados = 0;
                            } else {
                              item.valor_total = item.price_promotional;
                              item.valor_total_produtos_vinculados = item.price_promotional;
                            }
                          } else {
                            item.valor_total = 0;
                            item.valor_total_produtos_vinculados = 0;
                          }

                          /* Lote */

                          item.id_lote = null;
                          item.nome_lote = null;
                          item.cod_lote = null;
                          item.estoque_lote = null;
                          item.validade_lote = null;
                          item.preco_lote = null;

                          /* Campos Agregados */

                          item.qtde = null;

                          item.bonificacao_selecionada = false;

                          /* Qtde Mínima */

                          if (item.produtos_vinculados != null) {
                            /* Produtos Vinculados */

                            if (item.grupo_estoque == this.combo) {
                              let qtdeMinimaAtual: number = item.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.qtde_minima), 0);

                              item.qtde_minima_atual = qtdeMinimaAtual;
                            } else {
                              item.qtde_minima_atual = 0;
                            }
                          } else {
                            item.qtde_minima_atual = 0;
                          }

                          /* Qtde Mínima (Existente) */

                          if (item.produtos_vinculados != null) {
                            /* Produtos Vinculados */

                            if (item.grupo_estoque == this.combo) {
                              let existeQtdeMinima: number = item.produtos_vinculados.filter(produto_vinculado => produto_vinculado.qtde_minima > 0).length;

                              if (existeQtdeMinima > 0) {
                                item.qtde_minima_existente = true;
                              } else {
                                item.qtde_minima_existente = false;
                              }
                            } else {
                              item.qtde_minima_existente = false;
                            }
                          } else {
                            item.qtde_minima_existente = false;
                          }

                          item.qtde_bonificacao_multiplicavel = 0;

                          if (item.produtos_vinculados != null) {
                            let existeLote: number = item.produtos_vinculados.filter(produto_vinculado => !produto_vinculado.bonificacao && produto_vinculado.qtde_lote > 0).length;

                            if (existeLote > 0) {
                              item.existe_lote = true;
                            } else {
                              item.existe_lote = false;
                            }
                          } else {
                            item.existe_lote = false;
                          }

                          item.exibir = false;

                          if (item.produtos_vinculados != null) {
                            /* Produtos Vinculados */

                            item.expandido = true;
                          } else {
                            item.expandido = false;
                          }

                          item.erro_lote = false;
                          item.erro_qtde = false;
                          item.erro_valor = false;
                          item.valor_tabela = 0;
                          item.valor = null;
                          item.porcentagem_desconto = 0;
                          item.descritivo_desconto = null;
                          item.id_order_item = 0;
                          item.observacao_item = null;

                          item.saldo_gerado = 0;

                          item.id_kit_combo = null;

                          /* Imposto */

                          item.valor_imposto = 0;
                          item.valor_imposto_unitario = 0;
                          item.valor_imposto_produto = null;
                          item.valor_imposto_total = 0;

                          item.valor_imposto_total_final = 0;

                          item.valor_unitario_final = 0;

                          /* Fator Financeiro */

                          item.porcentagem_fator = null;
                          item.valor_fator_financeiro_tabela = 0;
                          item.valor_fator_financeiro_inicial = 0;
                          item.valor_fator_financeiro_tabela_fracionado = 0;

                          /* Ordem */

                          item.ordem = 0;
                        });

                        if (this.pedido != null) {
                          /* Alterando */

                          this.produtos = this.produtos.filter(produto => produto.categoria != this.campanhas);
                        }

                        if (this.configuracao != null && this.configuracao.permitir_produtos_sem_estoque == "N") {
                          this.produtos = this.produtos.filter(produto => produto.categoria == this.campanhas || produto.estoque > 0);
                        }

                        if (this.configuracao != null && this.configuracao.separar_produtos_por_referencia == "S") {
                          this.produtos = this.produtos.filter(produto => produto.categoria == this.campanhas || produto.referencia_cliente == this.produtoReferenciaClienteSelecionado);
                        }

                        /* Produtos p/ Produtos Original */

                        this.produtosOriginal = JSON.parse(JSON.stringify(this.produtos));

                        this.organizarPrazoPagamento(false, inicial);

                        return resolver();
                      }
                    );
                  }
                );
              }
            );
          }
        );
      } else {
        return resolver();
      }
    });

    return retorno;
  }

  /* Adicionar Itens */

  ajustarElementos() {
    this.viewContainerRef.createEmbeddedView(this.adicionarItens);

    setTimeout(() => {
      let adicionarItensFiltroAltura: number = this.adicionarItensFiltro != null ? this.adicionarItensFiltro.nativeElement.offsetHeight : 0;
      let adicionasItensDadosAltura: number = this.adicionarItensDados != null ? this.adicionarItensDados.nativeElement.offsetHeight : 0;

      this.tamanhoProdutos = "calc(100% - " + adicionarItensFiltroAltura + "px - " + adicionasItensDadosAltura + "px - 38px)"; /* 38 = linhas (hr) */

      this.viewContainerRef.clear();
    }, 100);
  }

  calcularSimulacaoResultado() {
    let valorTotalCusto: number = this.produtosAdicionados.reduce((sum, current) => (sum + (current.custo * current.qtde)), 0);

    this.produtosAdicionados.forEach((item) => {
      if (item.produtos_vinculados != null) {
        /* Produtos Vinculados */

        let qtdeCampanha: number = item.qtde != null && item.qtde > 0 ? item.qtde : 0;

        item.produtos_vinculados.forEach((subItem) => {
          let qtdeProduto: number = subItem.qtde != null && subItem.qtde > 0 ? subItem.qtde : 0;

          valorTotalCusto = (valorTotalCusto + ((subItem.custo * qtdeProduto) * qtdeCampanha));
        });
      }
    });

    let valorTotal: number = this.produtosAdicionados.reduce((sum, current) => sum + current.valor_total, 0);

    if (this.configuracao == null || valorTotalCusto == 0 || valorTotal == 0) {
      this.porcentagemMarkupPedido = 0;
      this.simulacaoResultado = "0%";
    } else {
      this.porcentagemMarkupPedido = (1 - (valorTotalCusto / valorTotal)) * 100;

      this.porcentagemMarkupPedido = Math.round(this.porcentagemMarkupPedido * 100) / 100;

      if (this.porcentagemMarkupPedido <= this.configuracao.margem_lucratividade_vermelha) {
        this.simulacaoResultado = "0%";
      } else if (this.porcentagemMarkupPedido >= this.configuracao.margem_lucratividade_verde) {
        this.simulacaoResultado = "98%";
      } else {
        this.simulacaoResultado = ((((this.porcentagemMarkupPedido - this.configuracao.margem_lucratividade_vermelha) * 100) / (this.configuracao.margem_lucratividade_verde - this.configuracao.margem_lucratividade_vermelha)) + "%").toString();
      }
    }
  }

  excluirItemAdicionado(indice: number, id_produto: number, id_campanha: number) {
    if (this.validarErrosProdutosAdicionados(true) > 0) {
      return;
    }

    if (id_campanha != null) {
      /* Campanha */

      this.produtosAdicionados.splice(indice, 1);

      this.produtosAdicionados = [...this.produtosAdicionados];

      this.excluirProdutoCampanha(id_campanha);
    } else {
      /* Produto */

      let produtoAdicionado = this.produtosOriginal.find(produtoAdicionado => produtoAdicionado.id == id_produto);

      if (produtoAdicionado.id_kit_combo == null) {
        this.produtosAdicionados.splice(indice, 1);

        this.produtosAdicionados = [...this.produtosAdicionados];

        let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == id_produto);

        if (produtoOriginal != null) {
          produtoOriginal.erro_lote = false;
          produtoOriginal.erro_qtde = false;
          produtoOriginal.erro_valor = false;

          produtoOriginal.valor_tabela = 0;

          produtoOriginal.qtde = null;
          produtoOriginal.valor = null;

          produtoOriginal.valor_total = 0;
          produtoOriginal.porcentagem_desconto = 0;
          produtoOriginal.descritivo_desconto = null;
          produtoOriginal.observacao_item = null;

          produtoOriginal.saldo_gerado = 0;

          /* Lote */

          produtoOriginal.id_lote = null;
          produtoOriginal.nome_lote = null;
          produtoOriginal.cod_lote = null;
          produtoOriginal.estoque_lote = null;
          produtoOriginal.validade_lote = null;
          produtoOriginal.preco_lote = null;

          /* Ordem */

          produtoOriginal.ordem = 0;

          /* Qtde (Bonificada) */

          produtoOriginal.qtde_bonificada = null;

          /* Total Fracionado */

          produtoOriginal.valor_unitario_fracionado = null;
          produtoOriginal.valor_unitario_imposto_fracionado = null;

          /* Porcentagem Desconto Temporária */

          produtoOriginal.porcentagem_desconto_temporaria = null;
        }
      } else {
        this.toastr.warning("", "Produto originado de campanha...");

        return;
      }
    }

    this.ordenarProdutosAdicionados();
    this.totalizarProdutosAdicionados();

    if (this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido) {
      this.organizarPrazoPagamento(false, false);
    } else {
      this.calcularFatorFinanceiro();
    }

    this.calcularSimulacaoResultado();
    this.totalizarSaldoGerado();

    /* Produtos Original p/ Produtos */

    this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
  }

  expandirProduto(id_produto: number) {
    let produto = this.produtos.find(produto => produto.id == id_produto);

    if (produto != null) {
      if ((produto.qtde != 0 && produto.qtde != null) && (produto.valor != 0 && produto.valor != null)) {
        produto.expandido = true;
      } else {
        produto.expandido = !produto.expandido;
      }
    }
  }

  formatarProdutosAdicionados() {
    this.produtosAdicionados.forEach((item) => {
      if (item.produtos_vinculados != null) {
        /* Produtos Vinculados */

        item.produtos_vinculados.forEach((subItem) => {
          /* Imposto */

          subItem.valor_imposto = this.formatacaoService.quatroCasasDecimais(subItem.valor_imposto);
          subItem.valor_imposto_unitario = this.formatacaoService.quatroCasasDecimais(subItem.valor_imposto_unitario);
          subItem.valor_imposto_produto = this.formatacaoService.quatroCasasDecimais(subItem.valor_imposto_produto);
          subItem.valor_imposto_total = parseFloat(subItem.valor_imposto_total.toFixed(2));

          subItem.valor_imposto_total_final = subItem.valor_imposto_total;

          subItem.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(subItem.valor_unitario_final);

          /* Fator Financeiro */

          /* Processado */

          subItem.valor_fator_financeiro = this.formatacaoService.quatroCasasDecimais(subItem.valor_fator_financeiro);
          subItem.valor_fator_financeiro_unitario = this.formatacaoService.quatroCasasDecimais(subItem.valor_fator_financeiro_unitario);
          subItem.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(subItem.valor_fator_financeiro_produto);
          subItem.valor_fator_financeiro_total = parseFloat(subItem.valor_fator_financeiro_total.toFixed(2));
        });
      }

      /* Imposto */

      item.valor_imposto = this.formatacaoService.quatroCasasDecimais(item.valor_imposto);
      item.valor_imposto_unitario = this.formatacaoService.quatroCasasDecimais(item.valor_imposto_unitario);
      item.valor_imposto_produto = this.formatacaoService.quatroCasasDecimais(item.valor_imposto_produto);
      item.valor_imposto_total = parseFloat(item.valor_imposto_total.toFixed(2));

      item.valor_imposto_total_final = item.valor_imposto_total;

      item.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(item.valor_unitario_final);

      /* Fator Financeiro */

      /* Processado */

      item.valor_fator_financeiro = this.formatacaoService.quatroCasasDecimais(item.valor_fator_financeiro);
      item.valor_fator_financeiro_unitario = this.formatacaoService.quatroCasasDecimais(item.valor_fator_financeiro_unitario);
      item.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(item.valor_fator_financeiro_produto);
      item.valor_fator_financeiro_total = parseFloat(item.valor_fator_financeiro_total.toFixed(2));
    });
  }

  ordenarProdutosAdicionados() {
    switch (this.tipoOrdenacaoItensPedido) {
      case 1:
        /* Ordenar = Inclusão */

        this.produtosAdicionados.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a["ordem"], b["ordem"]);
        });

        break;
      default:
        /* Ordernar = Alfabética */

        this.produtosAdicionados.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a["name"], b["name"]);
        });
    }

    this.produtosAdicionadosOriginal = JSON.parse(JSON.stringify(this.produtosAdicionados));
  }

  reiniciarDadosProduto() {
    this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
  }

  totalizarProdutosAdicionados() {
    this.formatarProdutosAdicionados();

    this.valorParcialProdutosAdicionados = this.produtosAdicionados.filter(produtoAdicionado => produtoAdicionado.qtde > 0).reduce((sum, current) => sum + current.valor_imposto_total, 0);

    /* Peso */

    this.valorTotalPesoProdutosAdicionados = this.produtosAdicionados.filter(produtoAdicionado => produtoAdicionado.qtde > 0).reduce((sum, current) => (sum + ((current.peso != null ? current.peso : 0) * current.qtde)), 0);
    this.valorTotalPesoProdutosAdicionados = this.valorTotalPesoProdutosAdicionados + this.produtosAdicionados.filter(produtoAdicionado => produtoAdicionado.qtde_bonificada > 0).reduce((sum, current) => (sum + ((current.peso != null ? current.peso : 0) * current.qtde_bonificada)), 0);

    this.produtosAdicionados.forEach((item) => {
      if (item.categoria == this.campanhas) {
        item.produtos_vinculados.forEach((subItem) => {
          this.valorTotalPesoProdutosAdicionados = this.valorTotalPesoProdutosAdicionados + (((subItem.peso != null ? subItem.peso : 0) * subItem.qtde) * item.qtde);
        });
      }
    });

    this.valorTotalProdutosAdicionados = this.produtosAdicionados.filter(produtoAdicionado => produtoAdicionado.qtde > 0).reduce((sum, current) => sum + current.valor_imposto_total_final, 0);
    this.valorTotalProdutosAdicionadosFatorFinanceiro = this.produtosAdicionados.filter(produtoAdicionado => produtoAdicionado.qtde > 0).reduce((sum, current) => sum + current.valor_fator_financeiro, 0);
    this.valorTotalProdutosAdicionadosImposto = this.produtosAdicionados.filter(produtoAdicionado => produtoAdicionado.qtde > 0).reduce((sum, current) => sum + current.valor_imposto, 0);
  }

  /* Adicionar Itens (Campanha) */

  atualizarDadosCampanha(id_campanha: number) {
    let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id_campanha == id_campanha);

    if (produtoOriginal != null) {
      let produto = this.produtos.find(produto => produto.id_campanha == id_campanha);

      if (produto != null) {
        /* Produtos Original p/ Produtos */

        if (produtoOriginal.produtos_vinculados != null) {
          /* Qtde Mínima */

          if (produtoOriginal.grupo_estoque == this.combo) {
            produto.qtde_minima_atual = produtoOriginal.qtde_minima_atual;
          }

          produto.qtde_bonificacao_multiplicavel = produtoOriginal.qtde_bonificacao_multiplicavel;

          /* Produtos Vinculados */

          produto.produtos_vinculados = JSON.parse(JSON.stringify(produtoOriginal.produtos_vinculados));
        }

        produto.erro_qtde = false;

        produto.qtde = produtoOriginal.qtde;
        produto.valor = produtoOriginal.valor;

        if (produtoOriginal.grupo_estoque == this.combo) {
          produto.valor_total = produtoOriginal.valor_total;
          produto.valor_total_produtos_vinculados = produtoOriginal.valor_total_produtos_vinculados;
        }

        /* Imposto */

        produto.valor_imposto = produtoOriginal.valor_imposto;
        produto.valor_imposto_unitario = produtoOriginal.valor_imposto_unitario;
        produto.valor_imposto_produto = produtoOriginal.valor_imposto_produto;
        produto.valor_imposto_total = produtoOriginal.valor_imposto_total;

        produto.valor_imposto_total_final = produto.valor_imposto_total;

        produto.valor_unitario_final = produtoOriginal.valor_unitario_final;

        /* Fator Financeiro */

        /* Processado */

        produto.porcentagem_fator = produtoOriginal.porcentagem_fator;
        produto.valor_fator_financeiro = produtoOriginal.valor_fator_financeiro;
        produto.valor_fator_financeiro_unitario = produtoOriginal.valor_fator_financeiro_unitario;
        produto.valor_fator_financeiro_produto = produtoOriginal.valor_fator_financeiro_produto;
        produto.valor_fator_financeiro_total = produtoOriginal.valor_fator_financeiro_total;

        /* Ordem */

        produto.ordem = produtoOriginal.ordem;
      }
    }
  }

  decrementarQtdeCampanha() {
    if (this.produtoCampanhaSelecionado != null) {
      if (this.produtoCampanhaSelecionado.qtde_minima_atual < this.produtoCampanhaSelecionado.qtde_minima) {
        this.toastr.error("", "Qtde Mínima de Itens inválida!");

        this.produtoCampanhaSelecionado.qtde = null;

        return;
      } else {
        this.produtoCampanhaSelecionado.qtde--;

        if (this.produtoCampanhaSelecionado.qtde < 1) {
          this.produtoCampanhaSelecionado.qtde = null;
        }

        /* Bonificação */

        this.definirQtdeBonificacaoMultiplicavel(false);
      }

      /* Totalizar */

      if (this.produtoCampanhaSelecionado.grupo_estoque == this.combo) {
        this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.valor_total_produtos_vinculados == 0 ? null : this.produtoCampanhaSelecionado.valor_total_produtos_vinculados;

        let valorTotal: number = this.produtoCampanhaSelecionado.valor_total_produtos_vinculados * this.produtoCampanhaSelecionado.qtde;

        this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
      } else {
        if (this.produtoCampanhaSelecionado.price_promotional > 0) {
          this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.price_promotional == 0 ? null : this.produtoCampanhaSelecionado.price_promotional;

          let valorTotal: number = this.produtoCampanhaSelecionado.price_promotional * this.produtoCampanhaSelecionado.qtde;

          this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
        } else {
          this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.price == 0 ? null : this.produtoCampanhaSelecionado.price;

          let valorTotal: number = this.produtoCampanhaSelecionado.price * this.produtoCampanhaSelecionado.qtde;

          this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
        }
      }

      this.calcularImpostoCampanha();
      this.validarCampanhaQtde();
    }
  }

  decrementarQtdeCampanhaProduto(id_produto: number) {
    if (this.produtoCampanhaSelecionado != null) {
      let produtoVinculado = this.produtoCampanhaSelecionado.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == id_produto);

      if (produtoVinculado != null) {
        let qtde: number = produtoVinculado.qtde;

        if (produtoVinculado.multiplo != null && produtoVinculado.multiplo != 0 && produtoVinculado.multiplo != 1) {
          /* C/ Múltiplo */

          if (qtde <= 1) {
            qtde = 0;
          } else {
            let qtdeFutura: number = qtde - produtoVinculado.multiplo;

            if (qtdeFutura == 0 || qtdeFutura % produtoVinculado.multiplo != 0) {
              qtde = 0;
            } else {
              qtde = qtdeFutura;
            }
          }
        } else {
          /* S/ Múltiplo */

          if (qtde <= 1) {
            qtde = 0;
          } else {
            qtde--;
          }
        }

        produtoVinculado.qtde = qtde;

        this.validarCampanhaProdutoQtde(id_produto);
      }
    }
  }

  excluirProdutoCampanha(id_campanha: number) {
    let produtoCampanhaSelecionado: ProdutoPromocional = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id_campanha == id_campanha);

    if (produtoCampanhaSelecionado != null) {
      /* Produto Inicial */

      if (produtoCampanhaSelecionado.grupo_estoque == this.combo) {
        if (produtoCampanhaSelecionado.produtos_vinculados != null) {
          /* Produtos Vinculados */

          produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
            if (!item.bonificacao) {
              item.qtde = null;
            }

            if (produtoCampanhaSelecionado.grupo_estoque == this.combo) {
              item.valor_total = 0;
            }
          });
        }
      }

      produtoCampanhaSelecionado.qtde = null;
      produtoCampanhaSelecionado.valor = null;

      if (produtoCampanhaSelecionado.grupo_estoque == this.combo) {
        produtoCampanhaSelecionado.valor_total = 0;
        produtoCampanhaSelecionado.valor_total_produtos_vinculados = 0;
      }

      this.calcularFatorFinanceiro();
      this.atualizarDadosCampanha(id_campanha);
    }
  }

  incrementarQtdeCampanha() {
    if (this.produtoCampanhaSelecionado != null) {
      if (this.produtoCampanhaSelecionado.qtde_minima_atual < this.produtoCampanhaSelecionado.qtde_minima) {
        this.toastr.error("", "Qtde Mínima de Itens inválida!");

        this.produtoCampanhaSelecionado.qtde = null;

        return;
      } else {
        this.produtoCampanhaSelecionado.qtde++;

        /* Bonificação */

        this.definirQtdeBonificacaoMultiplicavel(false);
      }

      /* Totalizar */

      if (this.produtoCampanhaSelecionado.grupo_estoque == this.combo) {
        this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.valor_total_produtos_vinculados == 0 ? null : this.produtoCampanhaSelecionado.valor_total_produtos_vinculados;

        let valorTotal: number = this.produtoCampanhaSelecionado.valor_total_produtos_vinculados * this.produtoCampanhaSelecionado.qtde;

        this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
      } else {
        if (this.produtoCampanhaSelecionado.price_promotional > 0) {
          this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.price_promotional == 0 ? null : this.produtoCampanhaSelecionado.price_promotional;

          let valorTotal: number = this.produtoCampanhaSelecionado.price_promotional * this.produtoCampanhaSelecionado.qtde;

          this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
        } else {
          this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.price == 0 ? null : this.produtoCampanhaSelecionado.price;

          let valorTotal: number = this.produtoCampanhaSelecionado.price * this.produtoCampanhaSelecionado.qtde;

          this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
        }
      }

      this.calcularImpostoCampanha();
      this.validarCampanhaQtde();
    }
  }

  incrementarQtdeCampanhaProduto(id_produto: number) {
    if (this.produtoCampanhaSelecionado != null) {
      let produtoVinculado = this.produtoCampanhaSelecionado.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == id_produto);

      if (produtoVinculado != null) {
        let qtde: number = produtoVinculado.qtde;

        if (produtoVinculado.multiplo != null && produtoVinculado.multiplo != 0 && produtoVinculado.multiplo != 1) {
          /* C/ Múltiplo */

          if (qtde <= 0 || qtde == null) {
            qtde = produtoVinculado.multiplo;
          } else {
            let qtdeFutura: number = qtde + produtoVinculado.multiplo;

            if (qtdeFutura % produtoVinculado.multiplo != 0) {
              qtde = produtoVinculado.multiplo;
            } else {
              qtde = qtdeFutura;
            }
          }
        } else {
          /* S/ Múltiplo */

          if (qtde <= 0 || qtde == null) {
            qtde = 1;
          } else {
            qtde++;
          }
        }

        produtoVinculado.qtde = qtde;

        this.validarCampanhaProdutoQtde(id_produto);
      }
    }
  }

  repassarDadosCampanha(cancelando: boolean) {
    let retorno = new Promise<void>((resolver) => {
      if (this.produtoCampanhaSelecionado != null) {
        let produtoAdicionado = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id_campanha == this.produtoCampanhaSelecionado.id_campanha);

        if (cancelando) {
          let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id_campanha == this.produtoCampanhaSelecionado.id_campanha);

          if (produtoAdicionado != null) {
            /* Produtos Adicionados p/ Produtos Original */

            if (produtoOriginal.produtos_vinculados != null) {
              /* Qtde Mínima */

              if (produtoOriginal.grupo_estoque == this.combo) {
                produtoOriginal.qtde_minima_atual = produtoAdicionado.qtde_minima_atual;
              }

              produtoOriginal.qtde_bonificacao_multiplicavel = produtoAdicionado.qtde_bonificacao_multiplicavel;

              /* Produtos Vinculados */

              produtoOriginal.produtos_vinculados = JSON.parse(JSON.stringify(produtoAdicionado.produtos_vinculados));
            }

            produtoOriginal.erro_qtde = false;

            produtoOriginal.qtde = produtoAdicionado.qtde;
            produtoOriginal.valor = produtoAdicionado.valor;

            if (produtoOriginal.grupo_estoque == this.combo) {
              produtoOriginal.valor_total = produtoAdicionado.valor_total;
              produtoOriginal.valor_total_produtos_vinculados = produtoAdicionado.valor_total_produtos_vinculados;
            }

            /* Imposto */

            produtoOriginal.valor_imposto = produtoAdicionado.valor_imposto;
            produtoOriginal.valor_imposto_unitario = produtoAdicionado.valor_imposto_unitario;
            produtoOriginal.valor_imposto_produto = produtoAdicionado.valor_imposto_produto;
            produtoOriginal.valor_imposto_total = produtoAdicionado.valor_imposto_total;

            produtoOriginal.valor_imposto_total_final = produtoOriginal.valor_imposto_total;

            produtoOriginal.valor_unitario_final = produtoAdicionado.valor_unitario_final;

            /* Fator Financeiro */

            /* Processado */

            produtoOriginal.porcentagem_fator = produtoAdicionado.porcentagem_fator;
            produtoOriginal.valor_fator_financeiro = produtoAdicionado.valor_fator_financeiro;
            produtoOriginal.valor_fator_financeiro_unitario = produtoAdicionado.valor_fator_financeiro_unitario;
            produtoOriginal.valor_fator_financeiro_produto = produtoAdicionado.valor_fator_financeiro_produto;
            produtoOriginal.valor_fator_financeiro_total = produtoAdicionado.valor_fator_financeiro_total;

            /* Ordem */

            produtoOriginal.ordem = 0;
          } else {
            /* Produto Inicial */

            if (produtoOriginal.grupo_estoque == this.combo) {
              if (produtoOriginal.produtos_vinculados != null) {
                /* Produtos Vinculados */

                produtoOriginal.produtos_vinculados.forEach((item) => {
                  item.qtde = null;
                });
              }
            }

            produtoOriginal.qtde = null;
            produtoOriginal.valor = null;

            if (produtoOriginal.grupo_estoque == this.combo) {
              produtoOriginal.valor_total = 0;
              produtoOriginal.valor_total_produtos_vinculados = 0;
            }

            this.calcularFatorFinanceiro();
          }
        } else {
          /* Produto Selecionado p/ Produtos Original */

          let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id_campanha == this.produtoCampanhaSelecionado.id_campanha);

          if (produtoOriginal != null) {
            if (produtoOriginal.produtos_vinculados != null) {
              /* Qtde Mínima */

              if (produtoOriginal.grupo_estoque == this.combo) {
                produtoOriginal.qtde_minima_atual = this.produtoCampanhaSelecionado.qtde_minima_atual;
              }

              produtoOriginal.qtde_bonificacao_multiplicavel = this.produtoCampanhaSelecionado.qtde_bonificacao_multiplicavel;

              /* Produtos Vinculados */

              produtoOriginal.produtos_vinculados = JSON.parse(JSON.stringify(this.produtoCampanhaSelecionado.produtos_vinculados));
            }

            produtoOriginal.erro_qtde = false;

            produtoOriginal.qtde = this.produtoCampanhaSelecionado.qtde;
            produtoOriginal.valor = this.produtoCampanhaSelecionado.valor;

            if (produtoOriginal.grupo_estoque == this.combo) {
              produtoOriginal.valor_total = this.produtoCampanhaSelecionado.valor_total;
              produtoOriginal.valor_total_produtos_vinculados = this.produtoCampanhaSelecionado.valor_total_produtos_vinculados;
            }

            /* Imposto */

            produtoOriginal.valor_imposto = this.produtoCampanhaSelecionado.valor_imposto;
            produtoOriginal.valor_imposto_unitario = this.produtoCampanhaSelecionado.valor_imposto_unitario;
            produtoOriginal.valor_imposto_produto = this.produtoCampanhaSelecionado.valor_imposto_produto;
            produtoOriginal.valor_imposto_total = this.produtoCampanhaSelecionado.valor_imposto_total;

            produtoOriginal.valor_imposto_total_final = produtoOriginal.valor_imposto_total;

            produtoOriginal.valor_unitario_final = this.produtoCampanhaSelecionado.valor_unitario_final;

            /* Fator Financeiro */

            /* Processado */

            produtoOriginal.porcentagem_fator = this.produtoCampanhaSelecionado.porcentagem_fator;
            produtoOriginal.valor_fator_financeiro = this.produtoCampanhaSelecionado.valor_fator_financeiro;
            produtoOriginal.valor_fator_financeiro_unitario = this.produtoCampanhaSelecionado.valor_fator_financeiro_unitario;
            produtoOriginal.valor_fator_financeiro_produto = this.produtoCampanhaSelecionado.valor_fator_financeiro_produto;
            produtoOriginal.valor_fator_financeiro_total = this.produtoCampanhaSelecionado.valor_fator_financeiro_total;

            /* Ordem */

            produtoOriginal.ordem = this.produtoCampanhaSelecionado.ordem;
          }
        }
      }

      return resolver();
    });

    return retorno;
  }

  /* Campanha (Bonificação) */

  alterarBonificacao(id_produto: string) {
    let idProduto: number = parseInt(id_produto);

    if (this.produtoCampanhaSelecionado != null) {
      if (this.produtoCampanhaSelecionado.produtos_vinculados != null) {
        /* Produtos Vinculados */

        this.produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
          if (item.bonificacao) {
            /* Bonificação */

            if (item.id == idProduto) {
              item.bonificacao_selecionada = true;

              this.bonificacaoInvalida = false;
            } else {
              item.bonificacao_selecionada = false;
            }
          }
        });
      }
    }
  }

  /* Campanha (Qtde (Bonificação)) */

  definirQtdeBonificacaoMultiplicavel(validar: boolean) {
    if (this.produtoCampanhaSelecionado != null) {
      if (this.produtoCampanhaSelecionado.preco_bonificacao == "B") {
        /* Bonificação */

        let qtdeBonificacaoMultiplicavel: number = 0;

        if (this.produtoCampanhaSelecionado.bonificacao_multiplicavel) {
          /* Multiplicável */

          if (this.produtoCampanhaSelecionado.qtde_minima > 0) {
            qtdeBonificacaoMultiplicavel = this.produtoCampanhaSelecionado.qtde_minima_atual / this.produtoCampanhaSelecionado.qtde_minima;

            qtdeBonificacaoMultiplicavel = parseInt(qtdeBonificacaoMultiplicavel.toString()) * this.produtoCampanhaSelecionado.qtde;

            if (qtdeBonificacaoMultiplicavel < 1) {
              qtdeBonificacaoMultiplicavel = null;
            }
          } else {
            qtdeBonificacaoMultiplicavel = this.produtoCampanhaSelecionado.qtde;
          }

          this.produtoCampanhaSelecionado.qtde_bonificacao_multiplicavel = qtdeBonificacaoMultiplicavel;
        } else {
          this.produtoCampanhaSelecionado.qtde_bonificacao_multiplicavel = 1;
        }
      }

      if (validar) {
        this.validarCampanhaQtdeGeral();
      }
    }
  }

  localizarProdutoBonificado() {
    if (this.produtoCampanhaSelecionado != null) {
      if (this.produtoCampanhaSelecionado.preco_bonificacao == "B" && !this.produtoCampanhaSelecionado.bonificacao_selecionavel) {
        if (this.produtoCampanhaSelecionado.produtos_vinculados != null) {
          /* Produtos Vinculados */

          this.produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
            item.bonificacao_selecionada = false;
          });

          let produtosVinculados = this.produtoCampanhaSelecionado.produtos_vinculados.filter(produtosVinculados => produtosVinculados.qtde > 0 && !produtosVinculados.bonificacao);

          produtosVinculados = JSON.parse(JSON.stringify(produtosVinculados));

          if (produtosVinculados.length > 0) {
            produtosVinculados.sort((a, b) => {
              const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

              return compare(a["price_promotional"], b["price_promotional"]);
            });

            let produtoVinculado = this.produtoCampanhaSelecionado.produtos_vinculados.find(produtoVinculado => produtoVinculado.id == produtosVinculados[0].id);

            if (produtoVinculado != null) {
              produtoVinculado.bonificacao_selecionada = true;
            }
          }
        }
      }
    }
  }

  /* Campanha (Qtde (Combo)) */

  validarQtdeMinimaCombo() {
    if (this.produtoCampanhaSelecionado != null) {
      if (this.produtoCampanhaSelecionado.grupo_estoque == this.combo) {
        let qtdeMinimaAtual: number = this.produtoCampanhaSelecionado.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao && !produtosVinculados.erro_qtde).reduce((sum, current) => (sum + current.qtde), 0);

        this.produtoCampanhaSelecionado.qtde_minima_atual = qtdeMinimaAtual;
      }
    }
  }

  /* Campanha (Totalizar) */

  totalizarProdutoCampanhaSelecionado() {
    /* Totalizar */

    if (this.produtoCampanhaSelecionado.grupo_estoque == this.combo) {
      this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.valor_total_produtos_vinculados == 0 ? null : this.produtoCampanhaSelecionado.valor_total_produtos_vinculados;

      let valorTotal: number = this.produtoCampanhaSelecionado.valor_total_produtos_vinculados * this.produtoCampanhaSelecionado.qtde;

      this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
    } else {
      if (this.produtoCampanhaSelecionado.price_promotional > 0) {
        this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.price_promotional == 0 ? null : this.produtoCampanhaSelecionado.price_promotional;

        let valorTotal: number = this.produtoCampanhaSelecionado.price_promotional * this.produtoCampanhaSelecionado.qtde;

        this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
      } else {
        this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.price == 0 ? null : this.produtoCampanhaSelecionado.price;

        let valorTotal: number = this.produtoCampanhaSelecionado.price * this.produtoCampanhaSelecionado.qtde;

        this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
      }
    }
  }

  /* Composição */

  selecionarOrigemComposicao(origem_composicao: string) {
    switch(origem_composicao) {
      case "0":
        // Produtos

        this.produtosComposicoes = JSON.parse(JSON.stringify(this.produtos));

        break;
      case "1":
        // Produtos (Adicionados)

        this.produtosComposicoes = JSON.parse(JSON.stringify(this.produtosAdicionados));

        break;
      case "2":
        // Produtos (Adicionados (Original))

        this.produtosComposicoes = JSON.parse(JSON.stringify(this.produtosAdicionadosOriginal));

        break;
      case "3":
        // Produtos (Original)

        this.produtosComposicoes = JSON.parse(JSON.stringify(this.produtosOriginal));

        break;
      case "4":
        // Produtos (Selecionado)

        this.produtosComposicoes = [];

        if (this.produtoCampanhaSelecionado != null) {
          this.produtosComposicoes.push(JSON.parse(JSON.stringify(this.produtoCampanhaSelecionado)));
        }

        break;
      default:
        this.produtosComposicoes = [];

        break;
    }

    this.produtosComposicoes.forEach((item) => {
      item.expandido = false;
    });
  }

  expandirProdutoCampanhaComposicao(id_campanha: number) {
    this.produtosComposicoes.forEach((item) => {
      if (item.id_campanha == id_campanha) {
        item.expandido = !item.expandido;
      } else {
        item.expandido = false;
      }
    });
  }

  /* Adicionar Itens (Produto) */

  decrementarQtdeProduto(id_produto: number) {
    let produto = this.produtos.find(produto => produto.id == id_produto);

    if (produto != null) {
      if (produto.id_kit_combo == null) {
        if (produto.qtde_lote == 0 || (produto.qtde_lote > 0 && produto.id_lote != null)) {
          let qtde: number = produto.qtde;

          if ((this.tiposPedidoSelecionado != null && this.tiposPedidoSelecionado.flag_considerar_pedido_como_venda) && (produto.multiplo != null && produto.multiplo != 0 && produto.multiplo != 1)) {
            /* C/ Múltiplo */

            if (qtde <= 1) {
              qtde = null;
            } else {
              let qtdeFutura: number = qtde - produto.multiplo;

              if (qtdeFutura == 0 || qtdeFutura % produto.multiplo != 0) {
                qtde = null;
              } else {
                qtde = qtdeFutura;
              }
            }
          } else {
            /* S/ Múltiplo */

            if (qtde <= 1) {
              qtde = null;
            } else {
              qtde--;
            }
          }

          produto.qtde = qtde;

          this.validarProdutoQtde(id_produto, true);
        }
      } else {
        this.toastr.warning("", "Produto originado de campanha...");
      }
    }
  }

  definirOrdemInclusao(id_produto: number, com_dados: boolean) {
    let produtoOriginal: ProdutoPromocional = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == id_produto);

    if (produtoOriginal != null) {
      if (com_dados) {
        if (produtoOriginal.ordem == 0) {
          let ordemAtual: number = Math.max(...this.produtosOriginal.map(produtoOriginal => produtoOriginal.ordem));

          produtoOriginal.ordem = ordemAtual + 1;
        }
      } else {
        produtoOriginal.ordem = 0;
      }
    }
  }

  incrementarQtdeProduto(id_produto: number) {
    let produto = this.produtos.find(produto => produto.id == id_produto);

    if (produto != null) {
      if (produto.id_kit_combo == null) {
        if (produto.qtde_lote == 0 || (produto.qtde_lote > 0 && produto.id_lote != null)) {
          let qtde: number = produto.qtde;

          if ((this.tiposPedidoSelecionado != null && this.tiposPedidoSelecionado.flag_considerar_pedido_como_venda) && (produto.multiplo != null && produto.multiplo != 0 && produto.multiplo != 1)) {
            /* C/ Múltiplo */

            if (qtde <= 0 || qtde == null) {
              qtde = produto.multiplo;
            } else {
              let qtdeFutura: number = qtde + produto.multiplo;

              if (qtdeFutura % produto.multiplo != 0) {
                qtde = produto.multiplo;
              } else {
                qtde = qtdeFutura;
              }
            }
          } else {
            /* S/ Múltiplo */

            if (qtde <= 0 || qtde == null) {
              qtde = 1;
            } else {
              qtde++;
            }
          }

          produto.qtde = qtde;

          this.validarProdutoQtde(id_produto, true);
        }
      } else {
        this.toastr.warning("", "Produto originado de campanha...");
      }
    }
  }

  definirPorcentagemDescontoTemporario(porcentagem_desconto_temporaria: number) {
    this.porcentagemDescontoTemporario = porcentagem_desconto_temporaria;
  }

  repassarDadosProduto(cancelando: boolean) {
    let retorno = new Promise<void>((resolver) => {
      this.produtos.forEach((item) => {
        if (item.categoria != this.campanhas) {
          /* Produto */

          let produtoAdicionadoOriginal = this.produtosAdicionadosOriginal.find(produtoAdicionadoOriginal => produtoAdicionadoOriginal.id == item.id);

          if ((item.qtde_lote > 0 && item.id_lote != null) || (item.qtde != null || item.qtde_bonificada != null) || item.valor != null) {
            /* Com valores */

            if (cancelando) {
              if (produtoAdicionadoOriginal != null) {
                /* Produtos Adicionados p/ Produtos Original */

                let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == item.id);

                if (produtoOriginal != null) {
                  produtoOriginal.erro_lote = produtoAdicionadoOriginal.erro_lote;

                  if (this.tiposPedidoErro) {
                    produtoOriginal.erro_qtde = false;
                  } else {
                    produtoOriginal.erro_qtde = produtoAdicionadoOriginal.erro_qtde;
                  }

                  produtoOriginal.erro_valor = produtoAdicionadoOriginal.erro_valor;

                  produtoOriginal.valor_tabela = produtoAdicionadoOriginal.valor_tabela;

                  produtoOriginal.qtde = produtoAdicionadoOriginal.qtde;
                  produtoOriginal.valor = produtoAdicionadoOriginal.valor;

                  produtoOriginal.valor_total = produtoAdicionadoOriginal.valor_total;
                  produtoOriginal.porcentagem_desconto = produtoAdicionadoOriginal.porcentagem_desconto;
                  produtoOriginal.descritivo_desconto = produtoAdicionadoOriginal.descritivo_desconto;
                  produtoOriginal.observacao_item = produtoAdicionadoOriginal.observacao_item;

                  produtoOriginal.saldo_gerado = produtoAdicionadoOriginal.saldo_gerado;

                  /* Lote */

                  produtoOriginal.id_lote = produtoAdicionadoOriginal.id_lote;
                  produtoOriginal.nome_lote = produtoAdicionadoOriginal.nome_lote;
                  produtoOriginal.cod_lote = produtoAdicionadoOriginal.cod_lote;
                  produtoOriginal.estoque_lote = produtoAdicionadoOriginal.estoque_lote;
                  produtoOriginal.validade_lote = produtoAdicionadoOriginal.validade_lote;
                  produtoOriginal.preco_lote = produtoAdicionadoOriginal.preco_lote;

                  /* Ordem */

                  produtoOriginal.ordem = produtoAdicionadoOriginal.ordem;

                  /* Qtde (Bonificada) */

                  produtoOriginal.qtde_bonificada = produtoAdicionadoOriginal.qtde_bonificada;

                  /* Total Fracionado */

                  produtoOriginal.valor_unitario_fracionado = produtoAdicionadoOriginal.valor_unitario_fracionado;
                  produtoOriginal.valor_unitario_imposto_fracionado = produtoAdicionadoOriginal.valor_unitario_imposto_fracionado;

                  /* Porcentagem Desconto (Temporária) */

                  produtoOriginal.porcentagem_desconto_temporaria = produtoAdicionadoOriginal.porcentagem_desconto_temporaria;
                }
              } else {
                /* Produto Inicial */

                let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == item.id);

                if (produtoOriginal != null) {
                  produtoOriginal.erro_lote = false;
                  produtoOriginal.erro_qtde = false;
                  produtoOriginal.erro_valor = false;

                  produtoOriginal.valor_tabela = 0;

                  produtoOriginal.qtde = null;
                  produtoOriginal.valor = null;

                  produtoOriginal.valor_total = 0;
                  produtoOriginal.porcentagem_desconto = 0;
                  produtoOriginal.descritivo_desconto = null;
                  produtoOriginal.observacao_item = null;

                  produtoOriginal.saldo_gerado = 0;

                  /* Lote */

                  produtoOriginal.id_lote = null;
                  produtoOriginal.nome_lote = null;
                  produtoOriginal.cod_lote = null;
                  produtoOriginal.estoque_lote = null;
                  produtoOriginal.validade_lote = null;
                  produtoOriginal.preco_lote = null;

                  /* Ordem */

                  produtoOriginal.ordem = 0;

                  /* Qtde (Bonificada) */

                  produtoOriginal.qtde_bonificada = null;

                  /* Total Fracionado */

                  produtoOriginal.valor_unitario_fracionado = null;
                  produtoOriginal.valor_unitario_imposto_fracionado = null;

                  /* Porcentagem Desconto (Temporária) */

                  produtoOriginal.porcentagem_desconto_temporaria = null;
                }
              }
            } else {
              /* Produtos p/ Produtos Original */

              let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == item.id);

              if (produtoOriginal != null) {
                if (item.qtde_lote > 0 && item.id_lote == null) {
                  produtoOriginal.erro_lote = true;
                  produtoOriginal.erro_qtde = false;
                  produtoOriginal.erro_valor = false;
                } else {
                  produtoOriginal.erro_lote = false;

                  if (item.qtde_bonificada == null || item.qtde_bonificada <= 0) {
                    if ((item.qtde == 0 || item.qtde == null) && (item.valor != 0 && item.valor != null)) {
                      produtoOriginal.erro_qtde = true;
                    } else {
                      produtoOriginal.erro_qtde = item.erro_qtde;
                    }
                  } else {
                    /* Qtde (Bonificada) */

                    produtoOriginal.erro_qtde = false;
                  }

                  if (item.qtde_bonificada == null || item.qtde_bonificada <= 0) {
                    if ((item.qtde != 0 && item.qtde != null) && (item.valor == 0 || item.valor == null)) {
                      produtoOriginal.erro_valor = true;
                    } else {
                      produtoOriginal.erro_valor = item.erro_valor;
                    }
                  } else {
                    /* Qtde (Bonificada) */

                    if (item.valor == 0 || item.valor == null) {
                      produtoOriginal.erro_valor = true;
                    } else {
                      produtoOriginal.erro_valor = item.erro_valor;
                    }
                  }
                }

                produtoOriginal.valor_tabela = item.valor_tabela;

                produtoOriginal.qtde = item.qtde;
                produtoOriginal.valor = item.valor;

                produtoOriginal.valor_total = item.valor_total;
                produtoOriginal.porcentagem_desconto = item.porcentagem_desconto;
                produtoOriginal.descritivo_desconto = item.descritivo_desconto;
                produtoOriginal.observacao_item = item.observacao_item;

                /* Lote */

                produtoOriginal.id_lote = item.id_lote;
                produtoOriginal.nome_lote = item.nome_lote;
                produtoOriginal.cod_lote = item.cod_lote;
                produtoOriginal.estoque_lote = item.estoque_lote;
                produtoOriginal.validade_lote = item.validade_lote;
                produtoOriginal.preco_lote = item.preco_lote;

                /* Qtde (Bonificada) */

                produtoOriginal.qtde_bonificada = item.qtde_bonificada;

                /* Total Fracionado */

                produtoOriginal.valor_unitario_fracionado = item.valor_unitario_fracionado;
                produtoOriginal.valor_unitario_imposto_fracionado = item.valor_unitario_imposto_fracionado;

                /* Porcentagem Desconto (Temporária) */

                produtoOriginal.porcentagem_desconto_temporaria = item.porcentagem_desconto_temporaria;
              }
            }
          } else {
            /* Sem valores */

            if (produtoAdicionadoOriginal != null) {
              /* Produtos Adicionados p/ Produtos Original */

              let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == item.id);

              if (produtoOriginal != null) {
                if (cancelando) {
                  produtoOriginal.erro_lote = produtoAdicionadoOriginal.erro_lote;
                  produtoOriginal.erro_qtde = produtoAdicionadoOriginal.erro_qtde;
                  produtoOriginal.erro_valor = produtoAdicionadoOriginal.erro_valor;

                  produtoOriginal.valor_tabela = produtoAdicionadoOriginal.valor_tabela;

                  produtoOriginal.qtde = produtoAdicionadoOriginal.qtde;
                  produtoOriginal.valor = produtoAdicionadoOriginal.valor;

                  produtoOriginal.valor_total = produtoAdicionadoOriginal.valor_total;
                  produtoOriginal.porcentagem_desconto = produtoAdicionadoOriginal.porcentagem_desconto;
                  produtoOriginal.descritivo_desconto = produtoAdicionadoOriginal.descritivo_desconto;
                  produtoOriginal.observacao_item = produtoAdicionadoOriginal.observacao_item;

                  produtoOriginal.saldo_gerado = produtoAdicionadoOriginal.saldo_gerado;

                  /* Lote */

                  produtoOriginal.id_lote = produtoAdicionadoOriginal.id_lote;
                  produtoOriginal.nome_lote = produtoAdicionadoOriginal.nome_lote;
                  produtoOriginal.cod_lote = produtoAdicionadoOriginal.cod_lote;
                  produtoOriginal.estoque_lote = produtoAdicionadoOriginal.estoque_lote;
                  produtoOriginal.validade_lote = produtoAdicionadoOriginal.validade_lote;
                  produtoOriginal.preco_lote = produtoAdicionadoOriginal.preco_lote;

                  /* Ordem */

                  produtoOriginal.ordem = produtoAdicionadoOriginal.ordem;

                  /* Qtde (Bonificada) */

                  produtoOriginal.qtde_bonificada = produtoAdicionadoOriginal.qtde_bonificada;

                  /* Total Fracionado */

                  produtoOriginal.valor_unitario_fracionado = produtoAdicionadoOriginal.valor_unitario_fracionado;
                  produtoOriginal.valor_unitario_imposto_fracionado = produtoAdicionadoOriginal.valor_unitario_imposto_fracionado;

                  /* Porcentagem Desconto (Temporária) */

                  produtoOriginal.porcentagem_desconto_temporaria = produtoAdicionadoOriginal.porcentagem_desconto_temporaria;
                } else {
                  /* Produtos p/ Produtos Original */

                  if (item.qtde_lote > 0 && item.id_lote == null) {
                    produtoOriginal.erro_lote = true;
                    produtoOriginal.erro_qtde = false;
                    produtoOriginal.erro_valor = false;
                  } else {
                    produtoOriginal.erro_lote = false;

                    if (item.qtde_bonificada == null || item.qtde_bonificada <= 0) {
                      if (item.qtde == 0 || item.qtde == null) {
                        produtoOriginal.erro_qtde = true;
                      } else {
                        produtoOriginal.erro_qtde = item.erro_qtde;
                      }
                    } else {
                      /* Qtde (Bonificada) */

                      produtoOriginal.erro_qtde = item.erro_qtde;
                    }

                    if (item.valor == 0 || item.valor == null) {
                      produtoOriginal.erro_valor = true;
                    } else {
                      produtoOriginal.erro_valor = item.erro_valor;
                    }
                  }

                  produtoOriginal.valor_tabela = item.valor_tabela;

                  produtoOriginal.qtde = item.qtde;
                  produtoOriginal.valor = item.valor;

                  produtoOriginal.valor_total = item.valor_total;
                  produtoOriginal.porcentagem_desconto = item.porcentagem_desconto;
                  produtoOriginal.descritivo_desconto = item.descritivo_desconto;
                  produtoOriginal.observacao_item = item.observacao_item;

                  /* Lote */

                  produtoOriginal.id_lote = item.id_lote;
                  produtoOriginal.nome_lote = item.nome_lote;
                  produtoOriginal.cod_lote = item.cod_lote;
                  produtoOriginal.estoque_lote = item.estoque_lote;
                  produtoOriginal.validade_lote = item.validade_lote;
                  produtoOriginal.preco_lote = item.preco_lote;

                  /* Qtde (Bonificada) */

                  produtoOriginal.qtde_bonificada = item.qtde_bonificada;

                  /* Total Fracionado */

                  produtoOriginal.valor_unitario_fracionado = item.valor_unitario_fracionado;
                  produtoOriginal.valor_unitario_imposto_fracionado = item.valor_unitario_imposto_fracionado;

                  /* Porcentagem Desconto (Temporária) */

                  produtoOriginal.porcentagem_desconto_temporaria = item.porcentagem_desconto_temporaria;
                }
              }
            } else {
              /* Produto Inicial */

              let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == item.id);

              if (produtoOriginal != null) {
                produtoOriginal.erro_lote = false;
                produtoOriginal.erro_qtde = false;
                produtoOriginal.erro_valor = false;

                produtoOriginal.valor_tabela = 0;

                produtoOriginal.qtde = null;
                produtoOriginal.valor = null;

                produtoOriginal.valor_total = 0;
                produtoOriginal.porcentagem_desconto = 0;
                produtoOriginal.descritivo_desconto = null;
                produtoOriginal.observacao_item = null;

                produtoOriginal.saldo_gerado = 0;

                /* Lote */

                produtoOriginal.id_lote = null;
                produtoOriginal.nome_lote = null;
                produtoOriginal.cod_lote = null;
                produtoOriginal.estoque_lote = null;
                produtoOriginal.validade_lote = null;
                produtoOriginal.preco_lote = null;

                /* Ordem */

                produtoOriginal.ordem = 0;

                /* Qtde (Bonificada) */

                produtoOriginal.qtde_bonificada = null;

                /* Total Fracionado */

                produtoOriginal.valor_unitario_fracionado = null;
                produtoOriginal.valor_unitario_imposto_fracionado = null;

                /* Porcentagem Desconto (Temporária) */

                produtoOriginal.porcentagem_desconto_temporaria = null;
              }
            }
          }
        }
      });

      this.calcularFatorFinanceiro();

      return resolver();
    });

    return retorno;
  }

  selecionarDescontoProduto(id_produto: number, po_desconto: number) {
    let produto = this.produtos.find(produto => produto.id == id_produto);

    if (produto != null) {
      if (produto.id_kit_combo == null) {
        if (po_desconto > 0) {
          produto.porcentagem_desconto_temporaria = po_desconto;

          this.calcularDescontoUnitario(id_produto, true, false);
          this.validarProdutoValor(id_produto, true);
        }
      } else {
        this.toastr.warning("", "Produto originado de campanha...");
      }
    }
  }

  selecionarValorProduto(id_produto: number, preco_unitario: number) {
    let produto = this.produtos.find(produto => produto.id == id_produto);

    if (produto != null) {
      if (produto.id_kit_combo == null) {
        if (preco_unitario > 0) {
          produto.erro_valor = false;
          
          produto.valor_tabela = parseFloat(preco_unitario.toFixed(2));
          produto.valor = parseFloat(preco_unitario.toFixed(2));
          produto.porcentagem_desconto_temporaria = null;

          if (this.modalReferenciaTabelasProdutos != null) {
            this.modalReferenciaTabelasProdutos.close();
          }

          this.calcularImpostoProduto(id_produto, false);
          this.validarProdutoQtde(produto.id, true);
        }
      } else {
        this.toastr.warning("", "Produto originário de campanha...");
      }
    }
  }

  totalizarSaldoGerado() {
    // Parcial

    let saldoParcial: number = this.produtosOriginal.reduce((sum, current) => (sum + current.saldo_gerado), 0);

    this.saldoGeradoValorParcial = parseFloat(saldoParcial.toFixed(2));

    // Final

    let saldoFinal: number = this.produtosAdicionados.reduce((sum, current) => (sum + current.saldo_gerado), 0);

    this.saldoGeradoValorFinal = parseFloat(saldoFinal.toFixed(2));
  }

  validarColunas(produto: ProdutoPromocional): number {
    let colunas: number = 0;

    if (produto.estoque != null || produto.estoque_lote != null) {
      if (produto.qtde_lote == 0 || (produto.qtde_lote > 0 && produto.id_lote != null)) {
        colunas = 1;
      }
    }

    if (produto.fracionado && produto.estoque_fracionado != null && produto.estoque != null) {
      colunas++;
    }

    if (produto.qtde_lote > 0 && produto.id_lote != null && produto.validade_lote != null) {
      colunas++
    }

    if ((produto.qtde_lote > 0 && produto.id_lote != null)) {
      colunas++;
    }

    return colunas;
  }

  /* Validação (Adicionar Itens (Campanha)) */

  validarErrosCampanha(): boolean {
    if (this.produtoCampanhaSelecionado != null) {
      let qtdeErros = this.produtoCampanhaSelecionado.produtos_vinculados.filter(produtoAdicionado => produtoAdicionado.erro_qtde).length;

      let produtosComErro: boolean = false;
      let qtdeMinimaInvalida: boolean = false;

      if (qtdeErros > 0) {
        if (qtdeErros == 1) {
          this.toastr.error("", "Produto com erro(s)!");
        } else {
          this.toastr.error("", "Existem produtos com erro(s)!");
        }

        produtosComErro = true;
      }

      if (this.produtoCampanhaSelecionado.qtde_minima_atual < this.produtoCampanhaSelecionado.qtde_minima) {
        this.toastr.error("", "Qtde Mínima de Itens inválida!");

        qtdeMinimaInvalida = true;
      }

      if (this.produtoCampanhaSelecionado.qtde == null) {
        this.toastr.error("", "Qtde inválida!");

        this.produtoCampanhaSelecionado.erro_qtde = true;
      } else {
        if (this.configuracao != null && this.configuracao.permitir_produtos_sem_estoque == "N") {
          let existeProdutoAcimaDoEstoque: boolean = false;

          this.produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
            if (!existeProdutoAcimaDoEstoque) {
              if (!item.bonificacao) {
                let qtdeMultiplicada: number = item.qtde * this.produtoCampanhaSelecionado.qtde;

                if (item.bonificacao_selecionada) {
                  qtdeMultiplicada = qtdeMultiplicada + this.produtoCampanhaSelecionado.qtde_bonificacao_multiplicavel;
                }

                if (qtdeMultiplicada > item.estoque) {
                  this.toastr.error("", "Qtde de produto(s) acima do estoque...");

                  this.produtoCampanhaSelecionado.erro_qtde = true;

                  existeProdutoAcimaDoEstoque = true;
                }
              }
            }
          });
        }
      }

      if (this.produtoCampanhaSelecionado.bonificacao_selecionavel) {
        /* Bonificação (Selecionável) */

        let produtoBonificado = this.produtoCampanhaSelecionado.produtos_vinculados.find(produtosVinculados => produtosVinculados.bonificacao_selecionada);

        if (produtoBonificado == null) {
          this.toastr.error("", "Bonificação inválida!");

          this.bonificacaoInvalida = true;
        } else {
          this.bonificacaoInvalida = false;
        }
      }

      if (produtosComErro || qtdeMinimaInvalida || this.produtoCampanhaSelecionado.erro_qtde || this.bonificacaoInvalida) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  validarCampanhaProdutoQtde(id_produto: number) {
    if (this.produtoCampanhaSelecionado != null) {
      let produtoVinculado = this.produtoCampanhaSelecionado.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == id_produto);

      if (produtoVinculado != null) {
        if (produtoVinculado.qtde_minima > 0) {
          if (produtoVinculado.qtde == null || produtoVinculado.qtde <= 0 || produtoVinculado.qtde < produtoVinculado.qtde_minima) {
            this.toastr.error("", "Qtde abaixo da qtde mínima...");

            produtoVinculado.erro_qtde = true;

            this.validarQtdeMinimaCombo();

            return;
          } else {
            produtoVinculado.erro_qtde = false;
          }
        } else {
          produtoVinculado.erro_qtde = false;
        }

        if (produtoVinculado.qtde != 0 && produtoVinculado.qtde != null) {
          if (this.configuracao != null && !this.configuracao.flag_permite_produtos_acima_do_estoque) {
            let qtdeFinal: number = produtoVinculado.qtde;

            if (produtoVinculado.bonificacao_selecionada) {
              qtdeFinal = qtdeFinal + this.produtoCampanhaSelecionado.qtde_bonificacao_multiplicavel;
            }

            let qtdeEstoque: number = 0;

            if (produtoVinculado.qtde_lote == 0) {
              qtdeEstoque = produtoVinculado.estoque
            } else {
              qtdeEstoque = produtoVinculado.estoque_lote;
            }

            if (qtdeFinal > qtdeEstoque) {
              this.toastr.error("", "Qtde acima do estoque...");

              produtoVinculado.erro_qtde = true;

              this.validarQtdeMinimaCombo();

              return;
            } else {
              produtoVinculado.erro_qtde = false;
            }
          }

          if (produtoVinculado.multiplo != null && produtoVinculado.multiplo != 0) {
            if (produtoVinculado.qtde % produtoVinculado.multiplo != 0) {
              this.toastr.error("", "Qtde somente múltipla de " + produtoVinculado.multiplo + "...");

              produtoVinculado.erro_qtde = true;

              this.validarQtdeMinimaCombo();

              return;
            } else {
              produtoVinculado.erro_qtde = false;
            }
          }
        }

        this.validarQtdeMinimaCombo();

        /* Bonificação */

        this.localizarProdutoBonificado();
        this.definirQtdeBonificacaoMultiplicavel(false);

        /* Totalizar (Produtos Vinculados) */

        if (this.produtoCampanhaSelecionado.grupo_estoque == this.combo) {
          let valorTotal: number = produtoVinculado.valor * produtoVinculado.qtde;

          produtoVinculado.valor_total = parseFloat(valorTotal.toFixed(2));

          this.produtoCampanhaSelecionado.valor_total_produtos_vinculados = this.produtoCampanhaSelecionado.produtos_vinculados.reduce((sum, current) => (sum + current.valor_total), 0);
        }

        this.totalizarProdutoCampanhaSelecionado();
        this.calcularFatorFinanceiroCampanhaProduto(id_produto);
      }
    }
  }

  validarCampanhaQtde() {
    if (this.produtoCampanhaSelecionado != null) {
      if (this.produtoCampanhaSelecionado.qtde <= 0) {
        this.produtoCampanhaSelecionado.qtde = null;

        return;
      } else {
        this.produtoCampanhaSelecionado.erro_qtde = false;
      }

      if (this.configuracao != null && this.configuracao.permitir_produtos_sem_estoque == "N") {
        let existeProdutoAcimaDoEstoque: boolean = false;

        this.produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
          if (!existeProdutoAcimaDoEstoque) {
            if (!item.bonificacao) {
              let qtdeMultiplicada: number = item.qtde * this.produtoCampanhaSelecionado.qtde;

              if (item.bonificacao_selecionada) {
                qtdeMultiplicada = qtdeMultiplicada + this.produtoCampanhaSelecionado.qtde_bonificacao_multiplicavel;
              }

              if (qtdeMultiplicada > item.estoque) {
                this.toastr.error("", "Qtde de produto(s) acima do estoque...");

                this.produtoCampanhaSelecionado.erro_qtde = true;

                existeProdutoAcimaDoEstoque = true;
              }
            }
          }
        });
      }

      if (this.produtoCampanhaSelecionado.qtde_minima_atual < this.produtoCampanhaSelecionado.qtde_minima) {
        if (this.produtoCampanhaSelecionado.qtde > 0) {
          this.toastr.error("", "Qtde Mínima de Itens inválida!");

          this.produtoCampanhaSelecionado.qtde = null;

          /* Bonificação */

          this.definirQtdeBonificacaoMultiplicavel(true);

          return;
        }
      } else {
        /* Bonificação */

        this.definirQtdeBonificacaoMultiplicavel(true);
      }

      /* Totalizar (Produtos Vinculados) */

      this.totalizarProdutoCampanhaSelecionado();
      this.calcularFatorFinanceiroCampanha();
    }
  }

  validarCampanhaQtdeGeral() {
    let retorno = new Promise<void>((resolver) => {
      if (this.produtoCampanhaSelecionado != null) {
        this.produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
          if (item.bonificacao) {
            /* Bonificação */

            if (this.configuracao != null && !this.configuracao.flag_permite_produtos_acima_do_estoque) {
              if (this.produtoCampanhaSelecionado.bonificacao_selecionavel) {
                /* Bonificação (Selecionável) */

                if (item.qtde > item.estoque) {
                  item.exibir = false;
                } else {
                  item.exibir = true;
                }
              }
            }
          } else {
            if (this.configuracao != null && !this.configuracao.flag_permite_produtos_acima_do_estoque) {
              let qtdeFinal: number = item.qtde;

              if (item.bonificacao_selecionada) {
                qtdeFinal = qtdeFinal + this.produtoCampanhaSelecionado.qtde_bonificacao_multiplicavel;
              }

              let qtdeEstoque: number = 0;

              if (item.qtde_lote == 0) {
                qtdeEstoque = item.estoque
              } else {
                qtdeEstoque = item.estoque_lote;
              }

              if (qtdeFinal > qtdeEstoque) {
                this.toastr.error("", "Qtde acima do estoque...");

                item.erro_qtde = true;

                return;
              } else {
                item.erro_qtde = false;
              }
            }

            if (item.multiplo != null && item.multiplo != 0) {
              if (item.qtde % item.multiplo != 0) {
                this.toastr.error("", "Qtde somente múltipla de " + item.multiplo + "...");

                item.erro_qtde = true;

                return;
              } else {
                item.erro_qtde = false;
              }
            } else {
              item.erro_qtde = false;
            }
          }
        });

        this.validarQtdeMinimaCombo();

        return resolver();
      }
    });

    return retorno;
  }

  /* Validação/Verificação (Adicionar Itens (Produto)) */

  agruparProdutosComErro() {
    let retorno = new Promise<void>((resolver) => {
      this.produtosComErro = [];

      this.produtosOriginal.filter(produtoOriginal => produtoOriginal.erro_lote || produtoOriginal.erro_qtde || produtoOriginal.erro_valor).forEach((item) => {
        item.exibir = true;
        item.expandido = true;

        let produtoComErro: ProdutoPromocional = { ...item };

        this.produtosComErro.push(produtoComErro);
      });

      return resolver();
    });

    return retorno;
  }

  organizarProdutosAdicionados() {
    if (this.validarErrosProdutosAdicionados(false) == 0) {
      /* Total */

      this.totalizarProdutosAdicionados();

      /* Prazo de Pagamento */

      this.organizarPrazoPagamento(true, false);

      /* Simulação (Resultado) */

      this.calcularSimulacaoResultado();

      /* Saldo */

      this.totalizarSaldoGerado();
    } else {
      /* Total */

      this.valorParcialProdutosAdicionados = 0;
      this.valorTotalProdutosAdicionados = 0;
      this.valorTotalProdutosAdicionadosFatorFinanceiro = 0;
      this.valorTotalProdutosAdicionadosImposto = 0;

      /* Simulação (Resultado) */

      this.porcentagemMarkupPedido = 0;
      this.simulacaoResultado = "0%";

      /* Saldo */

      this.saldoGeradoValorParcial = 0;
      this.saldoGeradoValorFinal = 0
    }
  }

  validarErrosProduto(cancelando: boolean) {
    let retorno = new Promise<void>((resolver) => {
      const repassarDadosProduto = this.repassarDadosProduto(cancelando);

      repassarDadosProduto.then(() => {
        const agruparProdutosComErro = this.agruparProdutosComErro();

        agruparProdutosComErro.then(() => {
          if (this.produtosComErro.length > 0) {
            if (this.produtosComErro.length == 1) {
              this.toastr.error("", "Item com erro(s)!");
            } else {
              this.toastr.error("", "Existem itens com erro(s)!");
            }

            this.produtos = JSON.parse(JSON.stringify(this.produtosComErro));

            this.enviadoProduto = true;
          } else {
            this.produtosOriginal.filter(produto => ((produto.qtde != 0 && produto.qtde != null) || (produto.qtde_bonificada != 0 && produto.qtde_bonificada != null)) && (produto.valor == 0 || produto.valor == null)).forEach((item) => {
              item.qtde = null;
              item.qtde_bonificada = null;
            });

            this.produtosOriginal.filter(produto => (produto.qtde == 0 || produto.qtde == null) && (produto.qtde_bonificada == 0 || produto.qtde_bonificada == null) && (produto.valor != 0 && produto.valor != null)).forEach((item) => {
              item.valor = null;
            });
          }

          this.totalizarSaldoGerado();

          return resolver();
        });
      });
    });

    return retorno;
  }

  validarErrosProdutosAdicionados(alerta: boolean): number {
    let produtosAdicionadosComErro: number = this.produtosAdicionados.filter(produtoAdicionado => produtoAdicionado.erro_valor).length;

    if (produtosAdicionadosComErro > 0) {
      if (alerta) {
        if (produtosAdicionadosComErro == 1) {
          this.toastr.error("", "Item com erro(s)!");
        } else {
          this.toastr.error("", "Existem itens com erro(s)!");
        }
      }
    }

    return produtosAdicionadosComErro;
  }

  validarProdutoFator(id_produto: number, foco?: boolean) {
    let produto: ProdutoPromocional = this.produtos.find(produto => produto.id == id_produto);

    if (produto != null) {
      if (foco) {
        this.calcularFatorFinanceiroProduto(produto.id, false);
      }
    }
  }

  validarProdutoQtde(id_produto: number, foco: boolean, event?: any) {
    /* Qtde */

    let produto: ProdutoPromocional = this.produtos.find(produto => produto.id == id_produto);

    if (produto != null) {
      if (produto.qtde_bonificada == null && produto.qtde_bonificada <= 0) {
        if (produto.qtde != null && produto.qtde <= 0) {
          produto.erro_qtde = true;
        } else if ((produto.qtde == 0 || produto.qtde == null) && (produto.qtde_lote > 0 && produto.id_lote != null)) {
          produto.erro_qtde = true;
        } else if ((produto.qtde == 0 || produto.qtde == null) && (produto.valor != 0 && produto.valor != null)) {
          produto.erro_qtde = true;
        } else if ((produto.qtde != 0 && produto.qtde != null) && (produto.valor == 0 || produto.valor == null)) {
          produto.erro_qtde = false;
          produto.erro_valor = true;
        } else if ((produto.qtde != 0 && produto.qtde != null) && (produto.valor != 0 && produto.valor != null)) {
          produto.erro_qtde = false;
          produto.erro_valor = false;
        } else {
          let produtoAdicionado = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id == id_produto);

          if (produtoAdicionado != null) {
            if (produto.qtde == 0 || produto.qtde == null) {
              produto.erro_qtde = true;
            } else {
              produto.erro_qtde = false;
            }

            if (produto.valor == 0 || produto.valor == null) {
              produto.erro_valor = true;
            } else {
              produto.erro_valor = false;
            }
          } else {
            produto.erro_qtde = false;
            produto.erro_valor = false;
          }
        }
      } else {
        /* Qtde (Bonificada) */

        produto.erro_qtde = false;

        if ((produto.valor != 0 && produto.valor != null)) {
          produto.erro_valor = false;
        } else {
          produto.erro_valor = true;
        }
      }

      if (foco) {
        this.calcularImpostoProduto(produto.id, false);
        this.verificarProdutoQtde(produto);
        this.verificarProdutoValor(produto, event, false);
      }
    }
  }

  validarProdutoValor(id_produto: number, foco?: boolean, event?: any, adicionado?: boolean) {
    /* Valor */

    let produto: ProdutoPromocional = null;

    if (adicionado) {
      produto = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id == id_produto);
    } else {
      produto = this.produtos.find(produto => produto.id == id_produto);
    }

    if (produto != null) {
      if (produto.qtde_bonificada == null && produto.qtde_bonificada <= 0) {
        if (produto.valor != null && produto.valor <= 0) {
          produto.erro_valor = true;
        } else if ((produto.valor == 0 || produto.valor == null) && (produto.qtde_lote > 0 && produto.id_lote != null)) {
          produto.erro_valor = true;
        } else if ((produto.qtde != 0 && produto.qtde != null) && (produto.valor == 0 || produto.valor == null)) {
          produto.erro_valor = true;
        } else if ((produto.qtde == 0 || produto.qtde == null) && (produto.valor != 0 && produto.valor != null)) {
          produto.erro_qtde = true;
          produto.erro_valor = false;
        } else if ((produto.qtde != 0 && produto.qtde != null) && (produto.valor != 0 && produto.valor != null)) {
          produto.erro_qtde = false;
          produto.erro_valor = false;
        } else {
          let produtoAdicionado = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id == id_produto);

          if (produtoAdicionado != null) {
            if (produto.qtde == 0 || produto.qtde == null) {
              produto.erro_qtde = true;
            } else {
              produto.erro_qtde = false;
            }

            if (produto.valor == 0 || produto.valor == null) {
              produto.erro_valor = true;
            } else {
              produto.erro_valor = false;
            }
          } else {
            produto.erro_qtde = false;
            produto.erro_valor = false;
          }
        }
      } else {
        /* Qtde (Bonificada) */

        produto.erro_qtde = false;

        if ((produto.valor != 0 && produto.valor != null)) {
          produto.erro_valor = false;
        } else {
          produto.erro_valor = true;
        }
      }

      if (foco) {
        this.calcularImpostoProduto(produto.id, adicionado);
        this.verificarProdutoQtde(produto);
        this.verificarProdutoValor(produto, event, adicionado);
      }
    }
  }

  verificarProdutoQtde(produto: ProdutoPromocional) {
    if (produto.qtde != 0 && produto.qtde != null) {
      if (this.configuracao != null && !this.configuracao.flag_permite_produtos_acima_do_estoque) {
        let qtdeEstoque: number = 0;

        if (produto.qtde_lote == 0) {
          qtdeEstoque = produto.estoque
        } else {
          qtdeEstoque = produto.estoque_lote;
        }

        if (produto.qtde > qtdeEstoque) {
          this.toastr.error("", "Qtde acima do estoque...");

          produto.erro_qtde = true;

          return;
        }
      }

      if ((this.tiposPedidoSelecionado != null && this.tiposPedidoSelecionado.flag_considerar_pedido_como_venda) && (produto.multiplo != null && produto.multiplo != 0)) {
        if (produto.qtde % produto.multiplo != 0) {
          this.toastr.error("", "Qtde somente múltipla de " + produto.multiplo + "...");

          produto.erro_qtde = true;

          return;
        }
      }
    }
  }

  verificarProdutoValor(produto: ProdutoPromocional, event: any, adicionado: boolean) {
    if (((produto.qtde != 0 && produto.qtde != null) || (produto.qtde_bonificada != 0 && produto.qtde_bonificada != null)) && (produto.valor != 0 && produto.valor != null)) {
      this.calculando = true;

      let valor: number = produto.valor;
      let qtdeFinal: number = produto.qtde != 0 && produto.qtde != null ? produto.qtde : produto.qtde_bonificada;

      let produtoDesconto: ProdutoDesconto = null;

      let valorFatorFinanceiroInicial: number = parseFloat(produto.valor_fator_financeiro_inicial.toFixed(2));

      this.produtoService.getDescontoByProduto(this.clienteSelecionado.id, produto.id, valor, qtdeFinal, this.vendedorSelecionado.id, produto.id_lote, valorFatorFinanceiroInicial).subscribe(
        produtosDescontos => produtoDesconto = produtosDescontos,
        error => { console.log("Erro: " + error) },
        () => {
          produto.valor_tabela = produtoDesconto.preco_base;

          produto.porcentagem_desconto = produtoDesconto.desconto_concedido;
          produto.descritivo_desconto = produtoDesconto.descritivo_desconto;

          /* Porcentagem Desconto Temporária */

          if (produtoDesconto.desconto_concedido > 0.01) {
            produto.porcentagem_desconto_temporaria = this.formatacaoService.quatroCasasDecimais(produtoDesconto.desconto_concedido);
          } else {
            produto.porcentagem_desconto_temporaria = null;
          }

          let porcentagemDescontoMaximo: number = null;

          if (produto.desc_maximo != null && produto.desc_maximo != 0) {
            porcentagemDescontoMaximo = produto.desc_maximo;
          }

          if (this.configuracao != null && this.configuracao.usa_conta_corrente_flex) {
            if (produto.cc_flex_desc_maximo != null && produto.cc_flex_desc_maximo != 0) {
              if (produto.cc_flex_desc_maximo > porcentagemDescontoMaximo) {
                porcentagemDescontoMaximo = produto.cc_flex_desc_maximo;
              }
            }

            if (porcentagemDescontoMaximo != null) {
              let valorCalculoInicial: number = produto.valor_tabela != null && produto.valor_tabela > 0 ? produto.valor_tabela : produto.price;

              if (this.prazoPagamentoSelecionado != null && this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido && this.configuracao.utiliza_fator_financeiro) {
                if (produto.aplica_fator_financeiro) {
                  /* C/ Fator */

                  valorCalculoInicial = produto.valor_fator_financeiro_tabela != null ? produto.valor_fator_financeiro_tabela : 0;
                }
              }

              let valorAtual: number = this.formatacaoService.duasCasasDecimais(produto.valor);
              let valorDescontoMaximo: number = (valorCalculoInicial - ((valorCalculoInicial * porcentagemDescontoMaximo) / 100));
              let valorMaximo: number = this.formatacaoService.duasCasasDecimais((valorDescontoMaximo * 100) / 100);

              if (valorAtual < valorMaximo) {
                this.toastr.error("", "Porcentagem máxima de desconto: " + porcentagemDescontoMaximo + "%...");

                produto.erro_valor = true;

                this.calcularFatorFinanceiroProduto(produto.id, adicionado);

                if (adicionado) {
                  this.organizarProdutosAdicionados();
                }

                this.calculando = false;

                setTimeout(() => {
                  if (event != null && event.relatedTarget != null) {
                    event.relatedTarget.focus();
                  }
                }, 100);

                return;
              }
            }

            let saldoGerado: number = 0;

            if (produto.qtde != null && produto.qtde > 0) {
              saldoGerado = ((produto.valor - produto.valor_tabela) * produto.qtde);

              if (this.configuracao.tipo_conta_corrente_flex == 2) {
                /* Conta Corrente (Flex) = 2 */

                if (this.tiposPedidoSelecionado != null && this.tiposPedidoSelecionado.flag_considerar_pedido_como_venda) {
                  if (produto.cc_flex_perc_credito != null && produto.cc_flex_perc_credito != 0) {
                    saldoGerado = ((((produto.valor - produto.valor_tabela) * produto.cc_flex_perc_credito) / 100) * produto.qtde);

                    if (saldoGerado < 0) {
                      saldoGerado = 0;
                    }
                  } else {
                    saldoGerado = 0;
                  }
                } else {
                  saldoGerado = ((produto.valor * produto.qtde) * -1);
                }
              }
            }

            produto.saldo_gerado = parseFloat(saldoGerado.toFixed(2));

            /* Qtde (Bonificada) */

            if (produto.qtde_bonificada != null && produto.qtde_bonificada > 0) {
              let saldoGerado: number = (produto.saldo_gerado - (produto.valor * produto.qtde_bonificada));

              produto.saldo_gerado = parseFloat(saldoGerado.toFixed(2));
            }

            let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == produto.id);

            if (produtoOriginal != null) {
              produtoOriginal.saldo_gerado = produto.saldo_gerado;
            }

            this.totalizarSaldoGerado();
          } else {
            if (porcentagemDescontoMaximo != null) {
              let valorCalculoInicial: number = produto.valor_tabela != null && produto.valor_tabela > 0 ? produto.valor_tabela : produto.price;

              if (this.prazoPagamentoSelecionado != null && this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido && this.configuracao.utiliza_fator_financeiro) {
                if (produto.aplica_fator_financeiro) {
                  /* C/ Fator */

                  valorCalculoInicial = produto.valor_fator_financeiro_tabela != null ? produto.valor_fator_financeiro_tabela : 0;
                }
              }

              let valorAtual: number = this.formatacaoService.duasCasasDecimais(produto.valor);
              let valorDescontoMaximo: number = (valorCalculoInicial - ((valorCalculoInicial * porcentagemDescontoMaximo) / 100));
              let valorMaximo: number = this.formatacaoService.duasCasasDecimais((valorDescontoMaximo * 100) / 100);

              if (valorAtual < valorMaximo) {
                this.toastr.error("", "Porcentagem máxima de desconto: " + porcentagemDescontoMaximo + "%...");

                produto.erro_valor = true;

                this.calcularFatorFinanceiroProduto(produto.id, adicionado);

                if (adicionado) {
                  this.organizarProdutosAdicionados();
                }

                this.calculando = false;

                setTimeout(() => {
                  if (event != null && event.relatedTarget != null) {
                    event.relatedTarget.focus();
                  }
                }, 100);

                return;
              }
            }
          }

          if (this.configuracao != null && !this.configuracao.flag_permite_preco_acima_tabela) {
            let valorTabela: number = produto.valor_tabela;

            if (valorTabela == 0) {
              valorTabela = produto.price;
            }

            if (produto.valor > valorTabela) {
              this.toastr.error("", "Valor acima da tabela...");

              produto.erro_valor = true;

              this.calcularFatorFinanceiroProduto(produto.id, adicionado);

              if (adicionado) {
                this.organizarProdutosAdicionados();
              }

              this.calculando = false;

              setTimeout(() => {
                if (event != null && event.relatedTarget != null) {
                  event.relatedTarget.focus();
                }
              }, 100);

              return;
            }
          }

          let valorTotal: number = produto.valor * produto.qtde;

          produto.valor_total = parseFloat(valorTotal.toFixed(2));

          this.calcularFatorFinanceiroProduto(produto.id, adicionado);

          if (!adicionado && this.tipoOrdenacaoItensPedido == 1) {
            /* Ordenar = Inclusão */

            this.definirOrdemInclusao(produto.id, true);
          }

          if (adicionado) {
            this.organizarProdutosAdicionados();
          }

          this.calculando = false;

          setTimeout(() => {
            if (event != null && event.relatedTarget != null) {
              event.relatedTarget.focus();
            }
          }, 100);
        }
      );
    } else {
      produto.valor_tabela = 0;

      produto.porcentagem_desconto = 0;
      produto.descritivo_desconto = null;

      /* Porcentagem Desconto Temporária */

      if (produto.valor != null && produto.valor > 0) {
        this.calcularDescontoUnitario(produto.id, false, adicionado);
      } else {
        produto.porcentagem_desconto_temporaria = null;
      }

      if (this.configuracao != null && this.configuracao.usa_conta_corrente_flex) {
        produto.saldo_gerado = 0;

        let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == produto.id);

        if (produtoOriginal != null) {
          produtoOriginal.saldo_gerado = 0;
        }

        this.totalizarSaldoGerado();
      }

      produto.valor_total = 0;

      this.calcularFatorFinanceiroProduto(produto.id, adicionado);

      if (this.tipoOrdenacaoItensPedido == 1) {
        /* Ordenar = Inclusão */

        this.definirOrdemInclusao(produto.id, false);
      }

      if (adicionado) {
        this.organizarProdutosAdicionados();
      }

      this.calculando = false;
    }
  }

  /* Cabeçalho */

  alterarProdutoReferenciaCliente(referencia_cliente: string) {
    this.produtoReferenciaClienteSelecionado = referencia_cliente;

    this.carregarDadosPorCliente(false);
  }

  selecionarCanalVenda(canal_venda: string) {
    this.canalVendasSelecionado = parseInt(canal_venda);
  }

  selecionarFormaPagamento(id_forma_pagamento: string) {
    this.formaPagamentoSelecionada = this.formasPagamento.find(formaPagamento => formaPagamento.id == parseInt(id_forma_pagamento));

    this.carregarDadosPorCliente(false);
  }

  selecionarFrete(frete: string) {
    this.tipoFreteSelecionado = this.tiposFrete.find(tipoFrete => tipoFrete.id == parseInt(frete));
  }

  selecionarLocalEntrega(id: string) {
    this.clienteLocalEntregaSelecionado = this.clienteLocaisEntrega.find(clienteLocalEntrega => clienteLocalEntrega.id == parseInt(id));
  }

  selecionarPedidoOrcamento(pedido_orcamento: string) {
    this.pedidoOrcamentoSelecionado = parseInt(pedido_orcamento);
  }

  selecionarTipoPedido(tipo_pedido: string) {
    this.tiposPedidoSelecionadoOriginal = this.tiposPedidoSelecionado;

    this.tiposPedidoSelecionado = this.tiposPedido.find(tipoPedido => tipoPedido.cod_tipopedvenda == tipo_pedido);

    this.verificarTipoPedido();
  }

  selecionarTransportadora(id_tranportadora: string) {
    this.transportadoraSelecionada = this.transportadoras.find(transportadora => transportadora.id_transportadora == parseInt(id_tranportadora));
  }

  selecionarVendedor(id_vendedor: string) {
    this.vendedorSelecionado = this.vendedores.find(vendedor => vendedor.id == parseInt(id_vendedor));
  }

  verificarTipoPedido() {
    if (this.tiposPedidoSelecionado != null && this.tiposPedidoSelecionado.flag_considerar_pedido_como_venda) {
      this.produtosAdicionados.forEach((item) => {
        if (item.multiplo != null && item.multiplo != 0) {
          if (item.qtde % item.multiplo != 0) {
            let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == item.id);

            if (produtoOriginal != null) {
              produtoOriginal.erro_qtde = true;

              item.erro_qtde = true;
            }
          }
        }
      });

      const agruparProdutosComErro = this.agruparProdutosComErro();

      agruparProdutosComErro.then(() => {
        if (this.produtosComErro.length > 0) {
          this.toastr.error("", "Qtde somente múltipla...");

          this.produtos = JSON.parse(JSON.stringify(this.produtosComErro));

          /* Filtro (Produtos) */

          this.filtrandoProduto = true;

          /* Tipo Pedido */

          this.tiposPedidoErro = true;

          this.modalAdicionarItens(this.adicionarItens, 0, 0);
        }
      });
    } else {
      this.produtosAdicionados.forEach((item) => {
        item.qtde_bonificada = null;
      });
    }
  }

  /* Ações */

  aprovar() {
    let statusPedido: string;

    this.pedidoService.getStatusPedidoByOrder(this.nroPedidoOrcamento, this.vendedorSelecionadoExterno).subscribe(
      statusPedidos => statusPedido = statusPedidos,
      error => { console.log("Erro: " + error) },
      () => {
        let pedidoAprovado: boolean = false;

        if (statusPedido == "Aguard Aprov") {
          this.pedidoService.aprovar(this.nroPedidoOrcamento, this.vendedorSelecionadoExterno).subscribe(
            pedidosAprovados => pedidoAprovado = pedidosAprovados,
            error => { console.log("Erro: " + error) },
            () => {
              if (pedidoAprovado) {
                this.redirecionar("aprovar");

                this.toastr.success("", "Pedido aprovado com sucesso!");
              } else {
                this.toastr.error("", "Erro ao realizar aprovação! Contate o suporte...");
              }
            }
          );
        } else {
          this.toastr.info("", "Tente novamente...");
        }
      }
    );
  }

  imprimirPedidoOrcamento() {
    this.confirmarPedido("imprimir");
  }

  reprovar(content: any) {
    let statusPedido: string;

    this.pedidoService.getStatusPedidoByOrder(this.nroPedidoOrcamento, this.vendedorSelecionadoExterno).subscribe(
      statusPedidos => statusPedido = statusPedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (statusPedido == "Aguard Aprov") {
          this.motivoReprovacao = "";

          this.modalService.open(content, {
            ariaLabelledBy: "titulo-pedido-reprovar",
            size: "lg",
            backdrop: "static"
          }).result.then((acao) => {
            if (acao == "Salvar") {
              let pedidoReprovado: boolean = false;

              this.pedidoService.reprovar(this.nroPedidoOrcamento, this.vendedorSelecionadoExterno, this.motivoReprovacao).subscribe(
                pedidosReprovados => pedidoReprovado = pedidosReprovados,
                error => { console.log("Erro: " + error) },
                () => {
                  if (pedidoReprovado) {
                    this.redirecionar("reprovar");

                    this.toastr.success("", "Pedido reprovado com sucesso!");
                  } else {
                    this.toastr.error("", "Erro ao realizar reprovação! Contate o suporte...");
                  }
                }
              );
            }
          }, () => {

          });
        } else {
          this.toastr.info("", "Tente novamente...");
        }
      }
    );
  }

  salvar() {
    this.confirmarPedido("salvar");
  }

  salvarEnviar() {
    this.confirmarPedido("salvar_enviar");
  }

  /* Dados (Final) */

  adicionarPedido(origem: string, produtoPromocionalAgrupado: ProdutoPromocionalAgrupado) {
    let retorno = new Promise<void>((resolver) => {
      this.pedidoService.getProximoNumeroPedidoBySeller().subscribe(
        nroPedidoOrcamento => this.nroPedidoOrcamento = nroPedidoOrcamento,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.nroPedidoOrcamento > 0) {
            this.prepararEnviarDados(produtoPromocionalAgrupado);

            produtoPromocionalAgrupado.order_id = this.nroPedidoOrcamento;
            produtoPromocionalAgrupado.seller_id = this.pedido.seller_id;

            let adicionarPedido: boolean = false;

            /* Pedido */

            this.pedidoService.add(this.pedido).subscribe(
              adicionarPedidos => adicionarPedido = adicionarPedidos,
              error => { console.log("Erro: " + error) },
              () => {
                if (adicionarPedido) {
                  let adicionarPedidoItens: boolean = false;

                  /* Pedido Itens */

                  this.pedidoItensService.add(this.pedidoItens).subscribe(
                    adicionarPedidosItens => adicionarPedidoItens = adicionarPedidosItens,
                    error => { console.log("Erro: " + error) },
                    () => {
                      if (adicionarPedidoItens) {
                        if (origem == "salvar_enviar") {
                          if (this.configuracao != null && this.configuracao.usa_conta_corrente_flex) {
                            let calcularSaldo: boolean = false;

                            this.pedidoService.calcularSaldo(this.nroPedidoOrcamento, this.clienteSelecionado.seller_id, this.vendedorSelecionado.id).subscribe(
                              calcularSaldos => calcularSaldo = calcularSaldos,
                              error => { console.log("Erro: " + error) },
                              () => {
                                if (calcularSaldo) {
                                  this.toastr.success("", "Saldo calculado com sucesso!");

                                  let pedidoBonificacaoRetorno: PedidoBonificacaoRetorno = null;

                                  let porcentagemDesconto = this.porcentagemDescontoAplicado == null ? 0 : this.porcentagemDescontoAplicado;

                                  this.pedidoService.enviar(this.nroPedidoOrcamento, this.vendedorSelecionado.id, porcentagemDesconto).subscribe(
                                    pedidosBonificacaoRetorno => pedidoBonificacaoRetorno = pedidosBonificacaoRetorno,
                                    error => { console.log("Erro: " + error) },
                                    () => {
                                      return resolver();
                                    }
                                  );
                                } else {
                                  this.toastr.error("", "Saldo inválido!");

                                  return resolver();
                                }
                              }
                            );
                          } else {
                            let pedidoBonificacaoRetorno: PedidoBonificacaoRetorno = null;

                            let porcentagemDesconto = this.porcentagemDescontoAplicado == null ? 0 : this.porcentagemDescontoAplicado;

                            this.pedidoService.enviar(this.nroPedidoOrcamento, this.vendedorSelecionado.id, porcentagemDesconto).subscribe(
                              pedidosBonificacaoRetorno => pedidoBonificacaoRetorno = pedidosBonificacaoRetorno,
                              error => { console.log("Erro: " + error) },
                              () => {
                                return resolver();
                              }
                            );
                          }
                        } else {
                          return resolver();
                        }
                      }
                    }
                  );
                }
              }
            );
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");
          }
        }
      );
    });

    return retorno;
  }

  adicionarPedidoBonificacaoVinculada(origem) {
    let retorno = new Promise<void>((resolver) => {
      this.pedidoService.getProximoNumeroPedidoBySeller().subscribe(
        nroPedidoOrcamento => this.nroPedidoOrcamento = nroPedidoOrcamento,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.nroPedidoOrcamento > 0) {
            this.prepararEnviarDadosBonificacaoVinculada();

            let adicionarPedido: boolean = false;

            /* Pedido */

            this.pedidoService.add(this.pedido).subscribe(
              adicionarPedidos => adicionarPedido = adicionarPedidos,
              error => { console.log("Erro: " + error) },
              () => {
                if (adicionarPedido) {
                  let adicionarPedidoItens: boolean = false;

                  /* Pedido Itens */

                  this.pedidoItensService.add(this.pedidoItens).subscribe(
                    adicionarPedidosItens => adicionarPedidoItens = adicionarPedidosItens,
                    error => { console.log("Erro: " + error) },
                    () => {
                      if (adicionarPedidoItens) {
                        if (origem == "salvar_enviar") {
                          let pedidoBonificacaoRetorno: PedidoBonificacaoRetorno = null;

                          this.pedidoService.enviar(this.nroPedidoOrcamento, this.vendedorSelecionado.id, 0).subscribe(
                            pedidosBonificacaoRetorno => pedidoBonificacaoRetorno = pedidosBonificacaoRetorno,
                            error => { console.log("Erro: " + error) },
                            () => {
                              return resolver();
                            }
                          );
                        } else {
                          return resolver();
                        }
                      }
                    }
                  );
                }
              }
            );
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");
          }
        }
      );
    });

    return retorno;
  }

  agruparProdutos() {
    let retorno = new Promise<void>((resolver) => {
      /* Produtos */

      let produtoPromocionalTemporario: ProdutoPromocional[] = [];

      this.produtosAdicionados.forEach((item) => {
        if (item.id != 0 && (item.qtde != 0 && item.qtde != null)) {
          produtoPromocionalTemporario.push(item);
        }

        if (item.produtos_vinculados != null) {
          /* Produtos Vinculados */

          item.produtos_vinculados.forEach((subItem) => {
            if (subItem.qtde > 0) {
              if (item.preco_bonificacao == "B") {
                /* Bonificação */

                if (subItem.bonificacao) {
                  /* Bonificação */

                  if (subItem.bonificacao_selecionada) {
                    if (item.bonificacao_multiplicavel) {
                      /* Bonificação (Multiplicável) */

                      let qtdeBonificacaoMultiplicavel: number = item.qtde_bonificacao_multiplicavel != null ? item.qtde_bonificacao_multiplicavel : 0;

                      if (item.bonificacao_selecionavel) {
                        /* Bonificação (Selecionável) */

                        subItem.qtde = subItem.qtde * qtdeBonificacaoMultiplicavel;
                      } else {
                        subItem.qtde = qtdeBonificacaoMultiplicavel;
                      }

                      produtoPromocionalTemporario.push(subItem);
                    } else {
                      produtoPromocionalTemporario.push(subItem);
                    }
                  }
                } else {
                  if (!item.bonificacao_selecionavel && subItem.bonificacao_selecionada) {
                    let produtoVinculado: ProdutoPromocional = { ...subItem };

                    /* Produto Origem */

                    subItem.bonificacao_selecionada = false;
                    subItem.qtde = subItem.qtde * item.qtde;

                    produtoPromocionalTemporario.push(subItem);

                    /* Produto Bonificado */

                    produtoVinculado.bonificacao = true;

                    if (item.bonificacao_multiplicavel) {
                      produtoVinculado.qtde = item.qtde_bonificacao_multiplicavel;
                    } else {
                      produtoVinculado.qtde = 1;
                    }

                    produtoPromocionalTemporario.push(produtoVinculado);
                  } else {
                    subItem.qtde = subItem.qtde * item.qtde;

                    produtoPromocionalTemporario.push(subItem);
                  }
                }
              } else {
                subItem.qtde = subItem.qtde * item.qtde;

                produtoPromocionalTemporario.push(subItem);
              }
            }
          });
        } else {
          /* Qtde (Bonificada) */

          if (item.qtde_bonificada != null && item.qtde_bonificada > 0) {
            let produtoPromocionalBonificado: ProdutoPromocional = { ...item };

            produtoPromocionalBonificado.bonificacao = true;
            produtoPromocionalBonificado.qtde = item.qtde_bonificada;

            produtoPromocionalTemporario.push(produtoPromocionalBonificado);
          }
        }
      });

      /* Agrega Qtde */

      let produtoPromocional: ProdutoPromocional[] = [];

      produtoPromocionalTemporario.forEach((item) => {
        let produto = produtoPromocional.find(produtoPromocional => produtoPromocional.id == item.id && produtoPromocional.bonificacao == item.bonificacao && produtoPromocional.valor == item.valor && produtoPromocional.cod_lote == item.cod_lote);

        if (produto != null) {
          produto.qtde = produto.qtde + item.qtde;
        } else {
          produtoPromocional.push(item);
        }
      });

      /* Produtos Agrupados */

      this.produtosAgrupados = [];

      produtoPromocional.forEach((item) => {
        if (this.produtosAgrupados.length == 0) {
          let produtoPromocionalAgrupado = new ProdutoPromocionalAgrupado();

          produtoPromocionalAgrupado.bonificacao = item.bonificacao;
          produtoPromocionalAgrupado.cod_lote = item.cod_lote;
          produtoPromocionalAgrupado.produtoPromocional = [];

          produtoPromocionalAgrupado.produtoPromocional.push(item);

          this.produtosAgrupados.push(produtoPromocionalAgrupado);
        } else {
          let novoAgrupamento: boolean = false;

          let produtoAdicionado: boolean = false;

          this.produtosAgrupados.forEach((subItem) => {
            if (!produtoAdicionado) {
              if (item.cod_lote != subItem.cod_lote) {
                novoAgrupamento = true;
              } else {
                if (item.bonificacao) {
                  /* Bonificação */

                  if (subItem.bonificacao) {
                    /* Bonificação */

                    let produto = subItem.produtoPromocional.find(produtoPromocional => produtoPromocional.id == item.id);

                    if (produto == null) {
                      subItem.produtoPromocional.push(item);

                      novoAgrupamento = false;
                      produtoAdicionado = true;
                    } else {
                      novoAgrupamento = true;
                    }
                  }
                } else {
                  if (!subItem.bonificacao) {
                    /* Bonificação */

                    let produto = subItem.produtoPromocional.find(produtoPromocional => produtoPromocional.id == item.id);

                    if (produto == null) {
                      subItem.produtoPromocional.push(item);

                      novoAgrupamento = false;
                      produtoAdicionado = true;
                    } else {
                      novoAgrupamento = true;
                    }
                  }
                }
              }
            }
          });

          if (novoAgrupamento || !produtoAdicionado) {
            let produtoPromocionalAgrupado = new ProdutoPromocionalAgrupado();

            produtoPromocionalAgrupado.bonificacao = item.bonificacao;
            produtoPromocionalAgrupado.cod_lote = item.cod_lote;
            produtoPromocionalAgrupado.produtoPromocional = [];

            produtoPromocionalAgrupado.produtoPromocional.push(item);

            this.produtosAgrupados.push(produtoPromocionalAgrupado);
          }
        }
      });

      this.produtosAgrupados.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a["bonificacao"], b["bonificacao"]);
      });

      return resolver();
    });

    return retorno;
  }

  confirmarPedido(origem: string) {
    /* Tipo Pedido */

    let tipoPedidoBonificacao: TipoPedido = this.tiposPedido.find(tipoPedido => tipoPedido.padrao_bonificacao == "S");

    if (tipoPedidoBonificacao == null) {
      this.toastr.error("", "Nenhum tipo de pedido como bonificação...");

      return;
    }

    if (this.pedido != null) {
      /* Alterando */

      this.prepararEnviarDados();

      /* Pedido */

      let alterarPedido: boolean = false;

      this.pedidoService.update(this.pedido).subscribe(
        alterarPedidos => alterarPedido = alterarPedidos,
        error => { console.log("Erro: " + error) },
        () => {
          if (alterarPedido) {
            /* Pedido Itens */

            let alterarPedidoItens: boolean = false;

            this.pedidoItensService.update(this.pedidoItens).subscribe(
              alterarPedidosItens => alterarPedidoItens = alterarPedidosItens,
              error => { console.log("Erro: " + error) },
              () => {
                if (alterarPedidoItens) {
                  this.retornoConfirmarPedido(origem);
                }
              }
            );
          }
        }
      );
    } else {
      /* Adicionando */

      const agruparProdutos = this.agruparProdutos();

      agruparProdutos.then(() => {
        if (this.produtosAgrupados.length > 0) {
          if (this.produtosAgrupados.length < 10) {
            /* 0 */

            const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[0]);

            adicionarPedido.then(() => {
              this.nroPedidoOrcamentoVinculado = this.produtosAgrupados[0].order_id;

              if (this.produtosAgrupados[1] != null) {
                /* 1 */

                const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[1]);

                adicionarPedido.then(() => {
                  if (this.produtosAgrupados[2] != null) {
                    /* 2 */

                    const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[2]);

                    adicionarPedido.then(() => {
                      if (this.produtosAgrupados[3] != null) {
                        /* 3 */

                        const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[3]);

                        adicionarPedido.then(() => {
                          if (this.produtosAgrupados[4] != null) {
                            /* 4 */

                            const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[4]);

                            adicionarPedido.then(() => {
                              if (this.produtosAgrupados[5] != null) {
                                /* 5 */

                                const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[5]);

                                adicionarPedido.then(() => {
                                  if (this.produtosAgrupados[6] != null) {
                                    /* 6 */

                                    const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[6]);

                                    adicionarPedido.then(() => {
                                      if (this.produtosAgrupados[7] != null) {
                                        /* 7 */

                                        const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[7]);

                                        adicionarPedido.then(() => {
                                          if (this.produtosAgrupados[8] != null) {
                                            /* 8 */

                                            const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[8]);

                                            adicionarPedido.then(() => {
                                              if (this.produtosAgrupados[9] != null) {
                                                /* 9 */

                                                const adicionarPedido = this.adicionarPedido(origem, this.produtosAgrupados[9]);

                                                adicionarPedido.then(() => {
                                                  this.retornoConfirmarPedido(origem);
                                                });
                                              } else {
                                                this.retornoConfirmarPedido(origem);
                                              }
                                            });
                                          } else {
                                            this.retornoConfirmarPedido(origem);
                                          }
                                        });
                                      } else {
                                        this.retornoConfirmarPedido(origem);
                                      }
                                    });
                                  } else {
                                    this.retornoConfirmarPedido(origem);
                                  }
                                });
                              } else {
                                this.retornoConfirmarPedido(origem);
                              }
                            });
                          } else {
                            this.retornoConfirmarPedido(origem);
                          }
                        });
                      } else {
                        this.retornoConfirmarPedido(origem);
                      }
                    });
                  } else {
                    this.retornoConfirmarPedido(origem);
                  }
                });
              } else {
                this.retornoConfirmarPedido(origem);
              }
            });
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");
          }
        }
      });
    }
  }

  enviar(origem: string) {
    let pedidoBonificacaoRetorno: PedidoBonificacaoRetorno = null;

    let porcentagemDesconto = this.porcentagemDescontoAplicado == null ? 0 : this.porcentagemDescontoAplicado;

    this.pedidoService.enviar(this.nroPedidoOrcamento, this.vendedorSelecionado.id, porcentagemDesconto).subscribe(
      pedidosBonificacaoRetorno => pedidoBonificacaoRetorno = pedidosBonificacaoRetorno,
      error => { console.log("Erro: " + error) },
      () => {
        this.toastr.success("", "Pedido enviado com sucesso!");
        this.toastr.success("", "Pedido salvo com sucesso!");

        /* Bonificação Vinculada */

        if (this.pedidoItensInativos.length > 0) {
          const adicionarPedidoBonificacaoVinculada = this.adicionarPedidoBonificacaoVinculada(origem);

          adicionarPedidoBonificacaoVinculada.then(() => {
            this.toastr.success("", "Bonificação enviada com sucesso!");
            this.toastr.success("", "Bonificação salva com sucesso!");

            this.verificarPedidoBonificado(origem);
          });
        } else {
          this.verificarPedidoBonificado(origem);
        }
      }
    );
  }

  retornoConfirmarPedido(origem: string) {
    if (origem == "imprimir") {
      /* Imprimir */

      if (this.produtosAgrupados.length == 0) {
        /* Alterando */

        this.pdfService.imprimirPedidoOrcamento(this.nroPedidoOrcamento, this.vendedorSelecionadoExterno, 0);
      } else {
        /* Adicionado */

        this.produtosAgrupados.forEach((item) => {
          this.pdfService.imprimirPedidoOrcamento(item.order_id, this.vendedorSelecionadoExterno, 0);
        });
      }

      if (this.pedidoOrcamentoSelecionado == 0) {
        this.toastr.success("", "Orçamento salvo com sucesso!");
      } else {
        this.toastr.success("", "Pedido salvo com sucesso!");
      }

      this.redirecionar(origem);
    } else if (origem == "salvar") {
      /* Salvar */

      if (this.pedidoOrcamentoSelecionado == 0) {
        this.toastr.success("", "Orçamento salvo com sucesso!");
      } else {
        this.toastr.success("", "Pedido salvo com sucesso!");
      }

      this.redirecionar(origem);
    } else if (origem == "salvar_enviar") {
      /* Salvar / Enviar */

      if (this.produtosAgrupados.length == 0) {
        /* Alterando */

        if (this.configuracao != null && this.configuracao.usa_conta_corrente_flex) {
          let calcularSaldo: boolean;

          this.pedidoService.calcularSaldo(this.nroPedidoOrcamento, this.clienteSelecionado.seller_id, this.vendedorSelecionado.id).subscribe(
            calcularSaldos => calcularSaldo = calcularSaldos,
            error => { console.log("Erro: " + error) },
            () => {
              if (calcularSaldo) {
                this.toastr.success("", "Saldo calculado com sucesso!");

                this.enviar(origem);
              } else {
                this.toastr.error("", "Saldo inválido!");
              }
            }
          );
        } else {
          this.enviar(origem);
        }
      } else {
        /* Adicionado */

        this.toastr.success("", "Pedido enviado com sucesso!");
        this.toastr.success("", "Pedido salvo com sucesso!");

        this.redirecionar(origem);
      }
    }
  }

  verificarPedidoBonificado(origem: string) {
    if (this.pedidoBonificacaoRetorno != null && this.pedidoBonificacaoRetorno.numero_pedido_bonificado != 0 && this.pedidoBonificacaoRetorno.valor_pedido_bonificado != 0) {
      /* Com Bonificação */

      if (this.ligacaoSelecionadaExterna == 0) {
        this.modalComBonificacao(this.comBonificacao, this.pedidoBonificacaoRetorno.numero_pedido_bonificado, this.vendedorSelecionado.id);
      } else {
        this.retornarCallCenterLigacao = true;

        this.router.navigate(["/call-center-ligacao/" + this.ligacaoSelecionadaExterna], {
          queryParams: {
            pedido: this.pedido.order_id_app,
            vendedor: this.pedido.seller_id,
            visita: this.visitaSelecionadaExterna
          }
        });
      }
    } else {
      /* Sem Bonificação */

      this.redirecionar(origem);
    }
  }

  /* Redirecionamento */

  redirecionar(origem: string) {
    if (this.ligacaoSelecionadaExterna == 0) {
      if (origem == "aprovar" || origem == "reprovar" || origem == "salvar_enviar") {
        this.router.navigate(["/pedidos"], {
          queryParams: {
            tab: "enviados"
          }
        });
      } else {
        this.router.navigate(["/pedidos"], {
          queryParams: {
            tab: "nao-enviados"
          }
        });
      }
    } else {
      this.retornarCallCenterLigacao = true;

      if (this.produtosAgrupados.length == 0) {
        /* Alterando */

        this.router.navigate(["/call-center-ligacao/" + this.ligacaoSelecionadaExterna], {
          queryParams: {
            pedido: this.pedido.order_id_app,
            vendedor: this.pedido.seller_id,
            visita: this.visitaSelecionadaExterna
          }
        });
      } else {
        /* Adicionando */

        let primeiroPedido = this.produtosAgrupados.find(produtoAgrupado => !produtoAgrupado.bonificacao);

        if (primeiroPedido != null) {
          this.router.navigate(["/call-center-ligacao/" + this.ligacaoSelecionadaExterna], {
            queryParams: {
              pedido: primeiroPedido.order_id,
              vendedor: primeiroPedido.seller_id,
              visita: this.visitaSelecionadaExterna
            }
          });
        }
      }
    }
  }

  /* Dados (Inicial) */

  atualizarEmAtendimento(em_atendimento: boolean) {
    let atualizarVendedor: boolean = false;

    this.vendedorService.updateEmAtendimentoByVendedor(em_atendimento).subscribe(
      atualizarVendedores => atualizarVendedor = atualizarVendedores,
      error => { console.log("Erro: " + error) },
      () => {

      }
    );
  }

  prepararEnviarDados(produtoPromocionalAgrupado?: ProdutoPromocionalAgrupado) {
    /* Pedido */

    let pedido = new Pedido();

    pedido.client_id = this.clienteSelecionado.client_id_app;
    pedido.is_billed = false;
    pedido.is_active = true;
    pedido.sale_channel_id = this.canalVendasSelecionado;

    if (this.pedidoOrcamentoSelecionado == 0) {
      /* Orçamento */

      pedido.is_quotation = true;
    } else {
      /* Pedido */

      pedido.is_quotation = false;
    }

    pedido.seller_id = this.vendedorSelecionado.id;
    pedido.referencia_do_pedido_no_cliente = this.pedidoVinculado != "" ? this.pedidoVinculado : null;
    pedido.observacao_interna = this.observacaoInterna;
    pedido.observacao_nf = this.observacaoNotaFiscal;
    pedido.id_prazo_pagamento = this.prazoPagamentoSelecionado.idprazopagamento;
    pedido.order_id_app = this.nroPedidoOrcamento; /* Nro Pedido */

    if (this.configuracao != null && !this.configuracao.seleciona_transportadora) {
      pedido.cd_transportadora = this.clienteSelecionado.cd_transportadora != null ? parseInt(this.clienteSelecionado.cd_transportadora) : null;
    } else {
      pedido.cd_transportadora = this.transportadoraSelecionada != null ? this.transportadoraSelecionada.id_transportadora : null;
    }

    if (produtoPromocionalAgrupado == null) {
      /* Alterando */

      pedido.cod_tipopedvenda = this.tiposPedidoSelecionado.cod_tipopedvenda.toString();
    } else {
      /* Adicionando */

      if (produtoPromocionalAgrupado.bonificacao) {
        /* Bonificação */

        pedido.cod_tipopedvenda = this.tiposPedido.find(tipoPedido => tipoPedido.padrao_bonificacao == "S").cod_tipopedvenda.toString();
      } else {
        pedido.cod_tipopedvenda = this.tiposPedidoSelecionado.cod_tipopedvenda.toString();
      }
    }

    pedido.id_tipo_frete = this.tipoFreteSelecionado.id;

    if (this.is_master || this.is_manager || this.is_master_seller || this.is_diretor) {
      pedido.master_seller_id = parseInt(localStorage.getItem("id"));
    } else {
      pedido.master_seller_id = null;
    }

    if (produtoPromocionalAgrupado == null) {
      /* Alterando */

      if (this.pedido != null) {
        pedido.order_id_origem_bonificacao = this.pedido.order_id_origem_bonificacao;
        pedido.vlr_bonificacao = this.pedido.vlr_bonificacao;
      }
    } else {
      if (produtoPromocionalAgrupado.bonificacao) {
        /* Bonificação */

        pedido.order_id_origem_bonificacao = this.nroPedidoOrcamentoVinculado;
      } else {
        pedido.order_id_origem_bonificacao = 0;
      }

      pedido.vlr_bonificacao = 0;
    }

    /* Forma de Pagamento */

    pedido.id_forma_pagamento = this.formaPagamentoSelecionada != null ? this.formaPagamentoSelecionada.id : null;

    /* Dt. Validade */

    if (this.dataValidade != null && this.dataValidade.year != null && this.dataValidade.month != null && this.dataValidade.day != null) {
      let dataValidade = new Date(this.dataValidade.year, this.dataValidade.month - 1, this.dataValidade.day, 0, 0, 0);

      pedido.dt_validade = new Date(this.datePipe.transform(dataValidade, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
    } else {
      pedido.dt_validade = null;
    }

    /* Dt. Previsão de Entrega */

    if (this.dataPrevisaoEntrega != null && this.dataPrevisaoEntrega.year != null && this.dataPrevisaoEntrega.month != null && this.dataPrevisaoEntrega.day != null) {
      let dataPrevisaoEntrega = new Date(this.dataPrevisaoEntrega.year, this.dataPrevisaoEntrega.month - 1, this.dataPrevisaoEntrega.day, 0, 0, 0);

      pedido.dt_previsao_entrega = new Date(this.datePipe.transform(dataPrevisaoEntrega, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
    } else {
      pedido.dt_previsao_entrega = null;
    }

    /* Local de Entrega */

    pedido.id_local_entrega = this.clienteLocalEntregaSelecionado != null ? this.clienteLocalEntregaSelecionado.id : null;

    /* Pedido Itens */

    let pedidoItens: PedidoItens[] = [];

    if (produtoPromocionalAgrupado == null) {
      /* Alterando */

      pedido.qtd_itens_ativos = this.produtosAdicionados.length;

      this.produtosAdicionados.forEach((item) => {
        let pedidoItem = new PedidoItens();

        pedidoItem.id = item.id_order_item;
        pedidoItem.order_id = this.nroPedidoOrcamento; /* Nro Pedido */
        pedidoItem.product_id = item.id;
        pedidoItem.quantity = item.qtde;
        pedidoItem.unit_price = item.valor_fator_financeiro_produto; /**/
        pedidoItem.unit_id = 1;
        pedidoItem.is_active = item.is_active;
        pedidoItem.seller_id = this.vendedorSelecionado.id;
        pedidoItem.percentual_desconto = ((this.porcentagemDescontoAplicado == null ? 0 : this.porcentagemDescontoAplicado) + item.porcentagem_desconto);
        pedidoItem.preco_tabela = item.valor_tabela;
        pedidoItem.descritivo_desconto = item.descritivo_desconto;
        pedidoItem.preco_reposicao = item.custo;
        pedidoItem.observacao_item = item.observacao_item;

        /* Lote */

        pedidoItem.id_lote = item.id_lote;

        /* Imposto */

        pedidoItem.valor_imposto = this.formatacaoService.quatroCasasDecimais(item.valor_imposto_unitario); /**/

        pedidoItens.push(pedidoItem);

        /* Fator Financeiro */

        if (item.valor_fator_financeiro_unitario != null) {
          pedidoItem.fator_financeiro = parseFloat(item.valor_fator_financeiro_unitario.toFixed(2)); /**/
        } else {
          pedidoItem.fator_financeiro = 0;
        }
      });
    } else {
      /* Adicionando */

      pedido.qtd_itens_ativos = produtoPromocionalAgrupado.produtoPromocional.length;

      produtoPromocionalAgrupado.produtoPromocional.forEach((item) => {
        let pedidoItem = new PedidoItens();

        pedidoItem.id = item.id_order_item;
        pedidoItem.order_id = this.nroPedidoOrcamento; /* Nro Pedido */
        pedidoItem.product_id = item.id;
        pedidoItem.quantity = item.qtde;
        pedidoItem.unit_price = item.valor_fator_financeiro_produto; /**/
        pedidoItem.unit_id = 1;
        pedidoItem.is_active = item.is_active;
        pedidoItem.seller_id = this.vendedorSelecionado.id;
        pedidoItem.percentual_desconto = ((this.porcentagemDescontoAplicado == null ? 0 : this.porcentagemDescontoAplicado) + item.porcentagem_desconto);

        if (item.id_campanha != null) {
          pedidoItem.preco_tabela = item.valor;
        } else {
          pedidoItem.preco_tabela = item.valor_tabela;
        }

        pedidoItem.descritivo_desconto = item.descritivo_desconto;
        pedidoItem.preco_reposicao = item.custo;
        pedidoItem.observacao_item = item.observacao_item;
        pedidoItem.id_kit_combo = item.id_campanha != null ? String(item.id_campanha) : null;

        /* Lote */

        pedidoItem.id_lote = item.id_lote;

        /* Imposto */

        pedidoItem.valor_imposto = this.formatacaoService.quatroCasasDecimais(item.valor_imposto_unitario); /**/

        pedidoItens.push(pedidoItem);

        /* Fator Financeiro */

        if (item.valor_fator_financeiro_unitario != null) {
          pedidoItem.fator_financeiro = parseFloat(item.valor_fator_financeiro_unitario.toFixed(2)); /**/
        } else {
          pedidoItem.fator_financeiro = 0;
        }
      });
    }

    this.pedido = pedido;

    this.pedidoItens = pedidoItens;

    /* Vendedor (Externo) */

    this.vendedorSelecionadoExterno = this.vendedorSelecionado.id;
  }

  prepararEnviarDadosBonificacaoVinculada() {
    /* Pedido */

    let pedido = new Pedido();

    pedido.client_id = this.clienteSelecionado.client_id_app;
    pedido.is_billed = false;
    pedido.is_active = true;
    pedido.sale_channel_id = this.canalVendasSelecionado;

    if (this.pedidoOrcamentoSelecionado == 0) {
      pedido.is_quotation = true; /* Orçamento */
    } else {
      pedido.is_quotation = false; /* Pedido */
    }

    pedido.seller_id = this.vendedorSelecionado.id;
    pedido.observacao_interna = this.observacaoInterna;
    pedido.observacao_nf = this.observacaoNotaFiscal;
    pedido.id_prazo_pagamento = this.prazoPagamentoSelecionado.idprazopagamento;
    pedido.order_id_app = this.nroPedidoOrcamento; /* Nro Pedido */
    pedido.cd_transportadora = this.transportadoraSelecionada != null ? this.transportadoraSelecionada.id_transportadora : null;
    pedido.cod_tipopedvenda = this.tiposPedido.find(tipoPedido => tipoPedido.padrao_bonificacao == "S").cod_tipopedvenda.toString();
    pedido.id_tipo_frete = this.tipoFreteSelecionado.id;

    if (this.is_master || this.is_manager || this.is_master_seller || this.is_diretor) {
      pedido.master_seller_id = parseInt(localStorage.getItem("id"));
    } else {
      pedido.master_seller_id = null;
    }

    if (this.pedido != null) {
      pedido.order_id_origem_bonificacao = this.pedido.order_id_origem_bonificacao;
      pedido.vlr_bonificacao = this.pedido.vlr_bonificacao;
    } else {
      pedido.order_id_origem_bonificacao = 0;
      pedido.vlr_bonificacao = 0;
    }

    pedido.qtd_itens_ativos = this.pedidoItensInativos.length;

    /* Pedido Itens */

    let pedidoItens: PedidoItens[] = [];

    this.pedidoItensInativos.forEach((item) => {
      let pedidoItem = new PedidoItens();

      pedidoItem.id = 0;
      pedidoItem.order_id = this.nroPedidoOrcamento; /* Nro Pedido */
      pedidoItem.product_id = item.product_id;
      pedidoItem.quantity = item.quantity;
      pedidoItem.unit_price = this.formatacaoService.quatroCasasDecimais(item.unit_price);
      pedidoItem.unit_id = 1;
      pedidoItem.is_active = true;
      pedidoItem.seller_id = this.vendedorSelecionado.id;
      pedidoItem.percentual_desconto = 0;
      pedidoItem.preco_tabela = item.unit_price;
      pedidoItem.descritivo_desconto = "";
      pedidoItem.preco_reposicao = item.custo;
      pedidoItem.observacao_item = "";
      pedidoItem.id_kit_combo = item.id_kit_combo;

      /* Lote */

      pedidoItem.id_lote = item.id_lote;

      /* Imposto */

      pedidoItem.valor_imposto = item.valor_imposto;

      /* Fator Financeiro */

      pedidoItem.fator_financeiro = item.fator_financeiro;

      pedidoItens.push(pedidoItem);
    });

    this.pedido = pedido;

    this.pedidoItens = pedidoItens;
  }

  prepararReceberDados(id_pedido: number, seller_id: number) {
    let retorno = new Promise<void>((resolver) => {
      /* Pedido */

      this.pedidoService.getByOrder(id_pedido, seller_id).subscribe(
        pedido => this.pedido = pedido,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.pedido != null) {
            /* Nro Pedido */

            this.nroPedidoOrcamento = this.pedido.order_id_app;

            /* Cliente */

            this.clienteSelecionado = this.clientes.find(cliente => cliente.client_id_app == this.pedido.client_id);

            /* Pedido/Orçamento */

            if (this.pedido.is_quotation) {
              this.pedidoOrcamentoSelecionado = 0; /* Orçamento */
            } else {
              this.pedidoOrcamentoSelecionado = 1; /* Pedido */
            }

            /* Canal de Vendas */

            this.canalVendasSelecionado = this.pedido.sale_channel_id;

            /* Vendedor(a) */

            this.vendedorSelecionado = this.vendedores.find(vendedor => vendedor.id == this.pedido.seller_id);

            /* Tipo de Pedido */

            if (this.tiposPedido.length > 0) {
              if (this.pedido.cod_tipopedvenda != null) {
                this.tiposPedidoSelecionado = this.tiposPedido.find(tipoPedido => tipoPedido.cod_tipopedvenda == this.pedido.cod_tipopedvenda);

                if (this.tiposPedidoSelecionado == null) {
                  this.tiposPedidoSelecionado = this.tiposPedido[0];
                }
              } else {
                this.tiposPedidoSelecionado = this.tiposPedido.find(tipoPedido => tipoPedido.padrao == "S");
              }
            }

            /* Transportadora */

            if (this.transportadoras.length > 0) {
              if (this.pedido.cd_transportadora != null) {
                this.transportadoraSelecionada = this.transportadoras.find(transportadora => transportadora.id_transportadora == this.pedido.cd_transportadora);

                if (this.transportadoraSelecionada == null) {
                  this.transportadoraSelecionada = this.transportadoras[0];
                }
              } else {
                this.transportadoraSelecionada = this.transportadoras[0];
              }
            }

            /* Frete */

            if (this.tiposFrete.length > 0) {
              if (this.pedido.id_tipo_frete != null) {
                this.tipoFreteSelecionado = this.tiposFrete.find(tipoFrete => tipoFrete.id == this.pedido.id_tipo_frete);

                if (this.tipoFreteSelecionado == null) {
                  this.tipoFreteSelecionado = this.tiposFrete.find(tipoFrete => tipoFrete.id == 4); /* EMITENTE (CIF) */

                  if (this.tipoFreteSelecionado == null) {
                    this.tipoFreteSelecionado = this.tiposFrete[0];
                  }
                }
              } else {
                this.tipoFreteSelecionado = this.tiposFrete.find(tipoFrete => tipoFrete.id == 4); /* EMITENTE (CIF) */

                if (this.tipoFreteSelecionado == null) {
                  this.tipoFreteSelecionado = this.tiposFrete[0];
                }
              }
            }

            /* Forma de Pagamento */

            if (this.formasPagamento.length > 0) {
              if (this.pedido.id_forma_pagamento != null) {
                this.formaPagamentoSelecionada = this.formasPagamento.find(formaPagamento => formaPagamento.id == this.pedido.id_forma_pagamento);

                if (this.formaPagamentoSelecionada == null) {
                  this.formaPagamentoSelecionada = this.formasPagamento[0];
                }
              } else {
                this.formaPagamentoSelecionada = this.formasPagamento[0];
              }
            }

            /* Dt. Validade */

            if (this.pedido.dt_validade != null) {
              let dataValidade = new Date(this.pedido.dt_validade);

              this.dataValidade = {
                year: dataValidade.getFullYear(),
                month: dataValidade.getMonth() + 1,
                day: dataValidade.getDate()
              };
            }

            /* Dt. Previsão de Entrega */

            if (this.pedido.dt_previsao_entrega != null) {
              let dataPrevisaoEntrega = new Date(this.pedido.dt_previsao_entrega);

              this.dataPrevisaoEntrega = {
                year: dataPrevisaoEntrega.getFullYear(),
                month: dataPrevisaoEntrega.getMonth() + 1,
                day: dataPrevisaoEntrega.getDate()
              };
            }

            /* Referência */

            this.pedidoVinculado = this.pedido.referencia_do_pedido_no_cliente == null ? "" : this.pedido.referencia_do_pedido_no_cliente;

            /* Pedido Itens (Inativos) */

            this.pedidoItensService.getInativosByOrder(id_pedido, this.pedido.seller_id).subscribe(
              pedidoItensInativos => this.pedidoItensInativos = pedidoItensInativos,
              error => { console.log("Erro: " + error) },
              () => {
                /* Pedido Itens */

                this.pedidoItensService.getByOrder(id_pedido, this.pedido.seller_id).subscribe(
                  pedidoItens => this.pedidoItens = pedidoItens,
                  error => { console.log("Erro: " + error) },
                  () => {
                    /* Produtos (Referências Cliente) */

                    this.produtoReferenciaClienteSelecionado = this.pedidoItens.length > 0 ? this.pedidoItens[0].referencia_cliente : "";

                    /* Prazo Pagamento / Prazo Médio */

                    if (this.pedido.id_prazo_pagamento != null && this.pedido.id_prazo_pagamento != 0) {
                      this.alterarPrazoPagamento(this.pedido.id_prazo_pagamento.toString(), false);
                    }

                    /* Produtos */

                    const filtrarCliente = this.filtrarCliente(true);

                    filtrarCliente.then(() => {
                      const carregarDadosPorCliente = this.carregarDadosPorCliente(false);

                      carregarDadosPorCliente.then(() => {
                        let produtosVerificados: ProdutoPromocional[] = [];

                        /* Pedido Itens p/ Produtos Original */

                        this.pedidoItens.forEach((item, i) => {
                          let produtoOriginal = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == item.product_id);

                          if (produtoOriginal != null) {
                            produtoOriginal.qtde = item.quantity;

                            produtoOriginal.valor_tabela = this.formatacaoService.duasCasasDecimais(item.preco_tabela);
                            produtoOriginal.valor = this.formatacaoService.quatroCasasDecimais(item.unit_price);

                            let valorTotal: number = item.unit_price * produtoOriginal.qtde;

                            produtoOriginal.valor_total = parseFloat(valorTotal.toFixed(2));
                            produtoOriginal.porcentagem_desconto = this.formatacaoService.duasCasasDecimais(item.percentual_desconto);
                            produtoOriginal.descritivo_desconto = item.descritivo_desconto;
                            produtoOriginal.id_order_item = item.id;
                            produtoOriginal.observacao_item = item.observacao_item;

                            if (this.configuracao != null && this.configuracao.usa_conta_corrente_flex) {
                              let saldoGerado: number = 0;

                              if (this.configuracao.tipo_conta_corrente_flex == 2) {
                                /* Conta Corrente (Flex) = 2 */

                                if (this.tiposPedidoSelecionado != null && this.tiposPedidoSelecionado.flag_considerar_pedido_como_venda) {
                                  if (produtoOriginal.cc_flex_perc_credito != null && produtoOriginal.cc_flex_perc_credito != 0) {
                                    saldoGerado = ((((produtoOriginal.valor - produtoOriginal.valor_tabela) * produtoOriginal.cc_flex_perc_credito) / 100) * produtoOriginal.qtde);

                                    if (saldoGerado < 0) {
                                      saldoGerado = 0;
                                    }
                                  } else {
                                    saldoGerado = 0;
                                  }
                                } else {
                                  saldoGerado = ((produtoOriginal.valor * produtoOriginal.qtde) * -1);
                                }
                              } else {
                                saldoGerado = (produtoOriginal.valor - produtoOriginal.valor_tabela) * produtoOriginal.qtde;
                              }

                              produtoOriginal.saldo_gerado = parseFloat(saldoGerado.toFixed(2));
                            } else {
                              produtoOriginal.saldo_gerado = 0;
                            }

                            produtoOriginal.id_kit_combo = item.id_kit_combo;

                            /* Lote */

                            produtoOriginal.id_lote = item.id_lote;
                            produtoOriginal.nome_lote = item.nome_lote;
                            produtoOriginal.cod_lote = item.cod_lote;
                            produtoOriginal.estoque_lote = item.estoque_lote;
                            produtoOriginal.validade_lote = item.validade_lote;
                            produtoOriginal.preco_lote = this.formatacaoService.duasCasasDecimais(item.preco_lote);

                            /* Imposto */

                            let valorImposto: number = item.valor_imposto == null ? 0 : item.valor_imposto;

                            produtoOriginal.valor_imposto = this.formatacaoService.quatroCasasDecimais(valorImposto * produtoOriginal.qtde);
                            produtoOriginal.valor_imposto_unitario = this.formatacaoService.quatroCasasDecimais(valorImposto);
                            produtoOriginal.valor_imposto_produto = this.formatacaoService.quatroCasasDecimais(produtoOriginal.valor + valorImposto);

                            let valorImpostoTotal: number = produtoOriginal.valor_imposto_produto * produtoOriginal.qtde;

                            produtoOriginal.valor_imposto_total = parseFloat(valorImpostoTotal.toFixed(2));

                            produtoOriginal.valor_imposto_total_final = produtoOriginal.valor_imposto_total;

                            let valorUnitarioFinal: number = produtoOriginal.valor + valorImposto;

                            produtoOriginal.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorUnitarioFinal);

                            /* Ordem */

                            if (this.tipoOrdenacaoItensPedido == 1) {
                              /* Ordenar = Inclusão */

                              produtoOriginal.ordem = i + 1;
                            }

                            /* Total Fracionado */

                            if (produtoOriginal.fracionado) {
                              produtoOriginal.valor_unitario_fracionado = produtoOriginal.valor / produtoOriginal.valorunidade;
                              produtoOriginal.valor_unitario_imposto_fracionado = produtoOriginal.valor_imposto_unitario / produtoOriginal.valorunidade;
                            }

                            /* Porcentagem Desconto Temporária */

                            let porcentagemDescontoTemporaria: number = item.percentual_desconto != null && item.percentual_desconto == 0 ? null : item.percentual_desconto;

                            produtoOriginal.porcentagem_desconto_temporaria = this.formatacaoService.quatroCasasDecimais(porcentagemDescontoTemporaria);

                            produtosVerificados.push(produtoOriginal);
                          }
                        });

                        /* Produtos Original p/ Produtos */

                        this.produtosOriginal.forEach((item) => {
                          let produto = this.produtos.find(produto => produto.id == item.id);

                          if (produto != null) {
                            produto.valor_tabela = item.valor_tabela;

                            produto.qtde = item.qtde;
                            produto.valor = item.valor;

                            produto.valor_total = item.valor_total;
                            produto.porcentagem_desconto = item.porcentagem_desconto;
                            produto.descritivo_desconto = item.descritivo_desconto;
                            produto.id_order_item = item.id;
                            produto.observacao_item = item.observacao_item;

                            produto.saldo_gerado = item.saldo_gerado;

                            produto.id_kit_combo = item.id_kit_combo;

                            /* Lote */

                            produto.id_lote = item.id_lote;
                            produto.nome_lote = item.nome_lote;
                            produto.cod_lote = item.cod_lote;
                            produto.estoque_lote = item.estoque_lote;
                            produto.validade_lote = item.validade_lote;
                            produto.preco_lote = item.preco_lote;

                            /* Imposto */

                            produto.valor_imposto = item.valor_imposto;
                            produto.valor_imposto_unitario = item.valor_imposto_unitario;
                            produto.valor_imposto_produto = item.valor_imposto_produto;
                            produto.valor_imposto_total = item.valor_imposto_total;

                            produto.valor_imposto_total_final = produto.valor_imposto_total;

                            produto.valor_unitario_final = item.valor_unitario_final;

                            /* Ordem */

                            produto.ordem = item.ordem;

                            /* Total Fracionado */

                            produto.valor_unitario_fracionado = item.valor_unitario_fracionado;
                            produto.valor_unitario_imposto_fracionado = item.valor_unitario_imposto_fracionado;

                            /* Porcentagem Desconto Temporária */

                            produto.porcentagem_desconto_temporaria = item.porcentagem_desconto_temporaria;
                          }
                        });

                        this.produtosAdicionados = JSON.parse(JSON.stringify(produtosVerificados));

                        this.totalizarProdutosAdicionados();

                        /* Local de Entrega */

                        if (this.pedido.id_local_entrega != null) {
                          this.clienteLocalEntregaSelecionado = this.clienteLocaisEntrega.find(clienteLocalEntrega => clienteLocalEntrega.id == this.pedido.id_local_entrega);
                        }

                        /* Prazo Pagamento / Prazo Médio */

                        this.organizarPrazoPagamento(false, false);

                        /* Observações */

                        this.observacaoInterna = this.pedido.observacao_interna != null ? this.pedido.observacao_interna : "";
                        this.observacaoNotaFiscal = this.pedido.observacao_nf != null ? this.pedido.observacao_nf : "";

                        this.calcularSimulacaoResultado();
                        this.totalizarSaldoGerado();

                        if (this.pedidoDuplicarExterno) {
                          /* Nro Pedido/Orçamento */

                          this.nroPedidoOrcamento = 0;

                          /* Adicionando/Alterando */

                          this.pedido = null;
                          this.pedidoItens = [];
                          this.pedidoItensInativos = [];

                          /* Observações */

                          this.observacaoInterna = "";
                          this.observacaoNotaFiscal = "";
                        }

                        return resolver();
                      });
                    });
                  }
                );
              }
            );
          } else {
            this.toastr.error("", "Pedido inválido!");

            this.router.navigate(["/pedidos"]);
          }
        }
      );
    });

    return retorno;
  }

  verificarAdicionandoAlterando() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    let id: string = null;

    this.route.params.subscribe(params => { id = params.id; });

    if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
      /* Alterando */

      const prepararReceberDados = this.prepararReceberDados(parseInt(id), this.vendedorSelecionadoExterno);

      prepararReceberDados.then(() => {
        if (this.pedidoAprovarExterno) {
          /* Aprovação */

          this.carregarDadosAprovacao();
        } else {
          this.carregando = false;
        }
      });
    } else {
      /* Adicionando */

      const filtrarCliente = this.filtrarCliente(false);

      filtrarCliente.then(() => {
        const carregarDadosPorCliente = this.carregarDadosPorCliente(true);

        carregarDadosPorCliente.then(() => {
          if (this.configuracao != null && this.configuracao.pedido_dt_validade_automatica != null) {
            let dataValidade = new Date();

            dataValidade.setDate(dataValidade.getDate() + this.configuracao.pedido_dt_validade_automatica)

            this.dataValidade = {
              year: dataValidade.getFullYear(),
              month: dataValidade.getMonth() + 1,
              day: dataValidade.getDate()
            };
          }

          this.filtrarCliente(false);

          this.carregando = false;
          this.enviadoCliente = false;
        });
      });
    }
  }

  /* Desconto Itens */

  validarDescontoAplicado() {
    if (this.prazoMedioDesconto != null) {
      if (this.porcentagemDescontoAplicado > this.prazoMedioDesconto.percentual_desconto) {
        this.porcentagemDescontoAplicadoErro = true;
      } else {
        this.porcentagemDescontoAplicadoErro = false;
      }
    } else {
      this.porcentagemDescontoAplicadoErro = true;
    }
  }

  /* Prazo de Pagamento */

  alterarPrazoPagamento(id_prazo_pagamento: string, cabecalho: boolean, event?: Event) {
    if (this.validarErrosProdutosAdicionados(true) > 0) {
      this.cancelarAlterarPrazoPagamento(event);

      return;
    }

    let idSelecionado: number = parseInt(id_prazo_pagamento);

    if (idSelecionado > 0) {
      const validarErrosProduto = this.validarErrosProduto(false);

      validarErrosProduto.then(() => {
        if (this.produtosComErro.length == 0) {
          /* Prazo Médio */

          this.prazoMedioService.getByIdPrazoPagamento(idSelecionado).subscribe(
            prazoMedio => this.prazoMedio = prazoMedio,
            error => { console.log("Erro: " + error) },
            () => {
              if (this.configuracao != null && this.configuracao.usar_desconto_prazo_medio == "S") {
                let valorTotalPadrao = this.produtosAdicionados.reduce((sum, current) => (sum + (current.price * current.qtde)), 0);
                let valorTotalAdicionado = this.produtosAdicionados.reduce((sum, current) => (sum + (current.valor * current.qtde)), 0);

                let qtdeCampanhas = this.produtosAdicionados.filter(produtoAdicionado => produtoAdicionado.categoria == this.campanhas).length;

                if (valorTotalAdicionado >= valorTotalPadrao && qtdeCampanhas == 0) {
                  /* Prazo Médio (Desconto) */

                  this.prazoMedioService.getPrazoMedioDescontoByGuidClienteNapis(this.prazoMedio.prazomedio).subscribe(
                    prazoMedioDesconto => this.prazoMedioDesconto = prazoMedioDesconto,
                    error => { console.log("Erro: " + error) },
                    () => {
                      this.prazoPagamentoInvalido = false;
                    }
                  );
                } else {
                  this.toastr.warning("", "Pedido fora da tabela padrão...");

                  this.prazoPagamentoInvalido = false;
                }
              } else {
                this.prazoPagamentoInvalido = false;
              }

              this.prazoPagamentoSelecionado = this.prazosPagamentoOriginal.find(prazoPagamento => prazoPagamento.idprazopagamento == idSelecionado);

              if (cabecalho) {
                this.carregarDadosPorCliente(false);
              }

              this.calcularFatorFinanceiro();
            }
          );
        } else {
          this.cancelarAlterarPrazoPagamento(event);
        }
      });
    } else {
      this.prazoPagamentoSelecionado = null;
      this.prazoMedio = null;
      this.prazoMedioDesconto = null;

      this.calcularFatorFinanceiro();
    }
  }

  cancelarAlterarPrazoPagamento(event?: Event) {
    if (event != null) {
      let target = event.target as HTMLSelectElement;

      if (this.prazoPagamentoSelecionado != null) {
        target.value = this.prazoPagamentoSelecionado.idprazopagamento.toString();
      } else {
        target.value = null;
      }

      event.preventDefault();
    }

    this.reiniciarFiltroProduto();
  }

  organizarPrazoPagamento(parcial: boolean, inicial: boolean) {
    let prazoPagamentoAnterior: PrazoPagamento = null;

    if (this.prazoPagamentoSelecionado != null) {
      prazoPagamentoAnterior = this.prazoPagamentoSelecionado;
    }

    if (this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido) {
      if (this.configuracao != null && this.configuracao.prazo_flex) {
        this.prazosPagamentoOriginal.forEach((item) => {
          if (this.valorTotalProdutosAdicionados < item.valorinicial) {
            if (item.id_prazo_pagamento_cliente == null) {
              let valorFaltante: number = item.valorinicial - this.valorTotalProdutosAdicionados;

              item.descricao = item.descricao_inicial + " (" + this.currencyPipe.transform(item.fator_financeiro, "", "") + "%)" + " - FALTAM: " + this.currencyPipe.transform(valorFaltante, "", "R$");
              item.prazo_especial = "S";
            } else {
              item.descricao = item.descricao_inicial + " (" + this.currencyPipe.transform(item.fator_financeiro, "", "") + "%)";
            }
          } else {
            item.descricao = item.descricao_inicial + " (" + this.currencyPipe.transform(item.fator_financeiro, "", "") + "%)";

            if (!item.especial_cliente) {
              item.prazo_especial = item.prazo_especial_inicial;
            }
          }
        });

        this.prazosPagamentoIniciais = this.prazosPagamentoOriginal.filter(prazoPagamento => prazoPagamento.prazo_especial == "N");

        if (this.configuracao != null && this.configuracao.flag_usa_prazo_vinculado_cliente) {
          this.prazosPagamentoEspeciais = [];
        } else {
          this.prazosPagamentoEspeciais = this.prazosPagamentoOriginal.filter(prazoPagamento => prazoPagamento.prazo_especial == "S");
        }
      } else {
        this.prazosPagamentoIniciais = this.prazosPagamentoOriginal.filter(prazoPagamento => prazoPagamento.prazo_especial == "N" && this.valorTotalProdutosAdicionados >= prazoPagamento.valorinicial);

        if (this.configuracao != null && this.configuracao.flag_usa_prazo_vinculado_cliente) {
          this.prazosPagamentoEspeciais = [];
        } else {
          this.prazosPagamentoEspeciais = this.prazosPagamentoOriginal.filter(prazoPagamento => prazoPagamento.prazo_especial == "S" && this.valorTotalProdutosAdicionados >= prazoPagamento.valorinicial);
        }
      }
    } else {
      this.prazosPagamentoIniciais = this.prazosPagamentoOriginal.filter(prazoPagamento => prazoPagamento.prazo_especial == "N");

      if (this.configuracao != null && this.configuracao.flag_usa_prazo_vinculado_cliente) {
        this.prazosPagamentoEspeciais = [];
      } else {
        this.prazosPagamentoEspeciais = this.prazosPagamentoOriginal.filter(prazoPagamento => prazoPagamento.prazo_especial == "S");
      }
    }

    if (prazoPagamentoAnterior != null) {
      let prazoPagamentoDisponivel: PrazoPagamento = this.prazosPagamentoIniciais.find(prazoPagamento => prazoPagamento.idprazopagamento == prazoPagamentoAnterior.idprazopagamento);

      if (prazoPagamentoDisponivel == null) {
        prazoPagamentoDisponivel = this.prazosPagamentoEspeciais.find(prazoPagamento => prazoPagamento.idprazopagamento == prazoPagamentoAnterior.idprazopagamento);

        if (prazoPagamentoDisponivel == null) {
          this.prazoPagamentoSelecionado = null;
          this.prazoMedio = null;
          this.prazoMedioDesconto = null;
        }
      }
    } else {
      this.prazoPagamentoSelecionado = null;
      this.prazoMedio = null;
      this.prazoMedioDesconto = null;
    }

    if (inicial) {
      if (this.clienteSelecionado.id_prazo_pagamento != null) {
        let prazoPagamento: PrazoPagamento = this.prazosPagamentoIniciais.find(prazoPagamento => prazoPagamento.idprazopagamento == this.clienteSelecionado.id_prazo_pagamento);

        if (prazoPagamento != null) {
          this.prazoPagamentoSelecionado = prazoPagamento;
        } else {
          prazoPagamento = this.prazosPagamentoEspeciais.find(prazoPagamento => prazoPagamento.idprazopagamento == this.clienteSelecionado.id_prazo_pagamento);

          if (prazoPagamento != null) {
            this.prazoPagamentoSelecionado = prazoPagamento;
          }
        }
      }
    }

    if (!parcial) {
      this.calcularFatorFinanceiro();
    }
  }

  /* Filtro (Clientes) */

  alterarFiltroCliente(filtro_selecionado: number) {
    this.reiniciarDadosCliente();

    let filtroAtual: string = this.formFiltroCliente.controls["filtro"].value;

    this.formFiltroCliente.reset();

    if (filtro_selecionado == null) {
      this.formFiltroCliente.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltroCliente.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltroCliente();

    this.enviadoCliente = false;
    this.filtrandoCliente = false;
  }

  filtrarCliente(recebendo_dados: boolean) {
    let retorno = new Promise<void>((resolver) => {
      this.enviadoCliente = true;

      let filtrar = new ClienteFiltrar();

      if (this.pedido == null && this.clienteSelecionadoExterno == "") {
        if (this.formFiltroCliente.invalid) {
          return resolver();
        }

        if (this.formFiltroCliente.controls["filtro"].value == "0") {
          /* Código */

          if (this.formFiltroCliente.controls["codigo"].value == "" || this.formFiltroCliente.controls["codigo"].value == null) {
            return resolver();
          }
        } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
          /* CNPJ/CPF */

          if (this.formFiltroCliente.controls["cnpj_cpf"].value == "" || this.formFiltroCliente.controls["cnpj_cpf"].value == null) {
            return resolver();
          }
        } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
          /* Nome Fantasia */

          if (this.formFiltroCliente.controls["nome_fantasia"].value == "" || this.formFiltroCliente.controls["nome_fantasia"].value == null) {
            return resolver();
          }
        } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
          /* Razão Social */

          if (this.formFiltroCliente.controls["razao_social"].value == "" || this.formFiltroCliente.controls["razao_social"].value == null) {
            return resolver();
          }
        }

        if (this.formFiltroCliente.controls["filtro"].value == "0") {
          /* Código */

          filtrar.codigo = this.formFiltroCliente.controls["codigo"].value;
        } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
          /* CNPJ/CPF */

          let cnpj_cpf = this.formFiltroCliente.controls["cnpj_cpf"].value.trim().split(".").join("").split("-").join("").split("/").join("");

          filtrar.cnpj_cpf = cnpj_cpf;
        } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
          /* Nome Fantasia */

          filtrar.nome_fantasia = this.formFiltroCliente.controls["nome_fantasia"].value;
        } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
          /* Razão Social */

          filtrar.razao_social = this.formFiltroCliente.controls["razao_social"].value;
        }
      }

      this.clientes = [];

      /* Filtro (Clientes) */

      if (this.callCenterConfiguracao != null) {
        if (this.pedido != null || this.clienteSelecionadoExterno != "") {
          if (this.pedido != null) {
            this.clienteService.getById(this.pedido.client_id).subscribe(
              cliente => this.clienteSelecionado = cliente,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.clienteSelecionado != null) {
                  this.clienteSelecionado.name = this.clienteSelecionado.name.trim().toUpperCase();
                  this.clienteSelecionado.endereco_completo = "";

                  if (this.clienteSelecionado.tipo_logradouro != null) {
                    this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + this.clienteSelecionado.tipo_logradouro.trim();
                  }

                  if (this.clienteSelecionado.endereco != null) {
                    this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + " " + this.clienteSelecionado.endereco.trim();
                  }

                  if (this.clienteSelecionado.end_numero != null) {
                    this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + ", " + this.clienteSelecionado.end_numero.trim();
                  }

                  if (this.clienteSelecionado.cidade != null) {
                    this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + " / " + this.clienteSelecionado.cidade.trim();
                  }

                  this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo.trim().toUpperCase();

                  this.clientes.push(this.clienteSelecionado);
                }

                this.selecionarClienteFiltrado(this.clienteSelecionado.codigo_no_erp_do_cliente, true, recebendo_dados);

                return resolver();
              }
            );
          } else {
            this.clienteService.getByCodigoERPCliente(this.clienteSelecionadoExterno).subscribe(
              cliente => this.clienteSelecionado = cliente,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.clienteSelecionado != null) {
                  this.clienteSelecionado.name = this.clienteSelecionado.name.trim().toUpperCase();
                  this.clienteSelecionado.endereco_completo = "";

                  if (this.clienteSelecionado.tipo_logradouro != null) {
                    this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + this.clienteSelecionado.tipo_logradouro.trim();
                  }

                  if (this.clienteSelecionado.endereco != null) {
                    this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + " " + this.clienteSelecionado.endereco.trim();
                  }

                  if (this.clienteSelecionado.end_numero != null) {
                    this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + ", " + this.clienteSelecionado.end_numero.trim();
                  }

                  if (this.clienteSelecionado.cidade != null) {
                    this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + " / " + this.clienteSelecionado.cidade.trim();
                  }

                  this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo.trim().toUpperCase();

                  this.clientes.push(this.clienteSelecionado);
                }

                this.selecionarClienteFiltrado(this.clienteSelecionado.codigo_no_erp_do_cliente, true, recebendo_dados);

                return resolver();
              }
            );
          }
        } else {
          if (this.callCenterConfiguracao.novaligacao_vertodosclientes) {
            this.clienteService.getByGuidClienteNapis(filtrar).subscribe(
              clientes => this.clientes = clientes,
              error => { console.log("Erro: " + error) },
              () => {
                this.clientes.forEach((item) => {
                  item.name = item.name.trim().toUpperCase();
                  item.endereco_completo = "";

                  if (item.tipo_logradouro != null) {
                    item.endereco_completo = item.endereco_completo + item.tipo_logradouro.trim();
                  }

                  if (item.endereco != null) {
                    item.endereco_completo = item.endereco_completo + " " + item.endereco.trim();
                  }

                  if (item.end_numero != null) {
                    item.endereco_completo = item.endereco_completo + ", " + item.end_numero.trim();
                  }

                  if (item.cidade != null) {
                    item.endereco_completo = item.endereco_completo + " / " + item.cidade.trim();
                  }

                  item.endereco_completo = item.endereco_completo.trim().toUpperCase();
                });

                this.filtrandoCliente = true;

                return resolver();
              }
            );
          } else {
            this.clienteService.getByUsuario(false, filtrar).subscribe(
              clientes => this.clientes = clientes,
              error => { console.log("Erro: " + error) },
              () => {
                this.clientes.forEach((item) => {
                  item.name = item.name.trim().toUpperCase();
                  item.endereco_completo = "";

                  if (item.tipo_logradouro != null) {
                    item.endereco_completo = item.endereco_completo + item.tipo_logradouro.trim();
                  }

                  if (item.endereco != null) {
                    item.endereco_completo = item.endereco_completo + " " + item.endereco.trim();
                  }

                  if (item.end_numero != null) {
                    item.endereco_completo = item.endereco_completo + ", " + item.end_numero.trim();
                  }

                  if (item.cidade != null) {
                    item.endereco_completo = item.endereco_completo + " / " + item.cidade.trim();
                  }

                  item.endereco_completo = item.endereco_completo.trim().toUpperCase();
                });

                this.filtrandoCliente = true;

                return resolver();
              }
            );
          }
        }
      }
    });

    return retorno;
  }

  organizarFiltroCliente() {
    if (this.formFiltroCliente.controls["filtro"].value == "0") {
      /* Código */

      this.formFiltroCliente.get("codigo").enable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
      /* CNPJ/CPF */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").enable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
      /* Nome Fantasia */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").enable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
      /* Razão Social */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").enable();
    }
  }

  reiniciarDadosCliente() {
    this.clientes = [];
  }

  selecionarClienteFiltrado(cliente_selecionado: string, cliente_existente: boolean, recebendo_dados: boolean) {
    if (cliente_selecionado != null && cliente_selecionado != "") {
      let clienteSelecionado: Cliente = this.clientes.find(cliente => cliente.codigo_no_erp_do_cliente == cliente_selecionado);

      if (clienteSelecionado != null) {
        /* Inadimplente */

        if (clienteSelecionado.status_financeiro != null) {
          this.toastr.warning("", "Cliente c/ inadimplência!");

          if (this.configuracao != null && this.configuracao.permite_lancar_pedido_inadimplente == "N") {
            return;
          }
        }

        this.clienteSelecionado = clienteSelecionado;

        /* Cliente / Vendedor */

        if (!recebendo_dados) {
          let clienteVendedor: Vendedor = this.vendedores.find(vendedor => vendedor.id == clienteSelecionado.seller_id);

          if (clienteVendedor != null) {
            this.vendedorSelecionado = clienteVendedor;
          }
        }

        if (!cliente_existente) {
          const carregarDadosPorCliente = this.carregarDadosPorCliente(true);

          carregarDadosPorCliente.then(() => {
            this.alterarFiltroCliente(null);

            this.clienteInvalido = false;
            this.filtrandoCliente = false;
          });
        } else {
          this.alterarFiltroCliente(null);

          this.clienteInvalido = false;
          this.filtrandoCliente = false;
        }
      }
    }
  }

  /* Filtro (Produtos) */

  alterarFiltroProduto(filtro_selecionado: number, erro_saldo?: boolean, cancelando?: boolean) {
    const validarErrosProduto = this.validarErrosProduto(cancelando);

    validarErrosProduto.then(() => {
      if (this.produtosComErro.length == 0) {
        this.reiniciarDadosProduto();

        let filtroAtual: string = this.formFiltroProduto.controls["filtro"].value;

        this.formFiltroProduto.reset();

        if (filtro_selecionado == null) {
          this.formFiltroProduto.controls["filtro"].setValue(filtroAtual);

          this.filtroProdutoSelecionado = filtroAtual;
        } else {
          this.formFiltroProduto.controls["filtro"].setValue(filtro_selecionado);

          this.filtroProdutoSelecionado = String(filtro_selecionado);
        }

        this.formFiltroProduto.controls["status"].setValue("0");

        this.organizarFiltroProduto();

        if (!erro_saldo) {
          this.saldoGeradoErro = false;
        }

        this.enviadoProduto = false;
        this.filtrandoProduto = false;
      } else {
        this.reiniciarFiltroProduto();
      }
    });
  }

  filtrarProduto() {
    const validarErrosProduto = this.validarErrosProduto(false);

    validarErrosProduto.then(() => {
      if (this.produtosComErro.length == 0) {
        this.enviadoProduto = true;

        if (this.formFiltroProduto.invalid) {
          if (this.formFiltroProduto.controls["status"].value == "2") {
            /* Item Reposição */

            if (this.formFiltroProduto.controls["filtro"].value == "1") {
              /* Código */

              this.formFiltroProduto.controls["codigo"].setErrors(null);
            } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
              /* Descrição */

              this.formFiltroProduto.controls["descricao"].setErrors(null);
            } else if (this.formFiltroProduto.controls["filtro"].value == "3") {
              /* Marca */

              this.formFiltroProduto.controls["marca"].setErrors(null);
            } else if (this.formFiltroProduto.controls["filtro"].value == "4") {
              /* Referência */

              this.formFiltroProduto.controls["referencia"].setErrors(null);
            }

            this.formFiltroProduto.updateValueAndValidity();
          } else {
            return;
          }
        }

        this.reiniciarDadosProduto();

        let filtrou: boolean = false;

        if (this.formFiltroProduto.controls["filtro"].value == "0") {
          /* Campanha */

          this.produtos.forEach((item) => {
            if (item.categoria == this.campanhas) {
              item.exibir = true;
            } else {
              item.exibir = false;
            }
          });
        } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
          /* Código */

          if (this.formFiltroProduto.controls["codigo"].value != "" && this.formFiltroProduto.controls["codigo"].value != null) {
            this.produtos.forEach((item) => {
              if (item.codigo == null) {
                item.exibir = false;
              } else if (item.codigo.toLowerCase() == this.formFiltroProduto.controls["codigo"].value.toLowerCase()) {
                item.exibir = true;
              }
            });

            filtrou = true;
          }
        } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
          /* Descrição */

          if (this.formFiltroProduto.controls["descricao"].value != "" && this.formFiltroProduto.controls["descricao"].value != null) {
            let palavras: string[] = this.formFiltroProduto.controls["descricao"].value.toString().split(" ");

            this.produtos.forEach((item) => {
              let qtdePalavrasEncontradasProduto: number = 0;

              palavras.forEach((subItem) => {
                if (item.name != null && item.name != "") {
                  if (item.name.toLowerCase().indexOf(subItem.toLowerCase()) !== -1) {
                    qtdePalavrasEncontradasProduto++;
                  }
                }
              });

              if (qtdePalavrasEncontradasProduto == palavras.length) {
                item.exibir = true;
              } else {
                item.exibir = false;
              }

              /* Produtos Vinculados */

              if (!item.exibir) {
                if (item.produtos_vinculados != null) {
                  let porProdutoVinculado: ProdutoPromocional[] = item.produtos_vinculados.filter(produtosVinculados => {
                    let qtdePalavrasEncontradasCampanha: number = 0;

                    palavras.forEach((subItem) => {
                      if (produtosVinculados.name.toLowerCase().indexOf(subItem.toLowerCase()) !== -1 && !produtosVinculados.bonificacao) {
                        qtdePalavrasEncontradasCampanha++;
                      }
                    });

                    return qtdePalavrasEncontradasCampanha == palavras.length;
                  });

                  if (porProdutoVinculado.length > 0) {
                    item.exibir = true;
                  }
                }
              }
            });

            filtrou = true;
          }
        } else if (this.formFiltroProduto.controls["filtro"].value == "3") {
          /* Marca */

          if (this.formFiltroProduto.controls["marca"].value != "" && this.formFiltroProduto.controls["marca"].value != null) {
            this.produtos.forEach((item) => {
              if (item.grupo_estoque == null) {
                item.exibir = false;
              } else if (item.grupo_estoque.toLowerCase().indexOf(this.formFiltroProduto.controls["marca"].value.trim().toLowerCase()) !== -1) {
                item.exibir = true;
              }
            });

            filtrou = true;
          }
        } else if (this.formFiltroProduto.controls["filtro"].value == "4") {
          /* Referência */

          if (this.formFiltroProduto.controls["referencia"].value != "" && this.formFiltroProduto.controls["referencia"].value != null) {
            this.produtos.forEach((item) => {
              if (item.referencia_cliente == null) {
                item.exibir = false;
              } else if (item.referencia_cliente.toLowerCase().indexOf(this.formFiltroProduto.controls["referencia"].value.trim().toLowerCase()) !== -1) {
                item.exibir = true;
              }
            });

            filtrou = true;
          }
        }

        let produtosFiltrados = this.produtos.filter(produto => produto.exibir);

        if (this.formFiltroProduto.controls["filtro"].value != "0") {
          /* Status */

          if (this.formFiltroProduto.controls["status"].value == "0") {
            /* Todos */

          } else if (this.formFiltroProduto.controls["status"].value == "1") {
            /* Novo Item */

            if (produtosFiltrados.length > 0 || filtrou) {
              produtosFiltrados.forEach((item) => {
                if (item.ultima_compra != null) {
                  item.exibir = false;
                } else {
                  item.exibir = true;
                }
              });

              produtosFiltrados = produtosFiltrados.filter(produto => produto.exibir);
            } else {
              this.produtos.forEach((item) => {
                if (item.ultima_compra != null) {
                  item.exibir = false;
                } else {
                  item.exibir = true;
                }
              });

              produtosFiltrados = this.produtos.filter(produto => produto.exibir);
            }
          } else if (this.formFiltroProduto.controls["status"].value == "2") {
            /* Item Reposição */

            if (produtosFiltrados.length > 0 || filtrou) {
              produtosFiltrados.forEach((item) => {
                if (item.ultima_compra == null) {
                  item.exibir = false;
                } else {
                  item.exibir = true;
                }
              });

              produtosFiltrados = produtosFiltrados.filter(produto => produto.exibir);
            } else {
              this.produtos.forEach((item) => {
                if (item.ultima_compra == null) {
                  item.exibir = false;
                } else {
                  item.exibir = true;
                }
              });

              produtosFiltrados = this.produtos.filter(produto => produto.exibir);
            }
          }
        }

        if (produtosFiltrados.length == 0) {
          this.toastr.error("", "Nenhum produto localizado!");

          this.saldoGeradoErro = false;
          this.filtrandoProduto = false;
        } else {
          this.produtos.forEach((item) => {
            if (!item.exibir) {
              let produtosSimilaresFiltrados = this.produtosSimilares.filter(produtoSimilar => produtoSimilar.product_similar == item.id);

              produtosSimilaresFiltrados.forEach((subItem) => {
                let produtoSimilar = this.produtos.find(produto => produto.id == subItem.product_id);

                if (produtoSimilar != null && produtoSimilar.exibir) {
                  item.exibir = true;
                }
              });
            }
          });

          this.produtos = this.produtos.filter(produto => produto.exibir);

          this.produtos.forEach((produto) => {
            if (((produto.qtde != 0 && produto.qtde != null) || (produto.qtde_bonificada != 0 && produto.qtde_bonificada != null)) && (produto.valor != 0 && produto.valor != null)) {
              produto.expandido = true;
            } else {
              produto.expandido = false;
            }
          });

          if (this.formFiltroProduto.controls["status"].value == "2") {
            /* Item Reposição */

            this.produtos = this.produtos.sort((a, b) => {
              const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

              return compare(a["ultima_compra"], b["ultima_compra"]) * -1; /* Decrescente */
            });
          } else {
            this.produtos = this.produtos.sort((a, b) => {
              const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

              return compare(a["categoria"], b["categoria"]);
            });

            this.produtos = this.produtos.sort((a, b) => {
              const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

              return compare(a["name"], b["name"]);
            });
          }

          this.filtrandoProduto = true;
        }
      } else {
        this.reiniciarFiltroProduto();
      }

      this.ajustarElementos();
    });
  }

  organizarFiltroProduto() {
    if (this.formFiltroProduto.controls["filtro"].value == "0") {
      /* Campanha */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").disable();
      this.formFiltroProduto.get("referencia").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
      /* Código */

      this.formFiltroProduto.get("codigo").enable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").disable();
      this.formFiltroProduto.get("referencia").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
      /* Descrição */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").enable();
      this.formFiltroProduto.get("marca").disable();
      this.formFiltroProduto.get("referencia").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "3") {
      /* Marca */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").enable();
      this.formFiltroProduto.get("referencia").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "4") {
      /* Referência */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").disable();
      this.formFiltroProduto.get("referencia").enable();
    }
  }

  reiniciarFiltroProduto() {
    let filtroInicial: string = "2";

    this.formFiltroProduto.reset();

    this.formFiltroProduto.controls["filtro"].setValue(filtroInicial);
    this.formFiltroProduto.controls["status"].setValue("0");

    this.filtroProdutoSelecionado = filtroInicial;

    this.organizarFiltroProduto();

    this.enviadoProduto = false;
  }

  /* Filtro (Produtos (Campanhas)) */

  alterarFiltroProdutoCampanha() {
    this.reiniciarDadosProdutoCampanha(true);

    this.formFiltroProdutoCampanha.reset();

    this.enviadoProdutoCampanha = false;
    this.filtrandoProdutoCampanha = false;
  }

  filtrarProdutoCampanha() {
    this.enviadoProdutoCampanha = true;

    if (this.formFiltroProdutoCampanha.invalid) {
      return;
    }

    this.reiniciarDadosProdutoCampanha(false);

    /* Descrição */

    if (this.formFiltroProdutoCampanha.controls["descricao"].value != "" && this.formFiltroProdutoCampanha.controls["descricao"].value != null) {
      let palavras: string[] = this.formFiltroProdutoCampanha.controls["descricao"].value.toString().split(" ");

      this.produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
        if (!item.bonificacao) {
          let qtdePalavrasEncontradasProdutoCampanha: number = 0;

          palavras.forEach((subItem) => {
            if (item.name.toLowerCase().indexOf(subItem.toLowerCase()) !== -1) {
              qtdePalavrasEncontradasProdutoCampanha++;
            }
          });

          if (qtdePalavrasEncontradasProdutoCampanha == palavras.length) {
            item.exibir = true;
          }
        }
      });

      this.filtrandoProdutoCampanha = true;
    }
  }

  reiniciarDadosProdutoCampanha(exibir: boolean) {
    this.produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
      if (!item.bonificacao) {
        item.exibir = exibir;
      }
    });
  }

  /* Formatação */

  formatarValoresQuatroCasasDecimais(valor: number, porcentagem?: boolean, zero?: boolean): string {
    if (valor != null) {
      if (porcentagem) {
        return (valor).toLocaleString("pt-BR", {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4
        });
      } else {
        return (valor).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 4,
          maximumFractionDigits: 4
        });
      }
    } else {
      if (zero) {
        return (0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 4,
          maximumFractionDigits: 4
        });
      }

      return null;
    }
  }

  /* Itens (Enviados) */

  expandirPedidosItensEnviados(i: number, id: number) {
    this.exibirFatorFinanceiroEnviados = false;

    this.pedidosItensEnviadosExpandido = i == this.pedidosItensEnviadosExpandido ? -1 : i;

    if (this.pedidosItensEnviadosExpandido > -1) {
      this.pedidosItensEnviados = this.pedidosItensEnviadosClientes.filter(pedidoItensCliente => {
        return pedidoItensCliente.order_id == id;
      });

      let qtdeProdutosFatorFinanceiro: number = this.pedidosItensEnviados.filter(pedidoItensCliente => pedidoItensCliente.fator_financeiro != null && pedidoItensCliente.fator_financeiro != 0).length;

      if (qtdeProdutosFatorFinanceiro > 0) {
        this.exibirFatorFinanceiroEnviados = true;
      }
    }
  }

  /* Itens (Menu) */

  alterarItemSelecionado(item: number) {
    this.validarItens(item);

    if (item > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item;
  }

  prosseguirItemSelecionado(item: number) {
    this.validarItens(item + 1);

    if ((item + 1) > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item + 1;
  }

  retornarItemSelecionado(item: number) {
    this.itemSelecionado = item - 1;
  }

  validarItens(item: number) {
    /* Validações */

    this.itemMaximoPermitido = 0;

    /* Cabeçalho */

    if (this.clienteSelecionado == null) {
      this.toastr.error("", "Cliente em branco!");

      this.clienteInvalido = true;
    }

    if (this.configuracao != null && (this.configuracao.prazo_pagamento_antes_itenspedido && (this.prazoPagamentoSelecionado == null || this.prazoPagamentoSelecionado.idprazopagamento == 0))) {
      this.toastr.error("", "Prazo de Pagamento inválido!");

      this.prazoPagamentoInvalido = true;
    } else {
      this.prazoPagamentoInvalido = false;
    }

    if (this.pedido == null) {
      /* Adicionado */

      if (this.configuracao != null && this.configuracao.pedido_dt_validade_automatica != null) {
        if (this.dataValidade != null && this.dataValidade.year != null && this.dataValidade.month != null && this.dataValidade.day != null) {
          let dataValidade = new Date(this.dataValidade.year, this.dataValidade.month - 1, this.dataValidade.day, 23, 59, 59);

          if (dataValidade < new Date()) {
            this.toastr.error("", "Dt. Validade inválida!");

            this.dataValidadeInvalida = true;
          } else {
            this.dataValidadeInvalida = false;
          }
        } else {
          this.dataValidadeInvalida = false;
        }
      }
    }

    if (!this.clienteInvalido && !this.prazoPagamentoInvalido && !this.dataValidadeInvalida) {
      this.itemMaximoPermitido = 1;
    } else {
      this.itemSelecionado = 0;

      return;
    }

    /* Itens */

    if (this.produtosAdicionados.length == 0) {
      if (item > this.itemMaximoPermitido) {
        this.toastr.error("", "Nenhum item adicionado!");

        this.itemSelecionado = 1;

        return;
      }
    } else {
      if (this.validarErrosProdutosAdicionados(true) > 0) {
        this.itemSelecionado = 1;

        return;
      } else {
        if (item > this.itemMaximoPermitido) {
          if (this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido && this.configuracao.utiliza_fator_financeiro) {
            if (this.prazoPagamentoSelecionado == null || this.prazoPagamentoSelecionado.idprazopagamento == 0) {
              this.toastr.error("", "Prazo de Pagamento inválido!");

              this.prazoPagamentoInvalido = true;

              this.itemSelecionado = 1;

              return;
            } else {
              this.itemMaximoPermitido = 2;
            }
          } else {
            this.itemMaximoPermitido = 2;
          }
        }
      }      
    }

    /* Detalhes */

    if (this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido && !this.configuracao.utiliza_fator_financeiro) {
      if (this.prazoPagamentoSelecionado == null || this.prazoPagamentoSelecionado.idprazopagamento == 0) {
        if (item > this.itemMaximoPermitido) {
          this.toastr.error("", "Prazo de Pagamento inválido!");

          this.prazoPagamentoInvalido = true;

          this.itemSelecionado = 2;

          return;
        }
      } else {
        this.itemMaximoPermitido = 3;
      }
    } else {
      this.itemMaximoPermitido = 3;
    }
  }

  /* Itens (Não Enviados) */

  expandirPedidosItensNaoEnviados(i: number, id: number) {
    this.exibirFatorFinanceiroNaoEnviados = false;

    this.pedidosItensNaoEnviadosExpandido = i == this.pedidosItensNaoEnviadosExpandido ? -1 : i;

    if (this.pedidosItensNaoEnviadosExpandido > -1) {
      this.pedidosItensNaoEnviados = this.pedidosItensNaoEnviadosClientes.filter(pedidoItensCliente => {
        return pedidoItensCliente.order_id == id;
      });

      let qtdeProdutosFatorFinanceiro: number = this.pedidosItensNaoEnviados.filter(pedidoItensCliente => pedidoItensCliente.fator_financeiro != null && pedidoItensCliente.fator_financeiro != 0).length;

      if (qtdeProdutosFatorFinanceiro > 0) {
        this.exibirFatorFinanceiroNaoEnviados = true;
      }
    }
  }

  /* Lote */

  removerLoteProduto(id_produto: number) {
    let produto = this.produtos.find(produto => produto.id == id_produto);

    if (produto != null) {
      produto.erro_lote = false;
      produto.erro_qtde = false;
      produto.erro_valor = false;

      produto.valor_tabela = 0;

      produto.qtde = null;
      produto.valor = null;

      produto.valor_total = 0;
      produto.porcentagem_desconto = 0;
      produto.descritivo_desconto = null;
      produto.observacao_item = null;

      produto.saldo_gerado = 0;

      /* Lote */

      produto.id_lote = null;
      produto.nome_lote = null;
      produto.cod_lote = null;
      produto.estoque_lote = null;
      produto.validade_lote = null;
      produto.preco_lote = null;

      /* Imposto */

      produto.valor_imposto = 0;
      produto.valor_imposto_unitario = 0;
      produto.valor_imposto_produto = null;
      produto.valor_imposto_total = 0;

      produto.valor_imposto_total_final = 0;

      produto.valor_unitario_final = 0;

      /* Ordem */

      produto.ordem = 0;

      /* Qtde (Bonificada) */

      produto.qtde_bonificada = null;

      /* Total Fracionado */

      produto.valor_unitario_fracionado = null;
      produto.valor_unitario_imposto_fracionado = null;

      /* Porcentagem Desconto Temporária */

      produto.porcentagem_desconto_temporaria = null;
    }
  }

  selecionarLoteProduto(id_lote: number) {
    if (this.produtoLotesSelecionado != null) {
      let produto = this.produtos.find(produto => produto.id == this.produtoLotesSelecionado.id);

      if (produto != null) {
        let produtoLote = this.produtoLotes.find(produtoLote => produtoLote.id == id_lote);

        if (produtoLote != null) {
          produto.erro_lote = false;

          produto.valor_tabela = produtoLote.preco;

          produto.valor = null;

          /* Lote */

          produto.id_lote = produtoLote.id;
          produto.nome_lote = produtoLote.nomelote;
          produto.cod_lote = produtoLote.codlote;
          produto.estoque_lote = produtoLote.estoque;
          produto.validade_lote = produtoLote.validade;
          produto.preco_lote = produtoLote.preco;

          this.validarProdutoQtde(produto.id, true);
          this.validarProdutoValor(produto.id, true);
        }

        this.produtoLotesSelecionado = produto;
      }
    }

    this.modalReferenciaSelecionarLote.close();
  }

  /* Modal */

  modalAdicionarItens(content: any, id_produto: number, id_campanha: number) {
    if (this.validarErrosProdutosAdicionados(true) > 0) {
      return;
    }

    if (this.produtosComErro.length == 0) {
      this.produtosAdicionadosOriginal = JSON.parse(JSON.stringify(this.produtosAdicionados));

      if (this.saldoGeradoErro && id_produto == 0 && id_campanha == 0) {
        this.alterarFiltroProduto(null, true);

        let produtosDigitados = this.produtosOriginal.filter(produtoOriginal => ((produtoOriginal.qtde != 0 && produtoOriginal.qtde != null) || (produtoOriginal.qtde_bonificada != 0 && produtoOriginal.qtde_bonificada != null)) && produtoOriginal.valor != 0 && produtoOriginal.valor != null);

        produtosDigitados.forEach((item) => {
          item.exibir = true;
          item.expandido = true;
        });

        this.produtos = JSON.parse(JSON.stringify(produtosDigitados));
      } else {
        /* Abrir s/ Erro(s) */

        if (!this.saldoGeradoErro) {
          this.produtoSelecionado = null;

          if (id_produto != 0 || id_campanha != 0) {
            /* Alterar */

            let produtoSelecionado: ProdutoPromocional = null;

            if (id_campanha != null) {
              /* Campanha */

              produtoSelecionado = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id_campanha == id_campanha);
            } else {
              /* Produto */

              produtoSelecionado = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id == id_produto);
            }

            produtoSelecionado.exibir = true;
            produtoSelecionado.expandido = true;

            this.produtoSelecionado = JSON.parse(JSON.stringify(produtoSelecionado));

            this.produtos = [];

            this.produtos.push(this.produtoSelecionado);

            this.enviadoProduto = true;
            this.filtrandoProduto = true;
          } else {
            if (this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido && this.configuracao.utiliza_fator_financeiro) {
              this.alterarFiltroProduto(null, null, true);
            } else {
              this.alterarFiltroProduto(null);
            }
          }
        }
      }
    } else {
      this.reiniciarFiltroProduto();
    }

    this.ajustarElementos();

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-adicionar-itens",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Salvar") {
        const validarErrosProduto = this.validarErrosProduto(false);

        validarErrosProduto.then(() => {
          if (this.produtosComErro.length > 0) {
            if (this.produtoSelecionado == null) {
              this.modalAdicionarItens(content, 0, 0);
            } else {
              this.modalAdicionarItens(content, this.produtoSelecionado.id, this.produtoSelecionado.id_campanha);
            }
          } else {
            this.saldoGeradoErro = false;

            /* Fechar c/ Alteração */

            this.produtosAdicionados = [];

            let produtosVerificados: ProdutoPromocional[] = [];

            /* Produtos Original p/ Produtos Adicionados */

            this.produtosOriginal.filter(produtoOriginal => (produtoOriginal.qtde > 0 || produtoOriginal.qtde_bonificada > 0) && produtoOriginal.valor > 0).forEach((item) => {
              let qtde: number = item.qtde != null && item.qtde > 0 ? item.qtde : 0;

              let valorTotal: number = item.valor * qtde;

              item.valor_total = parseFloat(valorTotal.toFixed(2));

              const produto: ProdutoPromocional = JSON.parse(JSON.stringify(item));

              produtosVerificados.push(produto);
            });

            this.produtosAdicionados = JSON.parse(JSON.stringify(produtosVerificados));

            if (this.produtosAdicionados.length > 0) {
              this.ordenarProdutosAdicionados();
              this.totalizarProdutosAdicionados();

              if (this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido) {
                this.organizarPrazoPagamento(false, false);
              } else {
                this.calcularFatorFinanceiro();
              }

              this.calcularSimulacaoResultado();
              this.totalizarSaldoGerado();
            }

            this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
          }
        });
      } else {
        /* Fechar s/ Alteração */

        this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));

        const validarErrosProduto = this.validarErrosProduto(true);

        validarErrosProduto.then(() => {
          if (this.produtosComErro.length > 0) {
            if (this.produtoSelecionado == null) {
              this.modalAdicionarItens(content, 0, 0);
            } else {
              this.modalAdicionarItens(content, this.produtoSelecionado.id, this.produtoSelecionado.id_campanha);
            }
          } else {
            /* Produtos Adicionados (Original) p/ Produtos Adicionados */

            this.produtosAdicionados = JSON.parse(JSON.stringify(this.produtosAdicionadosOriginal));

            if (this.produtosAdicionados.length > 0) {
              this.ordenarProdutosAdicionados();
              this.calcularFatorFinanceiro();
            }

            this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
          }
        });

        /* Tipo Pedido */

        if (this.tiposPedidoErro) {
          this.tiposPedidoErro = false;
          this.tiposPedidoSelecionado = this.tiposPedidoSelecionadoOriginal;
        }

        /* Saldo Gerado */

        this.saldoGeradoErro = false;
      }
    }, () => {
      /* Fechar s/ Alteração */

      this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));

      const validarErrosProduto = this.validarErrosProduto(true);

      validarErrosProduto.then(() => {
        if (this.produtosComErro.length > 0) {
          if (this.produtoSelecionado == null) {
            this.modalAdicionarItens(content, 0, 0);
          } else {
            this.modalAdicionarItens(content, this.produtoSelecionado.id, this.produtoSelecionado.id_campanha);
          }
        } else {
          /* Produtos Adicionados (Original) p/ Produtos Adicionados */

          this.produtosAdicionados = JSON.parse(JSON.stringify(this.produtosAdicionadosOriginal));

          if (this.produtosAdicionados.length > 0) {
            this.ordenarProdutosAdicionados();
            this.calcularFatorFinanceiro();
          }

          this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
        }
      });

      /* Tipo Pedido */

      if (this.tiposPedidoErro) {
        this.tiposPedidoErro = false;
        this.tiposPedidoSelecionado = this.tiposPedidoSelecionadoOriginal;
      }

      /* Saldo Gerado */

      this.saldoGeradoErro = false;
    });
  }

  modalAdicionarItensCampanha(content: any, id_campanha: number) {
    if (id_campanha != 0) {
      let produtoCampanhaSelecionado: ProdutoPromocional = this.produtos.find(produto => produto.id_campanha == id_campanha);

      this.produtoCampanhaSelecionado = JSON.parse(JSON.stringify(produtoCampanhaSelecionado));

      let produtoAdicionado: ProdutoPromocional = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id_campanha == id_campanha);

      if (produtoAdicionado == null) {
        /* Bonificação */

        this.localizarProdutoBonificado();
        this.definirQtdeBonificacaoMultiplicavel(false);
      } else {
        this.validarCampanhaQtdeGeral();
      }

      /* Bonificação */

      this.bonificacaoInvalida = false;

      this.calcularImpostoCampanhaProduto();
    }

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-adicionar-itens-campanha",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Salvar") {
        if (this.validarErrosCampanha()) {
          this.definirOrdemInclusao(this.produtoCampanhaSelecionado.id, true);

          const repassarDadosCampanha = this.repassarDadosCampanha(false);

          repassarDadosCampanha.then(() => {
            /* Produtos Original p/ Produtos Adicionados */

            this.produtosAdicionados = JSON.parse(JSON.stringify(this.produtosOriginal.filter(produtoOriginal => produtoOriginal.qtde > 0 && produtoOriginal.valor > 0)));

            /* Totalizar */

            if (this.produtoCampanhaSelecionado.grupo_estoque == this.combo) {
              this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.valor_total_produtos_vinculados == 0 ? null : this.produtoCampanhaSelecionado.valor_total_produtos_vinculados;

              let valorTotal: number = this.produtoCampanhaSelecionado.valor_total_produtos_vinculados * this.produtoCampanhaSelecionado.qtde;

              this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
            } else {
              if (this.produtoCampanhaSelecionado.price_promotional > 0) {
                this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.price_promotional == 0 ? null : this.produtoCampanhaSelecionado.price_promotional;

                let valorTotal: number = this.produtoCampanhaSelecionado.price_promotional * this.produtoCampanhaSelecionado.qtde;

                this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
              } else {
                this.produtoCampanhaSelecionado.valor = this.produtoCampanhaSelecionado.price == 0 ? null : this.produtoCampanhaSelecionado.price;

                let valorTotal: number = this.produtoCampanhaSelecionado.price * this.produtoCampanhaSelecionado.qtde;

                this.produtoCampanhaSelecionado.valor_total = parseFloat(valorTotal.toFixed(2));
              }
            }

            this.atualizarDadosCampanha(this.produtoCampanhaSelecionado.id_campanha);

            this.produtoCampanhaSelecionado = null;
          });
        } else {
          this.modalAdicionarItensCampanha(content, 0);
        }
      } else {
        this.alterarFiltroProdutoCampanha();
        this.repassarDadosCampanha(true);

        this.produtoCampanhaSelecionado = null;
      }
    }, () => {
      this.alterarFiltroProdutoCampanha();
      this.repassarDadosCampanha(true);

      this.produtoCampanhaSelecionado = null;
    });
  }

  modalAdicionarItensEstoqueLocal(content: any, id_produto: number) {
    this.produtoLocais = null;

    /* Produto (Lotes) */

    this.produtoService.getProdutoLocalByIdProduto(id_produto).subscribe(
      produtoLocal => this.produtoLocais = produtoLocal,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.produtoLocais.length > 0) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-adicionar-itens-estoque-local",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhum estoque local encontrado!");
        }
      }
    );
  }

  modalAdicionarItensFotos(content: any, id_produto: number) {
    this.produtoFotosSelecionado = null;

    /* Produto (Fotos) */

    this.produtoService.getProdutoFotosByIdProduto(id_produto).subscribe(
      produtoFoto => this.produtoFotos = produtoFoto,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.produtoFotos.length > 0) {
          this.produtoFotos.forEach((item) => {
            item.imagem = "data:image/png;base64," + item.imagem;
          });

          this.produtoFotosSelecionado = this.produtos.find(produto => produto.id == id_produto);

          this.modalService.open(content, {
            ariaLabelledBy: "titulo-adicionar-itens-fotos",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhuma foto encontrada!");
        }
      }
    );
  }

  modalAdicionarItensInformacoes(content: any, id_produto: number) {
    this.produtoInformacoesSelecionado = null;

    /* Produto (Ficha Técnica) */

    this.produtoService.getFichaTecnicaByIdProduto(id_produto).subscribe(
      produtosFichasTecnicas => this.produtoFichasTecnicas = produtosFichasTecnicas,
      error => { console.log("Erro: " + error) },
      () => {
        this.produtoInformacoesSelecionado = this.produtos.find(produto => produto.id == id_produto);

        if (this.produtoFichasTecnicas.length > 0 || (this.produtoInformacoesSelecionado.ficha_tecnica != null && this.produtoInformacoesSelecionado.ficha_tecnica != "")) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-adicionar-itens-informacoes",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhuma informação encontrada!");
        }
      }
    );
  }

  modalAdicionarItensLotes(content: any, id_produto: number) {
    this.produtoLotesSelecionado = null;

    /* Produto (Lotes) */

    this.produtoService.getProdutoLoteByIdProduto(id_produto, this.obs_gerais, this.clienteSelecionado.id, this.formaPagamentoSelecionada.id).subscribe(
      produtoLote => this.produtoLotes = produtoLote,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.produtoLotes.length > 0) {
          this.produtoLotesSelecionado = this.produtos.find(produto => produto.id == id_produto);

          this.modalReferenciaSelecionarLote = this.modalService.open(content, {
            ariaLabelledBy: "titulo-adicionar-itens-lotes",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhum lote encontrado!");
        }
      }
    );
  }

  modalAdicionarItensTabelas(content: any, id_produto: number, id_lote: number) {
    this.produtoTabelasSelecionado = null;

    let produto = this.produtos.find(produto => produto.id == id_produto);

    if (produto != null) {
      if (produto.qtde_lote > 0 && produto.id_lote == null) {
        this.toastr.error("", "Nenhum lote selecionado!");

        return;
      }

      /* Produto (Tabelas) */

      let idPrazoPagamento: number = null;

      if (this.prazoPagamentoSelecionado != null && this.configuracao != null && this.configuracao.prazo_pagamento_antes_itenspedido) {
        idPrazoPagamento = this.prazoPagamentoSelecionado.idprazopagamento;
      }

      this.produtoService.getTabelaByProduto(this.clienteSelecionado.id, this.vendedorSelecionado.id, id_produto, id_lote, idPrazoPagamento).subscribe(
        produtoTabela => this.produtoTabelas = produtoTabela,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.produtoTabelas.length > 0) {
            this.produtoTabelasSelecionado = produto;

            this.produtoTabelas.forEach((item) => {
              if (produto.valorunidade != null && produto.valorunidade > 0) {
                item.preco_fracionado = item.preco / produto.valorunidade;
              } else {
                item.preco_fracionado = item.preco;
              }
            });

            this.modalReferenciaTabelasProdutos = this.modalService.open(content, {
              ariaLabelledBy: "titulo-adicionar-itens-tabelas",
              size: "lg",
              backdrop: "static"
            });
          } else {
            this.toastr.error("", "Nenhuma tabela encontrada!");
          }
        }
      );
    }
  }

  modalComBonificacao(content: any, numero_pedido_bonificado: number, seller_id: number) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-com-bonificacao",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Sim") {
        this.router.navigate(["/pedidos-cadastro/" + numero_pedido_bonificado], {
          queryParams: {
            vendedor: seller_id
          }          
        });
      } else if (acao == "Nao") {
        this.redirecionar("salvar_enviar");
      }
    }, () => {
      this.redirecionar("salvar_enviar");
    });
  }

  modalComposicao(content: any) {
    if (!this.visualizandoComposicao) {
      this.visualizandoComposicao = true;

      this.modalService.open(content, {
        ariaLabelledBy: "titulo-composicao",
        size: "xl",
        backdrop: "static"
      }).result.then(() => {
        this.produtosComposicoes = [];

        this.visualizandoComposicao = false;
      }, () => {
        this.produtosComposicoes = [];

        this.visualizandoComposicao = false;
      });
    }
  }

  modalDescontoItens(content: any) {
    /* Desconto */

    if (this.configuracao != null && this.configuracao.usar_desconto_prazo_medio == "S") {
      if (this.prazoMedioDesconto != null && this.prazoMedioDesconto.percentual_desconto > 0) {
        if (this.pedido != null && this.pedido.order_id_origem_bonificacao != 0) {
          /* Bonificação */

          this.confirmarPedido("salvar_enviar");
        } else {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-desconto-itens",
            size: "lg",
            backdrop: "static"
          }).result.then((acao) => {
            if (acao == "SalvarEnviar") {
              if (!this.porcentagemDescontoAplicadoErro) {
                this.confirmarPedido("salvar_enviar");
              } else {
                this.toastr.error("", "Desconto Aplicado inválido!");

                this.modalDescontoItens(content);
              }
            } else {

            }
          }, () => {

          });
        }
      } else {
        this.confirmarPedido("salvar_enviar");
      }
    } else {
      this.confirmarPedido("salvar_enviar");
    }
  }

  modalNotasFiscaisSinteticas(content: any, client_document: string) {
    client_document = client_document.replace(/[.]/g, "").replace(/[/]/g, "").replace(/[-]/g, "");

    this.notaFiscalService.getNotaFiscalSinteticaByCNPJ(client_document).subscribe(
      notaFiscal => this.notasFiscais = notaFiscal,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.notasFiscais.length > 0) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-notas-fiscais-sinteticas",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhuma nota fiscal encontrada!");
        }
      }
    );
  }

  /* Cálculos */

  /* Desconto / Produto */

  calcularDescontoUnitario(id_produto: number, alterar: boolean, adicionado: boolean) {
    let produto: ProdutoPromocional = null;

    if (adicionado) {
      produto = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id == id_produto);
    } else {
      produto = this.produtos.find(produto => produto.id == id_produto);
    }

    if (produto != null) {
      let qtde: number = produto.qtde != null && produto.qtde > 0 ? produto.qtde : 0;
      let valorCalculoInicial: number = 0;

      if (this.prazoPagamentoSelecionado != null && this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido && this.configuracao.utiliza_fator_financeiro) {
        if (produto.aplica_fator_financeiro) {
          /* C/ Fator */

          valorCalculoInicial = produto.valor_fator_financeiro_tabela != null ? produto.valor_fator_financeiro_tabela : 0;
        } else {
          valorCalculoInicial = produto.valor_tabela != null && produto.valor_tabela > 0 ? produto.valor_tabela : produto.price;
        }
      } else {
        /* S/ Fator */

        valorCalculoInicial = produto.valor_tabela != null && produto.valor_tabela > 0 ? produto.valor_tabela : produto.price;
      }

      if (alterar && produto.porcentagem_desconto_temporaria != null && !isNaN(produto.porcentagem_desconto_temporaria) && isFinite(produto.porcentagem_desconto_temporaria)) {
        if (produto.porcentagem_desconto_temporaria > 0 && produto.porcentagem_desconto_temporaria <= 100) {
          let valorDesconto: number = ((valorCalculoInicial * produto.porcentagem_desconto_temporaria) / 100);
          let valorCalculado: number = valorCalculoInicial - valorDesconto;

          if (valorCalculado <= 0) {
            produto.valor = null;
          } else {
            produto.valor = this.formatacaoService.quatroCasasDecimais(valorCalculado);

            let valorTotal: number = valorCalculado * qtde;

            produto.valor_total = parseFloat(valorTotal.toFixed(2));

            if (produto.qtde != null && produto.qtde > 0) { // aqui
              produto.erro_valor = false;
            }
          }
        } else {
          produto.porcentagem_desconto_temporaria = null;
        }
      } else {
        if (this.porcentagemDescontoTemporario != null && (produto.porcentagem_desconto_temporaria == null || produto.porcentagem_desconto_temporaria == 0)) {
          produto.valor = valorCalculoInicial;

          let valorTotal: number = valorCalculoInicial * qtde;

          produto.valor_total = parseFloat(valorTotal.toFixed(2));
        } else {
          let valor: number = produto.valor != null ? produto.valor : 0;

          let porcentagemDiferenca: number = (100 - ((valor / valorCalculoInicial) * 100));

          if (porcentagemDiferenca > 0) {
            produto.porcentagem_desconto_temporaria = this.formatacaoService.quatroCasasDecimais(porcentagemDiferenca);
          } else {
            produto.porcentagem_desconto_temporaria = null;
          }
        }
      }

      this.calcularFatorFinanceiroProduto(id_produto, adicionado);
    }
  }

  /* Fator Financeiro */

  calcularFatorFinanceiro() {
    this.exibirFatorFinanceiro = false;

    if (this.prazoPagamentoSelecionado != null && this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido && this.configuracao.utiliza_fator_financeiro) {
      /* Produtos Original */

      this.produtosOriginal.forEach((item) => {
        if (item.produtos_vinculados != null) {
          /* Produtos Vinculados */

          item.produtos_vinculados.forEach((subItem) => {
            let qtde: number = subItem.qtde != null && subItem.qtde > 0 ? subItem.qtde : 0;
            let valorCalculoInicial: number = subItem.price_promotional != null && subItem.price_promotional > 0 ? subItem.price_promotional : subItem.price;

            if (subItem.aplica_fator_financeiro) {
              /* C/ Fator */

              /* Inicial */

              subItem.porcentagem_fator_inicial = this.prazoPagamentoSelecionado.fator_financeiro;

              let valorFatorFinanceiroTabela: number = (valorCalculoInicial + ((valorCalculoInicial * this.prazoPagamentoSelecionado.fator_financeiro) / 100));

              subItem.valor_fator_financeiro_tabela = parseFloat(valorFatorFinanceiroTabela.toFixed(2));

              let valorFatorFinanceiroInicial: number = subItem.valor_fator_financeiro_tabela - valorCalculoInicial;

              subItem.valor_fator_financeiro_inicial = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroInicial);

              subItem.valor_fator_financeiro_tabela_fracionado = !subItem.fracionado ? 0 : (subItem.valor_fator_financeiro_tabela > 0 && subItem.valorunidade > 0) ? (subItem.valor_fator_financeiro_tabela / subItem.valorunidade) : 0;

              /* Processado */

              if (qtde > 0) {
                let valorFatorFinanceiro: number = subItem.valor_fator_financeiro_inicial * qtde;

                subItem.valor_fator_financeiro = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiro);
              } else {
                subItem.valor_fator_financeiro = 0;
              }

              if (subItem.valor_fator_financeiro_inicial > 0) {
                subItem.valor_fator_financeiro_unitario = this.formatacaoService.quatroCasasDecimais(subItem.valor_fator_financeiro_inicial);
              } else {
                subItem.valor_fator_financeiro_unitario = null;
              }

              let valorFatorFinanceiroProduto: number = valorCalculoInicial + subItem.valor_fator_financeiro_inicial;

              subItem.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroProduto);

              let valorFatorFinanceiroTotal: number = valorFatorFinanceiroProduto * qtde;

              subItem.valor_fator_financeiro_total = parseFloat(valorFatorFinanceiroTotal.toFixed(2));

              if (subItem.valor_fator_financeiro != 0) {
                this.exibirFatorFinanceiro = true;
              }
            } else {
              /* S/ Fator */

              /* Inicial */

              subItem.porcentagem_fator_inicial = 0;
              subItem.valor_fator_financeiro_tabela = 0;
              subItem.valor_fator_financeiro_inicial = 0;
              subItem.valor_fator_financeiro_tabela_fracionado = 0;

              /* Processado */

              subItem.valor_fator_financeiro = 0;
              subItem.valor_fator_financeiro_unitario = null;
              subItem.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valorCalculoInicial);

              let valorFatorFinanceiroTotal: number = valorCalculoInicial * qtde;

              subItem.valor_fator_financeiro_total = parseFloat(valorFatorFinanceiroTotal.toFixed(2));
            }
          });

          /* Produtos (Produtos Vinculados) */

          let qtde: number = item.qtde != null && item.qtde > 0 ? item.qtde : 0;
          let valorCalculoInicialParcial: number = item.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao && produtosVinculados.aplica_fator_financeiro).reduce((sum, current) => (sum + current.valor_total), 0);
          let valorCalculoInicialTotal: number = item.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.valor_total), 0);

          /* Inicial */

          if (item.grupo_estoque == this.combo && qtde == 0) {
            item.porcentagem_fator_inicial = 0;
            item.valor_fator_financeiro_tabela = 0;
            item.valor_fator_financeiro_inicial = 0;
            item.valor_fator_financeiro_tabela_fracionado = 0;
          } else {
            item.porcentagem_fator_inicial = this.prazoPagamentoSelecionado.fator_financeiro;

            let valorFatorFinanceiroTabela: number = (valorCalculoInicialTotal + ((valorCalculoInicialParcial * this.prazoPagamentoSelecionado.fator_financeiro) / 100));

            item.valor_fator_financeiro_tabela = parseFloat(valorFatorFinanceiroTabela.toFixed(2));

            let valorFatorFinanceiroInicial: number = item.valor_fator_financeiro_tabela - valorCalculoInicialTotal;

            item.valor_fator_financeiro_inicial = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroInicial);

            item.valor_fator_financeiro_tabela_fracionado = !item.fracionado ? 0 : (item.valor_fator_financeiro_tabela > 0 && item.valorunidade > 0) ? (item.valor_fator_financeiro_tabela / item.valorunidade) : 0;
          }

          /* Processado */

          let valorFatorFinanceiroUnitario: number = item.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.valor_fator_financeiro), 0);
          let valorFatorFinanceiroTotal: number = item.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.valor_fator_financeiro_total), 0);
          let valorFatorFinanceiro: number = valorFatorFinanceiroUnitario * qtde;

          item.valor_fator_financeiro = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiro);

          if (valorFatorFinanceiroUnitario > 0) {
            item.valor_fator_financeiro_unitario = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroUnitario);
          } else {
            item.valor_fator_financeiro_unitario = null;
          }

          item.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroTotal);

          let valorFatorFinanceiroTotalCampanha: number = 0;

          if (item.grupo_estoque == this.combo) {
            valorFatorFinanceiroTotalCampanha = item.valor_total;
          } else {
            qtde = qtde > 0 ? qtde : 1;

            valorFatorFinanceiroTotalCampanha = valorFatorFinanceiroTotal * qtde;
          }

          item.valor_fator_financeiro_total = parseFloat(valorFatorFinanceiroTotalCampanha.toFixed(2));
        } else {
          /* Produtos */

          let qtde: number = item.qtde != null && item.qtde > 0 ? item.qtde : 0;
          let valor: number = item.valor != null ? item.valor : 0;

          if (item.aplica_fator_financeiro && item.qtde_lote == 0) {
            /* C/ Fator */

            /* Inicial */

            let valorCalculoInicial: number = item.valor_tabela != null && item.valor_tabela > 0 ? item.valor_tabela : item.price;

            item.porcentagem_fator_inicial = this.prazoPagamentoSelecionado.fator_financeiro;

            let valorFatorFinanceiroTabela: number = (valorCalculoInicial + ((valorCalculoInicial * this.prazoPagamentoSelecionado.fator_financeiro) / 100));

            item.valor_fator_financeiro_tabela = parseFloat(valorFatorFinanceiroTabela.toFixed(2));

            let valorFatorFinanceiroInicial: number = item.valor_fator_financeiro_tabela - valorCalculoInicial;

            item.valor_fator_financeiro_inicial = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroInicial);

            item.valor_fator_financeiro_tabela_fracionado = !item.fracionado ? 0 : (item.valor_fator_financeiro_tabela > 0 && item.valorunidade > 0) ? (item.valor_fator_financeiro_tabela / item.valorunidade) : 0;

            /* Processado */

            let valorAtualizado: number = this.calcularValorProdutoOriginal(item);

            if (valorAtualizado != null) {
              let valorTotal: number = valorAtualizado * qtde;

              item.valor_total = parseFloat(valorTotal.toFixed(2));

              valor = valorAtualizado;
            }

            item.valor = valorAtualizado; /* Atualiza valor */

            let valorFatorFinanceiro: number = (((valor * qtde) * item.porcentagem_fator_inicial) / 100);

            item.valor_fator_financeiro = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiro);

            let valorFatorFinanceiroUnitario: number = 0;

            if (qtde != null && qtde > 0) {
               valorFatorFinanceiroUnitario = valorFatorFinanceiro / qtde;
            }

            if (valorFatorFinanceiroUnitario > 0) {
              item.valor_fator_financeiro_unitario = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroUnitario);
            } else {
              item.valor_fator_financeiro_unitario = null;
            }

            item.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valor);

            let valorFatorFinanceiroTotal: number = valor * qtde;

            item.valor_fator_financeiro_total = parseFloat(valorFatorFinanceiroTotal.toFixed(2));

            if (item.valor_fator_financeiro != 0) {
              this.exibirFatorFinanceiro = true;
            }
          } else {
            /* S/ Fator */

            /* Inicial */

            item.porcentagem_fator_inicial = 0;
            item.valor_fator_financeiro_tabela = 0;
            item.valor_fator_financeiro_inicial = 0;
            item.valor_fator_financeiro_tabela_fracionado = 0;

            /* Processado */

            let valorAtualizado: number = this.calcularValorProdutoOriginal(item);

            if (valorAtualizado != null) {
              let valorTotal: number = valorAtualizado * qtde;

              item.valor_total = parseFloat(valorTotal.toFixed(2));

              valor = valorAtualizado;
            }

            item.valor_fator_financeiro = 0;
            item.valor_fator_financeiro_unitario = null;
            item.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valor);

            let valorFatorFinanceiroTotal: number = valor * qtde;

            item.valor_fator_financeiro_total = parseFloat(valorFatorFinanceiroTotal.toFixed(2));
          }
        }

        /* Produtos Original p/ Produtos */

        if (item.categoria == this.campanhas) {
          /* Campanha */

          let produto: ProdutoPromocional = this.produtos.find(produtoOriginal => produtoOriginal.id_campanha == item.id_campanha);

          if (produto != null) {
            if (produto.produtos_vinculados != null) {
              /* Produtos Vinculados */

              produto.produtos_vinculados.forEach((subItem) => {
                let produtoVinculado: ProdutoPromocional = item.produtos_vinculados.find(produtoVinculado => produtoVinculado.id == subItem.id);

                if (produtoVinculado != null) {
                  /* Inicial */

                  subItem.porcentagem_fator_inicial = produtoVinculado.porcentagem_fator_inicial;
                  subItem.valor_fator_financeiro_tabela = produtoVinculado.valor_fator_financeiro_tabela;
                  subItem.valor_fator_financeiro_inicial = produtoVinculado.valor_fator_financeiro_inicial;
                  subItem.valor_fator_financeiro_tabela_fracionado = produtoVinculado.valor_fator_financeiro_tabela_fracionado;

                  /* Processado */

                  subItem.valor_fator_financeiro = produtoVinculado.valor_fator_financeiro;
                  subItem.valor_fator_financeiro_unitario = produtoVinculado.valor_fator_financeiro_unitario;
                  subItem.valor_fator_financeiro_produto = produtoVinculado.valor_fator_financeiro_produto;
                  subItem.valor_fator_financeiro_total = produtoVinculado.valor_fator_financeiro_total;
                }
              });

              /* Produtos (Produtos Vinculados) */

              /* Inicial */

              produto.porcentagem_fator_inicial = item.porcentagem_fator_inicial;
              produto.valor_fator_financeiro_tabela = item.valor_fator_financeiro_tabela;
              produto.valor_fator_financeiro_inicial = item.valor_fator_financeiro_inicial;
              produto.valor_fator_financeiro_tabela_fracionado = item.valor_fator_financeiro_tabela_fracionado;

              /* Processado */

              produto.valor_fator_financeiro = item.valor_fator_financeiro;
              produto.valor_fator_financeiro_unitario = item.valor_fator_financeiro_unitario;
              produto.valor_fator_financeiro_produto = item.valor_fator_financeiro_produto;
              produto.valor_fator_financeiro_total = item.valor_fator_financeiro_total;
            }
          }
        } else {
          /* Produtos */

          let produto: ProdutoPromocional = this.produtos.find(produtoOriginal => produtoOriginal.id == item.id);

          if (produto != null) {
            /* Inicial */

            produto.porcentagem_fator_inicial = item.porcentagem_fator_inicial;
            produto.valor_fator_financeiro_tabela = item.valor_fator_financeiro_tabela;
            produto.valor_fator_financeiro_inicial = item.valor_fator_financeiro_inicial;
            produto.valor_fator_financeiro_tabela_fracionado = item.valor_fator_financeiro_tabela_fracionado;

            /* Processado */

            produto.valor_fator_financeiro = item.valor_fator_financeiro;
            produto.valor_fator_financeiro_unitario = item.valor_fator_financeiro_unitario;

            produto.valor = item.valor; /* Atualiza valor */
            produto.valor_total = item.valor_total; /* Atualiza valor */

            produto.valor_fator_financeiro_produto = item.valor_fator_financeiro_produto;
            produto.valor_fator_financeiro_total = item.valor_fator_financeiro_total;
          }
        }
      });

      if (!this.exibirFatorFinanceiro && this.configuracao != null && this.configuracao.utiliza_fator_financeiro) {
        this.exibirFatorFinanceiro = true;
      }
    } else {
      /* S/ Fator */

      /* Produtos Original */

      this.produtosOriginal.forEach((item) => {
        if (item.produtos_vinculados != null) {
          /* Produtos Vinculados */

          item.produtos_vinculados.forEach((subItem) => {
            let qtde: number = subItem.qtde != null && subItem.qtde > 0 ? subItem.qtde : 0;
            let valorCalculoInicial: number = subItem.price_promotional != null && subItem.price_promotional > 0 ? subItem.price_promotional : subItem.price;

            /* Inicial */

            subItem.porcentagem_fator_inicial = 0;
            subItem.valor_fator_financeiro_tabela = 0;
            subItem.valor_fator_financeiro_inicial = 0;
            subItem.valor_fator_financeiro_tabela_fracionado = 0;

            /* Processado */

            subItem.valor_fator_financeiro = 0;
            subItem.valor_fator_financeiro_unitario = null;
            subItem.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valorCalculoInicial);

            let valorFatorFinanceiroProduto: number = valorCalculoInicial * qtde;

            subItem.valor_fator_financeiro_total = parseFloat(valorFatorFinanceiroProduto.toFixed(2));
          });

          /* Produtos (Produtos Vinculados) */

          /* Inicial */

          item.porcentagem_fator_inicial = 0;
          item.valor_fator_financeiro_tabela = 0;
          item.valor_fator_financeiro_inicial = 0;
          item.valor_fator_financeiro_tabela_fracionado = 0;

          /* Processado */

          item.valor_fator_financeiro = 0;
          item.valor_fator_financeiro_unitario = null;

          let valorFatorFinanceiroProduto: number = item.price_promotional != null && item.price_promotional > 0 ? item.price_promotional : item.price;

          if (item.grupo_estoque == this.combo) {
            valorFatorFinanceiroProduto = item.valor != null ? item.valor : 0;

            item.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroProduto);
          } else {
            item.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroProduto);
          }

          let valorFatorFinanceiroTotal: number = 0;

          if (item.grupo_estoque == this.combo) {
            valorFatorFinanceiroTotal = item.valor_total;
          } else {
            let qtde: number = item.qtde != null && item.qtde > 0 ? item.qtde : 1;

            valorFatorFinanceiroTotal = valorFatorFinanceiroProduto * qtde;
          }

          item.valor_fator_financeiro_total = parseFloat(valorFatorFinanceiroTotal.toFixed(2));
        } else {
          /* Produtos */

          /* Inicial */

          item.porcentagem_fator_inicial = 0;
          item.valor_fator_financeiro_tabela = 0;
          item.valor_fator_financeiro_inicial = 0;
          item.valor_fator_financeiro_tabela_fracionado = 0;

          /* Processado */

          item.valor_fator_financeiro = 0;
          item.valor_fator_financeiro_unitario = null;

          let valorFatorFinanceiroProduto: number = item.valor != null ? item.valor : 0;

          item.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroProduto);
          item.valor_fator_financeiro_total = parseFloat(item.valor_total.toFixed(2));
        }

        /* Produtos Original p/ Produtos */

        if (item.categoria == this.campanhas) {
          /* Campanha */

          let produto: ProdutoPromocional = this.produtos.find(produtoOriginal => produtoOriginal.id_campanha == item.id_campanha);

          if (produto != null) {
            if (produto.produtos_vinculados != null) {
              /* Produtos Vinculados */

              produto.produtos_vinculados.forEach((subItem) => {
                let produtoVinculado: ProdutoPromocional = item.produtos_vinculados.find(produtoVinculado => produtoVinculado.id == subItem.id);

                if (produtoVinculado != null) {
                  /* Inicial */

                  subItem.porcentagem_fator_inicial = produtoVinculado.porcentagem_fator_inicial;
                  subItem.valor_fator_financeiro_tabela = produtoVinculado.valor_fator_financeiro_tabela;
                  subItem.valor_fator_financeiro_inicial = produtoVinculado.valor_fator_financeiro_inicial;
                  subItem.valor_fator_financeiro_tabela_fracionado = produtoVinculado.valor_fator_financeiro_tabela_fracionado;

                  /* Processado */

                  subItem.valor_fator_financeiro = produtoVinculado.valor_fator_financeiro;
                  subItem.valor_fator_financeiro_unitario = produtoVinculado.valor_fator_financeiro_unitario;
                  subItem.valor_fator_financeiro_produto = produtoVinculado.valor_fator_financeiro_produto;
                  subItem.valor_fator_financeiro_total = produtoVinculado.valor_fator_financeiro_total;
                }
              });

              /* Produto (Produtos Vinculados) */

              /* Inicial */

              produto.porcentagem_fator_inicial = item.porcentagem_fator_inicial;
              produto.valor_fator_financeiro_tabela = item.valor_fator_financeiro_tabela;
              produto.valor_fator_financeiro_inicial = item.valor_fator_financeiro_inicial;
              produto.valor_fator_financeiro_tabela_fracionado = item.valor_fator_financeiro_tabela_fracionado;

              /* Processado */

              produto.valor_fator_financeiro = item.valor_fator_financeiro;
              produto.valor_fator_financeiro_unitario = item.valor_fator_financeiro_unitario;
              produto.valor_fator_financeiro_produto = item.valor_fator_financeiro_produto;
              produto.valor_fator_financeiro_total = item.valor_fator_financeiro_total;
            }
          }
        } else {
          /* Produto */

          let produto: ProdutoPromocional = this.produtos.find(produtoOriginal => produtoOriginal.id == item.id);

          if (produto != null) {
            /* Inicial */

            produto.porcentagem_fator_inicial = item.porcentagem_fator_inicial;
            produto.valor_fator_financeiro_tabela = item.valor_fator_financeiro_tabela;
            produto.valor_fator_financeiro_inicial = item.valor_fator_financeiro_inicial;
            produto.valor_fator_financeiro_tabela_fracionado = item.valor_fator_financeiro_tabela_fracionado;

            /* Processado */

            produto.valor_fator_financeiro = item.valor_fator_financeiro;
            produto.valor_fator_financeiro_unitario = item.valor_fator_financeiro_unitario;
            produto.valor_fator_financeiro_produto = item.valor_fator_financeiro_produto;
            produto.valor_fator_financeiro_total = item.valor_fator_financeiro_total;
          }
        }
      });
    }

    /* Produtos Original p/ Produtos Adicionados */

    this.produtosOriginal.forEach((item) => {
      if (item.categoria == this.campanhas) {
        /* Campanha */

        let produto: ProdutoPromocional = this.produtosAdicionados.find(produtoOriginal => produtoOriginal.id_campanha == item.id_campanha);

        if (produto != null) {
          if (produto.produtos_vinculados != null) {
            /* Produtos Vinculados */

            produto.produtos_vinculados.forEach((subItem) => {
              let produtoVinculado: ProdutoPromocional = item.produtos_vinculados.find(produtoVinculado => produtoVinculado.id == subItem.id);

              if (produtoVinculado != null) {
                /* Inicial */

                subItem.porcentagem_fator_inicial = produtoVinculado.porcentagem_fator_inicial;
                subItem.valor_fator_financeiro_tabela = produtoVinculado.valor_fator_financeiro_tabela;
                subItem.valor_fator_financeiro_inicial = produtoVinculado.valor_fator_financeiro_inicial;
                subItem.valor_fator_financeiro_tabela_fracionado = produtoVinculado.valor_fator_financeiro_tabela_fracionado;

                /* Processado */

                subItem.valor_fator_financeiro = produtoVinculado.valor_fator_financeiro;
                subItem.valor_fator_financeiro_unitario = produtoVinculado.valor_fator_financeiro_unitario;
                subItem.valor_fator_financeiro_produto = produtoVinculado.valor_fator_financeiro_produto;
                subItem.valor_fator_financeiro_total = produtoVinculado.valor_fator_financeiro_total;
              }
            });

            /* Produto (Produtos Vinculados) */

            /* Inicial */

            produto.porcentagem_fator_inicial = item.porcentagem_fator_inicial;
            produto.valor_fator_financeiro_tabela = item.valor_fator_financeiro_tabela;
            produto.valor_fator_financeiro_inicial = item.valor_fator_financeiro_inicial;
            produto.valor_fator_financeiro_tabela_fracionado = item.valor_fator_financeiro_tabela_fracionado;

            /* Processado */

            produto.valor_fator_financeiro = item.valor_fator_financeiro;
            produto.valor_fator_financeiro_unitario = item.valor_fator_financeiro_unitario;
            produto.valor_fator_financeiro_produto = item.valor_fator_financeiro_produto;
            produto.valor_fator_financeiro_total = item.valor_fator_financeiro_total;
          }
        }
      } else {
        /* Produto */

        let produto: ProdutoPromocional = this.produtosAdicionados.find(produtoOriginal => produtoOriginal.id == item.id);

        if (produto != null) {
          /* Inicial */

          produto.porcentagem_fator_inicial = item.porcentagem_fator_inicial;
          produto.valor_fator_financeiro_tabela = item.valor_fator_financeiro_tabela;
          produto.valor_fator_financeiro_inicial = item.valor_fator_financeiro_inicial;
          produto.valor_fator_financeiro_tabela_fracionado = item.valor_fator_financeiro_tabela_fracionado;

          /* Processado */

          produto.valor_fator_financeiro = item.valor_fator_financeiro;
          produto.valor_fator_financeiro_unitario = item.valor_fator_financeiro_unitario;

          produto.valor = item.valor; /* Atualiza valor */
          produto.valor_total = item.valor_total; /* Atualiza valor */

          produto.valor_fator_financeiro_produto = item.valor_fator_financeiro_produto;
          produto.valor_fator_financeiro_total = item.valor_fator_financeiro_total;
        }
      }
    });

    this.calcularImpostoProdutosOriginal();
  }

  /* Fator / Campanha */

  calcularFatorFinanceiroCampanha() {
    if (this.produtoCampanhaSelecionado != null) {
      /* Processado */

      let qtde: number = this.produtoCampanhaSelecionado.qtde != null && this.produtoCampanhaSelecionado.qtde > 0 ? this.produtoCampanhaSelecionado.qtde : 0;
      let valorFatorFinanceiroUnitario: number = this.produtoCampanhaSelecionado.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.valor_fator_financeiro), 0);
      let valorFatorFinanceiroTotal: number = this.produtoCampanhaSelecionado.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.valor_fator_financeiro_total), 0);
      let valorFatorFinanceiro: number = valorFatorFinanceiroUnitario * qtde;

      this.produtoCampanhaSelecionado.valor_fator_financeiro = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiro);

      if (valorFatorFinanceiroUnitario > 0) {
        this.produtoCampanhaSelecionado.valor_fator_financeiro_unitario = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroUnitario);
      } else {
        this.produtoCampanhaSelecionado.valor_fator_financeiro_unitario = null;
      }

      this.produtoCampanhaSelecionado.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroTotal);

      let valorFatorFinanceiroTotalCampanha: number = 0;

      if (this.produtoCampanhaSelecionado.grupo_estoque == this.combo) {
        valorFatorFinanceiroTotalCampanha = this.produtoCampanhaSelecionado.valor_total;
      } else {
        qtde = qtde > 0 ? qtde : 1;

        valorFatorFinanceiroTotalCampanha = valorFatorFinanceiroTotal * qtde;
      }

      this.produtoCampanhaSelecionado.valor_fator_financeiro_total = parseFloat(valorFatorFinanceiroTotalCampanha.toFixed(2));
    }

    this.calcularImpostoCampanha();
  }

  /* Fator / Campanha / Produto */

  calcularFatorFinanceiroCampanhaProduto(id_produto: number) {
    if (this.produtoCampanhaSelecionado != null) {

      let produtoVinculado = this.produtoCampanhaSelecionado.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == id_produto);

      if (produtoVinculado != null) {
        let qtde: number = produtoVinculado.qtde != null && produtoVinculado.qtde > 0 ? produtoVinculado.qtde : 0;
        let valorCalculoInicial: number = produtoVinculado.price_promotional != null && produtoVinculado.price_promotional > 0 ? produtoVinculado.price_promotional : produtoVinculado.price;

        /* Processado */

        if (qtde > 0) {
          let valorFatorFinanceiro: number = produtoVinculado.valor_fator_financeiro_inicial * qtde;

          produtoVinculado.valor_fator_financeiro = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiro);
        } else {
          produtoVinculado.valor_fator_financeiro = 0;
        }

        if (produtoVinculado.valor_fator_financeiro_inicial > 0) {
          produtoVinculado.valor_fator_financeiro_unitario = this.formatacaoService.quatroCasasDecimais(produtoVinculado.valor_fator_financeiro_inicial);
        } else {
          produtoVinculado.valor_fator_financeiro_unitario = null;
        }

        let valorFatorFinanceiroProduto: number = valorCalculoInicial + produtoVinculado.valor_fator_financeiro_inicial;

        produtoVinculado.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroProduto);

        let valorFatorFinanceiroTotal: number = valorFatorFinanceiroProduto * qtde;

        produtoVinculado.valor_fator_financeiro_total = parseFloat(valorFatorFinanceiroTotal.toFixed(2));

        if (produtoVinculado.valor_fator_financeiro != 0) {
          this.exibirFatorFinanceiro = true;
        }
      }
    }

    this.calcularImpostoCampanhaProduto(id_produto);
  }

  /* Fator / Produto */

  calcularFatorFinanceiroProduto(id_produto: number, adicionado: boolean) {
    let produto: ProdutoPromocional = null;

    if (adicionado) {
      produto = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id == id_produto);
    } else {
      produto = this.produtos.find(produto => produto.id == id_produto);
    }

    if (produto != null) {
      if (this.configuracao != null && !this.configuracao.prazo_pagamento_antes_itenspedido && this.configuracao.utiliza_fator_financeiro) {
        let qtde: number = produto.qtde != null && produto.qtde > 0 ? produto.qtde : 0;
        let valor: number = produto.valor != null ? produto.valor : 0;

        if (produto.aplica_fator_financeiro && produto.qtde_lote == 0) {
          /* C/ Fator */

          /* Processado */

          let valorFatorFinanceiro: number = (((valor * qtde) * produto.porcentagem_fator_inicial) / 100);

          produto.valor_fator_financeiro = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiro);

          let valorFatorFinanceiroUnitario: number = 0;

          if (qtde != null && qtde > 0) {
            valorFatorFinanceiroUnitario = valorFatorFinanceiro / qtde;
          }

          if (valorFatorFinanceiroUnitario > 0) {
            produto.valor_fator_financeiro_unitario = this.formatacaoService.quatroCasasDecimais(valorFatorFinanceiroUnitario);
          } else {
            produto.valor_fator_financeiro_unitario = null;
          }

          produto.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valor);
          produto.valor_fator_financeiro_total = parseFloat(produto.valor_total.toFixed(2));
        } else {
          /* S/ Fator */

          /* Processado */

          produto.valor_fator_financeiro = 0;
          produto.valor_fator_financeiro_unitario = null;
          produto.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(valor);
          produto.valor_fator_financeiro_total = parseFloat(produto.valor_total.toFixed(2));
        }
      } else {
        /* S/ Fator */

        /* Processado */

        produto.valor_fator_financeiro = 0;
        produto.valor_fator_financeiro_unitario = null;
        produto.valor_fator_financeiro_produto = this.formatacaoService.quatroCasasDecimais(produto.valor);
        produto.valor_fator_financeiro_total = parseFloat(produto.valor_total.toFixed(2));
      }

      this.calcularImpostoProduto(produto.id, adicionado);
    }
  }

  /* Fracionado / Produto */

  calcularFracionadoProduto(produto: ProdutoPromocional): ProdutoPromocional {
    if (produto.valor != null && produto.valor > 0 && produto.valorunidade != null && produto.valorunidade > 0) {
      let valorUnitarioFracionado: number = produto.valor / produto.valorunidade;

      let valorImpostoProduto: number = produto.valor_imposto_produto != null ? produto.valor_imposto_produto : 0;

      let valorUnitarioImpostoFracionado: number = valorImpostoProduto / produto.valorunidade;

      produto.valor_unitario_fracionado = this.formatacaoService.quatroCasasDecimais(valorUnitarioFracionado);

      if (produto.qtde != null && produto.qtde > 0) {
        produto.valor_unitario_imposto_fracionado = this.formatacaoService.quatroCasasDecimais(valorUnitarioImpostoFracionado);
      } else {
        produto.valor_unitario_imposto_fracionado = null;
      }
    } else {
      produto.valor_unitario_fracionado = null;
      produto.valor_unitario_imposto_fracionado = null;
    }

    return produto;
  }

  /* Imposto */

  calcularImposto(produto: ProdutoPromocional): number {
    if (produto != null) {
      if (produto.qtde != null && produto.qtde > 0) {
        let valorProduto: number = 0;

        if (produto.id_campanha != null) {
          valorProduto = produto.valor_fator_financeiro_produto;
        } else {
          valorProduto = produto.valor;
        }

        /* ST */

        let valorST: number = 0;

        if (produto.pauta_icmssub > 0 && produto.icms_destino > 0 && produto.icms_interno) {
          valorST = ((produto.qtde * produto.pauta_icmssub) * (produto.icms_destino / 100) - (produto.qtde * valorProduto) * (produto.icms_interno / 100));
        }

        /* IPI */

        let valorIPI: number = 0;

        if (produto.ipi_tipoindice == 1 && produto.ipi_indice > 0) {
          valorIPI = (produto.qtde * valorProduto * (produto.ipi_indice / 100));
        }

        /* IVA */

        let valorIVA: number = 0;

        if (produto.iva > 0 && produto.icms_destino && produto.icms_interno > 0) {
          let produtoIPI: number = ((valorProduto * produto.qtde) + valorIPI);

          valorIVA = ((produtoIPI * (1 + (produto.iva / 100))) * (produto.icms_destino / 100)) - (valorProduto * produto.qtde * (produto.icms_interno / 100));
        }

        return valorST + valorIPI + valorIVA;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  /* Imposto / Campanha */

  calcularImpostoCampanha() {
    if (this.produtoCampanhaSelecionado != null) {
      let qtde: number = this.produtoCampanhaSelecionado.qtde != null && this.produtoCampanhaSelecionado.qtde > 0 ? this.produtoCampanhaSelecionado.qtde : 0;
      let valorCampanhaImpostoUnitario: number = this.produtoCampanhaSelecionado.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.valor_imposto), 0);
      let valorCampanhaImpostoTotal: number = this.produtoCampanhaSelecionado.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.valor_imposto_total), 0);

      /* Imposto */

      this.produtoCampanhaSelecionado.valor_imposto = this.formatacaoService.quatroCasasDecimais(valorCampanhaImpostoUnitario * qtde);
      this.produtoCampanhaSelecionado.valor_imposto_unitario = this.formatacaoService.quatroCasasDecimais(valorCampanhaImpostoUnitario);

      if (valorCampanhaImpostoTotal > 0) {
        this.produtoCampanhaSelecionado.valor_imposto_produto = this.formatacaoService.quatroCasasDecimais(valorCampanhaImpostoTotal);
      } else {
        this.produtoCampanhaSelecionado.valor_imposto_produto = null;
      }

      let valorImpostoTotal: number = valorCampanhaImpostoTotal * qtde;

      this.produtoCampanhaSelecionado.valor_imposto_total = parseFloat(valorImpostoTotal.toFixed(2));

      this.produtoCampanhaSelecionado.valor_imposto_total_final = this.produtoCampanhaSelecionado.valor_imposto_total;

      if (qtde > 0) {
        this.produtoCampanhaSelecionado.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorCampanhaImpostoTotal);
      } else {
        this.produtoCampanhaSelecionado.valor_unitario_final = 0;
      }
    }
  }

  /* Imposto / Campanha / Produto */

  calcularImpostoCampanhaProduto(id_produto?: number) {
    if (this.produtoCampanhaSelecionado != null) {
      if (this.configuracao != null && this.configuracao.flag_exibir_imposto_produto) {
        /* C/ Imposto */

        if (this.produtoCampanhaSelecionado.produtos_vinculados != null) {
          /* Produtos Vinculados */

          this.produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
            if (id_produto == null || id_produto == item.id) {
              let qtde: number = item.qtde != null && item.qtde > 0 ? item.qtde : 0;
              let valor: number = item.valor != null ? item.valor : 0;

              let valorFatorFinanceiroUnitario: number = item.valor_fator_financeiro_unitario != null ? item.valor_fator_financeiro_unitario : 0;
              let valorCalculo: number = valor + valorFatorFinanceiroUnitario;

              /* Imposto */

              let valorImposto: number = this.calcularImposto(item);
              let valorImpostoUnitario: number = valorImposto / (qtde < 1 ? 1 : qtde);
              let valorImpostoProduto: number = valorCalculo + valorImpostoUnitario;
              let valorImpostoTotal: number = ((valorCalculo * qtde) + valorImposto);

              item.valor_imposto = this.formatacaoService.quatroCasasDecimais(valorImposto);
              item.valor_imposto_unitario = this.formatacaoService.quatroCasasDecimais(valorImpostoUnitario);

              if (valorImpostoProduto > 0) {
                item.valor_imposto_produto = this.formatacaoService.quatroCasasDecimais(valorImpostoProduto);
              } else {
                item.valor_imposto_produto = null;
              }

              item.valor_imposto_total = parseFloat(valorImpostoTotal.toFixed(2));

              item.valor_imposto_total_final = item.valor_imposto_total;

              if (item.bonificacao) {
                /* Bonificação */

                item.valor_unitario_final = 0;
              } else {
                if (qtde > 0) {
                  let valorFatorFinanceiroUnitario: number = item.valor_fator_financeiro_unitario != null ? item.valor_fator_financeiro_unitario : 0;
                  let valorUnitarioFinal: number = item.valor + valorFatorFinanceiroUnitario + item.valor_imposto_unitario;

                  item.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorUnitarioFinal);
                } else {
                  item.valor_unitario_final = 0;
                }
              }
            }
          });
        }
      } else {
        /* S/ Imposto */

        if (this.produtoCampanhaSelecionado.produtos_vinculados != null) {
          /* Produtos Vinculados */

          this.produtoCampanhaSelecionado.produtos_vinculados.forEach((item) => {
            if (id_produto == null || id_produto == item.id) {
              /* Imposto */

              item.valor_imposto = 0;
              item.valor_imposto_unitario = 0;
              item.valor_imposto_produto = null;
              item.valor_imposto_total = parseFloat(item.valor_fator_financeiro_total.toFixed(2));

              item.valor_imposto_total_final = item.valor_imposto_total;

              let valorFatorFinanceiroUnitario: number = item.valor_fator_financeiro_unitario != null ? item.valor_fator_financeiro_unitario : 0;
              let valorUnitarioFinal: number = item.valor + valorFatorFinanceiroUnitario + item.valor_imposto_unitario;

              item.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorUnitarioFinal);
            }
          });
        }
      }

      this.calcularFatorFinanceiroCampanha();
    }
  }

  /* Imposto / Produto */

  calcularImpostoProduto(id_produto: number, adicionado: boolean) {
    let produto: ProdutoPromocional = null;

    if (adicionado) {
      produto = this.produtosAdicionados.find(produtoAdicionado => produtoAdicionado.id == id_produto);
    } else {
      produto = this.produtos.find(produto => produto.id == id_produto);
    }

    if (produto != null) {
      if (!produto.erro_qtde && !produto.erro_valor) {
        if ((produto.qtde != null && produto.qtde != 0) && (produto.valor != null && produto.valor != 0)) {
          let qtde: number = produto.qtde;

          if (this.configuracao != null && this.configuracao.flag_exibir_imposto_produto) {
            if (produto.multiplo == null || (qtde % produto.multiplo == 0)) {
              /* C/ Imposto */

              let valorImposto: number = this.calcularImposto(produto);
              let valorImpostoUnitario: number = this.formatacaoService.quatroCasasDecimais(valorImposto / qtde);
              let valorImpostoProduto: number = produto.valor + valorImpostoUnitario;
              let valorImpostoTotal: number = ((produto.valor * qtde) + valorImposto);

              produto.valor_imposto = this.formatacaoService.quatroCasasDecimais(valorImposto);
              produto.valor_imposto_unitario = this.formatacaoService.quatroCasasDecimais(valorImpostoUnitario);
              produto.valor_imposto_produto = this.formatacaoService.quatroCasasDecimais(valorImpostoProduto);
              produto.valor_imposto_total = parseFloat(valorImpostoTotal.toFixed(2));
            } else {
              /* Imposto */

              produto.valor_imposto = 0;
              produto.valor_imposto_unitario = 0;
              produto.valor_imposto_produto = null;
              produto.valor_imposto_total = 0;

              /* Total Fracionado */

              produto.valor_unitario_fracionado = null;
              produto.valor_unitario_imposto_fracionado = null;
            }
          } else {
            /* S/ Imposto */

            produto.valor_imposto = 0;
            produto.valor_imposto_unitario = 0;
            produto.valor_imposto_produto = null;

            let valorImpostoTotal: number = produto.valor * qtde;

            produto.valor_imposto_total = parseFloat(valorImpostoTotal.toFixed(2));
          }
        } else {
          /* Qtde (Bonificada) */

          /* S/ Imposto */

          /* Imposto */

          produto.valor_imposto = 0;
          produto.valor_imposto_unitario = 0;
          produto.valor_imposto_produto = null;
          produto.valor_imposto_total = 0;

          produto.valor_imposto_total_final = 0;

          let valorUnitarioFinal: number = produto.valor != null ? produto.valor : 0;

          produto.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorUnitarioFinal);
        }

        /* Total Fracionado */

        if (produto.fracionado && (produto.qtde != null && produto.qtde != 0) && (produto.valor != null && produto.valor != 0)) {
          produto = this.calcularFracionadoProduto(produto);
        }
      } else {
        /* Imposto */

        produto.valor_imposto = 0;
        produto.valor_imposto_unitario = 0;
        produto.valor_imposto_produto = null;
        produto.valor_imposto_total = 0;

        /* Total Fracionado */

        produto.valor_unitario_fracionado = null;
        produto.valor_unitario_imposto_fracionado = null;
      }

      produto.valor_imposto_total_final = produto.valor_imposto_total;

      if (produto.qtde > 0) {
        let valorUnitarioFinal: number = produto.valor + produto.valor_imposto_unitario;

        produto.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorUnitarioFinal);
      } else {
        produto.valor_unitario_final = 0;
      }
    }
  }

  /* Imposto / Produtos (Original) */

  calcularImpostoProdutosOriginal() {
    this.produtosOriginal.forEach((item) => {
      if (item.produtos_vinculados != null) {
        /* Produtos Vinculados */

        item.produtos_vinculados.forEach((subItem) => {
          if (this.configuracao != null && this.configuracao.flag_exibir_imposto_produto) {
            /* C/ Imposto */

            let qtde: number = subItem.qtde != null && subItem.qtde > 0 ? subItem.qtde : 0;
            let valor: number = subItem.valor != null ? subItem.valor : 0;

            let valorFatorFinanceiroUnitario: number = subItem.valor_fator_financeiro_unitario != null ? subItem.valor_fator_financeiro_unitario : 0;
            let valorCalculo: number = valor + valorFatorFinanceiroUnitario;

            /* Imposto */

            let valorImposto: number = this.calcularImposto(subItem);
            let valorImpostoUnitario: number = valorImposto / (qtde < 1 ? 1 : qtde);
            let valorImpostoProduto: number = valorCalculo + valorImpostoUnitario;
            let valorImpostoTotal: number = ((valorCalculo * qtde) + valorImposto);

            subItem.valor_imposto = this.formatacaoService.quatroCasasDecimais(valorImposto);
            subItem.valor_imposto_unitario = this.formatacaoService.quatroCasasDecimais(valorImpostoUnitario);

            if (valorImpostoProduto > 0) {
              subItem.valor_imposto_produto = this.formatacaoService.quatroCasasDecimais(valorImpostoProduto);
            } else {
              subItem.valor_imposto_produto = null;
            }

            subItem.valor_imposto_total = parseFloat(valorImpostoTotal.toFixed(2));

            subItem.valor_imposto_total_final = subItem.valor_imposto_total;

            if (subItem.bonificacao) {
              /* Bonificação */

              subItem.valor_unitario_final = 0;
            } else {
              if (qtde > 0) {
                let valorFatorFinanceiroUnitario: number = subItem.valor_fator_financeiro_unitario != null ? subItem.valor_fator_financeiro_unitario : 0;
                let valorUnitarioFinal: number = valor + valorFatorFinanceiroUnitario + subItem.valor_imposto_unitario;

                subItem.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorUnitarioFinal);
              } else {
                subItem.valor_unitario_final = 0;
              }
            }
          } else {
            /* S/ Imposto */

            /* Imposto */

            item.valor_imposto = 0;
            item.valor_imposto_unitario = 0;
            item.valor_imposto_produto = null;
            item.valor_imposto_total = parseFloat(item.valor_fator_financeiro_total.toFixed(2));

            item.valor_imposto_total_final = item.valor_imposto_total;

            let valorFatorFinanceiroUnitario: number = item.valor_fator_financeiro_unitario != null ? item.valor_fator_financeiro_unitario : 0;
            let valorUnitarioFinal: number = item.valor + valorFatorFinanceiroUnitario + item.valor_imposto_unitario;

            item.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorUnitarioFinal);
          }
        });

        /* Produtos (Produtos Vinculados) */

        let qtde: number = item.qtde != null && item.qtde > 0 ? item.qtde : 0;
        let valorCampanhaImpostoUnitario: number = item.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.valor_imposto), 0);
        let valorCampanhaImpostoTotal: number = item.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.valor_imposto_total), 0);

        /* Imposto */

        item.valor_imposto = this.formatacaoService.quatroCasasDecimais(valorCampanhaImpostoUnitario * qtde);
        item.valor_imposto_unitario = this.formatacaoService.quatroCasasDecimais(valorCampanhaImpostoUnitario);

        if (valorCampanhaImpostoTotal > 0) {
          item.valor_imposto_produto = this.formatacaoService.quatroCasasDecimais(valorCampanhaImpostoTotal);
        } else {
          item.valor_imposto_produto = null;
        }

        let valorImpostoTotal: number = valorCampanhaImpostoTotal * qtde;

        item.valor_imposto_total = parseFloat(valorImpostoTotal.toFixed(2));

        item.valor_imposto_total_final = item.valor_imposto_total;

        if (qtde > 0) {
          item.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorCampanhaImpostoTotal);
        } else {
          item.valor_unitario_final = 0;
        }
      } else {
        /* Produtos */

        let qtde: number = item.qtde != null && item.qtde > 0 ? item.qtde : 0;
        let valor: number = item.valor != null ? item.valor : 0;

        if (this.configuracao != null && this.configuracao.flag_exibir_imposto_produto) {
          /* C/ Imposto */

          /* Imposto */

          if (item.multiplo == null || (qtde % item.multiplo == 0)) {
            let valorImposto: number = this.calcularImposto(item);
            let valorImpostoUnitario: number = valorImposto / (qtde < 1 ? 1 : qtde);
            let valorImpostoProduto: number = valor + valorImpostoUnitario;
            let valorImpostoTotal: number = ((valor * qtde) + valorImposto);

            item.valor_imposto = this.formatacaoService.quatroCasasDecimais(valorImposto);
            item.valor_imposto_unitario = this.formatacaoService.quatroCasasDecimais(valorImpostoUnitario);

            if (qtde > 0) {
              item.valor_imposto_produto = this.formatacaoService.quatroCasasDecimais(valorImpostoProduto);
            } else {
              item.valor_imposto_produto = null;
            }

            item.valor_imposto_total = parseFloat(valorImpostoTotal.toFixed(2));

            item.valor_imposto_total_final = item.valor_imposto_total;

            if (qtde > 0) {
              let valorUnitarioFinal: number = valor + item.valor_imposto_unitario;

              item.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorUnitarioFinal);
            } else {
              item.valor_unitario_final = 0;
            }
          } else {
            /* Imposto */

            item.valor_imposto = 0;
            item.valor_imposto_unitario = 0;
            item.valor_imposto_produto = null;
            item.valor_imposto_total = 0;

            item.valor_imposto_total_final = item.valor_imposto_total;

            item.valor_unitario_final = 0;
          }

          /* Total Fracionado */

          if (item.fracionado && (item.qtde != null && item.qtde != 0) && (item.valor != null && item.valor != 0)) {
            item = this.calcularFracionadoProduto(item);
          }
        } else {
          /* S/ Imposto */

          /* Imposto */

          item.valor_imposto = 0;
          item.valor_imposto_unitario = 0;
          item.valor_imposto_produto = null;

          let valorImpostoTotal: number = valor * qtde;

          item.valor_imposto_total = parseFloat(valorImpostoTotal.toFixed(2));

          item.valor_imposto_total_final = item.valor_imposto_total;

          if (item.qtde > 0) {
            let valorUnitarioFinal: number = item.valor + item.valor_imposto_unitario;

            item.valor_unitario_final = this.formatacaoService.quatroCasasDecimais(valorUnitarioFinal);
          } else {
            item.valor_unitario_final = 0;
          }
        }
      }

      /* Produtos Original p/ Produtos */

      if (item.categoria == this.campanhas) {
        /* Campanha */

        let produto: ProdutoPromocional = this.produtos.find(produtoOriginal => produtoOriginal.id_campanha == item.id_campanha);

        if (produto != null) {
          if (produto.produtos_vinculados != null) {
            /* Produtos Vinculados */

            produto.produtos_vinculados.forEach((subItem) => {
              let produtoVinculado: ProdutoPromocional = item.produtos_vinculados.find(produtoVinculado => produtoVinculado.id == subItem.id);

              if (produtoVinculado != null) {
                /* Imposto */

                subItem.valor_imposto = produtoVinculado.valor_imposto;
                subItem.valor_imposto_unitario = produtoVinculado.valor_imposto_unitario;
                subItem.valor_imposto_produto = produtoVinculado.valor_imposto_produto;
                subItem.valor_imposto_total = produtoVinculado.valor_imposto_total;

                subItem.valor_imposto_total_final = produtoVinculado.valor_imposto_total_final;

                subItem.valor_unitario_final = produtoVinculado.valor_unitario_final;
              }
            });

            /* Produtos (Produtos Vinculados) */

            /* Imposto */

            produto.valor_imposto = item.valor_imposto;
            produto.valor_imposto_unitario = item.valor_imposto_unitario;
            produto.valor_imposto_produto = item.valor_imposto_produto;
            produto.valor_imposto_total = item.valor_imposto_total;

            produto.valor_imposto_total_final = item.valor_imposto_total_final;

            produto.valor_unitario_final = item.valor_unitario_final;
          }
        }
      } else {
        /* Produtos */

        let produto: ProdutoPromocional = this.produtos.find(produtoOriginal => produtoOriginal.id == item.id);

        if (produto != null) {
          /* Imposto */

          produto.valor_imposto = item.valor_imposto;
          produto.valor_imposto_unitario = item.valor_imposto_unitario;
          produto.valor_imposto_produto = item.valor_imposto_produto;
          produto.valor_imposto_total = item.valor_imposto_total;

          produto.valor_imposto_total_final = item.valor_imposto_total_final;

          produto.valor_unitario_final = item.valor_unitario_final;

          /* Total Fracionado */

          produto.valor_unitario_fracionado = item.valor_unitario_fracionado;
          produto.valor_unitario_imposto_fracionado = item.valor_unitario_imposto_fracionado;
        }
      }
    });

    /* Produtos Original p/ Produtos Adicionados */

    this.produtosOriginal.forEach((item) => {
      if (item.categoria == this.campanhas) {
        /* Campanha */

        let produto: ProdutoPromocional = this.produtosAdicionados.find(produtoOriginal => produtoOriginal.id_campanha == item.id_campanha);

        if (produto != null) {
          if (produto.produtos_vinculados != null) {
            /* Produtos Vinculados */

            produto.produtos_vinculados.forEach((subItem) => {
              let produtoVinculado: ProdutoPromocional = item.produtos_vinculados.find(produtoVinculado => produtoVinculado.id == subItem.id);

              if (produtoVinculado != null) {
                /* Imposto */

                subItem.valor_imposto = produtoVinculado.valor_imposto;
                subItem.valor_imposto_unitario = produtoVinculado.valor_imposto_unitario;
                subItem.valor_imposto_produto = produtoVinculado.valor_imposto_produto;
                subItem.valor_imposto_total = produtoVinculado.valor_imposto_total;

                subItem.valor_imposto_total_final = produtoVinculado.valor_imposto_total_final;

                subItem.valor_unitario_final = produtoVinculado.valor_unitario_final;
              }
            });

            /* Produto (Produtos Vinculados) */

            /* Imposto */

            produto.valor_imposto = item.valor_imposto;
            produto.valor_imposto_unitario = item.valor_imposto_unitario;
            produto.valor_imposto_produto = item.valor_imposto_produto;
            produto.valor_imposto_total = item.valor_imposto_total;

            produto.valor_imposto_total_final = item.valor_imposto_total_final;

            produto.valor_unitario_final = item.valor_unitario_final;
          }
        }
      } else {
        /* Produto */

        let produto: ProdutoPromocional = this.produtosAdicionados.find(produtoOriginal => produtoOriginal.id == item.id);

        if (produto != null) {
          /* Imposto */

          produto.valor_imposto = item.valor_imposto;
          produto.valor_imposto_unitario = item.valor_imposto_unitario;
          produto.valor_imposto_produto = item.valor_imposto_produto;
          produto.valor_imposto_total = item.valor_imposto_total;

          produto.valor_imposto_total_final = item.valor_imposto_total_final;

          produto.valor_unitario_final = item.valor_unitario_final;

          /* Total Fracionado */

          produto.valor_unitario_fracionado = item.valor_unitario_fracionado;
          produto.valor_unitario_imposto_fracionado = item.valor_unitario_imposto_fracionado;
        }
      }
    });

    this.totalizarProdutosAdicionados();
  }

  /* Valor / Produto (Original) */

  calcularValorProdutoOriginal(produto: ProdutoPromocional): number {
    if (produto != null) {
      let valorCalculoInicial: number = 0;

      if (produto.aplica_fator_financeiro) {
        /* C/ Fator */

        valorCalculoInicial = produto.valor_fator_financeiro_tabela != null ? produto.valor_fator_financeiro_tabela : 0;
      } else {
        /* S/ Fator */

        valorCalculoInicial = produto.valor_tabela != null && produto.valor_tabela > 0 ? produto.valor_tabela : produto.price;
      }

      if (produto.porcentagem_desconto_temporaria != null && !isNaN(produto.porcentagem_desconto_temporaria) && isFinite(produto.porcentagem_desconto_temporaria)) {
        if (produto.porcentagem_desconto_temporaria > 0 && produto.porcentagem_desconto_temporaria <= 100) {
          let valorDesconto: number = ((valorCalculoInicial * produto.porcentagem_desconto_temporaria) / 100);
          let valorCalculado: number = valorCalculoInicial - valorDesconto;

          if (valorCalculado >= 0) {
            return this.formatacaoService.quatroCasasDecimais(valorCalculado);
          }
        }
      } else if (produto.valor != null) {
        return valorCalculoInicial;
      }

      return produto.valor;
    }
  }
}
