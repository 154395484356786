import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { timer, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { CallCenterDiscadorService } from '../../core/services/call-center-discador/call-center-discador.service';
import { CentralAtendimentoService } from '../../core/services/central-atendimento/central-atendimento.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { CallCenterLigacaoAnalitica } from '../../core/models/call-center/call-center-ligacao-analitica';
import { CallCenterMotivoPausa } from '../../core/models/call-center/call-center-motivo-pausa';
import { CallCenterPausa } from '../../core/models/call-center/call-center-pausa';
import { CentralAtendimentoEvento } from '../../core/models/central-atendimento/central-atendimento-evento';
import { Cliente } from '../../core/models/clientes/cliente';

@Component({
  selector: 'app-call-center-discador',
  templateUrl: './call-center-discador.component.html',
  styleUrls: ['./call-center-discador.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CallCenterDiscadorComponent implements OnInit {
  /* Call Center (Motivos Pausa) */

  motivosPausa: CallCenterMotivoPausa[] = [];

  motivoPausaInvalido: boolean = false;

  motivoPausaSelecionado: CallCenterMotivoPausa = null;

  motivoPausaTempo: { hour: number, minute: number } = null;

  /* Discador */

  discadorMensagem: string = "Processando...";

  discadorStatusPause: string = "pause";
  discadorStatusPlay: string = "play";

  discadorStatus: string = this.discadorStatusPause;

  /* Tempo */

  tempoProximaChamada = new Subscription();

  tempoEntreChamadas: number = 10;

  segundosProximaChamada: number = this.tempoEntreChamadas;

  qtdeChamadaRealizada: number = 0;

  /* Storage */

  discador_ativo: boolean = (localStorage.getItem("discador_ativo") == "true");
  discador_pausado: boolean = (localStorage.getItem("discador_pausado") == "true");
  seller_id: number = parseInt(localStorage.getItem("id"));

  @Input() ligacoesAnaliticas: CallCenterLigacaoAnalitica[] = [];

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private callCenterDiscadorService: CallCenterDiscadorService,
    private centralAtendimentoService: CentralAtendimentoService,
    private clienteService: ClienteService,
    private vendedorService: VendedorService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();

    this.pararTempo();
  }

  ngOnInit() {
    this.carregarDados();

    if (this.discador_ativo) {
      this.play();
    } else {
      this.pause(true, false);
    }
  }

  /* Ações */

  cancelarPausa() {
    this.callCenterDiscadorService.chamadasCancelarPausa();
  }

  iniciarChamada(ligacao_selecionada: number, client_id_app: number) {
    let clienteSelecionado: Cliente = null;

    this.clienteService.getById(client_id_app).subscribe(
      clientesSelecionados => clienteSelecionado = clientesSelecionados,
      error => { console.log("Erro: " + error) },
      () => {
        if (clienteSelecionado != null) {
          let telefone: string = "";

          if (clienteSelecionado.phone != null && clienteSelecionado.phone != "") {
            telefone = clienteSelecionado.phone;
          } else if (clienteSelecionado.fone_02 != null && clienteSelecionado.fone_02 != "") {
            telefone = clienteSelecionado.fone_02;
          } else if (clienteSelecionado.fone_03 != null && clienteSelecionado.fone_03 != "") {
            telefone = clienteSelecionado.fone_03;
          }

          if (telefone != "") {
            const adicionarEvento = this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("telefonia", "iniciar_chamada", "", "", 0, false, false, false, false, false, "", "", telefone, null, "", 0));

            adicionarEvento.then((retorno) => {
              if (retorno.eventoExecutado) {
                this.router.navigate(["/call-center-ligacao/" + ligacao_selecionada]);
              } else {
                this.pause(true, true);
              }
            });
          } else {
            this.toastr.error("", "Nenhum telefone localizado!");

            this.router.navigate(["/call-center-ligacao/" + ligacao_selecionada]);
          }
        }
      }
    );
  }

  pararTempo() {
    this.segundosProximaChamada = this.tempoEntreChamadas;

    this.tempoProximaChamada.unsubscribe();
  }

  pause(externa: boolean, registrar: boolean) {
    this.pararTempo();

    if (registrar) {
      let alterarStatusDiscador = false;

      this.vendedorService.updateStatusDiscadorByVendedor(false).subscribe(
        alterarStatusDiscadores => alterarStatusDiscador = alterarStatusDiscadores,
        error => { console.log("Erro: " + error) },
        () => {

        }
      );
    }

    if (externa) {
      this.discador_ativo = false;

      this.qtdeChamadaRealizada = 0;

      this.discadorMensagem = "Aperte play para iniciar as chamadas automáticas...";

      this.discadorStatus = this.discadorStatusPause;

      /* Storage */

      localStorage.setItem("discador_ativo", "false");
    }
  }

  play() {
    if (this.ligacoesAnaliticas.length > 0) {
      let ligacoesAnaliticasDisponiveis = this.ligacoesAnaliticas.filter(ligacaoAnalitica => ligacaoAnalitica.id_status == 1 || ligacaoAnalitica.id_status == 6); /* 1 = À Ligar / 6 = Agendada */

      if (ligacoesAnaliticasDisponiveis.length > 0) {
        let mensagem: string = "";

        if (this.qtdeChamadaRealizada > 0) {
          mensagem = "Próxima chamada em ";
        } else {
          mensagem = "Chamadas automáticas iniciadas! Próxima chamada em ";

          /* Storage */

          localStorage.setItem("discador_ativo", "true");

          let alterarStatusDiscador = false;

          this.vendedorService.updateStatusDiscadorByVendedor(true).subscribe(
            alterarStatusDiscadores => alterarStatusDiscador = alterarStatusDiscadores,
            error => { console.log("Erro: " + error) },
            () => {

            }
          );
        }

        this.tempoProximaChamada = timer(1000, 1000).subscribe(() => { /* Início / Intervalo */
          this.discadorMensagem = mensagem + this.segundosProximaChamada + " segundos...";

          this.segundosProximaChamada--;

          if (this.segundosProximaChamada == -1) {
            this.qtdeChamadaRealizada++;

            let ligacaoSelecionada = this.ligacoesAnaliticas.find(ligacaoAnalitica => ligacaoAnalitica.id_status == 1 || ligacaoAnalitica.id_status == 6); /* 1 = À Ligar / 6 = Agendada */

            if (ligacaoSelecionada != null) {
              this.pause(false, false);

              this.iniciarChamada(ligacaoSelecionada.id, ligacaoSelecionada.client_id);
            } else {
              this.pause(true, true);

              this.callCenterDiscadorService.chamadasDesabilitadas();

              this.toastr.error("", "Nenhuma ligação encontrada!");
            }
          } else if (this.segundosProximaChamada == (this.tempoEntreChamadas - 1)) {
            this.discadorStatus = this.discadorStatusPlay;
          }
        });
      } else {
        this.pause(true, true);

        this.callCenterDiscadorService.chamadasDesabilitadas();

        this.toastr.error("", "Nenhuma ligação encontrada!");
      }
    } else {
      this.pause(true, true);

      this.callCenterDiscadorService.chamadasDesabilitadas();

      this.toastr.error("", "Nenhuma ligação encontrada!");
    }
  }

  /* Dados */

  addPausa() {
    let retorno = new Promise<void>((resolver) => {
      let pausa = new CallCenterPausa();

      pausa.seller_id = this.seller_id;
      pausa.id_motivo_pausa = this.motivoPausaSelecionado.id;
      pausa.data_criacao_usuario = new Date();

      /* Hora Inicial */

      let horaInicial = new Date();

      pausa.hr_inicial_pausa = this.datePipe.transform(horaInicial, "HH:mm:ss");

      /* Tempo */

      let tempo = new Date();

      tempo.setHours(this.motivoPausaTempo == null ? 0 : this.motivoPausaTempo.hour);
      tempo.setMinutes(this.motivoPausaTempo == null ? 0 : this.motivoPausaTempo.minute);
      tempo.setSeconds(0);

      pausa.tempo_pausa = this.datePipe.transform(tempo, "HH:mm:ss");

      let adicionarPausa: boolean = false;

      this.callCenterService.addPausa(pausa).subscribe(
        adicionarPausas => adicionarPausa = adicionarPausas,
        error => { console.log("Erro: " + error) },
        () => {
          if (adicionarPausa) {
            this.discador_pausado = true;

            this.toastr.success("", "Pausa salva com sucesso!");
          }

          return resolver();
        }
      );
    });

    return retorno;
  }

  carregarDados() {
    /* Call Center (Motivos Pausa) */

    this.callCenterService.getMotivoPausaGestorByGuidClienteNapis(false).subscribe(
      motivosPausa => this.motivosPausa = motivosPausa,
      error => { console.log("Erro: " + error) },
      () => {

      }
    );
  }

  /* Call Center (Motivos Pausa) */

  selecionarMotivoPausa(id_motivo: string) {
    if (id_motivo != null) {
      this.motivoPausaSelecionado = this.motivosPausa.find(motivoPausa => motivoPausa.id == parseInt(id_motivo));
    } else {
      if (this.motivosPausa.length > 0) {
        this.motivoPausaSelecionado = this.motivosPausa[0];
      }
    }

    if (this.motivoPausaSelecionado != null) {
      let tempo = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " " + this.motivoPausaSelecionado.tempo_pausa);

      this.motivoPausaTempo = {
        hour: tempo.getHours(),
        minute: tempo.getMinutes()
      };
    }
  }

  /* Modal */

  modalPausarChamadas(content: any) {
    /* Storage */

    let chamadas_automaticas: boolean = (localStorage.getItem("chamadas_automaticas") == "true");

    if (!chamadas_automaticas) {
      this.pause(true, true);

      return;
    } else {
      this.pause(true, true);

      this.callCenterDiscadorService.chamadasReiniciarTempo();
    }

    this.selecionarMotivoPausa(null);

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-pausar-chamadas",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Confirmar") {
        /* Motivo */

        if (this.motivoPausaSelecionado == null) {
          this.motivoPausaInvalido = true;
        } else {
          this.motivoPausaInvalido = false;
        }

        if (!this.motivoPausaInvalido) {
          const addPausa = this.addPausa();

          addPausa.then(() => {
            this.callCenterDiscadorService.chamadasPausadas();
          });
        } else {
          this.modalPausarChamadas(content);
        }
      } else {
        /* Storage */

        let chamadas_automaticas: boolean = (localStorage.getItem("chamadas_automaticas") == "true");

        if (chamadas_automaticas) {
          this.play();
        }

        this.motivoPausaInvalido = false;
      }
    }, (reacao) => {
      if (reacao != null) {
        /* Storage */

        let chamadas_automaticas: boolean = (localStorage.getItem("chamadas_automaticas") == "true");

        if (chamadas_automaticas) {
          this.play();
        }
      }

      this.motivoPausaInvalido = false;
    });
  }
}
