import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FormatacaoService {
  constructor() { }

  duasCasasDecimais(valor: number | null): number {
    if (valor != null) {
      let match: RegExpMatchArray = valor.toString().match(/^-?\d+(?:\.\d{0,2})?/);

      if (match != null) {
        valor = parseFloat(match[0].toString());
      }
    }

    return valor;
  }

  tresCasasDecimais(valor: number | null): number {
    if (valor != null) {
      let match: RegExpMatchArray = valor.toString().match(/^-?\d+(?:\.\d{0,3})?/);

      if (match != null) {
        valor = parseFloat(match[0].toString());
      }
    }

    return valor;
  }

  quatroCasasDecimais(valor: number | null): number {
    if (valor != null) {
      let match: RegExpMatchArray = valor.toString().match(/^-?\d+(?:\.\d{0,4})?/);

      if (match != null) {
        valor = parseFloat(match[0].toString());
      }
    }

    return valor;
  }
}
