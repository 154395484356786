export class CallCenterQualificacaoQuestaoResposta {
  public id: number;
  public id_call_center_qualificacao_questao: number;
  public id_call_center_qualificacao_questao_alternativa: number;
  public id_call_center_ligacao: number;
  public created_at: Date;
  public resposta: string;

  constructor() { }
}
