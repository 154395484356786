import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { AutomacaoEventoService } from '../../core/services/automacao-eventos/automacao-evento.service';

/* Models */

import { AutomacaoEventoMensagem } from '../../core/models/automacao-eventos/automacao-evento-mensagem';

@Component({
  selector: 'app-automacao-mensagens',
  templateUrl: './automacao-mensagens.component.html',
  styleUrls: ['./automacao-mensagens.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class AutomacaoMensagensComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Eventos (Mensagem) */

  automacaoEventosMensagens: AutomacaoEventoMensagem[] = [];

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private automacaoEventoService: AutomacaoEventoService
  ) { }

  ngOnInit() {
    this.carregarDados();
  }

  /* Ações */

  alterarMensagem(id: number) {
    this.router.navigate(["/automacao-mensagens-cadastro/" + id]);
  }

  deletarMensagem(id: number) {
    let automacaoEventoMensagem = this.automacaoEventosMensagens.findIndex(automacaoEventoMensagem => automacaoEventoMensagem.id == id);

    if (automacaoEventoMensagem != -1) {
      let deletarAutomacaoEventoMensagem: boolean = false;

      this.automacaoEventoService.deleteMensagemById(id).subscribe(
        deletarAutomacaoEventosMensagens => deletarAutomacaoEventoMensagem = deletarAutomacaoEventosMensagens,
        error => { console.log("Erro: " + error) },
        () => {
          if (deletarAutomacaoEventoMensagem) {
            this.automacaoEventosMensagens.splice(automacaoEventoMensagem, 1);

            this.automacaoEventosMensagens = [...this.automacaoEventosMensagens];

            this.toastr.success("", "Pausa excluída com sucesso!");
          }
        }
      );
    }
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;

    this.automacaoEventoService.getMensagemByGuidClienteNapis().subscribe(
      automacaoEventoMensagem => this.automacaoEventosMensagens = automacaoEventoMensagem,
      error => { console.log("Erro: " + error) },
      () => {
        this.carregando = false;
      }
    );
  }
}
