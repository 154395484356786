import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { NotaFiscalService } from '../../core/services/notas-fiscais/nota-fiscal.service';
import { TarefaService } from '../../core/services/tarefas/tarefa.service';
import { TipoTarefaService } from '../../core/services/tipos/tipo-tarefa.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { CallCenterLigacaoAnalitica } from '../../core/models/call-center/call-center-ligacao-analitica';
import { Cliente } from '../../core/models/clientes/cliente';
import { NotaFiscalSintetica } from '../../core/models/notas-fiscais/nota-fiscal-sintetica';
import { Tarefa } from '../../core/models/tarefas/tarefa';
import { TipoTarefa } from '../../core/models/tipos/tipo-tarefa';
import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-crm-tarefas',
  templateUrl: './crm-tarefas.component.html',
  styleUrls: ['./crm-tarefas.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CrmTarefasComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  carregandoDadosEventos: boolean = false;

  /* Calendário */

  atualizacao: Subject<any> = new Subject();

  calendarioAcoes: CalendarEventAction[] = [
    {
      label: "Alterar",
      a11yLabel: "Alterar",
      cssClass: "pl-1 pr-1",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.monitorarEvento("Alterar", event);
      },
    },
    {
      label: "Excluir",
      a11yLabel: "Excluir",
      cssClass: "pl-1 pr-1",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.monitorarEvento("Excluir", event);
      },
    },
  ];

  CalendarView = CalendarView;

  /* Eventos */

  eventos: CalendarEvent[] = [];

  eventoSelecionado: CalendarEvent = null;

  /* Filtro (Clientes) */

  formFiltroCliente: FormGroup;

  filtroClientes: any[] = [
    { texto: "Código", valor: "0" },
    { texto: "CNPJ/CPF", valor: "1" },
    { texto: "Nome Fantasia", valor: "2" },
    { texto: "Razão Social", valor: "3" }
  ];

  enviadoCliente: boolean = false;

  filtrandoCliente: boolean = false;

  /* Visualização */

  dataSelecionada: Date = new Date();

  dataSelecionadaAberta: boolean = false;

  visualizacao: CalendarView = CalendarView.Month;

  /* Dados */

    /* Filtro (Clientes) */

    clientes: Cliente[] = [];
    clientesOriginal: Cliente[] = [];

    clienteSelecionado: Cliente = null;

    clienteInvalido: boolean = false;

    /* Ligações */

    ligacoesAnaliticas: CallCenterLigacaoAnalitica[] = [];

    ligacaoMensagem: string = "";

    /* Notas Fiscais */

    notasFiscais: NotaFiscalSintetica[] = [];

    /* Tarefas */

    tarefas: Tarefa[] = [];

    tarefaObservacao: string = "";

    /* Tarefas (Data/Hora) */

    tarefaData: { year: number, month: number, day: number } = null;
    tarefaDataInvalida: boolean = false;
    tarefaHora: { hour: number, minute: number } = null;

    /* Tarefas (Baixa) */

    tarefaDataBaixa: { year: number, month: number, day: number } = null;
    tarefaDataBaixaInvalida: boolean = false;
    tarefaObservacaoBaixa: string = "";

    /* Tipo Tarefa */

    tiposTarefa: TipoTarefa[] = [];

    tipoTarefaSelecionada: TipoTarefa = null;

    /* Vendedores */

    vendedores: Vendedor[] = [];

    vendedorSelecionado: Vendedor = null;

    /* Vendedores (Tarefas) */

    vendedorTarefaSelecionado: Vendedor = null;

  /* Modal */

  @ViewChild("gerenciarTarefa", { static: false }) gerenciarTarefa: ElementRef;

  /* Storage */

  seller_id: number = parseInt(localStorage.getItem("id"));

  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private clienteService: ClienteService,
    private notaFiscalService: NotaFiscalService,
    private tarefaService: TarefaService,
    private tipoTarefaService: TipoTarefaService,
    private vendedorService: VendedorService
  ) { }

  ngOnInit() {
    /* Filtro (Clientes) */

    this.formFiltroCliente = this.formBuilder.group({
      filtro: ["3"],
      codigo: ["", Validators.required],
      cnpj_cpf: ["", Validators.required],
      nome_fantasia: ["", Validators.required],
      razao_social: ["", Validators.required]
    });

    this.organizarFiltroCliente();

    this.carregarDados();
  }

  get fc() {
    return this.formFiltroCliente.controls;
  }

  /* Ações */

  alterarDataSelecionadaAberta() {
    this.dataSelecionadaAberta = false;
  }

  alterarTempoEvento({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.eventos = this.eventos.map((evento) => {
      if (evento === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }

      return evento;
    });

    this.monitorarEvento("Redimensionar", event);
  }

  alterarVisualizacao(visualizacao: CalendarView) {
    this.visualizacao = visualizacao;
  }

  monitorarEvento(action: string, event: CalendarEvent): void {
    switch (action) {
      case "Alterar":
        this.modalGerenciarTarefa(this.gerenciarTarefa, event);

        break;
      case "Excluir":
        if (event.meta.tipo == "LIGACOES") {
          let id: number = parseInt(event.id.toString());

          let deletarCallCenterLigacao: boolean = false;

          this.callCenterService.deleteCallCenterLigacaoById(id, this.seller_id).subscribe(
            deletarCallCenterLigacoes => deletarCallCenterLigacao = deletarCallCenterLigacoes,
            error => { console.log("Erro: " + error) },
            () => {
              if (deletarCallCenterLigacao) {
                this.carregarDadosEventos();

                this.toastr.success("", "Ligação excluída com sucesso!");
              }
            }
          );
        } else if (event.meta.tipo == "TAREFAS") {
          let id: string = event.id.toString();

          let deletarTarefa: boolean = false;

          this.tarefaService.deleteTarefaById(id, this.seller_id).subscribe(
            deletarTarefas => deletarTarefa = deletarTarefas,
            error => { console.log("Erro: " + error) },
            () => {
              if (deletarTarefa) {
                this.carregarDadosEventos();

                this.toastr.success("", "Tarefa excluída com sucesso!");
              }
            }
          );
        }

        break;
      case "Redimensionar":
        break;
      default:

    }
  }

  selecionarDia({ date, event }: { date: Date; event: CalendarEvent[] }): void {
    if (isSameMonth(date, this.dataSelecionada)) {
      if ((isSameDay(this.dataSelecionada, date) && this.dataSelecionadaAberta == true) || (event != null && event.length == 0)) {
        this.dataSelecionadaAberta = false;
      } else {
        this.dataSelecionadaAberta = true;
      }

      this.dataSelecionada = date;
    }
  }

  /* Dados */

  addUpdate() {
    let retorno = new Promise<void>((resolver) => {
      if (this.tipoTarefaSelecionada.nome == "LIGAÇÃO") {
        let ligacao = new CallCenterLigacaoAnalitica();

        ligacao.client_id = this.clienteSelecionado.client_id_app;
        ligacao.seller_id = this.vendedorTarefaSelecionado.id;
        ligacao.tmp = 1;
        ligacao.id_tmk = this.vendedorTarefaSelecionado.seller_id_tmk;
        ligacao.tipo = "F";
        ligacao.id_status = 1;
        ligacao.mensagem = this.ligacaoMensagem;
        ligacao.lista = true;

        if (this.tarefaHora != null && this.tarefaHora.hour != null && this.tarefaHora.minute != null) {
          let dataTarefa = new Date(this.tarefaData.year, this.tarefaData.month - 1, this.tarefaData.day, this.tarefaHora.hour, this.tarefaHora.minute, 0);

          ligacao.data_ligacao = new Date(this.datePipe.transform(dataTarefa, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
        } else {
          let dataTarefa = new Date(this.tarefaData.year, this.tarefaData.month - 1, this.tarefaData.day, 0, 0, 0);

          ligacao.data_ligacao = new Date(this.datePipe.transform(dataTarefa, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
        }

        if (this.eventoSelecionado != null && this.eventoSelecionado.meta != null) {
          /* Alterando */

          ligacao.id = this.eventoSelecionado.meta.ligacao.id;

          let alterarLigacao: boolean = false;

          this.callCenterService.updateLigacao(ligacao).subscribe(
            alterarLigacoes => alterarLigacao = alterarLigacoes,
            error => { console.log("Erro: " + error) },
            () => {
              if (alterarLigacao) {
                this.carregarDadosEventos();

                this.toastr.success("", "Tarefa salva com sucesso!");
              }
            }
          );
        } else {
          /* Adicionando */

          let adicionarLigacao: boolean = false;

          this.callCenterService.addLigacao(ligacao).subscribe(
            adicionarLigacoes => adicionarLigacao = adicionarLigacoes,
            error => { console.log("Erro: " + error) },
            () => {
              if (adicionarLigacao) {
                this.carregarDadosEventos();

                this.toastr.success("", "Tarefa salva com sucesso!");
              }
            }
          );
        }
      } else {
        let tarefa = new Tarefa();

        tarefa.note = this.tarefaObservacao == null || this.tarefaObservacao == "" ? "AGENDADO PELA WEB" : "";

        if (this.tarefaHora != null && this.tarefaHora.hour != null && this.tarefaHora.minute != null) {
          let dataTarefa = new Date(this.tarefaData.year, this.tarefaData.month - 1, this.tarefaData.day, this.tarefaHora.hour, this.tarefaHora.minute, 0);

          tarefa.dt_deadline = new Date(this.datePipe.transform(dataTarefa, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
        } else {
          let dataTarefa = new Date(this.tarefaData.year, this.tarefaData.month - 1, this.tarefaData.day, 0, 0, 0);

          tarefa.dt_deadline = new Date(this.datePipe.transform(dataTarefa, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
        }

        tarefa.id_seller = this.vendedorTarefaSelecionado.id;
        tarefa.is_active = true;
        tarefa.id_tipo_tarefa = this.tipoTarefaSelecionada.id;
        tarefa.client_id = this.clienteSelecionado == null ? null : this.clienteSelecionado.client_id_app;

        if (this.tarefaDataBaixa != null && this.tarefaDataBaixa.year != null && this.tarefaDataBaixa.month != null && this.tarefaDataBaixa.day != null) {
          let dataTarefaBaixa = new Date(this.tarefaDataBaixa.year, this.tarefaDataBaixa.month - 1, this.tarefaDataBaixa.day, 0, 0, 0);

          tarefa.dt_baixa = new Date(this.datePipe.transform(dataTarefaBaixa, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
        } else {
          tarefa.dt_baixa = null;
        }

        tarefa.observacao_baixa = this.tarefaObservacaoBaixa;

        if (this.eventoSelecionado != null) {
          /* Alterando */

          tarefa.id = this.eventoSelecionado.meta.tarefa.id;

          let alterarTarefa: boolean = false;

          this.tarefaService.updateTarefa(tarefa).subscribe(
            alterarTarefas => alterarTarefa = alterarTarefas,
            error => { console.log("Erro: " + error) },
            () => {
              if (alterarTarefa) {
                this.carregarDadosEventos();

                this.toastr.success("", "Tarefa salva com sucesso!");
              }
            }
          );

        } else {
          /* Adicionando */

          let adicionarTarefa: boolean = false;

          this.tarefaService.addTarefa(tarefa).subscribe(
            adicionarTarefas => adicionarTarefa = adicionarTarefas,
            error => { console.log("Erro: " + error) },
            () => {
              if (adicionarTarefa) {
                this.carregarDadosEventos();

                this.toastr.success("", "Tarefa salva com sucesso!");
              }
            }
          );
        }
      }

      return resolver();
    });

    return retorno;
  }

  carregarDados() {
    this.carregando = true;

    /* Filtro (Clientes) */

    this.clienteService.getByUsuario(false).subscribe(
      clientes => this.clientes = clientes,
      error => { console.log("Erro: " + error) },
      () => {
        this.clientes.forEach((item) => {
          item.name = item.name.trim().toUpperCase();
          item.endereco_completo = "";

          if (item.tipo_logradouro != null) {
            item.endereco_completo = item.endereco_completo + item.tipo_logradouro.trim();
          }

          if (item.endereco != null) {
            item.endereco_completo = item.endereco_completo + " " + item.endereco.trim();
          }

          if (item.end_numero != null) {
            item.endereco_completo = item.endereco_completo + ", " + item.end_numero.trim();
          }

          if (item.cidade != null) {
            item.endereco_completo = item.endereco_completo + " / " + item.cidade.trim();
          }

          item.endereco_completo = item.endereco_completo.trim().toUpperCase();
        });

        this.clientesOriginal = JSON.parse(JSON.stringify(this.clientes));

        /* Tipo Tarefa */

        this.tipoTarefaService.getByGuidClienteNapis().subscribe(
          tiposTarefa => this.tiposTarefa = tiposTarefa,
          error => { console.log("Erro: " + error) },
          () => {
            /* Vendedores */

            this.vendedorService.getByUsuario().subscribe(
              vendedores => this.vendedores = vendedores,
              error => { console.log("Erro: " + error) },
              () => {
                this.carregando = false;
              }
            );
          }
        );
      }
    );
  }

  carregarDadosEventos() {
    if (this.vendedorSelecionado != null) {
      /* Call Center (Ligações) */

      /* Ligações Analíticas */

      this.callCenterService.getCRMByVendedor(this.vendedorSelecionado.id).subscribe(
        ligacoesAnaliticas => this.ligacoesAnaliticas = ligacoesAnaliticas,
        error => { console.log("Erro: " + error) },
        () => {
          /* Tarefas */

          this.tarefaService.getTarefaByVendedor(this.vendedorSelecionado.id).subscribe(
            tarefa => this.tarefas = tarefa,
            error => { console.log("Erro: " + error) },
            () => {
              this.transferirDados();
            }
          );
        }
      );
    }
  }

  prepararReceberDados() {
    if (this.eventoSelecionado.meta != null) {
      switch (this.eventoSelecionado.meta.tipo) {
        case "LIGACOES":
          let ligacao: CallCenterLigacaoAnalitica = this.eventoSelecionado.meta.ligacao;

          /* Filtro (Clientes) */

          this.clienteSelecionado = this.clientes.find(cliente => cliente.client_id_app == ligacao.client_id);

          /* Vendedores */

          this.vendedorTarefaSelecionado = this.vendedores.find(vendedor => vendedor.id == ligacao.seller_id);

          /* Tipo Tarefa */

          this.tipoTarefaSelecionada = this.tiposTarefa.find(tipoTarefa => tipoTarefa.nome == "LIGAÇÃO");

          /* Tarefas (Data/Hora) */

          let dataLigacao = new Date(ligacao.data_ligacao);

          this.tarefaData = {
            year: dataLigacao.getFullYear(),
            month: dataLigacao.getMonth() + 1,
            day: dataLigacao.getDate()
          };

          this.tarefaHora = {
            hour: dataLigacao.getHours(),
            minute: dataLigacao.getMinutes()
          };

          /* Ligações */

          this.ligacaoMensagem = ligacao.mensagem;

          break;
        case "TAREFAS":
          let tarefa: Tarefa = this.eventoSelecionado.meta.tarefa;

          /* Filtro (Clientes) */

          this.clienteSelecionado = this.clientes.find(cliente => cliente.client_id_app == tarefa.client_id);

          /* Vendedores */

          this.vendedorTarefaSelecionado = this.vendedores.find(vendedor => vendedor.id == tarefa.id_seller);

          /* Tipo Tarefa */

          this.tipoTarefaSelecionada = this.tiposTarefa.find(tipoTarefa => tipoTarefa.id == tarefa.id_tipo_tarefa);

          /* Tarefas (Data/Hora) */

          let dataTarefa = new Date(tarefa.dt_deadline);

          this.tarefaData = {
            year: dataTarefa.getFullYear(),
            month: dataTarefa.getMonth() + 1,
            day: dataTarefa.getDate()
          };

          this.tarefaHora = {
            hour: dataTarefa.getHours(),
            minute: dataTarefa.getMinutes()
          };

          /* Tarefas */

          this.tarefaObservacao = tarefa.note;

          /* Tarefas (Baixa) */

          if (tarefa.dt_baixa != null) {
            let dataTarefaBaixa = new Date(tarefa.dt_baixa);

            this.tarefaDataBaixa = {
              year: dataTarefaBaixa.getFullYear(),
              month: dataTarefaBaixa.getMonth() + 1,
              day: dataTarefaBaixa.getDate()
            };
          }

          this.tarefaObservacaoBaixa = tarefa.observacao_baixa;

          break;
        default:

      }
    }
  }

  transferirDados() {
    this.eventos = [];

    if (this.ligacoesAnaliticas.length > 0) {
      this.ligacoesAnaliticas.forEach((item) => {
        let corPrimaria: string = "#007bff";
        let corSecundaria: string = "#cce5ff"; /* 90% */

        if (item.status_name == "Agendada") {
          corPrimaria = "#ffc107";
          corSecundaria = "#fff2cc";
        }

        let evento: CalendarEvent = {
          start: new Date(item.data_ligacao),
          end: new Date(item.data_ligacao),
          id: item.id,
          title: item.name,
          meta: {
            tipo: "LIGACOES",
            ligacao: item
          },
          color: {
            primary: corPrimaria,
            secondary: corSecundaria
          },
          actions: this.calendarioAcoes,
          allDay: true,
          resizable: {
            beforeStart: false,
            afterEnd: false
          },
          draggable: false
        };

        this.eventos.push(evento);
      });
    }

    if (this.tarefas.length > 0) {
      this.tarefas.forEach((item) => {
        let evento: CalendarEvent = {
          start: new Date(item.dt_deadline),
          end: new Date(item.dt_deadline),
          id: item.id,
          title: item.nome_cliente != null && item.nome_cliente != "" ? item.nome_cliente : item.nome,
          meta: {
            tipo: "TAREFAS",
            tarefa: item
          },
          color: {
            primary: "#dc3545",
            secondary: "#f7d4d7" /* 90% */
          },
          actions: this.calendarioAcoes,
          allDay: true,
          resizable: {
            beforeStart: false,
            afterEnd: false
          },
          draggable: false
        };

        this.eventos.push(evento);
      });
    }

    this.atualizacao.next();
  }

  /* Filtro (Clientes) */

  alterarFiltroCliente(filtro_selecionado: number) {
    this.reiniciarDadosCliente();

    let filtroAtual: string = this.formFiltroCliente.controls["filtro"].value;

    this.formFiltroCliente.reset();

    if (filtro_selecionado == null) {
      this.formFiltroCliente.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltroCliente.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltroCliente();

    this.enviadoCliente = false;
    this.filtrandoCliente = false;
  }

  filtrarCliente() {
    this.enviadoCliente = true;

    if (this.formFiltroCliente.invalid) {
      return;
    }

    if (this.formFiltroCliente.controls["filtro"].value == "0") {
      /* Código */

      if (this.formFiltroCliente.controls["codigo"].value == "" || this.formFiltroCliente.controls["codigo"].value == null) {
        return;
      }
    } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
      /* CNPJ/CPF */

      if (this.formFiltroCliente.controls["cnpj_cpf"].value == "" || this.formFiltroCliente.controls["cnpj_cpf"].value == null) {
        return;
      }
    } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
      /* Nome Fantasia */

      if (this.formFiltroCliente.controls["nome_fantasia"].value == "" || this.formFiltroCliente.controls["nome_fantasia"].value == null) {
        return;
      }
    } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
      /* Razão Social */

      if (this.formFiltroCliente.controls["razao_social"].value == "" || this.formFiltroCliente.controls["razao_social"].value == null) {
        return;
      }
    }

    this.reiniciarDadosCliente();

    if (this.formFiltroCliente.controls["filtro"].value == "0") {
      /* Código */

      this.clientes = this.clientes.filter(cliente => {
        if (cliente.codigo_no_erp_do_cliente == null) {
          return false;
        } else {
          return (cliente.codigo_no_erp_do_cliente == this.formFiltroCliente.controls["codigo"].value);
        }
      });
    } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
      /* CNPJ/CPF */

      this.clientes = this.clientes.filter(cliente => {
        if (cliente.client_document == null) {
          return false;
        } else {
          let documentoAtual = cliente.client_document.trim().split(".").join("").split("-").join("").split("/").join("");
          let documentoDigitado = this.formFiltroCliente.controls["cnpj_cpf"].value.trim().split(".").join("").split("-").join("").split("/").join("");

          return (documentoAtual == documentoDigitado);
        }
      });
    } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
      /* Nome Fantasia */

      this.clientes = this.clientes.filter(cliente => {
        if (cliente.fantasia == null) {
          return false;
        } else {
          return (cliente.fantasia.toLowerCase().indexOf(this.formFiltroCliente.controls["nome_fantasia"].value.trim().toLowerCase()) !== -1);
        }
      });
    } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
      /* Razão Social */

      this.clientes = this.clientes.filter(cliente => {
        if (cliente.name == null) {
          return false;
        } else {
          return (cliente.name.toLowerCase().indexOf(this.formFiltroCliente.controls["razao_social"].value.trim().toLowerCase()) !== -1);
        }
      });
    }

    this.filtrandoCliente = true;
  }

  organizarFiltroCliente() {
    if (this.formFiltroCliente.controls["filtro"].value == "0") {
      /* Código */

      this.formFiltroCliente.get("codigo").enable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
      /* CNPJ/CPF */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").enable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
      /* Nome Fantasia */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").enable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
      /* Razão Social */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").enable();
    }
  }

  reiniciarDadosCliente() {
    this.clientes = JSON.parse(JSON.stringify(this.clientesOriginal));
  }

  selecionarClienteFiltrado(cliente_selecionado: string) {
    if (cliente_selecionado != null && cliente_selecionado != "") {
      let clienteSelecionado: Cliente = this.clientes.find(clientes => clientes.codigo_no_erp_do_cliente == cliente_selecionado);

      if (clienteSelecionado != null) {
        /* Inadimplente */

        if (clienteSelecionado.status_financeiro != null) {
          this.toastr.warning("", "Cliente c/ inadimplência!");
        }

        this.clienteSelecionado = clienteSelecionado;

        this.alterarFiltroCliente(null);

        this.clienteInvalido = false;
        this.filtrandoCliente = false;
      }
    } else {
      this.toastr.error("", "Houve um erro, contate o suporte...");
    }
  }

  /* Tipo Tarefa */

  selecionarTipoTarefa(id_tarefa: string) {
    this.tipoTarefaSelecionada = this.tiposTarefa.find(tipoTarefa => tipoTarefa.id == parseInt(id_tarefa));

    if (this.tipoTarefaSelecionada != null) {
      if (this.tipoTarefaSelecionada.nome == "LIGAÇÃO") {
        /* Tarefas */

        this.tarefaObservacao = "";

        /* Tarefas (Baixa) */

        this.tarefaDataBaixa = {
          year: null,
          month: null,
          day: null
        };

        this.tarefaDataBaixaInvalida = false;

        this.tarefaObservacaoBaixa = "";
      } else {
        /* Filtro (Clientes) */

        this.clienteInvalido = false;

        /* Ligações */

        this.ligacaoMensagem = "";
      }
    }
  }

  /* Vendedores */

  selecionarVendedor(id_vendedor: string) {
    /* Eventos */

    this.eventos = [];

    /* Vendedores */

    this.vendedorSelecionado = null;

    if (id_vendedor != "0") {
      this.vendedorSelecionado = this.vendedores.find(vendedor => vendedor.id == parseInt(id_vendedor));

      if (this.vendedorSelecionado != null) {
        this.carregarDadosEventos();
      }
    }
  }

  /* Vendedores (Tarefas) */

  selecionarVendedorTarefa(id_vendedor: string) {
    this.vendedorTarefaSelecionado = this.vendedores.find(vendedor => vendedor.id == parseInt(id_vendedor));
  }

  /* Modal */

  iniciarModalGerenciarTarefa() {
    /* Filtro (Clientes) */

    this.clienteSelecionado = null;

    this.clienteInvalido = false;

    this.alterarFiltroCliente(3);

    /* Vendedores */

    if (this.vendedorSelecionado != null) {
      this.vendedorTarefaSelecionado = this.vendedorSelecionado;
    } else {
      if (this.vendedores.length > 0) {
        this.vendedorTarefaSelecionado = this.vendedores[0];
      } else {
        this.vendedorTarefaSelecionado = null;
      }
    }

    /* Tipo Tarefa */

    if (this.tiposTarefa.length > 0) {
      this.tipoTarefaSelecionada = this.tiposTarefa[0];
    } else {
      this.tipoTarefaSelecionada = null;
    }

    /* Tarefas (Data/Hora) */

    this.tarefaData = {
      year: null,
      month: null,
      day: null
    };

    this.tarefaDataInvalida = false;

    this.tarefaHora = {
      hour: null,
      minute: null
    };

    /* Tarefas */

    this.tarefaObservacao = "";

    /* Tarefas (Baixa) */

    this.tarefaDataBaixa = {
      year: null,
      month: null,
      day: null
    };

    this.tarefaDataBaixaInvalida = false;

    this.tarefaObservacaoBaixa = "";

    /* Ligações */

    this.ligacaoMensagem = "";
  }

  modalGerenciarTarefa(content: any, event: CalendarEvent) {
    if (!this.clienteInvalido && !this.tarefaDataInvalida && !this.tarefaDataBaixaInvalida) {
      this.iniciarModalGerenciarTarefa();
    }

    if (event != null) {
      /* Alterando */

      this.eventoSelecionado = event;

      this.prepararReceberDados();
    } else {
      /* Adicionando */

      this.eventoSelecionado = null;
    }

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-gerenciar-tarefa",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Salvar") {
        if (this.tipoTarefaSelecionada.nome == "LIGAÇÃO") {
          if (this.clienteSelecionado == null) {
            this.clienteInvalido = true;

            this.toastr.error("", "Cliente em branco!");
          }
        }

        /* Tarefas (Data/Hora) */

        if (this.tarefaData == null || this.tarefaData.year == null || this.tarefaData.month == null || this.tarefaData.day == null) {
          this.tarefaDataInvalida = true;
        } else {
          this.tarefaDataInvalida = false;
        }

        /* Tarefas (Baixa) */

        if (this.tarefaObservacaoBaixa != null && this.tarefaObservacaoBaixa != "") {
          if (this.tarefaDataBaixa == null || this.tarefaDataBaixa.year == null || this.tarefaDataBaixa.month == null || this.tarefaDataBaixa.day == null) {
            this.tarefaDataBaixaInvalida = true;
          } else {
            this.tarefaDataBaixaInvalida = false;
          }
        } else {
          this.tarefaDataBaixaInvalida = false;
        }

        if (!this.clienteInvalido && !this.tarefaDataInvalida && !this.tarefaDataBaixaInvalida) {
          const addUpdate = this.addUpdate();

          addUpdate.then(() => {
            this.carregarDadosEventos();
          });
        } else {
          this.modalGerenciarTarefa(content, event);
        }
      } else {
        this.clienteInvalido = false;

        this.tarefaDataInvalida = false;

        this.tarefaDataBaixaInvalida = false;
      }
    }, () => {
      this.clienteInvalido = false;

      this.tarefaDataInvalida = false;

      this.tarefaDataBaixaInvalida = false;
    });
  }

  modalNotasFiscaisSinteticas(content: any, client_document: string) {
    client_document = client_document.replace(/[.]/g, "").replace(/[/]/g, "").replace(/[-]/g, "");

    this.notaFiscalService.getNotaFiscalSinteticaByCNPJ(client_document).subscribe(
      notaFiscal => this.notasFiscais = notaFiscal,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.notasFiscais.length > 0) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-notas-fiscais-sinteticas",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhuma nota fiscal encontrada!");
        }
      }
    );
  }
}
