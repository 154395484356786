import { CrmNegocio } from "./crm-negocio";

export class CrmAgrupado {
  public funnel_classification_id: number;
  public ganhou_perdeu: string;
  public nome_negocio: string;
  public negocios: CrmNegocio[];
  public valor_total_negocio: number;

  constructor() { }
}
