import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../../core/tools/animacao';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

/* Services */

import { DashboardVisitasService } from '../../../core/services/dashboard/visitas/dashboard-visitas.service';
import { ExcelService } from '../../../core/tools/excel.service';
import { PdfService } from '../../../core/tools/pdf.service';
import { VisitaService } from '../../../core/services/visitas/visita.service';

/* Models */

import { DashboardVisitasCanalVenda } from '../../../core/models/dashboard/visitas/dashboard-visitas-canal-venda';
import { DashboardVisitasPrimeiraVisita } from '../../../core/models/dashboard/visitas/dashboard-visitas-primeira-visita';
import { DashboardVisitasVisita } from '../../../core/models/dashboard/visitas/dashboard-visitas-visita';
import { DashboardVisitasVisitaSintetica } from '../../../core/models/dashboard/visitas/dashboard-visitas-visita-sintetica';
import { VisitaImagem } from '../../../core/models/visitas/visita-imagem';

/* Maps */

declare var google: any

import { } from '@google/maps';

@Component({
  selector: 'app-dashboard-visitas',
  templateUrl: './dashboard-visitas.component.html',
  styleUrls: ['./dashboard-visitas.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class DashboardVisitasComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Hoje */

  hoje: boolean = false;

  /* Filtros */

  formFiltro: FormGroup;

  filtrado: boolean = false;

  /* Acompanhamento da Equipe (Primeira Visita) */

  primeirasVisitas: DashboardVisitasPrimeiraVisita[] = [];
  primeirasVisitasOriginal: DashboardVisitasPrimeiraVisita[] = [];

  qtdeComCheckIn: number = 0;
  qtdeSemCheckIn: number = 0;

  /* Acompanhamento das Visitas (Visita) */

  visitas: DashboardVisitasVisita[] = [];
  visitasOriginal: DashboardVisitasVisita[] = [];

  qtdeVisitas: number = 0;
  qtdeEmRota: number = 0;
  porcentagemEmRota: number = 0;
  qtdeForaRota: number = 0;
  porcentagemForaRota: number = 0;
  qtdePedidos: number = 0;
  porcentagemPedidos: number = 0;
  valorPedidos: number = 0;
  litragemTotal: number = 0;

  /* Estatística (Canal de Vendas) / Detalhamento dos Pedidos (Canal de Vendas) */

  canaisVendas: DashboardVisitasCanalVenda[] = [];
  canaisVendasOriginal: DashboardVisitasCanalVenda[] = [];

  valorEmail: number = 0;
  qtdeEmail: number = 0;
  porcentagemEmail: number = 0;

  valorTelefone: number = 0;
  qtdeTelefone: number = 0;
  porcentagemTelefone: number = 0;

  valorVisita: number = 0;
  qtdeVisita: number = 0;
  porcentagemVisita: number = 0;

  valorWhatsApp: number = 0;
  qtdeWhatsApp: number = 0;
  porcentagemWhatsApp: number = 0;

  /* Gráfico de Efetividade das Visitas por Vendedor */

  barChartOptions: ChartOptions = {
    animation: {
      duration: 0
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontColor: "rgba(0, 0, 0, 1)"
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)",
            stepSize: 100
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            borderDash: [5],
            lineWidth: 1.4
          },
          ticks: {
            fontColor: "rgba(0, 0, 0, 1)"
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        align: "top",
        anchor: "start",
        color: "rgba(0, 0, 0, 1)",
        font: {
          weight: "bold"
        }
      }
    }
  };

  barChartLabels: Label[] = [];
  barChartType: ChartType = "bar";
  barChartLegend = true;
  barChartPlugins = [pluginDataLabels];

  barChartData: ChartDataSets[] = [
    { data: [], label: "Qtde Visitas" },
    { data: [], label: "Qtde Pedidos" }
  ];

  lineChartColors: Array<any> = [
    {
      backgroundColor: "rgba(0, 123, 255, 1)",
      borderColor: "rgba(0, 123, 255, 1)",
      pointBackgroundColor: "rgba(0, 123, 255, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(0, 123, 255, 1)"
    },
    {
      backgroundColor: "rgba(40, 167, 69, 1)",
      borderColor: "rgba(40, 167, 69, 1)",
      pointBackgroundColor: "rgba(40, 167, 69, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(40, 167, 69, 1)"
    }
  ];

  /* Maps */

  iconeOrigem: string = "assets/pins/";

  latitudeFoco: number = -22.8657664;
  longitudeFoco: number = -47.0263092;

  mapaRotas = [];

  zoom: number = 12;

  /* Relatório Sintético de Visitas e Vendas por Vendedor */

  visitasSinteticas: DashboardVisitasVisitaSintetica[] = [];
  visitasSinteticasOriginal: DashboardVisitasVisitaSintetica[] = [];

  /* Visitas (Imagens) */

  visitasImagens: VisitaImagem[] = [];

  constructor(
    private route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private dashboardVisitasService: DashboardVisitasService,
    private excelService: ExcelService,
    private pdfService: PdfService,
    private visitaService: VisitaService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      dt_inicial: [""],
      dt_final: [""],
      resultado: [""],
      vendedor: [""]
    });

    /* Hoje */

    this.route.url.subscribe(url => {
      if (url.length > 0) {
        if (url[0].path == "dashboard-visitas-hoje") {
          this.hoje = true;
        }

        this.carregarDados();
      }
    });
  }

  /* Dados */

  calcularDados() {
    if (this.primeirasVisitas != null) {
      /* Acompanhamento da Equipe (Primeira Visita) */

      this.qtdeComCheckIn = this.primeirasVisitas.filter(primeirasVisitas => primeirasVisitas.status == "Realizado").length;
      this.qtdeSemCheckIn = this.primeirasVisitas.filter(primeirasVisitas => primeirasVisitas.status == "Pendente").length;
    }

    if (this.visitas != null && this.canaisVendas != null) {
      /* Acompanhamento das Visitas (Visita) */

      let visitasFiltradas = this.visitas.filter(visitas => visitas.status != "NÃO VISITADO");

      this.qtdeVisitas = visitasFiltradas.length;

      this.qtdeEmRota = visitasFiltradas.filter(visitasFiltradas => visitasFiltradas.status == "EM ROTA").length;

      if (this.qtdeEmRota > 0) {
        this.porcentagemEmRota = ((this.qtdeEmRota / visitasFiltradas.length) * 100);
      }

      this.qtdeForaRota = visitasFiltradas.filter(visitasFiltradas => visitasFiltradas.status == "FORA ROTA").length;

      if (this.qtdeForaRota > 0) {
        this.porcentagemForaRota = ((this.qtdeForaRota / visitasFiltradas.length) * 100);
      }

      this.qtdePedidos = this.canaisVendas.length;

      this.valorPedidos = this.canaisVendas.reduce((sum, current) => sum + current.valor_do_pedido, 0);

      this.litragemTotal = this.canaisVendas.reduce((sum, current) => sum + current.total_litragem, 0);

      if (this.qtdePedidos > 0) {
        this.porcentagemPedidos = ((this.qtdePedidos / this.qtdeVisitas) * 100);
      }

      /* Estatística (Canal de Vendas) / Detalhamento dos Pedidos (Canal de Vendas) */

      this.valorEmail = this.canaisVendas.filter(canaisVendas => canaisVendas.canal_de_vendas == "E-mail").reduce((sum, current) => sum + current.valor_do_pedido, 0);
      this.qtdeEmail = this.canaisVendas.filter(canaisVendas => canaisVendas.canal_de_vendas == "E-mail").length;

      if (this.valorEmail > 0) {
        this.porcentagemEmail = ((this.valorEmail / this.valorPedidos) * 100);
      }

      this.valorTelefone = this.canaisVendas.filter(canaisVendas => canaisVendas.canal_de_vendas == "Telefone").reduce((sum, current) => sum + current.valor_do_pedido, 0);
      this.qtdeTelefone = this.canaisVendas.filter(canaisVendas => canaisVendas.canal_de_vendas == "Telefone").length;

      if (this.valorTelefone > 0) {
        this.porcentagemTelefone = ((this.valorTelefone / this.valorPedidos) * 100);
      }

      this.valorVisita = this.canaisVendas.filter(canaisVendas => canaisVendas.canal_de_vendas == "Visita").reduce((sum, current) => sum + current.valor_do_pedido, 0);
      this.qtdeVisita = this.canaisVendas.filter(canaisVendas => canaisVendas.canal_de_vendas == "Visita").length;

      if (this.valorVisita > 0) {
        this.porcentagemVisita = ((this.valorVisita / this.valorPedidos) * 100);
      }

      this.valorWhatsApp = this.canaisVendas.filter(canaisVendas => canaisVendas.canal_de_vendas == "WhatsApp").reduce((sum, current) => sum + current.valor_do_pedido, 0);
      this.qtdeWhatsApp = this.canaisVendas.filter(canaisVendas => canaisVendas.canal_de_vendas == "WhatsApp").length;

      if (this.valorWhatsApp > 0) {
        this.porcentagemWhatsApp = ((this.valorWhatsApp / this.valorPedidos) * 100);
      }

      /* Relatório Sintético de Visitas e Vendas por Vendedor */

      let visitasAgrupadas = visitasFiltradas.reduce((obj, item) => {
        obj[item.seller_id] = obj[item.seller_id] || [];
        obj[item.seller_id].push(item);

        return obj;
      }, { });

      this.visitasSinteticas = Object.keys(visitasAgrupadas).map((key) => {
        let qtdeVisitasPorVendedor = visitasAgrupadas[key].length;
        let qtdeEmRotaPorVendedor = visitasAgrupadas[key].filter(visitasAgrupadas => visitasAgrupadas.status == "EM ROTA").length;
        let porcentagemEmRotaPorVendedor = ((qtdeEmRotaPorVendedor / qtdeVisitasPorVendedor) * 100);
        let qtdeForaRotaPorVendedor = visitasAgrupadas[key].filter(visitasAgrupadas => visitasAgrupadas.status == "FORA ROTA").length;
        let porcentagemForaRotaPorVendedor = ((qtdeForaRotaPorVendedor / qtdeVisitasPorVendedor) * 100);
        let qtdeVendasPorVendedor = this.canaisVendas.filter(canaisVendas => canaisVendas.seller_id == visitasAgrupadas[key][0].seller_id).length;
        let valorPedidosPorVendedor = this.canaisVendas.filter(canaisVendas => canaisVendas.seller_id == visitasAgrupadas[key][0].seller_id).reduce((sum, current) => sum + current.valor_do_pedido, 0);
        let valorTicketMedioPorVendedor = qtdeVendasPorVendedor > 0 ? (valorPedidosPorVendedor / qtdeVendasPorVendedor) : 0;
        let porcentagemEfetividadePorVendedor = qtdeVisitasPorVendedor > 0 ? ((qtdeVendasPorVendedor / qtdeVisitasPorVendedor) * 100) : 0;

        return {
          vendedor: visitasAgrupadas[key][0].vendedor,
          qtde_visitas: qtdeVisitasPorVendedor,
          em_rota: qtdeEmRotaPorVendedor,
          porcentagem_em_rota: porcentagemEmRotaPorVendedor,
          fora_rota: qtdeForaRotaPorVendedor,
          porcentagem_fora_rota: porcentagemForaRotaPorVendedor,
          qtde_vendas: qtdeVendasPorVendedor,
          total_vendas: valorPedidosPorVendedor,
          ticket_medio: valorTicketMedioPorVendedor,
          porcentagem_efetividade: porcentagemEfetividadePorVendedor
        };
      });

      this.visitasSinteticasOriginal = JSON.parse(JSON.stringify(this.visitasSinteticas));
    }

    /* Gráfico de Efetividade das Visitas por Vendedor */

    this.visitasSinteticas.sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a["porcentagem_efetividade"], b["porcentagem_efetividade"]) * -1; /* Decrescente */
    });

    this.barChartLabels = [];
    this.barChartData[0].data = [];
    this.barChartData[1].data = [];

    this.visitasSinteticas.forEach(visitaSintetica => {
      this.barChartLabels.push(visitaSintetica.vendedor + " (" + this.currencyPipe.transform(visitaSintetica.porcentagem_efetividade, "", "").trim() + "%)")

      this.barChartData[0].data.push(visitaSintetica.qtde_visitas);

      this.barChartData[1].data.push(visitaSintetica.qtde_vendas);
    });
  }

  carregarDados() {
    this.carregando = true;

    /* Acompanhamento da Equipe (Primeira Visita) */

    this.dashboardVisitasService.getPrimeiraVisitaByUsuario().subscribe(
      primeirasVisitas => this.primeirasVisitas = primeirasVisitas,
      error => { console.log("Erro: " + error) },
      () => {
        this.primeirasVisitasOriginal = JSON.parse(JSON.stringify(this.primeirasVisitas));

        /* Acompanhamento das Visitas (Visita) */

        this.dashboardVisitasService.getVisitaByUsuario(this.hoje).subscribe(
          visitas => this.visitas = visitas,
          error => { console.log("Erro: " + error) },
          () => {
            this.visitasOriginal = JSON.parse(JSON.stringify(this.visitas));

            /* Estatística (Canal de Vendas) / Detalhamento dos Pedidos (Canal de Vendas) */

            this.dashboardVisitasService.getCanalVendaByUsuario(this.hoje).subscribe(
              canaisVendas => this.canaisVendas = canaisVendas,
              error => { console.log("Erro: " + error) },
              () => {
                this.canaisVendasOriginal = JSON.parse(JSON.stringify(this.canaisVendas));

                this.calcularDados();

                this.carregando = false;
              }
            );
          }
        );
      }
    );
  }

  reiniciarDados() {
    this.primeirasVisitas = JSON.parse(JSON.stringify(this.primeirasVisitasOriginal));
    this.visitas = JSON.parse(JSON.stringify(this.visitasOriginal));
    this.canaisVendas = JSON.parse(JSON.stringify(this.canaisVendasOriginal));
  }

  /* Exportar */

  exportarPedidos() {
    /* Detalhamento dos Pedidos (Canal de Vendas) */

    this.excelService.exportAsExcelFile(this.canaisVendas, "Detalhamento dos Pedidos");
  }

  exportarVisitas() {
    /* Acompanhamento das Visitas (Visita) */

    this.excelService.exportAsExcelFile(this.visitas, "Acompanhamento das Visitas");
  }

  exportarVisitasVendas() {
    /* Relatório Sintético de Visitas e Vendas por Vendedor */

    this.excelService.exportAsExcelFile(this.visitasSinteticas, "Relatório Sintético de Visitas e Vendas por Vendedor");
  }

  /* Filtros */

  filtrar() {
    this.filtrado = false;

    this.reiniciarDados();

    /* Período */

    if (this.formFiltro.controls["dt_inicial"].value != "" && this.formFiltro.controls["dt_inicial"].value != null && this.formFiltro.controls["dt_final"].value != "" && this.formFiltro.controls["dt_final"].value != null) {
      /* Dt. Inicial */

      let dataInicial = this.formFiltro.controls["dt_inicial"].value;

      dataInicial = new Date(dataInicial.year, dataInicial.month - 1, dataInicial.day);
      dataInicial = this.datePipe.transform(dataInicial, "yyyy/MM/dd");

      /* Dt. Final */

      let dataFinal = this.formFiltro.controls["dt_final"].value;

      dataFinal = new Date(dataFinal.year, dataFinal.month - 1, dataFinal.day);
      dataFinal = this.datePipe.transform(dataFinal, "yyyy/MM/dd");

      /* Acompanhamento das Visitas (Visita) */

      this.visitas = this.visitas.filter(visita => {
        let data = this.datePipe.transform(visita.data_rota, "yyyy/MM/dd");

        return data >= dataInicial && data <= dataFinal;
      });

      /* Estatística (Canal de Vendas) / Detalhamento dos Pedidos (Canal de Vendas) */

      this.canaisVendas = this.canaisVendas.filter(canalVenda => {
        let data = this.datePipe.transform(canalVenda.data_pedido, "yyyy/MM/dd");

        return data >= dataInicial && data <= dataFinal;
      });

      this.filtrado = true;
    }

    /* Resultado */

    if (this.formFiltro.controls["resultado"].value != "" && this.formFiltro.controls["resultado"].value != null) {
      /* Acompanhamento das Visitas (Visita) */

      this.visitas = this.visitas.filter(visita => {
        if (visita.resultado == null) {
          return false;
        } else {
          return (visita.resultado.toLowerCase().indexOf(this.formFiltro.controls["resultado"].value.trim().toLowerCase()) !== -1);
        }
      });

      this.filtrado = true;
    }

    /* Vendedor(a) */

    if (this.formFiltro.controls["vendedor"].value != "" && this.formFiltro.controls["vendedor"].value != null) {
      /* Acompanhamento das Visitas (Visita) */

      this.visitas = this.visitas.filter(visita => {
        return (visita.vendedor.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
      });

      /* Estatística (Canal de Vendas) / Detalhamento dos Pedidos (Canal de Vendas) */

      this.canaisVendas = this.canaisVendas.filter(canalVenda => {
        return (canalVenda.vendedor.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
      });

      this.filtrado = true;
    }

    if (this.filtrado) {
      this.calcularDados();
    }
  }

  removerFiltros() {
    this.reiniciarDados();

    this.calcularDados();

    this.formFiltro.reset();

    Object.keys(this.formFiltro.controls).forEach(key => {
      this.formFiltro.controls[key].setValue("");
    });

    this.filtrado = false;
  }

  /* Imprimir */

  imprimirImagensVisita(visit_id_app: number) {
    this.pdfService.imprimirImagensVisita(visit_id_app);
  }

  /* Maps */

  adicionarMarcador(latitude: number, longitude: number) {
    console.log(latitude + " : " + longitude);
  }

  adicionarMarcadoresAtuais(visita: DashboardVisitasVisita) {
    this.mapaRotas = [];

    let mapaRota = {
      name: "",
      origin: {
        lat: 0,
        lng: 0
      },
      destination: {
        lat: 0,
        lng: 0
      },
      visible: true,
      markerOptions: {
        origin: {},
        waypoints: [],
        destination: {}
      },
      renderOptions: {
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#000",
          strokeWeight: 5,
          strokeOpacity: 0.6
        }
      },
      travelMode: "DRIVING",
      waypoints: []
    };

    let mapaPonto = null;

    /* Cliente */

    mapaRota.origin.lat = visita.latcliente;
    mapaRota.origin.lng = visita.longcliente;

    mapaRota.markerOptions.origin = {
      infoWindow: "",
      icon: {
        url: this.iconeOrigem + "visita/cliente.png"
      }
    };

    mapaPonto = {
      location: {
        lat: visita.latcliente,
        lng: visita.longcliente
      },
      stopover: true
    };

    mapaRota.waypoints.push(mapaPonto)

    /* Visita */

    this.latitudeFoco = visita.latvisit;
    this.longitudeFoco = visita.longvisit;

    mapaRota.destination.lat = visita.latvisit;
    mapaRota.destination.lng = visita.longvisit;

    mapaRota.markerOptions.destination = {
      infoWindow: "",
      icon: {
        url: this.iconeOrigem + "visita/visita.png"
      }
    };

    mapaPonto = {
      location: {
        lat: visita.latvisit,
        lng: visita.longvisit
      },
      stopover: true
    };

    mapaRota.waypoints.push(mapaPonto)

    this.mapaRotas.push(mapaRota);
  }

  /* Modal */

  modalImagens(content: any, visit_id_app: number) {
    /* Visitas (Imagens) */

    this.visitaService.getVisitaImagemByVisitIdApp(visit_id_app).subscribe(
      visitasImagens => this.visitasImagens = visitasImagens,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.visitasImagens.length > 0) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-imagens",
            size: "lg",
            backdrop: "static"
          });
        }
      }
    );
  }

  modalRotas(content: any, visita: DashboardVisitasVisita) {
    this.adicionarMarcadoresAtuais(visita);

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-rotas",
      size: "xl",
      backdrop: "static"
    });
  }
}
