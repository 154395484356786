import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Integracao } from '../../models/integracoes/integracao';

@Injectable({
  providedIn: 'root'
})

export class IntegracaoService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getByGuidClienteNapis(): Observable<Integracao[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Integracao[]>(this.baseUrl + "api/Integracao/GetByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Integrações */

  public iniciarIntegracao(token: string, url_integracao: string, url_webjob: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "token": token,
      "url_integracao": url_integracao,
      "url_webjob": url_webjob
    };

    return this.http.post<boolean>(this.baseUrl + "api/Integracao/IniciarIntegracao", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
