import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { CrmCanalVenda } from '../../models/crm/crm-canal-venda';
import { CrmNegocio } from '../../models/crm/crm-negocio';

@Injectable({
  providedIn: 'root'
})

export class CrmService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Canal Venda */

  public getCrmCanalVendaByGuidClienteNapis(): Observable<CrmCanalVenda[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<CrmCanalVenda[]>(this.baseUrl + "api/Crm/GetCrmCanalVendaByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Negócio */

  public getCrmNegocioByVendedor(seller_id: number): Observable<CrmNegocio[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id
    };

    return this.http.post<CrmNegocio[]>(this.baseUrl + "api/Crm/GetCrmNegocioByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateCrmNegocioFunilByIdNegocio(id_negocio: string, funnel_classification_id: number, ganhou_perdeu: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_negocio": id_negocio,
      "funnel_classification_id": funnel_classification_id,
      "ganhou_perdeu": ganhou_perdeu
    };

    return this.http.post<boolean>(this.baseUrl + "api/Crm/UpdateCrmNegocioFunilByIdNegocio", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
