export class AutomacaoEventoMensagem {
  public id: number;
  public id_tipo: number;
  public mensagem: string;
  public guidcliente: string;
  public created_at: Date;
  public nome_tipo: string;

  constructor() { }
}
