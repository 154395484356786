import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Pedido } from '../../models/pedidos/pedido';
import { PedidoBonificacaoRetorno } from '../../models/pedidos/pedido-bonificacao-retorno';
import { PedidoPagamento } from '../../models/pedidos/pedido-pagamento';
import { PedidoSintetico } from '../../models/pedidos/pedido-sintetico';

@Injectable({
  providedIn: 'root'
})

export class PedidoService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public add(pedido: Pedido): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "pedido": pedido
    };

    return this.http.post<boolean>(this.baseUrl + "api/Pedido/Add", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getByOrder(order_id_app: number, seller_id: number): Observable<Pedido> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "order_id_app": order_id_app,
      "seller_id": seller_id
    };

    return this.http.post<Pedido>(this.baseUrl + "api/Pedido/GetByOrder", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getProximoNumeroPedidoBySeller(): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let is_master: boolean = (localStorage.getItem("is_master") == "true");
    let is_manager: boolean = (localStorage.getItem("is_manager") == "true");
    let is_master_seller: boolean = (localStorage.getItem("is_master_seller") == "true");
    let is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");

    let seller_id: number = 125;

    if (is_master || is_manager || is_master_seller || is_diretor) {
      seller_id = parseInt(localStorage.getItem("id"));
    }

    let data = {
      "seller_id": seller_id
    };

    return this.http.post<number>(this.baseUrl + "api/Pedido/GetProximoNumeroPedidoBySeller", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public update(pedido: Pedido): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "pedido": pedido
    };

    return this.http.post<boolean>(this.baseUrl + "api/Pedido/Update", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateDataIntegracaoByOrderIdApp(order_id_app: number, seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "order_id_app": order_id_app,
      "seller_id": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Pedido/UpdateDataIntegracaoByOrderIdApp", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Pagamento */

  public getPedidoPagamentoById(id: number): Observable<PedidoPagamento> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "id": id
    };

    return this.http.post<PedidoPagamento>(this.baseUrl + "api/Pedido/GetPedidoPagamentoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  /* Sintético */

  public getByCliente(client_id_app: number): Observable<PedidoSintetico[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<PedidoSintetico[]>(this.baseUrl + "api/Pedido/GetByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getByUsuario(atencao?: boolean): Observable<PedidoSintetico[]> {
    this.headers.set("Content-Type", "application/json");

    atencao = atencao == null ? false : atencao;

    let data = {
      "id": localStorage.getItem("id"),
      "atencao": atencao
    };

    return this.http.post<PedidoSintetico[]>(this.baseUrl + "api/Pedido/GetByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getStatusPedidoByOrder(order_id: number, seller_id: number): Observable<string> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "order_id": order_id,
      "seller_id": seller_id
    };

    return this.http.post<string>(this.baseUrl + "api/Pedido/GetStatusPedidoByOrder", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of("");
      })
    );
  }

  /* Ações */

  public aprovar(id: number, seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "id": id,
      "seller_id_logado": localStorage.getItem("id"),
      "seller_id_pedido": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Pedido/Aprovar", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public calcularSaldo(order_id_app: number, seller_id_client: number, seller_id_pedido: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "order_id_app": order_id_app,
      "seller_id_client": seller_id_client,
      "seller_id_pedido": seller_id_pedido
    };

    return this.http.post<boolean>(this.baseUrl + "api/Pedido/CalcularSaldo", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public enviar(order_id_app: number, seller_id: number, percentual_desconto: number): Observable<PedidoBonificacaoRetorno> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "order_id_app": order_id_app,
      "seller_id_logado": localStorage.getItem("id"),
      "seller_id_pedido": seller_id,
      "percentual_desconto": percentual_desconto
    };

    return this.http.post<PedidoBonificacaoRetorno>(this.baseUrl + "api/Pedido/Enviar", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public reintegrar(id: number, seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "id": id,
      "seller_id_logado": localStorage.getItem("id"),
      "seller_id_pedido": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Pedido/Reintegrar", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public reprovar(id: number, seller_id: number, motivo: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "seller_id_logado": localStorage.getItem("id"),
      "seller_id_pedido": seller_id,
      "motivo": motivo
    };

    return this.http.post<boolean>(this.baseUrl + "api/Pedido/Reprovar", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public retornar(id: number, seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "seller_id_logado": localStorage.getItem("id"),
      "seller_id_pedido": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Pedido/Retornar", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
