export class ProdutoCampanha {
  public id: number;
  public nome: string;
  public kit_combo: string;
  public is_active: boolean;
  public preco_bonificacao: string;
  public qtde_minima: number;
  public imagem: string;
  public guidclientenapis: string;
  public bonificacao_multiplicavel: boolean;
  public bonificacao_selecionavel: boolean;
  public id_no_erp_do_cliente: string;

  constructor() { }
}
