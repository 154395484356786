import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class ExcelService {
  constructor(
    private toastr: ToastrService
  ) { }

  /* Exportar */

  public exportAsExcelFile(json: any[], nome_arquivo: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { "data" : worksheet }, SheetNames: ["data"] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    this.saveAsExcelFile(excelBuffer, nome_arquivo);
  }

  /* Importar */

  public importExcelBinaryToJson(data: string): any[] {
    let conteudo = [];

    const workbook: XLSX.WorkBook = XLSX.read(data, { type: "binary" });

    if (workbook.SheetNames.length > 0) {
      const worksheet: XLSX.WorkSheet = workbook.Sheets[workbook.SheetNames[0]];

      conteudo = XLSX.utils.sheet_to_json(worksheet, { blankrows: false });
    }

    return conteudo;
  }

  /* Salvar */

  private saveAsExcelFile(buffer: any, nome_arquivo: string) {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

    FileSaver.saveAs(data, nome_arquivo + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
  }
}
