export class CallCenterComparativoVendaProduto {
  constructor(
    public produto: string,
    public valor_ano_passado: number,
    public valor_este_ano: number,
    public litragem_ano_passado: number,
    public litragem_este_ano: number,
    public ganho_perda_valor: number,
    public ganho_perda_litragem: number
  ) { } 
}
