import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { CallCenterDiscadorService } from '../../core/services/call-center-discador/call-center-discador.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { NotaFiscalService } from '../../core/services/notas-fiscais/nota-fiscal.service';
import { VisitaService } from '../../core/services/visitas/visita.service';

/* Models */

import { CallCenterConfiguracao } from '../../core/models/call-center/call-center-configuracao';
import { CallCenterLigacaoAnalitica } from '../../core/models/call-center/call-center-ligacao-analitica';
import { CallCenterTipo } from '../../core/models/call-center/call-center-tipo';
import { Cliente } from '../../core/models/clientes/cliente';
import { ClienteFiltrar } from '../../core/models/clientes/cliente-filtrar';
import { NotaFiscalSintetica } from '../../core/models/notas-fiscais/nota-fiscal-sintetica';
import { Visita } from '../../core/models/visitas/visita';

@Component({
  selector: 'app-call-center-nova-ligacao',
  templateUrl: './call-center-nova-ligacao.component.html',
  styleUrls: ['./call-center-nova-ligacao.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CallCenterNovaLigacaoComponent implements OnInit {
  /* Filtro (Clientes) */

  formFiltroCliente: FormGroup;

  filtroClientes: any[] = [
    { texto: "Código", valor: "0" },
    { texto: "CNPJ/CPF", valor: "1" },
    { texto: "Nome Fantasia", valor: "2" },
    { texto: "Razão Social", valor: "3" }
  ];

  enviadoCliente: boolean = false;

  filtrandoCliente: boolean = false;

  /* Dados */

    /* Call Center (Configurações) */

    callCenterConfiguracao: CallCenterConfiguracao = null;

    /* Call Center (Ligação) */

    ligacao: CallCenterLigacaoAnalitica = null;

    /* Filtro (Clientes) */

    clientes: Cliente[] = [];

    clienteSelecionado: Cliente = null;

    clienteInvalido: boolean = false;

    /* Call Center (Tipos) */

    tipos: CallCenterTipo[] = [];

    tipoSelecionado: CallCenterTipo = null;

    /* Notas Fiscais */

    notasFiscais: NotaFiscalSintetica[] = [];

    /* Visitas */

    visita: Visita = null;

    nroVisita: number = 0;

  /* Externo */

    /* Cliente (Externo) */

    clienteSelecionadoExterno: string = "";

    /* Cliente Ligação (Externo) */

    clienteLigacaoSelecionadoExterno: boolean = false;

    /* Cliente WhatsApp (Externo) */

    clienteWhatsAppSelecionadoExterno: boolean = false;

  /* Modal */

  @ViewChild("novaLigacao", { static: false }) novaLigacao: ElementRef;

  /* Storage */

  seller_id: number = parseInt(localStorage.getItem("id"));

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private callCenterDiscadorService: CallCenterDiscadorService,
    private clienteService: ClienteService,
    private notaFiscalService: NotaFiscalService,
    private visitaService: VisitaService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    /* Filtro (Clientes) */

    this.formFiltroCliente = this.formBuilder.group({
      filtro: ["3"],
      codigo: ["", Validators.required],
      cnpj_cpf: ["", Validators.required],
      nome_fantasia: ["", [Validators.required, Validators.minLength(3)]],
      razao_social: ["", [Validators.required, Validators.minLength(3)]]
    });

    this.organizarFiltroCliente();

    /* Externo */

      /* Cliente (Externo) */

      let idCliente: string = null;

      this.route.queryParams.subscribe(parametros => { idCliente = parametros["cliente"] });

      if (idCliente != null) {
        this.clienteSelecionadoExterno = idCliente;
      }

      /* Cliente Ligação (Externo) */

      let ligacao: string = "false";

      this.route.queryParams.subscribe(parametros => { ligacao = parametros["ligacao"] });

      if (ligacao != null) {
        this.clienteLigacaoSelecionadoExterno = (ligacao == "true");
      }

      /* Cliente WhatsApp (Externo) */

      let whatsApp: string = "false";

      this.route.queryParams.subscribe(parametros => { whatsApp = parametros["whatsapp"] });

      if (whatsApp != null) {
        this.clienteWhatsAppSelecionadoExterno = (whatsApp == "true");
      }

    this.carregarDados();
  }

  get fc() {
    return this.formFiltroCliente.controls;
  }

  /* Call Center (Tipos) */

  selecionarTipo(id_tipo: string) {
    this.tipoSelecionado = this.tipos.find(tipos => tipos.id == parseInt(id_tipo));
  }

  /* Dados */

  carregarDados() {
    /* Call Center (Configurações) */

    this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
      callCenterConfiguracao => this.callCenterConfiguracao = callCenterConfiguracao,
      error => { console.log("Erro: " + error) },
      () => {
        /* Call Center (Tipos) */

        this.callCenterService.getTipo().subscribe(
          tipos => this.tipos = tipos,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.tipos.length > 0) {
              this.tipoSelecionado = this.tipos[0];
            }

            this.verificarClienteExterno();
          }
        );
      }
    );
  }

  /* Cliente */

  verificarClienteExterno() {
    if (this.clienteLigacaoSelecionadoExterno) {
      /* Cliente Ligação (Externo) */

      if (this.tipos.length > 0) {
        this.tipoSelecionado = this.tipos.find(tipo => tipo.id == 1); /* Recebida */

        if (this.tipoSelecionado == null) {
          this.tipoSelecionado = this.tipos[0];
        }
      }
    } else if (this.clienteWhatsAppSelecionadoExterno) {
      /* Cliente WhatsApp (Externo) */

      if (this.tipos.length > 0) {
        this.tipoSelecionado = this.tipos.find(tipo => tipo.id == 3); /* WhatsApp */

        if (this.tipoSelecionado == null) {
          this.tipoSelecionado = this.tipos[0];
        }
      }
    }

    if (this.clienteSelecionadoExterno != "") {
      this.filtrarCliente();
    } else {
      this.modalNovaLigacao(this.novaLigacao);
    }
  }

  /* Ligação */

  addLigacao() {
    this.prepararEnviarDadosLigacao();

    let adicionarLigacao: boolean = false;

    this.callCenterService.addLigacao(this.ligacao).subscribe(
      adicionarLigacoes => adicionarLigacao = adicionarLigacoes,
      error => { console.log("Erro: " + error) },
      () => {
        if (adicionarLigacao) {
          this.callCenterService.getUltimaLigacaoByCliente(this.ligacao.client_id).subscribe(
            ligacoes => this.ligacao = ligacoes,
            error => { console.log("Erro: " + error) },
            () => {
              if (this.ligacao != null) {
                this.addVisita();
              }
            }
          );
        }
      }
    );
  }

  prepararEnviarDadosLigacao() {
    /* Ligação */

    let ligacao = new CallCenterLigacaoAnalitica();

    ligacao.client_id = this.clienteSelecionado.client_id_app;
    ligacao.seller_id = this.clienteSelecionado.seller_id;
    ligacao.manual = true;
    ligacao.tmp = 1;
    ligacao.id_tmk = this.seller_id;
    ligacao.tipo = this.tipoSelecionado.id_tipo;
    ligacao.id_status = 5; /* Não Comprou */
    ligacao.data_ligacao = new Date(this.datePipe.transform(Date.now(), "yyyy/MM/dd HH:mm:ss", "GMT-6"));

    this.ligacao = ligacao;
  }

  verificarLigacaoExistente() {
    let hojeLigacaoCliente: CallCenterLigacaoAnalitica[] = null;

    this.callCenterService.getHojeByCliente(this.clienteSelecionado.client_id_app).subscribe(
      ligacoesCliente => hojeLigacaoCliente = ligacoesCliente,
      error => { console.log("Erro: " + error) },
      () => {
        let ligacaoExistente: boolean = false;

        for (var i = 0; i < hojeLigacaoCliente.length; i++) {
          if (hojeLigacaoCliente[i].id_status != 4 && hojeLigacaoCliente[i].id_status != 5) {
            ligacaoExistente = true;

            /* Storage */

            let chamadas_automaticas: boolean = (localStorage.getItem("chamadas_automaticas") == "true");

            if (chamadas_automaticas) {
              localStorage.setItem("discador_ativo", "true");
            }

            this.router.navigate(["/call-center-ligacao/" + hojeLigacaoCliente[i].id]);

            break;
          }
        }

        if (!ligacaoExistente) {
          this.addLigacao();
        }
      }
    );
  }

  /* Visita */

  addVisita() {
    this.visitaService.getProximoNumeroVisitaBySeller().subscribe(
      nroVisita => this.nroVisita = nroVisita,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.nroVisita > 0) {
          this.prepararEnviarDadosVisita();

          let adicionarVisita: boolean = false;

          this.visitaService.add(this.visita).subscribe(
            adicionarVisitas => adicionarVisita = adicionarVisitas,
            error => { console.log("Erro: " + error) },
            () => {
              if (adicionarVisita) {
                this.visitaService.getUltimaVisitaByCliente(this.visita.client_id).subscribe(
                  visitas => this.visita = visitas,
                  error => { console.log("Erro: " + error) },
                  () => {
                    if (this.visita != null) {
                      /* Storage */

                      let chamadas_automaticas: boolean = (localStorage.getItem("chamadas_automaticas") == "true");

                      if (chamadas_automaticas) {
                        localStorage.setItem("discador_ativo", "true");
                      }

                      this.router.navigate(["/call-center-ligacao/" + this.ligacao.id], {
                        queryParams: {
                          visita: this.visita.id
                        }
                      });
                    }
                  }
                );
              }
            }
          );
        }
      }
    );
  }

  prepararEnviarDadosVisita() {
    /* Visita */

    let visita = new Visita();

    visita.client_id = this.clienteSelecionado.client_id_app;
    visita.check_in_distance_in_meters = 0;
    visita.no_order_done_reason_id = null;
    visita.note = null;
    visita.is_active = true;
    visita.seller_id = this.seller_id;
    visita.visit_id_app = this.nroVisita;
    visita.callcenter_tipo = this.tipoSelecionado.id_tipo;
    visita.callcenter_status = 5; /* Não Comprou */
    visita.id_call_center_ligacao = this.ligacao.id;

    this.visita = visita;
  }

  /* Filtro (Clientes) */

  alterarFiltroCliente(filtro_selecionado: number) {
    this.reiniciarDadosCliente();

    let filtroAtual: string = this.formFiltroCliente.controls["filtro"].value;

    this.formFiltroCliente.reset();

    if (filtro_selecionado == null) {
      this.formFiltroCliente.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltroCliente.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltroCliente();

    this.enviadoCliente = false;
    this.filtrandoCliente = false;
  }

  filtrarCliente() { /* aqui */
    this.enviadoCliente = true;

    let filtrar = new ClienteFiltrar();

    if (this.clienteSelecionadoExterno == "") {
      if (this.formFiltroCliente.invalid) {
        return;
      }

      if (this.formFiltroCliente.controls["filtro"].value == "0") {
        /* Código */

        if (this.formFiltroCliente.controls["codigo"].value == "" || this.formFiltroCliente.controls["codigo"].value == null) {
          return;
        }
      } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
        /* CNPJ/CPF */

        if (this.formFiltroCliente.controls["cnpj_cpf"].value == "" || this.formFiltroCliente.controls["cnpj_cpf"].value == null) {
          return;
        }
      } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
        /* Nome Fantasia */

        if (this.formFiltroCliente.controls["nome_fantasia"].value == "" || this.formFiltroCliente.controls["nome_fantasia"].value == null) {
          return;
        }
      } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
        /* Razão Social */

        if (this.formFiltroCliente.controls["razao_social"].value == "" || this.formFiltroCliente.controls["razao_social"].value == null) {
          return;
        }
      }

      if (this.formFiltroCliente.controls["filtro"].value == "0") {
        /* Código */

        filtrar.codigo = this.formFiltroCliente.controls["codigo"].value;
      } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
        /* CNPJ/CPF */

        let cnpj_cpf = this.formFiltroCliente.controls["cnpj_cpf"].value.trim().split(".").join("").split("-").join("").split("/").join("");

        filtrar.cnpj_cpf = cnpj_cpf;
      } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
        /* Nome Fantasia */

        filtrar.nome_fantasia = this.formFiltroCliente.controls["nome_fantasia"].value;
      } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
        /* Razão Social */

        filtrar.razao_social = this.formFiltroCliente.controls["razao_social"].value;
      }
    }

    this.clientes = [];

    /* Filtro (Clientes) */

    if (this.callCenterConfiguracao != null) {
      if (this.clienteSelecionadoExterno != "") {
        this.clienteService.getByCodigoERPCliente(this.clienteSelecionadoExterno).subscribe(
          cliente => this.clienteSelecionado = cliente,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.clienteSelecionado != null) {
              this.clienteSelecionado.name = this.clienteSelecionado.name.trim().toUpperCase();
              this.clienteSelecionado.endereco_completo = "";

              if (this.clienteSelecionado.tipo_logradouro != null) {
                this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + this.clienteSelecionado.tipo_logradouro.trim();
              }

              if (this.clienteSelecionado.endereco != null) {
                this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + " " + this.clienteSelecionado.endereco.trim();
              }

              if (this.clienteSelecionado.end_numero != null) {
                this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + ", " + this.clienteSelecionado.end_numero.trim();
              }

              if (this.clienteSelecionado.cidade != null) {
                this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo + " / " + this.clienteSelecionado.cidade.trim();
              }

              this.clienteSelecionado.endereco_completo = this.clienteSelecionado.endereco_completo.trim().toUpperCase();

              this.clientes.push(this.clienteSelecionado);
            }

            this.selecionarClienteFiltrado(this.clienteSelecionado.codigo_no_erp_do_cliente);
          }
        );

        this.modalNovaLigacao(this.novaLigacao);
      } else {
        if (this.callCenterConfiguracao.novaligacao_vertodosclientes) {
          this.clienteService.getByGuidClienteNapis(filtrar).subscribe(
            clientes => this.clientes = clientes,
            error => { console.log("Erro: " + error) },
            () => {
              this.clientes.forEach((item) => {
                item.name = item.name.trim().toUpperCase();
                item.endereco_completo = "";

                if (item.tipo_logradouro != null) {
                  item.endereco_completo = item.endereco_completo + item.tipo_logradouro.trim();
                }

                if (item.endereco != null) {
                  item.endereco_completo = item.endereco_completo + " " + item.endereco.trim();
                }

                if (item.end_numero != null) {
                  item.endereco_completo = item.endereco_completo + ", " + item.end_numero.trim();
                }

                if (item.cidade != null) {
                  item.endereco_completo = item.endereco_completo + " / " + item.cidade.trim();
                }

                item.endereco_completo = item.endereco_completo.trim().toUpperCase();
              });

              this.filtrandoCliente = true;
            }
          );
        } else {
          this.clienteService.getByUsuario(false, filtrar).subscribe(
            clientes => this.clientes = clientes,
            error => { console.log("Erro: " + error) },
            () => {
              this.clientes.forEach((item) => {
                item.name = item.name.trim().toUpperCase();
                item.endereco_completo = "";

                if (item.tipo_logradouro != null) {
                  item.endereco_completo = item.endereco_completo + item.tipo_logradouro.trim();
                }

                if (item.endereco != null) {
                  item.endereco_completo = item.endereco_completo + " " + item.endereco.trim();
                }

                if (item.end_numero != null) {
                  item.endereco_completo = item.endereco_completo + ", " + item.end_numero.trim();
                }

                if (item.cidade != null) {
                  item.endereco_completo = item.endereco_completo + " / " + item.cidade.trim();
                }

                item.endereco_completo = item.endereco_completo.trim().toUpperCase();
              });

              this.filtrandoCliente = true;
            }
          );
        }
      }
    }
  }

  organizarFiltroCliente() {
    if (this.formFiltroCliente.controls["filtro"].value == "0") {
      /* Código */

      this.formFiltroCliente.get("codigo").enable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
      /* CNPJ/CPF */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").enable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
      /* Nome Fantasia */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").enable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
      /* Razão Social */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").enable();
    }
  }

  reiniciarDadosCliente() {
    this.clientes = [];
  }

  selecionarClienteFiltrado(cliente_selecionado: string) {
    if (cliente_selecionado != null && cliente_selecionado != "") {
      let clienteSelecionado: Cliente = this.clientes.find(clientes => clientes.codigo_no_erp_do_cliente == cliente_selecionado);

      if (clienteSelecionado != null) {
        /* Inadimplente */

        if (clienteSelecionado.status_financeiro != null) {
          this.toastr.warning("", "Cliente c/ inadimplência!");
        }

        this.clienteSelecionado = clienteSelecionado;

        this.alterarFiltroCliente(null);

        this.clienteInvalido = false;
        this.filtrandoCliente = false;
      }
    } else {
      this.toastr.error("", "Houve um erro, contate o suporte...");
    }
  }

  /* Modal */

  modalNotasFiscaisSinteticas(content: any, client_document: string) {
    client_document = client_document.replace(/[.]/g, "").replace(/[/]/g, "").replace(/[-]/g, "");

    this.notaFiscalService.getNotaFiscalSinteticaByCNPJ(client_document).subscribe(
      notaFiscal => this.notasFiscais = notaFiscal,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.notasFiscais.length > 0) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-notas-fiscais-sinteticas",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhuma nota fiscal encontrada!");
        }
      }
    );
  }

  modalNovaLigacao(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-nova-ligacao",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Cancelar") {
        this.router.navigate(["/call-center"]);

        this.callCenterDiscadorService.chamadasReiniciadas();
      } else if (acao == "Iniciar") {
        if (this.clienteSelecionado == null) {
          this.clienteInvalido = true;

          this.toastr.error("", "Cliente em branco!");

          this.modalNovaLigacao(content);
        } else {
          this.verificarLigacaoExistente();
        }
      }
    }, (reacao) => {
      if (reacao != null) {
        this.router.navigate(["/call-center"]);

        this.callCenterDiscadorService.chamadasReiniciadas();
      }
    });
  }
}
