export class ContaCorrenteExtrato {
  public id: number;
  public seller_id: number;
  public data_transacao: Date;
  public valor_transacao: number;
  public detalhes_transacao: string;
  public saldo_final: number;
  public order_id: number;
  public validado_faturamento: boolean;
  public id_conta_corrente_flex_categoria: number;
  public deposito: boolean;
  public nome_categoria: string;

  constructor() { }
}
