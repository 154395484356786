import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { DashboardQuilometragemDespesaDespesa } from '../../../models/dashboard/quilometragem-despesa/dashboard-quilometragem-despesa-despesa';
import { DashboardQuilometragemDespesaQuilometragem } from '../../../models/dashboard/quilometragem-despesa/dashboard-quilometragem-despesa-quilometragem';

@Injectable({
  providedIn: 'root'
})

export class DashboardQuilometragemDespesaService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Despesa */

  public getDespesaByVendedor(vendedor: number, dt_inicial: Date, dt_final: Date): Observable<DashboardQuilometragemDespesaDespesa[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "vendedor": vendedor,
      "dt_inicial": dt_inicial,
      "dt_final": dt_final
    };

    return this.http.post<DashboardQuilometragemDespesaDespesa[]>(this.baseUrl + "api/DashboardQuilometragemDespesa/GetDespesaByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateAprovadaById(id: number, aprovar: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "aprovar": aprovar
    };

    return this.http.post<boolean>(this.baseUrl + "api/DashboardQuilometragemDespesa/UpdateAprovadaById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateIsOpenById(id: number, aberto: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "aberto": aberto
    };

    return this.http.post<boolean>(this.baseUrl + "api/DashboardQuilometragemDespesa/UpdateIsOpenById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Quilometragem */

  public getQuilometragemByVendedor(vendedor: number, dt_inicial: Date, dt_final: Date): Observable<DashboardQuilometragemDespesaQuilometragem[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "vendedor": vendedor,
      "dt_inicial": dt_inicial,
      "dt_final": dt_final
    };

    return this.http.post<DashboardQuilometragemDespesaQuilometragem[]>(this.baseUrl + "api/DashboardQuilometragemDespesa/GetQuilometragemByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
