import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { ConfiguracaoService } from '../../core/services/configuracoes/configuracao.service';
import { LiberacaoDescontoService } from '../../core/services/liberacao-descontos/liberacao-desconto.service';

/* Models */

import { CallCenterConfiguracao } from '../../core/models/call-center/call-center-configuracao';
import { Configuracao } from '../../core/models/configuracoes/configuracao';
import { LiberacaoDesconto } from '../../core/models/liberacao-descontos/liberacao-desconto';

@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ConfiguracoesComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Dados */

    /* Call Center (Configuração) */

    callCenterConfiguracao: CallCenterConfiguracao = null;

    /* Configurações */

    configuracao: Configuracao = null;

    /* Liberação Desconto */

    liberacaoDesconto: LiberacaoDesconto = null;

  arteRodape: string = "";
  arteRodapeErro: boolean = false;
  arteRodapeInvalida: boolean = false;

  logomarca: string = "";
  logomarcaErro: boolean = false;
  logomarcaInvalida: boolean = false;

  combinacaoPedidoSelecionado: string = "E";

  pedidoSemEstoqueSelecionado: string = "S";

  pedidoClienteInadimplenteSelecionado: string = "S";

  /* Formulário */

  formDados: FormGroup;

  enviado: boolean = false;

  @ViewChild("arquivoArteRodape", { static: false }) arquivoArteRodape: ElementRef;
  @ViewChild("arquivoLogomarca", { static: false }) arquivoLogomarca: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private configuracaoService: ConfiguracaoService,
    private liberacaoDescontoService: LiberacaoDescontoService
  ) { }

  ngOnInit() {
    this.formDados = this.formBuilder.group({
      razao_social: ["", Validators.required],
      tipo_pagamento: [""],
      banco: [""],
      agencia: [""],
      conta: [""],
      qtde_ligacoes_vendedor_interno: [""],
      qtde_dias_sem_visita_gerar_ligacao: [""],
      qtde_dias_sem_pedido_gerar_ligacao: [""],
      percentual_clientes_ativos: [""],
      percentual_clientes_inativos: [""],
      percentual_clientes_sem_compra: [""],
      qtde_dias_analise: [""],
      qtde_visitas_sem_pedido_no_periodo: [""],
      percentual_permitido_desconto_vendedor: [""],
      percentual_permitido_desconto_gestor: [""],
      margem_lucratividade_vermelha: [""],
      margem_lucratividade_verde: [""],
    });

    this.carregando = true;

    const prepararReceberDados = this.prepararReceberDados();

    prepararReceberDados.then(() => {
      this.carregando = false;
    });
  }

  get f() {
    return this.formDados.controls;
  }

  /* Dados */

  addUpdateCallCenterConfiguracao() {
    let retorno = new Promise<void>((resolver) => {
      let callCenterConfiguracao = new CallCenterConfiguracao();

      callCenterConfiguracao.arte_rodape = this.arteRodape;
      callCenterConfiguracao.logotipo = this.logomarca;
      callCenterConfiguracao.numero_ligacoes_por_dia = this.formDados.controls["qtde_ligacoes_vendedor_interno"].value;
      callCenterConfiguracao.vv_visita_dias = this.formDados.controls["qtde_dias_sem_visita_gerar_ligacao"].value;
      callCenterConfiguracao.vv_visitas_logica = this.combinacaoPedidoSelecionado;
      callCenterConfiguracao.vv_venda_dias = this.formDados.controls["qtde_dias_sem_pedido_gerar_ligacao"].value;
      callCenterConfiguracao.perc_ativo = this.formDados.controls["percentual_clientes_ativos"].value;
      callCenterConfiguracao.perc_inativo = this.formDados.controls["percentual_clientes_inativos"].value;
      callCenterConfiguracao.perc_svenda = this.formDados.controls["percentual_clientes_sem_compra"].value;
      callCenterConfiguracao.guidclientenapis = localStorage.getItem("guidclientenapis");
      callCenterConfiguracao.flag_atencao_periodo_dias = this.formDados.controls["qtde_dias_analise"].value;
      callCenterConfiguracao.flag_atencao_qtd_visitas = this.formDados.controls["qtde_visitas_sem_pedido_no_periodo"].value;

      if (this.callCenterConfiguracao != null) {
        /* Alterando */

        let alterarCallCenterConfiguracao: boolean = false;

        this.callCenterService.updateConfiguracao(callCenterConfiguracao).subscribe(
          alterarCallCenterConfiguracoes => alterarCallCenterConfiguracao = alterarCallCenterConfiguracoes,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarCallCenterConfiguracao) {
              return resolver();
            }
          }
        );
      } else {
        /* Adicionado */

        let adicionarCallCenterConfiguracao: boolean = false;

        this.callCenterService.addConfiguracao(callCenterConfiguracao).subscribe(
          adicionarCallCenterConfiguracoes => adicionarCallCenterConfiguracao = adicionarCallCenterConfiguracoes,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarCallCenterConfiguracao) {
              return resolver();
            }
          }
        );
      }
    });

    return retorno;
  }

  addUpdateConfiguracao() {
    let retorno = new Promise<void>((resolver) => {
      let configuracao = new Configuracao();

      configuracao.guidclientenapis = localStorage.getItem("guidclientenapis");
      configuracao.permitir_produtos_sem_estoque = this.pedidoSemEstoqueSelecionado;
      configuracao.permite_lancar_pedido_inadimplente = this.pedidoClienteInadimplenteSelecionado;
      configuracao.margem_lucratividade_vermelha = this.formDados.controls["margem_lucratividade_vermelha"].value;
      configuracao.margem_lucratividade_verde = this.formDados.controls["margem_lucratividade_verde"].value;
      configuracao.nm_empresa = this.formDados.controls["razao_social"].value;
      configuracao.tipo_pagamento = this.formDados.controls["tipo_pagamento"].value;
      configuracao.banco = this.formDados.controls["banco"].value;
      configuracao.agencia = this.formDados.controls["agencia"].value;
      configuracao.conta = this.formDados.controls["conta"].value;

      if (this.configuracao != null) {
        /* Alterando */

        let alterarConfiguracao: boolean = false;

        this.configuracaoService.update(configuracao).subscribe(
          alterarConfiguracoes => alterarConfiguracao = alterarConfiguracoes,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarConfiguracao) {
              return resolver();
            }
          }
        );
      } else {
        /* Adicionado */

        let adicionarConfiguracao: boolean = false;

        this.configuracaoService.add(configuracao).subscribe(
          adicionarConfiguracoes => adicionarConfiguracao = adicionarConfiguracoes,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarConfiguracao) {
              return resolver();
            }
          }
        );
      }
    });

    return retorno;
  }

  addUpdateLiberacaoDesconto() {
    let retorno = new Promise<void>((resolver) => {
      let liberacaoDesconto = new LiberacaoDesconto();

      liberacaoDesconto.guidclientenapis = localStorage.getItem("guidclientenapis");
      liberacaoDesconto.percliberacaovendedor = this.formDados.controls["percentual_permitido_desconto_vendedor"].value;
      liberacaoDesconto.percliberacaogestor = this.formDados.controls["percentual_permitido_desconto_gestor"].value;

      if (this.liberacaoDesconto != null) {
        /* Alterando */

        let alterarLiberacaoDesconto: boolean = false;

        this.liberacaoDescontoService.update(liberacaoDesconto).subscribe(
          alterarLiberacaoDescontos => alterarLiberacaoDesconto = alterarLiberacaoDescontos,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarLiberacaoDesconto) {
              return resolver();
            }
          }
        );
      } else {
        /* Adicionado */

        let adicionarLiberacaoDesconto: boolean = false;

        this.liberacaoDescontoService.add(liberacaoDesconto).subscribe(
          adicionarLiberacaoDescontos => adicionarLiberacaoDesconto = adicionarLiberacaoDescontos,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarLiberacaoDesconto) {
              return resolver();
            }
          }
        );
      }
    });

    return retorno;
  }

  prepararReceberDados() {
    let retorno = new Promise<void>((resolver) => {
      /* Call Center (Configurações) */

      this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
        callCenterConfiguracao => this.callCenterConfiguracao = callCenterConfiguracao,
        error => { console.log("Erro: " + error) },
        () => {
          /* Configurações */

          this.configuracaoService.getByGuidClienteNapis().subscribe(
            configuracoes => this.configuracao = configuracoes,
            error => { console.log("Erro: " + error) },
            () => {
              /* Desconto */

              this.liberacaoDescontoService.getByGuidClienteNapis().subscribe(
                liberacaoDesconto => this.liberacaoDesconto = liberacaoDesconto,
                error => { console.log("Erro: " + error) },
                () => {
                  if (this.callCenterConfiguracao != null) {
                    /* Dados */

                    this.arteRodape = this.callCenterConfiguracao.arte_rodape;
                    this.logomarca = this.callCenterConfiguracao.logotipo;

                    /* Call-Center (Gerar Ligações) */

                    this.formDados.controls["qtde_ligacoes_vendedor_interno"].setValue(this.callCenterConfiguracao.numero_ligacoes_por_dia);
                    this.formDados.controls["qtde_dias_sem_visita_gerar_ligacao"].setValue(this.callCenterConfiguracao.vv_visita_dias);

                    this.combinacaoPedidoSelecionado = this.callCenterConfiguracao.vv_visitas_logica;

                    this.formDados.controls["qtde_dias_sem_pedido_gerar_ligacao"].setValue(this.callCenterConfiguracao.vv_venda_dias);
                    this.formDados.controls["percentual_clientes_ativos"].setValue(this.callCenterConfiguracao.perc_ativo);
                    this.formDados.controls["percentual_clientes_inativos"].setValue(this.callCenterConfiguracao.perc_inativo);
                    this.formDados.controls["percentual_clientes_sem_compra"].setValue(this.callCenterConfiguracao.perc_svenda);

                    /* Call-Center (Atenção) */

                    this.formDados.controls["qtde_dias_analise"].setValue(this.callCenterConfiguracao.flag_atencao_periodo_dias);
                    this.formDados.controls["qtde_visitas_sem_pedido_no_periodo"].setValue(this.callCenterConfiguracao.flag_atencao_qtd_visitas);
                  }

                  if (this.configuracao != null) {
                    /* Dados */

                    this.formDados.controls["razao_social"].setValue(this.configuracao.nm_empresa);

                    /* Dados (Bancários) */

                    this.formDados.controls["tipo_pagamento"].setValue(this.configuracao.tipo_pagamento);
                    this.formDados.controls["banco"].setValue(this.configuracao.banco);
                    this.formDados.controls["agencia"].setValue(this.configuracao.agencia);
                    this.formDados.controls["conta"].setValue(this.configuracao.conta);

                    /* Pedidos */

                    this.pedidoSemEstoqueSelecionado = this.configuracao.permitir_produtos_sem_estoque;

                    this.pedidoClienteInadimplenteSelecionado = this.configuracao.permite_lancar_pedido_inadimplente;

                    this.formDados.controls["margem_lucratividade_vermelha"].setValue(this.configuracao.margem_lucratividade_vermelha);
                    this.formDados.controls["margem_lucratividade_verde"].setValue(this.configuracao.margem_lucratividade_verde);
                  }

                  if (this.liberacaoDesconto != null) {
                    /* Pedidos */

                    this.formDados.controls["percentual_permitido_desconto_vendedor"].setValue(this.liberacaoDesconto.percliberacaovendedor);
                    this.formDados.controls["percentual_permitido_desconto_gestor"].setValue(this.liberacaoDesconto.percliberacaogestor);
                  }

                  return resolver();
                }
              );
            }
          );
        }
      );
    });

    return retorno;
  }

  /* Formulário */

  salvar() {
    this.enviado = true;

    /* Call Center (Configuração) */

    let qtde_ligacoes_vendedor_interno: string = this.formDados.controls["qtde_ligacoes_vendedor_interno"].value;

    if (qtde_ligacoes_vendedor_interno == "" || qtde_ligacoes_vendedor_interno == null || parseInt(qtde_ligacoes_vendedor_interno) < 0) {
      this.formDados.controls["qtde_ligacoes_vendedor_interno"].setErrors({ "invalid": true });
    }

    let qtde_dias_sem_visita_gerar_ligacao: string = this.formDados.controls["qtde_dias_sem_visita_gerar_ligacao"].value;

    if (qtde_dias_sem_visita_gerar_ligacao == "" || qtde_dias_sem_visita_gerar_ligacao == null || parseInt(qtde_dias_sem_visita_gerar_ligacao) < 0) {
      this.formDados.controls["qtde_dias_sem_visita_gerar_ligacao"].setErrors({ "invalid": true });
    }

    let qtde_dias_sem_pedido_gerar_ligacao: string = this.formDados.controls["qtde_dias_sem_pedido_gerar_ligacao"].value;

    if (qtde_dias_sem_pedido_gerar_ligacao == "" || qtde_dias_sem_pedido_gerar_ligacao == null || parseInt(qtde_dias_sem_pedido_gerar_ligacao) < 0) {
      this.formDados.controls["qtde_dias_sem_pedido_gerar_ligacao"].setErrors({ "invalid": true });
    }

    this.validarPercentualCliente();

    let qtde_dias_analise: string = this.formDados.controls["qtde_dias_analise"].value;

    if (qtde_dias_analise == "" || qtde_dias_analise == null || parseInt(qtde_dias_analise) < 0) {
      this.formDados.controls["qtde_dias_analise"].setErrors({ "invalid": true });
    }

    let qtde_visitas_sem_pedido_no_periodo: string = this.formDados.controls["qtde_visitas_sem_pedido_no_periodo"].value;

    if (qtde_visitas_sem_pedido_no_periodo == "" || qtde_visitas_sem_pedido_no_periodo == null || parseInt(qtde_visitas_sem_pedido_no_periodo) < 0) {
      this.formDados.controls["qtde_visitas_sem_pedido_no_periodo"].setErrors({ "invalid": true });
    }

    /* Configuração */

    this.validarMargemLucratividade();

    /* Liberação Desconto */

    this.validarPercentualGestorVendedor();

    if (this.formDados.invalid) {
      return;
    }

    /* Call Center (Configuração) */

    const addUpdateCallCenterConfiguracao = this.addUpdateCallCenterConfiguracao();

    addUpdateCallCenterConfiguracao.then(() => {
      /* Configuração */

      const addUpdateConfiguracao = this.addUpdateConfiguracao();

      addUpdateConfiguracao.then(() => {
        /* Liberação Desconto */

        const addUpdateLiberacaoDesconto = this.addUpdateLiberacaoDesconto();

        addUpdateLiberacaoDesconto.then(() => {
          this.toastr.success("", "Configuração salva com sucesso!");

          const prepararReceberDados = this.prepararReceberDados();

          this.carregando = true;

          prepararReceberDados.then(() => {
            this.carregando = false;
          });
        });
      });
    });
  }

  selecionarCombinacaoPedido(combinacao_pedido: string) {
    this.combinacaoPedidoSelecionado = combinacao_pedido;
  }

  selecionarPedidoSemEstoque(pedido_sem_estoque: string) {
    this.pedidoSemEstoqueSelecionado = pedido_sem_estoque;
  }

  selecionarPedidoClienteInadimplente(pedido_cliente_inadimplente: string) {
    this.pedidoClienteInadimplenteSelecionado = pedido_cliente_inadimplente;
  }

  validarMargemLucratividade() {
    if (this.enviado) {
      let margem_lucratividade_vermelha: number = this.formDados.controls["margem_lucratividade_vermelha"].value == "" || this.formDados.controls["margem_lucratividade_vermelha"].value == null ? 0 : this.formDados.controls["margem_lucratividade_vermelha"].value;

      if (margem_lucratividade_vermelha <= 0 || margem_lucratividade_vermelha > 100) {
        this.formDados.controls["margem_lucratividade_vermelha"].setErrors({ "invalid": true });
      } else {
        this.formDados.controls["margem_lucratividade_vermelha"].setErrors(null);
      }

      let margem_lucratividade_verde: number = this.formDados.controls["margem_lucratividade_verde"].value == "" || this.formDados.controls["margem_lucratividade_verde"].value == null ? 0 : this.formDados.controls["margem_lucratividade_verde"].value;

      if (margem_lucratividade_verde <= 0 || margem_lucratividade_verde > 100) {
        this.formDados.controls["margem_lucratividade_verde"].setErrors({ "invalid": true });
      } else {
        this.formDados.controls["margem_lucratividade_verde"].setErrors(null);
      }

      if (margem_lucratividade_verde > 0 && (margem_lucratividade_verde <= margem_lucratividade_vermelha)) {
        this.formDados.controls["margem_lucratividade_vermelha"].setErrors({ "incorrect": true });
        this.formDados.controls["margem_lucratividade_verde"].setErrors({ "incorrect": true });
      } else {
        this.formDados.controls["margem_lucratividade_vermelha"].setErrors(null);
        this.formDados.controls["margem_lucratividade_verde"].setErrors(null);
      }
    }
  }

  validarPercentualCliente() {
    if (this.enviado) {
      let percentual_clientes_ativos: number = this.formDados.controls["percentual_clientes_ativos"].value == "" || this.formDados.controls["percentual_clientes_ativos"].value == null ? 0 : this.formDados.controls["percentual_clientes_ativos"].value;

      if (percentual_clientes_ativos < 0 || percentual_clientes_ativos > 100) {
        this.formDados.controls["percentual_clientes_ativos"].setErrors({ "invalid": true });
      } else {
        this.formDados.controls["percentual_clientes_ativos"].setErrors(null);
      }

      let percentual_clientes_inativos: number = this.formDados.controls["percentual_clientes_inativos"].value == "" || this.formDados.controls["percentual_clientes_inativos"].value == null ? 0 : this.formDados.controls["percentual_clientes_inativos"].value;

      if (percentual_clientes_inativos < 0 || percentual_clientes_inativos > 100) {
        this.formDados.controls["percentual_clientes_inativos"].setErrors({ "invalid": true });
      } else {
        this.formDados.controls["percentual_clientes_inativos"].setErrors(null);
      }

      let percentual_clientes_sem_compra: number = this.formDados.controls["percentual_clientes_sem_compra"].value == "" || this.formDados.controls["percentual_clientes_sem_compra"].value == null ? 0 : this.formDados.controls["percentual_clientes_sem_compra"].value;

      if (percentual_clientes_sem_compra < 0 || percentual_clientes_sem_compra > 100) {
        this.formDados.controls["percentual_clientes_sem_compra"].setErrors({ "invalid": true });
      } else {
        this.formDados.controls["percentual_clientes_sem_compra"].setErrors(null);
      }

      if ((percentual_clientes_ativos + percentual_clientes_inativos + percentual_clientes_sem_compra) != 100) {
        this.formDados.controls["percentual_clientes_ativos"].setErrors({ "incorrect": true });
        this.formDados.controls["percentual_clientes_inativos"].setErrors({ "incorrect": true });
        this.formDados.controls["percentual_clientes_sem_compra"].setErrors({ "incorrect": true });
      } else {
        this.formDados.controls["percentual_clientes_ativos"].setErrors(null);
        this.formDados.controls["percentual_clientes_inativos"].setErrors(null);
        this.formDados.controls["percentual_clientes_sem_compra"].setErrors(null);
      }
    }
  }

  validarPercentualGestorVendedor() {
    if (this.enviado) {
      let percentual_permitido_desconto_vendedor: number = this.formDados.controls["percentual_permitido_desconto_vendedor"].value == "" || this.formDados.controls["percentual_permitido_desconto_vendedor"].value == null ? 0 : this.formDados.controls["percentual_permitido_desconto_vendedor"].value;

      if (percentual_permitido_desconto_vendedor <= 0 || percentual_permitido_desconto_vendedor > 100) {
        this.formDados.controls["percentual_permitido_desconto_vendedor"].setErrors({ "invalid": true });
      } else {
        this.formDados.controls["percentual_permitido_desconto_vendedor"].setErrors(null);
      }

      let percentual_permitido_desconto_gestor: number = this.formDados.controls["percentual_permitido_desconto_gestor"].value == "" || this.formDados.controls["percentual_permitido_desconto_gestor"].value == null ? 0 : this.formDados.controls["percentual_permitido_desconto_gestor"].value;

      if (percentual_permitido_desconto_gestor <= 0 || percentual_permitido_desconto_gestor > 100) {
        this.formDados.controls["percentual_permitido_desconto_gestor"].setErrors({ "invalid": true });
      } else {
        this.formDados.controls["percentual_permitido_desconto_gestor"].setErrors(null);
      }

      if (percentual_permitido_desconto_gestor < percentual_permitido_desconto_vendedor) {
        this.formDados.controls["percentual_permitido_desconto_vendedor"].setErrors({ "incorrect": true });
        this.formDados.controls["percentual_permitido_desconto_gestor"].setErrors({ "incorrect": true });
      } else {
        this.formDados.controls["percentual_permitido_desconto_vendedor"].setErrors(null);
        this.formDados.controls["percentual_permitido_desconto_gestor"].setErrors(null);
      }
    }
  }

  /* Logomarca */

  converterImagemLogomarca(imagem: any) {
    this.logomarca = "data:image/png;base64," + btoa(imagem.target.result);
  }

  removerLogomarca() {
    this.arquivoLogomarca.nativeElement.value = "";
    this.logomarca = "";
  }

  uploadLogomarca(logomarca: any) {
    if (logomarca != null) {
      if (logomarca.target.files.length > 0) {
        if (logomarca.target.accept == logomarca.target.files[0].type) {
          const arquivo = logomarca.target.files[0];

          if (arquivo) {
            let tamanho: number = (arquivo.size / (1024 * 1024));

            if (tamanho > 2) {
              /* 2MB */

              this.logomarcaErro = true;

              this.removerLogomarca();
            } else {
              this.logomarcaErro = false;

              const reader = new FileReader();

              reader.onload = this.converterImagemLogomarca.bind(this);

              reader.readAsBinaryString(arquivo);
            }
          }

          this.logomarcaInvalida = false;
        } else {
          this.logomarcaErro = false;
          this.logomarcaInvalida = true;

          this.removerLogomarca();
        }
      }
    }
  }

  /* Arte Rodapé */

  converterImagemArteRodape(imagem: any) {
    this.arteRodape = "data:image/png;base64," + btoa(imagem.target.result);
  }

  removerArteRodape() {
    this.arquivoArteRodape.nativeElement.value = "";
    this.arteRodape = "";
  }

  uploadArteRodape(arte_rodape: any) {
    if (arte_rodape != null) {
      if (arte_rodape.target.files.length > 0) {
        if (arte_rodape.target.accept == arte_rodape.target.files[0].type) {
          const arquivo = arte_rodape.target.files[0];

          if (arquivo) {
            let tamanho: number = (arquivo.size / (1024 * 1024));

            if (tamanho > 2) {
              /* 2MB */

              this.arteRodapeErro = true;

              this.removerArteRodape();
            } else {
              this.arteRodapeErro = false;

              const reader = new FileReader();

              reader.onload = this.converterImagemArteRodape.bind(this);

              reader.readAsBinaryString(arquivo);
            }
          }

          this.arteRodapeInvalida = false;
        } else {
          this.arteRodapeErro = false;
          this.arteRodapeInvalida = true;

          this.removerArteRodape();
        }
      }
    }
  }
}
