import { Injectable } from '@angular/core';

/* Services */

import { FormatacaoService } from './formatacao.service';

@Injectable({
  providedIn: 'root'
})

export class ConversaoService {
  constructor(
    private formatacaoService: FormatacaoService
  ) { }

  /* Base64 */

  base64ParaString(valor: string): string {
    return atob(valor);
  }

  /* Binário */

  arquivoParaBinario(arquivo: File) {
    let retorno = new Promise<string>((resolver) => {
      let reader = new FileReader();

      reader.readAsBinaryString(arquivo);

      reader.onloadend = () => {
        const data = reader.result as string;

        return resolver(data);
      }
    });

    return retorno;
  }

  /* Blob */

  blobParaBase64(blob: Blob): Promise<string> {
    let retorno = new Promise<string>((resolver) => {
      let reader = new FileReader();

      reader.readAsDataURL(blob);

      reader.onloadend = () => {
        let base64: string = reader.result as string;

        return resolver(base64);
      };
    });

    return retorno;
  }

  /* Metragem */

  metrosParaKilometros(valor: number): string {
    if (valor > 1000) {
      let calculo: number = (valor / 1000);

      return this.formatacaoService.tresCasasDecimais(calculo) + " km";
    } else {
      return valor + " m";
    }
  }

  /* Mês por Extenso */

  numeroParaMesPorExtenso(valor: number): string {
    let mesesNomes: string[] = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    return mesesNomes[valor];
  }

  /* Telefone (WhatsApp) */

  telefoneParaTelefoneWhatsApp(telefone: string): string {
    let primeiroDigito: string = telefone.substring(0, 1);

    if (primeiroDigito == "0") {
      telefone = telefone.substring(1, telefone.length);
    }

    let ddi: string = telefone.substring(0, 2);

    if (ddi != "55") {
      telefone = "55" + telefone;
    }

    return telefone;
  }

  /* Tempo */

  decimalParaMinutos(valor: number): string {
    let sinal: string = valor < 0 ? "-" : "";
    let minutos: number = Math.floor(Math.abs(valor));
    let segundos: number = Math.floor((Math.abs(valor) * 60) % 60);

    return sinal + (minutos < 10 ? "0" : "") + minutos + ":" + (segundos < 10 ? "0" : "") + segundos;
  }

  milissegundosParaDias(valor: number): number {
    let calculo: number = (valor / (24 * 60 * 60 * 1000));

    return calculo;
  }

  milissegundosParaMinutos(valor: number): number {
    if (valor > 0) {
      return (valor / (60 * 1000));
    } else {
      return 0;
    }
  }

  /* Zero (Esquerda) */

  zeroEsquerda(valor: string, maximo: number): string {
    valor = valor.toString();

    return valor.length < maximo ? this.zeroEsquerda("0" + valor, maximo) : valor;
  }
}
