export class MotivoNaoVenda {
  public id: number;
  public name: string;
  public is_active: boolean;
  public updated_at: string;
  public created_at: Date;
  public guidclientenapis: string;
  public flag_muda_em_contato: string;
  public id_crm: number;
  public flag_visita_efetiva: boolean;
  public flag_check_out_aberto: boolean;
  public tipo: string;

  constructor() { }
}
