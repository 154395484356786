import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

/* Services */

import { CallCenterService } from '../../../core/services/call-center/call-center.service';
import { ConversaoService } from '../../../core/tools/conversao.service';
import { DashboardCallCenterService } from '../../../core/services/dashboard/call-center/dashboard-call-center.service';
import { ExcelService } from '../../../core/tools/excel.service';

/* Models */

import { CallCenterChamada } from '../../../core/models/call-center/call-center-chamada';
import { CallCenterPausa } from '../../../core/models/call-center/call-center-pausa';
import { DashboardCallCenterLigacao } from '../../../core/models/dashboard/call-center/dashboard-call-center-ligacao';
import { DashboardCallCenterPedido } from '../../../core/models/dashboard/call-center/dashboard-call-center-pedido';

@Component({
  selector: 'app-dashboard-call-center',
  templateUrl: './dashboard-call-center.component.html',
  styleUrls: ['./dashboard-call-center.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class DashboardCallCenterComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Chamadas */

  chamadas: CallCenterChamada[] = [];

  /* Filtros */

  formFiltro: FormGroup;

  filtrado: boolean = false;

  /* Ligações */

  ligacoes: DashboardCallCenterLigacao[] = [];
  ligacoesOriginal: DashboardCallCenterLigacao[] = [];

  /* Pausas */

  pausas: CallCenterPausa[] = [];
  pausasOriginal: CallCenterPausa[] = [];

  pausasSelecionadas: CallCenterPausa[] = [];

  /* Pedidos */

  pedidos: DashboardCallCenterPedido[] = [];
  pedidosOriginal: DashboardCallCenterPedido[] = [];

  /* Ligações (Agrupadas) */

  ligacoesAgrupadasPorEquipe = null;
  ligacoesAgrupadasPorVendedor = null;

  /* Pedidos (Agrupados) */

  pedidosAgrupados = null;
  pedidosAgrupadosPorVendedorExterno = null;

  /* Resultados (Agrupados) */

  resultadosAgrupados = null;

  /* Visitas (Com Check-in) */

  visitasComCheckIn: DashboardCallCenterLigacao[] = [];

  /* Gráfico de Efetividade */

  lineChartOptionsEfetividade: ChartOptions = {
    animation: {
      duration: 0
    },
    responsive: true,
    legend: {
      labels: {
        fontColor: "rgba(0, 0, 0, 1)"
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)"
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        align: "top",
        anchor: "center",
        font: {
          weight: "bold"
        }
      }
    }
  };

  lineChartLabelsEfetividade: Label[] = [];
  lineChartTypeEfetividade: ChartType = "line";
  lineChartLegendEfetividade = true;
  lineChartPluginsEfetividade = [pluginDataLabels];

  lineChartDataEfetividade: ChartDataSets[] = [
    { data: [], label: "" },
    { data: [], label: "" }
  ];

  lineChartColorsEfetividade: Array<any> = [
    {
      backgroundColor: "rgba(40, 167, 69, 0.2)",
      borderColor: "rgba(40, 167, 69, 1)",
      pointBackgroundColor: "rgba(40, 167, 69, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(40, 167, 69, 1)"
    },
    {
      backgroundColor: "rgba(220, 53, 69, 0.2)",
      borderColor: "rgba(220, 53, 69, 1)",
      pointBackgroundColor: "rgba(220, 53, 69, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(220, 53, 69, 0.8)"
    }
  ];

  /* Gráfico de Ligações por Tipo */

  barChartOptionsLigacoes: ChartOptions = {
    animation: {
      duration: 0
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            borderDash: [5],
            lineWidth: 1.4
          },
          ticks: {
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)"
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        align: "top",
        anchor: "start",
        color: "rgba(0, 0, 0, 1)",
        font: {
          weight: "bold"
        }
      }
    }
  };

  barChartLabelsLigacoes: Label[] = [];
  barChartTypeLigacoes: ChartType = "bar";
  barChartLegendLigacoes = false;
  barChartPluginsLigacoes = [pluginDataLabels];

  barChartDataLigacoes: ChartDataSets[] = [
    { data: [] }
  ];

  lineChartColorsLigacoes: Array<any> = [
    {
      backgroundColor: "rgba(138, 0, 230, 1)",
      borderColor: "rgba(138, 0, 230, 1)",
      pointBackgroundColor: "rgba(138, 0, 230, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(138, 0, 230, 1)"
    }
  ];

  /* Acompanhamento */

  qtdeTotalAgendado: number = 0;
  qtdeTotalAgendadoRealizado: number = 0;
  porcentagemQtdeTotalAgendadoRealizado: number = 0;

  qtdeTotalGerado: number = 0;
  qtdeTotalGeradoRealizado: number = 0;
  porcentagemQtdeTotalGeradoRealizado: number = 0;

  qtdeTotalManual: number = 0;

  qtdeTotalRealizado: number = 0;
  porcentagemQtdeTotalRealizado: number = 0;

  qtdeVendaRealizada: number = 0;
  porcentagemQtdeVendaRealizada: number = 0;
  qtdeNaoComprou: number = 0;
  porcentagemQtdeNaoComprou: number = 0;

  qtdePausa: number = 0;
  tempoMedioPorLigacao: string = "";

  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private conversaoService: ConversaoService,
    private dashboardCallCenterService: DashboardCallCenterService,
    private excelService: ExcelService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      dt_inicial: [""],
      dt_final: [""],
      resultado: [""],
      vendedor: [""]
    });

    this.carregarDados();
  }

  /* Dados */

  calcularDados() {
    /* Pedidos */

    if (this.pedidos != null) {
      /* Detalhamento dos Pedidos (120 Dias) */

      /* Por Vendedor */

      this.pedidosAgrupados = this.pedidos.reduce((obj, item) => {
        obj[item.seller_id] = obj[item.seller_id] || [];
        obj[item.seller_id].push(item);

        return obj;
      }, {});

      this.pedidosAgrupados = Object.keys(this.pedidosAgrupados).map((key) => {
        let vendasTotal = this.pedidosAgrupados[key].reduce((sum, current) => sum + current.total_pedido, 0);
        let litragemTotal = this.pedidosAgrupados[key].reduce((sum, current) => sum + current.total_litragem, 0);

        // && pedidoAgrupado.order_id_app.toString().substring(0, 3) != "100"

        let vendasExternas = this.pedidosAgrupados[key].filter(pedidoAgrupado => pedidoAgrupado.master_seller_id == null).reduce((sum, current) => sum + current.total_pedido, 0);
        let litragemExterna = this.pedidosAgrupados[key].filter(pedidoAgrupado => pedidoAgrupado.master_seller_id == null).reduce((sum, current) => sum + current.total_litragem, 0);
        let porcentagemVendasExternas = vendasExternas > 0 ? ((vendasExternas / vendasTotal) * 100) : 0;

        // || pedidoAgrupado.order_id_app.toString().substring(0, 3) == "100"

        let vendasInternas = this.pedidosAgrupados[key].filter(pedidoAgrupado => pedidoAgrupado.master_seller_id != null).reduce((sum, current) => sum + current.total_pedido, 0);
        let litragemInterna = this.pedidosAgrupados[key].filter(pedidoAgrupado => pedidoAgrupado.master_seller_id != null).reduce((sum, current) => sum + current.total_litragem, 0);
        let porcentagemVendasInternas = vendasInternas > 0 ? ((vendasInternas / vendasTotal) * 100) : 0;

        return {
          vendedor: this.pedidosAgrupados[key][0].fullname,

          vendas_total: vendasTotal,
          litragem_total: litragemTotal,

          vendas_externas: vendasExternas,
          litragem_externa: litragemExterna,
          porcentagem_vendas_externas: porcentagemVendasExternas,

          vendas_internas: vendasInternas,
          litragem_interna: litragemInterna,
          porcentagem_vendas_internas: porcentagemVendasInternas
        };
      }).sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.vendas_total, b.vendas_total) * -1; /* Decrescente */
      });

      /* Por Vendedor + Por Equipe */

      this.pedidosAgrupadosPorVendedorExterno = this.pedidos.reduce((obj, item) => {
        let chave: string = item.seller_id + "-" + item.master_seller_id;

        obj[chave] = obj[chave] || [];
        obj[chave].push(item);

        return obj;
      }, {});

      this.pedidosAgrupadosPorVendedorExterno = Object.keys(this.pedidosAgrupadosPorVendedorExterno).map((key) => {
        let vendasTotal = this.pedidosAgrupadosPorVendedorExterno[key].reduce((sum, current) => sum + current.total_pedido, 0);
        let litragemTotal = this.pedidosAgrupadosPorVendedorExterno[key].reduce((sum, current) => sum + current.total_litragem, 0);

        // && pedidoAgrupadoPorVendedorExterno.order_id_app.toString().substring(0, 3) != "100"

        let vendasExternas = this.pedidosAgrupadosPorVendedorExterno[key].filter(pedidoAgrupadoPorVendedorExterno => pedidoAgrupadoPorVendedorExterno.master_seller_id == null).reduce((sum, current) => sum + current.total_pedido, 0);
        let litragemExterna = this.pedidosAgrupadosPorVendedorExterno[key].filter(pedidoAgrupadoPorVendedorExterno => pedidoAgrupadoPorVendedorExterno.master_seller_id == null).reduce((sum, current) => sum + current.total_litragem, 0);
        let porcentagemVendasExternas = vendasExternas > 0 ? ((vendasExternas / vendasTotal) * 100) : 0;

        // || pedidoAgrupadoPorVendedorExterno.order_id_app.toString().substring(0, 3) == "100"

        let vendasInternas = this.pedidosAgrupadosPorVendedorExterno[key].filter(pedidoAgrupadoPorVendedorExterno => pedidoAgrupadoPorVendedorExterno.master_seller_id != null).reduce((sum, current) => sum + current.total_pedido, 0);
        let litragemInterna = this.pedidosAgrupadosPorVendedorExterno[key].filter(pedidoAgrupadoPorVendedorExterno => pedidoAgrupadoPorVendedorExterno.master_seller_id != null).reduce((sum, current) => sum + current.total_litragem, 0);
        let porcentagemVendasInternas = vendasInternas > 0 ? ((vendasInternas / vendasTotal) * 100) : 0;

        return {
          chave: key,

          vendas_total: vendasTotal,
          litragem_total: litragemTotal,

          vendas_externas: vendasExternas,
          litragem_externa: litragemExterna,
          porcentagem_vendas_externas: porcentagemVendasExternas,

          vendas_internas: vendasInternas,
          litragem_interna: litragemInterna,
          porcentagem_vendas_internas: porcentagemVendasInternas
        };
      }).sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.vendas_total, b.vendas_total) * -1; /* Decrescente */
      });
    }

    /* Ligações */

    if (this.ligacoes != null) {
      /* Acompanhamento */

      /* Agendado */

      let agendado: DashboardCallCenterLigacao[] = this.ligacoes.filter(ligacao => !ligacao.manual && !ligacao.lista);

      this.qtdeTotalAgendado = agendado.length;
      this.qtdeTotalAgendadoRealizado = agendado.filter(ligacao => ligacao.id_status != 1 && ligacao.id_status != 6 && ligacao.check_in != null && ligacao.check_out != null).length;

      /* Gerado */
      
      let gerado: DashboardCallCenterLigacao[] = this.ligacoes.filter(ligacao => !ligacao.manual && ligacao.lista);

      this.qtdeTotalGerado = gerado.length;
      this.qtdeTotalGeradoRealizado = gerado.filter(ligacao => ligacao.id_status != 1 && ligacao.id_status != 6 && ligacao.check_in != null && ligacao.check_out != null).length;

      /* Manual */

      let manual: DashboardCallCenterLigacao[] = this.ligacoes.filter(ligacao => ligacao.manual && !ligacao.lista && ligacao.check_in != null && ligacao.check_out != null);

      this.qtdeTotalManual = manual.length;

      this.qtdeTotalRealizado = this.qtdeTotalAgendadoRealizado + this.qtdeTotalGeradoRealizado + this.qtdeTotalManual;
      this.porcentagemQtdeTotalRealizado = this.qtdeTotalRealizado > 0 ? ((this.qtdeTotalRealizado / this.ligacoes.length) * 100) : 0;

      if (this.qtdeTotalAgendadoRealizado > 0) {
        this.porcentagemQtdeTotalAgendadoRealizado = ((this.qtdeTotalAgendadoRealizado / agendado.length) * 100);
      }

      if (this.qtdeTotalGeradoRealizado > 0) {
        this.porcentagemQtdeTotalGeradoRealizado = ((this.qtdeTotalGeradoRealizado / gerado.length) * 100);
      }

      this.qtdeVendaRealizada = this.ligacoes.filter(ligacao => ligacao.id_status == 4 && ligacao.check_in != null && ligacao.check_out != null).length;
      this.porcentagemQtdeVendaRealizada = this.qtdeVendaRealizada > 0 ? ((this.qtdeVendaRealizada / this.qtdeTotalRealizado) * 100) : 0;

      this.qtdeNaoComprou = this.ligacoes.filter(ligacao => ligacao.id_status != 1 && ligacao.id_status != 4 && ligacao.id_status != 6 && ligacao.check_in != null && ligacao.check_out != null).length;
      this.porcentagemQtdeNaoComprou = this.qtdeNaoComprou > 0 ? ((this.qtdeNaoComprou / this.qtdeTotalRealizado) * 100) : 0;

      this.qtdePausa = this.pausas.length;

      let somaMilissegundos: number = 0;

      this.ligacoes.forEach((item) => {
        if (item.check_out != null && item.check_in != null) {
          somaMilissegundos = (somaMilissegundos + (new Date(item.check_out).getTime() - new Date(item.check_in).getTime()));
        }
      });

      this.tempoMedioPorLigacao = this.ligacoes.length > 0 ? this.conversaoService.decimalParaMinutos((this.conversaoService.milissegundosParaMinutos(somaMilissegundos) / this.ligacoes.length)) : this.conversaoService.decimalParaMinutos(0);

      /* Gráfico de Efetividade */

      let dataInicial = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      let dataFinal = new Date();

      let dataTemporaria = new Date();

      /* Ligações (Com Venda) */

      this.lineChartLabelsEfetividade = [];

      this.lineChartDataEfetividade[0].data = [];

      let ligacoesVendasRealizadas = this.ligacoes.filter(ligacao => ligacao.id_status == 4 && ligacao.check_in != null && ligacao.check_out != null);

      this.lineChartDataEfetividade[0].label = "Com Venda";

      this.lineChartLabelsEfetividade.push(this.conversaoService.numeroParaMesPorExtenso(dataInicial.getMonth()));

      let ligacoesPorPeriodo = ligacoesVendasRealizadas.filter(ligacaoPorPeriodo => {
        let data = this.datePipe.transform(ligacaoPorPeriodo.data_ligacao, "yyyy/MM/dd");

        return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
      });

      this.lineChartDataEfetividade[0].data.push(ligacoesPorPeriodo.length);

      for (var i = 0; i < 5; i++) {
        dataTemporaria = new Date(dataInicial.getFullYear(), dataInicial.getMonth(), 1);

        dataInicial = new Date(dataTemporaria.setMonth(dataTemporaria.getMonth() - 1));
        dataFinal = new Date(dataFinal.getFullYear(), dataFinal.getMonth(), 0);

        this.lineChartLabelsEfetividade.push(this.conversaoService.numeroParaMesPorExtenso(dataInicial.getMonth()));

        ligacoesPorPeriodo = ligacoesVendasRealizadas.filter(ligacaoPorPeriodo => {
          let data = this.datePipe.transform(ligacaoPorPeriodo.data_ligacao, "yyyy/MM/dd");

          return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
        });

        this.lineChartDataEfetividade[0].data.push(ligacoesPorPeriodo.length);
      }

      this.lineChartDataEfetividade[0].data.reverse();

      /* Ligações (Sem Venda) */

      this.lineChartDataEfetividade[1].data = [];

      let ligacoesNaoComprou = this.ligacoes.filter(ligacao => ligacao.id_status != 1 && ligacao.id_status != 4 && ligacao.id_status != 6 && ligacao.check_in != null && ligacao.check_out != null);

      dataInicial = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      dataFinal = new Date();

      this.lineChartDataEfetividade[1].label = "Sem Venda";

      ligacoesPorPeriodo = ligacoesNaoComprou.filter(ligacaoPorPeriodo => {
        let data = this.datePipe.transform(ligacaoPorPeriodo.data_ligacao, "yyyy/MM/dd");

        return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
      });

      this.lineChartDataEfetividade[1].data.push(ligacoesPorPeriodo.length);

      for (var i = 0; i < 5; i++) {
        dataTemporaria = new Date(dataInicial.getFullYear(), dataInicial.getMonth(), 1);

        dataInicial = new Date(dataTemporaria.setMonth(dataTemporaria.getMonth() - 1));
        dataFinal = new Date(dataFinal.getFullYear(), dataFinal.getMonth(), 0);

        ligacoesPorPeriodo = ligacoesNaoComprou.filter(ligacaoPorPeriodo => {
          let data = this.datePipe.transform(ligacaoPorPeriodo.data_ligacao, "yyyy/MM/dd");

          return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
        });

        this.lineChartDataEfetividade[1].data.push(ligacoesPorPeriodo.length);
      }

      this.lineChartDataEfetividade[1].data.reverse();

      this.lineChartLabelsEfetividade.reverse();

      /* Gráfico de Ligações por Tipo */

      this.barChartLabelsLigacoes = [];

      this.barChartDataLigacoes[0].data = [];

      let ligacoesRealizadas = this.ligacoes.filter(ligacao => ligacao.id_status != 1 && ligacao.id_status != 6 && ligacao.check_in != null && ligacao.check_out != null);

      let ligacoesAgrupadasPorTipo = ligacoesRealizadas.reduce((obj, item) => {
        obj[item.tiponome] = obj[item.tiponome] || [];
        obj[item.tiponome].push(item);

        return obj;
      }, {});

      ligacoesAgrupadasPorTipo = Object.keys(ligacoesAgrupadasPorTipo).map((objeto) => {
        return {
          key: objeto,
          value: ligacoesAgrupadasPorTipo[objeto]
        };
      }).sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.value.length, b.value.length) * -1; /* Decrescente */
      });

      for (var i = 0; i < Object.keys(ligacoesAgrupadasPorTipo).length; i++) {
        this.barChartLabelsLigacoes.push(ligacoesAgrupadasPorTipo[i].key);
        this.barChartDataLigacoes[0].data.push(ligacoesAgrupadasPorTipo[i].value.length);
      }

      /* Detalhamento dos Resultados */

      this.resultadosAgrupados = this.ligacoes.reduce((obj, item) => {
        obj[item.resultado] = obj[item.resultado] || [];
        obj[item.resultado].push(item);

        return obj;
      }, {});

      this.resultadosAgrupados = Object.keys(this.resultadosAgrupados).map((objeto) => {
        return {
          key: objeto,
          value: this.resultadosAgrupados[objeto]
        };
      }).sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.value.length, b.value.length) * -1; /* Decrescente */
      });

      /* Acompanhamento das Ligações */

      this.visitasComCheckIn = this.ligacoes.filter(ligacao => ligacao.check_in != null && ligacao.check_out != null);

      this.visitasComCheckIn.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.data_ligacao, b.data_ligacao) * -1; /* Decrescente */
      });

      /* Relatório Sintético por Vendedor(a) */

      this.ligacoesAgrupadasPorVendedor = this.ligacoes.reduce((obj, item) => {
        let chave: string = item.sellervendedor + "-" + item.id_tmk;

        obj[chave] = obj[chave] || [];
        obj[chave].push(item);

        return obj;
      }, {});

      this.ligacoesAgrupadasPorVendedor = Object.keys(this.ligacoesAgrupadasPorVendedor).map((key) => {
        let id_tmk = this.ligacoesAgrupadasPorVendedor[key][0].id_tmk;
        let vendedor = this.ligacoesAgrupadasPorVendedor[key][0].fullname;
        let equipe = this.ligacoesAgrupadasPorVendedor[key][0].nome_equipe;

        /* Agendado */

        let agendado: any = this.ligacoesAgrupadasPorVendedor[key].filter(ligacaoAgrupadaPorVendedor => !ligacaoAgrupadaPorVendedor.manual && !ligacaoAgrupadaPorVendedor.lista);

        let qtdeTotalAgendado = agendado.length;
        let qtdeTotalAgendadoRealizado = agendado.filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_status != 1 && ligacaoAgrupadaPorVendedor.id_status != 6 && ligacaoAgrupadaPorVendedor.check_in != null && ligacaoAgrupadaPorVendedor.check_out != null).length;

        /* Gerado */

        let gerado: any = this.ligacoesAgrupadasPorVendedor[key].filter(ligacaoAgrupadaPorVendedor => !ligacaoAgrupadaPorVendedor.manual && ligacaoAgrupadaPorVendedor.lista);

        let qtdeTotalGerado = gerado.length;
        let qtdeTotalGeradoRealizado = gerado.filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_status != 1 && ligacaoAgrupadaPorVendedor.id_status != 6 && ligacaoAgrupadaPorVendedor.check_in != null && ligacaoAgrupadaPorVendedor.check_out != null).length;

        /* Manual */

        let manual: any = this.ligacoesAgrupadasPorVendedor[key].filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.manual && !ligacaoAgrupadaPorVendedor.lista && ligacaoAgrupadaPorVendedor.check_in != null && ligacaoAgrupadaPorVendedor.check_out != null);

        let qtdeTotalManual = manual.length;

        let qtdeTotalRealizado = qtdeTotalAgendadoRealizado + qtdeTotalGeradoRealizado + qtdeTotalManual;

        let porcentagemQtdeTotalAgendadoRealizado = 0;

        if (qtdeTotalAgendadoRealizado > 0) {
          porcentagemQtdeTotalAgendadoRealizado = ((qtdeTotalAgendadoRealizado / agendado.length) * 100);
        }

        let porcentagemQtdeTotalGeradoRealizado = 0;

        if (qtdeTotalGeradoRealizado > 0) {
          porcentagemQtdeTotalGeradoRealizado = ((qtdeTotalGeradoRealizado / gerado.length) * 100);
        }

        /* Pausa */

        let qtdeTotalPausa = this.pausas.filter(pausa => pausa.seller_id == parseInt(key)).length;

        let qtdeVendaRealizada = this.ligacoesAgrupadasPorVendedor[key].filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_status == 4 && ligacaoAgrupadaPorVendedor.check_in != null && ligacaoAgrupadaPorVendedor.check_out != null).length;
        let porcentagemQtdeVendaRealizada = 0;

        if (qtdeVendaRealizada > 0) {
          porcentagemQtdeVendaRealizada = ((qtdeVendaRealizada / qtdeTotalRealizado) * 100);
        }

        let qtdeNaoComprou = this.ligacoesAgrupadasPorVendedor[key].filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_status != 1 && ligacaoAgrupadaPorVendedor.id_status != 4 && ligacaoAgrupadaPorVendedor.id_status != 6 && ligacaoAgrupadaPorVendedor.check_in != null && ligacaoAgrupadaPorVendedor.check_out != null).length;
        let porcentagemQtdeNaoComprou = 0;

        if (qtdeNaoComprou > 0) {
          porcentagemQtdeNaoComprou = ((qtdeNaoComprou / qtdeTotalRealizado) * 100);
        }

        let vendasTotal = 0;
        let litragemTotal = 0;

        let pedidoAgrupado = this.pedidosAgrupadosPorVendedorExterno.find(pedidoAgrupadoPorVendedorExterno => pedidoAgrupadoPorVendedorExterno.chave == key);

        if (pedidoAgrupado != null) {
          vendasTotal = pedidoAgrupado.vendas_internas;
          litragemTotal = pedidoAgrupado.litragem_interna;
        }

        let qtdeEmail = this.ligacoesAgrupadasPorVendedor[key].filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.tipo == "E" && ligacaoAgrupadaPorVendedor.check_in != null && ligacaoAgrupadaPorVendedor.check_out != null).length;
        let porcentagemQtdeEmail = qtdeEmail > 0 ? ((qtdeEmail / qtdeTotalRealizado) * 100) : 0;

        let qtdeFeitas = this.ligacoesAgrupadasPorVendedor[key].filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.tipo == "F" && ligacaoAgrupadaPorVendedor.check_in != null && ligacaoAgrupadaPorVendedor.check_out != null).length;
        let porcentagemFeitas = qtdeFeitas > 0 ? ((qtdeFeitas / qtdeTotalRealizado) * 100) : 0;

        let qtdeRecebidas = this.ligacoesAgrupadasPorVendedor[key].filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.tipo == "R" && ligacaoAgrupadaPorVendedor.check_in != null && ligacaoAgrupadaPorVendedor.check_out != null).length;
        let porcentagemRecebidas = qtdeRecebidas > 0 ? ((qtdeRecebidas / qtdeTotalRealizado) * 100) : 0;

        let qtdeWhatsapp = this.ligacoesAgrupadasPorVendedor[key].filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.tipo == "W" && ligacaoAgrupadaPorVendedor.check_in != null && ligacaoAgrupadaPorVendedor.check_out != null).length;
        let porcentagemWhatsapp = qtdeWhatsapp > 0 ? ((qtdeWhatsapp / qtdeTotalRealizado) * 100) : 0;

        return {
          id_tmk: id_tmk,
          vendedor: vendedor,
          equipe: equipe,

          /* Agendado */

          qtde_total_agendado: qtdeTotalAgendado,
          qtde_realizado_agendado: qtdeTotalAgendadoRealizado,
          porcentam_realizado_agendado: porcentagemQtdeTotalAgendadoRealizado,

          /* Gerado */

          qtde_total_gerado: qtdeTotalGerado,
          qtde_realizado_gerado: qtdeTotalGeradoRealizado,
          porcentam_realizado_gerado: porcentagemQtdeTotalGeradoRealizado,

          qtde_total_manual: qtdeTotalManual,

          qtde_total_realizado: qtdeTotalRealizado,

          qtde_total_pausa: qtdeTotalPausa,

          qtde_venda_realizada: qtdeVendaRealizada,
          porcentagem_qtde_venda_realizada: porcentagemQtdeVendaRealizada,
          qtde_nao_comprou: qtdeNaoComprou,
          porcentagem_qtde_nao_comprou: porcentagemQtdeNaoComprou,

          vendas_total: vendasTotal,
          litragem_total: litragemTotal,

          qtde_email: qtdeEmail,
          porcentagem_qtde_email: porcentagemQtdeEmail,

          qtde_feitas: qtdeFeitas,
          porcentagem_feitas: porcentagemFeitas,

          qtde_recebidas: qtdeRecebidas,
          porcentagem_recebidas: porcentagemRecebidas,

          qtde_whatsapp: qtdeWhatsapp,
          porcentagem_whatsapp: porcentagemWhatsapp
        };
      }).sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.vendedor, b.vendedor);
      });

      /* Relatório Sintético por Equipe */

      this.ligacoesAgrupadasPorEquipe = this.ligacoes.reduce((obj, item) => {
        obj[item.id_tmk] = obj[item.id_tmk] || [];
        obj[item.id_tmk].push(item);

        return obj;
      }, {});

      this.ligacoesAgrupadasPorEquipe = Object.keys(this.ligacoesAgrupadasPorEquipe).map((key) => {
        let id_tmk = key;
        let equipe = this.ligacoesAgrupadasPorEquipe[key][0].nome_equipe;

        /* Agendado */

        let agendado: any = this.ligacoesAgrupadasPorEquipe[key].filter(ligacaoAgrupadaPorEquipe => !ligacaoAgrupadaPorEquipe.manual && !ligacaoAgrupadaPorEquipe.lista);

        let qtdeTotalAgendado = agendado.length;
        let qtdeTotalAgendadoRealizado = agendado.filter(ligacaoAgrupadaPorEquipe => ligacaoAgrupadaPorEquipe.id_status != 1 && ligacaoAgrupadaPorEquipe.id_status != 6 && ligacaoAgrupadaPorEquipe.check_in != null && ligacaoAgrupadaPorEquipe.check_out != null).length;

        /* Gerado */

        let gerado: any = this.ligacoesAgrupadasPorEquipe[key].filter(ligacaoAgrupadaPorEquipe => !ligacaoAgrupadaPorEquipe.manual && ligacaoAgrupadaPorEquipe.lista);

        let qtdeTotalGerado = gerado.length;
        let qtdeTotalGeradoRealizado = gerado.filter(ligacaoAgrupadaPorEquipe => ligacaoAgrupadaPorEquipe.id_status != 1 && ligacaoAgrupadaPorEquipe.id_status != 6 && ligacaoAgrupadaPorEquipe.check_in != null && ligacaoAgrupadaPorEquipe.check_out != null).length;

        /* Manual */

        let manual: any = this.ligacoesAgrupadasPorEquipe[key].filter(ligacaoAgrupadaPorEquipe => ligacaoAgrupadaPorEquipe.manual && !ligacaoAgrupadaPorEquipe.lista && ligacaoAgrupadaPorEquipe.check_in != null && ligacaoAgrupadaPorEquipe.check_out != null);

        let qtdeTotalManual = manual.length;

        let qtdeTotalRealizado = qtdeTotalAgendadoRealizado + qtdeTotalGeradoRealizado + qtdeTotalManual;

        let porcentagemQtdeTotalAgendadoRealizado = 0;

        if (qtdeTotalAgendadoRealizado > 0) {
          porcentagemQtdeTotalAgendadoRealizado = ((qtdeTotalAgendadoRealizado / agendado.length) * 100);
        }

        let porcentagemQtdeTotalGeradoRealizado = 0;

        if (qtdeTotalGeradoRealizado > 0) {
          porcentagemQtdeTotalGeradoRealizado = ((qtdeTotalGeradoRealizado / gerado.length) * 100);
        }

        /* Pausa */

        let qtdeTotalPausa = this.pausas.filter(pausa => pausa.seller_id == parseInt(key)).length;

        let qtdeVendaRealizada = this.ligacoesAgrupadasPorEquipe[key].filter(ligacaoAgrupadaPorEquipe => ligacaoAgrupadaPorEquipe.id_status == 4 && ligacaoAgrupadaPorEquipe.check_in != null && ligacaoAgrupadaPorEquipe.check_out != null).length;
        let porcentagemQtdeVendaRealizada = 0;

        if (qtdeVendaRealizada > 0) {
          porcentagemQtdeVendaRealizada = ((qtdeVendaRealizada / qtdeTotalRealizado) * 100);
        }

        let qtdeNaoComprou = this.ligacoesAgrupadasPorEquipe[key].filter(ligacaoAgrupadaPorEquipe => ligacaoAgrupadaPorEquipe.id_status != 1 && ligacaoAgrupadaPorEquipe.id_status != 4 && ligacaoAgrupadaPorEquipe.id_status != 6 && ligacaoAgrupadaPorEquipe.check_in != null && ligacaoAgrupadaPorEquipe.check_out != null).length;
        let porcentagemQtdeNaoComprou = 0;

        if (qtdeNaoComprou > 0) {
          porcentagemQtdeNaoComprou = ((qtdeNaoComprou / qtdeTotalRealizado) * 100);
        }

        let vendasTotal = this.ligacoesAgrupadasPorVendedor.filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_tmk == id_tmk).reduce((sum, current) => sum + current.vendas_total, 0);
        let litragemTotal = this.ligacoesAgrupadasPorVendedor.filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_tmk == id_tmk).reduce((sum, current) => sum + current.litragem_total, 0);

        let vendasExternas = this.ligacoesAgrupadasPorVendedor.filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_tmk == id_tmk && ligacaoAgrupadaPorVendedor.master_seller_id == null).reduce((sum, current) => sum + current.vendas_total, 0);
        let litragemExterna = this.ligacoesAgrupadasPorVendedor.filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_tmk == id_tmk && ligacaoAgrupadaPorVendedor.master_seller_id == null).reduce((sum, current) => sum + current.litragem_total, 0);

        let porcentagemVendasExternas = 0;

        if (vendasTotal > 0) {
          porcentagemVendasExternas = ((vendasExternas / vendasTotal) * 100);
        }

        let vendasInternas = this.ligacoesAgrupadasPorVendedor.filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_tmk == id_tmk && ligacaoAgrupadaPorVendedor.master_seller_id != null).reduce((sum, current) => sum + current.vendas_total, 0);
        let litragemInterna = this.ligacoesAgrupadasPorVendedor.filter(ligacaoAgrupadaPorVendedor => ligacaoAgrupadaPorVendedor.id_tmk == id_tmk && ligacaoAgrupadaPorVendedor.master_seller_id != null).reduce((sum, current) => sum + current.litragem_total, 0);

        let porcentagemVendasInternas = 0;

        if (vendasTotal > 0) {
          porcentagemVendasInternas = ((vendasInternas / vendasTotal) * 100);
        }

        let qtdeEmail = this.ligacoesAgrupadasPorEquipe[key].filter(ligacaoAgrupadaPorEquipe => ligacaoAgrupadaPorEquipe.tipo == "E" && ligacaoAgrupadaPorEquipe.check_in != null && ligacaoAgrupadaPorEquipe.check_out != null).length;
        let porcentagemQtdeEmail = qtdeEmail > 0 ? ((qtdeEmail / qtdeTotalRealizado) * 100) : 0;

        let qtdeFeitas = this.ligacoesAgrupadasPorEquipe[key].filter(ligacaoAgrupadaPorEquipe => ligacaoAgrupadaPorEquipe.tipo == "F" && ligacaoAgrupadaPorEquipe.check_in != null && ligacaoAgrupadaPorEquipe.check_out != null).length;
        let porcentagemFeitas = qtdeFeitas > 0 ? ((qtdeFeitas / qtdeTotalRealizado) * 100) : 0;

        let qtdeRecebidas = this.ligacoesAgrupadasPorEquipe[key].filter(ligacaoAgrupadaPorEquipe => ligacaoAgrupadaPorEquipe.tipo == "R" && ligacaoAgrupadaPorEquipe.check_in != null && ligacaoAgrupadaPorEquipe.check_out != null).length;
        let porcentagemRecebidas = qtdeRecebidas > 0 ? ((qtdeRecebidas / qtdeTotalRealizado) * 100) : 0;

        let qtdeWhatsapp = this.ligacoesAgrupadasPorEquipe[key].filter(ligacaoAgrupadaPorEquipe => ligacaoAgrupadaPorEquipe.tipo == "W" && ligacaoAgrupadaPorEquipe.check_in != null && ligacaoAgrupadaPorEquipe.check_out != null).length;
        let porcentagemWhatsapp = qtdeWhatsapp > 0 ? ((qtdeWhatsapp / qtdeTotalRealizado) * 100) : 0;

        return {
          id_tmk: id_tmk,
          equipe: equipe,

          /* Agendado */

          qtde_total_agendado: qtdeTotalAgendado,
          qtde_realizado_agendado: qtdeTotalAgendadoRealizado,
          porcentam_realizado_agendado: porcentagemQtdeTotalAgendadoRealizado,

          /* Gerado */

          qtde_total_gerado: qtdeTotalGerado,
          qtde_realizado_gerado: qtdeTotalGeradoRealizado,
          porcentam_realizado_gerado: porcentagemQtdeTotalGeradoRealizado,

          qtde_total_manual: qtdeTotalManual,

          qtde_total_realizado: qtdeTotalRealizado,

          qtde_total_pausa: qtdeTotalPausa,

          qtde_venda_realizada: qtdeVendaRealizada,
          porcentagem_qtde_venda_realizada: porcentagemQtdeVendaRealizada,
          qtde_nao_comprou: qtdeNaoComprou,
          porcentagem_qtde_nao_comprou: porcentagemQtdeNaoComprou,

          vendas_externa: vendasExternas,
          litragem_externa: litragemExterna,
          porcentagem_vendas_externas: porcentagemVendasExternas,

          vendas_internas: vendasInternas,
          litragem_interna: litragemInterna,
          porcentagem_vendas_internas: porcentagemVendasInternas,

          vendas_total: vendasTotal,
          litragem_total: litragemTotal,

          qtde_email: qtdeEmail,
          porcentagem_qtde_email: porcentagemQtdeEmail,

          qtde_feitas: qtdeFeitas,
          porcentagem_feitas: porcentagemFeitas,

          qtde_recebidas: qtdeRecebidas,
          porcentagem_recebidas: porcentagemRecebidas,

          qtde_whatsapp: qtdeWhatsapp,
          porcentagem_whatsapp: porcentagemWhatsapp
        };
      }).sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.qtde_total_realizado, b.qtde_total_realizado) * -1; /* Decrescente */
      });
    }
  }

  carregarDados() {
    this.carregando = true;

    /* Ligações */

    this.dashboardCallCenterService.getLigacaoByUsuario().subscribe(
      ligacoes => this.ligacoes = ligacoes,
      error => { console.log("Erro: " + error) },
      () => {
        this.ligacoesOriginal = JSON.parse(JSON.stringify(this.ligacoes));

        /* Pausas */

        this.callCenterService.getPausaRealizada6MesesByGuidClienteNapis().subscribe(
          pausas => this.pausas = pausas,
          error => { console.log("Erro: " + error) },
          () => {
            this.pausasOriginal = JSON.parse(JSON.stringify(this.pausas));

            /* Pedidos */

            this.dashboardCallCenterService.getPedidoByUsuario().subscribe(
              pedidos => this.pedidos = pedidos,
              error => { console.log("Erro: " + error) },
              () => {
                this.pedidosOriginal = JSON.parse(JSON.stringify(this.pedidos));

                this.calcularDados();

                this.carregando = false;
              }
            );
          }
        );
      }
    );
  }

  reiniciarDados() {
    /* Ligações */

    this.ligacoes = JSON.parse(JSON.stringify(this.ligacoesOriginal));

    /* Pausas */

    this.pausas = JSON.parse(JSON.stringify(this.pausasOriginal));

    /* Pedidos */

    this.pedidos = JSON.parse(JSON.stringify(this.pedidosOriginal));
  }

  /* Exportar */

  exportarAcompanhamento() {
    /* Acompanhamento das Ligações */

    this.excelService.exportAsExcelFile(this.visitasComCheckIn, "Acompanhamento das Ligações");
  }

  exportarRelatorioSinteticoPorEquipe() {
    /* Relatório Sintético por Equipe */

    this.excelService.exportAsExcelFile(this.ligacoesAgrupadasPorEquipe, "Relatório Sintético por Equipe");
  }

  exportarRelatorioSinteticoPorVendedor() {
    /* Relatório Sintético por Vendedor(a) */

    this.excelService.exportAsExcelFile(this.ligacoesAgrupadasPorVendedor, "Relatório Sintético por Vendedor(a)");
  }

  /* Filtros */

  filtrar() {
    this.filtrado = false;

    this.reiniciarDados();

    /* Período */

    if (this.formFiltro.controls["dt_inicial"].value != "" && this.formFiltro.controls["dt_inicial"].value != null && this.formFiltro.controls["dt_final"].value != "" && this.formFiltro.controls["dt_final"].value != null) {
      /* Dt. Inicial */

      let dataInicial = this.formFiltro.controls["dt_inicial"].value;

      dataInicial = new Date(dataInicial.year, dataInicial.month - 1, dataInicial.day);
      dataInicial = this.datePipe.transform(dataInicial, "yyyy/MM/dd");

      /* Dt. Final */

      let dataFinal = this.formFiltro.controls["dt_final"].value;

      dataFinal = new Date(dataFinal.year, dataFinal.month - 1, dataFinal.day);
      dataFinal = this.datePipe.transform(dataFinal, "yyyy/MM/dd");

      /* Ligações */

      this.ligacoes = this.ligacoes.filter(ligacao => {
        let data = this.datePipe.transform(ligacao.data_ligacao, "yyyy/MM/dd");

        return data >= dataInicial && data <= dataFinal;
      });

      /* Pausas */

      this.pausas = this.pausas.filter(pausa => {
        let data = this.datePipe.transform(pausa.data_criacao_usuario, "yyyy/MM/dd");

        return data >= dataInicial && data <= dataFinal;
      });

      /* Pedidos */

      this.pedidos = this.pedidos.filter(pedido => {
        let data = this.datePipe.transform(pedido.order_sent_on, "yyyy/MM/dd");

        return data >= dataInicial && data <= dataFinal;
      });

      this.filtrado = true;
    }

    /* Resultado */

    if (this.formFiltro.controls["resultado"].value != "" && this.formFiltro.controls["resultado"].value != null) {
      /* Visitas */

      this.ligacoes = this.ligacoes.filter(ligacao => {
        if (ligacao.resultado == null) {
          return false;
        } else {
          return (ligacao.resultado.toLowerCase().indexOf(this.formFiltro.controls["resultado"].value.trim().toLowerCase()) !== -1);
        }
      });

      this.filtrado = true;
    }

    /* Vendedor(a) */

    if (this.formFiltro.controls["vendedor"].value != "" && this.formFiltro.controls["vendedor"].value != null) {
      /* Ligações */

      this.ligacoes = this.ligacoes.filter(ligacao => {
        if (ligacao.nome_equipe == null && ligacao.fullname == null) {
          /* Nenhum */

          return false;
        } else if (ligacao.nome_equipe != null && ligacao.fullname == null ) {
          /* Equipe */

          return (ligacao.nome_equipe.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
        } else if (ligacao.nome_equipe == null && ligacao.fullname != null) {
          /* Vendedor(a) */

          return (ligacao.fullname.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
        } else {
          /* Ambos */

          return (ligacao.nome_equipe.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1 || ligacao.fullname.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
        }
      });

      /* Pausas */

      this.pausas = this.pausas.filter(pausa => {
        if (pausa.fullname == null && pausa.fullname != null) {
          /* Vendedor(a) */

          return (pausa.fullname.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
        }
      });

      /* Pedidos */

      this.pedidos = this.pedidos.filter(pedido => {
        if (pedido.nome_equipe == null) {
          return false;
        } else {
          if (pedido.nome_equipe == null && pedido.fullname == null) {
            /* Nenhum */

            return false;
          } else if (pedido.nome_equipe != null && pedido.fullname == null) {
            /* Equipe */

            return (pedido.nome_equipe.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
          } else if (pedido.nome_equipe == null && pedido.fullname != null) {
            /* Vendedor(a) */

            return (pedido.fullname.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
          } else {
            /* Ambos */

            return (pedido.nome_equipe.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1 || pedido.fullname.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
          }
        }
      });

      this.filtrado = true;
    }

    if (this.filtrado) {
      this.calcularDados();
    }
  }

  removerFiltros() {
    this.reiniciarDados();

    this.calcularDados();

    this.formFiltro.reset();

    Object.keys(this.formFiltro.controls).forEach(key => {
      this.formFiltro.controls[key].setValue("");
    });

    this.filtrado = false;
  }

  /* Modal */

  modalChamadasRealizadas(content: any, id_call_center_ligacao: number) {
    this.callCenterService.getChamadaByIdCallCenterLigacao(id_call_center_ligacao).subscribe(
      chamadas => this.chamadas = chamadas,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.chamadas.length > 0) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-chamadas-realizadas",
            size: "lg",
            backdrop: "static"
          });
        }
      }
    );
  }

  modalPausasRealizadas(content: any, seller_id: number) {
    this.pausasSelecionadas = this.pausas.filter(pausaSelecionada => pausaSelecionada.seller_id == seller_id);

    if (this.pausasSelecionadas.length > 0) {
      this.modalService.open(content, {
        ariaLabelledBy: "titulo-pausas-realizadas",
        size: "lg",
        backdrop: "static"
      });
    } else {
      this.toastr.error("", "Nenhuma pausa encontrada!");
    }
  }
}
