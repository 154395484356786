import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { DashboardCallCenterPainelGerencialLigacao } from '../../../models/dashboard/call-center-painel-gerencial/dashboard-call-center-painel-gerencial-ligacao';
import { DashboardCallCenterPainelGerencialStatus } from '../../../models/dashboard/call-center-painel-gerencial/dashboard-call-center-painel-gerencial-status';

@Injectable({
  providedIn: 'root'
})

export class DashboardCallCenterPainelGerencialService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Ligação */

  public getLigacaoByUsuario(): Observable<DashboardCallCenterPainelGerencialLigacao[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<DashboardCallCenterPainelGerencialLigacao[]>(this.baseUrl + "api/DashboardCallCenterPainelGerencial/GetLigacaoByUsuario", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Status */

  public getStatusByUsuario(): Observable<DashboardCallCenterPainelGerencialStatus[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<DashboardCallCenterPainelGerencialStatus[]>(this.baseUrl + "api/DashboardCallCenterPainelGerencial/GetStatusByUsuario", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
