export class CanaisComunicacaoWhatsAppChatDocumento {
  public documentUrl: string;
  public mimeType: string;
  public thumbnailUrl: string;
  public fileName: string;
  public title: string;
  public pageCount: number;

  constructor() { }
}
