import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';

/* Models */

import { RotaConfiguracao } from '../models/rotas/rota-configuracao';

/* Services */

import { RotaService } from '../services/rotas/rota.service';

export class MapaConfiguracao implements LazyMapsAPILoaderConfigLiteral {
  public apiKey: string = "AIzaSyDrTPiqwTJnj-6RCJeCstFyVS1naHWMlTo";

  constructor(
    private rotaservice: RotaService
  ) {
    if (localStorage.length > 0) {
      let guidclientenapis: string = localStorage.getItem("guidclientenapis");

      if (guidclientenapis != "") {
        let rotaConfiguracao: RotaConfiguracao = null;

        this.rotaservice.getConfiguracaoByGuidClienteNapis().subscribe(
          rotasConfiguracao => rotaConfiguracao = rotasConfiguracao,
          error => { console.log("erro: " + error) },
          () => {
            if (rotaConfiguracao != null) {
              this.apiKey = rotaConfiguracao.api_key;
            }
          }
        );
      }
    }
  }
}
