import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ClienteService } from '../../core/services/clientes/cliente.service';
import { NotaFiscalService } from '../../core/services/notas-fiscais/nota-fiscal.service';
import { ProdutoService } from '../../core/services/produtos/produto.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { Cliente } from '../../core/models/clientes/cliente';
import { NotaFiscalSintetica } from '../../core/models/notas-fiscais/nota-fiscal-sintetica';
import { Produto } from '../../core/models/produtos/produto';
import { ProdutoEspecialCliente } from '../../core/models/produtos/produto-especial-cliente';
import { ProdutoEspecialVendedor } from '../../core/models/produtos/produto-especial-vendedor';
import { ProdutoLote } from '../../core/models/produtos/produto-lote';
import { ProdutoTabelaEspecial } from '../../core/models/produtos/produto-tabela-especial';
import { ProdutoTabelaEspecialItens } from '../../core/models/produtos/produto-tabela-especial-itens';
import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-produtos-promocoes-cadastro',
  templateUrl: './produtos-promocoes-cadastro.component.html',
  styleUrls: ['./produtos-promocoes-cadastro.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ProdutosPromocoesCadastroComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Itens (Menu) */

  itemSelecionado: number = 0;
  itemMaximoPermitido: number = 0;

  /* Filtro (Clientes) */

  formFiltroCliente: FormGroup;

  filtroClientes: any[] = [
    { texto: "Código", valor: "0" },
    { texto: "CNPJ/CPF", valor: "1" },
    { texto: "Nome Fantasia", valor: "2" },
    { texto: "Razão Social", valor: "3" }
  ];

  enviadoCliente: boolean = false;

  filtrandoCliente: boolean = false;

  /* Filtro (Produtos) */

  formFiltroProduto: FormGroup;

  filtroProdutos: any[] = [
    { texto: "Código", valor: "0" },
    { texto: "Descrição", valor: "1" },
    { texto: "Marca", valor: "2" }
  ];

  enviadoProduto: boolean = false;

  filtrandoProduto: boolean = false;

  /* Filtro (Vendedores) */

  formFiltroVendedor: FormGroup;

  filtroVendedores: any[] = [
    { texto: "Código", valor: "0" },
    { texto: "Nome", valor: "1" }
  ];

  enviadoVendedor: boolean = false;

  filtrandoVendedor: boolean = false;

  /* Formulário (Tabela Especial (Itens)) */

  formProdutoTabelaEspecialItem: FormGroup;

  enviadoProdutoTabelaEspecialItem: boolean = false;

  /* Formulário (Tabela Especial) */

  formProdutoTabelaEspecial: FormGroup;

  enviadoProdutoTabelaEspecial: boolean = false;

  /* Dados */

    /* Filtro (Clientes) */

    clientes: Cliente[] = [];
    clientesOriginal: Cliente[] = [];

    clienteSelecionado: Cliente = null;

    clienteInvalido: boolean = false;

    /* Filtro (Produtos) */

    produtoEmBranco: boolean = false;
    produtoInvalido: boolean = false;

    produtos: Produto[] = [];
    produtosOriginal: Produto[] = [];

    produtoSelecionado: Produto = null;

    /* Filtro (Vendedores) */

    vendedores: Vendedor[] = [];
    vendedoresOriginal: Vendedor[] = [];

    vendedorInvalido: boolean = false;

    vendedorSelecionado: Vendedor = null;

    /* Notas Fiscais */

    notasFiscais: NotaFiscalSintetica[] = [];

    /* Produto Especial (Clientes) */

    produtosEspecialClientes: ProdutoEspecialCliente[] = [];

    /* Produto Especial (Vendedores) */

    produtosEspecialVendedores: ProdutoEspecialVendedor[] = [];

    /* Produtos (Lotes) */

    produtosLotes: ProdutoLote[] = [];

    produtoLoteErro: boolean = false;

    /* Produto (Tabela Especial (Itens)) */

    produtoTabelaEspecialItemAtivo: boolean = true;

    produtosTabelaEspecialItens: ProdutoTabelaEspecialItens[] = [];

    produtoTabelaEspecialItemSelecionado: ProdutoTabelaEspecialItens = null;

    /* Produto (Tabela Especial) */

    produtoTabelaEspecialAtivo: boolean = true;

    produtoTabelaEspecial: ProdutoTabelaEspecial = null;

  /* Lote */

  exibirLote: boolean = false;

  /* Modal */

  modalReferenciaSelecionarLote: NgbModalRef = null;

  /* Storage */

  guidclientenapis: string = localStorage.getItem("guidclientenapis");
  obs_gerais: string = localStorage.getItem("obs_gerais");

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private clienteService: ClienteService,
    private notaFiscalService: NotaFiscalService,
    private produtoService: ProdutoService,
    private vendedorService: VendedorService
  ) { }

  ngOnInit() {
    /* Filtro (Clientes) */

    this.formFiltroCliente = this.formBuilder.group({
      filtro: ["3"],
      codigo: ["", Validators.required],
      cnpj_cpf: ["", Validators.required],
      nome_fantasia: ["", Validators.required],
      razao_social: ["", Validators.required]
    });

    this.organizarFiltroCliente();

    /* Filtro (Produtos) */

    this.formFiltroProduto = this.formBuilder.group({
      filtro: ["1"],
      codigo: ["", Validators.required],
      descricao: ["", Validators.required],
      marca: ["", Validators.required]
    });

    this.organizarFiltroProduto();

    /* Filtro (Vendedores) */

    this.formFiltroVendedor = this.formBuilder.group({
      filtro: ["1"],
      codigo: ["", Validators.required],
      nome: ["", Validators.required]
    });

    this.organizarFiltroVendedor();

    this.formProdutoTabelaEspecialItem = this.formBuilder.group({
      qtde: [{ value: "", disabled: true }],
      preco: [{ value: "", disabled: true }, Validators.required]
    });

    this.formProdutoTabelaEspecial = this.formBuilder.group({
      nome: ["", Validators.required],
    });

    let vincular: boolean = false;

    this.route.queryParams.subscribe(parametros => { vincular = parametros["vincular"] });

    if (vincular) {
      this.itemSelecionado = 2;
      this.itemMaximoPermitido = 3;
    }

    this.carregarDados();
  }

  get fc() {
    return this.formFiltroCliente.controls;
  }

  get fv() {
    return this.formFiltroVendedor.controls;
  }

  get ffp() {
    return this.formFiltroProduto.controls;
  }

  get fti() {
    return this.formProdutoTabelaEspecialItem.controls;
  }

  get fta() {
    return this.formProdutoTabelaEspecial.controls;
  }

  /* Ações */

  reiniciarProdutoTabelaEspecial() {
    /* Itens (Menu) */

    this.itemSelecionado = 0;
    this.itemMaximoPermitido = 0;

    /* Formulário (Tabela Especial) */

    this.formProdutoTabelaEspecial.reset();

    this.enviadoProdutoTabelaEspecial = false;

    /* Produto (Tabela Especial) */

    this.produtoTabelaEspecialAtivo = true;

    this.produtoTabelaEspecial = null;

    /* Produto (Tabela Especial (Itens)) */

    this.produtosTabelaEspecialItens = [];

    this.reiniciarProdutoTabelaEspecialItem();
  }

  reiniciarProdutoTabelaEspecialItem() {
    /* Formulário (Tabela Especial (Itens)) */

    this.formProdutoTabelaEspecialItem.reset()

    this.formProdutoTabelaEspecialItem.controls["qtde"].disable();
    this.formProdutoTabelaEspecialItem.controls["preco"].disable();

    this.enviadoProdutoTabelaEspecialItem = false;

    /* Filtro (Produtos) */

    this.produtoEmBranco = false;
    this.produtoInvalido = false;

    this.produtoSelecionado = null;

    /* Produto (Tabela Especial (Itens)) */

    this.produtoTabelaEspecialItemAtivo = true;

    this.produtoTabelaEspecialItemSelecionado = null;

    /* Produto (Lote) */

    this.removerLoteProduto();
  }

  salvarProdutoTabelaEspecial() {
    if (this.produtosTabelaEspecialItens.length > 0) {
      this.prepararEnviarDados();

      if (this.produtoTabelaEspecial.idprodutotabelaespecial != null) {
        /* Alterando */

        let alterarProdutoTabelaEspecial: boolean = false;

        this.produtoService.updateProdutoTabelaEspecial(this.produtoTabelaEspecial).subscribe(
          alterarProdutosTabelaEspecial => alterarProdutoTabelaEspecial = alterarProdutosTabelaEspecial,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarProdutoTabelaEspecial) {
              let alterarProdutoTabelaEspecialItem: boolean = false;

              this.produtoService.updateProdutoTabelaEspecialItens(this.produtosTabelaEspecialItens).subscribe(
                alterarProdutosTabelaEspecialItem => alterarProdutoTabelaEspecialItem = alterarProdutosTabelaEspecialItem,
                error => { console.log("Erro: " + error) },
                () => {
                  if (alterarProdutoTabelaEspecialItem) {
                    this.router.navigate(["/produtos"]);

                    this.toastr.success("", "Promoção alterada com sucesso!");
                  }
                }
              );
            } else {
              this.toastr.error("", "Houve um erro, contate o suporte...");
            }
          }
        );
      } else {
        /* Adicionando */

        let adicionarProdutoTabelaEspecial: number = 0;

        this.produtoService.addProdutoTabelaEspecial(this.produtoTabelaEspecial).subscribe(
          adicionarProdutosTabelaEspecial => adicionarProdutoTabelaEspecial = adicionarProdutosTabelaEspecial,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarProdutoTabelaEspecial > 0) {
              this.produtosTabelaEspecialItens.forEach((item) => {
                item.idprodutotabelaespecial = adicionarProdutoTabelaEspecial;
              });

              let alterarProdutoTabelaEspecialItem: boolean = false;

              this.produtoService.updateProdutoTabelaEspecialItens(this.produtosTabelaEspecialItens).subscribe(
                alterarProdutosTabelaEspecialItem => alterarProdutoTabelaEspecialItem = alterarProdutosTabelaEspecialItem,
                error => { console.log("Erro: " + error) },
                () => {
                  if (alterarProdutoTabelaEspecialItem) {
                    this.router.navigate(["/produtos-promocoes-cadastro/" + adicionarProdutoTabelaEspecial], {
                      queryParams: {
                        vincular: 1
                      }
                    });

                    this.toastr.success("", "Promoção adicionada com sucesso!");
                  }
                }
              );
            } else {
              this.toastr.error("", "Houve um erro, contate o suporte...");
            }
          }
        );
      }
    } else {
      this.toastr.error("", "Nenhum produto adicionado!");
    }
  }

  salvarProdutoTabelaEspecialItem() {
    this.enviadoProdutoTabelaEspecialItem = true;

    /* Filtro (Produtos) */

    this.produtoEmBranco = false;
    this.produtoInvalido = false;

    /* Produtos (Lotes) */

    this.produtoLoteErro = false;

    if (this.produtoSelecionado == null) {
      this.produtoEmBranco = true;

      this.toastr.error("", "Produto em branco!");
    } else {
      if (this.produtoSelecionado.qtde_lote > 0) {
        if (this.produtoSelecionado.id_lote == null) {
          this.produtoLoteErro = true;
        }
      }
    }

    if (this.produtoSelecionado != null) {
      let qtde: number = this.formProdutoTabelaEspecialItem.controls["qtde"].value;

      if (qtde != 0 && qtde != null) {
        if (this.produtoSelecionado.multiplo != null && this.produtoSelecionado.multiplo != 0) {
          if (qtde % this.produtoSelecionado.multiplo != 0) {
            this.formProdutoTabelaEspecialItem.controls["qtde"].setErrors({ "invalid": true });
          }
        }
      }
    }

    if (this.formProdutoTabelaEspecialItem.controls["preco"].valid) {
      if (this.formProdutoTabelaEspecialItem.controls["preco"].value == 0) {
        this.formProdutoTabelaEspecialItem.controls["preco"].setErrors({ "invalid": true });
      }
    }

    if (!this.produtoEmBranco && !this.produtoLoteErro && this.formProdutoTabelaEspecialItem.valid) {
      let qtde: number = this.formProdutoTabelaEspecialItem.controls["qtde"].value == null || this.formProdutoTabelaEspecialItem.controls["qtde"].value == "" ? 0 : this.formProdutoTabelaEspecialItem.controls["qtde"].value;

      let produtoAdicionado = this.produtosTabelaEspecialItens.find(produtoTabelaEspecialItem => produtoTabelaEspecialItem.codigo == this.produtoSelecionado.codigo && produtoTabelaEspecialItem.qtd_minima == qtde);

      if (produtoAdicionado != null) {
        if (this.produtoTabelaEspecialItemSelecionado != null) {
          /* Alterando */

          if (this.produtoTabelaEspecialItemSelecionado.codigo == produtoAdicionado.codigo && this.produtoTabelaEspecialItemSelecionado.qtd_minima == produtoAdicionado.qtd_minima) {

          } else {
            this.toastr.error("", "Produto/Qtde já adicionado!");

            this.produtoInvalido = true;
          }
        } else {
          /* Adicionando */

          this.toastr.error("", "Produto/Qtde já adicionado!");

          return;
        }
      }

      if (!this.produtoInvalido) {
        let produtoTabelaEspecialItens = new ProdutoTabelaEspecialItens;

        produtoTabelaEspecialItens.ativo = this.produtoTabelaEspecialItemAtivo;
        produtoTabelaEspecialItens.preco = this.formProdutoTabelaEspecialItem.controls["preco"].value;
        produtoTabelaEspecialItens.guidclientenapis = this.guidclientenapis;
        produtoTabelaEspecialItens.codigo = this.produtoSelecionado.codigo;
        produtoTabelaEspecialItens.qtd_minima = qtde;
        produtoTabelaEspecialItens.id_lote = this.produtoSelecionado.id_lote;

        /* Produto */

        produtoTabelaEspecialItens.name = this.produtoSelecionado.name;
        produtoTabelaEspecialItens.price = this.produtoSelecionado.valor;

        /* Lote */

        produtoTabelaEspecialItens.nome_lote = this.produtoSelecionado.nome_lote;
        produtoTabelaEspecialItens.cod_lote = this.produtoSelecionado.cod_lote;
        produtoTabelaEspecialItens.estoque_lote = this.produtoSelecionado.estoque_lote;
        produtoTabelaEspecialItens.validade_lote = this.produtoSelecionado.validade_lote;
        produtoTabelaEspecialItens.preco_lote = this.produtoSelecionado.preco_lote;

        if (this.produtoTabelaEspecialItemSelecionado != null) {
          /* Alterando */

          let produtoAdicionado = this.produtosTabelaEspecialItens.findIndex(produtoTabelaEspecialItem => produtoTabelaEspecialItem.codigo == this.produtoTabelaEspecialItemSelecionado.codigo && produtoTabelaEspecialItem.qtd_minima == this.produtoTabelaEspecialItemSelecionado.qtd_minima);

          if (produtoAdicionado != -1) {
            this.produtosTabelaEspecialItens.splice(produtoAdicionado, 1);

            this.produtosTabelaEspecialItens = [...this.produtosTabelaEspecialItens];

            produtoTabelaEspecialItens.idprodutoespecial = this.produtoTabelaEspecialItemSelecionado.idprodutoespecial;

            this.produtosTabelaEspecialItens.push(produtoTabelaEspecialItens);

            this.organizarProdutoTabelaEspecialItem();
            this.reiniciarProdutoTabelaEspecialItem();

            this.toastr.success("", "Produto alterado com sucesso!");
          }
        } else {
          /* Adicionando */

          produtoTabelaEspecialItens.idprodutoespecial = 0;

          if (this.produtoTabelaEspecial != null) {
            produtoTabelaEspecialItens.idprodutotabelaespecial = this.produtoTabelaEspecial.idprodutotabelaespecial;
          }

          this.produtosTabelaEspecialItens.push(produtoTabelaEspecialItens);

          this.organizarProdutoTabelaEspecialItem();
          this.reiniciarProdutoTabelaEspecialItem();

          this.toastr.success("", "Produto adicionado com sucesso!");
        }

        this.verificarExibirLote();
      }
    }
  }

  selecionarProdutoTabelaEspecialItem(codigo: string, qtde: number) {
    this.produtoEmBranco = false;
    this.produtoInvalido = false;

    this.produtoTabelaEspecialItemSelecionado = this.produtosTabelaEspecialItens.find(produtoTabelaEspecialItem => produtoTabelaEspecialItem.codigo == codigo && produtoTabelaEspecialItem.qtd_minima == qtde);

    if (this.produtoTabelaEspecialItemSelecionado != null) {
      this.produtoSelecionado = this.produtos.find(produto => produto.codigo == this.produtoTabelaEspecialItemSelecionado.codigo);

      if (this.produtoSelecionado != null) {
        this.produtoTabelaEspecialItemAtivo = this.produtoTabelaEspecialItemSelecionado.ativo;

        this.produtoSelecionado.valor = this.produtoTabelaEspecialItemSelecionado.price;

        /* Lote */

        this.produtoSelecionado.id_lote = this.produtoTabelaEspecialItemSelecionado.id_lote;
        this.produtoSelecionado.nome_lote = this.produtoTabelaEspecialItemSelecionado.nome_lote;
        this.produtoSelecionado.cod_lote = this.produtoTabelaEspecialItemSelecionado.cod_lote;
        this.produtoSelecionado.estoque_lote = this.produtoTabelaEspecialItemSelecionado.estoque_lote;
        this.produtoSelecionado.validade_lote = this.produtoTabelaEspecialItemSelecionado.validade_lote;
        this.produtoSelecionado.preco_lote = this.produtoTabelaEspecialItemSelecionado.preco_lote;

        /* Formulário (Tabela Especial (Itens)) */

        this.formProdutoTabelaEspecialItem.controls["qtde"].enable();
        this.formProdutoTabelaEspecialItem.controls["qtde"].setValue(this.produtoTabelaEspecialItemSelecionado.qtd_minima);

        this.formProdutoTabelaEspecialItem.controls["preco"].enable();
        this.formProdutoTabelaEspecialItem.controls["preco"].setValue(this.produtoTabelaEspecialItemSelecionado.preco);
      }
    }
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;
    /* Filtro (Clientes) */

    this.clienteService.getByUsuario(false).subscribe(
      clientes => this.clientes = clientes,
      error => { console.log("Erro: " + error) },
      () => {
        this.clientes.forEach((item) => {
          item.name = item.name.trim().toUpperCase();
          item.endereco_completo = "";

          if (item.tipo_logradouro != null) {
            item.endereco_completo = item.endereco_completo + item.tipo_logradouro.trim();
          }

          if (item.endereco != null) {
            item.endereco_completo = item.endereco_completo + " " + item.endereco.trim();
          }

          if (item.end_numero != null) {
            item.endereco_completo = item.endereco_completo + ", " + item.end_numero.trim();
          }

          if (item.cidade != null) {
            item.endereco_completo = item.endereco_completo + " / " + item.cidade.trim();
          }

          item.endereco_completo = item.endereco_completo.trim().toUpperCase();
        });

        this.clientesOriginal = JSON.parse(JSON.stringify(this.clientes));

        /* Vendedores */

        this.vendedorService.getByUsuario().subscribe(
          vendedores => this.vendedores = vendedores,
          error => { console.log("Erro: " + error) },
          () => {
            this.vendedoresOriginal = JSON.parse(JSON.stringify(this.vendedores));

            /* Produtos */

            this.produtoService.getByGuidClienteNapis().subscribe(
              produtos => this.produtos = produtos,
              error => { console.log("Erro: " + error) },
              () => {
                this.produtosOriginal = JSON.parse(JSON.stringify(this.produtos));

                this.verificarAdicionandoAlterando();
              }
            );
          }
        );
      }
    );
  }

  excluirProdutoEspecialCliente(id_produto_especial_cliente: number) {
    if (this.produtoTabelaEspecial != null) {
      let deletarProdutoEspecialCliente: boolean = false;

      this.produtoService.deleteProdutoEspecialClienteById(id_produto_especial_cliente).subscribe(
        deletarProdutoEspecialClientes => deletarProdutoEspecialCliente = deletarProdutoEspecialClientes,
        error => { console.log("Erro: " + error) },
        () => {
          if (deletarProdutoEspecialCliente) {
            let produtoProdutoEspecialCliente = this.produtosEspecialClientes.findIndex(produtoEspecialCliente => produtoEspecialCliente.idprodutoespecialcliente == id_produto_especial_cliente);

            if (produtoProdutoEspecialCliente != -1) {
              this.produtosEspecialClientes.splice(produtoProdutoEspecialCliente, 1);

              this.produtosEspecialClientes = [...this.produtosEspecialClientes];
            }

            this.toastr.success("", "Cliente excluído com sucesso!");
          }
        }
      );
    }
  }

  excluirProdutoEspecialVendedor(id_produto_especial_vendedor: number) {
    if (this.produtoTabelaEspecial != null) {
      let deletarProdutoEspecialVendedor: boolean = false;

      this.produtoService.deleteProdutoEspecialVendedorById(id_produto_especial_vendedor).subscribe(
        deletarProdutoEspecialVendedores => deletarProdutoEspecialVendedor = deletarProdutoEspecialVendedores,
        error => { console.log("Erro: " + error) },
        () => {
          if (deletarProdutoEspecialVendedor) {
            let produtoProdutoEspecialVendedor = this.produtosEspecialVendedores.findIndex(produtoEspecialVendedor => produtoEspecialVendedor.idprodutoespecialvendedor == id_produto_especial_vendedor);

            if (produtoProdutoEspecialVendedor != -1) {
              this.produtosEspecialVendedores.splice(produtoProdutoEspecialVendedor, 1);

              this.produtosEspecialVendedores = [...this.produtosEspecialVendedores];
            }

            this.toastr.success("", "Vendedor(a) excluída com sucesso!");
          }
        }
      );
    }
  }

  organizarProdutoTabelaEspecialItem() {
    this.produtosTabelaEspecialItens = this.produtosTabelaEspecialItens.sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a["name"], b["name"]);
    });

    this.produtosTabelaEspecialItens = this.produtosTabelaEspecialItens.sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a["qtd_minima"], b["qtd_minima"]);
    });
  }

  prepararReceberDados(id_produto_tabela_especial: number) {
    let retorno = new Promise<void>((resolver) => {
      /* Produto (Tabela Especial) */

      this.produtoService.getProdutoTabelaEspecialById(id_produto_tabela_especial).subscribe(
        produtoTabelaEspecial => this.produtoTabelaEspecial = produtoTabelaEspecial,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.produtoTabelaEspecial != null) {
            this.formProdutoTabelaEspecial.controls["nome"].setValue(this.produtoTabelaEspecial.descricao);

            this.produtoTabelaEspecialAtivo = this.produtoTabelaEspecial.ativo;

            /* Produto (Tabela Especial (Itens)) */

            this.produtoService.getProdutoTabelaEspecialItensByIdProdutoTabelaEspecial(this.produtoTabelaEspecial.idprodutotabelaespecial).subscribe(
              produtosTabelaEspecialItens => this.produtosTabelaEspecialItens = produtosTabelaEspecialItens,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.produtosTabelaEspecialItens != null) {
                  this.produtosTabelaEspecialItens.forEach((item) => {
                    if (item.id_lote != null) {
                      item.price = item.preco_lote;
                    }
                  });
                }

                this.verificarExibirLote();

                /* Produto Especial (Clientes) */

                this.produtoService.getProdutoEspecialClienteByIdProdutoTabelaEspecial(this.produtoTabelaEspecial.idprodutotabelaespecial).subscribe(
                  produtosEspecialClientes => this.produtosEspecialClientes = produtosEspecialClientes,
                  error => { console.log("Erro: " + error) },
                  () => {
                    /* Produto Especial (Vendedores) */

                    this.produtoService.getProdutoEspecialVendedorByIdProdutoTabelaEspecial(this.produtoTabelaEspecial.idprodutotabelaespecial).subscribe(
                      produtosEspecialVendedores => this.produtosEspecialVendedores = produtosEspecialVendedores,
                      error => { console.log("Erro: " + error) },
                      () => {
                        return resolver();
                      }
                    );
                  }
                );
              }
            );
          } else {
            this.toastr.error("", "Promoção inválida!");

            this.router.navigate(["/produtos"]);
          }
        }
      );
    });

    return retorno;
  }

  prepararEnviarDados() {
    if (this.produtoTabelaEspecial != null) {
      /* Alterando */

      this.produtoTabelaEspecial.descricao = String(this.formProdutoTabelaEspecial.controls["nome"].value).toUpperCase();
      this.produtoTabelaEspecial.guidclientenapis = this.guidclientenapis;
      this.produtoTabelaEspecial.ativo = this.produtoTabelaEspecialAtivo;
    } else {
      /* Adicionando */

      this.produtoTabelaEspecial = new ProdutoTabelaEspecial;

      this.produtoTabelaEspecial.descricao = String(this.formProdutoTabelaEspecial.controls["nome"].value).toUpperCase();
      this.produtoTabelaEspecial.guidclientenapis = this.guidclientenapis;
      this.produtoTabelaEspecial.ativo = this.produtoTabelaEspecialAtivo;
    }
  }

  salvarProdutoEspecialCliente() {
    if (this.clienteSelecionado != null) {
      if (this.produtoTabelaEspecial != null) {
        let produtoEspecialCliente = new ProdutoEspecialCliente();

        produtoEspecialCliente.idprodutotabelaespecial = this.produtoTabelaEspecial.idprodutotabelaespecial;
        produtoEspecialCliente.idcliente = this.clienteSelecionado.id;
        produtoEspecialCliente.name = this.clienteSelecionado.name;

        let adicionarProdutoEspecialCliente: number = 0;

        this.produtoService.addProdutoEspecialCliente(produtoEspecialCliente).subscribe(
          adicionarProdutoEspecialClientes => adicionarProdutoEspecialCliente = adicionarProdutoEspecialClientes,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarProdutoEspecialCliente > 0) {
              produtoEspecialCliente.idprodutoespecialcliente = adicionarProdutoEspecialCliente;

              this.produtosEspecialClientes.push(produtoEspecialCliente);

              this.produtosEspecialClientes = this.produtosEspecialClientes.sort((a, b) => {
                const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

                return compare(a["name"], b["name"]);
              });

              this.clienteSelecionado = null;

              this.alterarFiltroCliente(null);

              this.clienteInvalido = false;
              this.filtrandoCliente = false;

              this.toastr.success("", "Cliente adicionado com sucesso!");
            }
          }
        );
      }
    } else {
      this.clienteInvalido = true;
    }
  }

  salvarProdutoEspecialVendedor() {
    if (this.vendedorSelecionado != null) {
      if (this.produtoTabelaEspecial != null) {
        let produtoEspecialVendedor = new ProdutoEspecialVendedor();

        produtoEspecialVendedor.idprodutotabelaespecial = this.produtoTabelaEspecial.idprodutotabelaespecial;
        produtoEspecialVendedor.idvendedor = this.vendedorSelecionado.id;
        produtoEspecialVendedor.fullname = this.vendedorSelecionado.fullname;

        let adicionarProdutoEspecialVendedor: number = 0;

        this.produtoService.addProdutoEspecialVendedor(produtoEspecialVendedor).subscribe(
          adicionarProdutoEspecialVendedores => adicionarProdutoEspecialVendedor = adicionarProdutoEspecialVendedores,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarProdutoEspecialVendedor > 0) {
              produtoEspecialVendedor.idprodutoespecialvendedor = adicionarProdutoEspecialVendedor;

              this.produtosEspecialVendedores.push(produtoEspecialVendedor);

              this.produtosEspecialVendedores = this.produtosEspecialVendedores.sort((a, b) => {
                const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

                return compare(a["fullname"], b["fullname"]);
              });

              this.vendedorSelecionado = null;

              this.alterarFiltroVendedor(null);

              this.vendedorInvalido = false;
              this.filtrandoVendedor = false;

              this.toastr.success("", "Vendedor(a) adicionado com sucesso!");
            }
          }
        );
      }
    } else {
      this.vendedorInvalido = true;
    }
  }

  verificarAdicionandoAlterando() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    let id: string = null;

    this.route.params.subscribe(params => { id = params.id; });

    if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
      /* Alterando */

      const prepararReceberDados = this.prepararReceberDados(parseInt(id));

      prepararReceberDados.then(() => {
        this.carregando = false;
      });
    } else {
      /* Adicionado */

      this.carregando = false;
    }
  }

  /* Filtro (Clientes) */

  alterarFiltroCliente(filtro_selecionado: number) {
    this.reiniciarDadosCliente();

    let filtroAtual: string = this.formFiltroCliente.controls["filtro"].value;

    this.formFiltroCliente.reset();

    if (filtro_selecionado == null) {
      this.formFiltroCliente.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltroCliente.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltroCliente();

    this.enviadoCliente = false;
    this.filtrandoCliente = false;
  }

  filtrarCliente() {
    this.enviadoCliente = true;

    if (this.formFiltroCliente.invalid) {
      return;
    }

    if (this.formFiltroCliente.controls["filtro"].value == "0") {
      /* Código */

      if (this.formFiltroCliente.controls["codigo"].value == "" || this.formFiltroCliente.controls["codigo"].value == null) {
        return;
      }
    } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
      /* CNPJ/CPF */

      if (this.formFiltroCliente.controls["cnpj_cpf"].value == "" || this.formFiltroCliente.controls["cnpj_cpf"].value == null) {
        return;
      }
    } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
      /* Nome Fantasia */

      if (this.formFiltroCliente.controls["nome_fantasia"].value == "" || this.formFiltroCliente.controls["nome_fantasia"].value == null) {
        return;
      }
    } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
      /* Razão Social */

      if (this.formFiltroCliente.controls["razao_social"].value == "" || this.formFiltroCliente.controls["razao_social"].value == null) {
        return;
      }
    }

    this.clientes = JSON.parse(JSON.stringify(this.clientesOriginal));

    if (this.formFiltroCliente.controls["filtro"].value == "0") {
      /* Código */

      this.clientes = this.clientes.filter(cliente => {
        if (cliente.codigo_no_erp_do_cliente == null) {
          return false;
        } else {
          return (cliente.codigo_no_erp_do_cliente == this.formFiltroCliente.controls["codigo"].value);
        }
      });
    } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
      /* CNPJ/CPF */

      this.clientes = this.clientes.filter(cliente => {
        if (cliente.client_document == null) {
          return false;
        } else {
          let documentoAtual = cliente.client_document.trim().split(".").join("").split("-").join("").split("/").join("");
          let documentoDigitado = this.formFiltroCliente.controls["cnpj_cpf"].value.trim().split(".").join("").split("-").join("").split("/").join("");

          return (documentoAtual == documentoDigitado);
        }
      });
    } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
      /* Nome Fantasia */

      this.clientes = this.clientes.filter(cliente => {
        if (cliente.fantasia == null) {
          return false;
        } else {
          return (cliente.fantasia.toLowerCase().indexOf(this.formFiltroCliente.controls["nome_fantasia"].value.trim().toLowerCase()) !== -1);
        }
      });
    } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
      /* Razão Social */

      this.clientes = this.clientes.filter(cliente => {
        if (cliente.name == null) {
          return false;
        } else {
          return (cliente.name.toLowerCase().indexOf(this.formFiltroCliente.controls["razao_social"].value.trim().toLowerCase()) !== -1);
        }
      });
    }

    this.filtrandoCliente = true;
  }

  organizarFiltroCliente() {
    if (this.formFiltroCliente.controls["filtro"].value == "0") {
      /* Código */

      this.formFiltroCliente.get("codigo").enable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "1") {
      /* CNPJ/CPF */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").enable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "2") {
      /* Nome Fantasia */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").enable();
      this.formFiltroCliente.get("razao_social").disable();
    } else if (this.formFiltroCliente.controls["filtro"].value == "3") {
      /* Razão Social */

      this.formFiltroCliente.get("codigo").disable();
      this.formFiltroCliente.get("cnpj_cpf").disable();
      this.formFiltroCliente.get("nome_fantasia").disable();
      this.formFiltroCliente.get("razao_social").enable();
    }
  }

  reiniciarDadosCliente() {
    this.clientes = JSON.parse(JSON.stringify(this.clientesOriginal));
  }

  selecionarClienteFiltrado(cliente_selecionado: string) {
    if (cliente_selecionado != null && cliente_selecionado != "") {
      let clienteSelecionado: Cliente = this.clientes.find(clientes => clientes.codigo_no_erp_do_cliente == cliente_selecionado);

      if (clienteSelecionado != null) {
        /* Inadimplente */

        if (clienteSelecionado.status_financeiro != null) {
          this.toastr.warning("", "Cliente c/ inadimplência!");
        }

        let produtoEspecialCliente = this.produtosEspecialClientes.find(produtoEspecialCliente => produtoEspecialCliente.idcliente == clienteSelecionado.id);

        if (produtoEspecialCliente == null) {
          this.clienteSelecionado = clienteSelecionado;

          this.alterarFiltroCliente(null);

          this.clienteInvalido = false;
          this.filtrandoCliente = false;
        } else {
          this.toastr.error("", "Cliente já adicionado!");
        }
      }
    } else {
      this.toastr.error("", "Houve um erro, contate o suporte...");
    }
  }

  /* Filtro (Produtos) */

  alterarFiltroProduto(filtro_selecionado: number) {
    this.reiniciarDadosProduto();

    let filtroAtual: string = this.formFiltroProduto.controls["filtro"].value;

    this.formFiltroProduto.reset();

    if (filtro_selecionado == null) {
      this.formFiltroProduto.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltroProduto.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltroProduto();

    this.enviadoProduto = false;
    this.filtrandoProduto = false;
  }

  filtrarProduto() {
    this.enviadoProduto = true;

    if (this.formFiltroProduto.invalid) {
      return;
    }

    this.reiniciarDadosProduto();

    if (this.formFiltroProduto.controls["filtro"].value == "0") {
      /* Código */

      if (this.formFiltroProduto.controls["codigo"].value != "" && this.formFiltroProduto.controls["codigo"].value != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.codigo == null) {
            return false;
          } else {
            return (produto.codigo.toLowerCase().indexOf(this.formFiltroProduto.controls["codigo"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
      /* Descrição */

      if (this.formFiltroProduto.controls["descricao"].value != "" && this.formFiltroProduto.controls["descricao"].value != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.name == null) {
            return false;
          } else {
            return (produto.name.toLowerCase().indexOf(this.formFiltroProduto.controls["descricao"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
      /* Marca */

      if (this.formFiltroProduto.controls["marca"].value != "" && this.formFiltroProduto.controls["marca"].value != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.grupo_estoque == null) {
            return false;
          } else {
            return (produto.grupo_estoque.toLowerCase().indexOf(this.formFiltroProduto.controls["marca"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    }

    this.filtrandoProduto = true;
  }

  organizarFiltroProduto() {
    if (this.formFiltroProduto.controls["filtro"].value == "0") {
      /* Código */

      this.formFiltroProduto.get("codigo").enable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
      /* Descrição */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").enable();
      this.formFiltroProduto.get("marca").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
      /* Marca */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").enable();
    }
  }

  reiniciarDadosProduto() {
    this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
  }

  selecionarProdutoFiltrado(id_produto: number) {
    let produtoSelecionado = this.produtos.find(produtos => produtos.id == id_produto);

    if (produtoSelecionado != null) {
      this.produtoSelecionado = produtoSelecionado;

      if (this.produtoSelecionado.qtde_lote == 0) {
        this.produtoSelecionado.valor = this.produtoSelecionado.price;
      } else {
        this.produtoSelecionado.valor = null;
      }

      this.alterarFiltroProduto(null);
      this.removerLoteProduto();

      this.produtoEmBranco = false;
      this.produtoInvalido = false;
      this.filtrandoProduto = false;

      /* Formulário (Tabela Especial (Itens)) */

      if (produtoSelecionado.qtde_lote == 0) {
        this.formProdutoTabelaEspecialItem.controls["qtde"].enable();
        this.formProdutoTabelaEspecialItem.controls["preco"].enable();
      }
    }
  }

  /* Filtro (Vendedores) */

  alterarFiltroVendedor(filtro_selecionado: number) {
    this.reiniciarDadosVendedor();

    let filtroAtual: string = this.formFiltroVendedor.controls["filtro"].value;

    this.formFiltroVendedor.reset();

    if (filtro_selecionado == null) {
      this.formFiltroVendedor.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltroVendedor.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltroVendedor();

    this.enviadoVendedor = false;
    this.filtrandoVendedor = false;
  }

  filtrarVendedor() {
    this.enviadoVendedor = true;

    if (this.formFiltroVendedor.invalid) {
      return;
    }

    this.reiniciarDadosVendedor();

    if (this.formFiltroVendedor.controls["filtro"].value == "0") {
      /* Código */

      if (this.formFiltroVendedor.controls["codigo"].value != "" && this.formFiltroVendedor.controls["codigo"].value != null) {
        this.vendedores = this.vendedores.filter(vendedor => {
          if (vendedor.codigo_no_erp_do_cliente == null) {
            return false;
          } else {
            return (vendedor.codigo_no_erp_do_cliente == this.formFiltroVendedor.controls["codigo"].value);
          }
        });
      }
    } else if (this.formFiltroVendedor.controls["filtro"].value == "1") {
      /* Nome */

      if (this.formFiltroVendedor.controls["nome"].value != "" && this.formFiltroVendedor.controls["nome"].value != null) {
        this.vendedores = this.vendedores.filter(vendedor => {
          if (vendedor.fullname == null) {
            return false;
          } else {
            return (vendedor.fullname.toLowerCase().indexOf(this.formFiltroVendedor.controls["nome"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    }

    this.filtrandoVendedor = true;
  }

  organizarFiltroVendedor() {
    if (this.formFiltroVendedor.controls["filtro"].value == "0") {
      /* Código */

      this.formFiltroVendedor.get("codigo").enable();
      this.formFiltroVendedor.get("nome").disable();
    } else if (this.formFiltroVendedor.controls["filtro"].value == "1") {
      /* Nome */

      this.formFiltroVendedor.get("codigo").disable();
      this.formFiltroVendedor.get("nome").enable();
    }
  }

  reiniciarDadosVendedor() {
    this.vendedores = JSON.parse(JSON.stringify(this.vendedoresOriginal));
  }

  selecionarVendedorFiltrado(id_vendedor: number) {
    let vendedorSelecionado = this.vendedores.find(vendedor => vendedor.id == id_vendedor);

    if (vendedorSelecionado != null) {
      let produtoEspecialVendedor = this.produtosEspecialVendedores.find(produtoEspecialVendedor => produtoEspecialVendedor.idvendedor == vendedorSelecionado.id);

      if (produtoEspecialVendedor == null) {
        this.vendedorSelecionado = vendedorSelecionado;

        this.alterarFiltroVendedor(null);

        this.vendedorInvalido = false;
        this.filtrandoVendedor = false;
      } else {
        this.toastr.error("", "Vendedor(a) já adicionado(a)!");
      }
    }
  }

  /* Itens (Menu) */

  alterarItemSelecionado(item: number) {
    this.validarItens(item);

    if (item > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item;
  }

  prosseguirItemSelecionado(item: number) {
    this.validarItens(item);

    if ((item + 1) > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item + 1;
  }

  retornarItemSelecionado(item: number) {
    this.itemSelecionado = item - 1;
  }

  validarItens(item: number) {
    /* Validações */

    this.itemMaximoPermitido = 0;

    if (this.formProdutoTabelaEspecial.invalid) {
      this.enviadoProdutoTabelaEspecial = true;

      this.toastr.error("", "Informações inválidas!");

      this.itemSelecionado = 0;

      return;
    } else {
      this.itemMaximoPermitido = 1;
    }

    if (this.produtosTabelaEspecialItens.length == 0) {
      if (item > this.itemMaximoPermitido) {
        this.toastr.error("", "Nenhum produto adicionado!");

        this.itemSelecionado = 1;

        return;
      }
    } else {
      this.itemMaximoPermitido = 3;
    }
  }

  /* Lotes */

  removerLoteProduto() {
    if (this.produtoSelecionado != null) {
      /* Lote */

      this.produtoSelecionado.id_lote = null;
      this.produtoSelecionado.nome_lote = null;
      this.produtoSelecionado.cod_lote = null;
      this.produtoSelecionado.estoque_lote = null;
      this.produtoSelecionado.validade_lote = null;
      this.produtoSelecionado.preco_lote = null;
    }
  }

  selecionarLoteProduto(id_lote: number) {
    if (this.produtoSelecionado != null) {
      let produtoLote = this.produtosLotes.find(produtoLote => produtoLote.id == id_lote);

      if (produtoLote != null) {
        this.produtoSelecionado.valor = produtoLote.preco;

        /* Lote */

        this.produtoSelecionado.id_lote = produtoLote.id;
        this.produtoSelecionado.nome_lote = produtoLote.nomelote;
        this.produtoSelecionado.cod_lote = produtoLote.codlote;
        this.produtoSelecionado.estoque_lote = produtoLote.estoque;
        this.produtoSelecionado.validade_lote = produtoLote.validade;
        this.produtoSelecionado.preco_lote = produtoLote.preco;

        this.produtoLoteErro = false;

        /* Formulário (Tabela Especial (Itens)) */

        this.formProdutoTabelaEspecialItem.controls["qtde"].enable();
        this.formProdutoTabelaEspecialItem.controls["preco"].enable();
      }
    }

    this.modalReferenciaSelecionarLote.close();
  }

  verificarExibirLote() {
    this.exibirLote = false;

    this.produtosTabelaEspecialItens.forEach((item) => {
      if (item.id_lote != null) {
        this.exibirLote = true;
      }
    });
  }

  /* Produto (Qtde) */

  decrementarQtdeProduto() {
    if (this.produtoSelecionado != null) {
      if (this.produtoSelecionado.qtde_lote == 0 || (this.produtoSelecionado.qtde_lote > 0 && this.produtoSelecionado.id_lote != null)) {
        let qtde: number = this.formProdutoTabelaEspecialItem.controls["qtde"].value;

        if (this.produtoSelecionado.multiplo != null && this.produtoSelecionado.multiplo != 0 && this.produtoSelecionado.multiplo != 1) {
          /* C/ Múltiplo */

          if (qtde <= 1) {
            qtde = null;
          } else {
            let qtdeFutura: number = qtde - this.produtoSelecionado.multiplo;

            if (qtdeFutura == 0 || qtdeFutura % this.produtoSelecionado.multiplo != 0) {
              qtde = null;
            } else {
              qtde = qtde - this.produtoSelecionado.multiplo;
            }
          }
        } else {
          /* S/ Múltiplo */

          if (qtde <= 1) {
            qtde = null;
          } else {
            qtde--;
          }
        }

        this.formProdutoTabelaEspecialItem.controls["qtde"].setValue(qtde);
      }
    }
  }

  incrementarQtdeProduto(id_produto: number) {
    if (this.produtoSelecionado != null) {
      if (this.produtoSelecionado.qtde_lote == 0 || (this.produtoSelecionado.qtde_lote > 0 && this.produtoSelecionado.id_lote != null)) {
        let qtde: number = this.formProdutoTabelaEspecialItem.controls["qtde"].value;

        if (this.produtoSelecionado.multiplo != null && this.produtoSelecionado.multiplo != 0 && this.produtoSelecionado.multiplo != 1) {
          /* C/ Múltiplo */

          if (qtde <= 0 || qtde == null) {
            qtde = this.produtoSelecionado.multiplo;
          } else {
            let qtdeFutura: number = qtde + this.produtoSelecionado.multiplo;

            if (qtdeFutura % this.produtoSelecionado.multiplo != 0) {
              qtde = this.produtoSelecionado.multiplo;
            } else {
              qtde = qtde + this.produtoSelecionado.multiplo;
            }
          }
        } else {
          /* S/ Múltiplo */

          if (qtde <= 0 || qtde == null) {
            qtde = 1;
          } else {
            qtde++;
          }
        }

        this.formProdutoTabelaEspecialItem.controls["qtde"].setValue(qtde);
      }
    }
  }

  /* Produto (Tabela Especial (Itens)) */

  selecionarProdutoTabelaEspecialItemAtivo(ativo: string) {
    this.produtoTabelaEspecialItemAtivo = ativo == "0" ? false : true;
  }

  /* Produto (Tabela Especial) */

  selecionarProdutoTabelaEspecialAtivo(ativo: string) {
    this.produtoTabelaEspecialAtivo = ativo == "0" ? false : true;
  }

  /* Verificação (Qtde) */

  verificarProdutoQtde() {
    if (this.produtoSelecionado != null) {
      let qtde: number = this.formProdutoTabelaEspecialItem.controls["qtde"].value;

      if (qtde != 0 && qtde != null) {
        if (this.produtoSelecionado.multiplo != null && this.produtoSelecionado.multiplo != 0) {
          if (qtde % this.produtoSelecionado.multiplo != 0) {
            this.toastr.error("", "Qtde somente múltipla de " + this.produtoSelecionado.multiplo + "...");

            this.formProdutoTabelaEspecialItem.controls["qtde"].setErrors({ "invalid": true });
          }
        }
      }
    }
  }

  /* Modal */

  modalSelecionarLote(content: any, id_produto: number) {
    if (this.produtoSelecionado != null) {
      this.produtoService.getProdutoLoteByIdProduto(id_produto, this.obs_gerais, null, null).subscribe(
        produtosLotes => this.produtosLotes = produtosLotes,
        error => { console.log("Erro: " + error) },
        () => {
          this.modalReferenciaSelecionarLote = this.modalService.open(content, {
            ariaLabelledBy: "titulo-selecionar-lote",
            size: "lg",
            backdrop: "static"
          });
        }
      );
    }
  }

  modalNotasFiscaisSinteticas(content: any, client_document: string) {
    client_document = client_document.replace(/[.]/g, "").replace(/[/]/g, "").replace(/[-]/g, "");

    this.notaFiscalService.getNotaFiscalSinteticaByCNPJ(client_document).subscribe(
      notaFiscal => this.notasFiscais = notaFiscal,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.notasFiscais.length > 0) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-notas-fiscais-sinteticas",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhuma nota fiscal encontrada!");
        }
      }
    );
  }
}
