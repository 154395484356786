export class Vendedor {
  public id: number;
  public fullname: string;
  public username: string;
  public password: string;
  public email: string;
  public app_token: string;
  public seller_contract_type_id: number;
  public is_active: boolean;
  public updated_at: string;
  public created_at: Date;
  public device_id: number;
  public is_manager: boolean;
  public manager_id: number;
  public is_master: boolean;
  public serial_number: string;
  public guidclientenapis: string;
  public seller_document: string;
  public is_master_seller: boolean;
  public codigo_no_erp_do_cliente: string;
  public flag_login_autorizado: boolean;
  public senha_no_erp_do_cliente: string;
  public cnpj_integracao: string;
  public seller_id_tmk: number;
  public flag_tablet: string;
  public id_perfil: number;
  public nome_equipe: string;
  public seller_id_multiempresa: number;
  public celular: string;
  public email_real: string;
  public painel_pedidos_enviados: number;
  public painel_pedidos_enviados_variacao: number;
  public painel_pedidos_faturados: number;
  public painel_pedidos_faturados_variacao: number;
  public gera_ligacoes: string;
  public painel_visitas: number;
  public painel_visitas_variacao: number;
  public painel_qtd_pedidos: number;
  public painel_qtd_pedidos_variacao: number;
  public painel_ticket_medio: number;
  public painel_ticket_medio_variacao: number;
  public painel_pedidos_enviados_mes_anterior: number;
  public painel_qtd_clientes: number;
  public painel_60_dias_sem_visita: number;
  public painel_clientes_com_objetivo: number;
  public painel_clientes_falta_bater: number;
  public painel_dt_atualizacao: Date;
  public link_bi: string;
  public rotas_tempo_real: boolean;
  public rotas_limite_uso: number;
  public rotas_consumo_mes: number;
  public hr_trab_inicial: string;
  public hr_trab_final: string;
  public link_whatsapp: string;
  public obs_gerais: string;
  public usuario_ecommerce: boolean;
  public usuario_integracao: boolean;
  public is_master_seller_inativo: boolean;
  public cod_sharpspring: number;
  public fuso_horario: number;
  public usa_pabx: boolean;
  public whtass_ver_todos: boolean;
  public usuario_teste: boolean;
  public usa_whatss: boolean;
  public whats_numero: string;
  public status_discador: boolean;
  public status_discador_ult_pausa: Date;
  public status_portal: boolean;
  public seller_foto: string;
  public aparece_painel: boolean;
  public dt_ultima_atualizacao: Date;
  public versao_app: string;
  public versao_app_ult_login: Date;
  public call_center_discador_automatico: boolean;
  public seller_ver_aba_call_center: boolean;
  public cb: boolean;
  public em_atendimento: boolean;
  public flag_modo_reparo: boolean;
  public flag_exibir_impostos: boolean;
  public pabx_server: string;
  public pabx_ramal: string;
  public pabx_usuario: string;
  public pabx_senha: string;
  public versao_target_app: string;
  public link_download_versao: string;
  public painel_margem_contribuicao: number;
  public token_innersac: string;
  public is_diretor: boolean;
  public nm_empresa: string;

  constructor() { }
}
