import { Component, OnInit } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ConversaoService } from '../../core/tools/conversao.service';
import { CrmService } from '../../core/services/crm/crm.service';
import { FunilClassificacaoService } from '../../core/services/funis-classificacao/funil-classificacao.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { CrmAgrupado } from '../../core/models/crm/crm-agrupado';
import { CrmNegocio } from '../../core/models/crm/crm-negocio';
import { CrmCanalVenda } from '../../core/models/crm/crm-canal-venda';
import { FunilClassificacao } from '../../core/models/funis-classificacao/funil-classificacao';
import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-crm-negocios',
  templateUrl: './crm-negocios.component.html',
  styleUrls: ['./crm-negocios.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CrmNegociosComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Dados */

    /* CRM (Canal Venda) */

    crmCanaisVendas: CrmCanalVenda[] = [];

    /* CRM (Negócio) */

    crmNegocios: CrmNegocio[] = [];

    crmAgrupados: CrmAgrupado[] = [];

    /* Funil Classificação */

    funisClassificacao: FunilClassificacao[] = [];

    /* Vendedores */

    vendedores: Vendedor[] = [];

    vendedorSelecionado: Vendedor = null;

  constructor(
    private toastr: ToastrService,
    private conversaoService: ConversaoService,
    private crmService: CrmService,
    private funilClassificacaoService: FunilClassificacaoService,
    private vendedorService: VendedorService
  ) { }

  ngOnInit() {
    this.carregarDados();
  }

  /* Dados */

  agruparDados() {
    this.funisClassificacao.forEach((item) => {
      let crmAgrupado = new CrmAgrupado();

      crmAgrupado.funnel_classification_id = item.id;
      crmAgrupado.ganhou_perdeu = null;
      crmAgrupado.nome_negocio = item.name;

      crmAgrupado.negocios = this.crmNegocios.filter(crmNegocio => crmNegocio.funnel_classification_id == item.id && crmNegocio.ganhou_perdeu == null);

      this.crmAgrupados.push(crmAgrupado);
    });

    /* Ganhos */

    let crmAgrupadoGanho = new CrmAgrupado();

    crmAgrupadoGanho.funnel_classification_id = null;
    crmAgrupadoGanho.ganhou_perdeu = 'G';
    crmAgrupadoGanho.nome_negocio = "Ganhos";

    crmAgrupadoGanho.negocios = this.crmNegocios.filter(crmNegocio => crmNegocio.ganhou_perdeu == 'G');

    this.crmAgrupados.push(crmAgrupadoGanho);

    /* Perdas */

    let crmAgrupadoPerda = new CrmAgrupado();

    crmAgrupadoPerda.funnel_classification_id = null;
    crmAgrupadoPerda.ganhou_perdeu = 'P';
    crmAgrupadoPerda.nome_negocio = "Perdas";

    crmAgrupadoPerda.negocios = this.crmNegocios.filter(crmNegocio => crmNegocio.ganhou_perdeu == 'P');

    this.crmAgrupados.push(crmAgrupadoPerda);

    this.totalizarDados();

    this.carregando = false;
  }

  atualizarNegocio(id_negocio: string, funnel_classification_id: number, ganhou_perdeu: string) {
    let alterarCrmNegocio: boolean = false;

    this.crmService.updateCrmNegocioFunilByIdNegocio(id_negocio, funnel_classification_id, ganhou_perdeu).subscribe(
      alterarCrmNegocios => alterarCrmNegocio = alterarCrmNegocios,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarCrmNegocio) {
          this.toastr.success("", "Negócio atualizado com sucesso!");
        }
      }
    );
  }

  carregarDados() {
    this.carregando = true;

    /* Vendedores */

    this.vendedorService.getByUsuario().subscribe(
      vendedores => this.vendedores = vendedores,
      error => { console.log("Erro: " + error) },
      () => {
        this.carregando = false;
      }
    );
  }

  carregarDadosCRM() {
    /* Funil Classificação */

    this.funilClassificacaoService.getFunilClassificacaoByGuidClienteNapis().subscribe(
      funilClassificacao => this.funisClassificacao = funilClassificacao,
      error => { console.log("Erro: " + error) },
      () => {
        /* CRM (Canal Venda) */

        this.crmService.getCrmCanalVendaByGuidClienteNapis().subscribe(
          crmCanalVenda => this.crmCanaisVendas = crmCanalVenda,
          error => { console.log("Erro: " + error) },
          () => {
            /* CRM (Negócio) */

            this.crmService.getCrmNegocioByVendedor(this.vendedorSelecionado.id).subscribe(
              crmNegocio => this.crmNegocios = crmNegocio,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.crmNegocios.length > 0) {
                  let penultima_ordem: number = this.crmNegocios[this.crmNegocios.length - 1].ordem + 1;
                  let ultima_ordem: number = penultima_ordem + 1;

                  this.crmNegocios.forEach((item) => {
                    item.valor_negocio = item.valor_negocio == null ? 0 : item.valor_negocio;

                    if (item.dt_fechamento != null) {
                      let dataFechamento = new Date(item.dt_fechamento);

                      item.qtdeDias = parseInt(this.conversaoService.milissegundosParaDias(new Date().getTime() - dataFechamento.getTime()).toFixed(0));
                    } else {
                      item.qtdeDias = 0;
                    }

                    item.update = false;
                    item.effectAllowed = "move";
                    item.disable = false;
                    item.handle = false;

                    if (item.ganhou_perdeu == "G") {
                      item.ordem = penultima_ordem;
                      item.nome_negocio = "Ganhos";
                    } else if (item.ganhou_perdeu == "P") {
                      item.ordem = ultima_ordem;
                      item.nome_negocio = "Perdas";
                    }
                  });
                }

                this.agruparDados();
              }
            );
          }
        );
      }
    );
  }

  totalizarDados() {
    this.crmAgrupados.forEach((item) => {
      item.valor_total_negocio = item.negocios.reduce((sum, current) => sum + current.valor_negocio, 0);
    });
  }

  /* Drag and Drop */

  onDragStart(evento_drag: DndDropEvent) {

  }

  onDragEnd(evento_drag: DndDropEvent) {

  }

  onDragged(item: CrmNegocio, negocios: CrmNegocio[], effect: DropEffect) {
    if (effect == "move") {
      const index = negocios.indexOf(item);

      negocios.splice(index, 1);

      this.totalizarDados();
    }
  }

  onDrop(evento: DndDropEvent, funnel_classification_id: number, ganhou_perdeu: string, nome_negocio: string, negocios: CrmNegocio[]) {
    if (negocios != null && (evento.dropEffect == "copy" || evento.dropEffect == "move")) {
      evento.data.funnel_classification_id = funnel_classification_id;
      evento.data.ganhou_perdeu = ganhou_perdeu;
      evento.data.nome_negocio = nome_negocio;

      let index = evento.index;

      if (typeof index == "undefined") {
        index = negocios.length;
      }

      negocios.splice(index, 0, evento.data);

      this.atualizarNegocio(evento.data.id_negocio, funnel_classification_id, ganhou_perdeu);
    }
  }

  /* Vendedores */

  selecionarVendedor(id_vendedor: string) {
    /* CRM (Canal Venda) */

    this.crmCanaisVendas = [];

    /* CRM (Negócio) */

    this.crmNegocios = [];

    this.crmAgrupados = [];

    /* Funil Classificação */

    this.funisClassificacao = [];

    /* Vendedores */

    this.vendedorSelecionado = null;

    if (id_vendedor != "0") {
      this.vendedorSelecionado = this.vendedores.find(vendedor => vendedor.id == parseInt(id_vendedor));

      if (this.vendedorSelecionado != null) {
        this.carregarDadosCRM();
      }
    }
  }
}
