import { Component, OnInit } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CrmComponent implements OnInit {
  /* Abas */

  tabSelecionada: string = "negocios";

  constructor() { }

  ngOnInit() {
    
  }

  /* Abas */

  alterarAbas(evento: any) {
    this.tabSelecionada = evento.nextId;
  }
}
