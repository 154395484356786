import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';

/* Models */

import { CallCenterMotivoPausa } from '../../core/models/call-center/call-center-motivo-pausa';

@Component({
  selector: 'app-call-center-motivos-pausa-cadastro',
  templateUrl: './call-center-motivos-pausa-cadastro.component.html',
  styleUrls: ['./call-center-motivos-pausa-cadastro.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CallCenterMotivosPausaCadastroComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Call Center (Motivos Pausa) */

  motivoPausa: CallCenterMotivoPausa = null;

  motivoPausaAtivo: boolean = true;
  motivoPausaSomenteGestor: boolean = true;

  motivoPausaTempo: { hour: number, minute: number } = null;

  motivoPausaTempoInvalido: boolean = false;

  /* Formulário */

  formDados: FormGroup;

  enviado: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private callCenterService: CallCenterService
  ) { }

  ngOnInit() {
    this.formDados = this.formBuilder.group({
      motivo: ["", Validators.required]
    });

    this.verificarAdicionandoAlterando();
  }

  get f() {
    return this.formDados.controls;
  }

  /* Ações */

  selecionarMotivoPausaAtivo(ativo: string) {
    this.motivoPausaAtivo = ativo == "0" ? false : true;
  }

  selecionarMotivoPausaSomenteGestor(somente_gestor: string) {
    this.motivoPausaSomenteGestor = somente_gestor == "0" ? false : true;

    this.validarMotivoPausaTempo();
  }

  validarMotivoPausaTempo() {
    if (!this.motivoPausaSomenteGestor) {
      if (this.motivoPausaTempo == null || this.motivoPausaTempo.hour == null || this.motivoPausaTempo.minute == null || (this.motivoPausaTempo.hour == 0 && this.motivoPausaTempo.minute == 0)) {
        this.motivoPausaTempoInvalido = true;
      } else {
        this.motivoPausaTempoInvalido = false;
      }
    } else {
      this.motivoPausaTempoInvalido = false;
    }
  }

  /* Dados */

  addUpdate() {
    let retorno = new Promise<void>((resolver) => {
      let motivoPausa = new CallCenterMotivoPausa();

      motivoPausa.nome = this.formDados.controls["motivo"].value;
      motivoPausa.is_active = this.motivoPausaAtivo;
      motivoPausa.flag_somente_gestor = this.motivoPausaSomenteGestor;

      let tempo = new Date();

      tempo.setHours(this.motivoPausaTempo == null ? 0 : this.motivoPausaTempo.hour);
      tempo.setMinutes(this.motivoPausaTempo == null ? 0 : this.motivoPausaTempo.minute);
      tempo.setSeconds(0);

      motivoPausa.tempo_pausa = this.datePipe.transform(tempo, "HH:mm:ss");

      if (this.motivoPausa != null) {
        /* Alterando */

        motivoPausa.id = this.motivoPausa.id;

        let alterarMotivoPausa: boolean = false;

        this.callCenterService.updateMotivoPausa(motivoPausa).subscribe(
          alterarMotivosPausa => alterarMotivoPausa = alterarMotivosPausa,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarMotivoPausa) {
              this.toastr.success("", "Motivo (Pausa) salvo com sucesso!");
            }

            return resolver();
          }
        );
      } else {
        /* Adicionando */

        let adicionarMotivoPausa: boolean = false;

        this.callCenterService.addMotivoPausa(motivoPausa).subscribe(
          adicionarMotivosPausa => adicionarMotivoPausa = adicionarMotivosPausa,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarMotivoPausa) {
              this.toastr.success("", "Motivo (Pausa) salvo com sucesso!");
            }

            return resolver();
          }
        );
      }
    });

    return retorno;
  }

  prepararReceberDados(id: number) {
    let retorno = new Promise<void>((resolver) => {
      this.callCenterService.getMotivoPausaById(id).subscribe(
        motivoPausa => this.motivoPausa = motivoPausa,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.motivoPausa != null) {
            this.formDados.controls["motivo"].setValue(this.motivoPausa.nome);

            this.motivoPausaAtivo = this.motivoPausa.is_active;
            this.motivoPausaSomenteGestor = this.motivoPausa.flag_somente_gestor;

            if (this.motivoPausa.tempo_pausa == null) {
              this.motivoPausa.tempo_pausa = "";
            }

            let tempo = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " " + this.motivoPausa.tempo_pausa);

            this.motivoPausaTempo = {
              hour: tempo.getHours(),
              minute: tempo.getMinutes()
            }

            return resolver();
          } else {
            this.toastr.error("", "Motivo (Pausa) inválido!");

            this.router.navigate(["/call-center-motivos-pausa/"]);
          }
        }
      );
    });

    return retorno;
  }

  salvar() {
    this.enviado = true;

    /* Tempo */

    this.validarMotivoPausaTempo();

    if (this.formDados.invalid || this.motivoPausaTempoInvalido) {
      return;
    }

    const addUpdate = this.addUpdate();

    addUpdate.then(() => {
      this.router.navigate(["/call-center-motivos-pausa/"]);
    });
  }

  verificarAdicionandoAlterando() {
    this.carregando = true;

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    let id: string = null;

    this.route.params.subscribe(params => { id = params.id; });

    if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
      /* Alterando */

      const prepararReceberDados = this.prepararReceberDados(parseInt(id));

      prepararReceberDados.then(() => {
        this.carregando = false;
      });
    } else {
      /* Adicionando */

      this.carregando = false;
    }
  }
}
