import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { UsuarioService } from '../../core/services/usuarios/usuario.service';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class RecuperarSenhaComponent implements OnInit {
  formRecuperarSenha: FormGroup;

  enviado: boolean = false;
  emailEnviado: boolean = false;

  /* Storage */

  logomarca: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.formRecuperarSenha = this.formBuilder.group({
      email: ['', Validators.required]
    });

    this.verificarLayout();
  }

  get f() {
    return this.formRecuperarSenha.controls;
  }

  recuperar() {
    this.enviado = true;

    if (this.formRecuperarSenha.valid) {
      this.usuarioService.sendEmail(
        this.formRecuperarSenha.controls["email"].value
      ).subscribe(
        emailEnviado => this.emailEnviado = emailEnviado,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.emailEnviado) {
            this.toastr.success("", "E-mail enviado com sucesso!");
          } else {
            this.toastr.error("", "Falha ao enviar e-mail!");
          }
        }
      );
    }
  }

  /* Layout */

  verificarLayout() {
    /* Empresa */

    if (window.location.href.indexOf("aokisistemas") > -1) {
      this.logomarca = "assets/logo_aoki_preto.png";
    } else if (window.location.href.indexOf("portal.cirobottini") > -1) {
      this.logomarca = "assets/logo_bottini_preto.png";
    } else {
      this.logomarca = "assets/logo_napis_preto.png";
    }
  }
}
