export class CallCenterChamada {
  public id: number;
  public idcallcenterligacao: number;
  public idsellerlogado: number;
  public numero: string;
  public inicio: Date;
  public fim: Date;
  public duracao: Date;

  constructor() {}
}
