import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Vendedor } from '../../models/vendedores/vendedor';

@Injectable({
  providedIn: 'root'
})

export class VendedorService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getByGuidClienteNapis(): Observable<Vendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Vendedor[]>(this.baseUrl + "api/Vendedor/GetByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getById(seller_id?: number): Observable<Vendedor> {
    this.headers.set("Content-Type", "application/json");

    let IdVendedor: number = 0;

    if (seller_id == null) {
      IdVendedor = parseInt(localStorage.getItem("id"));
    } else {
      IdVendedor = seller_id;
    }

    let data = {
      "seller_id": IdVendedor
    };

    return this.http.post<Vendedor>(this.baseUrl + "api/Vendedor/GetById", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getByUsuario(): Observable<Vendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<Vendedor[]>(this.baseUrl + "api/Vendedor/GetByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Master / Manager */

  public getMasterManagerByGuidClienteNapis(): Observable<Vendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Vendedor[]>(this.baseUrl + "api/Vendedor/GetMasterManagerByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Master Seller */

  public getMasterSellerByGuidClienteNapis(): Observable<Vendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Vendedor[]>(this.baseUrl + "api/Vendedor/GetMasterSellerByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getMasterSellerByUsuario(): Observable<Vendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<Vendedor[]>(this.baseUrl + "api/Vendedor/GetMasterSellerByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Rotas */

  public updateRotasLimiteUsoByVendedor(seller_id: number): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id
    };

    return this.http.post<number>(this.baseUrl + "api/Vendedor/UpdateRotasLimiteUsoByVendedor", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  /* Vendedores */

  public getVendedorByGuidClienteNapis(): Observable<Vendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Vendedor[]>(this.baseUrl + "api/Vendedor/GetVendedorByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getVendedorById(seller_id: number): Observable<Vendedor> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id,
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Vendedor>(this.baseUrl + "api/Vendedor/GetVendedorById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public update(vendedor: Vendedor): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "vendedor": vendedor
    };

    return this.http.post<boolean>(this.baseUrl + "api/Vendedor/Update", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateCallCenterDiscadorAutomaticoByVendedor(seller_id: number, call_center_discador_automatico: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id,
      "call_center_discador_automatico": call_center_discador_automatico
    };

    return this.http.post<boolean>(this.baseUrl + "api/Vendedor/UpdateCallCenterDiscadorAutomaticoByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateEmAtendimentoByVendedor(em_atendimento: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": localStorage.getItem("id"),
      "em_atendimento": em_atendimento
    };

    return this.http.post<boolean>(this.baseUrl + "api/Vendedor/UpdateEmAtendimentoByVendedor", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateSenhaByVendedor(seller_id: number, senha: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id,
      "senha": senha
    };

    return this.http.post<boolean>(this.baseUrl + "api/Vendedor/UpdateSenhaByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateSenhaInicialByVendedor(seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Vendedor/UpdateSenhaInicialByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateStatusDiscadorByVendedor(status: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": localStorage.getItem("id"),
      "status": status
    };

    return this.http.post<boolean>(this.baseUrl + "api/Vendedor/UpdateStatusDiscadorByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateStatusPortalByVendedor(status: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": localStorage.getItem("id"),
      "status": status
    };

    return this.http.post<boolean>(this.baseUrl + "api/Vendedor/UpdateStatusPortalByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Vendedores (Vendedores (Vínculo)) */

  public getVendedorVendedorVinculoByVendedor(): Observable<Vendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": parseInt(localStorage.getItem("id"))
    };

    return this.http.post<Vendedor[]>(this.baseUrl + "api/Vendedor/GetVendedorVendedorVinculoByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
