import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { OrcamentoService } from '../../core/services/orcamentos/orcamento.service';
import { OrcamentoItensService } from '../../core/services/orcamentos/orcamento-itens.service';
import { PdfService } from '../../core/tools/pdf.service';
import { PedidoService } from '../../core/services/pedidos/pedido.service';
import { PedidoStatusService } from '../../core/services/pedidos/pedido-status.service';
import { PrazoMedioService } from '../../core/services/prazos-medio/prazo-medio.service';

/* Models */

import { Configuracao } from '../../core/models/configuracoes/configuracao';
import { Estatistica } from '../../core/models/pedidos/estatistica';
import { OrcamentoItensSintetico } from '../../core/models/orcamentos/orcamento-itens-sintetico';
import { OrcamentoSintetico } from '../../core/models/orcamentos/orcamento-sintetico';
import { Pedido } from '../../core/models/pedidos/pedido';
import { PedidoBonificacaoRetorno } from '../../core/models/pedidos/pedido-bonificacao-retorno';
import { PedidoStatus } from '../../core/models/pedidos/pedido-status';
import { PrazoMedio } from '../../core/models/prazos-medio/prazo-medio';
import { PrazoMedioDesconto } from '../../core/models/prazos-medio/prazo-medio-desconto';

@Component({
  selector: 'app-pedidos-nao-enviados',
  templateUrl: './pedidos-nao-enviados.component.html',
  styleUrls: ['./pedidos-nao-enviados.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class PedidosNaoEnviadosComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Filtros */

  formFiltro: FormGroup;

  filtroOpcoes: any[] = [
    { texto: "Período (Criado)", valor: "0" },
    { texto: "Cliente", valor: "1" },
    { texto: "Nro Pedido", valor: "2" },
    { texto: "Status", valor: "3" }
  ];

  enviado: boolean = false;
  filtrado: boolean = false;

  /* Análise */

  qtdePedidos: number = 0;
  valorVendas: number = 0;
  litragemTotal: number = 0;
  valorTicketMedio: number = 0;

  /* Estatísticas */

  estatisticas: Estatistica[] = [];

  /* Status */

  filtroStatus: any[] = [
    { texto: "Todos", valor: "Todos" },
    { texto: "Orçamento", valor: "Orçamento" },
    { texto: "Não Enviado", valor: "Não Enviado" },
    { texto: "Reprovado", valor: "Reprovado" }
  ];

  /* Configurações */

  @Input() configuracao: Configuracao;

  /* Desconto Aplicado */

  porcentagemDescontoAplicado: number = null;

  porcentagemDescontoAplicadoErro: boolean = false;

  /* Desconto Simulado */

  porcentagemDescontoSimulado: number = null;

  porcentagemDescontoSimuladoErro: boolean = false;

  /* Fator Financeiro */

  exibirFatorFinanceiro: boolean = false;

  /* Orçamentos */

  orcamentos: OrcamentoSintetico[] = [];
  orcamentosOriginal: OrcamentoSintetico[] = [];

  orcamentoAlterado: boolean = false;
  orcamentoEnviado: boolean = false;

  /* Orçamentos Itens */

  orcamentosItens: OrcamentoItensSintetico[] = [];
  orcamentosItensBonificacao: OrcamentoItensSintetico[] = [];
  orcamentosItensEnviar: OrcamentoItensSintetico[] = [];
  orcamentosItensOriginal: OrcamentoItensSintetico[] = [];

  orcamentosItensSaldo: number = 0;

  itemExpandido: number = -1;

  /* Pedidos */

  pedidoDesativado: boolean = false;

  /* Pedido Bonificação (Retorno) */

  pedidoBonificacaoRetorno: PedidoBonificacaoRetorno = null;

  /* Pedidos (Status) */

  pedidosStatus: PedidoStatus[] = [];

  /* Prazo Médio */

  prazoMedio: PrazoMedio = null;

  prazoMedioDesconto: PrazoMedioDesconto = null;

  /* Modal */

  @ViewChild("comBonificacao", { static: false }) comBonificacao: ElementRef;

  /* Storage */

  is_master: boolean = (localStorage.getItem("is_master") == "true");
  is_manager: boolean = (localStorage.getItem("is_manager") == "true");
  is_master_seller: boolean = (localStorage.getItem("is_master_seller") == "true");
  is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");
  modelo_tela_ligacoes: number = parseInt(localStorage.getItem("modelo_tela_ligacoes"));

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private orcamentoItensService: OrcamentoItensService,
    private orcamentoService: OrcamentoService,
    private pedidoService: PedidoService,
    private pdfService: PdfService,
    private pedidoStatusService: PedidoStatusService,
    private prazoMedioService: PrazoMedioService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      filtro: ["0"],
      dt_inicial: ["", Validators.required],
      dt_final: ["", Validators.required],
      cliente: ["", Validators.required],
      nro_pedido: ["", Validators.required],
      status: ["Todos"],
      vendedor: [""]
    });

    this.organizarFiltro();

    this.carregarDados(true);
  }

  get f() {
    return this.formFiltro.controls;
  }

  /* Ações */

  alterar(id_pedido: number, seller_id: number) {
    let pedido: Pedido = null;

    this.pedidoService.getByOrder(id_pedido, seller_id).subscribe(
      pedidos => pedido = pedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (pedido.order_sent_on == null) {
          this.router.navigate(["/pedidos-cadastro/" + id_pedido], {
            queryParams: {
              vendedor: seller_id
            }
          });
        } else {
          this.toastr.info("", "Tente novamente...");

          this.carregarDados(false);
        }
      }
    );
  }

  desativar(id_pedido: number, seller_id: number) {
    this.orcamentoService.desativar(id_pedido, seller_id).subscribe(
      pedidoDesativado => this.pedidoDesativado = pedidoDesativado,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.pedidoDesativado) {
          this.toastr.success("", "Pedido desativado com sucesso!");
        }

        this.carregarDados(false);
      }
    );
  }

  duplicar(id_pedido: number, seller_id: number) {
    this.router.navigate(["/pedidos-cadastro/" + id_pedido], {
      queryParams: {
        duplicar: true,
        vendedor: seller_id
      }
    });
  }

  enviar(id_pedido: number, seller_id: number) {
    let pedidoBonificacaoRetorno: PedidoBonificacaoRetorno = null;

    let porcentagemDesconto = this.porcentagemDescontoAplicado == null ? 0 : this.porcentagemDescontoAplicado;

    this.pedidoService.enviar(id_pedido, seller_id, porcentagemDesconto).subscribe(
      pedidosBonificacoesRetorno => pedidoBonificacaoRetorno = pedidosBonificacoesRetorno,
      error => { console.log("Erro: " + error) },
      () => {
        this.toastr.success("", "Pedido enviado com sucesso!");

        if (this.pedidoBonificacaoRetorno != null && this.pedidoBonificacaoRetorno.numero_pedido_bonificado != 0 && this.pedidoBonificacaoRetorno.valor_pedido_bonificado != 0) {
          /* Com Bonificação */

          this.modalComBonificacao(this.comBonificacao, this.pedidoBonificacaoRetorno.numero_pedido_bonificado, seller_id);
        }

        this.carregarDados(false);
      }
    );
  }

  /* Dados */

  calcularDados() {
    /* Orçamentos */

    if (this.orcamentos != null) {
      /* Análise */

      this.qtdePedidos = this.orcamentos.length;
      this.valorVendas = this.orcamentos.reduce((sum, current) => sum + current.total, 0);
      this.litragemTotal = this.orcamentos.reduce((sum, current) => sum + current.total_litragem, 0);
      this.valorTicketMedio = 0;

      if (this.configuracao != null && this.configuracao.pedido_exibir_litragem) {
        if (this.valorVendas > 0) {
          this.valorTicketMedio = this.litragemTotal / this.qtdePedidos;
        }
      } else {
        if (this.valorVendas > 0) {
          this.valorTicketMedio = this.valorVendas / this.qtdePedidos;
        }
      }

      /* Estatísticas */

      this.estatisticas = [];

      /* Orçamento */

      let valorOrcamento = this.orcamentos.filter(orcamentos => orcamentos.status_pedido == "Orçamento").reduce((sum, current) => sum + current.total, 0);
      let valorLitragemOrcamento = this.orcamentos.filter(pedidos => pedidos.status_pedido == "Orçamento").reduce((sum, current) => sum + current.total_litragem, 0);
      let qtdeOrcamento = this.orcamentos.filter(orcamentos => orcamentos.status_pedido == "Orçamento").length;
      let porcentagemOrcamento = 0;

      if (valorOrcamento > 0) {
        porcentagemOrcamento = ((valorOrcamento / this.valorVendas) * 100);
      }

      this.estatisticas.push(new Estatistica("orcamento", "Orçamento", valorOrcamento, valorLitragemOrcamento, qtdeOrcamento, porcentagemOrcamento));

      /* Não Enviado */

      let valorNaoEnviado = this.orcamentos.filter(orcamentos => orcamentos.status_pedido == "Não Enviado").reduce((sum, current) => sum + current.total, 0);
      let valorLitragemNaoEnviado = this.orcamentos.filter(pedidos => pedidos.status_pedido == "Não Enviado").reduce((sum, current) => sum + current.total_litragem, 0);
      let qtdeNaoEnviado = this.orcamentos.filter(orcamentos => orcamentos.status_pedido == "Não Enviado").length;
      let porcentagemNaoEnviado = 0;

      if (valorNaoEnviado > 0) {
        porcentagemNaoEnviado = ((valorNaoEnviado / this.valorVendas) * 100);
      }

      this.estatisticas.push(new Estatistica("nao_enviado", "Não Enviado", valorNaoEnviado, valorLitragemNaoEnviado, qtdeNaoEnviado, porcentagemNaoEnviado));

      // Reprovado

      let valorReprovado = this.orcamentos.filter(orcamentos => orcamentos.status_pedido == "Reprovado").reduce((sum, current) => sum + current.total, 0);
      let valorLitragemReprovado = this.orcamentos.filter(pedidos => pedidos.status_pedido == "Reprovado").reduce((sum, current) => sum + current.total_litragem, 0);
      let qtdeReprovado = this.orcamentos.filter(orcamentos => orcamentos.status_pedido == "Reprovado").length;
      let porcentagemReprovado = 0;

      if (valorReprovado > 0) {
        porcentagemReprovado = ((valorReprovado / this.valorVendas) * 100);
      }

      this.estatisticas.push(new Estatistica("reprovado", "Reprovado", valorReprovado, valorLitragemReprovado, qtdeReprovado, porcentagemReprovado));

      this.estatisticas.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a["valor"], b["valor"]) * -1; /* Decrescente */
      });
    }
  }

  carregarDados(inicial: boolean) {
    this.carregando = true;

    this.itemExpandido = -1;

    /* Orçamentos */

    this.orcamentoService.getByUsuario().subscribe(
      orcamentos => this.orcamentos = orcamentos,
      error => { console.log("Erro: " + error) },
      () => {
        this.orcamentosOriginal = JSON.parse(JSON.stringify(this.orcamentos));

        this.calcularDados();

        if (!inicial) {
          this.filtrar(false);
        }
      }
    );
  }

  reiniciarDados() {
    /* Orçamentos */

    this.orcamentos = JSON.parse(JSON.stringify(this.orcamentosOriginal));
  }

  /* Desconto Aplicado / Prazo Médio / Pedido Bonificação (Retorno) */

  calcularSaldo(id_pedido: number, seller_id: number, seller_id_client: number) {
    if (this.configuracao != null && this.configuracao.usa_conta_corrente_flex) {
      let calcularSaldo: boolean;

      this.pedidoService.calcularSaldo(id_pedido, seller_id_client, seller_id).subscribe(
        calcularSaldos => calcularSaldo = calcularSaldos,
        error => { console.log("Erro: " + error) },
        () => {
          if (calcularSaldo) {
            this.toastr.success("", "Saldo calculado com sucesso!");

            this.enviar(id_pedido, seller_id);
          } else {
            this.toastr.error("", "Saldo inválido!");
          }
        }
      );
    } else {
      this.enviar(id_pedido, seller_id);
    }
  }

  carregarDadosEnviar(recarregando: boolean, orcamentoEnviado: OrcamentoSintetico) {
    let retorno = new Promise((resolver) => {
      if (recarregando) {
        return resolver("recarregando");
      } else {
        /* Prazo Médio */

        this.prazoMedio = null;

        this.prazoMedioDesconto = null;

        /* Pedido Bonificação (Retorno) */

        this.pedidoBonificacaoRetorno = null;

        if (this.configuracao != null && this.configuracao.usar_desconto_prazo_medio == "S") {
          /* Prazo Médio */

          this.prazoMedioService.getByIdPrazoPagamento(orcamentoEnviado.id_prazo_pagamento).subscribe(
            prazoMedio => this.prazoMedio = prazoMedio,
            error => { console.log("Erro: " + error) },
            () => {
              /* Orçamento Itens */

              this.orcamentoItensService.get15DiasByOrder(orcamentoEnviado.order_id_app, orcamentoEnviado.seller_id).subscribe(
                orcamentosItens => this.orcamentosItensEnviar = orcamentosItens,
                error => { console.log("Erro: " + error) },
                () => {
                  let valorTotalPadrao = this.orcamentosItensEnviar.reduce((sum, current) => (sum + (current.quantity * current.price)), 0);
                  let valorTotalAdicionado = this.orcamentosItensEnviar.reduce((sum, current) => (sum + (current.quantity * current.unit_price)), 0);

                  if (valorTotalAdicionado >= valorTotalPadrao) {
                    /* Prazo Médio (Desconto) */

                    this.prazoMedioService.getPrazoMedioDescontoByGuidClienteNapis(this.prazoMedio.prazomedio).subscribe(
                      prazoMedioDesconto => this.prazoMedioDesconto = prazoMedioDesconto,
                      error => { console.log("Erro: " + error) },
                      () => {
                        return resolver("com_modal");
                      }
                    );
                  } else {
                    return resolver("fora_tabela");
                  }
                }
              );
            }
          );
        } else {
          return resolver("enviar");
        }
      }
    });

    return retorno;
  }

  validarDescontoAplicado() {
    if (this.prazoMedioDesconto != null) {
      if (this.porcentagemDescontoAplicado > this.prazoMedioDesconto.percentual_desconto) {
        this.porcentagemDescontoAplicadoErro = true;
      } else {
        this.porcentagemDescontoAplicadoErro = false;
      }
    } else {
      this.porcentagemDescontoAplicadoErro = true;
    }
  }

  /* Desconto Simulado */

  limparDescontoSimulado() {
    this.porcentagemDescontoSimulado = null;

    this.porcentagemDescontoSimuladoErro = false;
  }

  validarDescontoSimulado() {
    if (this.porcentagemDescontoSimulado > 100) {
      this.porcentagemDescontoSimuladoErro = true;
    } else {
      this.porcentagemDescontoSimuladoErro = false;
    }
  }

  /* Expandir */

  expandir(i: number, id: number, seller_id: number) {
    this.exibirFatorFinanceiro = false;

    this.itemExpandido = i == this.itemExpandido ? -1 : i;

    this.orcamentosItensSaldo = 0;

    if (this.itemExpandido > -1) {
      this.orcamentoItensService.get15DiasByOrder(id, seller_id).subscribe(
        orcamentosItens => this.orcamentosItens = orcamentosItens,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.orcamentosItens.length > 0) {
            this.orcamentosItensBonificacao = this.orcamentosItens.filter(orcamentoItem => !orcamentoItem.is_active);

            this.orcamentosItens = this.orcamentosItens.filter(orcamentoItem => orcamentoItem.is_active);

            this.orcamentosItensSaldo = this.orcamentosItens.reduce((sum, current) => sum + current.valor_desconto, 0);

            this.orcamentosItensOriginal = JSON.parse(JSON.stringify(this.orcamentosItens));

            let qtdeProdutosFatorFinanceiro: number = this.orcamentosItens.filter(orcamentoItem => orcamentoItem.fator_financeiro != null && orcamentoItem.fator_financeiro != 0).length;

            if (qtdeProdutosFatorFinanceiro > 0) {
              this.exibirFatorFinanceiro = true;
            }
          } else {
            this.orcamentosItensOriginal = [];
          }
        }
      );
    }
  }

  /* Filtros */

  alterarFiltro(filtro_selecionado: string) {
    this.reiniciarDados();

    this.calcularDados();

    let filtroAtual: string = this.formFiltro.controls["filtro"].value;

    this.formFiltro.reset();

    if (filtro_selecionado == null) {
      this.formFiltro.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltro.controls["filtro"].setValue(filtro_selecionado);
    }

    this.formFiltro.controls["status"].setValue("Todos");

    this.organizarFiltro();

    this.enviado = false;
    this.filtrado = false;
  }

  filtrar(formulario: boolean) {
    if (formulario) {
      this.enviado = true;

      if (this.formFiltro.invalid) {
        return;
      }
    } else {
      if (this.formFiltro.controls["filtro"].value == "0") {
        /* Período (Criado) */

        if (this.formFiltro.controls["dt_inicial"].value == "" || this.formFiltro.controls["dt_inicial"].value == null || this.formFiltro.controls["dt_final"].value == "" || this.formFiltro.controls["dt_final"].value == null) {
          return;
        }
      } else if (this.formFiltro.controls["filtro"].value == "1") {
        /* Cliente */

        if (this.formFiltro.controls["cliente"].value == "" || this.formFiltro.controls["cliente"].value == null) {
          return;
        }
      } else if (this.formFiltro.controls["filtro"].value == "2") {
        /* Nro Pedido */

        if (this.formFiltro.controls["nro_pedido"].value == "" || this.formFiltro.controls["nro_pedido"].value == null) {
          return;
        }
      } else if (this.formFiltro.controls["filtro"].value == "3") {
        /* Status */

        if (this.formFiltro.controls["status"].value == "" || this.formFiltro.controls["status"].value == null) {
          return;
        }
      }
    }

    this.filtrado = true;

    /* Orçamentos */

    if (this.orcamentosOriginal != null) {
      this.orcamentos = JSON.parse(JSON.stringify(this.orcamentosOriginal));

      if (this.formFiltro.controls["filtro"].value == "0") {
        /* Período (Criado) */

        /* Dt. Inicial */

        let dataInicial = this.formFiltro.controls["dt_inicial"].value;

        dataInicial = new Date(dataInicial.year, dataInicial.month - 1, dataInicial.day);
        dataInicial = this.datePipe.transform(dataInicial, "yyyy/MM/dd");

        /* Dt. Final */

        let dataFinal = this.formFiltro.controls["dt_final"].value;

        dataFinal = new Date(dataFinal.year, dataFinal.month - 1, dataFinal.day);
        dataFinal = this.datePipe.transform(dataFinal, "yyyy/MM/dd");

        this.orcamentos = this.orcamentos.filter(orcamento => {
          let data = this.datePipe.transform(orcamento.order_created_at, "yyyy/MM/dd");

          return data >= dataInicial && data <= dataFinal;
        });
      } else if (this.formFiltro.controls["filtro"].value == "1") {
        /* Cliente */

        this.orcamentos = this.orcamentos.filter(orcamento => {
          return (orcamento.name.toLowerCase().indexOf(this.formFiltro.controls["cliente"].value.trim().toLowerCase()) !== -1);
        });
      } else if (this.formFiltro.controls["filtro"].value == "2") {
        /* Nro Pedido */

        this.orcamentos = this.orcamentos.filter(orcamento => orcamento.order_id_app == this.formFiltro.controls["nro_pedido"].value);
      } else if (this.formFiltro.controls["filtro"].value == "3") {
        /* Status */

        if (this.formFiltro.controls["status"].value != "Todos") {
          this.orcamentos = this.orcamentos.filter(orcamento => orcamento.status_pedido == this.formFiltro.controls["status"].value);
        }
      }

      if (this.formFiltro.controls["vendedor"].value != "" && this.formFiltro.controls["vendedor"].value != null) {
        /* Vendedor(a) */

        this.orcamentos = this.orcamentos.filter(orcamento => {
          return (orcamento.vend_ext.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
        });
      }
    }

    this.calcularDados();
  }

  organizarFiltro() {
    if (this.formFiltro.controls["filtro"].value == "0") {
      /* Período (Criado) */

      this.formFiltro.get("dt_inicial").enable();
      this.formFiltro.get("dt_final").enable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("nro_pedido").disable();
      this.formFiltro.get("status").disable();
    } else if (this.formFiltro.controls["filtro"].value == "1") {
      /* Cliente */

      this.formFiltro.get("dt_inicial").disable();
      this.formFiltro.get("dt_final").disable();
      this.formFiltro.get("cliente").enable();
      this.formFiltro.get("nro_pedido").disable();
      this.formFiltro.get("status").disable();
    } else if (this.formFiltro.controls["filtro"].value == "2") {
      /* Nro Pedido */

      this.formFiltro.get("dt_inicial").disable();
      this.formFiltro.get("dt_final").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("nro_pedido").enable();
      this.formFiltro.get("status").disable();
    } else if (this.formFiltro.controls["filtro"].value == "3") {
      /* Status */

      this.formFiltro.get("dt_inicial").disable();
      this.formFiltro.get("dt_final").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("nro_pedido").disable();
      this.formFiltro.get("status").enable();
    }
  }

  /* Formatação */

  formatarValoresQuatroCasasDecimais(valor: number) {
    if (valor != null) {
      return (valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
      });
    }
  }

  /* Modal */

  modalComBonificacao(content: any, numero_pedido_bonificado: number, seller_id: number) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-com-bonificacao",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Sim") {
        this.router.navigate(["/pedidos-cadastro/" + numero_pedido_bonificado], {
          queryParams: {
            vendedor: seller_id
          }
        });
      }
    }, () => {

    });
  }

  modalDescontoSimulado(content: any, id_pedido: number, seller_id: number) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-desconto-simulado",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Prosseguir") {
        if (this.porcentagemDescontoSimuladoErro) {
          this.toastr.error("", "Desconto inválido!");

          this.modalDescontoSimulado(content, id_pedido, seller_id);
        } else {
          this.pdfService.imprimirPedidoOrcamento(id_pedido, seller_id, this.porcentagemDescontoSimulado);

          this.limparDescontoSimulado();
        }
      } else {
        this.limparDescontoSimulado();
      }
    }, () => {
      this.limparDescontoSimulado();
    });
  }

  modalPedidoEnviar(content: any, id_pedido: number, seller_id: number, seller_id_client: number, recarregando: boolean) {
    let pedido: Pedido = null;

    this.pedidoService.getByOrder(id_pedido, seller_id).subscribe(
      pedidos => pedido = pedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (pedido.order_sent_on == null) {
          let orcamentoEnviado = this.orcamentos.find(orcamento => orcamento.order_id_app == id_pedido);

          if (orcamentoEnviado != null && orcamentoEnviado.id_prazo_pagamento != null) {
            let comModal: boolean = false;

            const carregarDadosEnviar = this.carregarDadosEnviar(recarregando, orcamentoEnviado);

            carregarDadosEnviar.then((resolver) => {
              if (resolver == "com_modal") {
                comModal = true;
              } else if (resolver == "enviar") {
                /* Enviar */

                this.calcularSaldo(id_pedido, seller_id, seller_id_client);
              } else if (resolver == "fora_tabela") {
                this.toastr.warning("", "Pedido fora da tabela padrão...");

                this.calcularSaldo(id_pedido, seller_id, seller_id_client);
              } else if (resolver == "recarregando") {
                comModal = true;
              }

              if (comModal) {
                this.modalService.open(content, {
                  ariaLabelledBy: "titulo-pedido-enviar",
                  size: "lg",
                  backdrop: "static"
                }).result.then((acao) => {
                  if (acao == "Enviar") {
                    if (this.porcentagemDescontoAplicadoErro) {
                      this.toastr.error("", "Desconto Aplicado inválido!");

                      this.modalPedidoEnviar(content, id_pedido, seller_id, seller_id_client, true);
                    } else {
                      /* Enviar */

                      this.calcularSaldo(id_pedido, seller_id, seller_id_client);
                    }
                  }
                }, () => {

                });
              }
            });
          } else {
            if (orcamentoEnviado != null) {
              this.toastr.info("", "Confirme as informações p/ envio!");

              this.router.navigate(["/pedidos-cadastro/" + orcamentoEnviado.order_id_app], {
                queryParams: {
                  vendedor: orcamentoEnviado.seller_id
                }
              });
            }
          }
        } else {
          this.toastr.info("", "Tente novamente...");

          this.carregarDados(false);
        }
      }
    );
  }

  modalPedidoStatus(content: any, id_pedido: number) {
    /* Pedidos (Status) */

    this.pedidoStatusService.getPedidoStatusByOrder(id_pedido).subscribe(
      pedidosStatus => this.pedidosStatus = pedidosStatus,
      error => { console.log("Erro: " + error) },
      () => {
        this.modalService.open(content, {
          ariaLabelledBy: "titulo-pedido-status",
          size: "lg",
          backdrop: "static"
        });
      }
    );
  }
}
