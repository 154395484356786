import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../../core/tools/animacao';

/* Services */

import { DashboardQuilometragemDespesaService } from '../../../core/services/dashboard/quilometragem-despesa/dashboard-quilometragem-despesa.service';
import { VendedorService } from '../../../core/services/vendedores/vendedor.service';

/* Models */

import { DashboardQuilometragemDespesaDespesa } from '../../../core/models/dashboard/quilometragem-despesa/dashboard-quilometragem-despesa-despesa';
import { DashboardQuilometragemDespesaQuilometragem } from '../../../core/models/dashboard/quilometragem-despesa/dashboard-quilometragem-despesa-quilometragem';
import { Vendedor } from '../../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-dashboard-quilometragens-despesas',
  templateUrl: './dashboard-quilometragens-despesas.component.html',
  styleUrls: ['./dashboard-quilometragens-despesas.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class DashboardQuilometragensDespesasComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Filtros */

  formFiltro: FormGroup;

  dataInicial: Date = null;
  dataFinal: Date = null;

  filtrado: boolean = false;

  /* Despesas */

  despesas: DashboardQuilometragemDespesaDespesa[] = [];
  despesasAbertas: DashboardQuilometragemDespesaDespesa[] = [];
  despesasFechadas: DashboardQuilometragemDespesaDespesa[] = [];

  despesaFechadaSelecionada: DashboardQuilometragemDespesaDespesa = null;

  despesasAbertasTotalLitros: number = 0;
  despesasAbertasTotalValor: number = 0;
  despesasFechadasTotalCombustivel: number = 0;
  despesasFechadasTotalDespesa: number = 0;
  despesasFechadasTotalLitros: number = 0;
  despesasFechadasTotalValor: number = 0;

  /* Quilometragens */

  quilometragens: DashboardQuilometragemDespesaQuilometragem[] = [];

  quilometragemSelecionada: DashboardQuilometragemDespesaQuilometragem = null;

  quilometragemFinal: number = 0;
  quilometragemInicial: number = 0;
  quilometragemTotal: number = 0;

  quilometragemPercorrida: number = 0;
  quilometragemPercorridaParticular: number = 0;
  consumoMedioVeiculo: number = 0;

  /* Vendedores */

  vendedores: Vendedor[] = [];

  vendedorSelecionado: Vendedor = null;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private dashboardQuilometragemDespesaService: DashboardQuilometragemDespesaService,
    private vendedorService: VendedorService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      dt_inicial: ["", Validators.required],
      dt_final: ["", Validators.required],
      vendedor: [""]
    });

    this.carregarDados();
  }

  get f() {
    return this.formFiltro.controls;
  }

  /* Dados */

  calcularDados() {
    this.reiniciarCalculos();

    /* Despesas (Fechadas) */

    if (this.despesasFechadas != null) {
      this.despesasAbertasTotalLitros = this.despesasAbertas.reduce((sum, current) => sum + current.liter_fuel, 0);
      this.despesasAbertasTotalValor = this.despesasAbertas.reduce((sum, current) => sum + current.total, 0);

      let despesasFechadasAprovadas = this.despesasFechadas.filter(despesaFechada => despesaFechada.aprovada);

      this.despesasFechadasTotalCombustivel = despesasFechadasAprovadas.filter(despesaFechada => despesaFechada.combustivel == "1").reduce((sum, current) => sum + current.total, 0);
      this.despesasFechadasTotalDespesa = despesasFechadasAprovadas.filter(despesaFechada => despesaFechada.combustivel != "1").reduce((sum, current) => sum + current.total, 0);
      this.despesasFechadasTotalLitros = despesasFechadasAprovadas.reduce((sum, current) => sum + current.liter_fuel, 0);
      this.despesasFechadasTotalValor = despesasFechadasAprovadas.reduce((sum, current) => sum + current.total, 0);
    }

    /* Quilometragens */

    if (this.quilometragens != null) {
      if (this.quilometragens.length > 0) {
        this.quilometragens.forEach((item) => {
          item.odometer_value = item.odometer_value == null ? 0 : item.odometer_value;
          item.final_odometer_value = item.final_odometer_value == null ? 0 : item.final_odometer_value;
          item.odometer_total = item.final_odometer_value - item.odometer_value;

          if (item.odometer_total < 0) {
            item.odometer_total = 0;
          }

          this.quilometragemFinal = item.final_odometer_value > 0 ? item.final_odometer_value : item.odometer_value;
        });

        this.quilometragemInicial = this.quilometragens[0].odometer_value == null ? 0 : this.quilometragens[0].odometer_value;
        this.quilometragemTotal = this.quilometragemFinal - this.quilometragemInicial;

        this.quilometragemPercorrida = this.quilometragens.reduce((sum, current) => sum + current.odometer_total, 0);
        this.quilometragemPercorridaParticular = this.quilometragemTotal - this.quilometragemPercorrida;

        if (this.despesasFechadasTotalLitros > 0) {
          this.consumoMedioVeiculo = this.quilometragemTotal / this.despesasFechadasTotalLitros;
        }
      }
    }
  }

  carregarDados() {
    this.carregando = true;

    /* Vendedores */

    this.vendedorService.getByUsuario().subscribe(
      vendedores => this.vendedores = vendedores,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.vendedores.length > 0) {
          this.vendedorSelecionado = this.vendedores[0];
        }

        this.carregando = false;
      }
    );
  }

  carregarDadosFiltrados(vendedor: number, dt_inicial: Date, dt_final: Date) {
    let retorno = new Promise<void>((resolver) => {
      this.carregando = true;

      /* Despesas */

      this.despesasAbertas = [];
      this.despesasFechadas = [];

      this.dashboardQuilometragemDespesaService.getDespesaByVendedor(vendedor, dt_inicial, dt_final).subscribe(
        despesas => this.despesas = despesas,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.despesas.length > 0) {
            this.despesas.forEach((item) => {
              if (item.imagem != null) {
                item.imagem = "data:image/png;base64," + item.imagem;
              }

              if (item.is_open) {
                this.despesasAbertas.push(item);
              } else {
                this.despesasFechadas.push(item);
              }
            });
          }

          /* Quilometragens */

          this.dashboardQuilometragemDespesaService.getQuilometragemByVendedor(vendedor, dt_inicial, dt_final).subscribe(
            quilometragens => this.quilometragens = quilometragens,
            error => { console.log("Erro: " + error) },
            () => {
              return resolver();
            }
          );
        }
      );
    });

    return retorno;
  }

  reiniciarCalculos() {
    /* Despesas */

    this.despesasAbertasTotalLitros = 0;
    this.despesasAbertasTotalValor = 0;
    this.despesasFechadasTotalCombustivel = 0;
    this.despesasFechadasTotalDespesa = 0;
    this.despesasFechadasTotalLitros = 0;
    this.despesasFechadasTotalValor = 0;

    /* Quilometragens */

    this.quilometragemFinal = 0;
    this.quilometragemInicial = 0;
    this.quilometragemTotal = 0;

    this.quilometragemPercorrida = 0;
    this.quilometragemPercorridaParticular = 0;
    this.consumoMedioVeiculo = 0;
  }

  /* Despesa (Abrir/Fechar) */

  abrirDespesa(id: number) {
    let alterarDespesa: boolean = false;

    this.dashboardQuilometragemDespesaService.updateIsOpenById(id, true).subscribe(
      alterarDespesas => alterarDespesa = alterarDespesas,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarDespesa) {
          let despesaFechada = this.despesasFechadas.findIndex(despesa => despesa.id == id);

          if (despesaFechada != null) {
            this.despesasFechadas[despesaFechada].is_open = true;

            this.despesasAbertas.push(this.despesasFechadas[despesaFechada]);

            this.despesasFechadas.splice(despesaFechada, 1);

            this.despesasFechadas = [...this.despesasFechadas];

            this.calcularDados();
          }
        }
      }
    );
  }

  fecharDespesa(id: number) {
    let alterarDespesa: boolean = false;

    this.dashboardQuilometragemDespesaService.updateIsOpenById(id, false).subscribe(
      alterarDespesas => alterarDespesa = alterarDespesas,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarDespesa) {
          let despesaAberta = this.despesasAbertas.findIndex(despesa => despesa.id == id);

          if (despesaAberta != null) {
            this.despesasAbertas[despesaAberta].is_open = false;

            this.despesasFechadas.push(this.despesasAbertas[despesaAberta]);

            this.despesasAbertas.splice(despesaAberta, 1);

            this.despesasAbertas = [...this.despesasAbertas];

            this.calcularDados();
          }
        }
      }
    );
  }

  /* Despesa (Aprovar/Desaprovar) */

  aprovarDespesa(id: number, aprovar: boolean) {
    let alterarDespesa: boolean = false;

    this.dashboardQuilometragemDespesaService.updateAprovadaById(id, aprovar).subscribe(
      alterarDespesas => alterarDespesa = alterarDespesas,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarDespesa) {
          let despesa = this.despesas.findIndex(despesa => despesa.id == id);

          if (despesa != null) {
            this.despesasFechadas[despesa].aprovada = aprovar;
          }

          this.calcularDados();
        }
      }
    );
  }

  /* Filtros */

  filtrar() {
    this.filtrado = false;

    if (this.formFiltro.controls["dt_inicial"].value != "" && this.formFiltro.controls["dt_inicial"].value != null &&
      this.formFiltro.controls["dt_final"].value != "" && this.formFiltro.controls["dt_final"].value != null &&
      this.vendedorSelecionado != null) {

      /* Dt. Inicial */

      let dataInicial = this.formFiltro.controls["dt_inicial"].value;

      this.dataInicial = new Date(dataInicial.year, dataInicial.month - 1, dataInicial.day);

      /* Dt. Final */

      let dataFinal = this.formFiltro.controls["dt_final"].value;

      this.dataFinal = new Date(dataFinal.year, dataFinal.month - 1, dataFinal.day);

      const carregarDadosFiltrados = this.carregarDadosFiltrados(this.vendedorSelecionado.id, this.dataInicial, this.dataFinal);

      carregarDadosFiltrados.then(() => {
        this.calcularDados();

        this.carregando = false;
        this.filtrado = true;
      });
    }
  }

  removerFiltros() {
    this.formFiltro.reset();

    Object.keys(this.formFiltro.controls).forEach(key => {
      this.formFiltro.controls[key].setValue("");
    });

    this.filtrado = false;
  }

  /* Imprimir */

  imprimir() {
    window.print();
  }

  /* Vendedor(a) */

  selecionarVendedor(id_vendedor: string) {
    this.vendedorSelecionado = this.vendedores.find(vendedor => vendedor.id == parseInt(id_vendedor));
  }

  /* Modal */

  modalImagens(content: any, tipo: string, id: string) {
    this.despesaFechadaSelecionada = null;
    this.quilometragemSelecionada = null;

    switch (tipo) {
      case "despesaFechada":
        this.despesaFechadaSelecionada = this.despesasFechadas.find(despesa => despesa.id == parseInt(id));

        break;
      case "quilometragem":
        this.quilometragemSelecionada = this.quilometragens.find(quilometragem => quilometragem.id == id);

        break;
      default:

    }

    if (this.despesaFechadaSelecionada != null || this.quilometragemSelecionada != null) {
      this.modalService.open(content, {
        ariaLabelledBy: "titulo-imagens",
        size: "lg",
        backdrop: "static"
      });
    }
  }
}
