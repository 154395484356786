import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../call-center/call-center.service';
import { UsuarioService } from '../usuarios/usuario.service';
import { VendedorService } from '../vendedores/vendedor.service';

/* Models */

import { CallCenterConfiguracao } from '../../models/call-center/call-center-configuracao';
import { Usuario } from '../../models/usuarios/usuario';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  /* Call Center (Configurações) */

  callCenterConfiguracao: CallCenterConfiguracao = null;

  /* Emitter */

  acessoEmitter = new EventEmitter<boolean>();
  autorizacaoEmitter = new EventEmitter<boolean>();
  centralAtendimentoEmitter = new EventEmitter<boolean>();
  centralAtendimentoEstiloEmitter = new EventEmitter<boolean>();
  navBarEmitter = new EventEmitter<void>();

  /* Usuário */

  private usuario: Usuario = null;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private usuarioService: UsuarioService,
    private vendedorService: VendedorService
  ) { }

  autorizar(usuario: string, senha: string, criptografada: boolean) {
    this.usuarioService.getByLogin(usuario, senha, criptografada).subscribe(
      usuario => this.usuario = usuario,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.usuario == null) {
          this.toastr.error("", "Usuário inválido!");

          this.acessoEmitter.emit(false);
        } else {
          if (!this.usuario.acesso_liberado) {
            this.toastr.error("", "Acesso bloqueado!");

            this.acessoEmitter.emit(false);
          } else {
            /* Usuário */

            localStorage.setItem("fullname", this.usuario.fullname.toString());
            localStorage.setItem("guidclientenapis", this.usuario.guidclientenapis.toString());
            localStorage.setItem("id", this.usuario.id.toString());
            localStorage.setItem("is_master", this.usuario.is_master.toString());
            localStorage.setItem("is_manager", this.usuario.is_manager.toString());
            localStorage.setItem("is_master_seller", this.usuario.is_master_seller.toString());
            localStorage.setItem("is_diretor", this.usuario.is_diretor.toString());

            if (this.usuario.obs_gerais != null) {
              localStorage.setItem("obs_gerais", this.usuario.obs_gerais.toString());
            }

            if (this.usuario.usa_pabx != null) {
              localStorage.setItem("usa_pabx", this.usuario.usa_pabx.toString());
            }

            if (this.usuario.usa_whatss != null) {
              localStorage.setItem("usa_whatsapp", this.usuario.usa_whatss.toString());
            }

            if (this.usuario.seller_ver_aba_call_center != null) {
              localStorage.setItem("seller_ver_aba_call_center", this.usuario.seller_ver_aba_call_center.toString());
            }

            if (this.usuario.token_innersac != null) {
              localStorage.setItem("token_innersac", this.usuario.token_innersac.toString());
            }

            if (this.usuario.link_innersac != null) {
              localStorage.setItem("link_innersac", this.usuario.link_innersac.toString());
            }

            let alterarStatusPortal = false;

            this.vendedorService.updateStatusPortalByVendedor(true).subscribe(
              alterarStatusPortais => alterarStatusPortal = alterarStatusPortais,
              error => { console.log("Erro: " + error) },
              () => {

              }
            );

            /* B. I. */

            if (this.usuario.link_bi != null) {
              localStorage.setItem("link_bi", this.usuario.link_bi.toString());
            }

            /* Call Center (Configuração) */

            this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
              callCenterConfiguracao => this.callCenterConfiguracao = callCenterConfiguracao,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.callCenterConfiguracao != null) {
                  /* Call Center */

                  localStorage.setItem("modelo_tela_ligacoes", this.callCenterConfiguracao.modelo_tela_ligacoes == null ? "0" : this.callCenterConfiguracao.modelo_tela_ligacoes.toString());

                  /* Central de Atendimento */

                  if (this.callCenterConfiguracao.integrado_pabx || this.callCenterConfiguracao.integrado_whatss) {
                    localStorage.setItem("central_atendimento", "true");

                    /* Telefonia */

                    let usa_pabx: boolean = (localStorage.getItem("usa_pabx") == "true");

                    if (this.callCenterConfiguracao.integrado_pabx && usa_pabx) {
                      if (this.callCenterConfiguracao.estilo_central_atendimento != null) {
                        if (this.callCenterConfiguracao.estilo_central_atendimento == 1) {
                          /* Móvel */

                          localStorage.setItem("central_atendimento_estilo", "1");

                          this.centralAtendimentoEstiloEmitter.emit(false);
                        } else {
                          /* Lateral */

                          this.centralAtendimentoEstiloEmitter.emit(true);
                        }
                      } else {
                        /* Lateral */

                        this.centralAtendimentoEstiloEmitter.emit(true);
                      }

                      localStorage.setItem("ddd_local", this.callCenterConfiguracao.ddd_local.toString());

                      if (this.callCenterConfiguracao.tipo_softphone != null) {
                        localStorage.setItem("tipo_softphone", this.callCenterConfiguracao.tipo_softphone.toString());
                      }
                    } else {
                      localStorage.setItem("usa_pabx", "false");
                    }

                    /* WhatsApp */

                    let usa_whatsapp: boolean = (localStorage.getItem("usa_whatsapp") == "true");

                    if (!this.callCenterConfiguracao.integrado_whatss) {
                      localStorage.setItem("usa_whatsapp", "false");
                    }

                    if (!usa_pabx && !usa_whatsapp) {
                      localStorage.setItem("central_atendimento", "false");
                    }

                    this.centralAtendimentoEmitter.emit(true);
                    this.navBarEmitter.emit();
                  } else {
                    this.centralAtendimentoEmitter.emit(false);
                    this.navBarEmitter.emit();
                  }
                }

                let cliente_consulta: string = localStorage.getItem("cliente_consulta");

                if (cliente_consulta != null && cliente_consulta != "") {
                  this.router.navigate(["/call-center-ligacao"], {
                    queryParams: {
                      cliente_consulta: cliente_consulta
                    }
                  });
                } else {
                  if (this.usuario.is_master_seller) {
                    this.router.navigate(["/call-center"]);
                  } else {
                    this.router.navigate(["/pedidos"]);
                  }
                }

                this.acessoEmitter.emit(true);
                this.autorizacaoEmitter.emit(true);
              }
            );
          }
        }
      }
    );
  }

  desautorizar(redirecionar: boolean) {
    let alterarStatusPortal = false;

    this.vendedorService.updateStatusPortalByVendedor(false).subscribe(
      alterarStatusPortais => alterarStatusPortal = alterarStatusPortais,
      error => { console.log("Erro: " + error) },
      () => {

      }
    );

    this.acessoEmitter.emit(false);
    this.autorizacaoEmitter.emit(false);
    this.centralAtendimentoEmitter.emit(false);
    this.centralAtendimentoEstiloEmitter.emit(false);

    this.iniciarStorage();

    if (redirecionar) {
      this.router.navigate(["/login"]);
    }
  }

  statusAutorizacao(): boolean {
    if (localStorage.length > 0) {
      /* Data/Hora */

      let dataHoraAtual: Date = new Date();
      let dataHoraExpiracao: Date = new Date(localStorage.getItem("data_hora"));

      if (dataHoraAtual < dataHoraExpiracao) {
        /* Usuário */

        let id: string = localStorage.getItem("id");
        let guidclientenapis: string = localStorage.getItem("guidclientenapis");

        if (id != null && id != "0" && guidclientenapis != null && guidclientenapis != "") {
          /* Central Atendimento */

          let centralAtendimento: boolean = (localStorage.getItem("central_atendimento") == "true");

          this.centralAtendimentoEmitter.emit(centralAtendimento);

          /* Central Atendimento (Estilo) */

          let centralAtendimentoEstilo: boolean = localStorage.getItem("central_atendimento_estilo") == "0" ? true : false; /* lateral / movel */

          this.centralAtendimentoEstiloEmitter.emit(centralAtendimentoEstilo);

          this.acessoEmitter.emit(true);

          return true;
        }
      } else {
        if (this.router.isActive("", false) || this.router.isActive("login", false)) {
          /* Storage */

          /* Data/Hora */

          let dataHora: Date = new Date();

          dataHora.setHours(dataHora.getHours() + 48);

          localStorage.setItem("data_hora", dataHora.toString());
        }
      }
    }

    return false;
  }

  /* Storage */

  iniciarStorage() {
    localStorage.clear();

    /* B. I. */

    localStorage.setItem("link_bi", "");

    /* Call Center */

    localStorage.setItem("chamadas_automaticas", "false");
    localStorage.setItem("discador_ativo", "false");
    localStorage.setItem("discador_pausado", "false");
    localStorage.setItem("id_call_center_ligacao", "0");
    localStorage.setItem("modelo_tela_ligacoes", "0");

    /* Central de Atendimento */

    localStorage.setItem("central_atendimento", "false");
    localStorage.setItem("central_atendimento_estilo", "0");
    localStorage.setItem("ddd_local", "0");
    localStorage.setItem("telefonia_guid", "");
    localStorage.setItem("tipo_softphone", "0");

    /* Data/Hora */

    let dataHora: Date = new Date();

    dataHora.setHours(dataHora.getHours() + 48);

    localStorage.setItem("data_hora", dataHora.toString());

    /* Usuário */

    localStorage.setItem("fullname", "");
    localStorage.setItem("guidclientenapis", "");
    localStorage.setItem("id", "0");
    localStorage.setItem("is_master", "false");
    localStorage.setItem("is_manager", "false");
    localStorage.setItem("is_master_seller", "false");
    localStorage.setItem("obs_gerais", "");
    localStorage.setItem("usa_pabx", "false");
    localStorage.setItem("usa_whatsapp", "false");
    localStorage.setItem("seller_ver_aba_call_center", "false");
    localStorage.setItem("token_innersac", "");
    localStorage.setItem("link_innersac", "");
  }
}
