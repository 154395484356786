export class Visita {
  public id: number;
  public client_id: number;
  public check_in: Date;
  public check_out: Date;
  public check_in_distance_in_meters: number;
  public no_order_done_reason_id: number;
  public note: string;
  public is_active: boolean;
  public updated_at: string;
  public created_at: Date;
  public seller_id: number;
  public deleted: boolean;
  public visit_id_app: number;
  public dt_ultima_atualizacao: Date;
  public id_sinc: number;
  public callcenter_tipo: string;
  public callcenter_status: number;
  public id_call_center_ligacao: number;
  public fullname: string;
  public email_real: string;
  public celular: string;
  public duracao: number;
  public name: string;

  constructor() { }
}
