import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { MotivoNaoVendaService } from '../../core/services/motivos-nao-venda/motivo-nao-venda.service';

/* Models */

import { MotivoNaoVenda } from '../../core/models/motivos-nao-venda/motivo-nao-venda';

@Component({
  selector: 'app-motivos-nao-venda-cadastro',
  templateUrl: './motivos-nao-venda-cadastro.component.html',
  styleUrls: ['./motivos-nao-venda-cadastro.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class MotivosNaoVendaCadastroComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Dados */

  motivoNaoVenda: MotivoNaoVenda = null;

  visitaEfetivaSelecionada: number = 0;

  /* Formulário */

  formDados: FormGroup;

  enviado: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private motivoNaoVendaService: MotivoNaoVendaService
  ) { }

  ngOnInit() {
    this.formDados = this.formBuilder.group({
      motivo: ["", Validators.required]
    });

    this.verificarAdicionandoAlterando();
  }

  get f() {
    return this.formDados.controls;
  }

  /* Dados */

  addUpdate() {
    let retorno = new Promise<void>((resolver) => {
      let motivoNaoVenda = new MotivoNaoVenda();

      motivoNaoVenda.name = this.formDados.controls["motivo"].value;
      motivoNaoVenda.is_active = true;
      motivoNaoVenda.guidclientenapis = localStorage.getItem("guidclientenapis");
      motivoNaoVenda.flag_visita_efetiva = this.visitaEfetivaSelecionada == 0 ? false : true;

      if (this.motivoNaoVenda != null) {
        /* Alterando */

        motivoNaoVenda.id = this.motivoNaoVenda.id;

        let alterarMotivoNaoVenda: boolean = false;

        this.motivoNaoVendaService.update(motivoNaoVenda).subscribe(
          alterarMotivo => alterarMotivoNaoVenda = alterarMotivo,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarMotivoNaoVenda) {
              this.toastr.success("", "Motivo (Não Venda) salvo com sucesso!");
            }

            return resolver();
          }
        );
      } else {
        /* Adicionando */

        let adicionarMotivoNaoVenda: boolean = false;

        this.motivoNaoVendaService.add(motivoNaoVenda).subscribe(
          adicionarMotivo => adicionarMotivoNaoVenda = adicionarMotivo,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarMotivoNaoVenda) {
              this.toastr.success("", "Motivo (Não Venda) salvo com sucesso!");
            }

            return resolver();
          }
        );
      }
    });

    return retorno;
  }

  prepararReceberDados(id: number) {
    let retorno = new Promise<void>((resolver) => {
      this.motivoNaoVendaService.getById(id).subscribe(
        motivoNaoVenda => this.motivoNaoVenda = motivoNaoVenda,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.motivoNaoVenda != null) {
            this.formDados.controls["motivo"].setValue(this.motivoNaoVenda.name);

            this.visitaEfetivaSelecionada = this.motivoNaoVenda.flag_visita_efetiva == false ? 0 : 1;

            return resolver();
          } else {
            this.toastr.error("", "Motivo (Não Venda) inválido!");

            this.router.navigate(["/motivos-nao-venda/"]);
          }
        }
      );
    });

    return retorno;
  }

  salvar() {
    this.enviado = true;

    if (this.formDados.invalid) {
      return;
    }

    const addUpdate = this.addUpdate();

    addUpdate.then(() => {
      this.router.navigate(["/motivos-nao-venda/"]);
    });
  }

  verificarAdicionandoAlterando() {
    this.carregando = true;

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    let id: string = null;

    this.route.params.subscribe(params => { id = params.id; });

    if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
      /* Alterando */

      const prepararReceberDados = this.prepararReceberDados(parseInt(id));

      prepararReceberDados.then(() => {
        this.carregando = false;
      });
    } else {
      /* Adicionando */

      this.carregando = false;
    }
  }

  /* Formulário */

  selecionarVisitaEfetiva(visita_efetiva: number) {
    this.visitaEfetivaSelecionada = visita_efetiva;
  }
}
