import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Animacao } from '../../core/tools/animacao';

/* Services */

import { ConfiguracaoService } from '../../core/services/configuracoes/configuracao.service';

/* Models */

import { Configuracao } from '../../core/models/configuracoes/configuracao';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class PedidosComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Abas */

  tabSelecionada: string = "atencao";

  /* Configurações */

  configuracao: Configuracao = null;

  constructor(
    private route: ActivatedRoute,
    private configuracaoService: ConfiguracaoService
  ) { }

  ngOnInit() {
    /* Abas */

    this.route.queryParams.subscribe(parametros => {
      if (parametros["tab"] != undefined) {
        if (parametros["tab"] == "atencao" || parametros["tab"] == "enviados" || parametros["tab"] == "nao-enviados") {
          this.tabSelecionada = parametros["tab"];
        }
      }
    });

    this.carregarDados();
  }

  /* Abas */

  alterarAbas(evento: any) {
    this.tabSelecionada = evento.nextId;
  }

  /* Ações */

  atualizar() {
    this.carregarDados();
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;

    /* Configurações */

    this.configuracaoService.getByGuidClienteNapis().subscribe(
      configuracoes => this.configuracao = configuracoes,
      error => { console.log("Erro: " + error) },
      () => {
        this.carregando = false;
      }
    );
  }
}
