import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { RotaSintetica } from '../../models/rotas/rota-sintetica';
import { RotaConfiguracao } from '../../models/rotas/rota-configuracao';

@Injectable({
  providedIn: 'root'
})

export class RotaService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Configuração */

  public getConfiguracaoByGuidClienteNapis(): Observable<RotaConfiguracao> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<RotaConfiguracao>(this.baseUrl + "api/Rota/GetConfiguracaoByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  /* Sintética */

  public getByVendedor(seller_id: number): Observable<RotaSintetica[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id
    };

    return this.http.post<RotaSintetica[]>(this.baseUrl + "api/Rota/GetByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateIsActiveById(id: string, route_id_app: number, is_active: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "route_id_app": route_id_app,
      "is_active": is_active
    };

    return this.http.post<boolean>(this.baseUrl + "api/Rota/UpdateIsActiveById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateIsActiveByRoute(route_id_app: number, is_active: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "route_id_app": route_id_app,
      "is_active": is_active
    };

    return this.http.post<boolean>(this.baseUrl + "api/Rota/UpdateIsActiveByRoute", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateNameDiaVisitaByRouteIdApp(route_id_app: number, name: string, dia_visita: Date): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "route_id_app": route_id_app,
      "name": name,
      "dia_visita": dia_visita
    };

    return this.http.post<boolean>(this.baseUrl + "api/Rota/UpdateNameDiaVisitaByRouteIdApp", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateRotaByRouteId(pontos: RotaSintetica[]): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "pontos": pontos
    };

    return this.http.post<boolean>(this.baseUrl + "api/Rota/UpdateRotaByRouteId", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateRotaDistanciaById(ponto: RotaSintetica): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "ponto": ponto
    };

    return this.http.post<boolean>(this.baseUrl + "api/Rota/UpdateRotaDistanciaById", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
