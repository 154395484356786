import { Component, OnInit } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';
import { Subscription, timer } from 'rxjs';

/* Services */

import { CanaisComunicacaoWhatsappService } from '../../core/services/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp.service';

/* Models */

import { CanaisComunicacaoWhatsAppQRCode } from '../../core/models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-qr-code';

@Component({
  selector: 'app-whatsapp-instancia',
  templateUrl: './whatsapp-instancia.component.html',
  styleUrls: ['./whatsapp-instancia.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class WhatsappInstanciaComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Canais de Comunicação (WhatsApp) */

  canaisComunicacaoWhatsAppQRCode: CanaisComunicacaoWhatsAppQRCode = null;

  verificarQRCode = new Subscription();

  constructor(
    private canaisComunicacaoWhatsAppService: CanaisComunicacaoWhatsappService
  ) { }

  ngOnDestroy() {
    this.verificarQRCode.unsubscribe();
  }

  ngOnInit() {
    this.carregarDados();
  }

  carregarDados() {
    this.carregando = true;

    this.verificarQRCode = timer(0, 5000).subscribe(() => { /* Início / Intervalo */
      this.canaisComunicacaoWhatsAppService.getQRCode().subscribe(
        canalComunicacaoWhatsAppQRCode => this.canaisComunicacaoWhatsAppQRCode = canalComunicacaoWhatsAppQRCode,
        error => { console.log("Erro: " + error) },
        () => {
          this.carregando = false;
        }
      );
    });
  }
}
