import { Component, OnInit } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';

/* Services */

import { ListenerService } from '../../core/services/listener/listener.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class LoaderComponent implements OnInit {
  carregando: boolean = false;

  constructor(
    private listenerService: ListenerService
  ) {
    this.listenerService.verificarRequisicoesPendentes().subscribe(pendente => {
      this.carregando = pendente;
    });
  }

  ngOnInit() {

  }
}
