import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Visita } from '../../models/visitas/visita';
import { VisitaImagem } from '../../models/visitas/visita-imagem';

@Injectable({
  providedIn: 'root'
})

export class VisitaService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public add(visita: Visita): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "visita": visita
    };

    return this.http.post<boolean>(this.baseUrl + "api/Visita/Add", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getById(id: number): Observable<Visita> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<Visita>(this.baseUrl + "api/Visita/GetById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getByVisitIdApp(visit_id_app: number): Observable<Visita> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "visit_id_app": visit_id_app
    };

    return this.http.post<Visita>(this.baseUrl + "api/Visita/GetByVisitIdApp", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getProximoNumeroVisitaBySeller(): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": localStorage.getItem("id")
    };

    return this.http.post<number>(this.baseUrl + "api/Visita/GetProximoNumeroVisitaBySeller", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public getUltimaVisitaByCliente(client_id_app: number): Observable<Visita> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<Visita>(this.baseUrl + "api/Visita/GetUltimaVisitaByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getVisitaByUsuario(): Observable<Visita[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<Visita[]>(this.baseUrl + "api/Visita/GetVisitaByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getVisitaByCliente(client_id: number): Observable<Visita[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id": client_id
    };

    return this.http.post<Visita[]>(this.baseUrl + "api/Visita/GetVisitaByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public update(visita: Visita): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "visita": visita
    };

    return this.http.post<boolean>(this.baseUrl + "api/Visita/Update", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateObservacaoById(id: number, observacao: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "observacao": observacao
    };

    return this.http.post<boolean>(this.baseUrl + "api/Visita/UpdateObservacaoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Imagens */

  public getVisitaImagemByVisitIdApp(visit_id_app: number): Observable<VisitaImagem[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "visit_id_app": visit_id_app
    };

    return this.http.post<VisitaImagem[]>(this.baseUrl + "api/Visita/GetVisitaImagemByVisitIdApp", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
