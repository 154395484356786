import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { CallCenterMotivoPausa } from '../../core/models/call-center/call-center-motivo-pausa';
import { CallCenterPausa } from '../../core/models/call-center/call-center-pausa';
import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-call-center-gerenciar-discador',
  templateUrl: './call-center-gerenciar-discador.component.html',
  styleUrls: ['./call-center-gerenciar-discador.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CallCenterGerenciarDiscadorComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Call Center (Motivos Pausa) */

  motivosPausa: CallCenterMotivoPausa[] = [];

  motivoPausaInvalido: boolean = false;

  motivoPausaSelecionado: CallCenterMotivoPausa = null;

  motivoPausaTempo: { hour: number, minute: number } = null;

  motivoPausaTempoInvalido: boolean = false;

  /* Pausa */

  pausas: CallCenterPausa[] = [];

  pausaSelecionada: CallCenterPausa = null;

  /* Vendedores */

  vendedores: Vendedor[] = [];

  vendedorExpandido: number = -1;

  vendedorSelecionado: Vendedor = null;

  /* Hora Inicial */

  horaInicial: { hour: number, minute: number } = null;

  horaInicialInvalida: boolean = false;

  constructor(
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private vendedorService: VendedorService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    this.carregarDados();
  }

  /* Ações */

  alterarCallCenterDiscadorAutomatico(seller_id: number, call_Center_discador_automatico: boolean) {
    let alterarCallCenterDiscadorAutomatico: boolean = false;

    this.vendedorService.updateCallCenterDiscadorAutomaticoByVendedor(seller_id, call_Center_discador_automatico).subscribe(
      alterarCallCenterDiscadoresAutomaticos => alterarCallCenterDiscadorAutomatico = alterarCallCenterDiscadoresAutomaticos,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarCallCenterDiscadorAutomatico) {
          this.carregarDados();

          if (call_Center_discador_automatico) {
            this.toastr.success("", "Discador Automático iniciado!");
          } else {
            this.toastr.success("", "Discador Manual iniciado!");
          }
        }
      }
    );
  }

  deletarPausa(id: number) {
    let pausa = this.pausas.findIndex(pausa => pausa.id == id);

    if (pausa != -1) {
      let deletarPausa: boolean = false;

      this.callCenterService.deletePausaById(id).subscribe(
        deletarPausas => deletarPausa = deletarPausas,
        error => { console.log("Erro: " + error) },
        () => {
          if (deletarPausa) {
            this.pausas.splice(pausa, 1);

            this.pausas = [...this.pausas];

            this.toastr.success("", "Pausa excluída com sucesso!");
          }
        }
      );
    }
  }

  expandir(i: number, seller_id: number) {
    this.vendedorExpandido = i == this.vendedorExpandido ? -1 : i;

    if (this.vendedorExpandido > -1) {
      this.callCenterService.getPausaProgramadaByVendedor(seller_id).subscribe(
        pausas => this.pausas = pausas,
        error => { console.log("Erro: " + error) },
        () => {

        }
      );
    }
  }

  /* Dados */

  addUpdate() {
    let retorno = new Promise<void>((resolver) => {
      let pausa = new CallCenterPausa();

      pausa.seller_id = this.vendedorSelecionado.id;
      pausa.id_motivo_pausa = this.motivoPausaSelecionado.id;

      /* Hora Inicial */

      let horaInicial = new Date();

      horaInicial.setHours(this.horaInicial == null ? 0 : this.horaInicial.hour);
      horaInicial.setMinutes(this.horaInicial == null ? 0 : this.horaInicial.minute);
      horaInicial.setSeconds(0);

      pausa.hr_inicial_pausa = this.datePipe.transform(horaInicial, "HH:mm:ss");

      /* Tempo */

      let tempo = new Date();

      tempo.setHours(this.motivoPausaTempo == null ? 0 : this.motivoPausaTempo.hour);
      tempo.setMinutes(this.motivoPausaTempo == null ? 0 : this.motivoPausaTempo.minute);
      tempo.setSeconds(0);

      pausa.tempo_pausa = this.datePipe.transform(tempo, "HH:mm:ss");

      if (this.pausaSelecionada != null) {
        /* Alterando */

        pausa.id = this.pausaSelecionada.id;

        let alterarPausa: boolean = false;

        this.callCenterService.updatePausa(pausa).subscribe(
          alterarPausas => alterarPausa = alterarPausas,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarPausa) {
              this.toastr.success("", "Pausa salva com sucesso!");
            }

            return resolver();
          }
        );
      } else {
        /* Adicionando */

        let adicionarPausa: boolean = false;

        this.callCenterService.addPausa(pausa).subscribe(
          adicionarPausas => adicionarPausa = adicionarPausas,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarPausa) {
              this.toastr.success("", "Pausa salva com sucesso!");
            }

            return resolver();
          }
        );
      }
    });

    return retorno;
  }

  carregarDados() {
    this.carregando = true;

    /* Vendedores */

    this.vendedorService.getMasterSellerByUsuario().subscribe(
      vendedores => this.vendedores = vendedores,
      error => { console.log("Erro: " + error) },
      () => {
        /* Call Center (Motivos Pausa) */

        this.callCenterService.getMotivoPausaByGuidClienteNapis(true).subscribe(
          motivosPausa => this.motivosPausa = motivosPausa,
          error => { console.log("Erro: " + error) },
          () => {
            this.carregando = false;
          }
        );
      }
    );
  }

  /* Call Center (Motivos Pausa) */

  selecionarMotivoPausa(id_motivo: string) {
    if (id_motivo != null) {
      this.motivoPausaSelecionado = this.motivosPausa.find(motivoPausa => motivoPausa.id == parseInt(id_motivo));
    } else {
      if (this.motivosPausa.length > 0) {
        this.motivoPausaSelecionado = this.motivosPausa[0];
      }
    }

    if (this.motivoPausaSelecionado != null) {
      let tempo = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " " + this.motivoPausaSelecionado.tempo_pausa);

      this.motivoPausaTempo = {
        hour: tempo.getHours(),
        minute: tempo.getMinutes()
      };
    }
  }

  /* Vendedores */

  selecionarVendedor(id_vendedor: string) {
    if (id_vendedor != null) {
      this.vendedorSelecionado = this.vendedores.find(vendedor => vendedor.id == parseInt(id_vendedor));
    } else {
      if (this.vendedores.length > 0) {
        this.vendedorSelecionado = this.vendedores[0];
      }
    }
  }

  /* Modal */

  modalGerenciarPausa(content: any, id_pausa: number) {
    if (!this.motivoPausaInvalido && !this.horaInicialInvalida && !this.motivoPausaTempoInvalido) {
      if (id_pausa != null) {
        /* Alterando */

        this.pausaSelecionada = this.pausas.find(pausa => pausa.id == id_pausa);

        if (this.pausaSelecionada != null) {
          this.selecionarMotivoPausa(this.pausaSelecionada.id_motivo_pausa.toString());

          this.selecionarVendedor(this.pausaSelecionada.seller_id.toString());

          /* Hora Inicial */

          let horaInicial = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " " + this.pausaSelecionada.hr_inicial_pausa);

          this.horaInicial = {
            hour: horaInicial.getHours(),
            minute: horaInicial.getMinutes()
          };

          /* Tempo */

          let tempo = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " " + this.pausaSelecionada.tempo_pausa);

          this.motivoPausaTempo = {
            hour: tempo.getHours(),
            minute: tempo.getMinutes()
          };
        }
      } else {
        /* Adicionando */

        this.pausaSelecionada = null;

        this.selecionarMotivoPausa(null);

        this.selecionarVendedor(null);

        /* Hora Inicial */

        this.horaInicial = {
          hour: null,
          minute: null
        };
      }
    }

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-gerenciar-pausa",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Salvar") {
        /* Motivo */

        if (this.motivoPausaSelecionado == null) {
          this.motivoPausaInvalido = true;
        } else {
          this.motivoPausaInvalido = false;
        }

        /* Hora Inicial */

        if (this.horaInicial == null || this.horaInicial.hour == null || this.horaInicial.minute == null) {
          this.horaInicialInvalida = true;
        } else {
          this.horaInicialInvalida = false;
        }

        /* Tempo */

        if (this.motivoPausaTempo == null || this.motivoPausaTempo.hour == null || this.motivoPausaTempo.minute == null || (this.motivoPausaTempo.hour == 0 && this.motivoPausaTempo.minute == 0)) {
          this.motivoPausaTempoInvalido = true;
        } else {
          this.motivoPausaTempoInvalido = false;
        }

        if (!this.motivoPausaInvalido && !this.horaInicialInvalida && !this.motivoPausaTempoInvalido) {
          const addUpdate = this.addUpdate();

          addUpdate.then(() => {
            this.vendedorExpandido = -1;

            this.carregarDados();
          });
        } else {
          this.modalGerenciarPausa(content, id_pausa);
        }
      } else {
        this.motivoPausaInvalido = false;

        this.horaInicialInvalida = false;

        this.motivoPausaTempoInvalido = false;
      }
    }, () => {
      this.motivoPausaInvalido = false;

      this.horaInicialInvalida = false;

      this.motivoPausaTempoInvalido = false;
    });
  }
}
