export class CentralAtendimentoEvento {
  public id: number;
  public data_hora: Date;

  constructor(
    public tipo: string,
    public acao: string,
    public titulo: string,
    public mensagem: string,
    public mensagem_contador: number,
    public visualizado: boolean,
    public exibir: boolean,
    public tentar_novamente: boolean,
    public atender: boolean,
    public redirecionar: boolean,
    public identificacao: string,
    public codigo_no_erp_do_cliente: string,
    public numero_telefone: string,
    public arquivo: File,
    public base64: string,
    public client_id_app: number
  ) { }
}
