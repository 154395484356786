export class Cliente {
  public id: number;
  public name: string;
  public oficial_address: string;
  public oficial_gps_lat: number;
  public oficial_gps_lon: number;
  public field_address: string;
  public field_gps_lat: number;
  public field_gps_lon: number;
  public note: string;
  public client_objective_id: number;
  public relationship_level_id: number;
  public is_active: boolean;
  public updated_at: string;
  public created_at: Date;
  public client_state_id: number;
  public responsible_name: string;
  public phone: string;
  public email: string;
  public funnel_classification_id: number;
  public seller_id: number;
  public segment: string;
  public client_document: string;
  public dth_edicao: Date;
  public guidclientenapis: string;
  public ie: string;
  public fantasia: string;
  public cep: string;
  public endereco: string;
  public end_numero: string;
  public end_complemento: string;
  public bairro: string;
  public cidade: string;
  public estado: string;
  public dt_ultima_atualizacao: Date;
  public tipo_logradouro: string;
  public client_id_app: number;
  public flag_novo_cliente: string;
  public dt_integracao: Date;
  public status_financeiro: string;
  public email_02: string;
  public fone_02: string;
  public entrega_endereco: string;
  public entrega_numero: string;
  public entrega_complemento: string;
  public entrega_bairro: string;
  public entrega_cidade: string;
  public entrega_estado: string;
  public fone_03: string;
  public codigo_no_erp_do_cliente: string;
  public objetivo_de_venda: number;
  public id_origem: number;
  public detalhes_origem: string;
  public orgao_emissor_rg: string;
  public escolaridade: string;
  public nome_pai: string;
  public nome_mae: string;
  public cd_transportadora: string;
  public ultima_compra: Date;
  public realizado_venda: number;
  public ultima_visita: Date;
  public banco_nome: string;
  public banco_agencia: string;
  public banco_conta: string;
  public banco_nome_contato: string;
  public banco_telefone: string;
  public fornecedor_referencia_01: string;
  public fornecedor_telefone_01: string;
  public fornecedor_contato_01: string;
  public fornecedor_referencia_02: string;
  public fornecedor_telefone_02: string;
  public fornecedor_contato_02: string;
  public fornecedor_referencia_03: string;
  public fornecedor_telefone_03: string;
  public fornecedor_contato_03: string;
  public codigo_ibge: number;
  public contato_callcenter: string;
  public password_b2b: string;
  public resetar_senha: boolean;
  public limite_credito_disponivel: number;
  public cod_communicator: string;
  public fone_whatss: string;
  public fone_whatss_ddd: string;
  public classificacao: string;
  public cadastro_efetivado: boolean;
  public visita_frequencia: number;
  public potencial_ano: number;
  public realizado_ano: number;
  public realizado_venda_mesanterior: number;
  public ratio_cliente: number;
  public ratio_segmento: number;
  public pv: string;
  public codigo_ge: string;
  public nome_ge: string;
  public zona_vendas: string;
  public b2b_margem_consumidor: number;
  public erro_integracao_status: string;
  public inside_sales_ativo: boolean;
  public inside_sales_ativo_dtatualizacao: Date;
  public flag_ativo_integracao: boolean;
  public client_rede_id: number;
  public dt_envio_innersac: Date;
  public razaosocial: string;
  public menor_preco: number;
  public prazo_principal_vincular: string;
  public dt_ativacao_reativacao: Date;
  public dias_semcompra_reativacao: number;
  public id_prazo_pagamento: number;
  public fullname: string;
  public nm_transportadora: string;
  public porcentagem_ano: number;
  public falta_ano: number;
  public porcentagem_venda: number;

  public endereco_completo: string;
  public efetivado: boolean;

  constructor() { }
}
