import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Animacao } from '../../core/tools/animacao';

/* Services */

import { CentralAtendimentoService } from '../../core/services/central-atendimento/central-atendimento.service';

@Component({
  selector: 'app-whatsapp-innersac',
  templateUrl: './whatsapp-innersac.component.html',
  styleUrls: ['./whatsapp-innersac.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class WhatsappInnersacComponent implements OnInit {
  @ViewChild("innersac", { static: false }) innersac: ElementRef;

  /* Storage */

  is_master_seller: boolean = (localStorage.getItem("is_master_seller") == "true");
  urlInnerSAC: string = localStorage.getItem("link_innersac") + localStorage.getItem("token_innersac");

  constructor(
    private route: ActivatedRoute,

    private centralAtendimentoService: CentralAtendimentoService,
  ) { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    let telefone: string = "";

    this.route.params.subscribe(params => { telefone = params.telefone; });

    if (telefone != null) {
      telefone = telefone
        .split("(").join("")
        .split(")").join("")
        .split("-").join("")
        .split(" ").join("");

      if (this.is_master_seller) {
        this.innersac.nativeElement.src = this.urlInnerSAC + "&telefone=" + telefone;
      } else {
        this.innersac.nativeElement.src = this.urlInnerSAC;
      }
    } else {
      this.innersac.nativeElement.src = this.urlInnerSAC;
    }

    this.centralAtendimentoService.maximizarEmitter.emit(false);
  }
}
