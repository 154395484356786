import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { PedidoStatus } from '../../models/pedidos/pedido-status';

@Injectable({
  providedIn: 'root'
})

export class PedidoStatusService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getPedidoStatusByOrder(order_id: number): Observable<PedidoStatus[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "order_id": order_id
    };

    return this.http.post<PedidoStatus[]>(this.baseUrl + "api/PedidoStatus/GetPedidoStatusByOrder", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
