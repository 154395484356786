import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { timer, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TempoRegressivoService {
  /* Emitter */

  redirecionarPorTempoEmitter = new EventEmitter<boolean>();

  /* Tempo */

  diasRegressivo: number = 0;
  horasRegressivo: number = 0;
  minutosRegressivo: number = 0;
  segundosRegressivo: number = 0;
  segundosRegressivoLimite: number = 0;

  milisegundosRestantes: number = 0;

  textoDescritivo: string = "Fechamento Automático: ";
  textoDescritivoTempo: string = "";

  tempoRegressivo = new Subscription();

  /* Toastr */

  toastId: number = 0;

  constructor(
    private router: Router,
    private toastr: ToastrService
  ) { }

  encerrarTempo() {
    if (this.toastId != 0) {
      let toast: any = this.toastr.toasts.find(toast => toast.toastId == this.toastId);

      if (toast != null) {
        this.toastr.remove(toast.toastId);
      }
    }

    /* Tempo */

    this.textoDescritivoTempo = "";

    this.tempoRegressivo.unsubscribe();

    /* Toastr */

    this.toastId = 0;
  }

  iniciarTempo(segundos: number) {
    this.encerrarTempo();

    this.segundosRegressivo = segundos;
    this.segundosRegressivoLimite = segundos;

    this.tempoRegressivo = timer(1000, 1000).subscribe(() => { /* Início / Intervalo */
      this.segundosRegressivoLimite--;

      if (this.segundosRegressivoLimite < 0) {
        this.redirecionarPorTempoEmitter.emit(true);

        this.encerrarTempo();

        return;
      }

      let milisegundosRestantes = this.segundosRegressivoLimite * 1000;

      this.horasRegressivo = Math.floor((milisegundosRestantes / (1000 * 60 * 60)) % 24);
      this.minutosRegressivo = Math.floor((milisegundosRestantes / (1000 * 60)) % 60);
      this.segundosRegressivo = Math.floor((milisegundosRestantes / (1000)) % 60);

      this.textoDescritivoTempo = "";

      if (this.diasRegressivo > 0) {
        this.textoDescritivoTempo = this.diasRegressivo.toString() + "D";
      }

      this.textoDescritivoTempo = this.textoDescritivoTempo + this.horasRegressivo.toString().padStart(2, "0") + ":" + this.minutosRegressivo.toString().padStart(2, "0") + ":" + this.segundosRegressivo.toString().padStart(2, "0");

      if (this.toastId != 0) {
        let toast: any = this.toastr.toasts.find(toast => toast.toastId == this.toastId);

        if (toast != null) {
          toast.toastRef.componentInstance.message = this.textoDescritivo + this.textoDescritivoTempo;
        } else {
          let toast: any = this.toastr.warning(this.textoDescritivo + this.textoDescritivoTempo, "", {
            positionClass: "toast-bottom-left",
            extendedTimeOut: 0,
            tapToDismiss: false,
            timeOut: 0
          });

          this.toastId = toast.toastId;
        }
      } else {
        let toast: any = this.toastr.warning(this.textoDescritivo + this.textoDescritivoTempo, "", {
          positionClass: "toast-bottom-left",
          extendedTimeOut: 0,
          tapToDismiss: false,
          timeOut: 0
        });

        this.toastId = toast.toastId;
      }
    });
  }
}
