import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

/* Services */

import { AuthService } from '../services/auth/auth.service';
import { VendedorService } from '../services/vendedores/vendedor.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private vendedorService: VendedorService
  ) {
    if (this.authService.statusAutorizacao()) {
      let alterarStatusPortal = false;

      this.vendedorService.updateStatusPortalByVendedor(true).subscribe(
        alterarStatusPortais => alterarStatusPortal = alterarStatusPortais,
        error => { console.log("Erro: " + error) },
        () => {

        }
      );
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (route.routeConfig.path == "" || route.routeConfig.path == "login") {
      let guidclientenapis: string = localStorage.getItem("guidclientenapis");

      if (guidclientenapis != null && guidclientenapis != "") {
        if (Object.keys(route.queryParams).length > 0) {
          return true;
        } else {
          this.router.navigate(["/pedidos"]);

          return false;
        }
      } else {
        return true;
      }
    } else {
      if (this.authService.statusAutorizacao()) {
        if (route.routeConfig.path != "call-center" && route.routeConfig.path != "call-center-ligacao" && route.routeConfig.path != "call-center-ligacao/:id" && route.routeConfig.path != "call-center-nova-ligacao" && route.routeConfig.path != "pedidos-cadastro" && route.routeConfig.path != "pedidos-cadastro/:id") {
          /* Storage */

          let discador_ativo: boolean = (localStorage.getItem("discador_ativo") == "true");

          if (discador_ativo) {
            return false;
          } else {
            return true;
          }
        } else {
          if (route.routeConfig.path == "call-center-nova-ligacao") {
            /* Storage */

            localStorage.setItem("discador_ativo", "false");
          }

          return true;
        }
      } else {
        this.authService.desautorizar(true);

        return false;
      }
    }
  }
}
