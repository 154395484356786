import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CallCenterDiscadorService {
  /* Emitter */
  
  cancelarPausa = new EventEmitter<boolean>();
  pausaManual = new EventEmitter<boolean>();
  reiniciarTempo = new EventEmitter<boolean>();
  statusChamadas = new EventEmitter<boolean>();
  verificacaoManual = new EventEmitter<boolean>();

  constructor() { }

  /* Chamadas */

  chamadasCancelarPausa() {
    this.cancelarPausa.emit(true);
  }

  chamadasDesabilitadas() {
    this.statusChamadas.emit(false);
  }

  chamadasPausadas() {
    this.pausaManual.emit(true);
  }

  chamadasReiniciadas() {
    this.verificacaoManual.emit(true);
  }

  chamadasReiniciarTempo() {
    this.reiniciarTempo.emit(true);
  }
}
