import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ClienteService } from '../../core/services/clientes/cliente.service';

/* Models */

import { Cliente } from '../../core/models/clientes/cliente';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ClientesComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Filtros */

  formFiltro: FormGroup;

  enviado: boolean = false;
  filtrado: boolean = false;

  /* Clientes */

  clientes: Cliente[] = [];
  clientesOriginal: Cliente[] = [];

  /* Storage */

  is_master: boolean = (localStorage.getItem("is_master") == "true");
  is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");
  token_innersac: string = localStorage.getItem("token_innersac");
  link_innersac: string = localStorage.getItem("link_innersac");

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private clienteService: ClienteService
  ) { }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      razao_social: ["", Validators.required]
    });

    this.carregarDados(true);
  }

  get f() {
    return this.formFiltro.controls;
  }

  /* Ações */

  alterarCliente(client_id_app: number) {
    this.router.navigate(["/clientes-cadastro/" + client_id_app]);
  }

  efetivarCadastro(client_id_app: number) {
    let alterarCadastroEfetivado: boolean = false;

    this.clienteService.updateCadastroEfetivadoById(client_id_app, true).subscribe(
      alterarCadastrosEfetivados => alterarCadastroEfetivado = alterarCadastrosEfetivados,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarCadastroEfetivado) {
          this.carregarDados(!this.enviado);

          this.toastr.success("", "Cadastro efetivado com sucesso!");
        }
      }
    );
  }

  desativarCadastro(client_id_app: number) {
    let desativarCadastro: boolean = false;

    this.clienteService.updateIsActiveById(client_id_app, false).subscribe(
      desativarCadastros => desativarCadastro = desativarCadastros,
      error => { console.log("Erro: " + error) },
      () => {
        if (desativarCadastro) {
          this.carregarDados(!this.enviado);

          this.toastr.success("", "Cadastro desativado com sucesso!");
        }
      }
    );
  }

  novoCliente() {
    this.router.navigate(["/clientes-cadastro"]);
  }

  /* Dados */

  carregarDados(inicial: boolean) {
    this.carregando = true;

    let inativos: boolean = this.is_master || this.is_diretor ? true : false;

    this.clienteService.getByUsuario(inativos).subscribe(
      clientes => this.clientes = clientes,
      error => { console.log("Erro: " + error) },
      () => {
        this.clientes.forEach((item) => {
          let inicioCodigo: string = item.codigo_no_erp_do_cliente != null ? item.codigo_no_erp_do_cliente.substring(0, 4) : "";

          if ((item.codigo_no_erp_do_cliente == null || inicioCodigo == "NOVO") && item.client_document != null && item.flag_novo_cliente == 'S' && !item.cadastro_efetivado) {
            item.efetivado = false;
          } else {
            item.efetivado = true;
          }
        });

        const ordernarClientes = this.ordernarClientes();

        ordernarClientes.then(() => {
          this.clientesOriginal = JSON.parse(JSON.stringify(this.clientes));

          this.filtrar(inicial);

          this.carregando = false;
        });
      }
    );
  }

  ordernarClientes() {
    let retorno = new Promise<void>((resolver) => {
      this.clientes.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.efetivado, b.efetivado);
      });

      return resolver();
    });

    return retorno;
  }

  /* Filtros */

  filtrar(inicial: boolean) {
    if (inicial) {
      return;
    }

    this.enviado = true;

    if (this.formFiltro.valid) {
      this.filtrado = true;

      this.clientes = JSON.parse(JSON.stringify(this.clientesOriginal));

      this.clientes = this.clientes.filter(cliente => {
        if (cliente.name == null) {
          return false;
        } else {
          return (cliente.name.toLowerCase().indexOf(this.formFiltro.controls["razao_social"].value.trim().toLowerCase()) !== -1);
        }
      });
    }
  }

  removerFiltros() {
    this.clientes = JSON.parse(JSON.stringify(this.clientesOriginal));

    this.formFiltro.reset();

    Object.keys(this.formFiltro.controls).forEach(key => {
      this.formFiltro.controls[key].setValue("");
    });

    this.enviado = false;
    this.filtrado = false;
  }

  /* Portal Compras */

  liberarPortalCompras(client_id_app: number, liberar: boolean) {
    let alterarSenha: boolean = false;

    this.clienteService.updatePasswordInicialById(client_id_app, liberar).subscribe(
      alterarSenhas => alterarSenha = alterarSenhas,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarSenha) {
          this.carregarDados(!this.enviado);

          if (liberar) {
            this.toastr.success("", "Cliente liberado!");
          } else {
            this.toastr.success("", "Cliente bloqueado!");
          }
        }
      }
    );
  }

  resetarSenhaPortalCompras(client_id_app: number) {
    let alterarSenha: boolean = false;

    this.clienteService.updatePasswordInicialById(client_id_app, true).subscribe(
      alterarSenhas => alterarSenha = alterarSenhas,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarSenha) {
          this.toastr.success("", "Senha resetada com sucesso!");
        }
      }
    );
  }

  /* Rotas */

  reiniciarGeolocalizacaoCliente(cliente_id_app: number) {
    let alterarGeolocalizacaoAntiga: boolean = false;

    this.clienteService.updateGeolocalizacaoAntigaById(cliente_id_app).subscribe(
      alterarGeolocalizacoesAntiga => alterarGeolocalizacaoAntiga = alterarGeolocalizacoesAntiga,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarGeolocalizacaoAntiga) {
          this.toastr.success("", "Geolocalização reiniciada com sucesso!");
        }
      }
    );
  }

  /* Whatsapp */

  iniciarChat(dddWhatsApp: number, telefoneWhatsApp: number) {
    if (dddWhatsApp.toString().trim() == "") {
      this.toastr.error("", "WhatsApp (DDD) em branco!");
    } else if (telefoneWhatsApp.toString().trim() == "") {
      this.toastr.error("", "WhatsApp (Telefone) em branco!");
    } else {
      if (this.token_innersac != "" && this.link_innersac != "") {
        this.router.navigate(["/whatsapp-innersac/" + dddWhatsApp.toString().trim() + telefoneWhatsApp.toString().trim()]);
      }
    }
  }
}
