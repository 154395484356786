import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

declare const WebphoneL5: any;

/* Services */

import { CallCenterService } from '../../call-center/call-center.service';
import { ClienteService } from '../../clientes/cliente.service';
import { VendedorService } from '../../vendedores/vendedor.service';

/* Models */

import { CallCenterChamada } from '../../../models/call-center/call-center-chamada';
import { CanaisComunicacaoTelefoniaConexao } from '../../../models/canais-comunicacao/telefonia/canais-comunicacao-telefonia-conexao';
import { CanaisComunicacaoTelefoniaEvento } from '../../../models/canais-comunicacao/telefonia/canais-comunicacao-telefonia-evento';
import { CanaisComunicacaoTelefoniaMensagem } from '../../../models/canais-comunicacao/telefonia/canais-comunicacao-telefonia-mensagem';
import { Cliente } from '../../../models/clientes/cliente';
import { Vendedor } from '../../../models/vendedores/vendedor';

@Injectable({
  providedIn: 'root'
})

export class CanaisComunicacaoTelefoniaService {
  private baseUrlCommunicator: string = "https://localhost.ucclient.net:5506";

  private headers = new HttpHeaders();

  /* Emitter */

  eventosEmitter = new EventEmitter<CanaisComunicacaoTelefoniaEvento>();

  /* Auxiliar */

  chamadasLiberadas: boolean = false;

  idCallCenterChamada: number = 0;

  telefoneSelecionado: string = "";

  /* Bria */

  briaDeclaracaoXML: string = "<?xml version=\"1.0\" encoding=\"utf-8\" ?>\r\n";

  briaErroConexao: boolean = false;

  briaIdChamadaConectada: string = "";
  briaIdChamadaConectando: string = "";
  briaIdChamadaRecebida: string = "";

  briaNroTransacao: number = 0;

  /* Cliente */

  clienteSelecionado: Cliente = null;

  /* Vendedor(a) */

  vendedor: Vendedor = null;

  /* WS */

  ws: WebSocket = null; /* 1 = Open / 2 = Closing / 3 = Closed */

  /* Storage */

  telefoniaGuid: string = localStorage.getItem("telefonia_guid");

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private clienteService: ClienteService,
    private vendedorService: VendedorService
  ) { }

  public removerDadosSelecionados() {
    /* Auxiliar */

    this.telefoneSelecionado = "";

    /* Cliente */

    this.clienteSelecionado = null;
  }

  /* Bria */

  public briaConverterMensagemRecebida(mensagem: string): CanaisComunicacaoTelefoniaMensagem {
    let canaisComunicacaoTelefoniaMensagem = new CanaisComunicacaoTelefoniaMensagem();

    let linhas: string[] = mensagem.replace(/\r\n/g, "\n").split("\n");
    let linha = linhas[0];

    if (linha.substr(0, 4) == "POST") {
      canaisComunicacaoTelefoniaMensagem.messageType = "event";

      linha = linha.substr(5).trim();

      if (linha.substr(0, 13) == "/statusChange") {
        canaisComunicacaoTelefoniaMensagem.eventType = "statuschange";
      }
    } else if (linha.substr(0, 8) == "HTTP/1.1") {
      linha = linha.substr(8).trim();

      if (linha.substr(0, 6) == "200 OK") {
        canaisComunicacaoTelefoniaMensagem.messageType = "response";
      } else if ((linha[0] == "4") || (linha[0] == "5")) {
        canaisComunicacaoTelefoniaMensagem.messageType = "error";
        canaisComunicacaoTelefoniaMensagem.errorCode = parseInt(linha.substr(0, 3));
        canaisComunicacaoTelefoniaMensagem.errorText = linha.substr(4);
      }
    }

    let i: number = 1;

    for (; i < linhas.length; i++) {
      linha = linhas[i];

      if (linha[0] == "<") {
        break;
      } else if (linha.substr(0, 15) == "Transaction-ID:") {
        canaisComunicacaoTelefoniaMensagem.transactionId = linha.substr(15).trim();
      } else if (linha.substr(0, 11) == "User-Agent:") {
        canaisComunicacaoTelefoniaMensagem.userAgentString = linha.substr(11).trim();
      } else if (linha.substr(0, 13) == "Content-Type:") {
        canaisComunicacaoTelefoniaMensagem.contentType = linha.substr(13).trim();
      } else if (linha.substr(0, 15) == "Content-Length:") {
        canaisComunicacaoTelefoniaMensagem.contentLength = parseInt(linha.substr(15).trim());
      } else {
        continue;
      }
    }

    canaisComunicacaoTelefoniaMensagem.content = "";

    for (; i < linhas.length; i++) {
      canaisComunicacaoTelefoniaMensagem.content += linhas[i];

      if (i < linhas.length - 1) {
        canaisComunicacaoTelefoniaMensagem.content += "\n";
      }
    }

    return canaisComunicacaoTelefoniaMensagem;
  }

  public briaPrepararMensagemEnvio(tipo: string, conteudo: string): string {
    let contentLength: number = conteudo.length;

    let mensagem = "GET /" + tipo +
      "\r\nUser-Agent: Napis - Portal do Cliente" +
      "\r\nTransaction-ID: " + this.briaProximoNumeroTransacao() +
      "\r\nContent-Type: application/xml" +
      "\r\nContent-Length: " + contentLength;

    if (contentLength > 0) {
      mensagem += "\r\n\r\n" + conteudo;
    }

    return mensagem;
  }

  public briaProcessarEvento(conteudo: string) {
    let parser: DOMParser = new DOMParser();

    let documentoXML: Document = parser.parseFromString(conteudo, "text/xml");

    let evento: Element = documentoXML.getElementsByTagName("event")[0];

    let tipoEvento = evento.getAttributeNode("type");

    switch (tipoEvento.nodeValue) {
      case "call":
        this.briaVerificarLigacao();

        break;
      case "missedCall":
      case "phone":
        break;
      default:

    }
  }

  public briaProcessarReposta(conteudo: string) {
    if (conteudo.length > 0) {
      let parser: DOMParser = new DOMParser();

      let documentoXML: Document = parser.parseFromString(conteudo, "text/xml");

      let status: Element = documentoXML.getElementsByTagName("status")[0];

      if (status != null) {
        let tipoStatus: Attr = status.getAttributeNode("type");

        switch (tipoStatus.value) {
          case "authentication":
            let autenticado = this.briaRecuperarValorTagXML(documentoXML, "authenticated");

            if (autenticado == "false") {
              this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("deslogado", "", ""));
            } else {
              this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("logado", "", ""));
            }

            break;
          case "call":
            let chamadas: HTMLCollectionOf<Element> = documentoXML.getElementsByTagName("call");

            if (chamadas.length > 0) {
              /* Primeira Chamada */

              let primeiraChamada: Element = chamadas[0];

              let estadoChamada: string = this.briaRecuperarValorTagXML(documentoXML, "state");

              /* Primeiro Participante */

              let primeiroParticipante: HTMLCollectionOf<Element> = primeiraChamada.getElementsByTagName("participants")[0].getElementsByTagName("participant");

              if (primeiroParticipante.length > 0) {
                let participante = primeiroParticipante[0];

                this.telefoneSelecionado = this.briaRecuperarValorTagXML(participante, "number");

                switch (estadoChamada) {
                  case "connecting":
                    /* Connecting */

                    if (this.briaIdChamadaConectando == "") {
                      this.clienteService.getByTelefone(this.telefoneSelecionado).subscribe(
                        clientesSelecionados => this.clienteSelecionado = clientesSelecionados,
                        error => { console.log("Erro: " + error) },
                        () => {
                          if (this.clienteSelecionado != null) {
                            this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("discando", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                          } else {
                            this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("discando", this.telefoneSelecionado, ""));
                          }

                          this.registrarInicioChamada(this.telefoneSelecionado);
                        }
                      );

                      this.briaIdChamadaConectando = this.briaRecuperarValorTagXML(documentoXML, "id");
                    }

                    break;
                  case "connected":
                    /* Connected */

                    if (this.briaIdChamadaConectada == "") {
                      if (this.clienteSelecionado != null) {
                        this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_em_andamento", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                      } else {
                        this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_em_andamento", this.telefoneSelecionado, ""));
                      }

                      this.briaIdChamadaConectada = this.briaRecuperarValorTagXML(documentoXML, "id");
                    }

                    break;
                  case "ringing":
                    /* Ringing */

                    this.briaIdChamadaRecebida = this.briaRecuperarValorTagXML(documentoXML, "id");

                    this.clienteService.getByTelefone(this.telefoneSelecionado).subscribe(
                      clientesSelecionados => this.clienteSelecionado = clientesSelecionados,
                      error => { console.log("Erro: " + error) },
                      () => {
                        if (this.clienteSelecionado != null) {
                          this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("recebendo", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                        } else {
                          this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("recebendo", this.telefoneSelecionado, ""));
                        }

                        this.registrarInicioChamada(this.telefoneSelecionado);
                      }
                    );

                    break;
                  default:

                }
              }
            } else {
              if (this.clienteSelecionado != null) {
                this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_encerrada", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
              } else {
                this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_encerrada", this.telefoneSelecionado, ""));
              }

              this.briaIdChamadaConectada = "";
              this.briaIdChamadaConectando = "";
              this.briaIdChamadaRecebida = "";

              this.removerDadosSelecionados();

              this.registrarFimChamada();
            }

            break;
          default:

        }
      }
    }
  }

  public briaProximoNumeroTransacao(): number {
    this.briaNroTransacao++;

    return this.briaNroTransacao;
  }

  public briaRecuperarValorTagXML(documentoXML: any, nomeTag): string {
    let elemento: Element = documentoXML.getElementsByTagName(nomeTag)[0];

    if (elemento != null) {
      let noFilho: ChildNode = elemento.childNodes[0];

      if (noFilho != null) {
        return noFilho.nodeValue;
      }
    }

    return "";
  }

  /* Bria (Envios) */

  public briaIniciarChamada(numero_telefone: string) {
    let primeiroDigito = numero_telefone.substring(0, 1);

    if (primeiroDigito != "0") {
      numero_telefone = "0" + numero_telefone;
    }

    let ddd = numero_telefone.substring(1, 3);

    let ddd_local: string = localStorage.getItem("ddd_local");

    if (ddd == ddd_local) {
      numero_telefone = numero_telefone.substring(3, numero_telefone.length);
    }

    let conteudo = this.briaDeclaracaoXML + "<dial type='audio'>\r\n<number>" + numero_telefone + "</number>\r\n<displayName></displayName>\r\n<suppressMainWindow>false</suppressMainWindow>\r\n</dial>";
    let mensagem = this.briaPrepararMensagemEnvio("call", conteudo);

    this.ws.send(mensagem);
  }

  public briaReceberChamada() {
    if (this.briaIdChamadaRecebida != "") {
      let conteudo = this.briaDeclaracaoXML + "<answerCall>\r\n<callId>" + this.briaIdChamadaRecebida + "</callId>\r\n<withVideo>false</withVideo>\r\n</answerCall>";
      let mensagem = this.briaPrepararMensagemEnvio("answer", conteudo);

      this.ws.send(mensagem);
    }
  }

  public briaVerificarLigacao() {
    let conteudo = this.briaDeclaracaoXML + "<status>\r\n<type>call</type>\r\n</status>";
    let mensagem = this.briaPrepararMensagemEnvio("status", conteudo);

    this.ws.send(mensagem);
  }

  public briaVerificarStatus() {
    let conteudo = this.briaDeclaracaoXML + "<status>\r\n<type>authentication</type>\r\n</status>";
    let mensagem = this.briaPrepararMensagemEnvio("status", conteudo);

    this.ws.send(mensagem);
  }

  /* Communicator */

  public communicatorConectar(): Observable<CanaisComunicacaoTelefoniaConexao> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "uuid": "0e2d4055-2254-47e4-be1d-0e5f1f197d00",
      "version": "0.1",
      "localized_strings": {
        "en": {
          "name": "Portal do Cliente",
          "description": "Call Center",
          "vendor": "Napis"
        }
      }
    };

    return this.http.post<CanaisComunicacaoTelefoniaConexao>(this.baseUrlCommunicator + "/connect", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError((erro) => {
        if (erro.statusText == "Unknown Error") {

        } else {
          this.toastr.error("", "Houve um erro, contate o suporte...");
        }

        return of(null);
      })
    );
  }

  public communicatorIniciarChamada(numero_telefone: string): Observable<any> {
    this.headers.set("Content-Type", "application/json");

    let primeiroDigito = numero_telefone.substring(0, 1);

    if (primeiroDigito != "0") {
      numero_telefone = "0" + numero_telefone;
    }

    let ddd = numero_telefone.substring(1, 3);

    let ddd_local: string = localStorage.getItem("ddd_local");

    if (ddd == ddd_local) {
      numero_telefone = numero_telefone.substring(3, numero_telefone.length);
    }

    let data = "?addr=" + numero_telefone + "&type=VoIPCall&session_id=" + this.telefoniaGuid + "&cmd=StartCommunication";

    return this.http.get<any>(this.baseUrlCommunicator + "/api" + data, { reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public communicatorMonitorar(): Observable<any> {
    this.headers.set("Content-Type", "application/json");

    let data = "?session_id=" + this.telefoniaGuid + "&cmd=SubscribeEvents&events=Calls";

    return this.http.get<any>(this.baseUrlCommunicator + "/api" + data, { reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public communicatorReceberChamada(): Observable<any> {
    this.headers.set("Content-Type", "application/json");

    let data = "?session_id=" + this.telefoniaGuid + "&cmd=CallAnswer";

    return this.http.get<any>(this.baseUrlCommunicator + "/api" + data, { reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  /* L5 */

  public l5IniciarChamada(numero_telefone: string) {
    WebphoneL5.call({
      "number": numero_telefone
    });
  }

  public l5ReceberChamada() {
    WebphoneL5.accept();
  }

  /* Canal (WebSocket) */

  public abrirCanal(tipo_softphone: number) {
    switch (tipo_softphone) {
      case 1:
        /* Communicator */

        if (this.ws == null) {
          let url: string = "wss://localhost.ucclient.net:5506/channel?session_id=" + this.telefoniaGuid;

          this.ws = new WebSocket(url);

          this.ws.onopen = (() => {
            let chamada: any = null;

            this.communicatorMonitorar().subscribe(
              chamadas => chamada = chamadas,
              error => { console.log("Erro: " + error) },
              () => {

              }
            );
          });

          this.ws.onmessage = ((evento) => {
            let resposta = JSON.parse(evento.data);

            if (resposta != null) {
              if (resposta.notification.event != null) {
                if (resposta.notification.event == "CallState") {
                  /* CallState */

                  if (resposta.notification.parameters != null) {
                    this.toastr.clear();

                    if (resposta.notification.parameters.state == "closed") {
                      if (this.clienteSelecionado != null) {
                        this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_encerrada", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                      } else {
                        this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_encerrada", this.telefoneSelecionado, ""));
                      }

                      this.removerDadosSelecionados();

                      this.registrarFimChamada();
                    } else if (resposta.notification.parameters.state == "open") {
                      if (this.clienteSelecionado != null) {
                        this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_em_andamento", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                      } else {
                        this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_em_andamento", this.telefoneSelecionado, ""));
                      }
                    } else if (resposta.notification.parameters.state == "ringing") {
                      let telefone: string[] = resposta.notification.parameters.address.replace("sip:", "").split("@");

                      this.telefoneSelecionado = telefone[0];

                      this.clienteService.getByTelefone(this.telefoneSelecionado).subscribe(
                        clientesSelecionados => this.clienteSelecionado = clientesSelecionados,
                        error => { console.log("Erro: " + error) },
                        () => {
                          if (this.clienteSelecionado != null) {
                            if (resposta.notification.parameters.direction == "incoming") {
                              this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("recebendo", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                            } else {
                              this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("discando", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                            }
                          } else {
                            if (resposta.notification.parameters.direction == "incoming") {
                              this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("recebendo", this.telefoneSelecionado, ""));
                            } else {
                              this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("discando", this.telefoneSelecionado, ""));
                            }
                          }

                          this.registrarInicioChamada(this.telefoneSelecionado);
                        }
                      );
                    }
                  }
                } else if (resposta.notification.event == "heartbeat") {
                  /* heartbeat */

                } else if (resposta.notification.event == "NewCall") {
                  /* NewCall */

                } else if (resposta.notification.event == "SessionCapabilities") {
                  /* SessionCapabilities */

                  if (resposta.notification.parameters != null && resposta.notification.parameters.capabilities == "") {
                    this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("deslogado", "", ""));

                    this.chamadasLiberadas = false;

                    this.removerDadosSelecionados();
                  } else {
                    if (resposta.notification.parameters.capabilities.indexOf("VoipCall") !== -1) {
                      if (!this.chamadasLiberadas) {
                        this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("logado", "", ""));

                        this.chamadasLiberadas = true;
                      }
                    }
                  }
                } else if (resposta.notification.event == "SubscribedEvents") {
                  /* SubscribedEvents */

                } else if (resposta.notification.event == "SignedOut") {
                  /* SignedOut */

                  this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("softphone_indisponivel", "", ""));

                  this.chamadasLiberadas = false;

                  this.removerDadosSelecionados();

                  this.encerrarCanal(true);
                }
              } else if (resposta.notification.parameters != null && resposta.notification.parameters.accepted != null && resposta.notification.parameters.accepted == true) {

              }
            }
          });

          this.ws.onerror = (() => {
            this.toastr.error("", "Houve um erro, contate o suporte...");

            this.chamadasLiberadas = false;

            this.removerDadosSelecionados();

            this.encerrarCanal(true);
          });

          this.ws.onclose = (() => {
            this.chamadasLiberadas = false;

            this.removerDadosSelecionados();
          });
        } else {
          this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("deslogado", "", ""));

          this.chamadasLiberadas = false;

          this.removerDadosSelecionados();
        }

        return;
      case 2:
        /* Bria */

        let url: string = "wss://cpclientapi.softphone.com:9002/counterpath/socketapi/v1/";

        this.ws = new WebSocket(url);

        this.ws.onopen = (() => {
          this.briaErroConexao = false;

          this.briaVerificarStatus();
        });

        this.ws.onmessage = ((evento) => {
          let canaisComunicacaoTelefoniaMensagem: CanaisComunicacaoTelefoniaMensagem = this.briaConverterMensagemRecebida(evento.data);

          switch (canaisComunicacaoTelefoniaMensagem.messageType) {
            case "response":
              /* Response */

              this.briaProcessarReposta(canaisComunicacaoTelefoniaMensagem.content);

              break;
            case "event":
              /* Event */

              if (canaisComunicacaoTelefoniaMensagem.eventType == "statuschange") {
                this.briaProcessarEvento(canaisComunicacaoTelefoniaMensagem.content);
              }

              break;
            case "error":
              /* Error */

              this.toastr.error("", "Houve um erro, contate o suporte...");

              break;
            default:

          }
        });

        this.ws.onerror = (() => {
          this.briaErroConexao = true;

          this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("softphone_indisponivel", "", ""));

          this.chamadasLiberadas = false;

          this.removerDadosSelecionados();

          this.encerrarCanal(true);
        });

        this.ws.onclose = (() => {
          if (!this.briaErroConexao) {
            this.abrirCanal(tipo_softphone);
          }

          this.chamadasLiberadas = false;

          this.removerDadosSelecionados();
        });

        return;
      case 3:
        /* L5 */

        localStorage.setItem("pagesId", JSON.stringify({}));

        this.vendedorService.getById().subscribe(
          vendedores => this.vendedor = vendedores,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.vendedor != null) {
              if (this.vendedor.pabx_server != null && this.vendedor.pabx_ramal != null && this.vendedor.pabx_senha != null) {
                WebphoneL5.init({
                  "extension": this.vendedor.pabx_ramal,
                  "password": this.vendedor.pabx_senha,
                  "server": this.vendedor.pabx_server,
                  "showCdr": true,
                  "app": {
                    "type": "iframe",
                    "iframeBtncolorSchema": {
                      backgroundColor: "#005580",
                      iconColor: "#fff"
                    }
                  }
                });

                WebphoneL5.onWebphoneStateChange((webphonestate) => {
                  switch (webphonestate) {
                    case "loaded":
                      localStorage.setItem("pagesId", JSON.stringify({}));

                      return;
                    case "registered":
                      this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("logado", "", ""));

                      this.chamadasLiberadas = true;

                      return;
                    default:

                  }
                });

                WebphoneL5.onCallStateChange((callState) => {
                  switch (callState.direction) {
                    case "in":
                      /* In */

                      this.telefoneSelecionado = callState.from;

                      this.clienteService.getByTelefone(this.telefoneSelecionado).subscribe(
                        clientesSelecionados => this.clienteSelecionado = clientesSelecionados,
                        error => { console.log("Erro: " + error) },
                        () => {
                          if (this.clienteSelecionado != null) {
                            this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("recebendo", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                          } else {
                            this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("recebendo", this.telefoneSelecionado, ""));
                          }

                          this.registrarInicioChamada(this.telefoneSelecionado);
                        }
                      );

                      return;
                    case "out":
                      /* Out */

                      switch (callState.state) {
                        case "disconnected":
                          /* Disconnected */

                          if (this.clienteSelecionado != null) {
                            this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_encerrada", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                          } else {
                            this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("chamada_encerrada", this.telefoneSelecionado, ""));
                          }

                          this.removerDadosSelecionados();

                          this.registrarFimChamada();

                          return;
                        case "ringing":
                          /* Ringing */

                          this.telefoneSelecionado = callState.to;

                          this.clienteService.getByTelefone(this.telefoneSelecionado).subscribe(
                            clientesSelecionados => this.clienteSelecionado = clientesSelecionados,
                            error => { console.log("Erro: " + error) },
                            () => {
                              if (this.clienteSelecionado != null) {
                                this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("discando", this.clienteSelecionado.name, this.clienteSelecionado.codigo_no_erp_do_cliente));
                              } else {
                                this.eventosEmitter.emit(new CanaisComunicacaoTelefoniaEvento("discando", this.telefoneSelecionado, ""));
                              }

                              this.registrarInicioChamada(this.telefoneSelecionado);
                            }
                          );

                          return;
                        default:

                      }

                      return;
                    default:

                  }
                });
              } else {
                this.toastr.error("", "(L5) Credenciais inválidas...");
              }
            }
          }
        );

        return;
      default:
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return;
    }
  }

  public encerrarCanal(remover_guid: boolean) {
    if (this.ws != null) {
      this.ws.close();

      this.ws = null;
    }

    if (remover_guid) {
      this.removerGuid();
    }
  }

  /* Registros */

  public registrarInicioChamada(numero: string) {
    /* Storage */

      /* Call Center */

      let id_call_center_ligacao: number = parseInt(localStorage.getItem("id_call_center_ligacao"));

      /* Usuário */

      let id: string = localStorage.getItem("id");

    if (id_call_center_ligacao != 0) {
      /* Chamada */

      let chamada = new CallCenterChamada();

      chamada.idcallcenterligacao = id_call_center_ligacao;
      chamada.idsellerlogado = parseInt(id);
      chamada.numero = numero;

      let adicionarChamada: number = 0;

      this.callCenterService.addChamada(chamada).subscribe(
        adicionarChamadas => adicionarChamada = adicionarChamadas,
        error => { console.log("Erro: " + error) },
        () => {
          if (adicionarChamada > 0) {
            this.idCallCenterChamada = adicionarChamada;
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");
          }
        }
      );
    }
  }

  public registrarFimChamada() {
    if (this.idCallCenterChamada != 0) {
      let alterarChamada: boolean = false;

      this.callCenterService.updateChamadaFimById(this.idCallCenterChamada).subscribe(
        alterarChamadas => alterarChamada = alterarChamadas,
        error => { console.log("Erro: " + error) },
        () => {
          if (alterarChamada) {
            this.idCallCenterChamada = 0;
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");
          }
        }
      );
    }
  }

  /* Storage */

  public definirGuid(guid: string) {
    this.telefoniaGuid = guid;

    /* Storage */

    localStorage.setItem("telefonia_guid", guid);
  }

  public removerGuid() {
    this.telefoniaGuid = "";

    /* Storage */

    localStorage.setItem("telefonia_guid", "");
  }
}
