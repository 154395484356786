import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-vendedores',
  templateUrl: './vendedores.component.html',
  styleUrls: ['./vendedores.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class VendedoresComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Filtros */

  formFiltro: FormGroup;

  enviado: boolean = false;
  filtrado: boolean = false;

  /* Vendedores */

  vendedores: Vendedor[] = [];
  vendedoresOriginal: Vendedor[] = [];

  /* Storage */

  is_master: boolean = (localStorage.getItem("is_master") == "true");
  is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private vendedorService: VendedorService
  ) { }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      nome: ["", Validators.required]
    });

    this.carregarDados();
  }

  get f() {
    return this.formFiltro.controls;
  }

  /* Ações */

  alterarVendedor(id: number) {
    this.router.navigate(["/vendedores-cadastro/" + id]);
  }

  resetarSenha(id: number) {
    let alterarSenha: boolean = false;

    this.vendedorService.updateSenhaInicialByVendedor(id).subscribe(
      alterarSenhas => alterarSenha = alterarSenhas,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarSenha) {
          this.toastr.success("", "Senha resetada com sucesso!");
        }
      }
    );
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;

    this.vendedorService.getVendedorByGuidClienteNapis().subscribe(
      vendedores => this.vendedores = vendedores,
      error => { console.log("Erro: " + error) },
      () => {
        this.vendedoresOriginal = JSON.parse(JSON.stringify(this.vendedores));

        this.carregando = false;
      }
    );
  }

  /* Filtros */

  filtrar() {
    this.enviado = true;

    if (this.formFiltro.valid) {
      this.filtrado = true;

      this.vendedores = JSON.parse(JSON.stringify(this.vendedoresOriginal));

      this.vendedores = this.vendedores.filter(vendedor => {
        if (vendedor.fullname == null) {
          return false;
        } else {
          return (vendedor.fullname.toLowerCase().indexOf(this.formFiltro.controls["nome"].value.trim().toLowerCase()) !== -1);
        }
      });
    }
  }

  removerFiltros() {
    this.vendedores = JSON.parse(JSON.stringify(this.vendedoresOriginal));

    this.formFiltro.reset();

    Object.keys(this.formFiltro.controls).forEach(key => {
      this.formFiltro.controls[key].setValue("");
    });

    this.enviado = false;
    this.filtrado = false;
  }
}
