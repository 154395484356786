import { CanaisComunicacaoWhatsAppChatAudio } from './canais-comunicacao-whatsapp-chat-audio';
import { CanaisComunicacaoWhatsAppChatDocumento } from './canais-comunicacao-whatsapp-chat-documento';
import { CanaisComunicacaoWhatsAppChatImagem } from './canais-comunicacao-whatsapp-chat-imagem';
import { CanaisComunicacaoWhatsAppChatSticker } from './canais-comunicacao-whatsapp-chat-sticker';
import { CanaisComunicacaoWhatsAppChatTexto } from './canais-comunicacao-whatsapp-chat-texto';

export class CanaisComunicacaoWhatsAppChat {
  public phone: string;
  public participantPhone: string;
  public senderName: string;
  public chatName: string;
  public momment: number;
  public messageId: string;
  public fromMe: boolean;
  public status: string;
  public photo: string;
  public broadcast: boolean;
  public forwarded: boolean;
  public audio: CanaisComunicacaoWhatsAppChatAudio;
  public document: CanaisComunicacaoWhatsAppChatDocumento;
  public image: CanaisComunicacaoWhatsAppChatImagem;
  public sticker: CanaisComunicacaoWhatsAppChatSticker;
  public text: CanaisComunicacaoWhatsAppChatTexto;

  public data_hora: Date;

  constructor() { }
}
