import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Produto } from '../../models/produtos/produto';
import { ProdutoCampanha } from '../../models/produtos/produto-campanha';
import { ProdutoCampanhaItens } from '../../models/produtos/produto-campanha-itens';
import { ProdutoCesta } from '../../models/produtos/produto-cesta';
import { ProdutoDesconto } from '../../models/produtos/produto-desconto';
import { ProdutoEspecialCliente } from '../../models/produtos/produto-especial-cliente';
import { ProdutoEspecialVendedor } from '../../models/produtos/produto-especial-vendedor';
import { ProdutoFichaTecnica } from '../../models/produtos/produto-ficha-tecnica';
import { ProdutoFoto } from '../../models/produtos/produto-foto';
import { ProdutoLote } from '../../models/produtos/produto-lote';
import { ProdutoPromocional } from '../../models/produtos/produto-promocional';
import { ProdutoReferenciaCliente } from '../../models/produtos/produto-referencia-cliente';
import { ProdutoSegmento } from '../../models/produtos/produto-segmento';
import { ProdutoSimilar } from '../../models/produtos/produto-similar';
import { ProdutoTabelaEspecialItens } from '../../models/produtos/produto-tabela-especial-itens';
import { ProdutoTabelaEspecial } from '../../models/produtos/produto-tabela-especial';
import { ProdutoTabela } from '../../models/produtos/produto-tabela';
import { ProdutoLocal } from '../../models/produtos/produto-local';

@Injectable({
  providedIn: 'root'
})

export class ProdutoService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Produto */

  public addMultiplos(produtos: Produto[]): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtos": produtos
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/AddMultiplos", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getByGuidClienteNapis(): Observable<Produto[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Produto[]>(this.baseUrl + "api/Produto/GetByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getById(id: number): Observable<Produto> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<Produto>(this.baseUrl + "api/Produto/GetById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getByUsuarioCliente(id_cliente: number): Observable<Produto[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "id_cliente": id_cliente
    };

    return this.http.post<Produto[]>(this.baseUrl + "api/Produto/GetByUsuarioCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getGrupoEstoqueByGuidClienteNapis(): Observable<string[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<string[]>(this.baseUrl + "api/Produto/GetGrupoEstoqueByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateFichaTecnica(produto: Produto): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produto": produto
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdateFichaTecnica", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateMostrarB2BById(id: number, liberar: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "liberar": liberar
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdateMostrarB2BById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updatePromocaoById(id: number, definir: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "definir": definir
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdatePromocaoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Produto (Campanha (Itens)) */

  public getProdutoCampanhaItensByIdCampanha(id_campanha: number): Observable<ProdutoCampanhaItens[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_campanha": id_campanha,
      "ativo": false,
      "mostrar_b2b": false
    };

    return this.http.post<ProdutoCampanhaItens[]>(this.baseUrl + "api/Produto/GetProdutoCampanhaItensByIdCampanha", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateProdutoCampanhaItens(produtoCampanhaItens: ProdutoCampanhaItens[]): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoCampanhaItens": produtoCampanhaItens
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdateProdutoCampanhaItens", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Produto (Campanha) */

  public addProdutoCampanha(produtoCampanha: ProdutoCampanha): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoCampanha": produtoCampanha
    };

    return this.http.post<number>(this.baseUrl + "api/Produto/AddProdutoCampanha", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public getProdutoCampanhaByGuidClienteNapis(): Observable<ProdutoCampanha[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "ativo": false
    };

    return this.http.post<ProdutoCampanha[]>(this.baseUrl + "api/Produto/GetProdutoCampanhaByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getProdutoCampanhaById(id_campanha: number): Observable<ProdutoCampanha> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_campanha": id_campanha
    };

    return this.http.post<ProdutoCampanha>(this.baseUrl + "api/Produto/GetProdutoCampanhaById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public updateProdutoCampanha(produtoCampanha: ProdutoCampanha): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoCampanha": produtoCampanha
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdateProdutoCampanha", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Produto (Cesta) */

  public getProdutoCestaByGuidClienteNapis(segmento: string): Observable<ProdutoCesta[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "segmento": segmento
    };

    return this.http.post<ProdutoCesta[]>(this.baseUrl + "api/Produto/GetProdutoCestaByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Desconto) */

  public getDescontoByProduto(id_cliente: number, id_produto: number, preco: number, qtde: number, id_vendedor: number, id_lote: number, valor_fator_financeiro_tabela: number): Observable<ProdutoDesconto> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_cliente": id_cliente,
      "id_produto": id_produto,
      "preco": preco,
      "qtde": qtde,
      "id_vendedor": id_vendedor,
      "id_lote": id_lote,
      "valor_fator_financeiro_tabela": valor_fator_financeiro_tabela
    };

    return this.http.post<ProdutoDesconto>(this.baseUrl + "api/Produto/GetDescontoByProduto", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        let produtoDesconto: ProdutoDesconto = null;

        produtoDesconto.desconto_concedido = 0;
        produtoDesconto.descritivo_desconto = "";

        return of(produtoDesconto);
      })
    );
  }

  /* Produto Especial (Cliente) */

  public addProdutoEspecialCliente(produtoEspecialCliente: ProdutoEspecialCliente): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoEspecialCliente": produtoEspecialCliente
    };

    return this.http.post<number>(this.baseUrl + "api/Produto/AddProdutoEspecialCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public deleteProdutoEspecialClienteById(id_produto_especial_cliente: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto_especial_cliente": id_produto_especial_cliente
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/DeleteProdutoEspecialClienteById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getProdutoEspecialClienteByIdProdutoTabelaEspecial(id_produto_tabela_especial: number): Observable<ProdutoEspecialCliente[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto_tabela_especial": id_produto_tabela_especial
    };

    return this.http.post<ProdutoEspecialCliente[]>(this.baseUrl + "api/Produto/GetProdutoEspecialClienteByIdProdutoTabelaEspecial", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto Especial (Vendedor) */

  public addProdutoEspecialVendedor(produtoEspecialVendedor: ProdutoEspecialVendedor): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoEspecialVendedor": produtoEspecialVendedor
    };

    return this.http.post<number>(this.baseUrl + "api/Produto/AddProdutoEspecialVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public deleteProdutoEspecialVendedorById(id_produto_especial_vendedor: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto_especial_vendedor": id_produto_especial_vendedor
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/DeleteProdutoEspecialVendedorById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getProdutoEspecialVendedorByIdProdutoTabelaEspecial(id_produto_tabela_especial: number): Observable<ProdutoEspecialVendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto_tabela_especial": id_produto_tabela_especial
    };

    return this.http.post<ProdutoEspecialVendedor[]>(this.baseUrl + "api/Produto/GetProdutoEspecialVendedorByIdProdutoTabelaEspecial", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Ficha Técnica) */

  public addFichaTecnica(produtoFichaTecnica: ProdutoFichaTecnica): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoFichaTecnica": produtoFichaTecnica
    };

    return this.http.post<number>(this.baseUrl + "api/Produto/AddFichaTecnica", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public deleteFichaTecnicaById(id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/DeleteFichaTecnicaById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getFichaTecnicaByIdProduto(id_produto: number): Observable<ProdutoFichaTecnica[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto": id_produto
    };

    return this.http.post<ProdutoFichaTecnica[]>(this.baseUrl + "api/Produto/GetFichaTecnicaByIdProduto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Foto) */

  public addProdutoFoto(produtoFoto: ProdutoFoto): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoFoto": produtoFoto
    };

    return this.http.post<number>(this.baseUrl + "api/Produto/AddProdutoFoto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public deleteProdutoFotoById(id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/DeleteProdutoFotoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getProdutoFotosByIdProduto(id_produto: number): Observable<ProdutoFoto[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto": id_produto
    };

    return this.http.post<ProdutoFoto[]>(this.baseUrl + "api/Produto/GetProdutoFotosByIdProduto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updatePadraoById(id: number, id_produto: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "id_produto": id_produto
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdatePadraoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Produto (Local) */

  public getProdutoLocalByIdProduto(id_produto: number): Observable<ProdutoLocal[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto": id_produto
    };

    return this.http.post<ProdutoLocal[]>(this.baseUrl + "api/Produto/GetProdutoLocalByIdProduto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Lote) */

  public getProdutoLoteByIdProduto(id_produto: number, nome_lote: string, id_cliente: number, id_forma_pagamento: number): Observable<ProdutoLote[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "id_produto": id_produto,
      "nome_lote": nome_lote,
      "id_cliente": id_cliente,
      "id_forma_pagamento": id_forma_pagamento
    };

    return this.http.post<ProdutoLote[]>(this.baseUrl + "api/Produto/GetProdutoLoteByIdProduto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Promocional) */

  public getProdutoPromocionalByGuidClienteNapis(client_id_app: number, nome_lote: string, id_forma_pagamento: number, id_prazo_pagamento?: number): Observable<ProdutoPromocional[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "mostrar_b2b": false,
      "client_id_app": client_id_app,
      "seller_id": localStorage.getItem("id"),
      "nome_lote": nome_lote,
      "id_forma_pagamento": id_forma_pagamento,
      "id_prazo_pagamento": id_prazo_pagamento
    };

    return this.http.post<ProdutoPromocional[]>(this.baseUrl + "api/Produto/GetProdutoPromocionalByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Referência) */

  public getReferenciaClienteByGuidClienteNapis(): Observable<ProdutoReferenciaCliente[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<ProdutoReferenciaCliente[]>(this.baseUrl + "api/Produto/GetReferenciaClienteByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Segmento) */

  public addProdutoSegmento(produtoSegmento: ProdutoSegmento): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoSegmento": produtoSegmento
    };

    return this.http.post<number>(this.baseUrl + "api/Produto/AddProdutoSegmento", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public deleteProdutoSegmentoById(id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/DeleteProdutoSegmentoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getProdutoSegmentoByIdProduto(id_produto: number): Observable<ProdutoSegmento[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto": id_produto
    };

    return this.http.post<ProdutoSegmento[]>(this.baseUrl + "api/Produto/GetProdutoSegmentoByIdProduto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Similar) */

  public getProdutoSimilarByGuidClienteNapis(): Observable<ProdutoSimilar[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<ProdutoSimilar[]>(this.baseUrl + "api/Produto/GetProdutoSimilarByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Tabela Especial (Itens)) */

  public getProdutoTabelaEspecialItensByIdProdutoTabelaEspecial(id_produto_tabela_especial: number): Observable<ProdutoTabelaEspecialItens[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto_tabela_especial": id_produto_tabela_especial,
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<ProdutoTabelaEspecialItens[]>(this.baseUrl + "api/Produto/GetProdutoTabelaEspecialItensByIdProdutoTabelaEspecial", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateProdutoTabelaEspecialItens(produtoTabelaEspecialItens: ProdutoTabelaEspecialItens[]): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoTabelaEspecialItens": produtoTabelaEspecialItens
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdateProdutoTabelaEspecialItens", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Produto (Tabela Especial) */

  public addProdutoTabelaEspecial(produtoTabelaEspecial: ProdutoTabelaEspecial): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoTabelaEspecial": produtoTabelaEspecial
    };

    return this.http.post<number>(this.baseUrl + "api/Produto/AddProdutoTabelaEspecial", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public getProdutoTabelaEspecialByGuidClienteNapis(): Observable<ProdutoTabelaEspecial[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<ProdutoTabelaEspecial[]>(this.baseUrl + "api/Produto/GetProdutoTabelaEspecialByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getProdutoTabelaEspecialById(id_produto_tabela_especial: number): Observable<ProdutoTabelaEspecial> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto_tabela_especial": id_produto_tabela_especial
    };

    return this.http.post<ProdutoTabelaEspecial>(this.baseUrl + "api/Produto/GetProdutoTabelaEspecialById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public updateProdutoTabelaEspecial(produtoTabelaEspecial: ProdutoTabelaEspecial): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produtoTabelaEspecial": produtoTabelaEspecial
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdateProdutoTabelaEspecial", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Produto (Tabela) */

  public getTabelaByProduto(id_cliente: number, id_vendedor: number, id_produto: number, id_lote: number, id_prazo_pagamento: number): Observable<ProdutoTabela[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_cliente": id_cliente,
      "id_vendedor": id_vendedor,
      "id_produto": id_produto,
      "id_lote": id_lote,
      "id_prazo_pagamento": id_prazo_pagamento
    };

    return this.http.post<ProdutoTabela[]>(this.baseUrl + "api/Produto/GetTabelaByProduto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
