export class CallCenterPausa {
  public id: number;
  public created_at: Date;
  public seller_id: number;
  public id_motivo_pausa: number;
  public hr_inicial_pausa: string;
  public tempo_pausa: string;
  public guidclientenapis: string;
  public data_criacao_usuario: Date;
  public nome: string;
  public hr_final_pausa: string;
  public fullname: string;

  public data_hora_final: Date;
  public data_hora_inicial: Date;

  constructor() { }
}
