import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { DashboardAnaliseComparativaCliente } from '../../../models/dashboard/analise-comparativa/dashboard-analise-comparativa-cliente';
import { DashboardAnaliseComparativaProduto } from '../../../models/dashboard/analise-comparativa/dashboard-analise-comparativa-produto';
import { DashboardAnaliseComparativaVendedor } from '../../../models/dashboard/analise-comparativa/dashboard-analise-comparativa-vendedor';

@Injectable({
  providedIn: 'root'
})

export class DashboardAnaliseComparativaService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Cliente */

  public getClienteByPeriodo(dt_inicial_1periodo: Date, dt_final_1periodo: Date, dt_inicial_2periodo: Date, dt_final_2periodo: Date, cidade: string, cliente: string, vendedor: string): Observable<DashboardAnaliseComparativaCliente[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "dt_inicial_1periodo": dt_inicial_1periodo,
      "dt_final_1periodo": dt_final_1periodo,
      "dt_inicial_2periodo": dt_inicial_2periodo,
      "dt_final_2periodo": dt_final_2periodo,
      "cidade": cidade,
      "cliente": cliente,
      "vendedor": vendedor
    };

    return this.http.post<DashboardAnaliseComparativaCliente[]>(this.baseUrl + "api/DashboardAnaliseComparativa/GetClienteByPeriodo", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto */

  public getProdutoByPeriodo(dt_inicial_1periodo: Date, dt_final_1periodo: Date, dt_inicial_2periodo: Date, dt_final_2periodo: Date, client_id_app: number): Observable<DashboardAnaliseComparativaProduto[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "dt_inicial_1periodo": dt_inicial_1periodo,
      "dt_final_1periodo": dt_final_1periodo,
      "dt_inicial_2periodo": dt_inicial_2periodo,
      "dt_final_2periodo": dt_final_2periodo,
      "client_id_app": client_id_app
    };

    return this.http.post<DashboardAnaliseComparativaProduto[]>(this.baseUrl + "api/DashboardAnaliseComparativa/GetProdutoByPeriodo", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Vendedor(a) */

  public getVendedorByPeriodo(dt_inicial_1periodo: Date, dt_final_1periodo: Date, dt_inicial_2periodo: Date, dt_final_2periodo: Date, cidade: string, cliente: string, vendedor: string): Observable<DashboardAnaliseComparativaVendedor[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "dt_inicial_1periodo": dt_inicial_1periodo,
      "dt_final_1periodo": dt_final_1periodo,
      "dt_inicial_2periodo": dt_inicial_2periodo,
      "dt_final_2periodo": dt_final_2periodo,
      "cidade": cidade,
      "cliente": cliente,
      "vendedor": vendedor
    };

    return this.http.post<DashboardAnaliseComparativaVendedor[]>(this.baseUrl + "api/DashboardAnaliseComparativa/GetVendedorByPeriodo", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
