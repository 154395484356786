export class CallCenterMotivoPausa {
  public id: number;
  public nome: string;
  public is_active: boolean;
  public flag_somente_gestor: boolean;
  public guidclientenapis: string;
  public tempo_pausa: string;

  constructor() { }
}
