export class Tarefa {
  public note: string;
  public dt_deadline: Date;
  public id_seller: number;
  public dt_ultima_atualizacao: Date;
  public is_active: boolean;
  public id_tipo_tarefa: number;
  public client_id: number;
  public created_at: Date;
  public id: string;
  public dt_baixa: Date;
  public observacao_baixa: string;
  public id_call_center_ligacao: number;
  public id_negocio: string;
  public email_tarefa_dia: boolean;
  public nome: string;
  public guidclientenapis: string;
  public nome_cliente: string;

  constructor() { }
}
