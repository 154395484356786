export class CanaisComunicacaoTelefoniaMensagem {
  public messageType: string;
  public eventType: string;
  public errorCode: number;
  public errorText: string;
  public transactionId: string;
  public userAgentString: string;
  public contentType: string;
  public contentLength: number;
  public content: string;

  constructor() { }
}
