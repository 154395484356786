export class Produto {
  public id: number;
  public name: string;
  public is_active: boolean;
  public price: number;
  public unit: string;
  public guidclientenapis: string;
  public codigo: string;
  public custo: number;
  public valorunidade: number;
  public estoque: number;
  public dt_atualizacao: Date;
  public referencia_cliente: string;
  public subgrupo_estoque: string;
  public grupo_estoque: string;
  public id_no_erp_do_cliente: string;
  public ficha_tecnica: string;
  public categoria: string;
  public promocao: boolean;
  public multiplo: number;
  public mostrar_b2b: boolean;
  public subgrupo_estoque2: string;
  public subgrupo_estoque3: string;
  public subgrupo_estoque4: string;
  public subgrupo_estoque5: string;
  public valor_litragem: number;
  public peso: number;
  public qtde_lote: number;

  /* Última Compra */

  public ultima_compra: Date;
  public quantity: number;
  public unit_price: number;

  public exibir: boolean;
  public erro_qtde: boolean;
  public erro_valor: boolean;
  public valor_tabela: number;
  public qtde: number;
  public valor: number;
  public valor_total: number;
  public porcentagem_desconto: number;
  public descritivo_desconto: string;
  public id_order_item: number;

  /* Lote */

  public id_lote: number;
  public nome_lote: string;
  public cod_lote: string;
  public estoque_lote: number;
  public validade_lote: Date;
  public preco_lote: number;

  constructor() { }
}
