import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { DashboardVisitasCanalVenda } from '../../../models/dashboard/visitas/dashboard-visitas-canal-venda';
import { DashboardVisitasPrimeiraVisita } from '../../../models/dashboard/visitas/dashboard-visitas-primeira-visita';
import { DashboardVisitasVisita } from '../../../models/dashboard/visitas/dashboard-visitas-visita';

@Injectable({
  providedIn: 'root'
})

export class DashboardVisitasService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Canal de Vendas */

  public getCanalVendaByUsuario(hoje: boolean): Observable<DashboardVisitasCanalVenda[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id"),
      "hoje": hoje
    };

    return this.http.post<DashboardVisitasCanalVenda[]>(this.baseUrl + "api/DashboardVisitas/GetCanalVendaByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Primeira Visita */

  public getPrimeiraVisitaByUsuario(): Observable<DashboardVisitasPrimeiraVisita[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<DashboardVisitasPrimeiraVisita[]>(this.baseUrl + "api/DashboardVisitas/GetPrimeiraVisitaByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Visita */

  public getVisitaByUsuario(hoje: boolean): Observable<DashboardVisitasVisita[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id"),
      "hoje": hoje
    };

    return this.http.post<DashboardVisitasVisita[]>(this.baseUrl + "api/DashboardVisitas/GetVisitaByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
