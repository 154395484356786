import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ConversaoService } from '../../core/tools/conversao.service';
import { ExcelService } from '../../core/tools/excel.service';
import { FormatacaoService } from '../../core/tools/formatacao.service';
import { ProdutoService } from '../../core/services/produtos/produto.service';

/* Models */

import { ImportacoesErro } from '../../core/models/importacoes/importacoes-erro';
import { Produto } from '../../core/models/produtos/produto';

@Component({
  selector: 'app-importacoes-produtos',
  templateUrl: './importacoes-produtos.component.html',
  styleUrls: ['./importacoes-produtos.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ImportacoesProdutosComponent implements OnInit {
  /* Arquivo */

  arquivoProcessado: boolean = false;

  /* Dados */

    /* Produtos */

    produtos: Produto[] = [];

  /* Importações */

  importacoesErros: ImportacoesErro[] = [];

  /* Storage */

  guidclientenapis: string = localStorage.getItem("guidclientenapis");

  @ViewChild("arquivo", { static: false }) arquivo: ElementRef;

  constructor(
    private toastr: ToastrService,
    private conversaoService: ConversaoService,
    private excelService: ExcelService,
    private formatacaoService: FormatacaoService,
    private produtoService: ProdutoService
  ) { }

  ngOnInit() {
  }

  reiniciarDados(com_arquivo: boolean) {
    /* Arquivo */

    this.arquivoProcessado = false;

    /* Dados */

      /* Produtos */

      this.produtos = [];

    /* Importações */

    this.importacoesErros = [];

    if (com_arquivo) {
      this.arquivo.nativeElement.value = "";
    }
  }

  /* Importar */

  importar() {
    if (this.arquivoProcessado && this.produtos.length > 0) {
      let adicionarProdutos: boolean = false;

      this.produtoService.addMultiplos(this.produtos).subscribe(
        adicionarProduto => adicionarProdutos = adicionarProduto,
        error => { console.log("Erro: " + error) },
        () => {
          if (adicionarProdutos) {
            this.toastr.success("", "Arquivo importado com sucesso!");

            this.reiniciarDados(true);
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");
          }
        }
      );
    }
  }

  /* Processar */

  processar() {
    this.reiniciarDados(false);

    if (this.arquivo.nativeElement.files.length == 0) {
      this.toastr.error("", "Nenhum arquivo selecionado!");

      return;
    } else if (this.arquivo.nativeElement.files[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.toastr.error("", "Arquivo inválido!");

      return;
    }

    const arquivoParaBinario = this.conversaoService.arquivoParaBinario(this.arquivo.nativeElement.files[0]);

    arquivoParaBinario.then((retorno) => {
      if (retorno != null) {
        let dados = this.excelService.importExcelBinaryToJson(retorno);

        if (dados.length > 0) {
          /* Validação */

          dados.forEach((item, i) => {
            /* Descrição */

            let descricao: string = item["descricao"] == undefined ? "" : String(item["descricao"]);

            if (descricao.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "descricao", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }

            /* Ativo */

            if (item["ativo"] == undefined) {
              let importacaoErro = new ImportacoesErro(i + 2, "ativo", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            } else {
              let ativo: string = String(item["ativo"]);

              if (ativo != "0" && ativo != "1") {
                let importacaoErro = new ImportacoesErro(i + 2, "ativo", "Formato inválido", "Informar 0 p/ falso ou 1 p/ verdadeiro");

                this.importacoesErros.push(importacaoErro);
              }
            }

            /* Preço de Venda */

            let precoVenda: string = item["preco_venda"] == undefined ? "" : String(item["preco_venda"]);

            if (precoVenda.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "preco_venda", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }
          });

          if (this.importacoesErros.length == 0) {
            /* Importação */

            dados.forEach((item) => {
              let produto = new Produto();

              produto.name = item["descricao"] == undefined ? null : String(item["descricao"]).trim().substring(0, 500).toUpperCase();
              produto.is_active = item["ativo"] == undefined ? false : (item["ativo"] == "1");
              produto.price = item["preco_venda"] == undefined ? null : this.formatacaoService.duasCasasDecimais(item["preco_venda"]);
              produto.codigo = item["codigo"] == undefined ? null : String(item["codigo"]).trim().substring(0, 50);
              produto.custo = item["custo"] == undefined ? null : this.formatacaoService.duasCasasDecimais(item["custo"]);
              produto.valorunidade = item["valor_unidade"] == undefined ? null : this.formatacaoService.duasCasasDecimais(item["valor_unidade"]);
              produto.estoque = item["estoque"] == undefined ? null : this.formatacaoService.duasCasasDecimais(item["estoque"]);
              produto.referencia_cliente = item["referencia"] == undefined ? null : String(item["referencia"]).trim().substring(0, 20);
              produto.subgrupo_estoque = item["subgrupo_estoque"] == undefined ? null : String(item["subgrupo_estoque"]).trim().substring(0, 30);
              produto.grupo_estoque = item["grupo_estoque"] == undefined ? null : String(item["grupo_estoque"]).trim().substring(0, 30);
              produto.ficha_tecnica = item["descricao_longa"] == undefined ? null : String(item["descricao_longa"]).trim().substring(0, 2500);
              produto.subgrupo_estoque2 = item["subgrupo_estoque_1"] == undefined ? null : String(item["subgrupo_estoque_1"]).trim().substring(0, 30);
              produto.subgrupo_estoque3 = item["subgrupo_estoque_2"] == undefined ? null : String(item["subgrupo_estoque_2"]).trim().substring(0, 30);

              produto.guidclientenapis = this.guidclientenapis;

              this.produtos.push(produto);
            });

            this.toastr.success("", "Arquivo processado com sucesso!");
          } else {
            this.toastr.error("", "Existe(m) linha(s) com erro(s)!");
          }

          /* Arquivo */

          this.arquivoProcessado = true;
        } else {
          this.toastr.error("", "Arquivo em branco!");
        }
      } else {
        this.reiniciarDados(true);

        this.toastr.warning("", "Selecione o arquivo novamente...");
      }
    });
  }
}
