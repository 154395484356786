import { Component, OnInit } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';

@Component({
  selector: 'app-importacoes',
  templateUrl: './importacoes.component.html',
  styleUrls: ['./importacoes.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ImportacoesComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Abas */

  tabSelecionada: string = "clientes";

  constructor() { }

  ngOnInit() {
  }

  /* Abas */

  alterarAbas(evento: any) {
    this.tabSelecionada = evento.nextId;
  }
}
