import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { AutomacaoEventoService } from '../../core/services/automacao-eventos/automacao-evento.service';

/* Models */

import { AutomacaoEventoMensagem } from '../../core/models/automacao-eventos/automacao-evento-mensagem';
import { AutomacaoEventoTipo } from '../../core/models/automacao-eventos/automacao-evento-tipo';
import { AutomacaoEventoTipoVariaveis } from '../../core/models/automacao-eventos/automacao-evento-tipo-variaveis';

@Component({
  selector: 'app-automacao-mensagens-cadastro',
  templateUrl: './automacao-mensagens-cadastro.component.html',
  styleUrls: ['./automacao-mensagens-cadastro.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class AutomacaoMensagensCadastroComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Eventos (Mensagem) */

  automacaoEventoMensagem: AutomacaoEventoMensagem = null;

  /* Eventos (Tipo) */

  automacaoEventosTipos: AutomacaoEventoTipo[] = [];

  automacaoEventoTipoSelecionado: AutomacaoEventoTipo = null;

  /* Eventos (Tipo Variavéis) */

  automacaoEventosTiposVariaveis: AutomacaoEventoTipoVariaveis[] = [];

  automacaoEventoTipoVariaveisSelecionado: AutomacaoEventoTipoVariaveis = null;

  /* Formulário */

  formDados: FormGroup;

  enviado: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private automacaoEventoService: AutomacaoEventoService
  ) { }

  ngOnInit() {
    this.formDados = this.formBuilder.group({
      mensagem: ["", Validators.required]
    });

    this.carregarDados();
  }

  get f() {
    return this.formDados.controls;
  }

  /* Dados */

  addUpdate() {
    let retorno = new Promise<void>((resolver) => {
      let automacaoEventoMensagem = new AutomacaoEventoMensagem();

      automacaoEventoMensagem.id_tipo = this.automacaoEventoTipoSelecionado.id;
      automacaoEventoMensagem.mensagem = this.formDados.controls["mensagem"].value;
      automacaoEventoMensagem.guidcliente = localStorage.getItem("guidclientenapis");

      if (this.automacaoEventoMensagem != null) {
        /* Alterando */

        automacaoEventoMensagem.id = this.automacaoEventoMensagem.id;

        let alterarAutomacaoEventoMensagem: boolean = false;

        this.automacaoEventoService.updateMensagem(automacaoEventoMensagem).subscribe(
          alterarAutomacaoEventosMensagens => alterarAutomacaoEventoMensagem = alterarAutomacaoEventosMensagens,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarAutomacaoEventoMensagem) {
              this.toastr.success("", "Mensagem (Automação) salva com sucesso!");
            }

            return resolver();
          }
        );

      } else {
        /* Adicionando */

        let adicionarAutomacaoEventoMensagem: number = 0;

        this.automacaoEventoService.addMensagem(automacaoEventoMensagem).subscribe(
          adicionarAutomacaoEventosMensagens => adicionarAutomacaoEventoMensagem = adicionarAutomacaoEventosMensagens,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarAutomacaoEventoMensagem > 0) {
              this.toastr.success("", "Mensagem (Automação) salva com sucesso!");
            }

            return resolver();
          }
        );
      }

      return resolver();
    });

    return retorno;
  }

  carregarDados() {
    this.carregando = true;

    /* Eventos (Tipo) */

    this.automacaoEventoService.getEventoTipo().subscribe(
      automacaoEventosTipos => this.automacaoEventosTipos = automacaoEventosTipos,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.automacaoEventosTipos.length > 0) {
          this.automacaoEventoTipoSelecionado = this.automacaoEventosTipos[0];

          const carregarDadosTipoVariaveis = this.carregarDadosTipoVariaveis(this.automacaoEventoTipoSelecionado.id);

          carregarDadosTipoVariaveis.then(() => {
            this.verificarAdicionandoAlterando();
          });
        }
      }
    );
  }

  carregarDadosTipoVariaveis(id_tipo: number) {
    let retorno = new Promise<void>((resolver) => {
      /* Eventos (Tipo Variáveis) */

      this.automacaoEventoService.getEventoTipoVariaveisByTipo(id_tipo).subscribe(
        automacaoEventosTiposVariaveis => this.automacaoEventosTiposVariaveis = automacaoEventosTiposVariaveis,
        error => { console.log("Erro: " + error) },
        () => {
          return resolver();
        }
      );
    });

    return retorno;
  }

  prepararReceberDados(id: number) {
    let retorno = new Promise<void>((resolver) => {
      this.automacaoEventoService.getMensagemById(id).subscribe(
        automacaoEventosMensagens => this.automacaoEventoMensagem = automacaoEventosMensagens,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.automacaoEventoMensagem != null) {
            this.formDados.controls["mensagem"].setValue(this.automacaoEventoMensagem.mensagem);

            return resolver();
          } else {
            this.toastr.error("", "Mensagem (Automação) inválida!");

            this.router.navigate(["/automacao-mensagens/"]);
          }
        }
      );
    });

    return retorno;
  }

  salvar() {
    this.enviado = true;

    if (this.formDados.invalid) {
      return;
    }

    const addUpdate = this.addUpdate();

    addUpdate.then(() => {
      this.router.navigate(["/automacao-mensagens/"]);
    });
  }

  verificarAdicionandoAlterando() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    let id: string = null;

    this.route.params.subscribe(params => { id = params.id; });

    if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
      /* Alterando */

      const prepararReceberDados = this.prepararReceberDados(parseInt(id));

      prepararReceberDados.then(() => {
        this.carregando = false;
      });
    } else {
      /* Adicionando */

      this.carregando = false;
    }
  }

  /* Eventos (Tipo) */

  selecionarAutomacaoEventoTipo(automacao_evento_tipo: string) {
    this.automacaoEventoTipoSelecionado = this.automacaoEventosTipos.find(automacaoEventoTipo => automacaoEventoTipo.id == parseInt(automacao_evento_tipo));

    if (this.automacaoEventoTipoSelecionado != null) {
      this.carregarDadosTipoVariaveis(this.automacaoEventoTipoSelecionado.id);
    } else {
      this.automacaoEventosTiposVariaveis = [];
    }

    this.automacaoEventoTipoVariaveisSelecionado = null;

    this.formDados.controls["mensagem"].setValue("");

    this.enviado = false;
  }

  /* Eventos (Tipo Variavéis) */

  selecionarAutomacaoEventoTipoVariaveis(evento: any, automacao_evento_tipo_variaveis: string) {
    this.automacaoEventoTipoVariaveisSelecionado = this.automacaoEventosTiposVariaveis.find(automacaoEventoTipoVariaveis => automacaoEventoTipoVariaveis.id == parseInt(automacao_evento_tipo_variaveis));

    if (this.automacaoEventoTipoVariaveisSelecionado != null) {
      let novaMensagem: string = (this.formDados.controls["mensagem"].value + " {" + this.automacaoEventoTipoVariaveisSelecionado.variavel + "} ").toString().replace("  ", " ").trim();

      this.formDados.controls["mensagem"].setValue(novaMensagem);

      this.automacaoEventoTipoVariaveisSelecionado = null;

      evento.target.selectedIndex = 0;
    }
  }
}
