export class CallCenterLigacaoSintetica {
  constructor(
    public periodo: string,
    public qtdeligacoes: number,
    public qtdevenda: number,
    public porcentagemvenda: number,
    public qtdeligacoessemvenda: number,
    public porcentagemligacoessemvenda: number,
    public qtdeligacoesfeitas: number,
    public porcentagemligacoesfeitas: number,
    public qtdeligacoesrecebidas: number,
    public porcentagemligacoesrecebidas: number,
    public qtdeoutros: number,
    public porcentagemoutros: number
  ) { }
}
