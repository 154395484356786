export class ProdutoTabelaEspecialItens {
  public idprodutoespecial: number;
  public idprodutotabelaespecial: number;
  public nome: string;
  public ativo: boolean;
  public preco: number;
  public unit: string;
  public guidclientenapis: string;
  public codigo: string;
  public custo: number;
  public valorunidade: number;
  public qtd_minima: number;
  public dt_ultima_atualizacao: Date;
  public sequencia: number;
  public id: number;
  public valorminimo: number;
  public ativo_integracao: boolean;
  public id_lote: number;

  /* Produto */

  public name: string;
  public price: number;

  /* Lote */

  public nome_lote: string;
  public cod_lote: string;
  public estoque_lote: number;
  public validade_lote: Date;
  public preco_lote: number;

  constructor() { }
}
