import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { DashboardCallCenterLigacao } from '../../../models/dashboard/call-center/dashboard-call-center-ligacao';
import { DashboardCallCenterPedido } from '../../../models/dashboard/call-center/dashboard-call-center-pedido';

@Injectable({
  providedIn: 'root'
})

export class DashboardCallCenterService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Ligação */

  public getLigacaoByUsuario(): Observable<DashboardCallCenterLigacao[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<DashboardCallCenterLigacao[]>(this.baseUrl + "api/DashboardCallCenter/GetLigacaoByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Pedido */

  public getPedidoByUsuario(): Observable<DashboardCallCenterPedido[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<DashboardCallCenterPedido[]>(this.baseUrl + "api/DashboardCallCenter/GetPedidoByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
