import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../../core/tools/animacao';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

/* Services */

import { DashboardAnaliseComparativaService } from '../../../core/services/dashboard/analise-comparativa/dashboard-analise-comparativa.service';
import { FormatacaoService } from '../../../core/tools/formatacao.service';

/* Models */

import { DashboardAnaliseComparativaCliente } from '../../../core/models/dashboard/analise-comparativa/dashboard-analise-comparativa-cliente';
import { DashboardAnaliseComparativaProduto } from '../../../core/models/dashboard/analise-comparativa/dashboard-analise-comparativa-produto';
import { DashboardAnaliseComparativaVendedor } from '../../../core/models/dashboard/analise-comparativa/dashboard-analise-comparativa-vendedor';

@Component({
  selector: 'app-dashboard-analise-comparativa',
  templateUrl: './dashboard-analise-comparativa.component.html',
  styleUrls: ['./dashboard-analise-comparativa.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class DashboardAnaliseComparativaComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Filtros */

  formFiltro: FormGroup;

  dataInicial1Periodo: Date = null;
  dataFinal1Periodo: Date = null;
  dataInicial2Periodo: Date = null;
  dataFinal2Periodo: Date = null;

  razaoSocial: string = "";

  filtrado: boolean = false;

  /* Análise Comparativa (Clientes) */

  analiseComparativaClientes: DashboardAnaliseComparativaCliente[] = [];
  analiseComparativaClientesOriginal: DashboardAnaliseComparativaCliente[] = [];

  total1PeriodoClientes: number = 0;
  total2PeriodoClientes: number = 0;
  totalGanhoPerdaClientes: number = 0;

  /* Análise Comparativa (Produtos) */

  analiseComparativaProdutos: DashboardAnaliseComparativaProduto[] = [];
  analiseComparativaProdutosOriginal: DashboardAnaliseComparativaProduto[] = [];

  /* Análise Comparativa (Vendedores) */

  analiseComparativaVendedores: DashboardAnaliseComparativaVendedor[] = [];
  analiseComparativaVendedoresOriginal: DashboardAnaliseComparativaVendedor[] = [];

  /* Análise Comparativa (Clientes (Agrupadas)) */

  analiseComparativaClientesAgrupadas = null;

  /* Análise Comparativa (Produtos (Agrupados)) */

  analiseComparativaProdutosAgrupados = null;

  /* Gráfico de Efetividade das Visitas por Vendedor */

  barChartOptionsVendedor: ChartOptions = {
    animation: {
      duration: 0
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontColor: "rgba(0, 0, 0, 1)"
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)",
            stepSize: 30000
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            borderDash: [5],
            lineWidth: 1.4
          },
          ticks: {
            fontColor: "rgba(0, 0, 0, 1)"
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        align: "top",
        anchor: "start",
        color: "rgba(0, 0, 0, 1)",
        rotation: -90,
        font: {
          weight: "bold"
        }
      }
    }
  };

  barChartLabelsVendedor: Label[] = [];
  barChartTypeVendedor: ChartType = "bar";
  barChartLegendVendedor = true;
  barChartPluginsVendedor = [pluginDataLabels];

  barChartDataVendedor: ChartDataSets[] = [
    { data: [], label: "1º Período" },
    { data: [], label: "2º Período" }
  ];

  lineChartColorsVendedor: Array<any> = [
    {
      backgroundColor: "rgba(0, 123, 255, 1)",
      borderColor: "rgba(0, 123, 255, 1)",
      pointBackgroundColor: "rgba(0, 123, 255, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(0, 123, 255, 1)"
    },
    {
      backgroundColor: "rgba(138, 0, 230, 1)",
      borderColor: "rgba(138, 0, 230, 1)",
      pointBackgroundColor: "rgba(138, 0, 230, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(138, 0, 230, 1)"
    }
  ];

  /* Modal */

  @ViewChild("detalhes", { static: false }) detalhes: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private dashboardAnaliseComparativaService: DashboardAnaliseComparativaService,
    private formatacaoService: FormatacaoService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      dt_inicial_1periodo: ["", Validators.required],
      dt_final_1periodo: ["", Validators.required],
      dt_inicial_2periodo: ["", Validators.required],
      dt_final_2periodo: ["", Validators.required],
      cidade: [""],
      cliente: [""],
      vendedor: [""]
    });
  }

  get f() {
    return this.formFiltro.controls;
  }

  /* Dados */

  calcularDados() {
    /* Gráfico de Faturamento por Vendedor */

    if (this.analiseComparativaVendedores != null) {
      this.barChartLabelsVendedor = [];
      this.barChartDataVendedor[0].data = [];
      this.barChartDataVendedor[1].data = [];

      this.analiseComparativaVendedores.forEach(analiseComparativaVendedor => {
        this.barChartLabelsVendedor.push(analiseComparativaVendedor.fullname);

        this.barChartDataVendedor[0].data.push(this.formatacaoService.duasCasasDecimais(analiseComparativaVendedor.total_pedido_1periodo));

        this.barChartDataVendedor[1].data.push(this.formatacaoService.duasCasasDecimais(analiseComparativaVendedor.total_pedido_2periodo));
      });
    }

    if (this.analiseComparativaClientes != null) {
      /* Comparativo entre Períodos por Cidade */

      this.total1PeriodoClientes = this.analiseComparativaClientes.reduce((sum, current) => sum + current.total_pedido_1periodo, 0);
      this.total2PeriodoClientes = this.analiseComparativaClientes.reduce((sum, current) => sum + current.total_pedido_2periodo, 0);
      this.totalGanhoPerdaClientes = this.total2PeriodoClientes - this.total1PeriodoClientes;

      this.analiseComparativaClientesAgrupadas = this.analiseComparativaClientes.reduce((obj, item) => {
        obj[item.cidade] = obj[item.cidade] || [];
        obj[item.cidade].push(item);

        return obj;
      }, {});

      this.analiseComparativaClientesAgrupadas = Object.keys(this.analiseComparativaClientesAgrupadas).map((key) => {
        let cidade = this.analiseComparativaClientesAgrupadas[key][0].cidade;
        let total1Periodo = this.analiseComparativaClientesAgrupadas[key].reduce((sum, current) => sum + current.total_pedido_1periodo, 0);
        let total2Periodo = this.analiseComparativaClientesAgrupadas[key].reduce((sum, current) => sum + current.total_pedido_2periodo, 0);
        let ganhoPerda = total2Periodo - total1Periodo;

        return {
          cidade: cidade,
          total_1periodo: total1Periodo,
          total_2Periodo: total2Periodo,
          ganho_perda: ganhoPerda
        }
      }).sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.ganho_perda, b.ganho_perda) * -1; /* Decrescente */
      });
    }
  }

  calcularDadosProdutos() {
    this.analiseComparativaProdutosAgrupados = this.analiseComparativaProdutos.reduce((obj, item) => {
      obj[item.grupo_estoque] = obj[item.grupo_estoque] || [];
      obj[item.grupo_estoque].push(item);

      return obj;
    }, {});

    this.analiseComparativaProdutosAgrupados = Object.keys(this.analiseComparativaProdutosAgrupados).map((key) => {
      let grupoEstoque = this.analiseComparativaProdutosAgrupados[key][0].grupo_estoque;
      let total1Periodo = this.analiseComparativaProdutosAgrupados[key].reduce((sum, current) => sum + current.total_pedido_1periodo, 0);
      let total2Periodo = this.analiseComparativaProdutosAgrupados[key].reduce((sum, current) => sum + current.total_pedido_2periodo, 0);
      let ganhoPerda = total2Periodo - total1Periodo;

      return {
        grupo_estoque: grupoEstoque,
        total_1periodo: total1Periodo,
        total_2Periodo: total2Periodo,
        ganho_perda: ganhoPerda
      }
    }).sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a.ganho_perda, b.ganho_perda) * -1; /* Decrescente */
    });
  }

  carregarDadosFiltrados(dt_inicial_1periodo: Date, dt_final_1periodo: Date, dt_inicial_2periodo: Date, dt_final_2periodo: Date, cidade: string, cliente: string, vendedor: string) {
    let retorno = new Promise<void>((resolver) => {
      this.carregando = true;

      this.dashboardAnaliseComparativaService.getClienteByPeriodo(dt_inicial_1periodo, dt_final_1periodo, dt_inicial_2periodo, dt_final_2periodo, cidade, cliente, vendedor).subscribe(
        analiseComparativaCliente => this.analiseComparativaClientes = analiseComparativaCliente,
        error => { console.log("Erro: " + error) },
        () => {
          this.analiseComparativaClientesOriginal = JSON.parse(JSON.stringify(this.analiseComparativaClientes));

          this.dashboardAnaliseComparativaService.getVendedorByPeriodo(dt_inicial_1periodo, dt_final_1periodo, dt_inicial_2periodo, dt_final_2periodo, cidade, cliente, vendedor).subscribe(
            analiseComparativaVendedor => this.analiseComparativaVendedores = analiseComparativaVendedor,
            error => { console.log("Erro: " + error) },
            () => {
              this.analiseComparativaVendedoresOriginal = JSON.parse(JSON.stringify(this.analiseComparativaVendedores));

              return resolver();
            }
          );
        }
      );
    });

    return retorno;
  }

  /* Filtros */

  filtrar() {
    this.filtrado = false;

    if (this.formFiltro.controls["dt_inicial_1periodo"].value != "" && this.formFiltro.controls["dt_inicial_1periodo"].value != null &&
      this.formFiltro.controls["dt_final_1periodo"].value != "" && this.formFiltro.controls["dt_final_1periodo"].value != null &&
      this.formFiltro.controls["dt_inicial_2periodo"].value != "" && this.formFiltro.controls["dt_inicial_2periodo"].value != null &&
      this.formFiltro.controls["dt_final_2periodo"].value != "" && this.formFiltro.controls["dt_final_2periodo"].value != null) {

      /* Dt. Inicial (1º Período) */

      let dataInicial = this.formFiltro.controls["dt_inicial_1periodo"].value;

      this.dataInicial1Periodo = new Date(dataInicial.year, dataInicial.month - 1, dataInicial.day);

      /* Dt. Final (1º Período) */

      let dataFinal = this.formFiltro.controls["dt_final_1periodo"].value;

      this.dataFinal1Periodo = new Date(dataFinal.year, dataFinal.month - 1, dataFinal.day);

      /* Dt. Inicial (2º Período) */

      dataInicial = this.formFiltro.controls["dt_inicial_2periodo"].value;

      this.dataInicial2Periodo = new Date(dataInicial.year, dataInicial.month - 1, dataInicial.day);

      /* Dt. Final (2º Período) */

      dataFinal = this.formFiltro.controls["dt_final_2periodo"].value;

      this.dataFinal2Periodo = new Date(dataFinal.year, dataFinal.month - 1, dataFinal.day);

      const carregarDadosFiltrados = this.carregarDadosFiltrados(this.dataInicial1Periodo, this.dataFinal1Periodo, this.dataInicial2Periodo, this.dataFinal2Periodo, this.formFiltro.controls["cidade"].value.trim().toLowerCase(), this.formFiltro.controls["cliente"].value.trim().toLowerCase(), this.formFiltro.controls["vendedor"].value.trim().toLowerCase());

      carregarDadosFiltrados.then(() => {
        this.calcularDados();

        this.carregando = false;
        this.filtrado = true;
      });
    }
  }

  removerFiltros() {
    this.formFiltro.reset();

    Object.keys(this.formFiltro.controls).forEach(key => {
      this.formFiltro.controls[key].setValue("");
    });

    this.filtrado = false;
  }

  /* Modal */

  modalDetalhes(content: any, client_id_app: number, razao_social: string) {
    this.dashboardAnaliseComparativaService.getProdutoByPeriodo(this.dataInicial1Periodo, this.dataFinal1Periodo, this.dataInicial2Periodo, this.dataFinal2Periodo, client_id_app).subscribe(
      analiseComparativaProduto => this.analiseComparativaProdutos = analiseComparativaProduto,
      error => { console.log("Erro: " + error) },
      () => {
        this.analiseComparativaProdutosOriginal = JSON.parse(JSON.stringify(this.analiseComparativaProdutos));

        if (this.analiseComparativaProdutos != null) {
          this.razaoSocial = razao_social;

          this.calcularDadosProdutos();

          this.modalService.open(content, {
            ariaLabelledBy: "titulo-detalhes",
            size: "sm",
            backdrop: "static"
          });
        }
      }
    );
  }
}
