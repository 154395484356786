import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Configuracao } from '../../models/configuracoes/configuracao';
import { Transportadora } from '../../models/transportadoras/transportadora';

@Injectable({
  providedIn: 'root'
})

export class TransportadoraService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getByGuidClienteNapis(configuracao: Configuracao): Observable<Transportadora[]> {
    if (configuracao != null && !configuracao.seleciona_transportadora) {
      return of([]);
    }

    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Transportadora[]>(this.baseUrl + "api/Transportadora/GetByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
