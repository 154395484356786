import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Cliente } from '../../models/clientes/cliente';
import { ClienteArquivo } from '../../models/clientes/cliente-arquivo';
import { ClienteFiltrar } from '../../models/clientes/cliente-filtrar';
import { ClienteLocalEntrega } from '../../models/clientes/cliente-local-entrega';
import { ClienteTabela } from '../../models/clientes/cliente-tabela';

@Injectable({
  providedIn: 'root'
})

export class ClienteService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public add(cliente: Cliente): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "cliente": cliente
    };

    return this.http.post<number>(this.baseUrl + "api/Cliente/Add", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public addMultiplos(clientes: Cliente[]): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "clientes": clientes
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/AddMultiplos", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getByClientDocument(client_document: string): Observable<Cliente> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_document": client_document,
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Cliente>(this.baseUrl + "api/Cliente/GetByClientDocument", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getByCodigoERPCliente(codigo_no_erp_do_cliente: string): Observable<Cliente> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "codigo_no_erp_do_cliente": codigo_no_erp_do_cliente,
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Cliente>(this.baseUrl + "api/Cliente/GetByCodigoERPCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getByGuidClienteNapis(filtrar?: ClienteFiltrar): Observable<Cliente[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "filtrar": filtrar
    };

    return this.http.post<Cliente[]>(this.baseUrl + "api/Cliente/GetByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getById(client_id_app: number): Observable<Cliente> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<Cliente>(this.baseUrl + "api/Cliente/GetById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getByTelefone(telefone: string): Observable<Cliente> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "telefone": telefone,
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<Cliente>(this.baseUrl + "api/Cliente/GetByTelefone", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getByUsuario(inativos: boolean, filtrar?: ClienteFiltrar): Observable<Cliente[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id"),
      "inativos": inativos,
      "filtrar": filtrar
    };

    return this.http.post<Cliente[]>(this.baseUrl + "api/Cliente/GetByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public sendEmailCadastro(client_id_app: number, email: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app,
      "email": email
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/SendEmailCadastro", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public update(cliente: Cliente): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "cliente": cliente
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/Update", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateCadastroEfetivadoById(client_id_app: number, efetivar: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app,
      "efetivar": efetivar
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/UpdateCadastroEfetivadoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateContatoById(client_id_app: number, contato: string, email_02: string, observacao: string, telefone_2: string, telefone_3: string, ddd_whatsApp: string, telefone_whatsApp: string, inside_sales_ativo: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app,
      "contato": contato,
      "email_02": email_02,
      "observacao": observacao,
      "telefone_2": telefone_2,
      "telefone_3": telefone_3,
      "ddd_whatsApp": ddd_whatsApp,
      "telefone_whatsApp": telefone_whatsApp,
      "inside_sales_ativo": inside_sales_ativo
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/UpdateContatoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateGeolocalizacaoAntigaById(client_id_app: number) {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/updateGeolocalizacaoAntigaById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateGeolocalizacaoById(client_id: number, oficial_gps_lat: number, oficial_gps_lon: number, precisao: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id": client_id,
      "oficial_gps_lat": oficial_gps_lat,
      "oficial_gps_lon": oficial_gps_lon,
      "precisao": precisao
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/UpdateGeolocalizacaoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateIsActiveById(client_id_app: number, is_active: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app,
      "is_active": is_active
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/UpdateIsActiveById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updatePasswordById(client_id_app: number, liberar: boolean, password_b2b: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app,
      "liberar": liberar,
      "password_b2b": password_b2b
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/UpdatePasswordById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updatePasswordInicialById(client_id_app: number, liberar: boolean): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app,
      "liberar": liberar
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/UpdatePasswordInicialById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateWhatsAppById(client_id_app: number, fone_whatss_ddd: string, fone_whatss: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app,
      "fone_whatss_ddd": fone_whatss_ddd,
      "fone_whatss": fone_whatss,
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/UpdateWhatsAppById", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public validarCNPJ(client_document: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_document": client_document
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/ValidarCNPJ", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Cliente (Arquivo) */

  public addClienteArquivo(arquivo: File, cliente_arquivo: ClienteArquivo): Observable<boolean> {
    this.headers.append("Content-Type", "multipart/form-data");

    let formData = new FormData();

    formData.append("arquivo", arquivo, arquivo.name);
    formData.set("client_id_app", cliente_arquivo.client_id_app.toString());
    formData.set("descricao", cliente_arquivo.descricao);

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/AddClienteArquivo", formData, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public deleteClienteArquivo(id: string, nm_arquivo: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "nm_arquivo": nm_arquivo
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/DeleteClienteArquivo", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getClienteArquivoByCliente(client_id_app: number): Observable<ClienteArquivo[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<ClienteArquivo[]>(this.baseUrl + "api/Cliente/GetClienteArquivoByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Cliente (Local Entrega) */

  public getClienteLocalEntregaByCliente(id: number): Observable<ClienteLocalEntrega[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<ClienteLocalEntrega[]>(this.baseUrl + "api/Cliente/GetClienteLocalEntregaByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Cliente (Tabela) */

  public getTabelaByIdCliente(client_id_app: number): Observable<ClienteTabela[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<ClienteTabela[]>(this.baseUrl + "api/Cliente/GetTabelaByIdCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Cliente (Tag) */

  public addTag(client_id_app: number, tags: string[]): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app,
      "tags": tags
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/AddTag", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
