import { Component, OnInit } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { IntegracaoService } from '../../core/services/integracoes/integracao.service';

/* Models */

import { Integracao } from '../../core/models/integracoes/integracao';

@Component({
  selector: 'app-integracoes',
  templateUrl: './integracoes.component.html',
  styleUrls: ['./integracoes.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class IntegracoesComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Integrações */

  integracoes: Integracao[] = [];

  /* Storage */

  is_master: boolean = (localStorage.getItem("is_master") == "true");
  is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");

  constructor(
    private toastr: ToastrService,
    private integracaoService: IntegracaoService
  ) { }

  ngOnInit() {
    this.carregarDados();
  }

  carregarDados() {
    this.carregando = true;

    this.integracaoService.getByGuidClienteNapis().subscribe(
      integracoes => this.integracoes = integracoes,
      error => { console.log("Erro: " + error) },
      () => {
        this.carregando = false;
      }
    );
  }

  /* Ações */

  iniciarIntegracao(token: string, url_integracao: string, url_webjob: string) {
    let iniciarIntegracao: boolean = false;

    this.integracaoService.iniciarIntegracao(token, url_integracao, url_webjob).subscribe(
      iniciarIntegracoes => iniciarIntegracao = iniciarIntegracoes,
      error => { console.log("Erro: " + error) },
      () => {
        if (iniciarIntegracao) {
          this.toastr.success("", "Integração realizada com sucesso!");
        }
      }
    );
  }
}
