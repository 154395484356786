export class CallCenterWhatsAppWebHook {
  public id: number;
  public telefone: string;
  public messageid: string;
  public created_at: Date;
  public status: boolean;
  public id_tmk: number;
  public id_client: number;
  public name: string;

  constructor() { }
}
