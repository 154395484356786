import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { AutomacaoEnvio } from '../../models/automacao-eventos/automacao-envio';
import { AutomacaoEvento } from '../../models/automacao-eventos/automacao-evento';
import { AutomacaoEventoMensagem } from '../../models/automacao-eventos/automacao-evento-mensagem';
import { AutomacaoEventoTipo } from '../../models/automacao-eventos/automacao-evento-tipo';
import { AutomacaoEventoTipoVariaveis } from '../../models/automacao-eventos/automacao-evento-tipo-variaveis';

@Injectable({
  providedIn: 'root'
})

export class AutomacaoEventoService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getByEvento(evento: string): Observable<AutomacaoEvento> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "evento": evento,
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<AutomacaoEvento>(this.baseUrl + "api/AutomacaoEvento/GetByEvento", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  /* Eventos */

  public iniciarEvento(link: string, dados: AutomacaoEnvio): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "link": link,
      "dados": dados
    };

    return this.http.post<boolean>(this.baseUrl + "api/AutomacaoEvento/IniciarEvento", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Eventos (Mensagem) */

  public addMensagem(automacao_evento_mensagem: AutomacaoEventoMensagem): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "automacao_evento_mensagem": automacao_evento_mensagem
    };

    return this.http.post<number>(this.baseUrl + "api/AutomacaoEvento/AddMensagem", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public deleteMensagemById(id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<boolean>(this.baseUrl + "api/AutomacaoEvento/DeleteMensagemById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getMensagemByGuidClienteNapis(): Observable<AutomacaoEventoMensagem[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<AutomacaoEventoMensagem[]>(this.baseUrl + "api/AutomacaoEvento/GetMensagemByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getMensagemById(id: number): Observable<AutomacaoEventoMensagem> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<AutomacaoEventoMensagem>(this.baseUrl + "api/AutomacaoEvento/GetMensagemById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public updateMensagem(automacao_evento_mensagem: AutomacaoEventoMensagem): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "automacao_evento_mensagem": automacao_evento_mensagem
    };

    return this.http.post<boolean>(this.baseUrl + "api/AutomacaoEvento/UpdateMensagem", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Eventos (Tipo) */

  public getEventoTipo(): Observable<AutomacaoEventoTipo[]> {
    this.headers.set("Content-Type", "application/json");

    return this.http.post<AutomacaoEventoTipo[]>(this.baseUrl + "api/AutomacaoEvento/GetEventoTipo", null, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Eventos (Tipo Variáveis) */

  public getEventoTipoVariaveisByTipo(id_tipo: number): Observable<AutomacaoEventoTipoVariaveis[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_tipo": id_tipo
    };

    return this.http.post<AutomacaoEventoTipoVariaveis[]>(this.baseUrl + "api/AutomacaoEvento/GetEventoTipoVariaveisByTipo", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}
