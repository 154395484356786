import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Animacao } from '../../../core/tools/animacao';

/* Services */

import { DashboardControleClienteService } from '../../../core/services/dashboard/controle-cliente/dashboard-controle-cliente.service';

/* Models */

import { DashboardControleCliente } from '../../../core/models/dashboard/controle-cliente/dashboard-controle-cliente';
import { DashboardControleClienteSemVisita } from '../../../core/models/dashboard/controle-cliente/dashboard-controle-cliente-sem-visita';
import { DashboardControleClienteVendedor } from '../../../core/models/dashboard/controle-cliente/dashboard-controle-cliente-vendedor';

@Component({
  selector: 'app-dashboard-controle-clientes',
  templateUrl: './dashboard-controle-clientes.component.html',
  styleUrls: ['./dashboard-controle-clientes.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class DashboardControleClientesComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Filtros */

  formFiltro: FormGroup;

  filtrado: boolean = false;

  /* Acompanhamento */

  valorVendasTotal: number = 0;

  top5Clientes: number = 0;
  porcentagemTop5Clientes: number = 0;

  top10Clientes: number = 0;
  porcentagemTop10Clientes: number = 0;

  qtdeClientes: number = 0;

  qtdeClientesSemVisita30Dias: number = 0;
  porcentagemSemVisita30Dias: number = 0;

  qtdeClientesSemVisita60Dias: number = 0;
  porcentagemSemVisita60Dias: number = 0;

  /* Controle Clientes */

  controleClientes: DashboardControleCliente[] = [];
  controleClientesOriginal: DashboardControleCliente[] = [];

  /* Sem Visita (30 Dias) */

  controleClientesSemVisita30Dias: DashboardControleClienteSemVisita[] = [];
  controleClientesSemVisita30DiasOriginal: DashboardControleClienteSemVisita[] = [];

  /* Sem Visita (60 Dias) */

  controleClientesSemVisita60Dias: DashboardControleClienteSemVisita[] = [];
  controleClientesSemVisita60DiasOriginal: DashboardControleClienteSemVisita[] = [];

  /* Controle Clientes (Vendedor) */

  controleClientesVendedor: DashboardControleClienteVendedor[] = [];
  controleClientesVendedorOriginal: DashboardControleClienteVendedor[] = [];

  /* Ordernar */

  ordenarColunaControleClientes: string = "";
  ordenarTextoControleClientes: string = "";

  ordenarColunaControleClientesVendedor: string = "";
  ordenarTextoControleClientesVendedor: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private dashboardControleClienteService: DashboardControleClienteService
  ) { }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      dt_inicial: [""],
      dt_final: [""],
      cliente: [""],
      vendedor: [""]
    });
  }

  /* Dados */

  calcularDados() {
    if (this.controleClientes != null) {
      /* Acompanhamento */

      this.qtdeClientes = this.controleClientes.length;
      this.valorVendasTotal = this.controleClientes.reduce((sum, current) => sum + current.total_pedido, 0);

      this.top5Clientes = 0;
      this.porcentagemTop5Clientes = 0;

      this.top10Clientes = 0;
      this.porcentagemTop10Clientes = 0;

      let resultadoAgrupado = this.controleClientes.reduce((obj, item) => {
        let controleClienteAgrupado = item["id"];

        if (!obj[controleClienteAgrupado]) {
          obj[controleClienteAgrupado] = [];
        }

        obj[controleClienteAgrupado].push(item);

        return obj;
      }, {});

      const controleClientesAgrupados = [];

      for (let id in resultadoAgrupado) {
        let total_pedido = resultadoAgrupado[id].reduce((obj, item) => {
          return obj + item.total_pedido;
        }, 0);

        controleClientesAgrupados.push({
          id: id,
          name: resultadoAgrupado[id][0].name,
          total_pedido: total_pedido
        });
      };

      controleClientesAgrupados.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a["total_pedido"], b["total_pedido"]) * -1; /* Decrescente */
      });

      /* Top 5 */

      let qtdePedidosAgrupados = controleClientesAgrupados.length < 5 ? controleClientesAgrupados.length : 5;

      for (let i = 0; i < qtdePedidosAgrupados; i++) {
        this.top5Clientes = this.top5Clientes + controleClientesAgrupados[i].total_pedido;
      }

      if (this.top5Clientes > 0) {
        this.porcentagemTop5Clientes = ((this.top5Clientes / this.valorVendasTotal) * 100);
      }

      /* Top 10 */

      qtdePedidosAgrupados = controleClientesAgrupados.length < 10 ? controleClientesAgrupados.length : 10;

      for (let i = 0; i < qtdePedidosAgrupados; i++) {
        this.top10Clientes = this.top10Clientes + controleClientesAgrupados[i].total_pedido;
      }

      if (this.top10Clientes > 0) {
        this.porcentagemTop10Clientes = ((this.top10Clientes / this.valorVendasTotal) * 100);
      }

      /* Clientes s/ Visita >= 30 dias */

      if (this.controleClientesSemVisita30Dias != null) {
        this.qtdeClientesSemVisita30Dias = this.controleClientesSemVisita30Dias.length;

        if (this.qtdeClientesSemVisita30Dias > 0) {
          this.porcentagemSemVisita30Dias = ((this.qtdeClientesSemVisita30Dias / this.qtdeClientes) * 100);
        }
      }

      /* Clientes s/ Visita >= 60 dias */

      if (this.controleClientesSemVisita60Dias != null) {
        this.qtdeClientesSemVisita60Dias = this.controleClientesSemVisita60Dias.length;

        if (this.qtdeClientesSemVisita60Dias > 0) {
          this.porcentagemSemVisita60Dias = ((this.qtdeClientesSemVisita60Dias / this.qtdeClientes) * 100);
        }
      }
    }

    if (this.controleClientesVendedor != null) {
      this.controleClientesVendedor.forEach((item) => {
        item.ticket_medio = item.qtde_pedidos > 0 ? (item.total_pedido / item.qtde_pedidos) : 0;
        item.mix = item.qtde_pedidos > 0 ? Math.round(item.qtde_itens / item.qtde_pedidos) : 0;
        item.margem = item.total_custo > 0 ? ((1 - (item.total_custo / item.total_pedido)) * 100) : 0;
      });
    }
  }

  carregarDados(dt_inicial: Date, dt_final: Date) {
    let retorno = new Promise<void>((resolver) => {
      this.carregando = true;

      this.dashboardControleClienteService.getByPeriodo(dt_inicial, dt_final).subscribe(
        controleCliente => this.controleClientes = controleCliente,
        error => { console.log("Erro: " + error) },
        () => {
          this.controleClientesOriginal = JSON.parse(JSON.stringify(this.controleClientes));

          this.dashboardControleClienteService.getVendedorByPeriodo(dt_inicial, dt_final).subscribe(
            controleClienteVendedor => this.controleClientesVendedor = controleClienteVendedor,
            error => { console.log("Erro: " + error) },
            () => {
              this.controleClientesVendedorOriginal = JSON.parse(JSON.stringify(this.controleClientesVendedor));

              this.dashboardControleClienteService.getSemVisita30diasByGuidClienteNapis().subscribe(
                controleClienteSemVisita30Dias => this.controleClientesSemVisita30Dias = controleClienteSemVisita30Dias,
                error => { console.log("Erro: " + error) },
                () => {
                  this.controleClientesSemVisita30DiasOriginal = JSON.parse(JSON.stringify(this.controleClientesSemVisita30Dias));

                  this.dashboardControleClienteService.getSemVisita60diasByGuidClienteNapis().subscribe(
                    controleClienteSemVisita60Dias => this.controleClientesSemVisita60Dias = controleClienteSemVisita60Dias,
                    error => { console.log("Erro: " + error) },
                    () => {
                      this.controleClientesSemVisita60DiasOriginal = JSON.parse(JSON.stringify(this.controleClientesSemVisita60Dias));

                      return resolver();
                    }
                  );
                }
              );
            }
          );
        }
      );
    });

    return retorno;
  }

  /* Filtros */

  filtrar() {
    this.filtrado = false;

    if (this.formFiltro.controls["dt_inicial"].value != "" && this.formFiltro.controls["dt_inicial"].value != null && this.formFiltro.controls["dt_final"].value != "" && this.formFiltro.controls["dt_final"].value != null) {
      /* Dt. Inicial */

      let dataInicial = this.formFiltro.controls["dt_inicial"].value;

      dataInicial = new Date(dataInicial.year, dataInicial.month - 1, dataInicial.day);

      /* Dt. Final */

      let dataFinal = this.formFiltro.controls["dt_final"].value;

      dataFinal = new Date(dataFinal.year, dataFinal.month - 1, dataFinal.day);

      const carregarDados = this.carregarDados(dataInicial, dataFinal);

      carregarDados.then(() => {
        /* Cliente */

        if (this.formFiltro.controls["cliente"].value != "" && this.formFiltro.controls["cliente"].value != null) {
          /* Controle Clientes */

          this.controleClientes = this.controleClientes.filter(controleCliente => {
            return (controleCliente.name.toLowerCase().indexOf(this.formFiltro.controls["cliente"].value.trim().toLowerCase()) !== -1);
          });

          /* Sem Visita (30 Dias) */

          this.controleClientesSemVisita30Dias = this.controleClientesSemVisita30Dias.filter(controleClienteSemVisita30Dias => {
            return (controleClienteSemVisita30Dias.name.toLowerCase().indexOf(this.formFiltro.controls["cliente"].value.trim().toLowerCase()) !== -1);
          });

          /* Sem Visita (60 Dias) */

          this.controleClientesSemVisita60Dias = this.controleClientesSemVisita60Dias.filter(controleClienteSemVisita60Dias => {
            return (controleClienteSemVisita60Dias.name.toLowerCase().indexOf(this.formFiltro.controls["cliente"].value.trim().toLowerCase()) !== -1);
          });
        }

        /* Vendedor(a) */

        if (this.formFiltro.controls["vendedor"].value != "" && this.formFiltro.controls["vendedor"].value != null) {
          /* Controle Clientes */

          this.controleClientes = this.controleClientes.filter(controleCliente => {
            return (controleCliente.fullname.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
          });

          /* Sem Visita (30 Dias) */

          this.controleClientesSemVisita30Dias = this.controleClientesSemVisita30Dias.filter(controleClienteSemVisita30Dias => {
            return (controleClienteSemVisita30Dias.fullname.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
          });

          /* Sem Visita (60 Dias) */

          this.controleClientesSemVisita60Dias = this.controleClientesSemVisita60Dias.filter(controleClienteSemVisita60Dias => {
            return (controleClienteSemVisita60Dias.fullname.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
          });

          /* Controle Clientes (Vendedor) */

          this.controleClientesVendedor = this.controleClientesVendedor.filter(controleClienteVendedor => {
            return (controleClienteVendedor.fullname.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
          });
        }

        this.calcularDados();

        this.carregando = false;
        this.filtrado = true;
      });
    }
  }

  removerFiltros() {
    this.formFiltro.reset();

    Object.keys(this.formFiltro.controls).forEach(key => {
      this.formFiltro.controls[key].setValue("");
    });

    this.filtrado = false;
  }

  /* Ordenar */

  ordernarControleClientes(coluna: string) {
    if (this.controleClientes.length > 1) {
      this.ordenarTextoControleClientes = "Aguarde! Ordenando colunas...";

      setTimeout(() => {
        this.controleClientesOriginal.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          if (this.ordenarColunaControleClientes == coluna) {
            return compare(a[coluna], b[coluna]) * -1; /* Decrescente */
          } else {
            return compare(a[coluna], b[coluna]);
          }
        });

        this.controleClientes = JSON.parse(JSON.stringify(this.controleClientesOriginal));

        if (this.ordenarColunaControleClientes == coluna) {
          this.ordenarColunaControleClientes = "";
        } else {
          this.ordenarColunaControleClientes = coluna;
        }

        this.ordenarTextoControleClientes = "";
      }, 200);
    }
  }

  ordenarControleClientesVendedor(coluna: string) {
    if (this.controleClientesVendedor.length > 1) {
      this.ordenarTextoControleClientesVendedor = "Aguarde! Ordenando colunas...";

      setTimeout(() => {
        this.controleClientesVendedorOriginal.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          if (this.ordenarColunaControleClientesVendedor == coluna) {
            return compare(a[coluna], b[coluna]) * -1; /* Decrescente */
          } else {
            return compare(a[coluna], b[coluna]);
          }
        });

        this.controleClientesVendedor = JSON.parse(JSON.stringify(this.controleClientesVendedorOriginal));

        if (this.ordenarColunaControleClientesVendedor == coluna) {
          this.ordenarColunaControleClientesVendedor = "";
        } else {
          this.ordenarColunaControleClientesVendedor = coluna;
        }

        this.ordenarTextoControleClientesVendedor = "";
      }, 200);
    }
  }
}
