import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ContaCorrenteCategoria } from '../../models/contas-correntes/conta-corrente-categoria';
import { ContaCorrenteExtrato } from '../../models/contas-correntes/conta-corrente-extrato';
import { ContaCorrenteSaldo } from '../../models/contas-correntes/conta-corrente-saldo';

@Injectable({
  providedIn: 'root'
})

export class ContaCorrenteService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Categoria */

  public getCategoriasByGuidClienteNapis(): Observable<ContaCorrenteCategoria[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<ContaCorrenteCategoria[]>(this.baseUrl + "api/ContaCorrente/GetCategoriasByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Extrato */

  public add(contaCorrenteExtrato: ContaCorrenteExtrato): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "contaCorrenteExtrato": contaCorrenteExtrato
    };

    return this.http.post<number>(this.baseUrl + "api/ContaCorrente/Add", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  public deleteContaCorrenteById(id: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<boolean>(this.baseUrl + "api/ContaCorrente/DeleteContaCorrenteById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getExtratoByVendedor(seller_id: number): Observable<ContaCorrenteExtrato[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id
    };

    return this.http.post<ContaCorrenteExtrato[]>(this.baseUrl + "api/ContaCorrente/GetExtratoByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Saldo */

  public getSaldoByUsuario(): Observable<ContaCorrenteSaldo[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<ContaCorrenteSaldo[]>(this.baseUrl + "api/ContaCorrente/GetSaldoByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getSaldoByVendedor(seller_id: number): Observable<ContaCorrenteSaldo> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": seller_id
    };

    return this.http.post<ContaCorrenteSaldo>(this.baseUrl + "api/ContaCorrente/GetSaldoByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }
}
