import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ClienteService } from '../../core/services/clientes/cliente.service';
import { ConversaoService } from '../../core/tools/conversao.service';
import { ExcelService } from '../../core/tools/excel.service';

/* Models */

import { Cliente } from '../../core/models/clientes/cliente';
import { ImportacoesErro } from '../../core/models/importacoes/importacoes-erro';

@Component({
  selector: 'app-importacoes-clientes',
  templateUrl: './importacoes-clientes.component.html',
  styleUrls: ['./importacoes-clientes.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ImportacoesClientesComponent implements OnInit {
  /* Arquivo */

  arquivoProcessado: boolean = false;

  /* Dados */

    /* Clientes */

    clientes: Cliente[] = [];

  /* Importações */

  importacoesErros: ImportacoesErro[] = [];

  /* Storage */

  guidclientenapis: string = localStorage.getItem("guidclientenapis");

  @ViewChild("arquivo", { static: false }) arquivo: ElementRef;

  constructor(
    private toastr: ToastrService,
    private clienteService: ClienteService,
    private conversaoService: ConversaoService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
  }

  reiniciarDados(com_arquivo: boolean) {
    /* Arquivo */

    this.arquivoProcessado = false;

    /* Dados */

      /* Clientes */

      this.clientes = [];

    /* Importações */

    this.importacoesErros = [];

    if (com_arquivo) {
      this.arquivo.nativeElement.value = "";
    }
  }

  /* Importar */

  importar() {
    if (this.arquivoProcessado && this.clientes.length > 0) {
      let adicionarClientes: boolean = false;

      this.clienteService.addMultiplos(this.clientes).subscribe(
        adicionarCliente => adicionarClientes = adicionarCliente,
        error => { console.log("Erro: " + error) },
        () => {
          if (adicionarClientes) {
            this.toastr.success("", "Arquivo importado com sucesso!");

            this.reiniciarDados(true);
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");
          }
        }
      );
    }
  }

  /* Processar */

  processar() {
    this.reiniciarDados(false);

    if (this.arquivo.nativeElement.files.length == 0) {
      this.toastr.error("", "Nenhum arquivo selecionado!");

      return;
    } else if (this.arquivo.nativeElement.files[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.toastr.error("", "Arquivo inválido!");

      return;
    }

    const arquivoParaBinario = this.conversaoService.arquivoParaBinario(this.arquivo.nativeElement.files[0]);

    arquivoParaBinario.then((retorno) => {
      if (retorno != null) {
        let dados = this.excelService.importExcelBinaryToJson(retorno);

        if (dados.length > 0) {
          /* Validação */

          dados.forEach((item, i) => {
            /* Razão Social */

            let razaoSocial: string = item["razao_social"] == undefined ? "" : String(item["razao_social"]);

            if (razaoSocial.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "razao_social", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }

            /* CNPJ/CPF */

            let cnpj_cpf: string = item["cnpj_cpf"] == undefined ? "" : String(item["cnpj_cpf"]);

            if (cnpj_cpf.trim() != "") {
              cnpj_cpf = cnpj_cpf.trim().split(".").join("").split("-").join("").split("/").join("");

              if (cnpj_cpf.length != 11 && cnpj_cpf.length != 14) {
                let importacaoErro = new ImportacoesErro(i + 2, "cnpj_cpf", "Valor inválido", "");

                this.importacoesErros.push(importacaoErro);
              }
            }

            /* Código (Vendedor) */

            let codigo_vendedor: string = item["codigo_vendedor"] == undefined ? "" : String(item["codigo_vendedor"]);

            if (codigo_vendedor.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "codigo_vendedor", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }

            /* Logradouro */

            let logradouro: string = item["logradouro"] == undefined ? "" : String(item["logradouro"]);

            if (logradouro.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "logradouro", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }

            /* Endereço */

            let endereco: string = item["endereco"] == undefined ? "" : String(item["endereco"]);

            if (endereco.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "endereco", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }

            /* Número */

            let numero: string = item["numero"] == undefined ? "" : String(item["numero"]);

            if (numero.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "numero", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }

            /* Bairro */

            let bairro: string = item["bairro"] == undefined ? "" : String(item["bairro"]);

            if (bairro.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "bairro", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }

            /* Cidade */

            let cidade: string = item["cidade"] == undefined ? "" : String(item["cidade"]);

            if (cidade.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "cidade", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }

            /* Estado (Sigla) */

            let estadoSigla: string = item["estado_sigla"] == undefined ? "" : String(item["estado_sigla"]);

            if (estadoSigla.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "estado_sigla", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }

            /* CEP */

            let cep: string = item["cep"] == undefined ? "" : String(item["cep"]);

            if (cep.trim() == "") {
              let importacaoErro = new ImportacoesErro(i + 2, "cep", "Campo em branco", "");

              this.importacoesErros.push(importacaoErro);
            }
          });

          if (this.importacoesErros.length == 0) {
            /* Importação */

            dados.forEach((item) => {
              let cliente = new Cliente();

              /* Informações */

              cliente.name = item["razao_social"] == undefined ? null : String(item["razao_social"]).substring(0, 100).trim().toUpperCase();
              cliente.fantasia = item["fantasia"] == undefined ? null : String(item["fantasia"]).substring(0, 100).trim().toUpperCase();

              let cnpj_cpf: string = item["cnpj_cpf"] == undefined ? null : String(item["cnpj_cpf"]).substring(0, 30).trim();

              if (cnpj_cpf != null && cnpj_cpf != "") {
                cnpj_cpf = cnpj_cpf.split(".").join("").split("-").join("").split("/").join("");

                if (cnpj_cpf.length == 11) {
                  /* CPF */

                  cnpj_cpf = cnpj_cpf.substr(0, 3) + "." + cnpj_cpf.substr(3, 3) + "." + cnpj_cpf.substr(6, 3) + "-" + cnpj_cpf.substr(9, 2);
                } else if (cnpj_cpf.length == 14) {
                  /* CNPJ */

                  cnpj_cpf = cnpj_cpf.substr(0, 2) + "." + cnpj_cpf.substr(2, 3) + "." + cnpj_cpf.substr(5, 3) + "/" + cnpj_cpf.substr(8, 4) + "-" + cnpj_cpf.substr(12, 2);
                }
              }

              cliente.client_document = cnpj_cpf;

              cliente.ie = item["ie"] == undefined ? null : String(item["ie"]).substring(0, 20).trim();
              cliente.segment = item["segmento"] == undefined ? null : String(item["segmento"]).substring(0, 100).trim();
              cliente.seller_id = item["codigo_vendedor"] == undefined ? null : parseInt(String(item["codigo_vendedor"]));
              cliente.is_active = true;
              cliente.codigo_no_erp_do_cliente = item["codigo"] == undefined ? null : String(item["codigo"]).substring(0, 50).trim();

              /* Endereço */

              cliente.tipo_logradouro = item["logradouro"] == undefined ? null : String(item["logradouro"]).substring(0, 100).trim();
              cliente.endereco = item["endereco"] == undefined ? null : String(item["endereco"]).substring(0, 150).trim();
              cliente.end_numero = item["numero"] == undefined ? null : String(item["numero"]).substring(0, 10).trim();
              cliente.end_complemento = item["complemento"] == undefined ? null : String(item["complemento"]).substring(0, 40).trim();
              cliente.bairro = item["bairro"] == undefined ? null : String(item["bairro"]).substring(0, 40).trim();
              cliente.codigo_ibge = item["codigo_ibge"] == undefined ? null : parseInt(String(item["codigo_ibge"]));
              cliente.estado = item["estado_sigla"] == undefined ? null : String(item["estado_sigla"]).substring(0, 2).trim();
              cliente.cidade = item["cidade"] == undefined ? null : String(item["cidade"]).substring(0, 40).trim();

              let cep: string = item["cep"] == undefined ? null : String(item["cep"]).substring(0, 10).trim();

              if (cep != null && cep != "") {
                cep = cep.split("-").join("");
              }

              cliente.cep = cep;

              /* Contato */

              cliente.responsible_name = item["responsavel"] == undefined ? null : String(item["responsavel"]).substring(0, 100).trim();

              let telefone_1: string = item["telefone_1"] == undefined ? null : String(item["telefone_1"]).substring(0, 20).trim();

              if (telefone_1 != null && telefone_1 != "") {
                telefone_1 = telefone_1.split("(").join("").split(")").join("").split("-").join("").split(" ").join("");
              }

              cliente.phone = telefone_1;

              let telefone_2: string = item["telefone_2"] == undefined ? null : String(item["telefone_2"]).substring(0, 20).trim();

              if (telefone_2 != null && telefone_2 != "") {
                telefone_2 = telefone_2.split("(").join("").split(")").join("").split("-").join("").split(" ").join("");
              }

              cliente.fone_02 = telefone_2;

              cliente.fone_whatss_ddd = item["whatsapp_ddd"] == undefined ? null : String(item["whatsapp_ddd"]).substring(0, 2).trim();

              let whatsapp_telefone: string = item["whatsapp_telefone"] == undefined ? null : String(item["whatsapp_telefone"]).substring(0, 20).trim();

              if (whatsapp_telefone != null && whatsapp_telefone != "") {
                whatsapp_telefone = whatsapp_telefone.split("(").join("").split(")").join("").split("-").join("").split(" ").join("");
              }

              cliente.fone_whatss = whatsapp_telefone;

              cliente.email = item["email_1"] == undefined ? null : String(item["email_1"]).substring(0, 50).trim();
              cliente.email_02 = item["email_2"] == undefined ? null : String(item["email_2"]).substring(0, 50).trim();

              cliente.guidclientenapis = this.guidclientenapis;

              this.clientes.push(cliente);
            });

            this.toastr.success("", "Arquivo processado com sucesso!");
          } else {
            this.toastr.error("", "Existe(m) linha(s) com erro(s)!");
          }

          /* Arquivo */

          this.arquivoProcessado = true;
        } else {
          this.toastr.error("", "Arquivo em branco!");
        }
      } else {
        this.reiniciarDados(true);

        this.toastr.warning("", "Selecione o arquivo novamente...");
      }
    });
  }
}
