import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { CentralAtendimentoService } from '../../core/services/central-atendimento/central-atendimento.service';
import { CidadeService } from '../../core/services/cidades/cidade.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { ConfiguracaoService } from '../../core/services/configuracoes/configuracao.service';
import { ConversaoService } from '../../core/tools/conversao.service';
import { EstadoService } from '../../core/services/estados/estado.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { CallCenterConfiguracao } from '../../core/models/call-center/call-center-configuracao';
import { Cidade } from '../../core/models/cidades/cidade';
import { Cliente } from '../../core/models/clientes/cliente';
import { ClienteArquivo } from '../../core/models/clientes/cliente-arquivo';
import { Configuracao } from '../../core/models/configuracoes/configuracao';
import { Estado } from '../../core/models/estados/estado';
import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-clientes-cadastro',
  templateUrl: './clientes-cadastro.component.html',
  styleUrls: ['./clientes-cadastro.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ClientesCadastroComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Itens (Menu) */

  itemSelecionado: number = 0;
  itemMaximoPermitido: number = 0;

  /* Formulário (Informações) */

  formClienteInformacoes: FormGroup;

  enviadoClienteInformacoes: boolean = false;

  /* Formulário (Endereço) */

  formClienteEndereco: FormGroup;

  enviadoClienteEndereco: boolean = false;

  validarClienteEndereco: boolean = false;

  /* Formulário (Contato) */

  formClienteContato: FormGroup;

  enviadoClienteContato: boolean = false;

  validarClienteContato: boolean = false;

  /* Formulário (Observações) */

  formClienteObservacoes: FormGroup;

  enviadoClienteObservacoes: boolean = false;

  /* Formulário (Dados Bancários) */

  formClienteDadosBancarios: FormGroup;

  enviadoClienteDadosBancarios: boolean = false;

  /* Formulário (Dados Comerciais) */

  formClienteDadosComerciais: FormGroup;

  enviadoClienteDadosComerciais: boolean = false;

  /* Formulário (Arquivos) */

  formClienteArquivos: FormGroup;

  enviadoClienteArquivos: boolean = false;

  /* Dados */

    /* Call Center (Configurações) */

    callCenterConfiguracao: CallCenterConfiguracao = null;

    /* Cliente */

    cliente: Cliente = null;

    clienteAtivo: boolean = true;

    /* Cliente Arquivo */

    clienteArquivos: ClienteArquivo[] = [];

    /* Cidade */

    cidades: Cidade[] = [];

    cidadeSelecionada: Cidade = null;

    /* Estado */

    estados: Estado[] = [];

    estadoSelecionado: Estado = null;

    /* Vendedores */

    vendedores: Vendedor[] = [];

    vendedorSelecionado: Vendedor = null;

  /* Storage */

  is_master: boolean = (localStorage.getItem("is_master") == "true");
  is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");
  guidclientenapis: string = localStorage.getItem("guidclientenapis");
  usa_whatsapp: boolean = (localStorage.getItem("usa_whatsapp") == "true");
  token_innersac: string = localStorage.getItem("token_innersac");
  link_innersac: string = localStorage.getItem("link_innersac");

  @ViewChild("arquivo", { static: false }) arquivo: ElementRef;

  /* Modal */

  @ViewChild("arquivos", { static: false }) arquivos: ElementRef;
  @ViewChild("email", { static: false }) email: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private centralAtendimentoService: CentralAtendimentoService,
    private clienteService: ClienteService,
    private configuracaoService: ConfiguracaoService,
    private conversaoService: ConversaoService,
    private cidadeService: CidadeService,
    private estadoService: EstadoService,
    private vendedorService: VendedorService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    this.formClienteInformacoes = this.formBuilder.group({
      razao_social: ["", Validators.required],
      fantasia: [""],
      cnpj_cpf: [""],
      ie: [""],
      segmento: [""],
      seller_id: [""],
      dt_cadastro: [{ value: "", disabled: true }],
      status_financeiro: [""],
      objetivo: [""],
      transportadora: [""],
      ativo: [""]
    });

    if (!this.is_master || !this.is_diretor) {
      this.formClienteInformacoes.controls["objetivo"].disable();
    }

    this.formClienteEndereco = this.formBuilder.group({
      logradouro: [""],
      endereco: [""],
      numero: [""],
      complemento: [""],
      bairro: [""],
      estado: [""],
      cidade: [""],
      cep: [""],
      entrega_endereco: [""],
      entrega_numero: [""],
      entrega_complemento: [""],
      entrega_bairro: [""],
      entrega_estado: [""],
      entrega_cidade: [""]
    });

    if (this.validarClienteEndereco) {
      this.formClienteEndereco.controls["logradouro"].setValidators(Validators.required);
      this.formClienteEndereco.controls["endereco"].setValidators(Validators.required);
      this.formClienteEndereco.controls["numero"].setValidators(Validators.required);
      this.formClienteEndereco.controls["bairro"].setValidators(Validators.required);
      this.formClienteEndereco.controls["cep"].setValidators(Validators.required);
    }

    this.formClienteContato = this.formBuilder.group({
      responsavel: [""],
      contato: [""],
      telefone_1: [""],
      telefone_2: [""],
      telefone_3: [""],
      ddd_whatsapp: [""],
      telefone_whatsapp: [""],
      email_1: [""],
      email_2: [""]
    });

    if (this.validarClienteContato) {
      this.formClienteContato.controls["responsavel"].setValidators(Validators.required);
      this.formClienteContato.controls["telefone_1"].setValidators(Validators.required);
      this.formClienteContato.controls["email_1"].setValidators(Validators.required);
    }

    this.formClienteObservacoes = this.formBuilder.group({
      observacoes: [""]
    });

    this.formClienteDadosBancarios = this.formBuilder.group({
      nome: [""],
      agencia: [""],
      conta: [""],
      contato: [""],
      telefone: [""]
    });

    this.formClienteDadosComerciais = this.formBuilder.group({
      referencia_1: [""],
      referencia_telefone_1: [""],
      referencia_contato_1: [""],
      referencia_2: [""],
      referencia_telefone_2: [""],
      referencia_contato_2: [""],
      referencia_3: [""],
      referencia_telefone_3: [""],
      referencia_contato_3: [""]
    });

    this.formClienteArquivos = this.formBuilder.group({
      descricao: ["", Validators.required],
      arquivo: [null, Validators.required]
    });

    let arquivo: boolean = false;

    this.route.queryParams.subscribe(parametros => { arquivo = parametros["arquivo"] });

    if (arquivo) {
      this.itemSelecionado = 6;
      this.itemMaximoPermitido = 6;
    }

    this.carregarDados();
  }

  get fin() {
    return this.formClienteInformacoes.controls;
  }

  get fen() {
    return this.formClienteEndereco.controls;
  }

  get fco() {
    return this.formClienteContato.controls;
  }

  get fob() {
    return this.formClienteObservacoes.controls;
  }

  get fdb() {
    return this.formClienteDadosBancarios.controls;
  }

  get fdc() {
    return this.formClienteDadosComerciais.controls;
  }

  get fca() {
    return this.formClienteArquivos.controls;
  }

  /* Arquivos */

  abrirArquivo(nm_arquivo: string) {
    window.open(nm_arquivo, "_blank");
  }

  adicionarArquivo() {
    this.enviadoClienteArquivos = true;

    if (this.formClienteArquivos.controls["arquivo"].value == null) {
      this.formClienteArquivos.controls["arquivo"].setErrors({ "required": true });

      return;
    } else {
      const arquivo = this.arquivo.nativeElement.files[0];

      if (arquivo) {
        let tamanho: number = (arquivo.size / (1024 * 1024));

        if (tamanho > 2) {
          this.formClienteArquivos.controls["arquivo"].setErrors({ "invalid": true });

          this.toastr.error("", "Tamanho de arquivo inválido!");

          return;
        } else {
          let nomeArquivo = this.formClienteArquivos.controls["arquivo"].value;

          if (nomeArquivo.indexOf(".png") == -1 && nomeArquivo.indexOf(".jpg") == -1 && nomeArquivo.indexOf(".jpeg") == -1 && nomeArquivo.indexOf(".pdf") == -1) {
            this.formClienteArquivos.controls["arquivo"].setErrors({ "invalid": true });

            this.removerArquivo(false);

            this.toastr.error("", "Tipo de arquivo inválido!");

            return;
          }
        }
      }

      if (this.formClienteArquivos.valid) {
        let clienteArquivo = new ClienteArquivo();

        clienteArquivo.client_id_app = this.cliente.client_id_app;
        clienteArquivo.descricao = String(this.formClienteArquivos.controls["descricao"].value).toUpperCase();

        let clienteAdicionarArquivo: boolean = false;

        this.clienteService.addClienteArquivo(arquivo, clienteArquivo).subscribe(
          clienteAdicionarArquivos => clienteAdicionarArquivo = clienteAdicionarArquivos,
          error => { console.log("Erro: " + error) },
          () => {
            if (clienteAdicionarArquivo) {
              this.carregarArquivos(this.cliente.client_id_app);

              this.removerArquivo(true);

              this.toastr.success("", "Arquivo adicionado com sucesso!");
            }
          }
        );
      }
    }
  }

  deletarArquivo(id: string, nm_arquivo: string) {
    if (this.cliente != null) {
      let deletarClienteArquivo: boolean = false;

      this.clienteService.deleteClienteArquivo(id, nm_arquivo).subscribe(
        deletarClienteArquivos => deletarClienteArquivo = deletarClienteArquivos,
        error => { console.log("Erro: " + error) },
        () => {
          if (deletarClienteArquivo) {
            this.carregarArquivos(this.cliente.client_id_app);

            this.toastr.success("", "Arquivo excluído com sucesso!");
          }
        }
      );
    }
  }

  removerArquivo(com_descricao: boolean) {
    this.enviadoClienteArquivos = false;

    if (com_descricao) {
      this.formClienteArquivos.controls["descricao"].setValue("");
    }

    this.formClienteArquivos.controls["arquivo"].setValue(null);

    if (this.arquivo != undefined) {
      this.arquivo.nativeElement.value = "";
    }
  }

  /* Central de Atendimento */

  iniciarChat() {
    if (this.formClienteContato.controls["ddd_whatsapp"].value.trim() == "") {
      this.toastr.error("", "WhatsApp (DDD) em branco!");
    } else if (this.formClienteContato.controls["telefone_whatsapp"].value.trim() == "") {
      this.toastr.error("", "WhatsApp (Telefone) em branco!");
    } else {
      if (this.token_innersac != "" && this.link_innersac != "") {
        this.router.navigate(["/whatsapp-innersac/" + this.formClienteContato.controls["ddd_whatsapp"].value.trim() + this.formClienteContato.controls["telefone_whatsapp"].value.trim()]);
      }

      /*
      let numero: string = this.conversaoService.telefoneParaTelefoneWhatsApp(this.formClienteContato.controls["ddd_whatsapp"].value.trim() + this.formClienteContato.controls["telefone_whatsapp"].value.trim());

      this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "iniciar_chat", "", "", 0, false, true, false, false, false, this.cliente.name, "", numero, null, "", this.cliente.client_id_app));
      */

    }
  }

  /* Dados */

  addUpdate(retornar: boolean) {
    if (this.cliente != null) {
      /* Alterando */

      this.prepararEnviarDados();

      let alterarCliente: boolean = false;

      this.clienteService.update(this.cliente).subscribe(
        alterarClientes => alterarCliente = alterarClientes,
        error => { console.log("Erro: " + error) },
        () => {
          if (alterarCliente) {
            this.toastr.success("", "Cliente alterado com sucesso!");

            this.router.navigateByUrl("/not-found", { skipLocationChange: true }).then(() => {
              this.router.navigate(["/clientes-cadastro/" + this.cliente.client_id_app], {
                queryParams: {
                  arquivo: 1
                }
              });
            });
          }
        }
      );
    } else {
      /* Adicionando */

      this.prepararEnviarDados();

      let adicionarCliente: number = 0;

      this.clienteService.add(this.cliente).subscribe(
        adicionarClientes => adicionarCliente = adicionarClientes,
        error => { console.log("Erro: " + error) },
        () => {
          if (adicionarCliente > 0) {
            this.toastr.success("", "Cliente adicionado com sucesso!");

            if (retornar) {
              this.router.navigate(["/clientes-cadastro/" + adicionarCliente], {
                queryParams: {
                  arquivo: 1
                }
              });
            } else {
              this.cliente.client_id_app = adicionarCliente;

              this.modalEmail(this.email);

              this.router.navigate(["/clientes/"]);
            }
          } else {
            this.router.navigate(["/clientes/"]);

            this.toastr.error("", "Houve um erro, contate o suporte...");
          }
        }
      );
    }
  }

  carregarArquivos(client_id_app: number) {
    let retorno = new Promise<void>((resolver) => {
      this.clienteService.getClienteArquivoByCliente(client_id_app).subscribe(
        clienteArquivos => this.clienteArquivos = clienteArquivos,
        error => { console.log("Erro: " + error) },
        () => {
          return resolver();
        }
      );
    });

    return retorno;
  }

  carregarDados() {
    this.carregando = true;

    /* Call Center (Configurações) */

    this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
      callCenterConfiguracao => this.callCenterConfiguracao = callCenterConfiguracao,
      error => { console.log("Erro: " + error) },
      () => {
        /* Vendedores */

        this.vendedorService.getByUsuario().subscribe(
          vendedores => this.vendedores = vendedores,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.vendedores.length > 0) {
              this.vendedorSelecionado = this.vendedores[0];
            }

            /* Estados */

            this.estadoService.get().subscribe(
              estados => this.estados = estados,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.estados.length > 0) {
                  this.estadoSelecionado = this.estados[0];
                }

                if (this.estadoSelecionado != null) {
                  /* Cidades */

                  this.cidadeService.getCidadeByEstado(this.estadoSelecionado.sigla_uf).subscribe(
                    cidades => this.cidades = cidades,
                    error => { console.log("Erro: " + error) },
                    () => {
                      if (this.cidades.length > 0) {
                        this.cidadeSelecionada = this.cidades[0];
                      }

                      this.verificarAdicionandoAlterando();
                    }
                  )
                }
              }
            )
          }
        );
      }
    );
  }

  prepararEnviarDados() {
    let cliente = new Cliente();

    /* Informações */

    cliente.name = String(this.formClienteInformacoes.controls["razao_social"].value).toUpperCase();
    cliente.fantasia = String(this.formClienteInformacoes.controls["fantasia"].value).toUpperCase();
    cliente.client_document = this.formClienteInformacoes.controls["cnpj_cpf"].value;
    cliente.ie = this.formClienteInformacoes.controls["ie"].value;
    cliente.segment = this.formClienteInformacoes.controls["segmento"].value;
    cliente.seller_id = this.vendedorSelecionado.id;
    cliente.is_active = this.clienteAtivo;
    cliente.status_financeiro = this.formClienteInformacoes.controls["status_financeiro"].value;
    cliente.objetivo_de_venda = this.formClienteInformacoes.controls["objetivo"].value;
    cliente.cd_transportadora = this.formClienteInformacoes.controls["transportadora"].value;

    /* Endereço */

    cliente.tipo_logradouro = this.formClienteEndereco.controls["logradouro"].value;
    cliente.endereco = this.formClienteEndereco.controls["endereco"].value;
    cliente.end_numero = this.formClienteEndereco.controls["numero"].value;
    cliente.end_complemento = this.formClienteEndereco.controls["complemento"].value;
    cliente.bairro = this.formClienteEndereco.controls["bairro"].value;
    cliente.codigo_ibge = this.cidadeSelecionada.cod_ibge;
    cliente.estado = this.estadoSelecionado.sigla_uf;
    cliente.cidade = this.cidadeSelecionada.nome_cidade;

    let cep: string = this.formClienteEndereco.controls["cep"].value;

    if (cep != null && cep.trim() != "") {
      cep = cep.split("-").join("");
    }

    cliente.cep = cep;

    cliente.entrega_endereco = this.formClienteEndereco.controls["entrega_endereco"].value;
    cliente.entrega_numero = this.formClienteEndereco.controls["entrega_numero"].value;
    cliente.entrega_complemento = this.formClienteEndereco.controls["entrega_complemento"].value;
    cliente.entrega_bairro = this.formClienteEndereco.controls["entrega_bairro"].value;
    cliente.entrega_estado = this.formClienteEndereco.controls["entrega_estado"].value;
    cliente.entrega_cidade = this.formClienteEndereco.controls["entrega_cidade"].value;

    /* Contato */

    cliente.responsible_name = this.formClienteContato.controls["responsavel"].value;
    cliente.contato_callcenter = this.formClienteContato.controls["contato"].value;

    let telefone_1: string = this.formClienteContato.controls["telefone_1"].value;

    if (telefone_1 != null && telefone_1.trim() != "") {
      telefone_1 = telefone_1.split("(").join("").split(")").join("").split("-").join("").split(" ").join("");
    }

    cliente.phone = telefone_1;

    let telefone_2: string = this.formClienteContato.controls["telefone_2"].value;

    if (telefone_2 != null && telefone_2.trim() != "") {
      telefone_2 = telefone_2.split("(").join("").split(")").join("").split("-").join("").split(" ").join("");
    }

    cliente.fone_02 = telefone_2;

    let telefone_3: string = this.formClienteContato.controls["telefone_3"].value;

    if (telefone_3 != null && telefone_3.trim() != "") {
      telefone_3 = telefone_3.split("(").join("").split(")").join("").split("-").join("").split(" ").join("");
    }

    cliente.fone_03 = telefone_3;

    cliente.fone_whatss_ddd = this.formClienteContato.controls["ddd_whatsapp"].value;

    let telefone_whatsapp: string = this.formClienteContato.controls["telefone_whatsapp"].value;

    if (telefone_whatsapp != null && telefone_whatsapp.trim() != "") {
      telefone_whatsapp = telefone_whatsapp.split("(").join("").split(")").join("").split("-").join("").split(" ").join("");
    }

    cliente.fone_whatss = telefone_whatsapp;

    cliente.email = this.formClienteContato.controls["email_1"].value;
    cliente.email_02 = this.formClienteContato.controls["email_2"].value;

    /* Observações */

    cliente.note = this.formClienteObservacoes.controls["observacoes"].value;

    /* Dados Bancários */

    cliente.banco_nome = this.formClienteDadosBancarios.controls["nome"].value;
    cliente.banco_agencia = this.formClienteDadosBancarios.controls["agencia"].value;
    cliente.banco_conta = this.formClienteDadosBancarios.controls["conta"].value;
    cliente.banco_nome_contato = this.formClienteDadosBancarios.controls["contato"].value;
    cliente.banco_telefone = this.formClienteDadosBancarios.controls["telefone"].value;

    /* Dados Comerciais */

    cliente.fornecedor_referencia_01 = this.formClienteDadosComerciais.controls["referencia_1"].value;
    cliente.fornecedor_telefone_01 = this.formClienteDadosComerciais.controls["referencia_telefone_1"].value;
    cliente.fornecedor_contato_01 = this.formClienteDadosComerciais.controls["referencia_contato_1"].value;
    cliente.fornecedor_referencia_02 = this.formClienteDadosComerciais.controls["referencia_2"].value;
    cliente.fornecedor_telefone_02 = this.formClienteDadosComerciais.controls["referencia_telefone_2"].value;
    cliente.fornecedor_contato_02 = this.formClienteDadosComerciais.controls["referencia_contato_2"].value;
    cliente.fornecedor_referencia_03 = this.formClienteDadosComerciais.controls["referencia_3"].value;
    cliente.fornecedor_telefone_03 = this.formClienteDadosComerciais.controls["referencia_telefone_3"].value;
    cliente.fornecedor_contato_03 = this.formClienteDadosComerciais.controls["referencia_contato_3"].value;

    /* Outros */

    if (this.cliente != null) {
      cliente.client_id_app = this.cliente.client_id_app;
    }

    cliente.guidclientenapis = this.guidclientenapis;

    this.cliente = cliente;
  }

  prepararReceberDados(client_id_app: number) {
    let retorno = new Promise<void>((resolver) => {
      this.clienteService.getById(client_id_app).subscribe(
        cliente => this.cliente = cliente,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.cliente != null) {
            /* Informações */

            this.formClienteInformacoes.controls["razao_social"].setValue(this.cliente.name);

            if ((!this.is_master || !this.is_diretor) && this.cliente.name != null && this.cliente.name != "") {
              this.formClienteInformacoes.controls["razao_social"].disable();
            }

            this.formClienteInformacoes.controls["fantasia"].setValue(this.cliente.fantasia);

            if ((!this.is_master || !this.is_diretor) && this.cliente.fantasia != null && this.cliente.fantasia != "") {
              this.formClienteInformacoes.controls["fantasia"].disable();
            }

            this.formClienteInformacoes.controls["cnpj_cpf"].setValue(this.cliente.client_document);

            if ((!this.is_master || !this.is_diretor) && this.cliente.client_document != null && this.cliente.client_document != "") {
              this.formClienteInformacoes.controls["cnpj_cpf"].disable();
            }

            this.formClienteInformacoes.controls["ie"].setValue(this.cliente.ie);

            if ((!this.is_master || !this.is_diretor) && this.cliente.ie != null && this.cliente.ie != "") {
              this.formClienteInformacoes.controls["ie"].disable();
            }

            this.formClienteInformacoes.controls["segmento"].setValue(this.cliente.segment);

            this.vendedorSelecionado = this.vendedores.find(vendedores => vendedores.id == this.cliente.seller_id);

            this.formClienteInformacoes.controls["dt_cadastro"].setValue(this.datePipe.transform(this.cliente.created_at, "dd/MM/yyyy HH:mm:ss"));
            this.formClienteInformacoes.controls["dt_cadastro"].disable();

            this.formClienteInformacoes.controls["status_financeiro"].setValue(this.cliente.status_financeiro);

            this.formClienteInformacoes.controls["objetivo"].setValue(this.cliente.objetivo_de_venda);

            this.formClienteInformacoes.controls["transportadora"].setValue(this.cliente.cd_transportadora);

            this.clienteAtivo = this.cliente.is_active;

            /* Endereço */

            this.formClienteEndereco.controls["logradouro"].setValue(this.cliente.tipo_logradouro);

            if ((!this.is_master || !this.is_diretor) && this.cliente.tipo_logradouro != null && this.cliente.tipo_logradouro != "") {
              this.formClienteEndereco.controls["logradouro"].disable();
            }
            
            this.formClienteEndereco.controls["endereco"].setValue(this.cliente.endereco);

            if ((!this.is_master || !this.is_diretor) && this.cliente.endereco != null && this.cliente.endereco != "") {
              this.formClienteEndereco.controls["endereco"].disable();
            }
            
            this.formClienteEndereco.controls["numero"].setValue(this.cliente.end_numero);

            if ((!this.is_master || !this.is_diretor) && this.cliente.end_numero != null && this.cliente.end_numero != "") {
              this.formClienteEndereco.controls["numero"].disable();
            }

            this.formClienteEndereco.controls["complemento"].setValue(this.cliente.end_complemento);

            if ((!this.is_master || !this.is_diretor) && this.cliente.end_complemento != null && this.cliente.end_complemento != "") {
              this.formClienteEndereco.controls["complemento"].disable();
            }

            this.formClienteEndereco.controls["bairro"].setValue(this.cliente.bairro);

            if ((!this.is_master || !this.is_diretor) && this.cliente.bairro != null && this.cliente.bairro != "") {
              this.formClienteEndereco.controls["bairro"].disable();
            }

            const selecionarEstado = this.selecionarEstado(this.cliente.estado);

            if (this.cliente.codigo_ibge != null) {
              selecionarEstado.then(() => {
                this.selecionarCidade(this.cliente.codigo_ibge.toString());
              });
            }

            this.formClienteEndereco.controls["cep"].setValue(this.cliente.cep);

            if ((!this.is_master || !this.is_diretor) && this.cliente.cep != null && this.cliente.cep != "") {
              this.formClienteEndereco.controls["cep"].disable();
            }

            this.formClienteEndereco.controls["entrega_endereco"].setValue(this.cliente.entrega_endereco);

            if ((!this.is_master || !this.is_diretor) && this.cliente.entrega_endereco != null && this.cliente.entrega_endereco != "") {
              this.formClienteEndereco.controls["entrega_endereco"].disable();
            }
            
            this.formClienteEndereco.controls["entrega_numero"].setValue(this.cliente.entrega_numero);

            if ((!this.is_master || !this.is_diretor) && this.cliente.entrega_numero != null && this.cliente.entrega_numero != "") {
              this.formClienteEndereco.controls["entrega_numero"].disable();
            }

            this.formClienteEndereco.controls["entrega_complemento"].setValue(this.cliente.entrega_complemento);

            if ((!this.is_master || !this.is_diretor) && this.cliente.entrega_complemento != null && this.cliente.entrega_complemento != "") {
              this.formClienteEndereco.controls["entrega_complemento"].disable();
            }

            this.formClienteEndereco.controls["entrega_bairro"].setValue(this.cliente.entrega_bairro);

            if ((!this.is_master || !this.is_diretor) && this.cliente.entrega_bairro != null && this.cliente.entrega_bairro != "") {
              this.formClienteEndereco.controls["entrega_bairro"].disable();
            }

            this.formClienteEndereco.controls["entrega_estado"].setValue(this.cliente.entrega_estado);

            if ((!this.is_master || !this.is_diretor) && this.cliente.entrega_estado != null && this.cliente.entrega_estado != "") {
              this.formClienteEndereco.controls["entrega_estado"].disable();
            }
            
            this.formClienteEndereco.controls["entrega_cidade"].setValue(this.cliente.entrega_cidade);

            if ((!this.is_master || !this.is_diretor) && this.cliente.entrega_cidade != null && this.cliente.entrega_cidade != "") {
              this.formClienteEndereco.controls["entrega_cidade"].disable();
            }

            /* Contato */

            this.formClienteContato.controls["responsavel"].setValue(this.cliente.responsible_name);

            this.formClienteContato.controls["contato"].setValue(this.cliente.contato_callcenter);

            this.formClienteContato.controls["telefone_1"].setValue(this.cliente.phone);

            this.formClienteContato.controls["telefone_2"].setValue(this.cliente.fone_02);

            this.formClienteContato.controls["telefone_3"].setValue(this.cliente.fone_03);

            this.formClienteContato.controls["ddd_whatsapp"].setValue(this.cliente.fone_whatss_ddd);

            this.formClienteContato.controls["telefone_whatsapp"].setValue(this.cliente.fone_whatss);

            this.formClienteContato.controls["email_1"].setValue(this.cliente.email);

            this.formClienteContato.controls["email_2"].setValue(this.cliente.email_02);

            /* Observações */

            this.formClienteObservacoes.controls["observacoes"].setValue(this.cliente.note);

            /* Dados Bancários */

            this.formClienteDadosBancarios.controls["nome"].setValue(this.cliente.banco_nome);

            if (this.cliente.banco_nome != null && this.cliente.banco_nome != "") {
              this.formClienteDadosBancarios.controls["nome"].disable();
            }
            
            this.formClienteDadosBancarios.controls["agencia"].setValue(this.cliente.banco_agencia);

            if (this.cliente.banco_agencia != null && this.cliente.banco_agencia != "") {
              this.formClienteDadosBancarios.controls["agencia"].disable();
            }
            
            this.formClienteDadosBancarios.controls["conta"].setValue(this.cliente.banco_conta);

            if (this.cliente.banco_conta != null && this.cliente.banco_conta != "") {
              this.formClienteDadosBancarios.controls["conta"].disable();
            }
            
            this.formClienteDadosBancarios.controls["contato"].setValue(this.cliente.banco_nome_contato);

            if (this.cliente.banco_nome_contato != null && this.cliente.banco_nome_contato != "") {
              this.formClienteDadosBancarios.controls["contato"].disable();
            }
            
            this.formClienteDadosBancarios.controls["telefone"].setValue(this.cliente.banco_telefone);

            if (this.cliente.banco_telefone != null && this.cliente.banco_telefone != "") {
              this.formClienteDadosBancarios.controls["telefone"].disable();
            }

            /* Dados Comerciais */

            this.formClienteDadosComerciais.controls["referencia_1"].setValue(this.cliente.fornecedor_referencia_01);

            if (this.cliente.fornecedor_referencia_01 != null && this.cliente.fornecedor_referencia_01 != "") {
              this.formClienteDadosComerciais.controls["referencia_1"].disable();
            }
            
            this.formClienteDadosComerciais.controls["referencia_telefone_1"].setValue(this.cliente.fornecedor_telefone_01);

            if (this.cliente.fornecedor_telefone_01 != null && this.cliente.fornecedor_telefone_01 != "") {
              this.formClienteDadosComerciais.controls["referencia_telefone_1"].disable();
            }
            
            this.formClienteDadosComerciais.controls["referencia_contato_1"].setValue(this.cliente.fornecedor_contato_01);

            if (this.cliente.fornecedor_contato_01 != null && this.cliente.fornecedor_contato_01 != "") {
              this.formClienteDadosComerciais.controls["referencia_contato_1"].disable();
            }
            
            this.formClienteDadosComerciais.controls["referencia_2"].setValue(this.cliente.fornecedor_referencia_02);

            if (this.cliente.fornecedor_referencia_02 != null && this.cliente.fornecedor_referencia_02 != "") {
              this.formClienteDadosComerciais.controls["referencia_2"].disable();
            }
            
            this.formClienteDadosComerciais.controls["referencia_telefone_2"].setValue(this.cliente.fornecedor_telefone_02);

            if (this.cliente.fornecedor_telefone_02 != null && this.cliente.fornecedor_telefone_02 != "") {
              this.formClienteDadosComerciais.controls["referencia_telefone_2"].disable();
            }
            
            this.formClienteDadosComerciais.controls["referencia_contato_2"].setValue(this.cliente.fornecedor_contato_02);

            if (this.cliente.fornecedor_contato_02 != null && this.cliente.fornecedor_contato_02 != "") {
              this.formClienteDadosComerciais.controls["referencia_contato_2"].disable();
            }
            
            this.formClienteDadosComerciais.controls["referencia_3"].setValue(this.cliente.fornecedor_referencia_03);

            if (this.cliente.fornecedor_referencia_03 != null && this.cliente.fornecedor_referencia_03 != "") {
              this.formClienteDadosComerciais.controls["referencia_3"].disable();
            }
            
            this.formClienteDadosComerciais.controls["referencia_telefone_3"].setValue(this.cliente.fornecedor_telefone_03);

            if (this.cliente.fornecedor_telefone_03 != null && this.cliente.fornecedor_telefone_03 != "") {
              this.formClienteDadosComerciais.controls["referencia_telefone_3"].disable();
            }

            this.formClienteDadosComerciais.controls["referencia_contato_3"].setValue(this.cliente.fornecedor_contato_03);

            if (this.cliente.fornecedor_contato_03 != null && this.cliente.fornecedor_contato_03 != "") {
              this.formClienteDadosComerciais.controls["referencia_contato_3"].disable();
            }

            return resolver();
          } else {
            this.toastr.error("", "Cliente inválido!");

            this.router.navigate(["/clientes"]);
          }
        }
      );
    });

    return retorno;
  }

  salvar() {
    if (this.cliente != null) {
      this.addUpdate(true);
    } else {
      this.modalArquivos(this.arquivos);
    }
  }

  verificarAdicionandoAlterando() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    let id: string = null;

    this.route.params.subscribe(params => { id = params.id; });

    if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
      /* Alterando */

      const prepararReceberDados = this.prepararReceberDados(parseInt(id));

      prepararReceberDados.then(() => {
        const carregarArquivos = this.carregarArquivos(parseInt(id));

        carregarArquivos.then(() => {
          this.carregando = false;
        });
      });
    } else {
      /* Adicionando */

      this.carregando = false;
    }
  }

  /* E-mail */

  enviarEmail() {
    if (this.cliente != null) {
      /* Configurações */

      let configuracao: Configuracao = null;

      this.configuracaoService.getByGuidClienteNapis().subscribe(
        configuracoes => configuracao = configuracoes,
        error => { console.log("Erro: " + error) },
        () => {
          if (configuracao != null) {
            if (configuracao.email_cadastro_faturamento != null) {
              /* E-mail */

              let emailEnviado: boolean = false;

              this.clienteService.sendEmailCadastro(this.cliente.client_id_app, configuracao.email_cadastro_faturamento).subscribe(
                emailEnviados => emailEnviado = emailEnviados,
                error => { console.log("Erro: " + error) },
                () => {
                  if (emailEnviado) {
                    this.toastr.success("", "E-mail enviado com sucesso!");
                  } else {
                    this.toastr.warning("", "E-mail não enviado!");
                  }
                }
              );
            }
          }
        }
      );
    }
  }

  /* Itens (Menu) */

  alterarItemSelecionado(item: number) {
    this.validarItens(item);

    if (item > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item;

    if (this.itemSelecionado != 6) { /* Arquivos */
      this.removerArquivo(true);
    }
  }

  prosseguirItemSelecionado(item: number) {
    this.validarItens(item + 1);

    if ((item + 1) > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item + 1;
  }

  retornarItemSelecionado(item: number) {
    this.itemSelecionado = item - 1;
  }

  validarItens(item: number) {
    /* Validações */

    this.itemMaximoPermitido = 0;

    /* Informações */

    if (this.formClienteInformacoes.invalid) {
      this.enviadoClienteInformacoes = true;

      this.toastr.error("", "Informações inválidas!");

      this.itemSelecionado = 0;

      return;
    } else {
      if (this.cliente == null) {
        if (this.formClienteInformacoes.controls["cnpj_cpf"].value != null && this.formClienteInformacoes.controls["cnpj_cpf"].value != "") {
          let cnpj_cpf: string = this.formClienteInformacoes.controls["cnpj_cpf"].value.split(".").join("").split("-").join("").split("/").join("");

          if (cnpj_cpf.length == 11) {
            /* CPF */

            let cpf_formatado: string = cnpj_cpf.substr(0, 3) + "." + cnpj_cpf.substr(3, 3) + "." + cnpj_cpf.substr(6, 3) + "-" + cnpj_cpf.substr(9, 2);

            let cliente: Cliente = null;

            this.clienteService.getByClientDocument(cpf_formatado).subscribe(
              clientes => cliente = clientes,
              error => { console.log("Erro: " + error) },
              () => {
                if (cliente != null) {
                  this.enviadoClienteInformacoes = true;

                  this.toastr.error("", "CPF já adicionado!");

                  this.formClienteInformacoes.controls["cnpj_cpf"].setErrors({ "invalid": true });

                  this.itemSelecionado = 0;

                  return;
                } else {
                  this.formClienteInformacoes.controls["cnpj_cpf"].setValue(cpf_formatado);
                }
              }
            );
          } else if (cnpj_cpf.length == 14) {
            /* CNPJ */

            let cnpj_formatado: string = cnpj_cpf.substr(0, 2) + "." + cnpj_cpf.substr(2, 3) + "." + cnpj_cpf.substr(5, 3) + "/" + cnpj_cpf.substr(8, 4) + "-" + cnpj_cpf.substr(12, 2);

            let cliente: Cliente = null;

            this.clienteService.getByClientDocument(cnpj_formatado).subscribe(
              clientes => cliente = clientes,
              error => { console.log("Erro: " + error) },
              () => {
                if (cliente != null) {
                  this.enviadoClienteInformacoes = true;

                  this.toastr.error("", "CNPJ já adicionado!");

                  this.formClienteInformacoes.controls["cnpj_cpf"].setErrors({ "invalid": true });

                  this.itemSelecionado = 0;

                  return;
                } else {
                  let cnpjValido: boolean = false;

                  this.clienteService.validarCNPJ(cnpj_cpf).subscribe(
                    cnpjsValidos => cnpjValido = cnpjsValidos,
                    error => { console.log("Erro: " + error) },
                    () => {
                      if (!cnpjValido) {
                        this.enviadoClienteInformacoes = true;

                        this.toastr.error("", "CNPJ inválido!");

                        this.formClienteInformacoes.controls["cnpj_cpf"].setErrors({ "invalid": true });

                        this.itemSelecionado = 0;

                        return;
                      } else {
                        this.formClienteInformacoes.controls["cnpj_cpf"].setValue(cnpj_formatado);
                      }
                    }
                  );
                }
              }
            );
          } else if (cnpj_cpf.length > 0) {
            this.enviadoClienteInformacoes = true;

            this.toastr.error("", "CNPJ/CPF inválido!");

            this.formClienteInformacoes.controls["cnpj_cpf"].setErrors({ "invalid": true });

            this.itemSelecionado = 0;

            return;
          }
        }
      }

      this.enviadoClienteInformacoes = false;

      this.itemMaximoPermitido = 1;
    }

    /* Endereço */

    if (this.formClienteEndereco.invalid) {
      if (item > this.itemMaximoPermitido) {
        this.enviadoClienteEndereco = true;

        this.toastr.error("", "Endereço inválido!");

        this.itemSelecionado = 1;

        return;
      }
    } else {
      this.enviadoClienteEndereco = false;

      this.itemMaximoPermitido = 2;
    }

    /* Contato */

    if (this.formClienteContato.invalid) {
      if (item > this.itemMaximoPermitido) {
        this.enviadoClienteContato = true;

        this.toastr.error("", "Contato inválido!");

        this.itemSelecionado = 2;

        return;
      }
    } else {
      this.enviadoClienteContato = false;

      this.itemMaximoPermitido = 6;
    }
  }

  /* Modal */

  modalArquivos(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-arquivos",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Sim") {
        this.addUpdate(true);
      } else {
        this.addUpdate(false);
      }
    }, () => {
      this.addUpdate(false);
    });
  }

  modalEmail(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-email",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Sim") {
        this.enviarEmail();
      }
    }, () => {

    });
  }

  /* Números */

  somenteNumeros(element: HTMLInputElement) {
    element.value = element.value.replace(/[^0-9]/gi, "");
  }

  /* Cliente */

  selecionarAtivo(ativo: string) {
    this.clienteAtivo = ativo == "0" ? false : true;
  }

  /* Cidade */

  selecionarCidade(cod_ibge: string) {
    this.cidadeSelecionada = this.cidades.find(cidades => cidades.cod_ibge == parseInt(cod_ibge));

    if (this.cidadeSelecionada == null) {
      if (this.cidades.length > 0) {
        this.cidadeSelecionada = this.cidades[0];
      }
    }
  }

  /* Estado */

  selecionarEstado(sigla_uf: string) {
    let retorno = new Promise<void>((resolver) => {
      this.estadoSelecionado = this.estados.find(estados => estados.sigla_uf == sigla_uf);

      if (this.estadoSelecionado != null) {
        this.cidadeService.getCidadeByEstado(this.estadoSelecionado.sigla_uf).subscribe(
          cidades => this.cidades = cidades,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.cidades.length > 0) {
              this.cidadeSelecionada = this.cidades[0];
            }

            return resolver();
          }
        )
      } else {
        return resolver();
      }
    });

    return retorno;
  }

  /* Vendedor(a) */

  selecionarVendedor(id_vendedor: string) {
    this.vendedorSelecionado = this.vendedores.find(vendedores => vendedores.id == parseInt(id_vendedor));
  }
}
