import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ContaCorrenteService } from '../../core/services/contas-correntes/conta-corrente.service';

/* Models */

import { ContaCorrenteExtrato } from '../../core/models/contas-correntes/conta-corrente-extrato';
import { ContaCorrenteSaldo } from '../../core/models/contas-correntes/conta-corrente-saldo';
import { ContaCorrenteCategoria } from '../../core/models/contas-correntes/conta-corrente-categoria';

@Component({
  selector: 'app-contas-correntes',
  templateUrl: './contas-correntes.component.html',
  styleUrls: ['./contas-correntes.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ContasCorrentesComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Contas Correntes (Categoria) */

  contasCorrentesCategoria: ContaCorrenteCategoria[] = [];

  contasCorrentesCategoriaSelecionada: ContaCorrenteCategoria = null;

  /* Contas Correntes (Extrato) */

  contasCorrentesExtrato: ContaCorrenteExtrato[] = [];

  /* Contas Correntes (Saldo) */

  contasCorrentesSaldo: ContaCorrenteSaldo[] = [];

  contasCorrentesSaldoAgrupadas: ContaCorrenteSaldo[] = [];

  contaCorrenteSaldoSelecionado: ContaCorrenteSaldo = null;

  /* Depósito */

  depositoDeSelecionado: ContaCorrenteSaldo = null;

  depositoDeSelecionadoErro: boolean = false;

  depositoEnviado: boolean = false;

  depositoValorErro: boolean = false;

  formDeposito: FormGroup;

  /* Retirada */

  retiradaParaSelecionado: ContaCorrenteSaldo = null;

  retiradaParaSelecionadoErro: boolean = false;

  retiradaEnviada: boolean = false;

  retiradaValorErro: boolean = false;

  formRetirada: FormGroup;

  gestorExpandido: number = -1;

  /* Storage */

  is_master: boolean = (localStorage.getItem("is_master") == "true");
  is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private contaCorrenteService: ContaCorrenteService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    this.formDeposito = this.formBuilder.group({
      detalhes: [""],
      valor: ["", Validators.required]
    });

    this.formRetirada = this.formBuilder.group({
      detalhes: [""],
      valor: ["", Validators.required]
    });

    this.carregarDados();
  }

  get fd() {
    return this.formDeposito.controls;
  }

  get fr() {
    return this.formRetirada.controls;
  }

  /* Ações */

  expandir(i: number) {
    this.gestorExpandido = i == this.gestorExpandido ? -1 : i;
  }

  /* Categoria */

  selecionarCategoria(id_conta_corrente_categoria: string) {
    if (id_conta_corrente_categoria != "0") {
      this.contasCorrentesCategoriaSelecionada = this.contasCorrentesCategoria.find(categoria => categoria.id == parseInt(id_conta_corrente_categoria));
    } else {
      this.contasCorrentesCategoriaSelecionada = null;
    }
  }

  /* Dados */

  agruparDados() {
    if (this.contasCorrentesSaldo.length > 0) {
      let vendedoresGestores = this.contasCorrentesSaldo.filter(contaCorrenteSaldo => contaCorrenteSaldo.is_manager);

      vendedoresGestores.forEach((item) => {
        let vendedores = this.contasCorrentesSaldo.filter(contaCorrenteSaldo => !contaCorrenteSaldo.is_manager && contaCorrenteSaldo.manager_id == item.id);

        item.vendedores_vinculados = vendedores;
      });

      vendedoresGestores.forEach((item) => {
        item.saldo_distribuido = item.vendedores_vinculados.reduce((sum, current) => sum + current.saldo_final, 0);
      });

      this.contasCorrentesSaldoAgrupadas = vendedoresGestores;
    }
  }

  carregarDados() {
    this.carregando = true;

    this.contaCorrenteService.getCategoriasByGuidClienteNapis().subscribe(
      contaCorrenteCategoria => this.contasCorrentesCategoria = contaCorrenteCategoria,
      error => { console.log("Erro: " + error) },
      () => {
        this.contaCorrenteService.getSaldoByUsuario().subscribe(
          contaCorrenteSaldo => this.contasCorrentesSaldo = contaCorrenteSaldo,
          error => { console.log("Erro: " + error) },
          () => {
            this.agruparDados();

            this.carregando = false;
          }
        );
      }
    );
  }

  /* Modal */

  modalDeposito(content: any, inicial: boolean, conta_corrente_saldo: ContaCorrenteSaldo) {
    /* Contas Correntes (Categoria) */

    this.contasCorrentesCategoriaSelecionada = null;

    /* Contas Correntes (Saldo) */

    this.contaCorrenteSaldoSelecionado = conta_corrente_saldo;

    if (inicial) {
      this.depositoEnviado = false;
      this.depositoValorErro = false;

      this.formDeposito.reset();

      if (this.contasCorrentesSaldo.length > 0) {
        this.depositoDeSelecionado = this.contasCorrentesSaldo.find(contaCorrenteSaldo => contaCorrenteSaldo.id != this.contaCorrenteSaldoSelecionado.id);
      }
    }

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-deposito",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Salvar") {
        this.depositoEnviado = true;

        if (this.depositoDeSelecionado == null) {
          this.depositoDeSelecionadoErro = true;
        } else {
          this.depositoDeSelecionadoErro = false;
        }

        if (!this.depositoDeSelecionadoErro && !this.formDeposito.invalid) {
          if (!this.validarDepositoValorErro()) {
            this.modalDeposito(content, false, conta_corrente_saldo);
          } else {
            let detalhes = "";

            if (!this.contaCorrenteSaldoSelecionado.is_manager || ((this.is_master || this.is_diretor) && !this.contaCorrenteSaldoSelecionado.is_manager)) {
              /* Com Origem */

              detalhes = "DE: " + this.depositoDeSelecionado.fullname + " - PARA: " + this.contaCorrenteSaldoSelecionado.fullname;
            } else {
              /* Sem Origem */

              detalhes = "DE: MASTER - PARA: " + this.contaCorrenteSaldoSelecionado.fullname;
            }

            if (this.formDeposito.controls["detalhes"].value != null && this.formDeposito.controls["detalhes"].value.trim() != "") {
              detalhes = detalhes + " - " + this.formDeposito.controls["detalhes"].value;
            }

            /* Depósito */

            let contaCorrenteDeposito = new ContaCorrenteExtrato();

            contaCorrenteDeposito.seller_id = this.contaCorrenteSaldoSelecionado.id;
            contaCorrenteDeposito.valor_transacao = this.formDeposito.controls["valor"].value;
            contaCorrenteDeposito.detalhes_transacao = detalhes;
            contaCorrenteDeposito.deposito = true;

            /* Categoria */

            if (this.contasCorrentesCategoriaSelecionada != null) {
              contaCorrenteDeposito.id_conta_corrente_flex_categoria = this.contasCorrentesCategoriaSelecionada.id;
            } else {
              contaCorrenteDeposito.id_conta_corrente_flex_categoria = null;
            }

            let adicionarDeposito: number = 0;

            this.contaCorrenteService.add(contaCorrenteDeposito).subscribe(
              adicionarDepositos => adicionarDeposito = adicionarDepositos,
              error => { console.log("Erro: " + error) },
              () => {
                if (adicionarDeposito > 0) {
                  if (!this.contaCorrenteSaldoSelecionado.is_manager || ((this.is_master || this.is_diretor) && !this.contaCorrenteSaldoSelecionado.is_manager)) {
                    /* Com Origem */

                    /* Retirada */

                    let contaCorrenteRetirada = new ContaCorrenteExtrato();

                    contaCorrenteRetirada.seller_id = this.depositoDeSelecionado.id;
                    contaCorrenteRetirada.valor_transacao = this.formDeposito.controls["valor"].value;
                    contaCorrenteRetirada.detalhes_transacao = detalhes;
                    contaCorrenteRetirada.deposito = false;

                    let adicionarRetirada: number = 0;

                    this.contaCorrenteService.add(contaCorrenteRetirada).subscribe(
                      adicionarRetiradas => adicionarRetirada = adicionarRetiradas,
                      error => { console.log("Erro: " + error) },
                      () => {
                        if (adicionarRetirada > 0) {
                          this.toastr.success("", "Depósito adicionado com sucesso!");

                          this.carregarDados();
                        }
                      }
                    );
                  } else {
                    this.toastr.success("", "Depósito adicionado com sucesso!");

                    this.carregarDados();
                  }
                }
              }
            );
          }
        } else {
          this.modalDeposito(content, false, conta_corrente_saldo);
        }
      }
    }, () => {

    });
  }

  modalExtrato(content: any, conta_corrente_saldo: ContaCorrenteSaldo) {
    /* Contas Correntes (Saldo) */

    this.contaCorrenteSaldoSelecionado = conta_corrente_saldo;

    this.contaCorrenteService.getExtratoByVendedor(this.contaCorrenteSaldoSelecionado.id).subscribe(
      contaCorrenteExtrato => this.contasCorrentesExtrato = contaCorrenteExtrato,
      error => { console.log("Erro: " + error) },
      () => {
        this.modalService.open(content, {
          ariaLabelledBy: "titulo-extrato",
          size: "xl",
          backdrop: "static"
        });
      }
    );
  }

  modalRetirada(content: any, inicial: boolean, conta_corrente_saldo: ContaCorrenteSaldo) {
    /* Contas Correntes (Categoria) */

    this.contasCorrentesCategoriaSelecionada = null;

    /* Contas Correntes (Saldo) */

    this.contaCorrenteSaldoSelecionado = conta_corrente_saldo;

    if (inicial) {
      this.retiradaEnviada = false;
      this.retiradaValorErro = false;

      this.formRetirada.reset();

      if (this.contasCorrentesSaldo.length > 0) {
        this.retiradaParaSelecionado = this.contasCorrentesSaldo.find(contaCorrenteSaldo => contaCorrenteSaldo.id != this.contaCorrenteSaldoSelecionado.id);
      }
    }

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-retirada",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Salvar") {
        this.retiradaEnviada = true;

        if (this.retiradaParaSelecionado == null) {
          this.retiradaParaSelecionadoErro = true;
        } else {
          this.retiradaParaSelecionadoErro = false;
        }

        if (!this.retiradaParaSelecionadoErro && !this.formRetirada.invalid) {
          if (!this.validarRetiradaValorErro()) {
            this.modalRetirada(content, false, conta_corrente_saldo);
          } else {
            let detalhes: string = "";

            if (!this.contaCorrenteSaldoSelecionado.is_manager || ((this.is_master || this.is_diretor) && !this.contaCorrenteSaldoSelecionado.is_manager)) {
              /* Com Destino */

              detalhes = "DE: " + this.contaCorrenteSaldoSelecionado.fullname + " - PARA: " + this.retiradaParaSelecionado.fullname;
            } else {
              /* Sem Destino */

              detalhes = "DE: " + this.contaCorrenteSaldoSelecionado.fullname + " - PARA: MASTER";
            }

            if (this.formRetirada.controls["detalhes"].value != null && this.formRetirada.controls["detalhes"].value.trim() != "") {
              detalhes = detalhes + " - " + this.formRetirada.controls["detalhes"].value;
            }

            /* Retirada */

            let contaCorrenteRetirada = new ContaCorrenteExtrato();

            contaCorrenteRetirada.seller_id = this.contaCorrenteSaldoSelecionado.id;
            contaCorrenteRetirada.valor_transacao = this.formRetirada.controls["valor"].value;
            contaCorrenteRetirada.detalhes_transacao = detalhes;
            contaCorrenteRetirada.deposito = false;

            /* Categoria */

            if (this.contasCorrentesCategoriaSelecionada != null) {
              contaCorrenteRetirada.id_conta_corrente_flex_categoria = this.contasCorrentesCategoriaSelecionada.id;
            } else {
              contaCorrenteRetirada.id_conta_corrente_flex_categoria = null;
            }

            let adicionarRetirada: number = 0;

            this.contaCorrenteService.add(contaCorrenteRetirada).subscribe(
              adicionarRetiradas => adicionarRetirada = adicionarRetiradas,
              error => { console.log("Erro: " + error) },
              () => {
                if (adicionarRetirada > 0) {
                  if (!this.contaCorrenteSaldoSelecionado.is_manager || ((this.is_master || this.is_diretor) && !this.contaCorrenteSaldoSelecionado.is_manager)) {
                    /* Com Destino */

                    /* Depósito */

                    let contaCorrenteDeposito = new ContaCorrenteExtrato();

                    contaCorrenteDeposito.seller_id = this.retiradaParaSelecionado.id;
                    contaCorrenteDeposito.valor_transacao = this.formRetirada.controls["valor"].value;
                    contaCorrenteDeposito.detalhes_transacao = detalhes;
                    contaCorrenteDeposito.deposito = true;

                    let adicionarDeposito: number = 0;

                    this.contaCorrenteService.add(contaCorrenteDeposito).subscribe(
                      adicionarDepositos => adicionarDeposito = adicionarDepositos,
                      error => { console.log("Erro: " + error) },
                      () => {
                        if (adicionarDeposito > 0) {
                          this.toastr.success("", "Retirada adicionada com sucesso!");

                          this.carregarDados();
                        }
                      }
                    );
                  } else {
                    this.toastr.success("", "Retirada adicionada com sucesso!");

                    this.carregarDados();
                  }
                }
              }
            );
          }
        } else {
          this.modalRetirada(content, false, conta_corrente_saldo);
        }
      }
    }, () => {

    });
  }

  /* Depósito */

  selecionarDepositoDe(id: string) {
    this.depositoDeSelecionado = this.contasCorrentesSaldo.find(contaCorrenteSaldo => contaCorrenteSaldo.id == parseInt(id));

    this.validarDepositoValorErro();
  }

  validarDepositoValorErro(): boolean {
    if (!this.formDeposito.invalid) {
      let valor: number = this.formDeposito.controls["valor"].value == null ? 0 : this.formDeposito.controls["valor"].value;

      if (valor == 0) {
        this.depositoValorErro = true;

        return false;
      } else {
        if (!this.contaCorrenteSaldoSelecionado.is_manager || ((this.is_master || this.is_diretor) && !this.contaCorrenteSaldoSelecionado.is_manager)) {
          /* Com Origem */

          if (this.depositoDeSelecionado.saldo_final < valor) {
            this.depositoValorErro = true;

            return false;
          } else {
            this.depositoValorErro = false;

            return true;
          }
        } else {
          this.depositoValorErro = false;

          return true;
        }
      }
    }
  }

  /* Retirada */

  selecionarRetiradaPara(id: string) {
    this.retiradaParaSelecionado = this.contasCorrentesSaldo.find(contaCorrenteSaldo => contaCorrenteSaldo.id == parseInt(id));
  }

  validarRetiradaValorErro(): boolean {
    if (!this.formRetirada.invalid) {
      let valor: number = this.formRetirada.controls["valor"].value == null ? 0 : this.formRetirada.controls["valor"].value;

      if (valor == 0) {
        this.retiradaValorErro = true;

        return false;
      } else {
        if (this.contaCorrenteSaldoSelecionado.saldo_final < valor) {
          this.retiradaValorErro = true;

          return false;
        } else {
          this.retiradaValorErro = false;

          return true;
        }
      }
    }
  }
}
