import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Animacao } from './core/tools/animacao';
import { Subscription, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

declare const WebphoneL5: any;

/* Services */

import { AuthService } from './core/services/auth/auth.service';
import { CallCenterService } from './core/services/call-center/call-center.service';
import { CallCenterDiscadorService } from './core/services/call-center-discador/call-center-discador.service';
import { CentralAtendimentoService } from './core/services/central-atendimento/central-atendimento.service';
import { VendedorService } from './core/services/vendedores/vendedor.service';

/* Models */

import { CallCenterPausa } from './core/models/call-center/call-center-pausa';
import { Vendedor } from './core/models/vendedores/vendedor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class AppComponent {
  title = "Portal do Cliente";

  /* Acesso */

  acesso: boolean = false;
  autorizacao: boolean = false;

  /* Call Center */

  verificarStatusCallCenter = new Subscription();

  /* Central de Atendimento */

  centralAtendimento: boolean = false;
  centralAtendimentoEstilo: boolean = false;

  /* Maximizar */

  maximizar: boolean = true;

  /* Pausa */

  pausaVigente: CallCenterPausa = null;

  tempoPausaVigente = new Subscription();

  /* Tempo */

  tempoMonitoramentoIntervalo: number = 60000;

  /* Toastr */

  toastId: number = 0;

  /* Estilo */

  cssContainerConteudo: string = "";

  @HostListener("window:beforeunload")
  updateStatusPortal() {
    let alterarStatusPortal = false;

    this.vendedorService.updateStatusPortalByVendedor(false).subscribe(
      alterarStatusPortais => alterarStatusPortal = alterarStatusPortais,
      error => { console.log("Erro: " + error) },
      () => {

      }
    );
  }

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private callCenterService: CallCenterService,
    private callCenterDiscadorService: CallCenterDiscadorService,
    private centralAtendimentoService: CentralAtendimentoService,
    private vendedorService: VendedorService
  ) { }

  ngOnDestroy() {
    this.verificarStatusCallCenter.unsubscribe();
  }

  ngOnInit() {
    /* Acesso */

    this.receberAcesso();
    this.receberAutorizacao();

    /* Call Center */

    this.monitorarChamadas(0);

    /* Call Center (Discador) */

    this.receberCancelarPausa();
    this.receberPausaManual();
    this.receberReiniciarTempo();
    this.receberStatusChamadas();
    this.receberVerificacaoManual();

    /* Central de Atendimento */

    this.receberCentralAtendimento();
    this.receberCentralAtendimentoEstilo();
    this.receberCentralAtendimentoMaximizar();
  }

  /* Acesso */

  receberAcesso() {
    this.authService.acessoEmitter.subscribe(
      acesso => {
        this.acesso = acesso;

        /* Estilo */

        this.definirEstilo();
      }
    );
  }

  receberAutorizacao() {
    this.authService.autorizacaoEmitter.subscribe(
      autorizacao => {
        this.autorizacao = autorizacao;

        if (this.autorizacao) {
          this.monitorarChamadas(0);
        } else {
          this.verificarStatusCallCenter.unsubscribe();

          this.encerrarTempoPausa();
        }
      }
    );
  }

  /* Call Center */

  cancelarPausaVigente() {
    if (this.pausaVigente != null) {
      let alterarPausa: boolean = false;

      this.callCenterService.updatePausa(this.pausaVigente).subscribe(
        alterarPausas => alterarPausa = alterarPausas,
        error => { console.log("Erro: " + error) },
        () => {
          if (alterarPausa) {
            this.toastr.success("", "Pausa cancelada com sucesso!");

            this.encerrarTempoPausa();

            this.verificarChamadas();
          }
        }
      );
    }
  }

  encerrarTempoPausa() {
    if (this.toastId != 0) {
      let toast: any = this.toastr.toasts.find(toast => toast.toastId == this.toastId);

      if (toast != null) {
        this.toastr.remove(toast.toastId);
      }
    }

    /* Pausa */

    this.pausaVigente = null;

    this.tempoPausaVigente.unsubscribe();

    /* Toastr */

    this.toastId = 0;

    /* Storage */

    localStorage.setItem("discador_pausado", "false");
  }

  iniciarTempoPausa() {
    if (this.toastId == 0) {
      this.tempoPausaVigente = timer(1000, 1000).subscribe(() => { /* Início / Intervalo */
        if (this.pausaVigente != null) {
          if (this.pausaVigente.data_hora_final > new Date()) {
            /* Tempo Decorrido */

            let diferencaDecorrida: number = Math.abs(this.pausaVigente.data_hora_inicial.getTime() - new Date().getTime());

            let horasDecorridas: number = Math.floor((diferencaDecorrida / (1000 * 60 * 60)) % 24);
            let minutosDecorridos: number = Math.floor((diferencaDecorrida / (1000 * 60)) % 60);
            let segundosDecorridos: number = Math.floor((diferencaDecorrida / (1000)) % 60);

            let tempoDecorrido: string = horasDecorridas.toString().padStart(2, "0") + ":" + minutosDecorridos.toString().padStart(2, "0") + ":" + segundosDecorridos.toString().padStart(2, "0");

            this.pausaVigente.tempo_pausa = tempoDecorrido;

            /* Tempo Restante */

            let diferencaRestante: number = Math.abs(this.pausaVigente.data_hora_final.getTime() - new Date().getTime());

            let horasRestantes: number = Math.floor((diferencaRestante / (1000 * 60 * 60)) % 24);
            let minutosRestantes: number = Math.floor((diferencaRestante / (1000 * 60)) % 60);
            let segundosRestantes: number = Math.floor((diferencaRestante / (1000)) % 60);

            let tempoRestante: string = " Tempo restante: " + horasRestantes.toString().padStart(2, "0") + ":" + minutosRestantes.toString().padStart(2, "0") + ":" + segundosRestantes.toString().padStart(2, "0");

            if (this.toastId != 0) {
              let toast: any = this.toastr.toasts.find(toast => toast.toastId == this.toastId);

              if (toast != null) {
                toast.toastRef.componentInstance.message = "Chamadas pausadas!" + tempoRestante;
              } else {
                let toast: any = this.toastr.warning("", "Chamadas pausadas!" + tempoRestante, {
                  enableHtml: true,
                  extendedTimeOut: 0,
                  positionClass: "toast-bottom-left",
                  tapToDismiss: false,
                  timeOut: 0
                });

                this.toastId = toast.toastId;
              }
            } else {
              let toast: any = this.toastr.warning("", "Chamadas pausadas!" + tempoRestante, {
                enableHtml: true,
                extendedTimeOut: 0,
                positionClass: "toast-bottom-left",
                tapToDismiss: false,
                timeOut: 0
              });

              this.toastId = toast.toastId;
            }
          } else {
            this.encerrarTempoPausa();

            this.verificarChamadas();
          }
        } else {
          this.encerrarTempoPausa();

          this.verificarChamadas();
        }
      });
    }
  }

  monitorarChamadas(tempoMonitoramentoInicial: number) {
    this.verificarStatusCallCenter.unsubscribe();

    this.verificarStatusCallCenter = timer(tempoMonitoramentoInicial, this.tempoMonitoramentoIntervalo).subscribe(() => { /* Início / Intervalo */
      if (this.acesso) {
        /* Storage */

        let discador_ativo: boolean = (localStorage.getItem("discador_ativo") == "true");

        if (!discador_ativo) {
          this.verificarChamadas();
        }
      }
    });
  }

  verificarChamadas() {
    let vendedor: Vendedor = null;

    this.vendedorService.getById().subscribe(
      vendedores => vendedor = vendedores,
      error => { console.log("Erro: " + error) },
      () => {
        if (vendedor != null) {
          if (vendedor.is_master_seller) {
            if (vendedor.call_center_discador_automatico) {
              let pausas: CallCenterPausa[] = [];

              this.callCenterService.getPausaProgramadaHojeByUsuario(vendedor.id).subscribe(
                pausa => pausas = pausa,
                error => { console.log("Erro: " + error) },
                () => {
                  if (pausas.length > 0) {
                    let dataHoraAtual: Date = new Date();

                    this.pausaVigente = null;

                    pausas.forEach((item) => {
                      item.data_hora_final = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " " + item.hr_final_pausa);
                      item.data_hora_inicial = new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " " + item.hr_inicial_pausa);

                      if (dataHoraAtual >= item.data_hora_inicial && dataHoraAtual <= item.data_hora_final) {
                        this.pausaVigente = item;
                      }
                    });

                    if (this.pausaVigente != null) {
                      /* Storage */

                      localStorage.setItem("chamadas_automaticas", "false");
                      localStorage.setItem("discador_ativo", "false");
                      localStorage.setItem("discador_pausado", "true");

                      this.iniciarTempoPausa();
                    } else {
                      /* Storage */

                      localStorage.setItem("chamadas_automaticas", "true");
                      localStorage.setItem("discador_ativo", "true");
                      localStorage.setItem("discador_pausado", "false");

                      this.encerrarTempoPausa();

                      if (!this.router.isActive("call-center-ligacao", false) && !this.router.isActive("pedidos-cadastro", false)) {
                        this.toastr.warning("", "Iniciando chamadas automáticas...", { positionClass: "toast-bottom-left" });

                        this.router.navigateByUrl("/not-found", { skipLocationChange: true }).then(() => {
                          this.router.navigate(["/call-center"]);
                        });
                      }
                    }
                  } else {
                    /* Storage */

                    localStorage.setItem("chamadas_automaticas", "true");
                    localStorage.setItem("discador_ativo", "true");
                    localStorage.setItem("discador_pausado", "false");

                    this.encerrarTempoPausa();

                    if (!this.router.isActive("call-center-ligacao", false) && !this.router.isActive("pedidos-cadastro", false)) {
                      this.toastr.warning("", "Iniciando chamadas automáticas...", { positionClass: "toast-bottom-left" });

                      this.router.navigateByUrl("/not-found", { skipLocationChange: true }).then(() => {
                        this.router.navigate(["/call-center"]);
                      });
                    }
                  }
                }
              );
            } else {
              /* Storage */

              localStorage.setItem("chamadas_automaticas", "false");

              this.encerrarTempoPausa();
            }
          } else {
            /* Storage */

            localStorage.setItem("chamadas_automaticas", "false");

            this.verificarStatusCallCenter.unsubscribe();

            this.encerrarTempoPausa();
          }
        } else {
          /* Storage */

          localStorage.setItem("chamadas_automaticas", "false");

          this.verificarStatusCallCenter.unsubscribe();

          this.encerrarTempoPausa();
        }
      }
    );
  }

  /* Call Center (Discador) */

  receberCancelarPausa() {
    this.callCenterDiscadorService.cancelarPausa.subscribe(
      cancelarPausa => {
        if (cancelarPausa) {
          this.cancelarPausaVigente();
        }
      }
    );
  }

  receberPausaManual() {
    this.callCenterDiscadorService.pausaManual.subscribe(
      pausaManual => {
        if (pausaManual) {
          this.verificarChamadas();
        }
      }
    );
  }

  receberReiniciarTempo() {
    this.callCenterDiscadorService.reiniciarTempo.subscribe(
      reiniciarTempo => {
        if (reiniciarTempo) {
          this.monitorarChamadas(this.tempoMonitoramentoIntervalo);
        }
      }
    );
  }

  receberStatusChamadas() {
    this.callCenterDiscadorService.statusChamadas.subscribe(
      statusChamadas => {
        if (!statusChamadas) {
          this.verificarStatusCallCenter.unsubscribe();

          this.encerrarTempoPausa();
        }
      }
    );
  }

  receberVerificacaoManual() {
    this.callCenterDiscadorService.verificacaoManual.subscribe(
      verificacaoManual => {
        if (verificacaoManual) {
          this.verificarChamadas();
        }
      }
    );
  }

  /* Central de Atendimento */

  receberCentralAtendimento() {
    this.authService.centralAtendimentoEmitter.subscribe(
      centralAtendimento => {
        this.centralAtendimento = centralAtendimento;

        let usa_pabx: boolean = (localStorage.getItem("usa_pabx") == "true");

        if (usa_pabx) {
          if (!this.centralAtendimento) {
            let tipoSoftphone: number = parseInt(localStorage.getItem("tipo_softphone"));

            if (tipoSoftphone == 3) {
              /* L5 */

              WebphoneL5.unregister();
            }
          }
        }
      }
    );
  }

  receberCentralAtendimentoEstilo() {
    this.authService.centralAtendimentoEstiloEmitter.subscribe(
      centralAtendimentoEstilo => {
        this.centralAtendimentoEstilo = centralAtendimentoEstilo;
      }
    );
  }

  receberCentralAtendimentoMaximizar() {
    this.centralAtendimentoService.maximizarEmitter.subscribe(
      maximizar => {
        this.maximizar = maximizar;
      }
    );
  }

  /* Estilo */

  definirEstilo() {
    this.cssContainerConteudo = "";

    if (this.acesso) {
      this.cssContainerConteudo = "container_conteudo_topo";
    }
  }
}
