export class CallCenterConfiguracao {
  public id: number;
  public rota_ou_vendavisita: string;
  public rota_dias: number;
  public vv_visita_dias: number;
  public vv_venda_dias: number;
  public perc_ativo: number;
  public perc_inativo: number;
  public perc_svenda: number;
  public guidclientenapis: string;
  public numero_ligacoes_por_dia: number;
  public flag_atencao_qtd_visitas: number;
  public flag_atencao_periodo_dias: number;
  public vv_visitas_logica: string;
  public logotipo: string;
  public arte_rodape: string;
  public logo_b2b: string;
  public dominio_cliente: string;
  public libera_status_vendarealizada: boolean;
  public integrado_pabx: boolean;
  public estilo_central_atendimento: number;
  public novaligacao_vertodosclientes: boolean;
  public ddd_local: number;
  public integrado_whatss: boolean;
  public mostra_classificacao: boolean;
  public tipo_softphone: number;
  public tempo_atendimento: number;
  public fechamento_automatico_ligacao: boolean;
  public modelo_tela_ligacoes: number;
  public usa_tela_qualificacao: boolean;
  public usa_tela_cesta: boolean;

  constructor() { }
}
