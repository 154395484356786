import { Component, OnInit } from '@angular/core';

/* Services */

import { AuthService } from '../../core/services/auth/auth.service';
import { ConfiguracaoService } from '../../core/services/configuracoes/configuracao.service';

/* Models */

import { Configuracao } from '../../core/models/configuracoes/configuracao';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  preserveWhitespaces: false
})

export class NavBarComponent implements OnInit {
  menuInterno: boolean = true;

  /* Configurações */

  configuracao: Configuracao = null;

  /* Layout */

    /* Cores */

    corLink: string = "rgba(255, 255, 255, .5)";
    corMenu: string = "";

    /* Logomarca */

    logomarca: string = "";

  /* Storage */

  fullname: string = "";
  is_master: boolean = false;
  is_manager: boolean = false;
  is_master_seller: boolean = false;
  is_diretor: boolean = false;
  link_bi: string = "";
  seller_ver_aba_call_center: boolean = false;
  token_innersac: string = localStorage.getItem("token_innersac");
  link_innersac: string = localStorage.getItem("link_innersac");

  constructor(
    private authService: AuthService,
    private configuracaoService: ConfiguracaoService
  ) { }

  ngOnInit() {
    /* Emmiter */

    this.receberAtualizacao();

    this.atualizarStorage();
    this.carregarDados();
    this.verificarLayout();
  }

  /* Ações */

  abrirLinkBI() {
    window.open(this.link_bi, "_blank");
  }

  alternarMenuInterno() {
    this.menuInterno = !this.menuInterno;
  }

  sair() {
    this.authService.desautorizar(true);
  }

  /* Dados */

  carregarDados() {
    /* Configurações */

    this.configuracaoService.getByGuidClienteNapis().subscribe(
      configuracoes => this.configuracao = configuracoes,
      error => { console.log("Erro: " + error) },
      () => {

      }
    );
  }

  /* Emmiter */

  receberAtualizacao() {
    this.authService.navBarEmitter.subscribe(
      navBar => {
        this.atualizarStorage();
      }
    );
  }

  /* Layout */

  verificarLayout() {
    /* Empresa */

    if (window.location.href.indexOf("aokisistemas") > -1) {
      /* Aoki */

      this.corMenu = "#005aae";

      this.logomarca = "assets/logo_aoki_preto.png";
    } else if (window.location.href.indexOf("portal.cirobottini") > -1) {
      /* Bottini */

      this.corMenu = "#ef7e16";

      this.logomarca = "assets/logo_bottini_branco.png";
    } else if (window.location.href.indexOf("portal.2bdigitall") > -1) {
      /* 2B */

      this.corLink = "rgba(0, 0, 0, .5)";
      this.corMenu = "#f8f9fa";

      this.logomarca = "assets/logo_2bdigital_branco.png";
    } else {
      this.corMenu = "#00679a";

      this.logomarca = "assets/logo_napis_branco.png";
    }
  }

  /* Storage */

  atualizarStorage() {
    this.fullname = localStorage.getItem("fullname");
    this.is_master = (localStorage.getItem("is_master") == "true");
    this.is_manager = (localStorage.getItem("is_manager") == "true");
    this.is_master_seller = (localStorage.getItem("is_master_seller") == "true");
    this.is_diretor = (localStorage.getItem("is_diretor") == "true");
    this.link_bi = localStorage.getItem("link_bi");
    this.seller_ver_aba_call_center = (localStorage.getItem("seller_ver_aba_call_center") == "true");
  }
}
