import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Usuario } from '../../models/usuarios/usuario';

@Injectable({
  providedIn: 'root'
})

export class UsuarioService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getByEmail(email: string): Observable<Usuario> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "email": email
    };

    return this.http.post<Usuario>(this.baseUrl + "api/Usuario/GetByEmail", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getByLogin(email: string, senha: string, criptografada: boolean): Observable<Usuario> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "email": email,
      "senha": senha,
      "criptografada": criptografada
    };

    return this.http.post<Usuario>(this.baseUrl + "api/Usuario/GetByLogin", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public sendEmail(email: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "email": email
    };

    return this.http.post<boolean>(this.baseUrl + "api/Usuario/SendEmail", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
