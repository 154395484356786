import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Componentes */

import { AlterarSenhaComponent } from './components/alterar-senha/alterar-senha.component';
import { AlternarUsuarioComponent } from './components/alternar-usuario/alternar-usuario.component';
import { AutomacaoMensagensComponent } from './components/automacao-mensagens/automacao-mensagens.component';
import { AutomacaoMensagensCadastroComponent } from './components/automacao-mensagens-cadastro/automacao-mensagens-cadastro.component';
import { CallCenterComponent } from './components/call-center/call-center.component';
import { CallCenterGerenciarDiscadorComponent } from './components/call-center-gerenciar-discador/call-center-gerenciar-discador.component';
import { CallCenterLigacaoComponent } from './components/call-center-ligacao/call-center-ligacao.component';
import { CallCenterMotivosPausaComponent } from './components/call-center-motivos-pausa/call-center-motivos-pausa.component';
import { CallCenterMotivosPausaCadastroComponent } from './components/call-center-motivos-pausa-cadastro/call-center-motivos-pausa-cadastro.component';
import { CallCenterNovaLigacaoComponent } from './components/call-center-nova-ligacao/call-center-nova-ligacao.component';
import { ConfiguracoesComponent } from './components/configuracoes/configuracoes.component';
import { ContasCorrentesComponent } from './components/contas-corrrentes/contas-correntes.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { ClientesCadastroComponent } from './components/clientes-cadastro/clientes-cadastro.component';
import { CrmComponent } from './components/crm/crm.component';
import { DashboardAnaliseComparativaComponent } from './components/dashboard/dashboard-analise-comparativa/dashboard-analise-comparativa.component';
import { DashboardCallCenterComponent } from './components/dashboard/dashboard-call-center/dashboard-call-center.component';
import { DashboardCallCenterPainelGerencialComponent } from './components/dashboard/dashboard-call-center-painel-gerencial/dashboard-call-center-painel-gerencial.component';
import { DashboardControleClientesComponent } from './components/dashboard/dashboard-controle-clientes/dashboard-controle-clientes.component';
import { DashboardOportunidadesComponent } from './components/dashboard/dashboard-oportunidades/dashboard-oportunidades.component';
import { DashboardQuilometragensDespesasComponent } from './components/dashboard/dashboard-quilometragens-despesas/dashboard-quilometragens-despesas.component';
import { DashboardVisitasComponent } from './components/dashboard/dashboard-visitas/dashboard-visitas.component';
import { ImportacoesComponent } from './components/importacoes/importacoes.component';
import { IntegracoesComponent } from './components/integracoes/integracoes.component';
import { LoginComponent } from './components/login/login.component';
import { MotivosNaoVendaCadastroComponent } from './components/motivos-nao-venda-cadastro/motivos-nao-venda-cadastro.component';
import { MotivosNaoVendaComponent } from './components/motivos-nao-venda/motivos-nao-venda.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PedidosCadastroComponent } from './components/pedidos-cadastro/pedidos-cadastro.component';
import { PedidosComponent } from './components/pedidos/pedidos.component';
import { ProdutosComponent } from './components/produtos/produtos.component';
import { ProdutosCadastroComponent } from './components/produtos-cadastro/produtos-cadastro.component';
import { ProdutosCampanhasCadastroComponent } from './components/produtos-campanhas-cadastro/produtos-campanhas-cadastro.component';
import { ProdutosPromocoesCadastroComponent } from './components/produtos-promocoes-cadastro/produtos-promocoes-cadastro.component';
import { RecuperarSenhaComponent } from './components/recuperar-senha/recuperar-senha.component';
import { RotasComponent } from './components/rotas/rotas.component';
import { VendedoresComponent } from './components/vendedores/vendedores.component';
import { VendedoresCadastroComponent } from './components/vendedores-cadastro/vendedores-cadastro.component';
import { WhatsappInnersacComponent } from './components/whatsapp-innersac/whatsapp-innersac.component';
import { WhatsappInstanciaComponent } from './components/whatsapp-instancia/whatsapp-instancia.component';

/* Auth */

import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: "", component: LoginComponent, canActivate: [AuthGuard] },

  { path: "alterar-senha", component: AlterarSenhaComponent, canActivate: [AuthGuard] },

  { path: "alternar-usuario", component: AlternarUsuarioComponent, canActivate: [AuthGuard] },

  { path: "automacao-mensagens", component: AutomacaoMensagensComponent, canActivate: [AuthGuard] },
  { path: "automacao-mensagens-cadastro", component: AutomacaoMensagensCadastroComponent, canActivate: [AuthGuard] },
  { path: "automacao-mensagens-cadastro/:id", component: AutomacaoMensagensCadastroComponent, canActivate: [AuthGuard] },

  { path: "call-center", component: CallCenterComponent, canActivate: [AuthGuard] },
  { path: "call-center-gerenciar-discador", component: CallCenterGerenciarDiscadorComponent, canActivate: [AuthGuard] },
  { path: "call-center-ligacao", component: CallCenterLigacaoComponent, canActivate: [AuthGuard] },
  { path: "call-center-ligacao/:id", component: CallCenterLigacaoComponent, canActivate: [AuthGuard] },
  { path: "call-center-motivos-pausa", component: CallCenterMotivosPausaComponent, canActivate: [AuthGuard] },
  { path: "call-center-motivos-pausa-cadastro", component: CallCenterMotivosPausaCadastroComponent, canActivate: [AuthGuard] },
  { path: "call-center-motivos-pausa-cadastro/:id", component: CallCenterMotivosPausaCadastroComponent, canActivate: [AuthGuard] },
  { path: "call-center-nova-ligacao", component: CallCenterNovaLigacaoComponent, canActivate: [AuthGuard] },

  { path: "clientes", component: ClientesComponent, canActivate: [AuthGuard] },
  { path: "clientes-cadastro", component: ClientesCadastroComponent, canActivate: [AuthGuard] },
  { path: "clientes-cadastro/:id", component: ClientesCadastroComponent, canActivate: [AuthGuard] },

  { path: "configuracoes", component: ConfiguracoesComponent, canActivate: [AuthGuard] },

  { path: "contas-correntes", component: ContasCorrentesComponent, canActivate: [AuthGuard] },

  { path: "crm", component: CrmComponent, canActivate: [AuthGuard] },

  { path: "dashboard-analise-comparativa", component: DashboardAnaliseComparativaComponent, canActivate: [AuthGuard] },
  { path: "dashboard-call-center", component: DashboardCallCenterComponent, canActivate: [AuthGuard] },
  { path: "dashboard-call-center-painel-gerencial", component: DashboardCallCenterPainelGerencialComponent, canActivate: [AuthGuard] },
  { path: "dashboard-controle-clientes", component: DashboardControleClientesComponent, canActivate: [AuthGuard] },
  { path: "dashboard-oportunidades", component: DashboardOportunidadesComponent, canActivate: [AuthGuard] },
  { path: "dashboard-quilometragens-despesas", component: DashboardQuilometragensDespesasComponent, canActivate: [AuthGuard] },
  { path: "dashboard-visitas-30-dias", component: DashboardVisitasComponent, canActivate: [AuthGuard] },
  { path: "dashboard-visitas-hoje", component: DashboardVisitasComponent, canActivate: [AuthGuard] },

  { path: "integracoes", component: IntegracoesComponent, canActivate: [AuthGuard] },

  { path: "importacoes", component: ImportacoesComponent, canActivate: [AuthGuard] },

  { path: "login", component: LoginComponent, canActivate: [AuthGuard] },

  { path: "motivos-nao-venda", component: MotivosNaoVendaComponent, canActivate: [AuthGuard] },
  { path: "motivos-nao-venda-cadastro", component: MotivosNaoVendaCadastroComponent, canActivate: [AuthGuard] },
  { path: "motivos-nao-venda-cadastro/:id", component: MotivosNaoVendaCadastroComponent, canActivate: [AuthGuard] },

  { path: "pedidos", component: PedidosComponent, canActivate: [AuthGuard] },
  { path: "pedidos-cadastro", component: PedidosCadastroComponent, canActivate: [AuthGuard] },
  { path: "pedidos-cadastro/:id", component: PedidosCadastroComponent, canActivate: [AuthGuard] },

  { path: "produtos", component: ProdutosComponent, canActivate: [AuthGuard] },
  { path: "produtos-cadastro", component: ProdutosCadastroComponent, canActivate: [AuthGuard] },
  { path: "produtos-cadastro/:id", component: ProdutosCadastroComponent, canActivate: [AuthGuard] },

  { path: "produtos-campanhas-cadastro", component: ProdutosCampanhasCadastroComponent, canActivate: [AuthGuard] },
  { path: "produtos-campanhas-cadastro/:id", component: ProdutosCampanhasCadastroComponent, canActivate: [AuthGuard] },

  { path: "produtos-promocoes-cadastro", component: ProdutosPromocoesCadastroComponent, canActivate: [AuthGuard] },
  { path: "produtos-promocoes-cadastro/:id", component: ProdutosPromocoesCadastroComponent, canActivate: [AuthGuard] },

  { path: "recuperar-senha", component: RecuperarSenhaComponent },

  { path: "rotas", component: RotasComponent, canActivate: [AuthGuard] },

  { path: "vendedores", component: VendedoresComponent, canActivate: [AuthGuard] },
  { path: "vendedores-cadastro", component: VendedoresCadastroComponent, canActivate: [AuthGuard] },
  { path: "vendedores-cadastro/:id", component: VendedoresCadastroComponent, canActivate: [AuthGuard] },

  { path: "whatsapp-innersac", component: WhatsappInnersacComponent, canActivate: [AuthGuard] },
  { path: "whatsapp-innersac/:telefone", component: WhatsappInnersacComponent, canActivate: [AuthGuard] },

  { path: "whatsapp-instancia", component: WhatsappInstanciaComponent, canActivate: [AuthGuard] },

  { path: "**", component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
