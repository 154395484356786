export class AutomacaoEnvio {
  public email: string;
  public phone: string;
  public idvisit: number;
  public dataligacao: string;
  public razaosocial: string;
  public cidade: string;
  public sellerid: number;
  public vendedorexterno: string;
  public emailvendedor: string;
  public statusligacao: string;
  public resultadoligacao: string;
  public anotacoes: string;
  public segmento: string;
  public fone_whatss: string;
  public fone_whatss_ddd: string;
  public responsible_name: string;
  public contato_callcenter: string;
  public client_id_app: number;

  constructor() { }
}
