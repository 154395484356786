import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

/* Models */

import { CanaisComunicacaoWhatsAppAudio } from '../../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-audio';
import { CanaisComunicacaoWhatsAppChat } from '../../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-chat';
import { CanaisComunicacaoWhatsAppDocumento } from '../../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-documento';
import { CanaisComunicacaoWhatsAppImagem } from '../../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-imagem';
import { CanaisComunicacaoWhatsAppQRCode } from '../../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-qr-code';
import { CanaisComunicacaoWhatsAppStatus } from '../../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-status';
import { CanaisComunicacaoWhatsAppSticker } from '../../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-sticker';
import { CanaisComunicacaoWhatsAppTelefone } from '../../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-telefone';
import { CanaisComunicacaoWhatsAppTexto } from '../../../models/canais-comunicacao/whatsapp/canais-comunicacao-whatsapp-texto';

@Injectable({
  providedIn: 'root'
})

export class CanaisComunicacaoWhatsappService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public getChat(dados: CanaisComunicacaoWhatsAppTelefone): Observable<CanaisComunicacaoWhatsAppChat[]> {
    this.headers.set("Content-Type", "application/json");

    dados.guidclientenapis = localStorage.getItem("guidclientenapis");

    let data = {
      "dados": dados
    };

    return this.http.post<CanaisComunicacaoWhatsAppChat[]>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/GetChat", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getQRCode(): Observable<CanaisComunicacaoWhatsAppQRCode> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<CanaisComunicacaoWhatsAppQRCode>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/GetQRCode", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getStatus(): Observable<CanaisComunicacaoWhatsAppStatus> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<CanaisComunicacaoWhatsAppStatus>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/GetStatus", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public sendAudio(dados: CanaisComunicacaoWhatsAppAudio): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    dados.guidclientenapis = localStorage.getItem("guidclientenapis");

    let data = {
      "dados": dados
    };

    return this.http.post<boolean>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/SendAudio", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public sendDocument(dados: CanaisComunicacaoWhatsAppDocumento): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    dados.guidclientenapis = localStorage.getItem("guidclientenapis");

    let data = {
      "dados": dados
    };

    return this.http.post<boolean>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/SendDocument", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public sendImage(dados: CanaisComunicacaoWhatsAppImagem): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    dados.guidclientenapis = localStorage.getItem("guidclientenapis");

    let data = {
      "dados": dados
    };

    return this.http.post<boolean>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/SendImage", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public sendSticker(dados: CanaisComunicacaoWhatsAppSticker): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    dados.guidclientenapis = localStorage.getItem("guidclientenapis");

    let data = {
      "dados": dados
    };

    return this.http.post<boolean>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/SendSticker", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public sendText(dados: CanaisComunicacaoWhatsAppTexto): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    dados.guidclientenapis = localStorage.getItem("guidclientenapis");

    let data = {
      "dados": dados
    };

    return this.http.post<boolean>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/SendText", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Arquivo */

  public addCanaisComunicacaoArquivo(arquivo: File): Observable<string> {
    this.headers.append("Content-Type", "multipart/form-data");

    let formData = new FormData();

    formData.append("arquivo", arquivo, arquivo.name);

    return this.http.post<string>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/AddCanaisComunicacaoArquivo", formData, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of("");
      })
    );
  }

  public deleteCanaisComunicacaoArquivo(nm_arquivo: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "nm_arquivo": nm_arquivo
    };

    return this.http.post<boolean>(this.baseUrl + "api/CanaisComunicacaoWhatsApp/DeleteCanaisComunicacaoArquivo", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
