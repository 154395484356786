import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

import { ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { CallCenterDiscadorService } from '../../core/services/call-center-discador/call-center-discador.service';
import { CentralAtendimentoService } from '../../core/services/central-atendimento/central-atendimento.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { ExcelService } from '../../core/tools/excel.service';
import { PedidoTotalizadorService } from '../../core/services/pedidos/pedido-totalizador.service';
import { ProdutoService } from '../../core/services/produtos/produto.service';
import { VisitaService } from '../../core/services/visitas/visita.service';

/* Models */

import { CallCenterConfiguracao } from '../../core/models/call-center/call-center-configuracao';
import { CallCenterLigacaoAnalitica } from '../../core/models/call-center/call-center-ligacao-analitica';
import { CallCenterLigacaoSintetica } from '../../core/models/call-center/call-center-ligacao-sintetica';
import { CallCenterOportunidade } from '../../core/models/call-center/call-center-oportunidade';
import { CallCenterOportunidadeClassificacao } from '../../core/models/call-center/call-center-oportunidade-classificacao';
import { CallCenterOportunidadeFiltrar } from '../../core/models/call-center/call-center-oportunidade-filtrar';
import { ClienteTag } from '../../core/models/clientes/cliente-tag';
import { Estatistica } from '../../core/models/pedidos/estatistica';
import { Produto } from '../../core/models/produtos/produto';
import { Visita } from '../../core/models/visitas/visita';
import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-call-center',
  templateUrl: './call-center.component.html',
  styleUrls: ['./call-center.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CallCenterComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Abas */

  tabSelecionada: string = "atencao";

  /* Dados */

    /* Filtro (Ligações) */

    vendedores: Vendedor[] = [];
    vendedorLigacoesSelecionado: Vendedor = null;

    /* Filtro (Produtos) */

    produtoEmBranco: boolean = false;
    produtoInvalido: boolean = false;

    produtos: Produto[] = [];
    produtosOriginal: Produto[] = [];

    produtoSelecionado: Produto = null;

  /* Filtros */

  formFiltro: FormGroup;

  filtroOpcoes: any[] = [
    { texto: "Classificação", valor: "0" },
    { texto: "Cliente", valor: "1" },
    { texto: "CNPJ/CPF", valor: "2" },
    { texto: "Código", valor: "3" },
    { texto: "Dias sem Compra", valor: "4" },
    { texto: "Dias sem Visita", valor: "5" },
    { texto: "Produto", valor: "6" },
    { texto: "Realizado Ano", valor: "7" },
    { texto: "Realizado Mês", valor: "8" },
    { texto: "Segmento", valor: "9" },
    { texto: "Tags", valor: "10" }
  ];

  /* Filtro (Produtos) */

  formFiltroProduto: FormGroup;

  filtroProdutos: any[] = [
    { texto: "Código", valor: "0" },
    { texto: "Descrição", valor: "1" },
    { texto: "Marca", valor: "2" }
  ];

  enviadoProduto: boolean = false;

  filtrandoProduto: boolean = false;

  enviado: boolean = false;
  filtrado: boolean = false;

  tagFiltro: any = [];
  tagFiltroErro: boolean = false;
  tagFiltroOpcoes: any = [];

  /* Ordernar (Ligações) */

  ordernarLigacoesOpcoes: any[] = [];

  ordernarLigacoesOpcoesOrdem: any[] = [
    { texto: "Crescente", valor: "0" },
    { texto: "Decrescente", valor: "1" }
  ];

  ordernarLigacoesSelecionado: string = "relationship";
  ordernarLigacoesOrdemSelecionado: string = "0";

  /* Ordernar (Oportunidades) */

  ordernarOportunidadesOpcoes: any[] = [];

  ordernarOportunidadesOpcoesOrdem: any[] = [
    { texto: "Crescente", valor: "0" },
    { texto: "Decrescente", valor: "1" }
  ];

  ordernarOportunidadesSelecionado: string = "name";
  ordernarOportunidadesOrdemSelecionado: string = "0";

  /* Gráfico de Status de Chamadas */

  pieChartOptions: ChartOptions = {
    animation: {
      duration: 0
    },
    responsive: true,
    legend: {
      position: "right",
      labels: {
        fontColor: "rgba(0, 0, 0, 1)"
      }
    },
    plugins: {
      datalabels: {
        color: "rgba(0, 0, 0, 1)",
        font: {
          weight: "bold"
        },
        formatter: (value) => {
          const label = value + "%";

          return label;
        }
      }
    }
  };

  pieChartLabels: Label[] = [];
  pieChartType: ChartType = "pie";
  pieChartLegend = true;
  pieChartPlugins = [pluginDataLabels];
  pieChartData: number[] = [];
  pieChartColors = [{ backgroundColor: [] }];

  /* Call Center (Configurações) */

  callCenterConfiguracao: CallCenterConfiguracao = null;

  /* Call Center (Ligações) */

    /* Ligações Analíticas */

    ligacoesAnaliticas: CallCenterLigacaoAnalitica[] = [];
    ligacoesAnaliticasOriginal: CallCenterLigacaoAnalitica[] = [];

    ligacaoAnaliticaMensagem: string = "";
  
    /* Ligações Sintéticas */

    ligacoesSinteticas: CallCenterLigacaoSintetica[] = [];

  /* Call Center (Oportunidades) */

  oportunidades: CallCenterOportunidade[] = [];

  /* Call Center (Oportunidades (Classificações)) */

  oportunidadesClassificacoes: CallCenterOportunidadeClassificacao[] = [];

  /* Visitas */

  visitas: Visita[] = [];

  /* Acompanhamento */

  /* Análise Sintética */

  valorVendasDia: number = 0;
  porcentagemVendasDia: number = 0;
  valorVendasMes: number = 0;

  /* Atenção/Ligações */

  qtdeLigacoes: number = 0;
  qtdeRealizado: number = 0;
  porcentagemQtdeRealizado: number = 0;
  valorMetaDia: number = 0;

  /* Oportunidades */

  qtdeClientes: number = 0;
  qtdeClientesPadrao: number = 0;
  qtdeClientesAdicionais: number = 0;
  valorMetaMes: number = 0;

  /* Estatística (Status do Pedido) */

  estatisticasClientes: Estatistica[] = [];

  /* Tags (Clientes) */

  tagCliente: any = [];
  tagClienteOpcoes: any = [];

  tagClienteIdSelecionado: number = 0;
  tagClienteNomeSelecionado: string = "";

  /* Modal */

  @ViewChild("comCheckOutPendente", { static: false }) comCheckOutPendente: ElementRef;

  /* Storage */

  modelo_tela_ligacoes: number = parseInt(localStorage.getItem("modelo_tela_ligacoes"));
  seller_id: number = parseInt(localStorage.getItem("id"));
  usa_pabx: boolean = (localStorage.getItem("usa_pabx") == "true");

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private callCenterDiscadorService: CallCenterDiscadorService,
    private centralAtendimentoService: CentralAtendimentoService,
    private clienteService: ClienteService,
    private excelService: ExcelService,
    private pedidoTotalizadorService: PedidoTotalizadorService,
    private produtoService: ProdutoService,
    private visitaService: VisitaService
  ) { }

  ngAfterViewInit() {
    this.centralAtendimentoService.maximizarEmitter.emit(true);
  }

  ngOnDestroy() {
    this.modalService.dismissAll();

    if (this.router.routerState.snapshot.url.indexOf("call-center-ligacao") !== 1 && this.router.routerState.snapshot.url.indexOf("call-center-nova-ligacao") !== 1) {
      this.apagarFiltroArmazenado();
    }
  }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      filtro: ["1"],
      classificacao: ["", Validators.required],
      cliente: ["", [Validators.required, Validators.minLength(3)]],
      cnpj_cpf: ["", Validators.required],
      codigo: ["", Validators.required],
      nro_inicial_sem_compra: ["", Validators.required],
      nro_final_sem_compra: [""],
      nro_inicial_sem_visita: ["", Validators.required],
      nro_final_sem_visita: [""],
      valor_inicial_realizado_ano: ["", Validators.required],
      valor_final_realizado_ano: [""],
      valor_inicial_realizado_mes: ["", Validators.required],
      valor_final_realizado_mes: [""],
      segmento: ["", Validators.required],
      vendedor: [""]
    });

    this.organizarFiltro();

    /* Filtro (Produtos) */

    this.formFiltroProduto = this.formBuilder.group({
      filtro: ["1"],
      codigo: ["", Validators.required],
      descricao: ["", Validators.required],
      marca: ["", Validators.required]
    });

    this.organizarFiltroProduto();

    /* Ordernar (Ligações) */

    if (this.modelo_tela_ligacoes == 0) {
      /* Modelo 1 */

      this.ordernarLigacoesOpcoes = [
        { texto: "Tipo (Ligação)", valor: "tipo" },
        { texto: "Status (Ligação)", valor: "status_name" },
        { texto: "Hora (Ligação)", valor: "data_ligacao" },
        { texto: "Agendamento", valor: "call_center_prox_agenda" },
        { texto: "Cliente", valor: "name" },
        { texto: "Cidade", valor: "cidade" },
        { texto: "Estado", valor: "estado" },
        { texto: "Vendedor(a)", valor: "fullname" },
        { texto: "Ult. Visita", valor: "ult_visit" },
        { texto: "Ult. Visita Dias", valor: "qtdediasultvis" },
        { texto: "Ult. Compra", valor: "ult_compra" },
        { texto: "Ult. Compra Dias", valor: "qtdediasultcompra" },
        { texto: "Objetivo Mês", valor: "objetivo_venda" },
        { texto: "Realizado Mês", valor: "realizado_venda" },
        { texto: "Falta Vender Mês", valor: "falta_vender" },
        { texto: "Status (Cliente)", valor: "relationship" },
        { texto: "Atenção", valor: "flag_atencao" }
      ];
    } else if (this.modelo_tela_ligacoes == 1) {
      /* Modelo 2 */

      this.ordernarLigacoesOpcoes = [
        { texto: "Tipo (Ligação)", valor: "tipo" },
        { texto: "Status (Ligação)", valor: "status_name" },
        { texto: "Hora (Ligação)", valor: "data_ligacao" },
        { texto: "Agendamento", valor: "call_center_prox_agenda" },
        { texto: "Cliente", valor: "name" },
        { texto: "Estado", valor: "estado" },
        { texto: "CNPJ/CPF", valor: "client_document" },
        { texto: "PV", valor: "pv" },
        { texto: "Segmento", valor: "segment" },
        { texto: "Zona", valor: "zona_vendas" },
        { texto: "Ult. Visita", valor: "ult_visit" },
        { texto: "Ult. Visita Dias", valor: "qtdediasultvis" },
        { texto: "Ult. Compra", valor: "ult_compra" },
        { texto: "Ult. Compra Dias", valor: "qtdediasultcompra" },
        { texto: "Potêncial Ano", valor: "potencial_ano" },
        { texto: "Realizado Ano", valor: "realizado_ano" },
        { texto: "% Ano", valor: "porcentagem_ano" },
        { texto: "Falta Ano", valor: "falta_ano" },
        { texto: "Objetivo Mês", valor: "objetivo_venda" },
        { texto: "Realizado Mês", valor: "realizado_venda" },
        { texto: "% Mês", valor: "porcentagem_venda" },
        { texto: "Mês Anterior", valor: "realizado_venda_mesanterior" },
        { texto: "Status (Cliente)", valor: "relationship" },
        { texto: "Atenção", valor: "flag_atencao" }
      ];
    }

    /* Ordernar (Oportunidades) */

    if (this.modelo_tela_ligacoes == 0) {
      /* Modelo 1 */

      this.ordernarOportunidadesOpcoes = [
        { texto: "Status", valor: "relationship" },
        { texto: "Agendamento", valor: "call_center_prox_agenda" },
        { texto: "Cliente", valor: "name" },
        { texto: "Cidade", valor: "cidade" },
        { texto: "Estado", valor: "estado" },
        { texto: "CNPJ/CPF", valor: "client_document" },
        { texto: "Segmento", valor: "segment" },
        { texto: "Vendedor(a)", valor: "fullname" },
        { texto: "Ult. Visita", valor: "ultvisita" },
        { texto: "Ult. Visita Dias", valor: "qtdediasultvisita" },
        { texto: "Ult. Compra", valor: "ultcomp" },
        { texto: "Ult. Compra Dias", valor: "qtdediasultcomp" },
        { texto: "Objetivo", valor: "objetivo_de_venda" },
        { texto: "Realizado", valor: "order_total" },
        { texto: "Falta Vender", valor: "falta_vender" },
        { texto: "Atenção", valor: "atencao" }
      ];
    } else if (this.modelo_tela_ligacoes == 1) {
      /* Modelo 2 */

      this.ordernarOportunidadesOpcoes = [
        { texto: "Status", valor: "relationship" },
        { texto: "Agendamento", valor: "call_center_prox_agenda" },
        { texto: "Cliente", valor: "name" },
        { texto: "Estado", valor: "estado" },
        { texto: "CNPJ/CPF", valor: "client_document" },
        { texto: "PV", valor: "pv" },
        { texto: "Segmento", valor: "segment" },
        { texto: "Zona", valor: "zona_vendas" },
        { texto: "Ult. Visita", valor: "ultvisita" },
        { texto: "Ult. Visita Dias", valor: "qtdediasultvisita" },
        { texto: "Ult. Compra", valor: "ultcomp" },
        { texto: "Ult. Compra Dias", valor: "qtdediasultcomp" },
        { texto: "Potêncial Ano", valor: "potencial_ano" },
        { texto: "Realizado Ano", valor: "realizado_ano" },
        { texto: "% Ano", valor: "porcentagem_ano" },
        { texto: "Falta Ano", valor: "falta_ano" },
        { texto: "Objetivo Mês", valor: "objetivo_de_venda" },
        { texto: "Realizado Mês", valor: "realizado_venda" },
        { texto: "% Mês", valor: "porcentagem_venda" },
        { texto: "Mês Anterior", valor: "realizado_venda_mesanterior" },
        { texto: "Atenção", valor: "atencao" }
      ];
    }

    this.carregarDadosLigacoes();
  }

  get ff() {
    return this.formFiltro.controls;
  }

  get fp() {
    return this.formFiltroProduto.controls;
  }

  /* Abas */

  alterarAbas(evento: any) {
    /* Ligações Analíticas */

    this.ligacoesAnaliticas = [];
    this.ligacoesAnaliticasOriginal = [];

    /* Call Center (Oportunidades) */

    this.oportunidades = [];

    if (evento.nextId == "atencao" || evento.nextId == "ligacoes") {
      this.tabSelecionada = evento.nextId;

      /* Filtro (Ligações) */

      this.vendedores = [];
      this.vendedorLigacoesSelecionado = null;

      this.carregarDadosLigacoes();
      this.apagarFiltroArmazenado();
    } else if (evento.nextId == "oportunidades") {
      /* Storage */

      let discador_ativo: boolean = (localStorage.getItem("discador_ativo") == "true");

      if (discador_ativo) {
        evento.preventDefault();

        return;
      }

      this.alterarFiltro("1");
      this.carregarDadosOportunidades();

      this.tabSelecionada = evento.nextId;
    }
  }

  /* Ações */

  alterarLigacao(ligacao_selecionada: number) {
    this.modalComCheckOutPendente(this.comCheckOutPendente, null, ligacao_selecionada);
  }

  atualizar() {
    this.carregarDadosLigacoes();
  }

  novaLigacao(id_cliente: number) {
    this.modalComCheckOutPendente(this.comCheckOutPendente, id_cliente, null);
  }

  /* Dados */

  calcularDadosAnaliseSintetica() {
    /* Vendas do Dia */

    if (this.valorVendasDia > 0 && this.valorMetaDia > 0) {
      this.porcentagemVendasDia = ((this.valorVendasDia / this.valorMetaDia) * 100);
    }

    /* Ligações Sintéticas */

    if (this.visitas.length > 0) {
      /* Visitas */

      /* Este Mês */

      let dataInicial = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      let dataFinal = new Date();

      let visitasPorPeriodo = this.visitas.filter(visitas => {
        let data = this.datePipe.transform(visitas.check_in, "yyyy/MM/dd");

        return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
      });

      this.ligacoesSinteticas = [];

      let periodo: string = "Este Mês: " + this.datePipe.transform(dataInicial, "dd/MM/yyyy") + " - " + this.datePipe.transform(dataFinal, "dd/MM/yyyy");

      let qtdeLigacoes: number = visitasPorPeriodo.length;
      let qtdeVenda: number = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_status == 4).length;
      let porcentagemVenda: number = qtdeVenda > 0 ? ((qtdeVenda / qtdeLigacoes) * 100) : 0;
      let qtdeLigacoesSemVenda: number = qtdeLigacoes - qtdeVenda;
      let porcentagemLigacoesSemVenda: number = qtdeLigacoesSemVenda > 0 ? ((qtdeLigacoesSemVenda / qtdeLigacoes) * 100) : 0;
      let qtdeLigacoesFeitas: number = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_tipo == "F").length;
      let porcentagemLigacoesFeitas: number = qtdeLigacoesFeitas > 0 ? ((qtdeLigacoesFeitas / qtdeLigacoes) * 100) : 0;
      let qtdeLigacoesRecebidas: number = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_tipo == "R").length;;
      let porcentagemLigacoesRecebidas: number = qtdeLigacoesRecebidas > 0 ? ((qtdeLigacoesRecebidas / qtdeLigacoes) * 100) : 0;
      let qtdeOutros: number = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_tipo != "F" && visitaPorPerido.callcenter_tipo != "R").length;
      let porcentagemOutros: number = qtdeOutros > 0 ? ((qtdeOutros / qtdeLigacoes) * 100) : 0;

      this.ligacoesSinteticas.push(new CallCenterLigacaoSintetica(periodo, qtdeLigacoes, qtdeVenda, porcentagemVenda, qtdeLigacoesSemVenda, porcentagemLigacoesSemVenda, qtdeLigacoesFeitas, porcentagemLigacoesFeitas, qtdeLigacoesRecebidas, porcentagemLigacoesRecebidas, qtdeOutros, porcentagemOutros));

      /* Mês Anterior */

      dataInicial = new Date(dataInicial.setMonth(dataInicial.getMonth() - 1));

      let dataTemporariaMesAnterior = new Date(dataFinal.getFullYear(), dataFinal.getMonth() - 1, dataFinal.getDate());

      if (dataFinal.getDate() > dataTemporariaMesAnterior.getDate()) {
        dataFinal = new Date(dataTemporariaMesAnterior.getFullYear(), dataTemporariaMesAnterior.getMonth(), 0);
      } else {
        dataFinal = new Date(dataFinal.setMonth(dataFinal.getMonth() - 1));
      }

      visitasPorPeriodo = this.visitas.filter(visitaPorPeriodo => {
        let data = this.datePipe.transform(visitaPorPeriodo.check_in, "yyyy/MM/dd");

        return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
      });

      periodo = "Mês Anterior: " + this.datePipe.transform(dataInicial, "dd/MM/yyyy") + " - " + this.datePipe.transform(dataFinal, "dd/MM/yyyy");

      qtdeLigacoes = visitasPorPeriodo.length;
      qtdeVenda = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_status == 4).length;
      porcentagemVenda = qtdeVenda > 0 ? ((qtdeVenda / qtdeLigacoes) * 100) : 0;
      qtdeLigacoesSemVenda = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_status == 5).length;
      porcentagemLigacoesSemVenda = qtdeLigacoesSemVenda > 0 ? ((qtdeLigacoesSemVenda / qtdeLigacoes) * 100) : 0;
      qtdeLigacoesFeitas = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_tipo == "F").length;
      porcentagemLigacoesFeitas = qtdeLigacoesFeitas > 0 ? ((qtdeLigacoesFeitas / qtdeLigacoes) * 100) : 0;
      qtdeLigacoesRecebidas = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_tipo == "R").length;;
      porcentagemLigacoesRecebidas = qtdeLigacoesRecebidas > 0 ? ((qtdeLigacoesRecebidas / qtdeLigacoes) * 100) : 0;
      qtdeOutros = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_tipo != "F" && visitaPorPerido.callcenter_tipo != "R").length;
      porcentagemOutros = qtdeOutros > 0 ? ((qtdeOutros / qtdeLigacoes) * 100) : 0;

      this.ligacoesSinteticas.push(new CallCenterLigacaoSintetica(periodo, qtdeLigacoes, qtdeVenda, porcentagemVenda, qtdeLigacoesSemVenda, porcentagemLigacoesSemVenda, qtdeLigacoesFeitas, porcentagemLigacoesFeitas, qtdeLigacoesRecebidas, porcentagemLigacoesRecebidas, qtdeOutros, porcentagemOutros));

      /* 2 Meses Atrás */

      dataInicial = new Date(dataInicial.setMonth(dataInicial.getMonth() - 1));

      let dataTemporariaDoisMeses = new Date(dataTemporariaMesAnterior.getFullYear(), dataTemporariaMesAnterior.getMonth() - 1, dataTemporariaMesAnterior.getDate());

      if (dataFinal.getDate() > dataTemporariaDoisMeses.getDate()) {
        dataFinal = new Date(dataTemporariaDoisMeses.getFullYear(), dataTemporariaDoisMeses.getMonth(), 0);
      } else {
        dataFinal = new Date(dataFinal.setMonth(dataFinal.getMonth() - 1));
      }

      visitasPorPeriodo = this.visitas.filter(visitaPorPeriodo => {
        let data = this.datePipe.transform(visitaPorPeriodo.check_in, "yyyy/MM/dd");

        return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
      });

      periodo = "2 Meses Atrás: " + this.datePipe.transform(dataInicial, "dd/MM/yyyy") + " - " + this.datePipe.transform(dataFinal, "dd/MM/yyyy");

      qtdeLigacoes = visitasPorPeriodo.length;
      qtdeVenda = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_status == 4).length;
      porcentagemVenda = qtdeVenda > 0 ? ((qtdeVenda / qtdeLigacoes) * 100) : 0;
      qtdeLigacoesSemVenda = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_status == 5).length;
      porcentagemLigacoesSemVenda = qtdeLigacoesSemVenda > 0 ? ((qtdeLigacoesSemVenda / qtdeLigacoes) * 100) : 0;
      qtdeLigacoesFeitas = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_tipo == "F").length;
      porcentagemLigacoesFeitas = qtdeLigacoesFeitas > 0 ? ((qtdeLigacoesFeitas / qtdeLigacoes) * 100) : 0;
      qtdeLigacoesRecebidas = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_tipo == "R").length;;
      porcentagemLigacoesRecebidas = qtdeLigacoesRecebidas > 0 ? ((qtdeLigacoesRecebidas / qtdeLigacoes) * 100) : 0;
      qtdeOutros = visitasPorPeriodo.filter(visitaPorPerido => visitaPorPerido.callcenter_tipo != "F" && visitaPorPerido.callcenter_tipo != "R").length;
      porcentagemOutros = qtdeOutros > 0 ? ((qtdeOutros / qtdeLigacoes) * 100) : 0;

      this.ligacoesSinteticas.push(new CallCenterLigacaoSintetica(periodo, qtdeLigacoes, qtdeVenda, porcentagemVenda, qtdeLigacoesSemVenda, porcentagemLigacoesSemVenda, qtdeLigacoesFeitas, porcentagemLigacoesFeitas, qtdeLigacoesRecebidas, porcentagemLigacoesRecebidas, qtdeOutros, porcentagemOutros));
    }
  }

  calcularDadosLigacoes() {
    this.pieChartLabels = [];
    this.pieChartData = [];
    this.pieChartColors = [{ backgroundColor: [] }];

    /* Call Center (Ligações) */

    /* Ligações Analíticas */

    if (this.ligacoesAnaliticas.length > 0) {
      /* Atenção/Ligações */

      this.qtdeLigacoes = this.ligacoesAnaliticas.length;
      this.qtdeRealizado = this.ligacoesAnaliticas[0].qtde_realizado;
      this.porcentagemQtdeRealizado = this.ligacoesAnaliticas[0].porcentagem_qtde_realizado;
      this.valorMetaDia = this.ligacoesAnaliticas[0].valor_meta_dia;

      /* À Ligar */

      let qtdeALigar: number = this.ligacoesAnaliticas.filter(ligacoesAnaliticas => ligacoesAnaliticas.status_name == "À Ligar").length;
      let porcentagemALigacar: number = ((qtdeALigar / this.ligacoesAnaliticas.length) * 100);

      if (qtdeALigar > 0) {
        this.pieChartLabels.push("À Ligar (" + qtdeALigar + ")");
        this.pieChartData.push(parseInt(porcentagemALigacar.toFixed(0)));
        this.pieChartColors[0].backgroundColor.push("rgba(0, 123, 255, 1)");
      }

      /* Agendada */

      let qtdeAgendada: number = this.ligacoesAnaliticas.filter(ligacoesAnaliticas => ligacoesAnaliticas.status_name == "Agendada").length;
      let porcentagemQtdeAgendada: number = ((qtdeAgendada / this.ligacoesAnaliticas.length) * 100);

      if (qtdeAgendada > 0) {
        this.pieChartLabels.push("Agendada (" + qtdeAgendada + ")");
        this.pieChartData.push(parseInt(porcentagemQtdeAgendada.toFixed(0)));
        this.pieChartColors[0].backgroundColor.push("rgba(255, 193, 7, 1)");
      }

      /* Não Comprou */

      let qtdeNaoComprou: number = this.ligacoesAnaliticas.filter(ligacoesAnaliticas => ligacoesAnaliticas.status_name == "Não Comprou").length;
      let porcentagemQtdeNaoComprou: number = ((qtdeNaoComprou / this.ligacoesAnaliticas.length) * 100);

      if (qtdeNaoComprou > 0) {
        this.pieChartLabels.push("Não Comprou (" + qtdeNaoComprou + ")");
        this.pieChartData.push(parseInt(porcentagemQtdeNaoComprou.toFixed(0)));
        this.pieChartColors[0].backgroundColor.push("rgba(220, 53, 69, 1)");
      }

      /* Sem Contato */

      let qtdeSemContato: number = this.ligacoesAnaliticas.filter(ligacoesAnaliticas => ligacoesAnaliticas.status_name == "Sem Contato").length;
      let porcentagemQtdeSemContato: number = ((qtdeSemContato / this.ligacoesAnaliticas.length) * 100);

      if (qtdeSemContato > 0) {
        this.pieChartLabels.push("Sem Contato (" + qtdeSemContato + ")");
        this.pieChartData.push(parseInt(porcentagemQtdeSemContato.toFixed(0)));
        this.pieChartColors[0].backgroundColor.push("rgba(108, 117, 125, 1)");
      }

      /* Retornar (Não Estava) */

      let qtdeRetornarNaoEstava: number = this.ligacoesAnaliticas.filter(ligacoesAnaliticas => ligacoesAnaliticas.status_name == "Retornar (Não Estava)").length;
      let porcentagemQtdeRetornarNaoEstava: number = ((qtdeRetornarNaoEstava / this.ligacoesAnaliticas.length) * 100);

      if (qtdeRetornarNaoEstava > 0) {
        this.pieChartLabels.push("Retornar (Não Estava) (" + qtdeRetornarNaoEstava + ")");
        this.pieChartData.push(parseInt(porcentagemQtdeRetornarNaoEstava.toFixed(0)));
        this.pieChartColors[0].backgroundColor.push("rgba(138, 0, 230, 1)");
      }

      /* Retornar (Proposta) */

      let qtdeRetornarProposta: number = this.ligacoesAnaliticas.filter(ligacoesAnaliticas => ligacoesAnaliticas.status_name == "Retornar (Proposta)").length;
      let porcentagemQtdeRetornarProposta: number = ((qtdeRetornarProposta / this.ligacoesAnaliticas.length) * 100);

      if (qtdeRetornarProposta > 0) {
        this.pieChartLabels.push("Retornar (Proposta) (" + qtdeRetornarProposta + ")");
        this.pieChartData.push(parseInt(porcentagemQtdeRetornarProposta.toFixed(0)));
        this.pieChartColors[0].backgroundColor.push("rgba(23, 162, 184, 1)");
      }

      /* Venda Realizada */

      let qtdeVendaRealizada: number = this.ligacoesAnaliticas.filter(ligacoesAnaliticas => ligacoesAnaliticas.status_name == "Venda Realizada").length;
      let porcentagemQtdeVendaRealizada: number = ((qtdeVendaRealizada / this.ligacoesAnaliticas.length) * 100);

      if (qtdeVendaRealizada > 0) {
        this.pieChartLabels.push("Venda Realizada (" + qtdeVendaRealizada + ")");
        this.pieChartData.push(parseInt(porcentagemQtdeVendaRealizada.toFixed(0)));
        this.pieChartColors[0].backgroundColor.push("rgba(40, 167, 69, 1)");
      }
    }
  }

  calcularDadosOportunidades() {
    /* Call Center (Oportunidades) */

    if (this.oportunidades.length > 0) {
      /* Meta do Mês */

      this.valorMetaMes = this.oportunidades.reduce((sum, current) => sum + current.falta_vender, 0);

      /* Acompanhamento */

      this.qtdeClientes = this.oportunidades.length;
      this.qtdeClientesPadrao = this.oportunidades.filter(oportunidades => oportunidades.origem == "CARTEIRA").length;
      this.qtdeClientesAdicionais = this.oportunidades.filter(oportunidades => oportunidades.origem == "ADICIONAL").length;

      /* Estatística (Clientes) */

      this.estatisticasClientes = [];

      /* Ativo */

      let qtdeAtivo = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Ativo").length;
      let porcentagemAtivo = 0;

      if (qtdeAtivo > 0) {
        porcentagemAtivo = ((qtdeAtivo / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("ativo", "Ativo", qtdeAtivo, null, qtdeAtivo, porcentagemAtivo));

      /* Ativo batido */

      let qtdeAtivoBatido = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Ativo batido").length;
      let porcentagemAtivoBatido = 0;

      if (qtdeAtivoBatido > 0) {
        porcentagemAtivoBatido = ((qtdeAtivoBatido / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("ativo_batido", "Ativo (Batido)", qtdeAtivoBatido, null, qtdeAtivoBatido, porcentagemAtivoBatido));

      /* Ativo Premium */

      let qtdeAtivoPremium = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Ativo Premium").length;
      let porcentagemAtivoPremium = 0;

      if (qtdeAtivoPremium > 0) {
        porcentagemAtivoPremium = ((qtdeAtivoPremium / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("ativo_premium", "Ativo (Premium)", qtdeAtivoPremium, null, qtdeAtivoPremium, porcentagemAtivoPremium));

      /* Inativo */

      let qtdeInativo = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Inativo").length;
      let porcentagemInativo = 0;

      if (qtdeInativo > 0) {
        porcentagemInativo = ((qtdeInativo / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("inativo", "Inativo", qtdeInativo, null, qtdeInativo, porcentagemInativo));

      /* Prospecção */

      let qtdeProspeccao = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Prospecção").length;
      let porcentagemProspeccao = 0;

      if (qtdeProspeccao > 0) {
        porcentagemProspeccao = ((qtdeProspeccao / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("prospeccao", "Prospecção", qtdeProspeccao, null, qtdeProspeccao, porcentagemProspeccao));

      this.estatisticasClientes.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a["qtde"], b["qtde"]) * -1; /* Decrescente */
      });
    }
  }

  carregarDadosLigacoes() {
    this.carregando = true;

    /* Call Center (Configurações) */

    this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
      callCenterConfiguracao => this.callCenterConfiguracao = callCenterConfiguracao,
      error => { console.log("Erro: " + error) },
      () => {
        /* Ordernar (Ligações) */

        if (this.callCenterConfiguracao != null && this.callCenterConfiguracao.mostra_classificacao) {
          let classificacao = this.ordernarLigacoesOpcoes.find(opcao => opcao.valor == "classificacao");

          if (classificacao == null) {
            this.ordernarLigacoesOpcoes.push(
              { texto: "Classificação", valor: "classificacao" }
            );
          }
        }

        this.ordernarLigacoesOrganizarOpcoes();

        /* Ordernar (Oportunidades) */

        if (this.callCenterConfiguracao != null && this.callCenterConfiguracao.mostra_classificacao) {
          let classificacao = this.ordernarOportunidadesOpcoes.find(opcao => opcao.valor == "classificacao");

          if (classificacao == null) {
            this.ordernarOportunidadesOpcoes.push(
              { texto: "Classificação", valor: "classificacao" }
            );
          }
        }

        this.ordernarOportunidadesOrganizarOpcoes();

        /* Call Center (Ligações) */

        /* Ligações Analíticas */

        let atencao: boolean = false;

        if (this.tabSelecionada == "atencao") {
          atencao = true;
        }

        this.callCenterService.getLigacaoByUsuario(atencao).subscribe(
          ligacoesAnaliticas => this.ligacoesAnaliticas = ligacoesAnaliticas,
          error => { console.log("Erro: " + error) },
          () => {
            this.ligacoesAnaliticas.forEach((item) => {
              if ((item.objetivo_venda - item.realizado_venda) < 0) {
                item.falta_vender = 0;
              } else {
                item.falta_vender = item.objetivo_venda - item.realizado_venda;
              }

              item.exibir = true;

              if (item.seller_id != null) {
                let vendedorAtual: Vendedor = this.vendedores.find(vendedor => vendedor.id == item.seller_id);

                if (vendedorAtual == null) {
                  let vendedor = new Vendedor();

                  vendedor.id = item.seller_id
                  vendedor.fullname = item.fullname;

                  this.vendedores.push(vendedor);
                }
              }
            });

            this.vendedores.sort((a, b) => {
              const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

              return compare(a["fullname"], b["fullname"]);
            });

            if (this.modelo_tela_ligacoes == 1) {
              /* Modelo 2 */

              this.ligacoesAnaliticas.sort((a, b) => {
                const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

                return compare(a["ult_visit"], b["ult_visit"]);
              });

              this.ordernarLigacoesSelecionado = "ult_visit";
            }

            this.ligacoesAnaliticasOriginal = JSON.parse(JSON.stringify(this.ligacoesAnaliticas));

            this.calcularDadosLigacoes();

            /* Visitas */

            this.visitaService.getVisitaByUsuario().subscribe(
              visitas => this.visitas = visitas,
              error => { console.log("Erro: " + error) },
              () => {
                this.verificarFiltro();

                this.carregando = false;
              }
            );
          }
        );
      }
    );
  }

  carregarDadosOportunidades() {
    if (this.oportunidadesClassificacoes.length == 0) {
      this.callCenterService.getOportunidadeClassificacaoByGuidClienteNapis().subscribe(
        oportunidadesClassificacoes => this.oportunidadesClassificacoes = oportunidadesClassificacoes,
        error => { console.log("Erro: " + error) },
        () => {

        }
      );
    }
  }

  reiniciarDados() {
    this.oportunidades = [];
  }

  /* Exportar */

  exportarOportunidades() {
    this.excelService.exportAsExcelFile(this.oportunidades, "Oportunidades");
  }

  /* Filtros */

  alterarFiltro(filtro_selecionado: string, carregando_filtro?: boolean) {
    this.reiniciarDados();

    this.calcularDadosOportunidades();

    let filtroAtual: string = this.formFiltro.controls["filtro"].value;

    this.formFiltro.reset();

    if (filtro_selecionado == null) {
      this.formFiltro.controls["filtro"].setValue(filtroAtual);

      this.apagarFiltroArmazenado();
    } else {
      this.formFiltro.controls["filtro"].setValue(filtro_selecionado);

      if (!carregando_filtro && filtro_selecionado != filtroAtual) {
        this.apagarFiltroArmazenado();
      }
    }

    this.organizarFiltro();

    this.enviado = false;
    this.filtrado = false;

    this.tagFiltroErro = false;

    /* Dados */

    /* Filtro (Produtos) */

    if (this.formFiltro.controls["filtro"].value == "6" && this.produtosOriginal.length == 0) {
      this.produtoService.getByGuidClienteNapis().subscribe(
        produtos => this.produtos = produtos,
        error => { console.log("Erro: " + error) },
        () => {
          this.produtosOriginal = JSON.parse(JSON.stringify(this.produtos));
        }
      );
    }

    this.produtoSelecionado = null;

    /* Filtro (Produtos) */

    this.enviadoProduto = false;
  }

  apagarFiltroArmazenado() {
    localStorage.removeItem("oportunidades_filtro");
    localStorage.removeItem("oportunidades_vendedor");

    /* Classificação */

    localStorage.removeItem("oportunidades_classificacao");

    /* Cliente */

    localStorage.removeItem("oportunidades_cliente");

    /* CNPJ/CPF */

    localStorage.removeItem("oportunidades_cnpj_cpf");

    /* Código */

    localStorage.removeItem("oportunidades_codigo");

    /* Dias sem Compra */

    localStorage.removeItem("oportunidades_nro_inicial_sem_compra");
    localStorage.removeItem("oportunidades_nro_final_sem_compra");

    /* Dias sem Visita */

    localStorage.removeItem("oportunidades_nro_inicial_sem_visita");
    localStorage.removeItem("oportunidades_nro_final_sem_visita");

    /* Realizado Ano */

    localStorage.removeItem("oportunidades_valor_inicial_realizado_ano");
    localStorage.removeItem("oportunidades_valor_final_realizado_ano");

    /* Realizado Mês */

    localStorage.removeItem("oportunidades_valor_inicial_realizado_mes");
    localStorage.removeItem("oportunidades_valor_final_realizado_mes");

    /* Segmento */

    localStorage.removeItem("oportunidades_segmento");

    /* Tags */

  }

  armazenarFiltro() {
    this.apagarFiltroArmazenado();

    localStorage.setItem("oportunidades_filtro", this.formFiltro.controls["filtro"].value);

    if (this.formFiltro.controls["filtro"].value == "0") {
      /* Classificação */

      localStorage.setItem("oportunidades_classificacao", this.formFiltro.controls["classificacao"].value);
    } else if (this.formFiltro.controls["filtro"].value == "1") {
      /* Cliente */

      localStorage.setItem("oportunidades_cliente", this.formFiltro.controls["cliente"].value);
    } else if (this.formFiltro.controls["filtro"].value == "2") {
      /* CNPJ/CPF */

      localStorage.setItem("oportunidades_cnpj_cpf", this.formFiltro.controls["cnpj_cpf"].value);
    } else if (this.formFiltro.controls["filtro"].value == "3") {
      /* Código */

      localStorage.setItem("oportunidades_codigo", this.formFiltro.controls["codigo"].value);
    } else if (this.formFiltro.controls["filtro"].value == "4") {
      /* Dias sem Compra */

      localStorage.setItem("oportunidades_nro_inicial_sem_compra", this.formFiltro.controls["nro_inicial_sem_compra"].value);

      if (this.formFiltro.controls["nro_inicial_sem_compra"].value != null && this.formFiltro.controls["nro_inicial_sem_compra"].value != "") {
        localStorage.setItem("oportunidades_nro_final_sem_compra", this.formFiltro.controls["nro_final_sem_compra"].value);
      }
    } else if (this.formFiltro.controls["filtro"].value == "5") {
      /* Dias sem Visita */

      localStorage.setItem("oportunidades_nro_inicial_sem_visita", this.formFiltro.controls["nro_inicial_sem_visita"].value);

      if (this.formFiltro.controls["oportunidades_nro_final_sem_visita"].value != null && this.formFiltro.controls["oportunidades_nro_final_sem_visita"].value != "") {
        localStorage.setItem("oportunidades_nro_final_sem_visita", this.formFiltro.controls["nro_final_sem_visita"].value);
      }
    } else if (this.formFiltro.controls["filtro"].value == "6") {
      /* Produto */

    } else if (this.formFiltro.controls["filtro"].value == "7") {
      /* Realizado Ano */

      localStorage.setItem("oportunidades_valor_inicial_realizado_ano", this.formFiltro.controls["valor_inicial_realizado_ano"].value);

      if (this.formFiltro.controls["oportunidades_valor_final_realizado_ano"].value != null && this.formFiltro.controls["oportunidades_valor_final_realizado_ano"].value != "") {
        localStorage.setItem("oportunidades_valor_final_realizado_ano", this.formFiltro.controls["valor_final_realizado_ano"].value);
      }
    } else if (this.formFiltro.controls["filtro"].value == "8") {
      /* Realizado Mês */

      localStorage.setItem("oportunidades_valor_inicial_realizado_mes", this.formFiltro.controls["valor_inicial_realizado_mes"].value);

      if (this.formFiltro.controls["oportunidades_valor_final_realizado_mes"].value != null && this.formFiltro.controls["oportunidades_valor_final_realizado_mes"].value != "") {
        localStorage.setItem("oportunidades_valor_final_realizado_mes", this.formFiltro.controls["valor_final_realizado_mes"].value);
      }
    } else if (this.formFiltro.controls["filtro"].value == "9") {
      /* Segmento */

      localStorage.setItem("oportunidades_segmento", this.formFiltro.controls["segmento"].value);
    } else if (this.formFiltro.controls["filtro"].value == "10") {
      /* Tags */

    }

    localStorage.setItem("oportunidades_vendedor", this.formFiltro.controls["vendedor"].value == null ? "" : this.formFiltro.controls["vendedor"].value);
  }

  filtrar() {
    this.enviado = true;

    if (this.formFiltro.valid) {
      /* Tags */

      if (this.formFiltro.controls["filtro"].value == "9") {
        if (this.tagFiltro.length == 0) {
          this.tagFiltroErro = true;

          return;
        } else {
          this.tagFiltroErro = false;
        }
      } else {
        this.tagFiltroErro = false;
      }

      let filtrar = new CallCenterOportunidadeFiltrar();

      if (this.formFiltro.controls["filtro"].value == "0") {
        /* Classificação */

        if (this.formFiltro.controls["classificacao"].value != "" && this.formFiltro.controls["classificacao"].value != null) {
          filtrar.classificacao = this.formFiltro.controls["classificacao"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "1") {
        /* Cliente */

        if (this.formFiltro.controls["cliente"].value != "" && this.formFiltro.controls["cliente"].value != null) {
          filtrar.cliente = this.formFiltro.controls["cliente"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "2") {
        /* CNPJ/CPF */

        if (this.formFiltro.controls["cnpj_cpf"].value != "" && this.formFiltro.controls["cnpj_cpf"].value != null) {
          let cnpj_cpf: string = this.formFiltro.controls["cnpj_cpf"].value.trim().split(".").join("").split("-").join("").split("/").join("");

          filtrar.cnpj_cpf = cnpj_cpf;
        }
      } else if (this.formFiltro.controls["filtro"].value == "3") {
        /* Código */

        if (this.formFiltro.controls["codigo"].value != "" && this.formFiltro.controls["codigo"].value != null) {
          filtrar.codigo = this.formFiltro.controls["codigo"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "4") {
        /* Dias sem Compra */

        filtrar.nro_inicial_sem_compra = this.formFiltro.controls["nro_inicial_sem_compra"].value;

        if (this.formFiltro.controls["nro_final_sem_compra"].value != "" && this.formFiltro.controls["nro_final_sem_compra"].value != null) {
          filtrar.nro_final_sem_compra = this.formFiltro.controls["nro_final_sem_compra"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "5") {
        /* Dias sem Visita */

        filtrar.nro_inicial_sem_visita = this.formFiltro.controls["nro_inicial_sem_visita"].value;

        if (this.formFiltro.controls["nro_final_sem_visita"].value != "" && this.formFiltro.controls["nro_final_sem_visita"].value != null) {
          filtrar.nro_final_sem_visita = this.formFiltro.controls["nro_final_sem_visita"].value
        }
      } else if (this.formFiltro.controls["filtro"].value == "6") {
        /* Produto */

        if (this.produtoSelecionado != null) {
          filtrar.id_produto = this.produtoSelecionado.id;
        }
      } else if (this.formFiltro.controls["filtro"].value == "7") {
        /* Realizado Ano */

        filtrar.valor_inicial_realizado_ano = this.formFiltro.controls["valor_inicial_realizado_ano"].value;

        if (this.formFiltro.controls["valor_final_realizado_ano"].value != "" && this.formFiltro.controls["valor_final_realizado_ano"].value != null) {
          filtrar.valor_final_realizado_ano = this.formFiltro.controls["valor_final_realizado_ano"].value
        }
      } else if (this.formFiltro.controls["filtro"].value == "8") {
        /* Realizado Mês */

        filtrar.valor_inicial_realizado_mes = this.formFiltro.controls["valor_inicial_realizado_mes"].value;

        if (this.formFiltro.controls["valor_final_realizado_mes"].value != "" && this.formFiltro.controls["valor_final_realizado_mes"].value != null) {
          filtrar.valor_final_realizado_mes = this.formFiltro.controls["valor_final_realizado_mes"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "9") {
        /* Segmento */

        if (this.formFiltro.controls["segmento"].value != "" && this.formFiltro.controls["segmento"].value != null) {
          filtrar.segmento = this.formFiltro.controls["segmento"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "10") {
        /* Tags */

        this.tagFiltro.forEach((item) => {
          filtrar.tags.push(item.tagValue.toLowerCase());
        });
      }

      if (this.formFiltro.controls["vendedor"].value != "" && this.formFiltro.controls["vendedor"].value != null) {
        /* Vendedor(a) */

        filtrar.vendedor = this.formFiltro.controls["vendedor"].value;
      }

      this.oportunidades = [];

      this.callCenterService.getOportunidadeByUsuario(filtrar).subscribe(
        oportunidades => this.oportunidades = oportunidades,
        error => { console.log("Erro: " + error) },
        () => {
          this.filtrado = true;

          this.oportunidades.forEach((item) => {
            if ((item.objetivo_de_venda - item.order_total) < 0) {
              item.falta_vender = 0;
            } else {
              item.falta_vender = item.objetivo_de_venda - item.order_total;
            }
          });

          if (this.modelo_tela_ligacoes == 1) {
            /* Modelo 2 */

            this.oportunidades.sort((a, b) => {
              const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

              return compare(a["ultvisita"], b["ultvisita"]);
            });

            this.ordernarOportunidadesSelecionado = "ultvisita";
          }

          this.calcularDadosOportunidades();
          this.armazenarFiltro();

          if (this.oportunidades.length == 0) {
            this.toastr.error("", "Nenhuma oportunidade encontrada!");
          }
        }
      );
    }
  }

  organizarFiltro() {
    if (this.formFiltro.controls["filtro"].value == "0") {
      /* Classificação */

      this.formFiltro.get("classificacao").enable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "1") {
      /* Cliente */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").enable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "2") {
      /* CNPJ/CPF */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").enable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "3") {
      /* Código */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").enable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "4") {
      /* Dias sem Compra */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").enable();
      this.formFiltro.get("nro_final_sem_compra").enable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "5") {
      /* Dias sem Visita */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").enable();
      this.formFiltro.get("nro_final_sem_visita").enable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "6") {
      /* Produto */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "7") {
      /* Realizado Ano */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").enable();
      this.formFiltro.get("valor_final_realizado_ano").enable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "8") {
      /* Realizado Mês */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").enable();
      this.formFiltro.get("valor_final_realizado_mes").enable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "9") {
      /* Segmento */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").enable();
    } else if (this.formFiltro.controls["filtro"].value == "10") {
      /* Tags */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    }

    this.tagFiltro = [];
  }

  verificarFiltro() {
    if (localStorage.getItem("oportunidades_filtro") != null) {
      if (localStorage.getItem("oportunidades_filtro") != null) {
        let filtro: string = localStorage.getItem("oportunidades_filtro");

        this.alterarFiltro(filtro, true);

        if (this.formFiltro.controls["filtro"].value == "0") {
          /* Classificação */

          if (localStorage.getItem("oportunidades_classificacao") != null) {
            this.formFiltro.controls["classificacao"].setValue(localStorage.getItem("oportunidades_classificacao"));
          }
        } else if (this.formFiltro.controls["filtro"].value == "1") {
          /* Cliente */

          if (localStorage.getItem("oportunidades_cliente") != null) {
            this.formFiltro.controls["cliente"].setValue(localStorage.getItem("oportunidades_cliente"));
          }
        } else if (this.formFiltro.controls["filtro"].value == "2") {
          /* CNPJ/CPF */

          if (localStorage.getItem("oportunidades_cnpj_cpf") != null) {
            this.formFiltro.controls["cnpj_cpf"].setValue(localStorage.getItem("oportunidades_cnpj_cpf"));
          }
        } else if (this.formFiltro.controls["filtro"].value == "3") {
          /* Código */

          if (localStorage.getItem("oportunidades_codigo") != null) {
            this.formFiltro.controls["codigo"].setValue(localStorage.getItem("oportunidades_codigo"));
          }
        } else if (this.formFiltro.controls["filtro"].value == "4") {
          /* Dias sem Compra */

          if (localStorage.getItem("oportunidades_nro_inicial_sem_compra") != null) {
            this.formFiltro.controls["nro_inicial_sem_compra"].setValue(localStorage.getItem("oportunidades_nro_inicial_sem_compra"));
          }

          if (localStorage.getItem("oportunidades_nro_final_sem_compra") != null) {
            this.formFiltro.controls["nro_final_sem_compra"].setValue(localStorage.getItem("oportunidades_nro_final_sem_compra"));
          }
        } else if (this.formFiltro.controls["filtro"].value == "5") {
          /* Dias sem Visita */

          if (localStorage.getItem("oportunidades_nro_inicial_sem_visita") != null) {
            this.formFiltro.controls["nro_inicial_sem_visita"].setValue(localStorage.getItem("oportunidades_nro_inicial_sem_visita"));
          }

          if (localStorage.getItem("oportunidades_nro_final_sem_visita") != null) {
            this.formFiltro.controls["nro_final_sem_visita"].setValue(localStorage.getItem("oportunidades_nro_final_sem_visita"));
          }
        } else if (this.formFiltro.controls["filtro"].value == "6") {
          /* Realizado Ano */

          if (localStorage.getItem("oportunidades_valor_inicial_realizado_ano") != null) {
            this.formFiltro.controls["valor_inicial_realizado_ano"].setValue(localStorage.getItem("oportunidades_valor_inicial_realizado_ano"));
          }

          if (localStorage.getItem("oportunidades_valor_final_realizado_ano") != null) {
            this.formFiltro.controls["valor_final_realizado_ano"].setValue(localStorage.getItem("oportunidades_valor_final_realizado_ano"));
          }
        } else if (this.formFiltro.controls["filtro"].value == "7") {
          /* Realizado Mês */

          if (localStorage.getItem("oportunidades_valor_inicial_realizado_mes") != null) {
            this.formFiltro.controls["valor_inicial_realizado_mes"].setValue(localStorage.getItem("oportunidades_valor_inicial_realizado_mes"));
          }

          if (localStorage.getItem("oportunidades_valor_final_realizado_mes") != null) {
            this.formFiltro.controls["valor_final_realizado_mes"].setValue(localStorage.getItem("oportunidades_valor_final_realizado_mes"));
          }
        } else if (this.formFiltro.controls["filtro"].value == "8") {
          /* Segmento */

          if (localStorage.getItem("oportunidades_segmento") != null) {
            this.formFiltro.controls["segmento"].setValue(localStorage.getItem("oportunidades_segmento"));
          }
        } else if (this.formFiltro.controls["filtro"].value == "9") {
          /* Tags */

        }

        if (localStorage.getItem("oportunidades_vendedor") != "") {
          this.formFiltro.controls["vendedor"].setValue(localStorage.getItem("oportunidades_vendedor"));
        }

        this.tabSelecionada = "oportunidades";

        this.filtrar();
      }

      this.carregarDadosOportunidades();
    }
  }

  /* Filtro (Ligações) */

  filtrarLigacoes() {
    if (this.vendedorLigacoesSelecionado != null) {
      this.ligacoesAnaliticas.forEach((item) => {
        if (item.seller_id == this.vendedorLigacoesSelecionado.id) {
          item.exibir = true;
        } else {
          item.exibir = false;
        }
      });
    } else {
      this.ligacoesAnaliticas.forEach((item) => {
        item.exibir = true;
      });
    }
  }

  selecionarVendedorLigacoes(id_vendedor: string) {
    if (id_vendedor != "0") {
      this.vendedorLigacoesSelecionado = this.vendedores.find(vendedores => vendedores.id == parseInt(id_vendedor));
    } else {
      this.vendedorLigacoesSelecionado = null;
    }

    this.filtrarLigacoes();
  }

  /* Filtro (Produtos) */

  alterarFiltroProduto(filtro_selecionado: number) {
    this.reiniciarDadosProduto();

    let filtroAtual: string = this.formFiltroProduto.controls["filtro"].value;

    this.formFiltroProduto.reset();

    if (filtro_selecionado == null) {
      this.formFiltroProduto.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltroProduto.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltroProduto();

    this.enviadoProduto = false;
    this.filtrandoProduto = false;
  }

  filtrarProduto() {
    this.enviadoProduto = true;

    if (this.formFiltroProduto.invalid) {
      return;
    }

    this.reiniciarDadosProduto();

    if (this.formFiltroProduto.controls["filtro"].value == "0") {
      /* Código */

      if (this.formFiltroProduto.controls["codigo"].value != "" && this.formFiltroProduto.controls["codigo"].value != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.codigo == null) {
            return false;
          } else {
            return (produto.codigo.toLowerCase().indexOf(this.formFiltroProduto.controls["codigo"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
      /* Descrição */

      if (this.formFiltroProduto.controls["descricao"].value != "" && this.formFiltroProduto.controls["descricao"].value != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.name == null) {
            return false;
          } else {
            return (produto.name.toLowerCase().indexOf(this.formFiltroProduto.controls["descricao"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
      /* Marca */

      if (this.formFiltroProduto.controls["marca"].value != "" && this.formFiltroProduto.controls["marca"].value != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.grupo_estoque == null) {
            return false;
          } else {
            return (produto.grupo_estoque.toLowerCase().indexOf(this.formFiltroProduto.controls["marca"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    }

    this.filtrandoProduto = true;
  }

  organizarFiltroProduto() {
    if (this.formFiltroProduto.controls["filtro"].value == "0") {
      /* Código */

      this.formFiltroProduto.get("codigo").enable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
      /* Descrição */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").enable();
      this.formFiltroProduto.get("marca").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
      /* Marca */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").enable();
    }
  }

  reiniciarDadosProduto() {
    this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
  }

  selecionarProdutoFiltrado(id_produto: number) {
    let produtoSelecionado = this.produtos.find(produtos => produtos.id == id_produto);

    if (produtoSelecionado != null) {
      this.produtoSelecionado = produtoSelecionado;

      if (this.produtoSelecionado.qtde_lote == 0) {
        this.produtoSelecionado.valor = this.produtoSelecionado.price;
      } else {
        this.produtoSelecionado.valor = null;
      }

      this.alterarFiltroProduto(null);

      this.produtoEmBranco = false;
      this.produtoInvalido = false;
      this.filtrandoProduto = false;

      this.filtrar();
    }
  }

  /* Modal */

  modalAnaliseSintetica(content: any) {
    /* Pedidos (Totalizador) */

    /* Este Mês */

    this.pedidoTotalizadorService.getEsteMesByUsuario().subscribe(
      pedidoTotalizadorEsteMes => this.valorVendasMes = pedidoTotalizadorEsteMes,
      error => { console.log("Erro: " + error) },
      () => {
        /* Hoje */

        this.pedidoTotalizadorService.getHojeByUsuario().subscribe(
          pedidoTotalizadorHoje => this.valorVendasDia = pedidoTotalizadorHoje,
          error => { console.log("Erro: " + error) },
          () => {
            this.calcularDadosAnaliseSintetica();

            this.modalService.open(content, {
              ariaLabelledBy: "titulo-analise-sintetica",
              size: "xl",
              backdrop: "static"
            });
          }
        );
      }
    );
  }

  modalComCheckOutPendente(content: any, id_cliente: number, ligacao_selecionada: number) {
    let hoje = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    let visitasComCheckOutPendente = this.visitas.filter(visita => {
      let data = this.datePipe.transform(visita.check_in, "yyyy/MM/dd");

      return data == this.datePipe.transform(hoje, "yyyy/MM/dd") && visita.check_out == null && visita.id_call_center_ligacao != null;
    });

    if (visitasComCheckOutPendente.length > 0) {
      if (ligacao_selecionada == visitasComCheckOutPendente[0].id_call_center_ligacao) {
        /* Storage */

        let chamadas_automaticas: boolean = (localStorage.getItem("chamadas_automaticas") == "true");

        if (chamadas_automaticas) {
          localStorage.setItem("discador_ativo", "true");
        }

        this.router.navigate(["/call-center-ligacao/" + visitasComCheckOutPendente[0].id_call_center_ligacao], {
          queryParams: {
            visita: visitasComCheckOutPendente[0].id
          }
        });
      } else {
        this.modalService.open(content, {
          ariaLabelledBy: "titulo-com-checkout-pendente",
          size: "lg",
          backdrop: "static"
        }).result.then((acao) => {
          if (acao == "Sim") {
            /* Storage */

            let chamadas_automaticas: boolean = (localStorage.getItem("chamadas_automaticas") == "true");

            if (chamadas_automaticas) {
              localStorage.setItem("discador_ativo", "true");
            }

            this.router.navigate(["/call-center-ligacao/" + visitasComCheckOutPendente[0].id_call_center_ligacao], {
              queryParams: {
                visita: visitasComCheckOutPendente[0].id
              }
            });
          }
        }, () => {

        });
      }
    } else {
      if (ligacao_selecionada != null) {
        this.router.navigate(["/call-center-ligacao/" + ligacao_selecionada]);
      } else {
        /* Storage */

        let chamadas_automaticas: boolean = (localStorage.getItem("chamadas_automaticas") == "true");

        if (chamadas_automaticas) {
          this.callCenterDiscadorService.chamadasReiniciarTempo();
        }

        if (id_cliente != null) {
          this.router.navigate(["/call-center-nova-ligacao"], {
            queryParams: {
              cliente: id_cliente
            }
          });
        } else {
          this.router.navigate(["/call-center-nova-ligacao/"]);
        }
      }
    }
  }

  modalLigacaoMensagem(content: any, mensagem: string) {
    this.ligacaoAnaliticaMensagem = mensagem;

    this.modalService.open(content, {
      ariaLabelledBy: "titulo-ligacao-mensagem",
      size: "lg",
      backdrop: "static"
    });
  }

  modalTagsClientes(content: any, origem: string, id_cliente: number) {
    this.tagCliente = [];

    this.tagClienteIdSelecionado = 0;
    this.tagClienteNomeSelecionado = "";

    switch (origem) {
      case "ligacoes":
        let ligacaoSelecionada = this.ligacoesAnaliticas.find(ligacaoAnalitica => ligacaoAnalitica.client_id == id_cliente);

        if (ligacaoSelecionada != null) {
          ligacaoSelecionada.cliente_tags.forEach((item) => {
            this.tagCliente.push({
              tagLabel: item.descricao,
              tagValue: item.descricao
            });
          });

          this.tagClienteIdSelecionado = ligacaoSelecionada.client_id;
          this.tagClienteNomeSelecionado = ligacaoSelecionada.name;
        }

        break;
      case "oportunidades":
        let oportunidadeSelecionada = this.oportunidades.find(oportunidade => oportunidade.client_id_app == id_cliente);

        if (oportunidadeSelecionada != null) {
          oportunidadeSelecionada.cliente_tags.forEach((item) => {
            this.tagCliente.push({
              tagLabel: item.descricao,
              tagValue: item.descricao
            });
          });

          this.tagClienteIdSelecionado = oportunidadeSelecionada.client_id_app;
          this.tagClienteNomeSelecionado = oportunidadeSelecionada.name;
        }

        break;
      default:

    }

    if (this.tagClienteIdSelecionado != 0) {
      this.modalService.open(content, {
        ariaLabelledBy: "titulo-tags-clientes",
        size: "lg",
        backdrop: "static"
      }).result.then((acao) => {
        if (acao == "Salvar") {
          let tags: string[] = [];

          this.tagCliente.forEach((item) => {
            tags.push(item.tagValue);
          });

          let adicionarTag: boolean = false;

          this.clienteService.addTag(this.tagClienteIdSelecionado, tags).subscribe(
            adicionarTags => adicionarTag = adicionarTags,
            error => { console.log("Erro: " + error) },
            () => {
              if (adicionarTag) {
                /* Atualiza Dados */

                switch (origem) {
                  case "ligacoes":
                    let ligacaoAnaliticaSelecionada: CallCenterLigacaoAnalitica = this.ligacoesAnaliticas.find(ligacaoAnalitica => ligacaoAnalitica.client_id == id_cliente);

                    if (ligacaoAnaliticaSelecionada != null) {
                      ligacaoAnaliticaSelecionada.cliente_tags = [];

                      tags.forEach((item) => {
                        let tag = new ClienteTag();

                        tag.client_id_app = id_cliente;
                        tag.descricao = item;

                        ligacaoAnaliticaSelecionada.cliente_tags.push(tag);
                      });

                      let ligacaoAnaliticaOriginalSelecionada = this.ligacoesAnaliticasOriginal.find(ligacaoAnaliticaOriginal => ligacaoAnaliticaOriginal.id == ligacaoAnaliticaSelecionada.id);

                      if (ligacaoAnaliticaOriginalSelecionada != null) {
                        ligacaoAnaliticaOriginalSelecionada.cliente_tags = ligacaoAnaliticaSelecionada.cliente_tags;
                      }
                    }

                    break;
                  case "oportunidades":
                    let oportunidadeSelecionada: CallCenterOportunidade = this.oportunidades.find(oportunidade => oportunidade.client_id_app == id_cliente);

                    if (oportunidadeSelecionada != null) {
                      oportunidadeSelecionada.cliente_tags = [];

                      tags.forEach((item) => {
                        let tag = new ClienteTag();

                        tag.client_id_app = id_cliente;
                        tag.descricao = item;

                        oportunidadeSelecionada.cliente_tags.push(tag);
                      });
                    }

                    break;
                  default:
                }

                this.toastr.success("", "Tag(s) salva(s) com sucesso!");
              }
            }
          );
        }
      }, () => {

      });
    }
  }

  /* Ordernar (Ligações) */

  ordernarLigacoes(opcao_selecionada: string) {
    this.ordernarLigacoesSelecionado = opcao_selecionada;

    switch (this.ordernarLigacoesOrdemSelecionado) {
      case "0":
        /* Crescente */

        this.ligacoesAnaliticasOriginal.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a[this.ordernarLigacoesSelecionado], b[this.ordernarLigacoesSelecionado]);
        });

        break;
      case "1":
        /* Decrescente */

        this.ligacoesAnaliticasOriginal.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a[this.ordernarLigacoesSelecionado], b[this.ordernarLigacoesSelecionado]) * -1; /* Decrescente */
        });

        break;
      default:

    }

    this.ligacoesAnaliticas = JSON.parse(JSON.stringify(this.ligacoesAnaliticasOriginal));
  }

  ordernarLigacoesOrdem(opcao_selecionada: string) {
    this.ordernarLigacoesOrdemSelecionado = opcao_selecionada;

    this.ordernarLigacoes(this.ordernarLigacoesSelecionado);
  }

  ordernarLigacoesOrganizarOpcoes() {
    this.ordernarLigacoesOpcoes.sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a["texto"], b["texto"]);
    });
  }

  /* Ordernar (Oportunidades) */

  ordernarOportunidades(opcao_selecionada: string) {
    let oportunidadesAnterior = this.oportunidades;

    this.ordernarOportunidadesSelecionado = opcao_selecionada;

    switch (this.ordernarOportunidadesOrdemSelecionado) {
      case "0":
        /* Crescente */

        oportunidadesAnterior.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a[this.ordernarOportunidadesSelecionado], b[this.ordernarOportunidadesSelecionado]);
        });

        break;
      case "1":
        /* Decrescente */

        oportunidadesAnterior.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a[this.ordernarOportunidadesSelecionado], b[this.ordernarOportunidadesSelecionado]) * -1; /* Decrescente */
        });

        break;
      default:

    }

    this.oportunidades = JSON.parse(JSON.stringify(oportunidadesAnterior));
  }

  ordernarOportunidadesOrdem(opcao_selecionada: string) {
    this.ordernarOportunidadesOrdemSelecionado = opcao_selecionada;

    this.ordernarOportunidades(this.ordernarOportunidadesSelecionado);
  }

  ordernarOportunidadesOrganizarOpcoes() {
    this.ordernarOportunidadesOpcoes.sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a["texto"], b["texto"]);
    });
  }
}
