import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ProdutoService } from '../../core/services/produtos/produto.service';

/* Models */

import { Produto } from '../../core/models/produtos/produto';
import { ProdutoCampanha } from '../../core/models/produtos/produto-campanha';
import { ProdutoCampanhaItens } from '../../core/models/produtos/produto-campanha-itens';
import { ProdutoTabelaEspecialItens } from '../../core/models/produtos/produto-tabela-especial-itens';
import { ProdutoTabelaEspecial } from '../../core/models/produtos/produto-tabela-especial';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ProdutosComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Abas */

  tabSelecionada: string = "produtos";

  /* Filtro (Produtos) */

  formFiltroProduto: FormGroup;

  filtroProdutos: any[] = [
    { texto: "Código", valor: "0" },
    { texto: "Descrição", valor: "1" },
    { texto: "Marca", valor: "2" }
  ];

  enviadoProduto: boolean = false;

  filtrandoProduto: boolean = false;

  /* Produtos */

  produtos: Produto[] = [];
  produtosOriginal: Produto[] = [];

  /* Produto (Campanha Itens) */

  produtosCampanhaItens: ProdutoCampanhaItens[] = [];

  /* Produto (Campanha) */

  produtosCampanha: ProdutoCampanha[] = [];

  produtoCampanhaExpandido: number = -1;

  /* Produto (Tabela Especial (Itens)) */

  produtosTabelaEspecialItens: ProdutoTabelaEspecialItens[] = [];

  /* Produto (Tabela Especial) */

  produtosTabelaEspecial: ProdutoTabelaEspecial[] = [];

  produtoTabelaEspecialExpandido: number = -1;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private produtoService: ProdutoService
  ) { }

  ngOnInit() {
    /* Filtro (Produtos) */

    this.formFiltroProduto = this.formBuilder.group({
      filtro: ["1"],
      codigo: ["", Validators.required],
      descricao: ["", Validators.required],
      marca: ["", Validators.required]
    });

    this.organizarFiltroProduto();

    this.carregarDados(true);
  }

  get fp() {
    return this.formFiltroProduto.controls;
  }

  /* Abas */

  alterarAbas(evento: any) {
    this.tabSelecionada = evento.nextId;
  }

  /* Ações */

  alterarProduto(id: number) {
    this.router.navigate(["/produtos-cadastro/" + id]);
  }

  /* Dados */

  carregarDados(inicial: boolean) {
    this.carregando = true;

    this.produtoService.getByGuidClienteNapis().subscribe(
      produtos => this.produtos = produtos,
      error => { console.log("Erro: " + error) },
      () => {
        this.produtosOriginal = JSON.parse(JSON.stringify(this.produtos));

        /* Produto (Campanha) */

        this.produtoService.getProdutoCampanhaByGuidClienteNapis().subscribe(
          produtosCampanha => this.produtosCampanha = produtosCampanha,
          error => { console.log("Erro: " + error) },
          () => {
            /* Produto (Tabela Especial) */

            this.produtoService.getProdutoTabelaEspecialByGuidClienteNapis().subscribe(
              produtosTabelaEspecial => this.produtosTabelaEspecial = produtosTabelaEspecial,
              error => { console.log("Erro: " + error) },
              () => {
                this.filtrarProduto(inicial);

                this.carregando = false;
              }
            );
          }
        );
      }
    );
  }

  /* Filtro (Produtos) */

  alterarFiltroProduto(filtro_selecionado: number) {
    this.reiniciarDadosProduto();

    let filtroAtual: string = this.formFiltroProduto.controls["filtro"].value;

    this.formFiltroProduto.reset();

    if (filtro_selecionado == null) {
      this.formFiltroProduto.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltroProduto.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltroProduto();

    this.enviadoProduto = false;
    this.filtrandoProduto = false;
  }

  filtrarProduto(inicial: boolean) {
    if (!inicial) {
      this.enviadoProduto = true;

      if (this.formFiltroProduto.invalid) {
        return;
      }

      this.reiniciarDadosProduto();

      if (this.formFiltroProduto.controls["filtro"].value == "0") {
        /* Código */

        if (this.formFiltroProduto.controls["codigo"].value != "" && this.formFiltroProduto.controls["codigo"].value != null) {
          this.produtos = this.produtos.filter(produto => {
            if (produto.codigo == null) {
              return false;
            } else {
              return (produto.codigo.toLowerCase().indexOf(this.formFiltroProduto.controls["codigo"].value.trim().toLowerCase()) !== -1);
            }
          });
        }
      } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
        /* Descrição */

        if (this.formFiltroProduto.controls["descricao"].value != "" && this.formFiltroProduto.controls["descricao"].value != null) {
          this.produtos = this.produtos.filter(produto => {
            if (produto.name == null) {
              return false;
            } else {
              return (produto.name.toLowerCase().indexOf(this.formFiltroProduto.controls["descricao"].value.trim().toLowerCase()) !== -1);
            }
          });
        }
      } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
        /* Marca */

        if (this.formFiltroProduto.controls["marca"].value != "" && this.formFiltroProduto.controls["marca"].value != null) {
          this.produtos = this.produtos.filter(produto => {
            if (produto.grupo_estoque == null) {
              return false;
            } else {
              return (produto.grupo_estoque.toLowerCase().indexOf(this.formFiltroProduto.controls["marca"].value.trim().toLowerCase()) !== -1);
            }
          });
        }
      }

      this.filtrandoProduto = true;
    }
  }

  organizarFiltroProduto() {
    if (this.formFiltroProduto.controls["filtro"].value == "0") {
      /* Código */

      this.formFiltroProduto.get("codigo").enable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
      /* Descrição */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").enable();
      this.formFiltroProduto.get("marca").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
      /* Marca */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").enable();
    }
  }

  reiniciarDadosProduto() {
    this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
  }

  /* Portal Compras */

  definirPromocao(id: number, definir: boolean) {
    let alterarPromocao: boolean = false;

    this.produtoService.updatePromocaoById(id, definir).subscribe(
      alterarPromocoes => alterarPromocao = alterarPromocoes,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarPromocao) {
          this.carregarDados(!this.enviadoProduto);

          if (definir) {
            this.toastr.success("", "Produto definido como promoção!");
          } else {
            this.toastr.success("", "Produto removido como promoção!");
          }
        }
      }
    );
  }

  liberarPortalCompras(id: number, liberar: boolean) {
    let alterarExibicao: boolean = false;

    this.produtoService.updateMostrarB2BById(id, liberar).subscribe(
      alterarExibicoes => alterarExibicao = alterarExibicoes,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarExibicao) {
          this.carregarDados(!this.enviadoProduto);

          if (liberar) {
            this.toastr.success("", "Produto liberado!");
          } else {
            this.toastr.success("", "Produto bloqueado!");
          }
        }
      }
    );
  }

  /* Produto (Campanha) */

  alterarProdutoCampanha(id_campanha: number) {
    this.router.navigate(["/produtos-campanhas-cadastro/" + id_campanha]);
  }

  expandirProdutoCampanha(i: number, id_campanha: number) {
    this.produtoCampanhaExpandido = i == this.produtoCampanhaExpandido ? -1 : i;

    if (this.produtoCampanhaExpandido > -1) {
      this.produtoService.getProdutoCampanhaItensByIdCampanha(id_campanha).subscribe(
        produtosCampanhaItens => this.produtosCampanhaItens = produtosCampanhaItens,
        error => { console.log("Erro: " + error) },
        () => {

        }
      );
    }
  }

  novoProdutoCampanha() {
    this.router.navigate(["/produtos-campanhas-cadastro"]);
  }

  /* Produto (Tabela Especial) */

  alterarProdutoTabelaEspecial(id_produto_tabela_especial: number) {
    this.router.navigate(["/produtos-promocoes-cadastro/" + id_produto_tabela_especial]);
  }

  expandirProdutoTabelaEspecial(i: number, id_produto_tabela_especial: number) {
    this.produtoTabelaEspecialExpandido = i == this.produtoTabelaEspecialExpandido ? -1 : i;

    if (this.produtoTabelaEspecialExpandido > -1) {
      this.produtoService.getProdutoTabelaEspecialItensByIdProdutoTabelaEspecial(id_produto_tabela_especial).subscribe(
        produtosTabelaEspecialItens => this.produtosTabelaEspecialItens = produtosTabelaEspecialItens,
        error => { console.log("Erro: " + error) },
        () => {

        }
      );
    }
  }

  novoProdutoTabelaEspecial() {
    this.router.navigate(["/produtos-promocoes-cadastro"]);
  }
}
