import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-vendedores-cadastro',
  templateUrl: './vendedores-cadastro.component.html',
  styleUrls: ['./vendedores-cadastro.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class VendedoresCadastroComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Dados */

    /* Gerente */

    gerentes: Vendedor[] = [];

    gerenteInvalido: boolean = false;

    gerenteSelecionado: Vendedor = null;

    /* Telemarketing */

    telemarketings: Vendedor[] = [];

    telemarketingInvalido: boolean = false;

    telemarketingSelecionado: Vendedor = null;

    /* Vendedor(a) */

    vendedor: Vendedor = null;

    vendedorAtivo: boolean = true;

  /* Formulário */

  formDados: FormGroup;

  enviado: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private vendedorService: VendedorService
  ) { }

  ngOnInit() {
    this.formDados = this.formBuilder.group({
      nome: ["", Validators.required],
      email: ["", Validators.required],
      documento: ["", Validators.required],
      codigo_regiao: [""],
      codigo_vendedor: [""]
    });

    this.carregarDados();
  }

  get f() {
    return this.formDados.controls;
  }

  /* Dados */

  addUpdate() {
    let retorno = new Promise<void>((resolver) => {
      let vendedor = new Vendedor();

      vendedor.fullname = String(this.formDados.controls["nome"].value).toUpperCase();
      vendedor.email = String(this.formDados.controls["email"].value).toLowerCase();
      vendedor.is_active = this.vendedorAtivo;
      vendedor.manager_id = this.gerenteSelecionado.id;
      vendedor.seller_document = this.formDados.controls["documento"].value;
      vendedor.codigo_no_erp_do_cliente = this.formDados.controls["codigo_regiao"].value;
      vendedor.senha_no_erp_do_cliente = this.formDados.controls["codigo_vendedor"].value;
      vendedor.nome_equipe = this.telemarketingSelecionado.fullname;
      vendedor.seller_id_tmk = this.telemarketingSelecionado.id;

      if (this.vendedor != null) {
        /* Alterando */

        vendedor.id = this.vendedor.id;

        let alterarVendedor: boolean = false;

        this.vendedorService.update(vendedor).subscribe(
          alterarVendedores => alterarVendedor = alterarVendedores,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarVendedor) {
              this.toastr.success("", "Vendedor(a) salvo(a) com sucesso!");
            }

            return resolver();
          }
        );
      } else {
        /* Adicionando */

        return resolver();
      }
    });

    return retorno;
  }

  carregarDados() {
    this.carregando = true;

    /* Gerentes */

    this.vendedorService.getMasterManagerByGuidClienteNapis().subscribe(
      gerentes => this.gerentes = gerentes,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.gerentes.length > 0) {
          this.gerenteSelecionado = this.gerentes[0];
        }

        /* Telemarketings */

        this.vendedorService.getMasterSellerByGuidClienteNapis().subscribe(
          telemarketings => this.telemarketings = telemarketings,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.telemarketings.length > 0) {
              this.telemarketingSelecionado = this.telemarketings[0];
            }

            this.verificarAdicionandoAlterando();
          }
        );
      }
    );
  }

  prepararReceberDados(id: number) {
    let retorno = new Promise<void>((resolver) => {
      this.vendedorService.getVendedorById(id).subscribe(
        vendedor => this.vendedor = vendedor,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.vendedor != null) {
            this.formDados.controls["nome"].setValue(this.vendedor.fullname);
            this.formDados.controls["email"].setValue(this.vendedor.email);
            this.formDados.controls["documento"].setValue(this.vendedor.seller_document);
            this.formDados.controls["codigo_regiao"].setValue(this.vendedor.codigo_no_erp_do_cliente);
            this.formDados.controls["codigo_vendedor"].setValue(this.vendedor.senha_no_erp_do_cliente);

            /* Gerente */

            this.gerenteSelecionado = this.gerentes.find(gerentes => gerentes.id == this.vendedor.manager_id);

            /* Telemarketing */

            this.telemarketingSelecionado = this.telemarketings.find(telemarketings => telemarketings.id == this.vendedor.seller_id_tmk);

            this.vendedorAtivo = this.vendedor.is_active;

            return resolver();
          } else {
            this.toastr.error("", "Vendedor(a) inválido(a)!");

            this.router.navigate(["/vendedores/"]);
          }
        }
      );
    });

    return retorno;
  }

  salvar() {
    this.enviado = true;

    if (this.formDados.invalid) {
      return;
    }

    /* Gerente */

    if (this.gerenteSelecionado == null) {
      this.gerenteInvalido = true;
    } else {
      this.gerenteInvalido = false;
    }

    /* Telemarketing */

    if (this.telemarketingSelecionado == null) {
      this.telemarketingInvalido = true;
    } else {
      this.telemarketingInvalido = false;
    }

    if (!this.gerenteInvalido && !this.telemarketingInvalido) {
      const addUpdate = this.addUpdate();

      addUpdate.then(() => {
        this.router.navigate(["/vendedores/"]);
      });
    }
  }

  verificarAdicionandoAlterando() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    let id: string = null;

    this.route.params.subscribe(params => { id = params.id; });

    if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
      /* Alterando */

      const prepararReceberDados = this.prepararReceberDados(parseInt(id));

      prepararReceberDados.then(() => {
        this.carregando = false;
      });
    } else {
      /* Adicionando */

      this.carregando = false;

      this.router.navigate(["/vendedores/"]);
    }
  }

  /* Gerente */

  selecionarGerente(id_gerente: string) {
    this.gerenteSelecionado = this.gerentes.find(gerentes => gerentes.id == parseInt(id_gerente));
  }

  /* Telemarketing */

  selecionarTelemarketing(id_telemarketing: string) {
    this.telemarketingSelecionado = this.telemarketings.find(telemarketings => telemarketings.id == parseInt(id_telemarketing));
  }

  /* Vendedor(a) */

  selecionarAtivo(ativo: string) {
    this.vendedorAtivo = ativo == "0" ? false : true;
  }
}
