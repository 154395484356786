import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { OrcamentoSintetico } from '../../models/orcamentos/orcamento-sintetico';

@Injectable({
  providedIn: 'root'
})

export class OrcamentoService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Sintético */

  public getByUsuario(): Observable<OrcamentoSintetico[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": localStorage.getItem("id")
    };

    return this.http.post<OrcamentoSintetico[]>(this.baseUrl + "api/Orcamento/GetByUsuario", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Ações */

  public alterar(id: number, seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "seller_id": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Orcamento/Alterar", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public desativar(id: number, seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "seller_id": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Orcamento/Desativar", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public enviar(id: number, seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "seller_id": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Orcamento/Enviar", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
