import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Animacao } from '../../core/tools/animacao';

/* Services */

import { CallCenterService } from '../../core/services/call-center/call-center.service';

/* Models */

import { CallCenterMotivoPausa } from '../../core/models/call-center/call-center-motivo-pausa';

@Component({
  selector: 'app-call-center-motivos-pausa',
  templateUrl: './call-center-motivos-pausa.component.html',
  styleUrls: ['./call-center-motivos-pausa.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CallCenterMotivosPausaComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Call Center (Motivos Pausa) */

  motivosPausa: CallCenterMotivoPausa[] = [];

  constructor(
    private router: Router,
    private callCenterService: CallCenterService
  ) { }

  ngOnInit() {
    this.carregarDados();
  }

  /* Ações */

  alterarMotivo(id: number) {
    this.router.navigate(["/call-center-motivos-pausa-cadastro/" + id]);
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;

    /* Call Center (Motivos Pausa) */

    this.callCenterService.getMotivoPausaByGuidClienteNapis(false).subscribe(
      motivosPausa => this.motivosPausa = motivosPausa,
      error => { console.log("Erro: " + error) },
      () => {
        this.carregando = false;
      }
    );
  }
}
