export class Estatistica {
  constructor(
    public item: string,
    public descricao: string,
    public valor: number,
    public valor_litragem: number,
    public qtde: number,
    public porcentagem: number
  ) { }
}
