import { Component, OnInit } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';

/* Services */

import { AuthService } from '../../core/services/auth/auth.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { Vendedor } from '../../core/models/vendedores/vendedor';

@Component({
  selector: 'app-alternar-usuario',
  templateUrl: './alternar-usuario.component.html',
  styleUrls: ['./alternar-usuario.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class AlternarUsuarioComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Vendedores */

  vendedores: Vendedor[] = [];

  constructor(
    private authService: AuthService,
    private vendedorService: VendedorService
  ) { }

  ngOnInit() {
    this.carregarDados();
  }

  /* Ações*/

  alternar(vendedor: Vendedor) {
    if (vendedor != null) {
      this.authService.desautorizar(false);
      this.authService.autorizar(vendedor.username, vendedor.password, true);
    }
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;

    this.vendedorService.getVendedorVendedorVinculoByVendedor().subscribe(
      vendedor => this.vendedores = vendedor,
      error => { console.log("Erro: " + error) },
      () => {
        this.carregando = false;
      }
    );
  }
}
