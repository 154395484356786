import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ConfiguracaoService } from '../../core/services/configuracoes/configuracao.service';
import { ProdutoService } from '../../core/services/produtos/produto.service';
import { SegmentoService } from '../../core/services/segmentos/segmento.service';

/* Models */

import { Configuracao } from '../../core/models/configuracoes/configuracao';
import { Produto } from '../../core/models/produtos/produto';
import { ProdutoFichaTecnica } from '../../core/models/produtos/produto-ficha-tecnica';
import { ProdutoFoto } from '../../core/models/produtos/produto-foto';
import { ProdutoSegmento } from '../../core/models/produtos/produto-segmento';
import { Segmento } from '../../core/models/segmentos/segmento';

@Component({
  selector: 'app-produtos-cadastro',
  templateUrl: './produtos-cadastro.component.html',
  styleUrls: ['./produtos-cadastro.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ProdutosCadastroComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Itens (Menu) */

  itemSelecionado: number = 0;
  itemMaximoPermitido: number = 0;

  /* Formulário (Informações) */

  formProdutoInformacoes: FormGroup;

  enviadoProdutoInformacoes: boolean = false;

  /* Formulário (Ficha Técnica (Links)) */

  formProdutoFichaTecnica: FormGroup;

  enviadoProdutoFichaTecnica: boolean = false;

  /* Dados */

  /* Configurações */

  configuracao: Configuracao = null;

  /* Produto */

  produto: Produto = null;

  produtoAtivo: boolean = true;

  /* Produto (Ficha Técnica (Links)) */

  produtosFichaTecnica: ProdutoFichaTecnica[] = [];

  /* Produto (Foto) */

  foto: string = "";
  fotoErro: boolean = false;
  fotoInvalida: boolean = false;

  produtosFotos: ProdutoFoto[] = [];

  /* Produto (Segmento) */

  produtosSegmento: ProdutoSegmento[] = [];

  /* Segmento */

  segmentos: Segmento[] = [];

  segmentoSelecionado: Segmento = null;

  @ViewChild("arquivoFoto", { static: false }) arquivoFoto: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private configuracaoService: ConfiguracaoService,
    private produtoService: ProdutoService,
    private segmentoService: SegmentoService
  ) { }

  ngOnInit() {
    this.formProdutoInformacoes = this.formBuilder.group({
      codigo: [{ value: "", disabled: true }, Validators.required],
      descricao: [{ value: "", disabled: true }, Validators.required],
      qtde_estoque: [{ value: "", disabled: true }, Validators.required],
      preco: [{ value: "", disabled: true }, Validators.required],
      custo: [{ value: "", disabled: true }, Validators.required],
      ativo: [""],
      ficha_tecnica: [""]
    });

    this.formProdutoFichaTecnica = this.formBuilder.group({
      titulo: ["", Validators.required],
      link: ["", Validators.required]
    });

    this.carregarDados();
  }

  get fin() {
    return this.formProdutoInformacoes.controls;
  }

  get ffi() {
    return this.formProdutoFichaTecnica.controls;
  }

  /* Itens (Menu) */

  alterarItemSelecionado(item: number) {
    this.validarItens(item);

    if (item > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item;
  }

  prosseguirItemSelecionado(item: number) {
    this.validarItens(item + 1);

    if ((item + 1) > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item + 1;
  }

  retornarItemSelecionado(item: number) {
    this.itemSelecionado = item - 1;
  }

  validarItens(item: number) {
    /* Validações */

    this.itemMaximoPermitido = 4;
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;

    this.verificarAdicionandoAlterando();
  }

  prepararReceberDados(id: number) {
    let retorno = new Promise<void>((resolver) => {
      /* Configurações */

      this.configuracaoService.getByGuidClienteNapis().subscribe(
        configuracoes => this.configuracao = configuracoes,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.configuracao != null) {
            /* Produto */

            this.produtoService.getById(id).subscribe(
              produto => this.produto = produto,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.produto != null) {
                  this.formProdutoInformacoes.controls["codigo"].setValue(this.produto.codigo);
                  this.formProdutoInformacoes.controls["codigo"].disable();

                  this.formProdutoInformacoes.controls["descricao"].setValue(this.produto.name);
                  this.formProdutoInformacoes.controls["descricao"].disable();

                  this.formProdutoInformacoes.controls["qtde_estoque"].setValue(this.produto.estoque);
                  this.formProdutoInformacoes.controls["qtde_estoque"].disable();

                  this.formProdutoInformacoes.controls["preco"].setValue(this.produto.price);
                  this.formProdutoInformacoes.controls["preco"].disable();

                  this.formProdutoInformacoes.controls["custo"].setValue(this.produto.custo);
                  this.formProdutoInformacoes.controls["custo"].disable();

                  this.produtoAtivo = this.produto.is_active;

                  this.formProdutoInformacoes.controls["ficha_tecnica"].setValue(this.produto.ficha_tecnica);

                  /* Produto (Ficha Técnica (Links)) */

                  this.produtoService.getFichaTecnicaByIdProduto(this.produto.id).subscribe(
                    produtosFichaTecnica => this.produtosFichaTecnica = produtosFichaTecnica,
                    error => { console.log("Erro: " + error) },
                    () => {
                      /* Produto (Foto) */

                      this.produtoService.getProdutoFotosByIdProduto(this.produto.id).subscribe(
                        produtosFotos => this.produtosFotos = produtosFotos,
                        error => { console.log("Erro: " + error) },
                        () => {
                          if (this.produtosFotos.length > 0) {
                            this.produtosFotos.forEach((item) => {
                              item.imagem = "data:image/png;base64," + item.imagem;
                            });
                          }

                          /* Produto (Segmento) */

                          this.produtoService.getProdutoSegmentoByIdProduto(this.produto.id).subscribe(
                            produtosSegmento => this.produtosSegmento = produtosSegmento,
                            error => { console.log("Erro: " + error) },
                            () => {
                              /* Segmento */

                              this.segmentoService.getByGuidClienteNapis().subscribe(
                                segmentos => this.segmentos = segmentos,
                                error => { console.log("Erro: " + error) },
                                () => {
                                  if (this.segmentos.length > 0) {
                                    this.segmentoSelecionado = this.segmentos[0];
                                  }

                                  return resolver();
                                }
                              );
                            }
                          );
                        }
                      );
                    }
                  );
                } else {
                  this.toastr.error("", "Produto inválido!");

                  this.router.navigate(["/produtos"]);
                }
              }
            );
          }
        }
      )
    });

    return retorno;
  }

  verificarAdicionandoAlterando() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    let id: string = null;

    this.route.params.subscribe(params => { id = params.id; });

    if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
      /* Alterando */

      const prepararReceberDados = this.prepararReceberDados(parseInt(id));

      prepararReceberDados.then(() => {
        this.carregando = false;
      });
    } else {
      /* Adicionando */

      this.carregando = false;

      this.router.navigate(["/produtos"]);
    }
  }

  /* Produto */

  salvarInformacoes() {
    if (this.formProdutoInformacoes.valid) {
      if (this.produto != null) {
        let alterarProduto: boolean = false;

        this.produto.ficha_tecnica = this.formProdutoInformacoes.controls["ficha_tecnica"].value;

        this.produtoService.updateFichaTecnica(this.produto).subscribe(
          alterarProdutos => alterarProduto = alterarProdutos,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarProduto) {
              this.toastr.success("", "Produto alterado com sucesso!");
            }
          }
        );
      }
    }
  }

  selecionarAtivo(ativo: string) {
    this.produtoAtivo = ativo == "0" ? false : true;
  }

  /* Produto (Ficha Técnica (Links)) */

  excluirFichaTecnica(id_produto_ficha_tecnica: number) {
    if (this.produto != null) {
      let deletarFichaTecnica: boolean = false;

      this.produtoService.deleteFichaTecnicaById(id_produto_ficha_tecnica).subscribe(
        deletarFichaTecnicas => deletarFichaTecnica = deletarFichaTecnicas,
        error => { console.log("Erro: " + error) },
        () => {
          if (deletarFichaTecnica) {
            let produtoFichaTecnica = this.produtosFichaTecnica.findIndex(produtoFichaTecnica => produtoFichaTecnica.id == id_produto_ficha_tecnica);

            if (produtoFichaTecnica != -1) {
              this.produtosFichaTecnica.splice(produtoFichaTecnica, 1);

              this.produtosFichaTecnica = [...this.produtosFichaTecnica];
            }

            this.toastr.success("", "Ficha Técnica (Links) excluída com sucesso!");
          }
        }
      );
    }
  }

  salvarFichaTecnica() {
    this.enviadoProdutoFichaTecnica = true;

    if (this.formProdutoFichaTecnica.valid) {
      if (this.produto != null) {
        let produtoFichaTecnica = new ProdutoFichaTecnica();

        produtoFichaTecnica.id_produto = this.produto.id;
        produtoFichaTecnica.titulo = String(this.formProdutoFichaTecnica.controls["titulo"].value).toUpperCase();
        produtoFichaTecnica.link = this.formProdutoFichaTecnica.controls["link"].value;

        let adicionarFichaTecnica: number = 0;

        this.produtoService.addFichaTecnica(produtoFichaTecnica).subscribe(
          adicionarFichasTecnicas => adicionarFichaTecnica = adicionarFichasTecnicas,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarFichaTecnica > 0) {
              produtoFichaTecnica.id = adicionarFichaTecnica;

              this.produtosFichaTecnica.push(produtoFichaTecnica);

              this.produtosFichaTecnica = this.produtosFichaTecnica.sort((a, b) => {
                const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

                return compare(a["titulo"], b["titulo"]);
              });

              this.enviadoProdutoFichaTecnica = false;

              this.formProdutoFichaTecnica.reset();

              this.toastr.success("", "Ficha Técnica (Links) adicionada com sucesso!");
            }
          }
        );
      }
    }
  }

  /* Produto (Foto) */

  converterImagemFoto(imagem: any) {
    this.foto = "data:image/png;base64," + btoa(imagem.target.result);
  }

  definirProdutoFotoPadrao(id_produto_foto: number) {
    if (this.produto != null) {
      let alterarFotoPadrao: boolean = false;

      this.produtoService.updatePadraoById(id_produto_foto, this.produto.id).subscribe(
        alterarFotosPadrao => alterarFotoPadrao = alterarFotosPadrao,
        error => { console.log("Erro: " + error) },
        () => {
          if (alterarFotoPadrao) {
            this.produtosFotos.forEach((item) => {
              if (item.id == id_produto_foto) {
                item.padrao = true;
              } else {
                item.padrao = false;
              }
            });

            this.toastr.success("", "Foto definida com sucesso!");
          }
        }
      );
    }
  }

  excluirProdutoFoto(id_produto_foto: number) {
    if (this.produto != null) {
      let deletarFoto: boolean = false;

      this.produtoService.deleteProdutoFotoById(id_produto_foto).subscribe(
        deletarFotos => deletarFoto = deletarFotos,
        error => { console.log("Erro: " + error) },
        () => {
          if (deletarFoto) {
            let produtoFoto = this.produtosFotos.findIndex(produtoFoto => produtoFoto.id == id_produto_foto);

            if (produtoFoto != -1) {
              if (this.produtosFotos[produtoFoto].padrao) {
                this.produtosFotos.splice(produtoFoto, 1);

                this.produtosFotos = [...this.produtosFotos];

                if (this.produtosFotos.length > 0) {
                  this.definirProdutoFotoPadrao(this.produtosFotos[0].id);
                }
              } else {
                this.produtosFotos.splice(produtoFoto, 1);

                this.produtosFotos = [...this.produtosFotos];
              }
            }

            this.toastr.success("", "Foto excluída com sucesso!");
          }
        }
      );
    }
  }

  removerProdutoFoto() {
    this.arquivoFoto.nativeElement.value = "";
    this.foto = "";
  }

  salvarProdutoFoto() {
    if (this.produto != null) {
      let produtoFoto = new ProdutoFoto();

      produtoFoto.id_produto = this.produto.id;
      produtoFoto.dt_ultima_atualizacao = new Date();
      produtoFoto.imagem = this.foto.replace("data:image/png;base64,", "");

      if (this.produtosFotos.length > 0) {
        produtoFoto.padrao = false;
      } else {
        produtoFoto.padrao = true;
      }

      let adicionarProdutoFoto: number = 0;

      this.produtoService.addProdutoFoto(produtoFoto).subscribe(
        adicionarProdutoFotos => adicionarProdutoFoto = adicionarProdutoFotos,
        error => { console.log("Erro: " + error) },
        () => {
          if (adicionarProdutoFoto) {
            produtoFoto.id = adicionarProdutoFoto;
            produtoFoto.imagem = this.foto;

            this.produtosFotos.push(produtoFoto);

            this.removerProdutoFoto();

            this.toastr.success("", "Foto adicionada com sucesso!");
          }
        }
      );
    }
  }

  uploadProdutoFoto(foto: any) {
    if (foto != null) {
      if (foto.target.files.length > 0) {
        if (foto.target.accept == foto.target.files[0].type) {
          const arquivo = foto.target.files[0];

          if (arquivo) {
            let tamanho: number = (arquivo.size / (1024 * 1024));

            if (tamanho > 0.16) {
              /* 160KB */

              this.fotoErro = true;

              this.removerProdutoFoto();
            } else {
              this.fotoErro = false;

              const reader = new FileReader();

              reader.onload = this.converterImagemFoto.bind(this);

              reader.readAsBinaryString(arquivo);
            }
          }

          this.fotoInvalida = false;
        } else {
          this.fotoErro = false;
          this.fotoInvalida = true;

          this.removerProdutoFoto();
        }
      }
    }
  }

  /* Segmento */

  excluirSegmento(id: number) {
    let deletarSegmento: boolean = false;

    this.produtoService.deleteProdutoSegmentoById(id).subscribe(
      deletarSegmentos => deletarSegmento = deletarSegmentos,
      error => { console.log("Erro: " + error) },
      () => {
        if (deletarSegmento) {
          let produtoSegmento = this.produtosSegmento.findIndex(produtoSegmento => produtoSegmento.id == id);

          if (produtoSegmento != -1) {
            this.produtosSegmento.splice(produtoSegmento, 1);

            this.produtosSegmento = [...this.produtosSegmento];
          }

          this.toastr.success("", "Segmento excluido com sucesso!");
        }
      }
    );
  }

  salvarSegmento() {
    if (this.produto != null) {
      let produtoSegmentoAdicionado = this.produtosSegmento.find(produtoSegmento => produtoSegmento.segment == this.segmentoSelecionado.segment);

      if (produtoSegmentoAdicionado == null) {
        let produtoSegmento = new ProdutoSegmento();

        produtoSegmento.id_produto = this.produto.id;
        produtoSegmento.segment = this.segmentoSelecionado.segment;

        let adicionarSegmento: number = 0;

        this.produtoService.addProdutoSegmento(produtoSegmento).subscribe(
          adicionarSegmentos => adicionarSegmento = adicionarSegmentos,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarSegmento > 0) {
              produtoSegmento.id = adicionarSegmento;

              this.produtosSegmento.push(produtoSegmento);

              this.produtosSegmento = this.produtosSegmento.sort((a, b) => {
                const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

                return compare(a["segment"], b["segment"]);
              });

              this.toastr.success("", "Segmento adicionado com sucesso!");
            }
          }
        );
      } else {
        this.toastr.error("", "Segmento já adicionado!");
      }
    }
  }

  selecionarSegmento(segment: string) {
    this.segmentoSelecionado = this.segmentos.find(segmentos => segmentos.segment == segment);
  }
}
