export class RotaSintetica {
  public id: string;
  public nome_rota: string;
  public route_id_app: number;
  public seller_id: number;
  public guidclientenapis: string;
  public client_id: number;
  public client_id_app: number;
  public name: string;
  public client_document: string;
  public cep: string;
  public tipo_logradouro: string;
  public endereco: string;
  public end_numero: string;
  public end_complemento: string;
  public bairro: string;
  public cidade: string;
  public estado: string;
  public phone: string;
  public endereco_completo: string;
  public order_in_list: number;
  public route_client_id: string;
  public relationship: string;
  public origem: string;
  public oficial_gps_lat: number;
  public oficial_gps_lon: number;
  public objetivo_de_venda: number;
  public segment: string;
  public distancia_rota: string;
  public distancia: string;
  public visita_frequencia: number;
  public dia_visita: Date;

  /* Alteração */

  public route_id_app_novo: number;
  public duplicado: boolean;

  /* Drag and Drop */

  public effectAllowed: string;
  public disable: boolean;
  public handle: boolean;

  /* Frequência */

  public frequencia_qtde_atual: number;

  /* Maps */

  public reposicionar: boolean;

  constructor() { }
}
