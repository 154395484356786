export class ProdutoCampanhaItens {
  public id: number;
  public id_campanha: number;
  public product_id: number;
  public qtdade: number;
  public preco: number;
  public bonificacao: boolean;
  public is_active: boolean;
  public id_lote: number;

  /* Produto */

  public name: string;
  public codigo: string;
  public price: number;

  /* Lote */

  public nome_lote: string;
  public cod_lote: string;
  public estoque_lote: number;
  public validade_lote: Date;
  public preco_lote: number;

  constructor() { }
}
