import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { AuthService } from '../../core/services/auth/auth.service';
import { ConversaoService } from '../../core/tools/conversao.service';
import { UsuarioService } from '../../core/services/usuarios/usuario.service';

/* Models */

import { Usuario } from '../../core/models/usuarios/usuario';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class LoginComponent implements OnInit {
  formLogin: FormGroup;

  enviado = false;

  /* Cores */

  corFundo: string = "#fff";
  corMensagem: string = "#000";
  corTexto: string = "#212529";

  /* Dados */

  usuario: Usuario = null;

  /* Layout */

    /* Logomarca */

    logomarca: string = "";

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private conversaoService: ConversaoService,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.formLogin = this.formBuilder.group({
      email: ["", Validators.required],
      senha: ["", Validators.required]
    });

    /* Dados Externos */

    let dadosExternos: string = "";

    this.route.queryParams.subscribe(parametros => { dadosExternos = parametros["externo"] });

    if (dadosExternos != null && dadosExternos != "") {
      /* 0 = Vendedor (E-mail) / 1 = Cliente (Código) */

      let dadosDivididos = this.conversaoService.base64ParaString(dadosExternos).split(":");

      if (dadosDivididos != null && dadosDivididos[0] != null && dadosDivididos[0] != "") {
        this.usuarioService.getByEmail(dadosDivididos[0]).subscribe(
          usuario => this.usuario = usuario,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.usuario != null) {
              this.formLogin.controls["email"].setValue(this.usuario.username);
              this.formLogin.controls["senha"].setValue(this.usuario.password);

              if (dadosDivididos[1] != null && !isNaN(parseInt(dadosDivididos[1])) && isFinite(parseInt(dadosDivididos[1]))) {
                localStorage.setItem("cliente_consulta", dadosDivididos[1]);
              }

              this.acessar(true);
            } else {
              this.toastr.error("", "Usuário externo inválido!");
            }
          }
        );
      }
    }

    this.verificarLayout();
  }

  get f() {
    return this.formLogin.controls;
  }

  /* Ações */

  acessar(criptografada: boolean) {
    this.enviado = true;

    if (this.formLogin.valid) {
      this.authService.autorizar(this.formLogin.controls["email"].value, this.formLogin.controls["senha"].value, criptografada);
    }
  }

  /* Layout */

  verificarLayout() {
    /* Empresa */

    if (window.location.href.indexOf("aokisistemas") > -1) {
      /* Aoki */

      this.corFundo = "#005aae";
      this.corMensagem = "#fff";
      this.corTexto = "#fff";

      this.logomarca = "assets/logo_aoki_preto.png";
    } else if (window.location.href.indexOf("portal.cirobottini") > -1) {
      /* Bottini */

      this.logomarca = "assets/logo_bottini_preto.png";
    } else if (window.location.href.indexOf("portal.2bdigitall") > -1) {
      /* 2B */

      this.logomarca = "assets/logo_2bdigital_branco.png";
    } else {
      this.logomarca = "assets/logo_napis_preto.png";
    }
  }
}
