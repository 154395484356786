import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ProdutoService } from '../../core/services/produtos/produto.service';

/* Models */

import { Produto } from '../../core/models/produtos/produto';
import { ProdutoCampanha } from '../../core/models/produtos/produto-campanha';
import { ProdutoCampanhaItens } from '../../core/models/produtos/produto-campanha-itens';
import { ProdutoLote } from '../../core/models/produtos/produto-lote';

@Component({
  selector: 'app-produtos-campanhas-cadastro',
  templateUrl: './produtos-campanhas-cadastro.component.html',
  styleUrls: ['./produtos-campanhas-cadastro.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ProdutosCampanhasCadastroComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Itens (Menu) */

  itemSelecionado: number = 0;
  itemMaximoPermitido: number = 0;

  /* Filtro (Produtos) */

  formFiltroProduto: FormGroup;

  filtroProdutos: any[] = [
    { texto: "Código", valor: "0" },
    { texto: "Descrição", valor: "1" },
    { texto: "Marca", valor: "2" }
  ];

  enviadoProduto: boolean = false;

  filtrandoProduto: boolean = false;

  /* Formulário (Campanha (Itens)) */

  formProdutoCampanhaItem: FormGroup;

  enviadoProdutoCampanhaItem: boolean = false;

  /* Formulário (Campanha) */

  formProdutoCampanha: FormGroup;

  enviadoProdutoCampanha: boolean = false;

  /* Dados */

    /* Filtro (Produtos) */

    produtoEmBranco: boolean = false;
    produtoInvalido: boolean = false;

    produtos: Produto[] = [];
    produtosOriginal: Produto[] = [];

    produtoSelecionado: Produto = null;

    /* Produto (Campanha (Itens)) */

    produtoCampanhaItemAtivo: boolean = true;
    produtoCampanhaItemBonificacao: boolean = false;

    produtosCampanhaItens: ProdutoCampanhaItens[] = [];

    produtoCampanhaItemSelecionado: ProdutoCampanhaItens = null;

    /* Produto (Campanha) */

    produtoCampanhaAtivo: boolean = true;
    produtoCampanhaKitCombo: string = "K";
    produtoCampanhaPrecoBonificacao: string = "P";
    produtoCampanhaBonificacaoMultiplicavel: boolean = false;
    produtoCampanhaBonificacaoSelecionavel: boolean = false;

    produtoCampanha: ProdutoCampanha = null;

    /* Produto (Campanha (Imagem)) */

    campanhaImagem: string = "";
    campanhaImagemErro: boolean = false;
    campanhaImagemInvalida: boolean = false;

    /* Produto (Lotes) */

    produtosLotes: ProdutoLote[] = [];

    produtoLoteErro: boolean = false;

  /* Lote */

  exibirLote: boolean = false;

  /* Modal */

  modalReferenciaSelecionarLote: NgbModalRef = null;

  /* Storage */

  guidclientenapis: string = localStorage.getItem("guidclientenapis");
  obs_gerais: string = localStorage.getItem("obs_gerais");

  @ViewChild("arquivoImagem", { static: false }) arquivoImagem: ElementRef;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private produtoService: ProdutoService
  ) { }

  ngOnInit() {
    /* Filtro (Produtos) */

    this.formFiltroProduto = this.formBuilder.group({
      filtro: ["1"],
      codigo: ["", Validators.required],
      descricao: ["", Validators.required],
      marca: ["", Validators.required]
    });

    this.organizarFiltroProduto();

    this.formProdutoCampanhaItem = this.formBuilder.group({
      qtde: [{ value: "", disabled: true }, Validators.required],
      preco: [{ value: "", disabled: true }, Validators.required]
    });

    this.formProdutoCampanha = this.formBuilder.group({
      nome: ["", Validators.required],
      qtde: ["", Validators.required],
      id_no_erp_do_cliente: [""]
    });

    this.carregarDados();
  }

  get ffp() {
    return this.formFiltroProduto.controls;
  }

  get fpc() {
    return this.formProdutoCampanha.controls;
  }

  get fpi() {
    return this.formProdutoCampanhaItem.controls;
  }

  /* Ações */

  reiniciarProdutoCampanha() {
    /* Itens (Menu) */

    this.itemSelecionado = 0;
    this.itemMaximoPermitido = 0;

    /* Formulário (Campanha) */

    this.formProdutoCampanha.reset();

    this.enviadoProdutoCampanha = false;

    /* Produto (Campanha) */

    this.produtoCampanhaAtivo = true;
    this.produtoCampanhaKitCombo = "K";
    this.produtoCampanhaPrecoBonificacao = "P";
    this.produtoCampanhaBonificacaoMultiplicavel = false;
    this.produtoCampanhaBonificacaoSelecionavel = false;

    this.produtoCampanha = null;

    /* Produto (Campanha (Itens)) */

    this.produtosCampanhaItens = [];

    this.reiniciarProdutoCampanhaItem();

    /* Produto (Campanha (Imagem)) */

    this.campanhaImagemErro = false;

    this.removerCampanhaImagem();
  }

  reiniciarProdutoCampanhaItem() {
    /* Formulário (Campanha (Itens)) */

    this.formProdutoCampanhaItem.reset();

    this.formProdutoCampanhaItem.controls["qtde"].disable();
    this.formProdutoCampanhaItem.controls["preco"].disable();

    this.enviadoProdutoCampanhaItem = false;

    /* Filtro (Produtos) */

    this.produtoEmBranco = false;
    this.produtoInvalido = false;

    this.produtoSelecionado = null;

    /* Produto (Campanha (Itens)) */

    this.produtoCampanhaItemAtivo = true;
    this.produtoCampanhaItemBonificacao = false;

    this.produtoCampanhaItemSelecionado = null;

    /* Produto (Lote) */

    this.removerLoteProduto();
  }

  salvarProdutoCampanha() {
    if (this.produtosCampanhaItens.length > 0) {
      if (this.produtoCampanhaPrecoBonificacao == "B" && this.produtoCampanhaBonificacaoSelecionavel) {
        let produtoCampanhaItem = this.produtosCampanhaItens.find(produtoCampanhaItem => produtoCampanhaItem.bonificacao);

        if (produtoCampanhaItem == null) {
          this.toastr.error("", "Nenhuma bonificação adicionada!");

          return;
        }
      }

      this.prepararEnviarDados();

      if (this.produtoCampanha.id != null) {
        /* Alterando */

        let alterarProdutoCampanha: boolean = false;

        this.produtoService.updateProdutoCampanha(this.produtoCampanha).subscribe(
          alterarProdutosCampanhas => alterarProdutoCampanha = alterarProdutosCampanhas,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarProdutoCampanha) {
              let alterarProdutoCampanhaItem: boolean = false;

              this.produtoService.updateProdutoCampanhaItens(this.produtosCampanhaItens).subscribe(
                alterarProdutosCampanhasItens => alterarProdutoCampanhaItem = alterarProdutosCampanhasItens,
                error => { console.log("Erro: " + error) },
                () => {
                  if (alterarProdutoCampanhaItem) {
                    this.router.navigate(["/produtos"]);

                    this.toastr.success("", "Campanha alterada com sucesso!");
                  }
                }
              );
            } else {
              this.toastr.error("", "Houve um erro, contate o suporte...");
            }
          }
        );
      } else {
        /* Adicionando */

        let adicionarProdutoCampanha: number = 0;

        this.produtoService.addProdutoCampanha(this.produtoCampanha).subscribe(
          adicionarProdutosCampanhas => adicionarProdutoCampanha = adicionarProdutosCampanhas,
          error => { console.log("Erro: " + error) },
          () => {
            if (adicionarProdutoCampanha > 0) {
              this.produtosCampanhaItens.forEach((item) => {
                item.id_campanha = adicionarProdutoCampanha;
              });

              let alterarProdutoCampanhaItem: boolean = false;

              this.produtoService.updateProdutoCampanhaItens(this.produtosCampanhaItens).subscribe(
                alterarProdutosCampanhasItens => alterarProdutoCampanhaItem = alterarProdutosCampanhasItens,
                error => { console.log("Erro: " + error) },
                () => {
                  if (alterarProdutoCampanhaItem) {
                    this.reiniciarProdutoCampanha();

                    this.toastr.success("", "Campanha adicionada com sucesso!");
                  }
                }
              );
            } else {
              this.toastr.error("", "Houve um erro, contate o suporte...");
            }
          }
        );
      }
    } else {
      this.toastr.error("", "Nenhum produto adicionado!");
    }
  }

  salvarProdutoCampanhaItem() {
    this.enviadoProdutoCampanhaItem = true;

    /* Filtro (Produtos) */

    this.produtoEmBranco = false;
    this.produtoInvalido = false;

    /* Produto (Lotes) */

    this.produtoLoteErro = false;

    if (this.produtoSelecionado == null) {
      this.produtoEmBranco = true;

      this.toastr.error("", "Produto em branco!");
    } else {
      if (this.produtoSelecionado.qtde_lote > 0) {
        if (this.produtoSelecionado.id_lote == null) {
          this.produtoLoteErro = true;
        }
      }
    }

    if (this.produtoCampanhaKitCombo == "C") {
      /* Combo */

      if (this.formProdutoCampanhaItem.controls["qtde"].value == null || this.formProdutoCampanhaItem.controls["qtde"].value == "") {
        this.formProdutoCampanhaItem.controls["qtde"].setErrors(null);
      }
    } else {
      /* Kit */

      if (this.formProdutoCampanhaItem.controls["qtde"].valid) {
        let qtde: number = 0;

        if (this.formProdutoCampanhaItem.controls["qtde"].value != null && this.formProdutoCampanhaItem.controls["qtde"].value != "") {
          qtde = parseInt(this.formProdutoCampanhaItem.controls["qtde"].value);
        }

        if (qtde == 0) {
          this.formProdutoCampanhaItem.controls["qtde"].setErrors({ "invalid": true });
        }
      }
    }

    if (this.produtoSelecionado != null) {
      let qtde: number = this.formProdutoCampanhaItem.controls["qtde"].value;

      if (qtde != 0 && qtde != null) {
        if (this.produtoSelecionado.multiplo != null && this.produtoSelecionado.multiplo != 0) {
          if (qtde % this.produtoSelecionado.multiplo != 0) {
            this.formProdutoCampanhaItem.controls["qtde"].setErrors({ "invalid": true });
          }
        }
      }
    }

    if (this.produtoCampanhaItemBonificacao) {
      this.formProdutoCampanhaItem.controls["preco"].setErrors(null);
    } else {
      if (this.formProdutoCampanhaItem.controls["preco"].valid) {
        if (this.formProdutoCampanhaItem.controls["preco"].value == 0) {
          this.formProdutoCampanhaItem.controls["preco"].setErrors({ "invalid": true });
        }
      }
    }

    if (!this.produtoEmBranco && !this.produtoLoteErro && this.formProdutoCampanhaItem.valid) {
      let produtoAdicionado = this.produtosCampanhaItens.find(produtoCampanhaItem => produtoCampanhaItem.codigo == this.produtoSelecionado.codigo && produtoCampanhaItem.bonificacao == this.produtoCampanhaItemBonificacao);

      if (produtoAdicionado != null) {
        if (this.produtoCampanhaItemSelecionado != null) {
          /* Alterando */

          if (this.produtoCampanhaItemSelecionado.codigo == produtoAdicionado.codigo && this.produtoCampanhaItemSelecionado.bonificacao == produtoAdicionado.bonificacao) {

          } else {
            this.toastr.error("", "Produto já adicionado!");

            this.produtoInvalido = true;
          }
        } else {
          this.toastr.error("", "Produto já adicionado!");

          this.produtoInvalido = true;
        }
      }

      if (!this.produtoInvalido) {
        let produtoCampanhaItens = new ProdutoCampanhaItens;

        let qtde: number = this.formProdutoCampanhaItem.controls["qtde"].value == null || this.formProdutoCampanhaItem.controls["qtde"].value == "" ? 0 : this.formProdutoCampanhaItem.controls["qtde"].value;
        let preco: number = this.formProdutoCampanhaItem.controls["preco"].value == null || this.formProdutoCampanhaItem.controls["preco"].value == "" ? 0 : this.formProdutoCampanhaItem.controls["preco"].value;

        produtoCampanhaItens.product_id = this.produtoSelecionado.id;
        produtoCampanhaItens.qtdade = qtde;
        produtoCampanhaItens.preco = preco;
        produtoCampanhaItens.bonificacao = this.produtoCampanhaItemBonificacao;
        produtoCampanhaItens.is_active = this.produtoCampanhaItemAtivo;
        produtoCampanhaItens.id_lote = this.produtoSelecionado.id_lote;

        /* Produto */

        produtoCampanhaItens.name = this.produtoSelecionado.name;
        produtoCampanhaItens.codigo = this.produtoSelecionado.codigo;
        produtoCampanhaItens.price = this.produtoSelecionado.valor;

        /* Lote */

        produtoCampanhaItens.nome_lote = this.produtoSelecionado.nome_lote;
        produtoCampanhaItens.cod_lote = this.produtoSelecionado.cod_lote;
        produtoCampanhaItens.estoque_lote = this.produtoSelecionado.estoque_lote;
        produtoCampanhaItens.validade_lote = this.produtoSelecionado.validade_lote;
        produtoCampanhaItens.preco_lote = this.produtoSelecionado.preco_lote;

        if (this.produtoCampanhaItemSelecionado != null) {
          /* Alterando */

          let produtoAdicionado = this.produtosCampanhaItens.findIndex(produtoCampanhaItem => produtoCampanhaItem.codigo == this.produtoCampanhaItemSelecionado.codigo && produtoCampanhaItem.bonificacao == this.produtoCampanhaItemSelecionado.bonificacao);

          if (produtoAdicionado != -1) {
            this.produtosCampanhaItens.splice(produtoAdicionado, 1);

            this.produtosCampanhaItens = [...this.produtosCampanhaItens];

            produtoCampanhaItens.id = this.produtoCampanhaItemSelecionado.id;

            this.produtosCampanhaItens.push(produtoCampanhaItens);

            this.organizarProdutoCampanhaItem();
            this.reiniciarProdutoCampanhaItem();

            this.toastr.success("", "Produto alterado com sucesso!");
          }
        } else {
          /* Adicionando */

          if (this.produtoCampanha != null) {
            produtoCampanhaItens.id_campanha = this.produtoCampanha.id;
          }

          this.produtosCampanhaItens.push(produtoCampanhaItens);

          this.organizarProdutoCampanhaItem();
          this.reiniciarProdutoCampanhaItem();

          this.toastr.success("", "Produto adicionado com sucesso!");
        }

        this.verificarExibirLote();
      }
    }
  }

  selecionarProdutoCampanhaItem(codigo: string, bonificacao: boolean) {
    this.produtoEmBranco = false;
    this.produtoInvalido = false;

    this.produtoCampanhaItemSelecionado = this.produtosCampanhaItens.find(produtoCampanhaItem => produtoCampanhaItem.codigo == codigo && produtoCampanhaItem.bonificacao == bonificacao);

    if (this.produtoCampanhaItemSelecionado != null) {
      this.produtoSelecionado = this.produtos.find(produto => produto.codigo == this.produtoCampanhaItemSelecionado.codigo);

      if (this.produtoSelecionado != null) {
        this.produtoCampanhaItemAtivo = this.produtoCampanhaItemSelecionado.is_active;

        this.produtoSelecionado.valor = this.produtoCampanhaItemSelecionado.price;

        /* Lote */

        this.produtoSelecionado.id_lote = this.produtoCampanhaItemSelecionado.id_lote;
        this.produtoSelecionado.nome_lote = this.produtoCampanhaItemSelecionado.nome_lote;
        this.produtoSelecionado.cod_lote = this.produtoCampanhaItemSelecionado.cod_lote;
        this.produtoSelecionado.estoque_lote = this.produtoCampanhaItemSelecionado.estoque_lote;
        this.produtoSelecionado.validade_lote = this.produtoCampanhaItemSelecionado.validade_lote;
        this.produtoSelecionado.preco_lote = this.produtoCampanhaItemSelecionado.preco_lote;

        this.produtoCampanhaItemBonificacao = this.produtoCampanhaItemSelecionado.bonificacao;

        /* Formulário (Campanha (Itens)) */

        this.formProdutoCampanhaItem.controls["qtde"].enable();
        this.formProdutoCampanhaItem.controls["qtde"].setValue(this.produtoCampanhaItemSelecionado.qtdade);

        this.formProdutoCampanhaItem.controls["preco"].enable();
        this.formProdutoCampanhaItem.controls["preco"].setValue(this.produtoCampanhaItemSelecionado.preco);
      }
    }
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;

    this.produtoService.getByGuidClienteNapis().subscribe(
      produtos => this.produtos = produtos,
      error => { console.log("Erro: " + error) },
      () => {
        this.produtosOriginal = JSON.parse(JSON.stringify(this.produtos));

        this.verificarAdicionandoAlterando();
      }
    );
  }

  organizarProdutoCampanhaItem() {
    this.produtosCampanhaItens = this.produtosCampanhaItens.sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a["name"], b["name"]);
    });
  }

  prepararReceberDados(id_campanha: number) {
    let retorno = new Promise<void>((resolver) => {
      /* Produto (Campanha) */

      this.produtoService.getProdutoCampanhaById(id_campanha).subscribe(
        produtoCampanha => this.produtoCampanha = produtoCampanha,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.produtoCampanha != null) {
            this.formProdutoCampanha.controls["nome"].setValue(this.produtoCampanha.nome);
            this.formProdutoCampanha.controls["qtde"].setValue(this.produtoCampanha.qtde_minima);
            this.formProdutoCampanha.controls["id_no_erp_do_cliente"].setValue(this.produtoCampanha.id_no_erp_do_cliente);

            /* Produto (Campanha) */

            this.produtoCampanhaAtivo = this.produtoCampanha.is_active;
            this.produtoCampanhaKitCombo = this.produtoCampanha.kit_combo;
            this.produtoCampanhaPrecoBonificacao = this.produtoCampanha.preco_bonificacao;
            this.produtoCampanhaBonificacaoMultiplicavel = this.produtoCampanha.bonificacao_multiplicavel;
            this.produtoCampanhaBonificacaoSelecionavel = this.produtoCampanha.bonificacao_selecionavel;

            /* Produto (Campanha (Imagem)) */

            if (this.produtoCampanha.imagem != null) {
              this.campanhaImagem = "data:image/png;base64," + this.produtoCampanha.imagem;
            }

            /* Produto (Campanha (Itens)) */

            this.produtoService.getProdutoCampanhaItensByIdCampanha(this.produtoCampanha.id).subscribe(
              produtosCampanhaItens => this.produtosCampanhaItens = produtosCampanhaItens,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.produtosCampanhaItens != null) {
                  this.produtosCampanhaItens.forEach((item) => {
                    if (item.id_lote != null) {
                      item.price = item.preco_lote;
                    }
                  });
                }

                this.verificarExibirLote();

                return resolver();
              }
            );
          } else {
            this.toastr.error("", "Campanha inválida!");

            this.router.navigate(["/produtos"]);
          }
        }
      );
    });

    return retorno;
  }

  prepararEnviarDados() {
    if (this.produtoCampanha != null) {
      /* Alterando */

      let qtde: number = this.formProdutoCampanha.controls["qtde"].value == null || this.formProdutoCampanha.controls["qtde"].value == "" ? 0 : this.formProdutoCampanha.controls["qtde"].value;

      this.produtoCampanha.nome = String(this.formProdutoCampanha.controls["nome"].value).toUpperCase();
      this.produtoCampanha.is_active = this.produtoCampanhaAtivo;
      this.produtoCampanha.qtde_minima = qtde;
      this.produtoCampanha.imagem = this.campanhaImagem == "" ? null : this.campanhaImagem.replace("data:image/png;base64,", "");
      this.produtoCampanha.bonificacao_multiplicavel = this.produtoCampanhaBonificacaoMultiplicavel;
      this.produtoCampanha.bonificacao_selecionavel = this.produtoCampanhaBonificacaoSelecionavel;

      if (this.formProdutoCampanha.controls["id_no_erp_do_cliente"].value != null && this.formProdutoCampanha.controls["id_no_erp_do_cliente"].value != "") {
        this.produtoCampanha.id_no_erp_do_cliente = this.formProdutoCampanha.controls["id_no_erp_do_cliente"].value;
      } else {
        this.produtoCampanha.id_no_erp_do_cliente = null;
      }
    } else {
      /* Adicionando */

      this.produtoCampanha = new ProdutoCampanha;

      let qtde: number = this.formProdutoCampanha.controls["qtde"].value == null || this.formProdutoCampanha.controls["qtde"].value == "" ? 0 : this.formProdutoCampanha.controls["qtde"].value;

      this.produtoCampanha.nome = String(this.formProdutoCampanha.controls["nome"].value).toUpperCase();
      this.produtoCampanha.kit_combo = this.produtoCampanhaKitCombo;
      this.produtoCampanha.is_active = this.produtoCampanhaAtivo;
      this.produtoCampanha.preco_bonificacao = this.produtoCampanhaPrecoBonificacao;
      this.produtoCampanha.qtde_minima = qtde;
      this.produtoCampanha.imagem = this.campanhaImagem == "" ? null : this.campanhaImagem.replace("data:image/png;base64,", "");
      this.produtoCampanha.guidclientenapis = this.guidclientenapis;
      this.produtoCampanha.bonificacao_multiplicavel = this.produtoCampanhaBonificacaoMultiplicavel;
      this.produtoCampanha.bonificacao_selecionavel = this.produtoCampanhaBonificacaoSelecionavel;

      if (this.formProdutoCampanha.controls["id_no_erp_do_cliente"].value != null && this.formProdutoCampanha.controls["id_no_erp_do_cliente"].value != "") {
        this.produtoCampanha.id_no_erp_do_cliente = this.formProdutoCampanha.controls["id_no_erp_do_cliente"].value;
      } else {
        this.produtoCampanha.id_no_erp_do_cliente = null;
      }
    }
  }

  verificarAdicionandoAlterando() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    let id: string = null;

    this.route.params.subscribe(params => { id = params.id; });

    if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
      /* Alterando */

      const prepararReceberDados = this.prepararReceberDados(parseInt(id));

      prepararReceberDados.then(() => {
        this.carregando = false;
      });
    } else {
      /* Adicionado */

      this.carregando = false;
    }
  }

  /* Filtro (Produtos) */

  alterarFiltroProduto(filtro_selecionado: number) {
    this.reiniciarDadosProduto();

    let filtroAtual: string = this.formFiltroProduto.controls["filtro"].value;

    this.formFiltroProduto.reset();

    if (filtro_selecionado == null) {
      this.formFiltroProduto.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltroProduto.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltroProduto();

    this.enviadoProduto = false;
    this.filtrandoProduto = false;
  }

  filtrarProduto() {
    this.enviadoProduto = true;

    if (this.formFiltroProduto.invalid) {
      return;
    }

    this.reiniciarDadosProduto();

    if (this.formFiltroProduto.controls["filtro"].value == "0") {
      /* Código */

      if (this.formFiltroProduto.controls["codigo"].value != "" && this.formFiltroProduto.controls["codigo"].value != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.codigo == null) {
            return false;
          } else {
            return (produto.codigo.toLowerCase().indexOf(this.formFiltroProduto.controls["codigo"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
      /* Descrição */

      if (this.formFiltroProduto.controls["descricao"].value != "" && this.formFiltroProduto.controls["descricao"].value != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.name == null) {
            return false;
          } else {
            return (produto.name.toLowerCase().indexOf(this.formFiltroProduto.controls["descricao"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
      /* Marca */

      if (this.formFiltroProduto.controls["marca"].value != "" && this.formFiltroProduto.controls["marca"].value != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.grupo_estoque == null) {
            return false;
          } else {
            return (produto.grupo_estoque.toLowerCase().indexOf(this.formFiltroProduto.controls["marca"].value.trim().toLowerCase()) !== -1);
          }
        });
      }
    }

    this.filtrandoProduto = true;
  }

  organizarFiltroProduto() {
    if (this.formFiltroProduto.controls["filtro"].value == "0") {
      /* Código */

      this.formFiltroProduto.get("codigo").enable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "1") {
      /* Descrição */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").enable();
      this.formFiltroProduto.get("marca").disable();
    } else if (this.formFiltroProduto.controls["filtro"].value == "2") {
      /* Marca */

      this.formFiltroProduto.get("codigo").disable();
      this.formFiltroProduto.get("descricao").disable();
      this.formFiltroProduto.get("marca").enable();
    }
  }

  reiniciarDadosProduto() {
    this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
  }

  selecionarProdutoFiltrado(id_produto: number) {
    let produtoSelecionado = this.produtos.find(produtos => produtos.id == id_produto);

    if (produtoSelecionado != null) {
      this.produtoSelecionado = produtoSelecionado;

      if (this.produtoSelecionado.qtde_lote == 0) {
        this.produtoSelecionado.valor = this.produtoSelecionado.price;
      } else {
        this.produtoSelecionado.valor = null;
      }

      this.alterarFiltroProduto(null);
      this.removerLoteProduto();

      this.produtoEmBranco = false;
      this.produtoInvalido = false;
      this.filtrandoProduto = false;

      /* Formulário (Campanha (Itens)) */

      if (produtoSelecionado.qtde_lote == 0) {
        this.formProdutoCampanhaItem.controls["qtde"].enable();
        this.formProdutoCampanhaItem.controls["preco"].enable();
      }
    } 
  }

  /* Itens (Menu) */

  alterarItemSelecionado(item: number) {
    this.validarItens();

    if (item > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item;
  }

  prosseguirItemSelecionado(item: number) {
    this.validarItens();

    if ((item + 1) > this.itemMaximoPermitido) {
      return;
    }

    this.itemSelecionado = item + 1;
  }

  retornarItemSelecionado(item: number) {
    this.itemSelecionado = item - 1;
  }

  validarItens() {
    /* Validações */

    this.itemMaximoPermitido = 0;

    if (this.produtoCampanhaKitCombo == "K") {
      this.formProdutoCampanha.controls["qtde"].setErrors(null);
    }

    if (this.formProdutoCampanha.invalid) {
      this.enviadoProdutoCampanha = true;

      this.toastr.error("", "Informações inválidas!");

      this.itemSelecionado = 0;

      return;
    } else {
      this.itemMaximoPermitido = 2;
    }
  }

  /* Lotes */

  removerLoteProduto() {
    if (this.produtoSelecionado != null) {
      /* Lote */

      this.produtoSelecionado.id_lote = null;
      this.produtoSelecionado.nome_lote = null;
      this.produtoSelecionado.cod_lote = null;
      this.produtoSelecionado.estoque_lote = null;
      this.produtoSelecionado.validade_lote = null;
      this.produtoSelecionado.preco_lote = null;
    }
  }

  selecionarLoteProduto(id_lote: number) {
    if (this.produtoSelecionado != null) {
      let produtoLote = this.produtosLotes.find(produtoLote => produtoLote.id == id_lote);

      if (produtoLote != null) {
        this.produtoSelecionado.valor = produtoLote.preco;

        /* Lote */

        this.produtoSelecionado.id_lote = produtoLote.id;
        this.produtoSelecionado.nome_lote = produtoLote.nomelote;
        this.produtoSelecionado.cod_lote = produtoLote.codlote;
        this.produtoSelecionado.estoque_lote = produtoLote.estoque;
        this.produtoSelecionado.validade_lote = produtoLote.validade;
        this.produtoSelecionado.preco_lote = produtoLote.preco;

        this.produtoLoteErro = false;

        /* Formulário (Campanha (Itens)) */

        this.formProdutoCampanhaItem.controls["qtde"].enable();
        this.formProdutoCampanhaItem.controls["preco"].enable();
      }
    }

    this.modalReferenciaSelecionarLote.close();
  }

  verificarExibirLote() {
    this.exibirLote = false;

    this.produtosCampanhaItens.forEach((item) => {
      if (item.id_lote != null) {
        this.exibirLote = true;
      }
    });
  }

  /* Produto (Campanha (Itens)) */

  selecionarProdutoCampanhaItemAtivo(ativo: string) {
    this.produtoCampanhaItemAtivo = ativo == "0" ? false : true;
  }

  selecionarProdutoCampanhaItemBonificacao(bonificacao: string) {
    this.produtoCampanhaItemBonificacao = bonificacao == "0" ? false : true;

    if (this.produtoCampanhaItemBonificacao) {
      this.formProdutoCampanhaItem.controls["preco"].setErrors(null);
    }
  }

  /* Produto (Campanha) */

  selecionarProdutoCampanhaAtivo(ativo: string) {
    this.produtoCampanhaAtivo = ativo == "0" ? false : true;
  }

  selecionarProdutoCampanhaKitCombo(kit_combo: string) {
    this.produtoCampanhaKitCombo = kit_combo;

    if (this.produtoCampanhaKitCombo == "K") {
      this.formProdutoCampanha.controls["qtde"].setValue("");
      this.formProdutoCampanha.controls["qtde"].setErrors(null);
    }

    this.changeDetector.detectChanges();
  }

  selecionarProdutoCampanhaPrecoBonificacao(preco_bonificacao: string) {
    this.produtoCampanhaPrecoBonificacao = preco_bonificacao;

    if (this.produtoCampanhaPrecoBonificacao == "P") {
      /* Produto (Campanha (Itens)) */

      this.produtoCampanhaItemBonificacao = false;

      /* Produto (Campanha) */

      this.produtoCampanhaBonificacaoMultiplicavel = false;
      this.produtoCampanhaBonificacaoSelecionavel = false;
    }
  }

  selecionarProdutoCampanhaBonificacaoMultiplicavel(bonificacao_multiplicavel: string) {
    this.produtoCampanhaBonificacaoMultiplicavel = bonificacao_multiplicavel == "0" ? false : true;
  }

  selecionarProdutoCampanhaBonificacaoSelecionavel(bonificacao_selecionavel: string) {
    this.produtoCampanhaBonificacaoSelecionavel = bonificacao_selecionavel == "0" ? false : true;

    if (this.produtosCampanhaItens.length > 0) {
      this.produtosCampanhaItens.filter(produtoCampanhaItem => produtoCampanhaItem.bonificacao).forEach((item) => {
        item.is_active = bonificacao_selecionavel == "0" ? false : true;
      });
    }
  }

  /* Produto (Campanha (Imagem)) */

  converterCampanhaImagem(imagem: any) {
    this.campanhaImagem = "data:image/png;base64," + btoa(imagem.target.result);
  }

  removerCampanhaImagem() {
    if (this.arquivoImagem != undefined) {
      this.arquivoImagem.nativeElement.value = "";
    }

    this.campanhaImagem = "";
  }

  uploadCampanhaImagem(imagem_campanha: any) {
    if (imagem_campanha != null) {
      if (imagem_campanha.target.files.length > 0) {
        if (imagem_campanha.target.accept == imagem_campanha.target.files[0].type) {
          const arquivo = imagem_campanha.target.files[0];

          if (arquivo) {
            let tamanho: number = (arquivo.size / (1024 * 1024));

            if (tamanho > 0.06) {
              /* 60KB */

              this.campanhaImagemErro = true;

              this.removerCampanhaImagem();
            } else {
              this.campanhaImagemErro = false;

              const reader = new FileReader();

              reader.onload = this.converterCampanhaImagem.bind(this);

              reader.readAsBinaryString(arquivo);
            }
          }

          this.campanhaImagemInvalida = false;
        } else {
          this.campanhaImagemErro = false;
          this.campanhaImagemInvalida = true;

          this.removerCampanhaImagem();
        }
      }
    }
  }

  /* Produto (Qtde) */

  decrementarQtdeProduto() {
    if (this.produtoSelecionado != null) {
      if (this.produtoSelecionado.qtde_lote == 0 || (this.produtoSelecionado.qtde_lote > 0 && this.produtoSelecionado.id_lote != null)) {
        let qtde: number = this.formProdutoCampanhaItem.controls["qtde"].value;

        if (this.produtoSelecionado.multiplo != null && this.produtoSelecionado.multiplo != 0 && this.produtoSelecionado.multiplo != 1) {
          /* C/ Múltiplo */

          if (qtde <= 1) {
            qtde = null;
          } else {
            let qtdeFutura: number = qtde - this.produtoSelecionado.multiplo;

            if (qtdeFutura == 0 || qtdeFutura % this.produtoSelecionado.multiplo != 0) {
              qtde = null;
            } else {
              qtde = qtde - this.produtoSelecionado.multiplo;
            }
          }
        } else {
          /* S/ Múltiplo */

          if (qtde <= 1) {
            qtde = null;
          } else {
            qtde--;
          }
        }

        this.formProdutoCampanhaItem.controls["qtde"].setValue(qtde);
      }
    }
  }

  incrementarQtdeProduto(id_produto: number) {
    if (this.produtoSelecionado != null) {
      if (this.produtoSelecionado.qtde_lote == 0 || (this.produtoSelecionado.qtde_lote > 0 && this.produtoSelecionado.id_lote != null)) {
        let qtde: number = this.formProdutoCampanhaItem.controls["qtde"].value;

        if (this.produtoSelecionado.multiplo != null && this.produtoSelecionado.multiplo != 0 && this.produtoSelecionado.multiplo != 1) {
          /* C/ Múltiplo */

          if (qtde <= 0 || qtde == null) {
            qtde = this.produtoSelecionado.multiplo;
          } else {
            let qtdeFutura: number = qtde + this.produtoSelecionado.multiplo;

            if (qtdeFutura % this.produtoSelecionado.multiplo != 0) {
              qtde = this.produtoSelecionado.multiplo;
            } else {
              qtde = qtde + this.produtoSelecionado.multiplo;
            }
          }
        } else {
          /* S/ Múltiplo */

          if (qtde <= 0 || qtde == null) {
            qtde = 1;
          } else {
            qtde++;
          }
        }

        this.formProdutoCampanhaItem.controls["qtde"].setValue(qtde);
      }
    }
  }

  /* Verificação (Qtde) */

  verificarProdutoQtde() {
    if (this.produtoSelecionado != null) {
      let qtde: number = this.formProdutoCampanhaItem.controls["qtde"].value;

      if (qtde != 0 && qtde != null) {
        if (this.produtoSelecionado.multiplo != null && this.produtoSelecionado.multiplo != 0) {
          if (qtde % this.produtoSelecionado.multiplo != 0) {
            this.toastr.error("Qtde somente múltipla de " + this.produtoSelecionado.multiplo + "...", "");

            this.formProdutoCampanhaItem.controls["qtde"].setErrors({ "invalid": true });
          }
        }
      }
    }
  }

  /* Modal */

  modalSelecionarLote(content: any, id_produto: number) {
    if (this.produtoSelecionado != null) {
      this.produtoService.getProdutoLoteByIdProduto(id_produto, this.obs_gerais, null, null).subscribe(
        produtosLotes => this.produtosLotes = produtosLotes,
        error => { console.log("Erro: " + error) },
        () => {
          this.modalReferenciaSelecionarLote = this.modalService.open(content, {
            ariaLabelledBy: "titulo-selecionar-lote",
            size: "lg",
            backdrop: "static"
          });
        }
      );
    }
  }
}
