import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class ValidacaoFormularioService {
  constructor() { }

  /* Data */

  validarData(controle: FormControl) {
    let controleValor: any = controle.value;

    if (controleValor != null) {
      if (controleValor.year == undefined || controleValor.month == undefined || controleValor.day == undefined) {
        return { invalid: true };
      }
    }
  }

  /* Números */

  validarSomenteNumeros(controle: FormControl) {
    let controleValor: string = controle.value;

    if (controleValor != null && isNaN(Number(controleValor))) {
      return { invalid: true };
    }
  }
}
