import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { VendedorService } from '../../core/services/vendedores/vendedor.service';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class AlterarSenhaComponent implements OnInit {
  /* Formulário */

  formDados: FormGroup;

  enviado: boolean = false;

  /* Storage */

  seller_id: number = parseInt(localStorage.getItem("id"));

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private vendedorService: VendedorService
  ) { }

  ngOnInit() {
    this.formDados = this.formBuilder.group({
      nova_senha: ["", Validators.required]
    });
  }

  get f() {
    return this.formDados.controls;
  }

  /* Dados */

  update() {
    let retorno = new Promise<void>((resolver) => {
      let alterarSenha: boolean = false;

      this.vendedorService.updateSenhaByVendedor(this.seller_id, this.formDados.controls["nova_senha"].value).subscribe(
        alterarSenhas => alterarSenha = alterarSenhas,
        error => { console.log("Erro: " + error) },
        () => {
          if (alterarSenha) {
            this.toastr.success("", "Senha alterada com sucesso!");
          }

          return resolver();
        }
      );
    });

    return retorno;
  }

  salvar() {
    this.enviado = true;

    if (this.formDados.invalid) {
      return;
    } else if (this.formDados.controls["nova_senha"].value.length < 6) {
      this.formDados.controls["nova_senha"].setErrors({ "invalid": true });

      return;
    }

    const update = this.update();

    update.then(() => {
      
    });
  }
}
