import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { registerLocaleData, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

/* Pacotes Externos */

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ChartsModule } from 'ng2-charts';

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbProgressbarModule,
  NgbTabsetModule,
  NgbTimepickerModule,
  NgbTooltipModule,
  NgbTooltipConfig
} from '@ng-bootstrap/ng-bootstrap';

import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';

import { NgxTagsModule } from 'ngx-tags';

import { ToastrModule } from 'ngx-toastr';

/* Auth */

import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/services/auth/auth.service';

/* Calendar */

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

/* Conversão */

import { ConversaoService } from './core/tools/conversao.service';

/* Drag and Drop */

import { DndModule } from 'ngx-drag-drop';

/* Listener */

import { ListenerInterceptor } from './core/interceptors/listener-interceptor';
import { ListenerService } from './core/services/listener/listener.service';

/* Maps */

import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { MapaConfiguracao } from './core/tools/mapa-configuracao';

/* Split */

import { AngularSplitModule } from 'angular-split';

/* Tempo */

import { TempoProgressivoService } from './core/tools/tempo-progressivo.service';
import { TempoRegressivoService } from './core/tools/tempo-regressivo.service';

/* Componentes */

import { AppComponent } from './app.component';

import { AlterarSenhaComponent } from './components/alterar-senha/alterar-senha.component';
import { AlternarUsuarioComponent } from './components/alternar-usuario/alternar-usuario.component';
import { AutomacaoMensagensComponent } from './components/automacao-mensagens/automacao-mensagens.component';
import { AutomacaoMensagensCadastroComponent } from './components/automacao-mensagens-cadastro/automacao-mensagens-cadastro.component';
import { CallCenterComponent } from './components/call-center/call-center.component';
import { CallCenterDiscadorComponent } from './components/call-center-discador/call-center-discador.component';
import { CallCenterGerenciarDiscadorComponent } from './components/call-center-gerenciar-discador/call-center-gerenciar-discador.component';
import { CallCenterLigacaoComponent } from './components/call-center-ligacao/call-center-ligacao.component';
import { CallCenterMotivosPausaComponent } from './components/call-center-motivos-pausa/call-center-motivos-pausa.component';
import { CallCenterMotivosPausaCadastroComponent } from './components/call-center-motivos-pausa-cadastro/call-center-motivos-pausa-cadastro.component';
import { CallCenterNovaLigacaoComponent } from './components/call-center-nova-ligacao/call-center-nova-ligacao.component';
import { CentralAtendimentoComponent } from './components/central-atendimento/central-atendimento.component';
import { ConfiguracoesComponent } from './components/configuracoes/configuracoes.component';
import { ContasCorrentesComponent } from './components/contas-corrrentes/contas-correntes.component';
import { ClientesCadastroComponent } from './components/clientes-cadastro/clientes-cadastro.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { CrmComponent } from './components/crm/crm.component';
import { CrmNegociosComponent } from './components/crm-negocios/crm-negocios.component';
import { CrmTarefasComponent } from './components/crm-tarefas/crm-tarefas.component';
import { DashboardAnaliseComparativaComponent } from './components/dashboard/dashboard-analise-comparativa/dashboard-analise-comparativa.component';
import { DashboardControleClientesComponent } from './components/dashboard/dashboard-controle-clientes/dashboard-controle-clientes.component';
import { DashboardCallCenterComponent } from './components/dashboard/dashboard-call-center/dashboard-call-center.component';
import { DashboardCallCenterPainelGerencialComponent } from './components/dashboard/dashboard-call-center-painel-gerencial/dashboard-call-center-painel-gerencial.component';
import { DashboardOportunidadesComponent } from './components/dashboard/dashboard-oportunidades/dashboard-oportunidades.component';
import { DashboardQuilometragensDespesasComponent } from './components/dashboard/dashboard-quilometragens-despesas/dashboard-quilometragens-despesas.component';
import { DashboardVisitasComponent } from './components/dashboard/dashboard-visitas/dashboard-visitas.component';
import { ImportacoesComponent } from './components/importacoes/importacoes.component';
import { ImportacoesClientesComponent } from './components/importacoes-clientes/importacoes-clientes.component';
import { ImportacoesProdutosComponent } from './components/importacoes-produtos/importacoes-produtos.component';
import { IntegracoesComponent } from './components/integracoes/integracoes.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { MotivosNaoVendaCadastroComponent } from './components/motivos-nao-venda-cadastro/motivos-nao-venda-cadastro.component';
import { MotivosNaoVendaComponent } from './components/motivos-nao-venda/motivos-nao-venda.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PedidosCadastroComponent } from './components/pedidos-cadastro/pedidos-cadastro.component';
import { PedidosComponent } from './components/pedidos/pedidos.component';
import { PedidosEnviadosComponent } from './components/pedidos-enviados/pedidos-enviados.component';
import { PedidosNaoEnviadosComponent } from './components/pedidos-nao-enviados/pedidos-nao-enviados.component';
import { ProdutosCadastroComponent } from './components/produtos-cadastro/produtos-cadastro.component';
import { ProdutosComponent } from './components/produtos/produtos.component';
import { ProdutosCampanhasCadastroComponent } from './components/produtos-campanhas-cadastro/produtos-campanhas-cadastro.component';
import { ProdutosPromocoesCadastroComponent } from './components/produtos-promocoes-cadastro/produtos-promocoes-cadastro.component';
import { RecuperarSenhaComponent } from './components/recuperar-senha/recuperar-senha.component';
import { RotasComponent } from './components/rotas/rotas.component';
import { VendedoresComponent } from './components/vendedores/vendedores.component';
import { VendedoresCadastroComponent } from './components/vendedores-cadastro/vendedores-cadastro.component';
import { WhatsappInnersacComponent } from './components/whatsapp-innersac/whatsapp-innersac.component';
import { WhatsappInstanciaComponent } from './components/whatsapp-instancia/whatsapp-instancia.component';
import { PedidosAtencaoComponent } from './components/pedidos-atencao/pedidos-atencao.component';

@NgModule({
  declarations: [
    /* Componentes */

    AppComponent,
    AlterarSenhaComponent,
    AlternarUsuarioComponent,
    AutomacaoMensagensComponent,
    AutomacaoMensagensCadastroComponent,
    CallCenterComponent,
    CallCenterDiscadorComponent,
    CallCenterGerenciarDiscadorComponent,
    CallCenterLigacaoComponent,
    CallCenterMotivosPausaComponent,
    CallCenterMotivosPausaCadastroComponent,
    CallCenterNovaLigacaoComponent,
    CentralAtendimentoComponent,
    ConfiguracoesComponent,
    ContasCorrentesComponent,
    ClientesCadastroComponent,
    ClientesComponent,
    CrmComponent,
    CrmNegociosComponent,
    CrmTarefasComponent,
    DashboardAnaliseComparativaComponent,
    DashboardControleClientesComponent,
    DashboardCallCenterComponent,
    DashboardCallCenterPainelGerencialComponent,
    DashboardOportunidadesComponent,
    DashboardQuilometragensDespesasComponent,
    DashboardVisitasComponent,
    ImportacoesComponent,
    ImportacoesClientesComponent,
    ImportacoesProdutosComponent,
    IntegracoesComponent,
    LoaderComponent,
    LoginComponent,
    MotivosNaoVendaCadastroComponent,
    MotivosNaoVendaComponent,
    NavBarComponent,
    NotFoundComponent,
    PedidosCadastroComponent,
    PedidosComponent,
    PedidosEnviadosComponent,
    PedidosNaoEnviadosComponent,
    ProdutosCadastroComponent,
    ProdutosComponent,
    ProdutosCampanhasCadastroComponent,
    ProdutosPromocoesCadastroComponent,
    RecuperarSenhaComponent,
    RotasComponent,
    VendedoresComponent,
    VendedoresCadastroComponent,
    WhatsappInnersacComponent,
    WhatsappInstanciaComponent,
    PedidosAtencaoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ScrollingModule,

    /* Pacotes Externos */

    AngularFontAwesomeModule,
    ChartsModule,
    NgxBootstrapMultiselectModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    NgxTagsModule,
    NgbTimepickerModule,
    NgbTooltipModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      tapToDismiss: true,
      timeOut: 2400
    }),
    DndModule,
    AgmCoreModule.forRoot(),
    AgmDirectionModule,
    AngularSplitModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    DecimalPipe,

    /* Pacotes Externos */

    NgbTooltipConfig,

    /* Auth */

    AuthGuard,
    AuthService,

    /* Listener */

    ListenerInterceptor,
    ListenerService,
    { provide: HTTP_INTERCEPTORS, useClass: ListenerInterceptor, multi: true },

    /* Conversão */

    ConversaoService,

    /* Maps */

    { provide: LAZY_MAPS_API_CONFIG, useClass: MapaConfiguracao },

    /* Tempo */

    TempoProgressivoService,
    TempoRegressivoService,

    { provide: LOCALE_ID, useValue: "pt" }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
