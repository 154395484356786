import { ProdutoPromocional } from './produto-promocional';

export class ProdutoPromocionalAgrupado {
  public bonificacao: boolean;
  public order_id: number;
  public seller_id: number;
  public cod_lote: string;
  public produtoPromocional: ProdutoPromocional[];

  constructor() { }
}
