import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { MotivoNaoVendaService } from '../../core/services/motivos-nao-venda/motivo-nao-venda.service';

/* Models */

import { MotivoNaoVenda } from '../../core/models/motivos-nao-venda/motivo-nao-venda';

@Component({
  selector: 'app-motivos-nao-venda',
  templateUrl: './motivos-nao-venda.component.html',
  styleUrls: ['./motivos-nao-venda.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class MotivosNaoVendaComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Motivos (Não Venda) */

  motivosNaoVenda: MotivoNaoVenda[] = [];

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private motivoNaoVendaService: MotivoNaoVendaService
  ) { }

  ngOnInit() {
    this.carregarDados();
  }

  /* Ações */

  alterarMotivo(id: number) {
    this.router.navigate(["/motivos-nao-venda-cadastro/" + id]);
  }

  atualizarStatus(id: number) {
    let atualizarStatus: boolean = false;

    this.motivoNaoVendaService.updateIsActiveById(id, false).subscribe(
      atualizaStatus => atualizarStatus = atualizaStatus,
      error => { console.log("Erro: " + error) },
      () => {
        if (atualizarStatus) {
          this.toastr.success("", "Motivo (Não Venda) desativado com sucesso!");

          this.carregarDados();
        }
      }
    );
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;

    this.motivoNaoVendaService.getByGuidClienteNapis().subscribe(
      motivosNaoVenda => this.motivosNaoVenda = motivosNaoVenda,
      error => { console.log("Erro: " + error) },
      () => {
        this.carregando = false;
      }
    );
  }
}
