export class ProdutoTabelaEspecial {
  public idprodutotabelaespecial: number;
  public descricao: string;
  public dtcriacao: Date;
  public guidclientenapis: string;
  public ativo: boolean;
  public cd_tabela_cliente: number;
  public filtro_tabela: string;
  public flag_desativar_na_integracao: string;
  public cod_tabela_ecommerce: number;

  constructor() { }
}
