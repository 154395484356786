export class Configuracao {
  public guidclientenapis: string;
  public id: number;
  public endereco_em_uma_linha: string;
  public separar_produtos_por_referencia: string;
  public permitir_produtos_sem_estoque: string;
  public filtrar_tabela: string;
  public margem_lucratividade_vermelha: number;
  public margem_lucratividade_verde: number;
  public preco_na_tabela_produtos: string;
  public nm_empresa: string;
  public usar_desconto_prazo_medio: string;
  public usar_bonificacao_volume: string;
  public alteracao_cadastro_cliente: string;
  public tela_cadastro_clientes: number;
  public permite_lancar_pedido_inadimplente: string;
  public is_active: boolean;
  public email_cadastro_faturamento: string;
  public empresa_fone: string;
  public empresa_endereco: string;
  public empresa_cidade_estado: string;
  public empresa_bairro: string;
  public empresa_email: string;
  public fotos_produtos: boolean;
  public qtd_maxima_fotos_por_visita: number;
  public modelo_navegacao_mapa: number;
  public estoque_em_tempo_real: boolean;
  public b2b_segmentado: boolean;
  public fuso_horario: number;
  public b2b_mostra_fator_fin: boolean;
  public b2b_seleciona_parcelas: boolean;
  public b2b_pedido_sem_estoque: boolean;
  public b2b_valor_minimo_pedido: number;
  public whats_faturamento: string;
  public prazo_flex: boolean;
  public full_offline: boolean;
  public mostra_faturado: boolean;
  public seleciona_transportadora: boolean;
  public mostra_estoque_grade: boolean;
  public b2b_busca_medida: boolean;
  public mostra_estoque: boolean;
  public usa_conta_corrente_flex: boolean;
  public usa_lote: boolean;
  public flag_exibe_produtos_sem_lote: boolean;
  public flag_permite_produtos_acima_do_estoque: boolean;
  public flag_permite_preco_acima_tabela: boolean;
  public flag_exibir_imposto_produto: boolean;
  public flag_separar_pedidos_por_lote: boolean;
  public flag_integra_pedidos_tempo_real: boolean;
  public integra_pedidos_tempo_real_pacote: string;
  public integra_pedidos_tempo_real_funcao: string;
  public usa_integracao: boolean;
  public flag_arredondar_fator_financeiro: boolean;
  public flag_bloquear_pedido_acima_limite_cliente: boolean;
  public rotas_dias: number;
  public sankhya_tabelas_para_importar: string;
  public flag_usa_desconto_maximo: boolean;
  public flag_usa_prazo_vinculado_cliente: boolean;
  public flag_permite_checkin_com_gps_desligado: boolean;
  public b2b_usa_como_orcamento: boolean;
  public b2b_mostra_produtos_sem_preco: boolean;
  public b2b_libera_faturamento_semcredito: boolean;
  public mercadopago_public_token: string;
  public tipo_ordenacao_itens_pedido: number;
  public valida_quantidade_minima_preco_tabela: boolean;
  public exibe_quantidade_fixa_lista_pedidos: boolean;
  public sugere_desconto_automaticamente: boolean;
  public link_innersac: string;
  public ocultar_observacao_nf_pedido: boolean;
  public exibe_campo_valor_por_unidade: boolean;
  public prazo_pagamento_antes_itenspedido: boolean;
  public oculta_tela_clientes_talonario: boolean;
  public exibe_outra_tabela_padrao: boolean;
  public exibe_price_como_tabela_pedidos: boolean;
  public tmk_seleciona_vendedor: boolean;
  public tipo_pagamento: string;
  public banco: string;
  public agencia: string;
  public conta: string;
  public utiliza_fator_financeiro: boolean;
  public exibe_local_entrega: boolean;
  public tipo_conta_corrente_flex: number;
  public usa_percentual_desconto_por_produto: boolean;
  public usa_seller_client_vinculo: boolean;
  public exibe_tabela_historico_pedido: boolean;
  public arredonda_percentual_conta_corrente_flex: boolean;
  public atualiza_status_totalizador: boolean;
  public calcula_desconto_maximo_sobre_preco_minimo: boolean;
  public flag_exibe_observacao_item_pedido: boolean;
  public seller_tmk_pdf: boolean;
  public pedido_dt_validade_automatica: number;
  public pedido_exibir_litragem: boolean;
  public pedido_filtrar_b2b: boolean;
  public b2b_cor_primaria: string;
  public b2b_modelo_tela: number;

  constructor() { }
}
