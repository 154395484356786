import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Tarefa } from '../../models/tarefas/tarefa';

@Injectable({
  providedIn: 'root'
})

export class TarefaService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public addTarefa(tarefa: Tarefa): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "tarefa": tarefa
    };

    return this.http.post<boolean>(this.baseUrl + "api/Tarefa/AddTarefa", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public deleteTarefaById(id: string, seller_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id,
      "seller_id": seller_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Tarefa/DeleteTarefaById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getTarefaById(id: string): Observable<Tarefa> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<Tarefa>(this.baseUrl + "api/Tarefa/GetTarefaById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getTarefaByVendedor(id_seller: number): Observable<Tarefa[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_seller": id_seller
    };

    return this.http.post<Tarefa[]>(this.baseUrl + "api/Tarefa/GetTarefaByVendedor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateTarefa(tarefa: Tarefa): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "tarefa": tarefa
    };

    return this.http.post<boolean>(this.baseUrl + "api/Tarefa/UpdateTarefa", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }
}
